import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import './overlap-carousel-block.scss';

import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import ImageTextElement from '../../ImageTextElement/ImageTextElement';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import AnalyticsService from '../../../Services/AnalyticsService';

const OverlapCarouselBlock = () => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const boxData = [
        {
            title: t('OverlapCarouselBlock.verticals.photography.title'),
            description: t(
                'OverlapCarouselBlock.verticals.photography.description'
            ),
            imageUrl: 'marketing_site/paid/Vertical_-_Job_vertical_Image_1.png',
            linkUrl: '/all-in-one-software-for-photographers'
        },
        {
            title: t('OverlapCarouselBlock.verticals.webDesigners.title'),
            description: t(
                'OverlapCarouselBlock.verticals.webDesigners.description'
            ),
            imageUrl: 'marketing_site/paid/Vertical_-_Job_vertical_Image_2.png',
            linkUrl: '/software-for-web-designers'
        },
        {
            title: t('OverlapCarouselBlock.verticals.eventPlanners.title'),
            description: t(
                'OverlapCarouselBlock.verticals.eventPlanners.description'
            ),
            imageUrl: 'marketing_site/paid/Vertical_-_Job_vertical_Image_3.png',
            linkUrl: '/all-in-one-software-for-event-and-wedding-planners'
        },
        {
            title: t('OverlapCarouselBlock.verticals.graphicDesigners.title'),
            description: t(
                'OverlapCarouselBlock.verticals.graphicDesigners.description'
            ),
            imageUrl: 'marketing_site/paid/Vertical_-_Job_vertical_Image_4.png',
            linkUrl: '/crm-for-graphic-designers'
        },
        {
            title: t('OverlapCarouselBlock.verticals.florists.title'),
            description: t(
                'OverlapCarouselBlock.verticals.florists.description'
            ),
            imageUrl: 'marketing_site/paid/Vertical_-_Job_vertical_Image_5.png',
            linkUrl: '/software-for-florists'
        },
        {
            title: t('OverlapCarouselBlock.verticals.consultants.title'),
            description: t(
                'OverlapCarouselBlock.verticals.consultants.description'
            ),
            imageUrl:
                'marketing_site/paid/Vertical_-_Job_vertical_Image_2_1.jpg',
            linkUrl: '/crm-for-consultants'
        },
        {
            title: t('OverlapCarouselBlock.verticals.accounting.title'),
            description: t(
                'OverlapCarouselBlock.verticals.accounting.description'
            ),
            imageUrl: 'marketing_site/paid/Vertical_-_Job_vertical_Image_7.png',
            linkUrl: '/software-for-home-accountants'
        },
        {
            title: t('OverlapCarouselBlock.verticals.PR.title'),
            description: t('OverlapCarouselBlock.verticals.PR.description'),
            imageUrl: 'marketing_site/paid/Vertical_-_Job_vertical_Image_8.png',
            linkUrl: '/marketing-consultant-software'
        },
        {
            title: t('OverlapCarouselBlock.verticals.legal.title'),
            description: t('OverlapCarouselBlock.verticals.legal.description'),
            imageUrl: 'marketing_site/paid/Vertical_-_Job_vertical_Image_9.png',
            linkUrl: '/all-in-one-software-for-lawyers'
        },
        {
            title: t('OverlapCarouselBlock.verticals.interiorDesign.title'),
            description: t(
                'OverlapCarouselBlock.verticals.interiorDesign.description'
            ),
            imageUrl:
                'marketing_site/paid/Vertical_-_Job_vertical_Image_10.png',
            linkUrl: '/interior-design-business-software'
        },
        {
            title: t('OverlapCarouselBlock.verticals.doula.title'),
            description: t('OverlapCarouselBlock.verticals.doula.description'),
            imageUrl:
                'marketing_site/paid/Vertical_-_Job_vertical_Image_11.png',
            linkUrl: '/doula-business-software'
        },
        {
            title: t('OverlapCarouselBlock.verticals.venueManagement.title'),
            description: t(
                'OverlapCarouselBlock.verticals.venueManagement.description'
            ),
            imageUrl:
                'marketing_site/paid/Vertical_-_Job_vertical_Image_12.png',
            linkUrl: '/venue-management-software'
        },
        {
            title: t('OverlapCarouselBlock.verticals.catering.title'),
            description: t(
                'OverlapCarouselBlock.verticals.catering.description'
            ),
            imageUrl:
                'marketing_site/paid/Vertical_-_Job_vertical_Image_14.png',
            linkUrl: '/catering-management-software'
        },
        {
            title: t('OverlapCarouselBlock.verticals.virtualAssistants.title'),
            description: t(
                'OverlapCarouselBlock.verticals.virtualAssistants.description'
            ),
            imageUrl:
                'marketing_site/paid/Vertical_-_Job_vertical_Image_15.png',
            linkUrl: '/virtual-assistant-software'
        },
        {
            title: t('OverlapCarouselBlock.verticals.socialMedia.title'),
            description: t(
                'OverlapCarouselBlock.verticals.socialMedia.description'
            ),
            imageUrl:
                'marketing_site/paid/Vertical_-_Job_vertical_Image_16.png',
            linkUrl: '/social-media-crm'
        },
        {
            title: t('OverlapCarouselBlock.verticals.artists.title'),
            description: t(
                'OverlapCarouselBlock.verticals.artists.description'
            ),
            imageUrl:
                'marketing_site/paid/Vertical_-_Job_vertical_Image_17.png',
            linkUrl: '/software-for-visual-artists'
        },
        {
            title: t('OverlapCarouselBlock.verticals.healthCoach.title'),
            description: t(
                'OverlapCarouselBlock.verticals.healthCoach.description'
            ),
            imageUrl:
                'marketing_site/paid/Vertical_-_Job_vertical_Image_18.png',
            linkUrl: '/health-coach-software'
        },
        {
            title: t('OverlapCarouselBlock.verticals.lifeCoach.title'),
            description: t(
                'OverlapCarouselBlock.verticals.lifeCoach.description'
            ),
            imageUrl:
                'marketing_site/paid/Vertical_-_Job_vertical_Image_19.png',
            linkUrl: '/life-coach-software'
        },
        {
            title: t('OverlapCarouselBlock.verticals.DJ.title'),
            description: t('OverlapCarouselBlock.verticals.DJ.description'),
            imageUrl:
                'marketing_site/paid/Vertical_-_Job_vertical_Image_20.png',
            linkUrl: '/dj-booking-software'
        },
        {
            title: t('OverlapCarouselBlock.verticals.freelance.title'),
            description: t(
                'OverlapCarouselBlock.verticals.freelance.description'
            ),
            imageUrl:
                'marketing_site/paid/Vertical_-_Job_vertical_Image_21.png',
            linkUrl: '/crm-for-freelancers'
        },
        {
            title: t(
                'OverlapCarouselBlock.verticals.businessConsultants.title'
            ),
            description: t(
                'OverlapCarouselBlock.verticals.businessConsultants.description'
            ),
            imageUrl:
                'marketing_site/paid/Vertical_-_Job_vertical_Image_13.png',
            linkUrl: '/crm-for-consultants'
        }
    ];

    return (
        <FeatureBlockSection halfSize customClass="overlap-carousel-block">
            <FeatureBlockContent
                columnWidth={1}
                customClass="overlap-carousel-block__text-container"
            >
                <div className="overlap-carousel-block__text-content">
                    <FeatureBlockText
                        fullWidth
                        primary={t('OverlapCarouselBlock.title')}
                        secondary={t('OverlapCarouselBlock.subtitle')}
                    />
                    <RegistrationForm
                        source="overlap carousel block"
                        showNoCcRequired
                        buttonStyle="primary--dark"
                        size="large"
                    />
                </div>
            </FeatureBlockContent>

            <FeatureBlockContent
                columnWidth={1}
                customClass="feature-block--swiper"
            >
                <div className="swiper-container">
                    <Swiper
                        className="mySwiper"
                        slidesPerView={breakpoints.medium ? 1 : 3}
                        centeredSlides={true}
                        initialSlide={1}
                        loop={true}
                        navigation={true}
                        pagination={false}
                        keyboard={true}
                        mousewheel={false}
                        modules={[
                            Navigation,
                            Pagination,
                            Mousewheel,
                            Keyboard,
                            Autoplay
                        ]}
                    >
                        {boxData.map(
                            ({ title, description, imageUrl, linkUrl }) => (
                                <SwiperSlide key={title}>
                                    <a
                                        href={linkUrl}
                                        className="swiper__box-link"
                                    >
                                        <div className="swiper__box">
                                            <ImageTextElement
                                                imagePath={imageUrl}
                                                mediaFlags={{ lazyLoad: false }}
                                                title={title}
                                                description={description}
                                            />
                                            <p className="swiper__link-text">
                                                Learn more
                                            </p>
                                        </div>
                                    </a>
                                </SwiperSlide>
                            )
                        )}
                    </Swiper>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default memo(OverlapCarouselBlock);
