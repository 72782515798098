import { useTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import HBText from '../../honeybook-ui/HBText/HBText';
import HBQuote from '../../HBQuote/HBQuote';

import './members-enjoy.scss';

const MembersCarousel = () => {
    const { t } = useTranslation();

    const QUOTE_TRANS_IDS = ['rachel', 'jennifer', 'jeanette'];

    return (
        <div className="members-enjoy">
            <FeatureBlockSection>
                <FeatureBlockContent>
                    <HBText
                        tag="h3"
                        type="body-l"
                        customClass="members-enjoy__pre-title"
                    >
                        {t('ReviewsPage.membersEnjoy.preTitle')}
                    </HBText>
                    <HBText
                        tag="h2"
                        type="title-l"
                        customClass="members-enjoy__title"
                    >
                        {t('ReviewsPage.membersEnjoy.title')}
                    </HBText>
                    <HBText
                        tag="p"
                        type="body-l"
                        customClass="members-enjoy__subtitle"
                    >
                        {t('ReviewsPage.membersEnjoy.subtitle')}
                    </HBText>
                    <div className="members-enjoy__cards">
                        {QUOTE_TRANS_IDS.map(transId => (
                            <div key={transId} className="members-enjoy__card">
                                <HBText
                                    tag="p"
                                    type="body-s"
                                    customClass="members-enjoy__card__name"
                                >
                                    {t(
                                        `ReviewsPage.membersEnjoy.responses.${transId}.name`
                                    )}
                                </HBText>
                                <HBText
                                    tag="p"
                                    type="body-m"
                                    customClass="members-enjoy__card__job-title"
                                >
                                    {t(
                                        `ReviewsPage.membersEnjoy.responses.${transId}.jobTitle`
                                    )}
                                </HBText>
                                <HBQuote customClass="members-enjoy__card__quote">
                                    {t(
                                        `ReviewsPage.membersEnjoy.responses.${transId}.quote`
                                    )}
                                </HBQuote>
                            </div>
                        ))}
                    </div>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

export default MembersCarousel;
