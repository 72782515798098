import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import HBText from '../../honeybook-ui/HBText/HBText';
import ChevronLeft from '../../../svg/chevron-left.inline.svg';

const industries = [
    'placeholder',
    'bake',
    'business_consulting',
    'blogging',
    'marketing',
    'entertainment',
    'fashion',
    'food',
    'floral_design',
    'graphic_design',
    'hair',
    'interior_design',
    'maker',
    'photography',
    'planning',
    'rentals_photo_booth',
    'Venue',
    'visual_art',
    'videography',
    'web_design',
    'education',
    'health',
    'retail',
    'social_media',
    'other'
];
interface Props {
    hasFailed: boolean;
}

const StepThree = ({ hasFailed }: Props) => {
    const { t } = useTranslation();
    const placeholderText = t(`${industries[0]}`);
    const [selectedIndustry, setSelectedIndustry] = useState(placeholderText);

    return (
        <div className="mission-statement-step mission-statement-step--three">
            <HBText
                tag="p"
                type="body-l"
                customClass="mission-statement-step__question"
            >
                <Trans>{t('missionStatement.quiz.step3.question')}</Trans>
            </HBText>
            <div className="mission-statement-step__select-container">
                <div
                    className={`mission-statement-step__input-placeholder ${
                        selectedIndustry === industries[0]
                            ? ''
                            : 'mission-statement-step__input-placeholder--active'
                    }`}
                >
                    {t(`missionStatement.quiz.step3.${selectedIndustry}`)}
                    <ChevronLeft />
                </div>

                <select
                    onChange={e => {
                        setSelectedIndustry(e.target.value);
                    }}
                    className="mission-statement-step__input mission-statement-step__input--select"
                >
                    {industries.map(industry => (
                        <option
                            className="mission-statement-step__input__option"
                            value={industry}
                            key={industry}
                            disabled={industry === industries[0]}
                        >
                            {t(`missionStatement.quiz.step3.${industry}`)}
                        </option>
                    ))}
                </select>
            </div>
            {hasFailed && (
                <p className="mission-statement-step__error">
                    {t('missionStatement.quiz.errors.missingField')}
                </p>
            )}
        </div>
    );
};

export default StepThree;
