import { useCallback, useEffect, useRef } from 'react';

function loadGoogleAuth2() {
    function start() {
        const apiKey = process.env.GATSBY_google_api_api_key || '';
        gapi.client.setApiKey(apiKey);
        gapi.auth2.init({
            client_id: process.env.GATSBY_google_api_client_id,
            scope: 'profile email'
        });
    }

    gapi.load('client:auth2', start);
}

function useLoadGoogleApi() {
    const hasLoaded = useRef(false);

    const loadGoogleApi = useCallback(() => {
        if (typeof window !== 'undefined') {
            if (!hasLoaded.current) {
                hasLoaded.current = true;
                window.removeEventListener('mousemove', loadGoogleApi);

                // Create a script element that will load
                const dynamicScript = document.createElement('script');

                // Set source to the script we need to load
                dynamicScript.src =
                    'https://apis.google.com/js/client:auth2.js';

                // Set onload to callback function that depends on this script or do inline as shown below
                dynamicScript.onload = loadGoogleAuth2;

                // append the created script element to body element
                document.body.append(dynamicScript);
            }
        }
    }, [hasLoaded]);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        window.addEventListener('mousemove', loadGoogleApi);
    }, [loadGoogleApi]);
}

export default useLoadGoogleApi;
