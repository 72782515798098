import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import HBText from '@honeybook-ui/HBText/HBText';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import InvoiceTemplateItem from './InvoiceTemplateItem';
import type { InvoiceItemType } from './InvoiceTemplateItem';
import type { InvoiceGeneratorResponse } from '../../../Services/ApiService';
import ApiService from '../../../Services/ApiService';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';
import HBModal from '@honeybook-ui/HBModal/HBModal';
import HBButton from '@honeybook-ui/HBButton/HBButton';
import HBLoader from '../../honeybook-ui/HBLoader/HBLoader';
import UtilsService from '../../../Services/UtilsService';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './invoice-template-demo.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import getApiUrl from '../../../env';

interface Props {
    title?: string;
    subtitle?: string;
}

const heroSlideImagesAfterLoad: Array<string> = [
    'https://res.cloudinary.com/honeybook/image/upload/c_fill,dpr_auto,f_auto,q_auto:best,w_755,h_201,e_brightness:-30/v1691429689/marketing_site/invoice-generator-lp/banners/Banner2.png',
    'https://res.cloudinary.com/honeybook/image/upload/c_fill,dpr_auto,f_auto,q_auto:best,w_755,h_201,e_brightness:-20/v1691429689/marketing_site/invoice-generator-lp/banners/Banner3.png',
    'https://res.cloudinary.com/honeybook/image/upload/c_fill,dpr_auto,f_auto,q_auto:best,w_755,h_201/v1691429689/marketing_site/invoice-generator-lp/banners/Banner4.png',
    'https://res.cloudinary.com/honeybook/image/upload/c_fill,dpr_auto,f_auto,q_auto:best,w_755,h_201,e_brightness:-20/v1691429689/marketing_site/invoice-generator-lp/banners/Banner5.png',
    'https://res.cloudinary.com/honeybook/image/upload/c_fill,dpr_auto,f_auto,q_auto:best,w_755,h_201/v1691429689/marketing_site/invoice-generator-lp/banners/Banner8.png',
    'https://res.cloudinary.com/honeybook/image/upload/c_fill,dpr_auto,f_auto,q_auto:best,w_755,h_201/v1691429689/marketing_site/invoice-generator-lp/banners/Banner9.png',
    'https://res.cloudinary.com/honeybook/image/upload/c_fill,dpr_auto,f_auto,q_auto:best,w_755,h_201/v1691429689/marketing_site/invoice-generator-lp/banners/Banner10.png',
    'https://res.cloudinary.com/honeybook/image/upload/c_fill,dpr_auto,f_auto,q_auto:best,w_755,h_201,e_brightness:-20/v1691429689/marketing_site/invoice-generator-lp/banners/Banner11.png',
    'https://res.cloudinary.com/honeybook/image/upload/c_fill,dpr_auto,f_auto,q_auto:best,w_755,h_201/v1691429689/marketing_site/invoice-generator-lp/banners/Banner12.png'
];

const heroSlideImages = [heroSlideImagesAfterLoad[0]];

const date = new Date();
const currentDate = `${
    date.getMonth() + 1
}/${date.getDate()}/${date.getFullYear()}`;

const InvoiceTemplateDemo = ({ title, subtitle }: Props) => {
    const { t } = useTranslation();

    const defaultItem: InvoiceItemType = {
        name: '',
        description: '',
        price: 0
    };

    const [companyName, setCompanyName] = useState('');
    const [invoiceDate, setInvoiceDate] = useState(currentDate);
    const [logoUrl, setLogoUrl] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState(1234567890);
    const [companyEmail, setCompanyEmail] = useState('');
    const [clientName, setClientName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hasAcceptedMarketingEmails, setHasAcceptedMarketingEmails] =
        useState(true);
    const [isModalSubmitted, setIsModalSubmitted] = useState(false);
    const [swiperIndex, setSwiperIndex] = useState(1);

    const [invoiceItems, setInvoiceItems] = useState([defaultItem]);
    const [subTotal, setSubTotal] = useState(0);
    const [tax, setTax] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [slides, setSlides] = useState(heroSlideImages);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setSlides(heroSlideImagesAfterLoad);
    }, []);

    const inputRef = useRef();

    const formatPhoneNumberFromInput = e => {
        const phoneNumber = UtilsService.formatPhoneNumber(e.target.value);
        e.target.value = phoneNumber;
        return phoneNumber;
    };

    const formattedData = {
        company_name: companyName,
        date: invoiceDate,
        company_address: companyAddress,
        company_phone: companyPhoneNumber,
        email: companyEmail,
        billed_to: clientName,
        subtotal: subTotal,
        tax: tax,
        grand_total: grandTotal,
        lead_invoice_items: invoiceItems,
        header_image_url: slides[swiperIndex],
        logo_image_url: logoUrl
    };

    const calculateTotals = (items, taxRate) => {
        let totalPrice: number = 0;
        items.forEach(item => {
            totalPrice += item.price;
        });
        setSubTotal(totalPrice);
        setGrandTotal(totalPrice + (totalPrice * taxRate) / 100);
    };

    const calculateTax = e => {
        const newTaxRate = e.target.value;
        setTax(newTaxRate);
        calculateTotals(invoiceItems, newTaxRate);
    };

    const addItem = () => {
        setInvoiceItems([...invoiceItems, defaultItem]);
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            addItem();
        }
    };

    const removeItem = (index: number) => {
        const newItems = [...invoiceItems];
        newItems.splice(index, 1);
        setInvoiceItems(newItems);
        calculateTotals(newItems, tax);
    };

    const updateItem = (key: string, value: string, index: number) => {
        const newItems = [...invoiceItems];
        newItems[index][key] = value;
        setInvoiceItems(newItems);

        if (key === 'price') {
            calculateTotals(newItems, tax);
        }
    };

    const handleDownloadClick = () => {
        if (inputRef.current.reportValidity()) {
            setIsModalOpen(true);
        }
    };

    const postInvoiceData = () => {
        AnalyticsService.reportIterableEvent(
            'lead_created',
            {
                email: companyEmail,
                lead_type: 'invoice'
            },
            { email: companyEmail }
        );

        postDataToInvoiceGeneratorApi(formattedData);
    };

    const handleToggleMarketingEmails = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.lp_invoice_template_generator_modal_marketing_emails,
            {
                source: hasAcceptedMarketingEmails
                    ? 'marketing emails accepted'
                    : 'marketing emails declined'
            }
        );
        setHasAcceptedMarketingEmails(
            hasAcceptedMarketingEmails => !hasAcceptedMarketingEmails
        );
    };

    const postDataToInvoiceGeneratorApi = data => {
        AnalyticsService.trackClick(
            AnalyticsEvents.lp_invoice_template_generator_download
        );

        const apiUrl = `${getApiUrl()}/api/v2/lead_invoice_generator`;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        ApiService._apiFetch(apiUrl, requestOptions);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleConfirmEmail = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.lp_invoice_template_generator_confirm_email
        );
        setIsModalSubmitted(true);
        postInvoiceData();
        setTimeout(handleModalClose, 2000);
        setTimeout(() => setIsModalSubmitted(false), 2500);
    };

    const handleFileUplaod = file => {
        AnalyticsService.trackClick(
            AnalyticsEvents.lp_invoice_template_generator_upload_logo
        );

        setIsLoading(true);

        const formData = new FormData();
        formData.append('file', file);
        formData.append(
            'upload_preset',
            process.env.CLOUDINARY_UPLOAD_PRESET || ''
        );
        formData.append('cloud_name', 'honeybook-landing');

        const apiUrl =
            'https://api.cloudinary.com/v1_1/honeybook-landing/upload';

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        ApiService._apiFetch(apiUrl, requestOptions)
            .then(response => {
                const logoUrl = (response as InvoiceGeneratorResponse)
                    .secure_url;
                setLogoUrl(logoUrl);
                setIsLoading(false);
            })
            .catch(error => console.log('error ', error));
    };

    const hasUserLogo = logoUrl && logoUrl.length > 0;

    const logoBackgroundStyle = hasUserLogo
        ? { backgroundImage: `url(${logoUrl})` }
        : { backgroundImage: '' };

    return (
        <FeatureBlockSection customClass="invoice-template-demo">
            <FeatureBlockContent>
                <div className="invoice-template-demo__title-content">
                    <HBText
                        customClass="invoice-template-demo__title"
                        tag="h1"
                        type="title-l"
                    >
                        {title || t('InvoiceTemplateDemo.title')}
                    </HBText>

                    <HBText
                        customClass="invoice-template-demo__subtitle"
                        tag="h2"
                        type="body-m"
                    >
                        {subtitle || t('InvoiceTemplateDemo.subtitle')}
                    </HBText>
                </div>

                <div className="invoice-template-demo__container">
                    <div className="invoice-template-demo__hero-container">
                        <HBText
                            customClass="invoice-template-demo__change-photo"
                            tag="p"
                            type="body-m"
                        >
                            {t('InvoiceTemplateDemo.changePhoto')}
                        </HBText>
                        <div className="invoice-template-demo__hero">
                            <div
                                className="invoice-template-demo__hero__logo"
                                style={logoBackgroundStyle}
                            >
                                {!hasUserLogo && (
                                    <div className="invoice-template-demo__hero__logo-prompt">
                                        {t(
                                            'InvoiceTemplateDemo.placeholderText.logo'
                                        )}
                                    </div>
                                )}

                                <input
                                    type="file"
                                    className="invoice-template-demo__hero__upload"
                                    onChange={e =>
                                        handleFileUplaod(e.target.files[0])
                                    }
                                />
                                {isLoading && <HBLoader />}
                            </div>
                            <div className="invoice-template-demo__hero__text-container">
                                <input
                                    type="text"
                                    className="invoice-template-demo__hero__company invoice-template-demo__input invoice-template-demo__input--hero"
                                    placeholder={t(
                                        'InvoiceTemplateDemo.placeholderText.company'
                                    )}
                                    value={companyName}
                                    onChange={e => {
                                        setCompanyName(e.target.value);
                                    }}
                                    maxLength={70}
                                />
                                <input
                                    type="text"
                                    placeholder={invoiceDate}
                                    className="invoice-template-demo__hero__date invoice-template-demo__input invoice-template-demo__input--hero"
                                    value={invoiceDate}
                                    onChange={e => {
                                        setInvoiceDate(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <Swiper
                            className="invoice-template-demo__swiper"
                            slidesPerView={1}
                            autoplay={false}
                            loop
                            draggable
                            keyboard
                            navigation={true}
                            modules={[Navigation]}
                            onSlideChange={swiper => {
                                setSwiperIndex(swiper.realIndex);
                            }}
                        >
                            {slides.map(slideImage => (
                                <SwiperSlide key={slideImage}>
                                    <div
                                        className="invoice-template-demo__swiper-slide"
                                        style={{
                                            backgroundImage: `url(${slideImage})`
                                        }}
                                    ></div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <div className="invoice-template-demo__invoice-container">
                        <div className="invoice-template-demo__form">
                            <div className="invoice-template-demo__form__address-container invoice-template-demo__form__input-container">
                                <HBIcon name="nx-buildings" />
                                <input
                                    type="text"
                                    placeholder={t(
                                        'InvoiceTemplateDemo.placeholderText.address'
                                    )}
                                    className="invoice-template-demo__form__address invoice-template-demo__input"
                                    value={companyAddress}
                                    onChange={e => {
                                        setCompanyAddress(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="invoice-template-demo__form__bill-container invoice-template-demo__form__input-container">
                                <span className="invoice-template-demo__form__bill-to">
                                    {t('InvoiceTemplateDemo.billTo')}:
                                </span>
                                <input
                                    type="text"
                                    placeholder={t(
                                        'InvoiceTemplateDemo.placeholderText.client'
                                    )}
                                    className="invoice-template-demo__form__client invoice-template-demo__input"
                                    value={clientName}
                                    onChange={e => {
                                        setClientName(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="invoice-template-demo__form__phone-container invoice-template-demo__form__input-container">
                                <HBIcon name="nx-phone" />
                                <input
                                    type="tel"
                                    placeholder={t(
                                        'InvoiceTemplateDemo.placeholderText.phone'
                                    )}
                                    className="invoice-template-demo__form__phone invoice-template-demo__input"
                                    onChange={e => {
                                        setCompanyPhoneNumber(
                                            formatPhoneNumberFromInput(e)
                                        );
                                    }}
                                />
                            </div>
                            <div className="invoice-template-demo__form__email-container invoice-template-demo__form__input-container">
                                <HBIcon name="nx-email" />
                                <input
                                    ref={inputRef}
                                    type="email"
                                    required
                                    placeholder={t(
                                        'InvoiceTemplateDemo.placeholderText.email'
                                    )}
                                    className="invoice-template-demo__form__email invoice-template-demo__input"
                                    value={companyEmail}
                                    onChange={e => {
                                        setCompanyEmail(e.target.value);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="invoice-template-demo__items-container">
                            <div className="invoice-template-demo__items-header">
                                <p>{t('InvoiceTemplateDemo.items.title')}</p>
                                <p>{t('InvoiceTemplateDemo.items.price')}</p>
                            </div>
                            {invoiceItems.map((invoiceItem, index) => (
                                <InvoiceTemplateItem
                                    updateItem={updateItem}
                                    removeItem={removeItem}
                                    invoiceItem={invoiceItem}
                                    key={index}
                                    index={index}
                                />
                            ))}
                            {invoiceItems.length < 5 && (
                                <div
                                    className="invoice-template-demo__add-item"
                                    onClick={addItem}
                                    onKeyDown={handleKeyDown}
                                    tabIndex={0}
                                >
                                    <HBIcon name="nx-plus" />
                                    {t('InvoiceTemplateDemo.addItem')}
                                </div>
                            )}
                        </div>

                        <div className="invoice-template-demo__totals">
                            <div className="invoice-template-demo__sub-total">
                                {t('InvoiceTemplateDemo.subTotal')}:
                                <span>${subTotal.toFixed(2)}</span>
                            </div>
                            <div className="invoice-template-demo__tax">
                                {t('InvoiceTemplateDemo.tax')}:
                                <input
                                    className="invoice-template-demo__input"
                                    type="text"
                                    placeholder="0"
                                    onChange={calculateTax}
                                />
                                <span className="invoice-template-demo__tax-symbol">
                                    %
                                </span>
                            </div>
                            <div className="invoice-template-demo__grand-total">
                                {t('InvoiceTemplateDemo.grandTotal')}:
                                <span>${grandTotal.toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <RegistrationForm
                    size="large"
                    source="Invoice template demo"
                    buttonText={t('InvoiceTemplateDemo.ctaText')}
                    showNoCcRequired={false}
                />

                <div
                    className="invoice-template-demo__download hbui-button hbui-button--medium hbui-button--tertiary"
                    onClick={handleDownloadClick}
                >
                    <HBIcon name="nx-download-16" />{' '}
                    {t('InvoiceTemplateDemo.download')}
                </div>

                <HBModal
                    className="invoice-template-demo__modal"
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                >
                    <HBText
                        customClass="invoice-template-demo__modal__title"
                        tag="h2"
                        type="subtitle-l"
                    >
                        {t('InvoiceTemplateModal.title')}
                    </HBText>
                    <HBText
                        customClass="invoice-template-demo__modal__description"
                        tag="p"
                        type="body-s"
                    >
                        {t('InvoiceTemplateModal.bodyText')}:
                    </HBText>
                    <HBText
                        customClass="invoice-template-demo__modal__email"
                        tag="p"
                        type="body-s"
                    >
                        {companyEmail}
                    </HBText>
                    <HBButton
                        onClick={handleConfirmEmail}
                        text={
                            isModalSubmitted
                                ? t('InvoiceTemplateModal.modalSubmitted')
                                : t('InvoiceTemplateModal.ctaText')
                        }
                        buttonStyle={
                            isModalSubmitted ? 'secondary' : 'primary--dark'
                        }
                        customClass="invoice-template-demo__modal__button"
                        isDisabled={isModalSubmitted}
                    />
                    <div className="invoice-template-demo__modal__legal">
                        <input
                            name="marketing-emails"
                            className="invoice-template-demo__modal__checkbox"
                            type="checkbox"
                            checked={hasAcceptedMarketingEmails}
                            onChange={handleToggleMarketingEmails}
                        />
                        <label htmlFor="marketing-emails">
                            {t('InvoiceTemplateModal.legalText')}
                        </label>
                    </div>
                </HBModal>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default InvoiceTemplateDemo;
