import { memo, useState, useEffect, useMemo } from 'react';

// TODO: replace with @cloudinary/core and @cloudinary/react
import {
    Image,
    Video,
    Placeholder
    // Transformation,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
} from 'cloudinary-react';

import fetch from 'node-fetch';

import { Cloudinary } from 'cloudinary-core';

// The css from this file moved to layout.scss
//import './media-element.scss';

interface Props {
    name: string;
    type?: string;
    forceImgTag?: boolean;
    width?: number | string;
    height?: number;
    crop?: string;
    alt?: string;
    customClass?: string;
    x?: number;
    y?: number;
    gravity?: string;
    radius?: string;
    fetchFormat?: string;
    flags?: string;
    quality?: string;
    dpr?: number | string;
    responsive?: boolean;
    autoPlay?: boolean;
    muted?: boolean;
    loop?: boolean;
    controls?: boolean;
    playsInline?: boolean;
    zoom?: number;
    lazyLoad?: boolean;
    showPlaceholder?: boolean;
    poster?: string;
}

function HBMediaElement({
    name,
    type,
    forceImgTag,
    width = 'auto',
    height,
    crop = 'scale',
    customClass,
    alt,
    x,
    y,
    gravity,
    radius,
    fetchFormat,
    flags,
    quality,
    dpr,
    responsive,
    autoPlay,
    muted,
    loop,
    controls,
    playsInline,
    zoom,
    lazyLoad = true,
    showPlaceholder = lazyLoad,
    poster
}: Props): React.ReactElement {
    const [svgContent, setSvgContent] = useState<string>('');
    const [isSVG, setIsSVG] = useState<boolean>(false);
    const [core, _] = useState<Cloudinary>(
        new Cloudinary({
            cloud_name: 'honeybook',
            secure: true
        })
    );

    useEffect(() => {
        // Extract file extension from URL
        const fileExtension = name.slice(-3);
        const controller = new AbortController();
        const signal = controller.signal;
        let isUnMount = false;
        if (fileExtension === 'svg') {
            setIsSVG(true);

            if (!forceImgTag) {
                fetch(core.url(name), { signal })
                    .then(res => res.text())
                    .then((text: string) => {
                        setSvgContent(text);

                        return;
                    })
                    .catch(() => {
                        if (!isUnMount) {
                            setSvgContent('');
                        }
                    });
            }
        }
        return () => {
            isUnMount = true;
            controller.abort();
        };
    }, [core, forceImgTag, name]);

    const fetchFormatDefault =
        fetchFormat || isSVG || type === 'video' ? undefined : 'auto';
    const flagsDefault =
        flags || isSVG || type === 'video' ? undefined : 'lossy';
    const qualityDefault =
        quality || isSVG || type === 'video' ? undefined : 'auto';

    const Type = type === 'video' ? Video : Image;

    const dangerouslySetInnerHTML = useMemo(
        () => ({
            __html: svgContent
        }),
        [svgContent]
    );

    return (
        <>
            {/* eslint-disable-next-line react/no-danger */}
            {isSVG && !forceImgTag && (
                <span
                    className={`media-element svg-content ${customClass || ''}`}
                    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                />
            )}
            {(!isSVG || forceImgTag) && (
                <div className={`media-element ${customClass || ''}`}>
                    <Type
                        className={`${responsive ? 'cld-responsive' : ''}`}
                        publicId={name}
                        alt={alt}
                        width={width}
                        height={height}
                        crop={crop}
                        fetchFormat={fetchFormatDefault}
                        flags={flagsDefault}
                        quality={qualityDefault}
                        dpr={dpr}
                        radius={radius}
                        responsive={responsive}
                        x={x}
                        y={y}
                        autoPlay={autoPlay}
                        controls={controls}
                        muted={muted}
                        loop={loop}
                        playsInline={playsInline}
                        zoom={zoom}
                        gravity={gravity}
                        loading={lazyLoad ? 'lazy' : undefined}
                        poster={poster}
                    >
                        {showPlaceholder && <Placeholder type="blur" />}
                        {/* <Transformation
								dpr={dpr}
								width={width}
								crop={crop}
								radius={radius}
								height={height}
								gravity={gravity}
								fetchFormat={fetchFormat}
								flags={flags}
								quality={quality}
							/> */}
                    </Type>
                </div>
            )}
        </>
    );
}

export default memo(HBMediaElement);
