const InstantPaymentsStore = {
    listItems: [
        { id: 'cashMinutes' },
        { id: 'availableAlways' },
        { id: 'choosePayments' },
        { id: 'lowTransfer' }
    ],
    listIcon: 'nx-check-16',
    avatar: 'marketing_site/online-payments/seth-halligan'
};

export default InstantPaymentsStore;
