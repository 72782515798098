import { memo } from 'react';

import FooterCopyright from '../FooterCopyright/FooterCopyright';

import HBLogoMini from '../../svg/honeybook-logo-mini.inline.svg';

import './footer-slim.scss';

const FooterSlim = ({ darkTheme = 'false' }) => {
    return (
        <footer
            className={`footer-slim ${darkTheme ? '' : 'footer-slim--dark'}`}
        >
            <HBLogoMini />
            <FooterCopyright />
        </footer>
    );
};

export default memo(FooterSlim);
