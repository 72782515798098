import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';
import ImageTextElement from '../../ImageTextElement/ImageTextElement';

import './business-personality-tool-social-block.scss';
import BusinessPersonalityToolSocialStore from './BusinessPersonalityToolSocialStore';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

const BusinessPersonalityToolSocialBlock = ({ resultKey }) => {
    const result = BusinessPersonalityToolSocialStore[resultKey];

    const handleClick = (analyticsData, clickEvent) => {
        AnalyticsService.trackClick(AnalyticsEvents.educator, {
            ...analyticsData
        });
        clickEvent();
    };

    return (
        <FeatureBlockSection customClass="bptr-visionaries">
            <FeatureBlockContent>
                <FeatureBlockText fullWidth primary={result.title} />

                <div className="bptr-visionaries__profile-container">
                    {result.educators.map(educator => (
                        <ImageTextElement
                            key={educator.name}
                            imagePath={educator.imagePath}
                            mediaFlags={educator.mediaFlags}
                            title={educator.name}
                            description={educator.handleText}
                            handleClick={() => {
                                handleClick(
                                    { source: educator.name },
                                    educator.onClick
                                );
                            }}
                        />
                    ))}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

BusinessPersonalityToolSocialBlock.propTypes = {
    resultKey: PropTypes.string.isRequired
};

export default withTranslation()(BusinessPersonalityToolSocialBlock);
