import { memo } from 'react';
import './cookie-settings.scss';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

function CookieSettingsButton() {
    const trackCookieSettingsBanner = () => {
        AnalyticsService.track(AnalyticsEvents.open_cookie_settings_banner);
    };

    return (
        <li
            id="ot-sdk-btn"
            className="ot-sdk-show-settings cookie-settings"
            onClick={trackCookieSettingsBanner}
            tabIndex={0}
        ></li>
    );
}

export default memo(CookieSettingsButton);
