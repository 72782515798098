import { useContext } from 'react';
import { EmailSignatureFormContext } from './EmailSignatureBlock';
import EmailSignatureTemplateConservative from './templates/EmailSignatureTemplateConservative';
import EmailSignatureTemplateModern from './templates/EmailSignatureTemplateModern';
import EmailSignatureTemplateClassic from './templates/EmailSignatureTemplateClassic';
import EmailSignatureTemplateCute from './templates/EmailSignatureTemplateCute';
import EmailSignatureTemplateCrisp from './templates/EmailSignatureTemplateCrisp';
import EmailSignatureTemplateUrban from './templates/EmailSignatureTemplateUrban';
import EmailSignatureTemplateCool from './templates/EmailSignatureTemplateCool';
import EmailSignatureTemplateRomantic from './templates/EmailSignatureTemplateRomantic';

import FacebookIcon from './../../svg/socialMedia/facebook.inline.svg';
import FacebookIconColor from './../../svg/socialMedia/facebook-color.inline.svg';
import TwitterIcon from './../../svg/socialMedia/twitter.inline.svg';
import LinkedinIcon from './../../svg/socialMedia/linkedIn.inline.svg';
import LinkedinIconColor from './../../svg/socialMedia/linkedIn-color.inline.svg';
import InstagramIcon from './../../svg/socialMedia/instagram.inline.svg';
import InstagramIconColor from './../../svg/socialMedia/instagram-color.inline.svg';
import PinterestIcon from './../../svg/socialMedia/pinterest.inline.svg';
import PinterestIconColor from './../../svg/socialMedia/pinterest-color.inline.svg';
import YoutubeIcon from './../../svg/socialMedia/youtube.inline.svg';
import YoutubeIconColor from './../../svg/socialMedia/youtube-color.inline.svg';

import './templates/email-signature-template.scss';

function EmailSignatureTemplate() {
    const { selectedTemplateName } = useContext(EmailSignatureFormContext);

    switch (selectedTemplateName) {
        case 'conservative':
            return <EmailSignatureTemplateConservative />;
        case 'modern':
            return <EmailSignatureTemplateModern />;
        case 'classic':
            return <EmailSignatureTemplateClassic />;
        case 'cute':
            return <EmailSignatureTemplateCute />;
        case 'crisp':
            return <EmailSignatureTemplateCrisp />;
        case 'urban':
            return <EmailSignatureTemplateUrban />;
        case 'cool':
            return <EmailSignatureTemplateCool />;
        case 'romantic':
            return <EmailSignatureTemplateRomantic />;
        default:
            return <EmailSignatureTemplateConservative />;
    }
}

interface TSignatureField {
    fieldName: string;
    fieldValue: string;
    href?: string;
    elPrefix?: JSX.Element;
}
export const SignatureField = ({
    fieldName,
    fieldValue,
    href,
    elPrefix
}: TSignatureField) => {
    return (
        <>
            {!!fieldValue &&
                (href ? (
                    <a
                        href={href}
                        className={`email-signature-template__field email-signature-template__${fieldName}`}
                    >
                        {elPrefix}
                        <div>{fieldValue}</div>
                    </a>
                ) : (
                    <div
                        className={`email-signature-template__field email-signature-template__${fieldName}`}
                    >
                        {elPrefix}
                        <p>{fieldValue}</p>
                    </div>
                ))}
        </>
    );
};

interface TSignatureJobTitleAndCompany {
    jobTitle: string;
    company: string;
    spacer: string;
}
export const SignatureJobTitleAndCompany = ({
    jobTitle,
    company,
    spacer
}: TSignatureJobTitleAndCompany) => {
    return (
        <>
            {!!jobTitle && (
                <p className="email-signature-template__job-title">
                    {jobTitle}
                </p>
            )}
            {!!company && (
                <>
                    <p className="email-signature-template__at">{spacer}</p>
                    <p className="email-signature-template__company">
                        {company}
                    </p>
                </>
            )}
        </>
    );
};

interface TSocialUrls {
    facebookUrl?: string;
    twitterUrl?: string;
    linkedinUrl?: string;
    instagramUrl?: string;
    pinterestUrl?: string;
    youtubeUrl?: string;
}
interface TSignatureSocialIcons {
    socialUrls: TSocialUrls;
    isColor?: boolean;
}
export const SignatureSocialIcons = ({
    socialUrls,
    isColor
}: TSignatureSocialIcons) => {
    const {
        facebookUrl,
        twitterUrl,
        linkedinUrl,
        instagramUrl,
        pinterestUrl,
        youtubeUrl
    } = socialUrls;
    return (
        <div className="email-signature-template__social">
            {!!facebookUrl && (
                <a href={facebookUrl} target="_blank">
                    {isColor ? <FacebookIconColor /> : <FacebookIcon />}
                </a>
            )}
            {!!twitterUrl && (
                <a href={twitterUrl} target="_blank">
                    <TwitterIcon />
                </a>
            )}
            {!!linkedinUrl && (
                <a href={linkedinUrl} target="_blank">
                    {isColor ? <LinkedinIconColor /> : <LinkedinIcon />}
                </a>
            )}
            {!!instagramUrl && (
                <a href={instagramUrl} target="_blank">
                    {isColor ? <InstagramIconColor /> : <InstagramIcon />}
                </a>
            )}
            {!!pinterestUrl && (
                <a href={pinterestUrl} target="_blank">
                    {isColor ? <PinterestIconColor /> : <PinterestIcon />}
                </a>
            )}
            {!!youtubeUrl && (
                <a href={youtubeUrl} target="_blank">
                    {isColor ? <YoutubeIconColor /> : <YoutubeIcon />}
                </a>
            )}
        </div>
    );
};

interface TSignatureImage {
    imageUrl: string;
}
export const SignatureImage = ({ imageUrl }: TSignatureImage) => {
    return (
        <>
            {!!imageUrl && (
                <img
                    className="email-signature-template__image"
                    src={imageUrl}
                    alt="email-signature-image"
                />
            )}
        </>
    );
};

export default EmailSignatureTemplate;
