import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import HBMediaElement from '../../../honeybook-ui/HBMediaElement/HBMediaElement';

import './feature-pipeline-animation.scss';

interface Props {
    customClass: string;
}

interface IPipelineItem {
    id: string;
    count: number;
    isAnimatable: boolean;
}

const PIPELINE_ITEMS: IPipelineItem[] = [
    {
        id: 'projects',
        count: 58,
        isAnimatable: false
    },
    {
        id: 'inquiry',
        count: 4,
        isAnimatable: true
    },
    {
        id: 'follow-up',
        count: 5,
        isAnimatable: true
    },
    {
        id: 'sent',
        count: 10,
        isAnimatable: true
    },
    {
        id: 'signed',
        count: 4,
        isAnimatable: true
    },
    {
        id: 'retainer',
        count: 10,
        isAnimatable: true
    },
    {
        id: 'planning',
        count: 6,
        isAnimatable: true
    },
    {
        id: 'completed',
        count: 22,
        isAnimatable: true
    },
    {
        id: 'archived',
        count: 6,
        isAnimatable: false
    }
];

const FeaturePipelineAnimation = ({ customClass }: Props) => {
    const [position, setPosition] = useState(0);

    const handleScroll = position => {
        setPosition(position);
    };

    return (
        <div className={`feature-pipeline-animation ${customClass}`}>
            <ParallaxProvider scrollAxis="vertical">
                <Parallax
                    onProgressChange={handleScroll}
                    style={{ position: 'absolute', inset: '0px' }}
                />
                <div className="feature-pipeline-animation__content">
                    <HBMediaElement
                        customClass="feature-pipeline-animation__workspace"
                        name="marketing_site/crm-page/feature-info-block/pipeline/workspace.png"
                    />
                    <div className="feature-pipeline-animation__pipeline">
                        {PIPELINE_ITEMS.map(item => (
                            <FeaturePipelineItem
                                key={item.id}
                                item={item}
                                position={position}
                            />
                        ))}
                    </div>
                </div>
            </ParallaxProvider>
        </div>
    );
};

export default FeaturePipelineAnimation;

interface IFeaturePipelineItem {
    item: IPipelineItem;
    position: number;
}

const FeaturePipelineItem = ({ item, position }: IFeaturePipelineItem) => {
    const { t } = useTranslation();
    const _position = position - 0.25;
    const animatableItems = PIPELINE_ITEMS.filter(item => item.isAnimatable);
    const animatableItemsCount = animatableItems.length;
    const itemIdx = animatableItems.findIndex(_item => _item.id === item.id);
    const boundaries = {
        start: (1 / animatableItemsCount) * itemIdx - 1 / animatableItemsCount,
        end: (1 / animatableItemsCount) * itemIdx
    };

    const className = useMemo(
        () => `
        pipeline-box
        pipeline-box--${item.id}
        pipeline-box--${
            item.isAnimatable &&
            _position > boundaries.start &&
            _position < boundaries.end &&
            'selected'
        }
      `,
        [_position]
    );
    return (
        <div key={item.id} className={className}>
            <div className="pipeline-box__count">{item.count}</div>
            <div className="pipeline-box__label">
                {t(`featurePipelineAnimation.labels.${item.id}`)}
            </div>
        </div>
    );
};
