import React from 'react';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { withTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import ImageTextElement from '../ImageTextElement/ImageTextElement';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './slider.scss';

const Slider = ({ t, slides }) => {
    const breakpoints = useBreakpoint();

    return (
        <Carousel
            showStatus={false}
            showArrows={false}
            centerMode={!breakpoints.mediumUp}
            centerSlidePercentage={breakpoints.mediumUp ? 100 : 72}
            useKeyboardArrows
            autoPlay
            infiniteLoop
            swipeable={false}
            showIndicators={!!breakpoints.mediumUp}
            showThumbs={false}
        >
            {slides.map(slide => (
                <ImageTextElement
                    key={slide.id}
                    customClass="slide__contents"
                    title={t(slide.title)}
                    description={t(slide.description)}
                    imagePath={slide.imagePath}
                />
            ))}
        </Carousel>
    );
};

Slider.propTypes = {
    t: PropTypes.func,
    slides: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withTranslation()(Slider);
