import { useEffect, useMemo } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import '../../../styles/stats-animation.scss';

export interface TStats {
    title: JSX.Element;
    subtitle: JSX.Element;
    maxPositionOfScroll?: number;
    id?: string;
}
interface Props {
    stats: TStats[];
    rangeOfScrollBetweenWords?: number;
}

const CLASS_NAME_OF_CONTENT = 'stats-animation__content';
const ANIMATION_SLOWER_VALUE = 1.3;
const getFillWidth = ({
    elements,
    maxPositionOfScroll,
    rangeOfScrollBetweenWords,
    index
}: {
    elements: NodeListOf<Element>;
    index: number;
    maxPositionOfScroll: number;
    rangeOfScrollBetweenWords: number;
}) => {
    if (!elements[index - 1]) {
        const fillValue =
            (window.scrollY / (maxPositionOfScroll * ANIMATION_SLOWER_VALUE)) *
            100;
        return fillValue > 100 ? 100 : fillValue;
    } else {
        const scrollYPositionToStartFilling = Number(
            (elements[index - 1] as HTMLElement).dataset.max
        );
        const fillValue =
            ((window.scrollY -
                scrollYPositionToStartFilling * ANIMATION_SLOWER_VALUE) /
                (maxPositionOfScroll * ANIMATION_SLOWER_VALUE -
                    rangeOfScrollBetweenWords * index)) *
            100;
        return window.scrollY >= scrollYPositionToStartFilling
            ? fillValue > 100
                ? 100
                : fillValue
            : 0;
    }
};

const StatsAnimation = ({ stats, rangeOfScrollBetweenWords = 60 }: Props) => {
    const { mediumUp } = useBreakpoint();

    const defaultMaxPositionOfScroll = [
        mediumUp ? 40 : 80,
        mediumUp ? 100 : 140,
        mediumUp ? 160 : 200,
        mediumUp ? 220 : 240
    ];

    const statsForDisplay = useMemo(() => {
        return stats.map((stat, idx) => {
            return {
                ...stat,
                maxPositionOfScroll:
                    stat.maxPositionOfScroll || defaultMaxPositionOfScroll[idx]
            };
        });
    }, []);

    useEffect(() => {
        const scrollHandler = () => {
            if (
                window.screenY >
                statsForDisplay[statsForDisplay.length - 1].maxPositionOfScroll
            )
                return;
            const elContentContainers =
                document.querySelectorAll('.stats-animation');
            const elProgressContainers = document.querySelectorAll(
                '.stats-animation__content-container__inner'
            );
            elContentContainers.forEach((el: HTMLElement) => {
                const maxPositionOfScroll = Number(el.dataset.max);
                const diff = 10;
                const method =
                    window.scrollY >
                    (maxPositionOfScroll - diff) * ANIMATION_SLOWER_VALUE
                        ? 'add'
                        : 'remove';
                el.classList[method]('scale');
            });
            elProgressContainers.forEach((el: HTMLElement, index) => {
                const maxPositionOfScroll = Number(el.dataset.max);
                const fillWidth = getFillWidth({
                    elements: elProgressContainers,
                    index,
                    maxPositionOfScroll,
                    rangeOfScrollBetweenWords
                });
                el.style.width = `${fillWidth}%`;
            });
        };
        const handler = requestAnimationFrame.bind(window, scrollHandler);
        window.addEventListener('scroll', handler);
        return () => {
            window.removeEventListener('scroll', handler);
        };
    }, []);

    return (
        <FeatureBlockSection customClass="stats-animation-block">
            <FeatureBlockContent>
                <div className="stats-animation-container">
                    {statsForDisplay.map(
                        ({ title, subtitle, maxPositionOfScroll }, idx) => {
                            return (
                                <section
                                    className="stats-animation"
                                    data-max={maxPositionOfScroll}
                                    key={'stats' + idx}
                                >
                                    <div className="stats-animation__content-container">
                                        <div
                                            data-max={maxPositionOfScroll}
                                            className="stats-animation__content-container__inner"
                                        ></div>
                                        <span
                                            data-max={maxPositionOfScroll}
                                            className={`${CLASS_NAME_OF_CONTENT}`}
                                        >
                                            {title}
                                        </span>
                                    </div>
                                    <p className="stats-animation__sub-text">
                                        {subtitle}
                                    </p>
                                </section>
                            );
                        }
                    )}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default StatsAnimation;
