import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';

import CoreValuesBlock from '../components/CareersPage/CoreValuesBlock/CoreValuesBlock';
import SocialProofBlock from '../components/CareersPage/SocialProofBlock/SocialProofBlock';
import SocialMediaBlock from '../components/CareersPage/SocialMediaBlock/SocialMediaBlock';
import OpenRolesBlock from '../components/CareersPage/OpenRolesBlock/OpenRolesBlock';
import CareersHero from '../components/CareersPage/CareerHeroBlock/careers-hero';
import IntroBlock from '../components/CareersPage/IntroBlock/IntroBlock';

const ogTags = {
    url: 'https://www.honeybook.com/careers',
    title: 'Careers at HoneyBook',
    description:
        'We are a growing team of fun, dedicated, and passionate people doing our best to help independent business owners achieve their goals. Learn about our latest career opportunities and how you can contribute to and have an impact in this ever-expanding part of the workforce. Join us!'
};

const CareersPage = () => {
    return (
        <section className="careers-page">
            <Layout ogTags={ogTags} slimNav noCta>
                <CareersHero />
                <IntroBlock />
                <CoreValuesBlock />
                <OpenRolesBlock />
                <SocialProofBlock />
                <SocialMediaBlock />
            </Layout>
        </section>
    );
};

export default CareersPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "careers"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
