import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import FeatureTitleList from '../../FeatureTitleList/FeatureTitleList';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockUserQuote
} from '../../FeatureBlockElements';

import './branded-experience-block.scss';

const BrandedExperienceBlock = ({ t }) => {
    const translationKeysArray = Object.keys(
        t('schedulerPage.brandedExperienceBlock.list', { returnObjects: true })
    );

    const translationKeysArrayList = translationKeysArray.map(value => {
        return { id: value };
    });
    const breakpoints = useBreakpoint();

    return (
        <FeatureBlockSection customClass="branded-experience">
            <FeatureBlockContent>
                <FeatureTitleList
                    title={t('schedulerPage.brandedExperienceBlock.title')}
                    listName="schedulerPage.brandedExperienceBlock.list"
                    items={translationKeysArrayList}
                    registrationSource="scheduler-meeting"
                />

                <FeatureBlockUserQuote
                    avatar="marketing_site/scheduler-page/Samantha_Heberlein-image.jpg"
                    name={t('schedulerPage.brandedExperienceBlock.quotee')}
                    jobTitle={t(
                        'schedulerPage.brandedExperienceBlock.quoteeJobTitle'
                    )}
                    quote={t('schedulerPage.brandedExperienceBlock.quote')}
                    avatarWidth={40}
                />
            </FeatureBlockContent>

            <FeatureBlockContent customClass="branded-image">
                <HBMediaElement
                    alt={t(`schedulerPage.brandedExperienceBlock.title`)}
                    name="marketing_site/scheduler-page/branding-examples-rebrand.png"
                    width={breakpoints.medium ? 350 : 700}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

BrandedExperienceBlock.propTypes = {
    t: PropTypes.func
};

export default withTranslation()(BrandedExperienceBlock);
