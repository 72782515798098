import * as queryString from 'query-string';
import fetch from 'node-fetch';

import type { RequestInfo, RequestInit } from 'node-fetch';
import type { GeoData } from './GeolocationService';

import getApiUrl from '../env';
import { trackError } from '../utils/error.utils';
import type {
    TTemplateData,
    TTemplatePublicUrlResponse
} from '../components/PublicTemplatePreview/type';
import UtilsService from './UtilsService';

export interface ValidateOnboardingResponse {
    is_valid: boolean;
    domain_not_valid: boolean;
    redirect_to_url: string;
}

export interface RegistrationData {
    vendor_email?: string;
    vendor_name?: string;
    registration_variant:
        | 'paywall'
        | 'homepage'
        | 'homepage_facebook'
        | 'community';
    geo_data?: GeoData;
    fullName?: string;
    is_sso: boolean;
    sso_type?: string;
    auth_token?: string;
    auth_code?: string;
    profile_img_url?: string;
    pricingPlan?: unknown; // is it boolean?
    server_response?: ValidateOnboardingResponse;
}

export interface SignupUserResponse {
    api_version: string;
    authentication_token: string;
    _id: string;
}

export interface InvoiceGeneratorResponse {
    secure_url: string;
}

export default class ApiService {
    static _apiFetch = <T>(key: RequestInfo, data: RequestInit): Promise<T> => {
        return fetch(key, data).then(response => {
            if (response.status === 204) return '';
            if (!response.ok) {
                throw response;
            }
            return response.json();
        });
    };

    static validateOnboarding = (registrationData: RegistrationData) => {
        const fetchKey = `${getApiUrl()}/api/validate_onboarding? ${queryString.stringify(
            registrationData
        )}`;
        return this._apiFetch<ValidateOnboardingResponse>(fetchKey, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
    };

    static ssoSignupUser = (
        ssoData: Record<string, unknown>
    ): Promise<SignupUserResponse> => {
        return this._apiFetch(
            `${getApiUrl()}/api/v2/users/oauth/sso_signup_user`,
            {
                method: 'POST',
                body: JSON.stringify(ssoData),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ) as Promise<SignupUserResponse>;
    };

    static createEmailSignature = data => {
        interface Res {
            success?: boolean;
            lead_email_signature_id?: string;
            error?: boolean;
            error_message?: string;
            error_type?: string;
            is_timeout?: boolean;
            note?: string;
        }

        return this._apiFetch(
            `${getApiUrl()}/api/v2/lead_email_signature_generator`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then((res: Res) => {
            if (res.error) {
                throw new Error(res.error_message);
            } else {
                return res;
            }
        });
    };

    static getEmailSignatureById(id: string) {
        const url =
            'https://s3.amazonaws.com/' +
            process.env.S3_email_signature_bucket +
            '/' +
            id +
            '.html';
        return fetch(url, { method: 'GET' }).then(response => {
            if (response.status === 200) {
                return response.text();
            } else {
                throw new Error('failed to fetch email signature');
            }
        });
    }

    static uploadImage = imageData => {
        const url =
            'https://api.cloudinary.com/v1_1/honeybook-landing/image/upload';
        return this._apiFetch(url, {
            method: 'POST',
            body: imageData
        });
    };

    static getFeedbackData = (clientFeedbackId: string) => {
        interface Res {
            company_name: string;
            member_name: string;
            company_logo_url: string;
            feedback_submitted: boolean;
            feedback_request_prompt: string;
            error_message?: string;
        }

        const url = `${getApiUrl()}/api/v2/feedback/${clientFeedbackId}`;
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        return ApiService._apiFetch(url, options)
            .catch(err => {
                trackError(err);
            })
            .then((res: Res) => {
                if (res?.error_message) {
                    trackError(res.error_message);
                    return null;
                } else {
                    return res;
                }
            });
    };

    static async getTemplateData(templateId: string): Promise<TTemplateData> {
        const url = `${getApiUrl()}/api/v2/client/flow/${templateId}/template/view`;
        return this._apiFetch<TTemplateData>(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
    }

    static async getTemplatePublicUrl(
        templateId: string
    ): Promise<TTemplatePublicUrlResponse> {
        const url = `${getApiUrl()}/api/v2/client/flow/${templateId}/template/public_url`;
        return this._apiFetch<TTemplatePublicUrlResponse>(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
    }

    static sendFeedbackData = (
        clientFeedbackId: string,
        rating_score: number,
        rating_comment: string
    ) => {
        const url = `${getApiUrl()}/api/v2/feedback/${clientFeedbackId}`;

        const feedbackData = {
            rating_score,
            rating_comment
        };

        const options = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(feedbackData)
        };

        return ApiService._apiFetch(url, options);
    };
}
