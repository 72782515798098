import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';

import './free-to-try-rebrand.scss';

const FreeToTryBlockRebrand = ({
    imagePath = 'marketing_site/moneystack.png'
}) => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="free-to-try-rebrand" fullWidth>
            <FeatureBlockContent col customClass="free-to-try-rebrand__text">
                <FeatureBlockText
                    customClass="free-to-try-rebrand__title free-to-try-rebrand__title--a"
                    primary={t('FreeToTryBlockRebrand.title-a')}
                />
                <FeatureBlockText
                    customClass="free-to-try-rebrand__title free-to-try-rebrand__title--b"
                    primary={t('FreeToTryBlockRebrand.title-b')}
                    secondary={t('FreeToTryBlockRebrand.subtitle')}
                />
                <RegistrationForm
                    source="paid marketing footer"
                    showNoCcRequired
                    buttonSize="large"
                />
            </FeatureBlockContent>
            <FeatureBlockContent customClass="free-to-try-rebrand__media">
                <HBMediaElement name={imagePath} />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

FreeToTryBlockRebrand.propTypes = {
    imagePath: PropTypes.string
};

export default FreeToTryBlockRebrand;
