import { useState, useEffect, createRef, memo } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import UtilsService from '../../Services/UtilsService';
import StickySidebar from '../StickySidebar/StickySidebar';
import FeaturesInvoiceBlock from '../FeatureBlocks/FeaturesInvoiceBlock/FeaturesInvoiceBlock';
import FeaturesProposalBlock from '../FeatureBlocks/FeaturesProposalBlock/FeaturesProposalBlock';
import FeaturesSchedulingBlock from '../FeatureBlocks/FeaturesSchedulingBlock/FeaturesSchedulingBlock';
import FeaturesContractBlock from '../FeatureBlocks/FeaturesContractBlock/FeaturesContractBlock';
import FeaturesAutomationBlock from '../FeatureBlocks/FeaturesAutomationBlock/FeaturesAutomationBlock';
import FeaturesAllInOneBlock from '../FeatureBlocks/FeaturesAllInOneBlock/FeaturesAllInOneBlock';
import FeaturesIntegrationsBlock from '../FeatureBlocks/FeaturesIntegrationBlock/FeaturesIntegrationBlock';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

import './features-page-body.scss';

const menuItems = [
    {
        id: 'invoices',
        menuIcon:
            'https://res.cloudinary.com/honeybook/image/upload/v1535380932/marketing_site/features-hub/desktop/sticky_menu_icons/Invoice.svg'
    },
    {
        id: 'proposals',
        menuIcon:
            'https://res.cloudinary.com/honeybook/image/upload/v1535380932/marketing_site/features-hub/desktop/sticky_menu_icons/proposal.svg'
    },
    {
        id: 'scheduling',
        menuIcon:
            'https://res.cloudinary.com/honeybook/image/upload/v1535380932/marketing_site/features-hub/desktop/sticky_menu_icons/calendar-24.svg'
    },
    {
        id: 'contracts',
        menuIcon:
            'https://res.cloudinary.com/honeybook/image/upload/v1535380932/marketing_site/features-hub/desktop/sticky_menu_icons/contract.svg'
    },
    {
        id: 'automation',
        menuIcon:
            'https://res.cloudinary.com/honeybook/image/upload/v1535380932/marketing_site/features-hub/desktop/sticky_menu_icons/Automation.svg'
    },
    {
        id: 'integrations',
        menuIcon:
            'https://res.cloudinary.com/honeybook/image/upload/v1535380932/marketing_site/features-hub/desktop/sticky_menu_icons/Integrations.svg'
    },
    {
        id: 'allInOne',
        menuIcon:
            'https://res.cloudinary.com/honeybook/image/upload/v1535380932/marketing_site/features-hub/desktop/sticky_menu_icons/package.svg'
    }
];

const sectionIds: string[] = menuItems.map(item => item.id);

const sectionComponents = [
    <FeaturesInvoiceBlock />,
    <FeaturesProposalBlock />,
    <FeaturesSchedulingBlock />,
    <FeaturesContractBlock />,
    <FeaturesAutomationBlock />,
    <FeaturesIntegrationsBlock />,
    <FeaturesAllInOneBlock />
];

function handleStickySidebarClick(sectionId: string): void {
    AnalyticsService.track(AnalyticsEvents.lp_features_hub_feature_selected, {
        source: sectionId
    });

    UtilsService.scrollToElementId(sectionId, 55);
}

function FeaturesPageBody(): JSX.Element {
    const breakpoint = useBreakpoint();
    const [sectionInView, setSectionInView] = useState<string>(sectionIds[0]);

    const sidebarRef = createRef<HTMLDivElement>();
    const featuresContainerRef = createRef<HTMLDivElement>();

    const trackVisibleSectionsOnScroll = (sectionIds: string[]): void => {
        if (typeof window !== 'undefined') {
            const scrollPosition = window.pageYOffset;

            sectionIds.forEach((sectionId: string): void => {
                const element = document.getElementById(sectionId);

                if (element !== null) {
                    const elementTop =
                        scrollPosition + element.getBoundingClientRect().top;
                    const elementHeight = element.offsetHeight || 0;
                    const elementBottom = elementTop + elementHeight;
                    const topNavHeight =
                        (
                            document.getElementsByClassName(
                                'navbar'
                            ) as HTMLCollectionOf<HTMLDivElement>
                        )[0].offsetHeight || 0;

                    if (
                        elementTop <= scrollPosition + topNavHeight + 200 &&
                        scrollPosition + topNavHeight + 400 <= elementBottom
                    ) {
                        setSectionInView(sectionId);
                    }
                }
            });
        }
    };

    useEffect(() => {
        const fn = UtilsService.throttle(() => {
            trackVisibleSectionsOnScroll(sectionIds);
        }, 100);

        // Only if sidebar is displayed (desktop only)
        if (sidebarRef.current) {
            trackVisibleSectionsOnScroll(sectionIds);

            window.addEventListener('scroll', fn);
        }

        return () => {
            window.removeEventListener('scroll', fn);
        };
    });

    return (
        <div className="features-page-body">
            <div
                className="features-page-body__features-container"
                ref={featuresContainerRef}
            >
                {breakpoint.largeUp && (
                    <aside
                        className="features-page-body__sticky-sidebar"
                        ref={sidebarRef}
                    >
                        <StickySidebar
                            menuItems={menuItems}
                            onClick={handleStickySidebarClick}
                            highlightMenuItem={sectionInView}
                            transKey="featuresPage"
                        />
                    </aside>
                )}

                <div className="features-page-body__features-list">
                    {sectionComponents.map((section, index) => (
                        <div
                            id={sectionIds[index]}
                            className="features-page-body__feature-container"
                            key={sectionIds[index]}
                        >
                            {section}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default memo(FeaturesPageBody);
