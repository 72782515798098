import { Fragment, memo } from 'react';
import * as PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import ListItem from './ListItem/ListItem';

import './list.scss';
import { TRANSLATION_COMPONENTS } from '../../constants/constants';

interface Props {
    listName: string;
    items: {
        id: string;
    }[];
    icon: string;
    title?: string | JSX.Element;
    subtitle?: string;
}

function List({ listName, items, icon, title, subtitle }: Props): JSX.Element {
    return (
        <Fragment>
            <h3 className="list__title">{title}</h3>
            {subtitle && <div className="list__subtitle">{subtitle}</div>}
            <ul className="list">
                {items.map(item => (
                    <ListItem
                        key={item.id}
                        text={
                            <Trans
                                i18nKey={`${listName}.${[item.id]}`}
                                components={TRANSLATION_COMPONENTS}
                            />
                        }
                        icon={icon}
                    />
                ))}
            </ul>
        </Fragment>
    );
}

List.propTypes = {
    listName: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    icon: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    subtitle: PropTypes.string
};

export default memo(List);
