import { useState } from 'react';
import { Trans } from 'react-i18next';

import FAQItem from './FAQItem';

import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../FeatureBlockElements';

import './faq-container.scss';
import HBText from '../honeybook-ui/HBText/HBText';

interface Item {
    props: {
        id: string;
        context: {
            source: string;
            signupLandingPage?: string;
        };
        answer?: string;
        question: string;
        answerTransKey: string;
    };
}

interface Props {
    title?: string;
    subtitle?: string;
    customClass?: string;
    displayContactLink?: boolean;
    items: Item[];
}

function FAQContainer({
    title,
    subtitle,
    items,
    displayContactLink,
    customClass
}: Props): JSX.Element {
    const [currentOpenItemId, setCurrentOpenItemId] = useState('');

    return (
        <FeatureBlockSection customClass={`faq-container ${customClass}`}>
            <FeatureBlockContent>
                <div className="faq-container__title-container">
                    {!!title && (
                        <HBText
                            customClass="faq-container__title"
                            tag="h2"
                            type="title-l"
                        >
                            {title}
                        </HBText>
                    )}
                    {!!subtitle && (
                        <HBText
                            customClass="faq-container__subtitle"
                            tag="h3"
                            type="subtitle-l"
                        >
                            {subtitle}
                        </HBText>
                    )}
                    {displayContactLink && (
                        <p className="faq-container__contact-link">
                            <Trans i18nKey="faqs.contactLinkText">
                                #TEXT#
                                <a
                                    href="/contact-us"
                                    target="__blank"
                                    rel="noopener noreferrer"
                                >
                                    #LINK_TEXT#
                                </a>
                            </Trans>
                        </p>
                    )}
                </div>

                {items.map((item: Item): JSX.Element => {
                    const itemProps = item.props;

                    return (
                        <FAQItem
                            key={itemProps.id}
                            id={itemProps.id}
                            isActive={currentOpenItemId === itemProps.id}
                            currentOpenItemId={currentOpenItemId}
                            setCurrentOpenItemId={setCurrentOpenItemId}
                            context={itemProps.context}
                            question={itemProps.question}
                            answer={itemProps.answer}
                            answerTransKey={itemProps.answerTransKey}
                        />
                    );
                })}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default FAQContainer;
