import { memo, ReactElement } from 'react';

import HBIcon from '../../honeybook-ui/HBIcon/HBIcon';

import './list-item.scss';

interface Props {
    text: string | ReactElement;
    icon: string;
    customClass?: string;
}

function ListItem({ text, icon, customClass = '' }: Props): JSX.Element {
    return (
        <li className={`${customClass} list__item`}>
            <span className="list__item-icon-container">
                <HBIcon name={icon} />
            </span>
            <span className="list__item-text-container">{text}</span>
        </li>
    );
}

export default memo(ListItem);
