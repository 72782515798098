import { graphql } from 'gatsby';
import { useTranslation, Trans } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import Layout from '../components/Layout/Layout';

import PressBlogBlock from '../components/FeatureBlocks/PressBlogBlock/PressBlogBlock';
import PressKitBlock from '../components/FeatureBlocks/PressKitBlock/PressKitBlock';
// import PressSpellingBlock from '../components/FeatureBlocks/PressSpellingBlock/PressSpellingBlock';
import PressFeaturedInBlock from '../components/FeatureBlocks/PressFeaturedInBlock/PressFeaturedInBlock';

import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';

import '../styles/press.scss';

const articles = [
    {
        id: 'techCrunch',
        imagePath: 'marketing_site/press_page/logo-tech_2x.png',
        link: 'https://techcrunch.com/2021/11/03/honeybook-doubles-valuation-with-new-round-as-it-helps-independent-workers-get-paid/?guccounter=1'
    },
    {
        id: 'reuters',
        imagePath:
            'marketing_site/press_page/featured_in_logos/reuters-logo.png',
        link: 'https://www.reuters.com/technology/business-software-firm-honeybook-valued-24-bln-after-tiger-global-led-funding-2021-11-03/'
    },
    {
        id: 'forbesWomen',
        imagePath:
            'marketing_site/press_page/featured_in_logos/forbes-women2.png',
        link: 'https://www.forbes.com/sites/geristengel/2021/06/16/focusing-on-values-profits-a-tech-company-serving-solopreneurs/?sh=31248a2728f3'
    },
    {
        id: 'cnbc',
        imagePath: 'marketing_site/press_page/logo-cnbc_2x.png',
        link: 'https://www.cnbc.com/2020/03/17/freelancers-should-do-this-now-to-protect-against-crises-like-covid-19.html'
    },
    {
        id: 'forbes',
        imagePath: 'marketing_site/press_page/logo-forbs_2x.png',
        link: 'https://www.forbes.com/sites/jonyounger/2024/03/06/honeybook-offers-a-master-class-in-growing-a-community-based-business/?sh=7ae3d6405116'
    },
    {
        id: 'ozy',
        imagePath: 'marketing_site/about_page/ozy-logo2.png',
        link: 'https://www.ozy.com/news-and-politics/this-gender-pay-gap-is-being-replaced-by-a-different-gap/277889/'
    },
    {
        id: 'newYorker',
        imagePath: 'marketing_site/press_page/logo-new-yorker_2x.png',
        link: 'https://www.newyorker.com/culture/cultural-comment/the-gig-economy-is-especially-susceptible-to-sexual-harassment'
    }
];

const spellOptions = [
    {
        id: 'HoneyBook',
        icon: 'nx-check',
        customClass: 'correct'
    },
    {
        id: 'Honey Book',
        icon: 'nx-close',
        customClass: 'wrong'
    },
    {
        id: 'Honeybook',
        icon: 'nx-close',
        customClass: 'wrong'
    },
    {
        id: 'HoneyBOOboo',
        icon: 'nx-close',
        customClass: 'wrong'
    }
];

const pressKitUrl = ' https://presskit.honeybook.com/';

const companyNewsLink = 'https://news.honeybook.com/  ';

const primaryPhotos = [
    {
        id: 'honeybook-1',
        imagePath: 'marketing_site/press_page/HoneyBookLogo_White.png',
        customClass: 'white-logo logo',
        download:
            'https://s3.amazonaws.com/honeybook_cdn/assets_system/honeybook_logos/HoneyBookLogo_White.png',
        alt: 'honeybook white logo'
    },
    {
        id: 'honeybook-2',
        imagePath: 'marketing_site/press_page/HoneyBookLogo_Grey.png',
        customClass: 'dark-logo logo',
        download:
            'https://s3.amazonaws.com/honeybook_cdn/assets_system/honeybook_logos/HoneyBookLogo_Grey.png',
        alt: 'honeybook dark logo'
    }
];

const secondaryPhotos = [
    {
        id: '1',
        imagePath: 'marketing_site/press_page/Team_HoneyBook.jpg',
        download:
            'https://s3.amazonaws.com/honeybook_cdn/assets_system/marketing_site/media_kit/Team_HoneyBook.jpg',
        alt: 'the honeybook team'
    },
    {
        id: '2',
        imagePath: 'marketing_site/press_page/One_Office_Week.jpg',
        download:
            'https://s3.amazonaws.com/honeybook_cdn/assets_system/marketing_site/media_kit/One_Office_Week.jpg',
        alt: 'one office week'
    },
    {
        id: '3',
        imagePath: 'marketing_site/press_page/HoneyBook_Founders.jpg',
        download:
            'https://s3.amazonaws.com/honeybook_cdn/assets_system/marketing_site/media_kit/HoneyBook_Founders.jpg',
        alt: 'honeybook founders'
    },
    {
        id: '4',
        imagePath: 'marketing_site/press_page/Office1.jpg',
        download:
            'https://s3.amazonaws.com/honeybook_cdn/assets_system/marketing_site/media_kit/Office1.jpg',
        alt: 'honeybook office'
    },
    {
        id: '5',
        imagePath: 'marketing_site/press_page/Board_Member_Q_A.jpg',
        download:
            'https://s3.amazonaws.com/honeybook_cdn/assets_system/marketing_site/media_kit/Board_Member_Q_A.jpg',
        alt: 'honeybook board members'
    },
    {
        id: '6',
        imagePath: 'marketing_site/press_page/Core_Values.jpg',
        download:
            'https://s3.amazonaws.com/honeybook_cdn/assets_system/marketing_site/media_kit/Core_Values.jpg',
        alt: 'our core values'
    },
    {
        id: '7',
        imagePath: 'marketing_site/press_page/Commando.jpg',
        download:
            'https://s3.amazonaws.com/honeybook_cdn/assets_system/marketing_site/media_kit/Commando.jpg',
        alt: 'commando'
    },
    {
        id: '8',
        imagePath: 'marketing_site/press_page/All_Hands.jpg',
        download:
            'https://s3.amazonaws.com/honeybook_cdn/assets_system/marketing_site/media_kit/All_Hands.jpg',
        alt: 'all hands'
    },
    {
        id: '9',
        imagePath: 'marketing_site/press_page/Member_Day.jpg',
        download:
            'https://s3.amazonaws.com/honeybook_cdn/assets_system/marketing_site/media_kit/Member_Day.jpeg',
        alt: 'members day'
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/press',
    title: 'HoneyBook Press Room',
    description:
        'Our online contracts and agreements templates and software are customized to suit the many needs of our members. Our legal reviewed agreements will help ensure your client interactions are governed responsibly and communitcated effectively with easy online signing.',
    imageURL:
        'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1675367094/marketing_site/paid/OG_image2.png'
};

function PressTemplate() {
    const { t } = useTranslation();

    return (
        <section className="press-page">
            <Layout ogTags={ogTags}>
                <WhiteHero
                    image="marketing_site/press_page/hero-press-img-rebrand-new.png"
                    mobileImage="marketing_site/press_page/hero-press-img-rebrand-new.png"
                    mainTitle={t('pressPage.hero.mainTitle')}
                    description={t('pressPage.hero.description')}
                    titleCustomClass="press-page__hero-text-container"
                />
                <div className="feature-block__wrapper">
                    <PressFeaturedInBlock
                        customClass="recent-press"
                        lightBackground
                        articles={articles}
                        footerText={
                            <Trans
                                i18nKey="pressPage.blocks.featuredIn.footerText"
                                components={ReactHtmlParser(
                                    "#TEXT# <a href='mailto:press@honeybook.com'>#LINK_TEXT#</a> #TEXT#"
                                )}
                            />
                        }
                    />
                    {/* <PressSpellingBlock options={spellOptions} /> */}
                    <PressKitBlock pressKitUrl={pressKitUrl} />
                    <PressBlogBlock companyNewsLink={companyNewsLink} />
                </div>
            </Layout>
        </section>
    );
}

export default PressTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "press"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
