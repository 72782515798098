import { memo, useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import ReactHtmlParser from 'react-html-parser';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './image-text-element-link-grid.scss';

import ImageTextElement from '../../ImageTextElement/ImageTextElement';

import type { MediaFlags } from '../../BlogLink/BlogLink';

interface Section {
    id: string;
    image: string;
    mediaFlags: MediaFlags;
    linkUrl: string;
}

interface Props {
    customClass?: string;
    transKey: string;
    lightBackground?: boolean;
    darkBackground?: boolean;
    onClick?: (id: string) => void;
    imageTextElements: Section[];
}

interface SectionProps {
    section: Section;
    transKey: string;
    onClick?: (id: string) => void;
}

function ImageTextElementLinkGridBlockSection({
    section,
    transKey,
    onClick
}: SectionProps): JSX.Element {
    const { t } = useTranslation();

    const description = useMemo(() => {
        return (
            <Trans
                i18nKey={`${transKey}.${section.id}.body`}
                components={ReactHtmlParser(`${transKey}.${section.id}.body`)}
            />
        );
    }, [section.id, transKey]);

    const mediaFlags = useMemo(() => {
        return {
            crop: 'scale',
            forceImgTag: true,
            ...section.mediaFlags
        };
    }, [section.mediaFlags]);

    const imageTextElementContent = useMemo(() => {
        return (
            <ImageTextElement
                customClass={`image-text-element-link-grid__image image-text-element-link-grid__image--${section.id}`}
                key={section.id}
                title={t(`${transKey}.${section.id}.title`)}
                description={description}
                imagePath={section.image}
                imagePosition="left"
                mediaFlags={mediaFlags}
            />
        );
    }, [description, mediaFlags, section.id, section.image, t, transKey]);

    const handleClick = useCallback(() => {
        if (typeof onClick === 'function') {
            onClick(section.id);
        }
    }, [onClick, section.id]);

    const content = section.linkUrl ? (
        <a
            key={section.id}
            onClick={handleClick}
            className="image-text-element-link-grid__link"
            href={section.linkUrl}
        >
            {imageTextElementContent}
        </a>
    ) : (
        imageTextElementContent
    );

    return (
        <div key={section.id} className="image-text-element-link-grid__block">
            {content}
        </div>
    );
}

function ImageTextElementLinkGridBlock({
    customClass = '',
    transKey,
    lightBackground,
    darkBackground,
    imageTextElements,
    onClick
}: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection
            customClass={`image-text-element-link-grid ${customClass}`}
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent>
                <FeatureBlockText fullWidth primary={t(`${transKey}.title`)} />
                <div className="image-text-element-link-grid__container">
                    {imageTextElements.map(section => {
                        return (
                            <ImageTextElementLinkGridBlockSection
                                key={section.id}
                                section={section}
                                onClick={onClick}
                                transKey={transKey}
                            />
                        );
                    })}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

ImageTextElementLinkGridBlock.propTypes = {
    customClass: PropTypes.string,
    transKey: PropTypes.string,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool,
    onClick: PropTypes.func,
    imageTextElements: PropTypes.arrayOf(PropTypes.object)
};

export default memo(ImageTextElementLinkGridBlock);
