import type { GOOGLE_ONE_TAP_MESSAGES } from './constants';

export interface CredentialResponse {
    credential?: string;
    select_by?:
        | 'auto'
        | 'user'
        | 'user_1tap'
        | 'user_2tap'
        | 'btn'
        | 'btn_confirm'
        | 'brn_add_session'
        | 'btn_confirm_add_session';
    clientId?: string;
}

export interface IdConfiguration {
    client_id: string;
    auto_select?: boolean;
    callback: (handleCredentialResponse: CredentialResponse) => void;
    login_uri?: string;
    native_callback?: Function;
    cancel_on_tap_outside?: boolean;
    prompt_parent_id?: string;
    nonce?: string;
    context?: string;
    state_cookie_domain?: string;
    ux_mode?: 'popup' | 'redirect';
    allowed_parent_origin?: string | string[];
    intermediate_iframe_close_callback?: Function;
}

export type TGoogleOneTapReasons =
    | keyof typeof GOOGLE_ONE_TAP_MESSAGES
    | 'credential_returned';

export type TGoogleOneTapStates = 'dismissed' | 'skipped';

export type TGoogleOneTapStatesHandlers = {
    [moment in TGoogleOneTapStates]: (notification) => void;
};

export interface PromptMomentNotification {
    isDisplayMoment: () => boolean;
    isDisplayed: () => boolean;
    isNotDisplayed: () => boolean;
    getNotDisplayedReason: () => TGoogleOneTapReasons;
    isSkippedMoment: () => boolean;
    getSkippedReason: () => TGoogleOneTapReasons;
    isDismissedMoment: () => boolean;
    getDismissedReason: () => TGoogleOneTapReasons;
    getMomentType: () => TGoogleOneTapStates;
}

export const ONE_TAP_SUCCESS_EVENT_TYPES = {
    load: 'marketing_site_google_one_tap_load',
    display: 'marketing_site_google_one_tap_display',
    one_tap_credential_returned:
        'marketing_site_google_one_tap_credentials_returned_successfully'
} as const;

export type TSuccessEventType = keyof typeof ONE_TAP_SUCCESS_EVENT_TYPES;
