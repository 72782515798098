import { memo } from 'react';
import * as PropTypes from 'prop-types';

import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

import './feature-block-image.scss';

interface Props {
    featureImage: string;

    customClass?: string;

    width?: number;
    height?: number;
    alt?: string;
    x?: number;
    y?: number;
    gravity?: string;
    radius?: string;
    fetchFormat?: string;
    flags?: string;
    quality?: string;
    dpr?: number | string;
    responsive?: boolean;
    autoPlay?: boolean;
    muted?: boolean;
    loop?: boolean;
    controls?: boolean;
    playsInline?: boolean;
    zoom?: number;
    lazyLoad?: boolean;
}

const FeatureBlockImage = ({
    customClass = '',
    featureImage,
    width,
    height,
    alt,
    x,
    y,
    gravity,
    radius,
    fetchFormat,
    flags,
    quality,
    dpr,
    responsive,
    autoPlay,
    muted,
    loop,
    controls,
    playsInline,
    zoom,
    lazyLoad
}: Props): JSX.Element => {
    return (
        <span className={`feature-block__image ${customClass}`}>
            <HBMediaElement
                name={featureImage}
                type="Image"
                forceImgTag
                width={width}
                height={height}
                crop="scale"
                alt={alt}
                x={x}
                y={y}
                gravity={gravity}
                lazyLoad={lazyLoad}
                radius={radius}
                fetchFormat={fetchFormat}
                flags={flags}
                quality={quality}
                dpr={dpr}
                responsive={responsive}
                autoPlay={autoPlay}
                muted={muted}
                loop={loop}
                controls={controls}
                playsInline={playsInline}
                zoom={zoom}
            />
        </span>
    );
};

FeatureBlockImage.propTypes = {
    featureImage: PropTypes.string,
    customClass: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
};

export default memo(FeatureBlockImage);
