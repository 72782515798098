import { memo } from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';

import './comparison-table-cell.scss';

interface Props {
    cellElement?: JSX.Element;
    title?: string | JSX.Element;
    description?: string;
    customClass?: string;
    featureCell?: boolean;
}

function ComparisonTableCell({
    customClass = '',
    cellElement,
    title,
    description,
    featureCell
}: Props): JSX.Element {
    const divClassName = classnames('comparison-table-cell', {
        [`${customClass}`]: Boolean(customClass),
        'comparison-table-cell--feature': featureCell
    });

    const headerClassName = classnames('comparison-table-cell__title', {
        'comparison-table-cell__title--feature': featureCell
    });

    return (
        <div className={divClassName}>
            {cellElement}
            {title && <h3 className={headerClassName}>{title}</h3>}
            {description && (
                <p className="comparison-table-cell__description">
                    {description}
                </p>
            )}
        </div>
    );
}

ComparisonTableCell.propTypes = {
    cellElement: PropTypes.element,
    title: PropTypes.shape({}),
    description: PropTypes.string,
    customClass: PropTypes.string,
    featureCell: PropTypes.bool
};

export default memo(ComparisonTableCell);
