import { memo, ReactNode, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import NavSubMenu from '../NavSubMenu/NavSubMenu';
import './navbar-btn.scss';
import type { MouseEventHandler } from 'react';
import type { NavBarSubItem } from '../NavBar/NavBar';
import { pagesSet } from '../../../pages-list';
import ArrowDown16 from '../../svg/navbar-icons/arrow-down-16.inline.svg';
import { Link } from '@gatsbyjs/reach-router';
import {
    NavigationMenu,
    NavigationMenuItem,
    NavigationMenuTrigger,
    NavigationMenuContent,
    NavigationMenuLink
} from '@radix-ui/react-navigation-menu';

interface Props {
    id: string;
    link?: string;
    subItems?: NavBarSubItem[];
    onClick?: MouseEventHandler<HTMLAnchorElement | HTMLDivElement>;
    onMouseEnter?: MouseEventHandler<HTMLAnchorElement | HTMLDivElement>;
    iconPath?: string;
    icon?: ReactNode;
    customClass?: string;
}

interface CustomNavLinkProps {
    link: string;
    [key: string]: any;
}

export const CustomNavLink = forwardRef<HTMLAnchorElement, CustomNavLinkProps>(
    ({ link, ...props }, ref) =>
        pagesSet.has(link) ? (
            <Link to={link} {...props} ref={ref} />
        ) : (
            <a href={link} {...props} ref={ref} />
        )
);

function NavBarBtn({
    id,
    link,
    subItems,
    onClick,
    onMouseEnter,
    customClass,
    icon
}: Props): JSX.Element {
    const { t } = useTranslation();

    const buttonClass = classnames('navbar-btn', {
        [`${customClass}`]: Boolean(customClass)
    });

    if (id && subItems) {
        return (
            <NavigationMenu>
                <NavigationMenuItem>
                    {id && (
                        <div
                            className={buttonClass}
                            onClick={onClick}
                            onMouseEnter={onMouseEnter}
                            data-item-link={link}
                            role="menuitem"
                            aria-label={id}
                        >
                            <NavigationMenuTrigger className="navbar-btn__radix-trigger">
                                <>
                                    {icon && (
                                        <div className="media-element svg-content">
                                            {icon}
                                        </div>
                                    )}
                                    {!icon && t(`navBarButtons.${[id]}`)}
                                    <span className="icon">
                                        <ArrowDown16 />
                                    </span>
                                </>
                            </NavigationMenuTrigger>

                            <NavigationMenuContent className="navbar-btn__radix-content">
                                <div className="navbar-btn__container">
                                    {subItems && (
                                        <NavSubMenu
                                            customClass={id}
                                            items={subItems}
                                        />
                                    )}
                                </div>
                            </NavigationMenuContent>
                        </div>
                    )}
                </NavigationMenuItem>
            </NavigationMenu>
        );
    }

    // If no dropdown return an anchor link
    // Otherwise we cannot use nested anchor links
    if (typeof link !== 'undefined') {
        return (
            <NavigationMenu>
                <NavigationMenuItem>
                    <NavigationMenuLink asChild>
                        <CustomNavLink
                            link={link}
                            className={buttonClass}
                            onClick={link ? onClick : undefined}
                            onMouseEnter={onMouseEnter}
                        >
                            <>
                                {icon && (
                                    <div className="media-element svg-content">
                                        {icon}
                                    </div>
                                )}
                                {!icon && t(`navBarButtons.${[id]}`)}
                            </>
                        </CustomNavLink>
                    </NavigationMenuLink>
                </NavigationMenuItem>
            </NavigationMenu>
        );
    }

    return <></>;
}

export default memo(NavBarBtn);
