import { memo } from 'react';
import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';

import './image-text-element.scss';

import type { ReactNode, MouseEventHandler } from 'react';

interface Props {
    svg?: ReactNode;
    imagePath?: string;
    imagePosition?: 'top' | 'right' | 'bottom' | 'left';
    mediaFlags?: Record<string, unknown>;
    title?: string;
    description?: string | JSX.Element;
    customClass?: string;
    children?: ReactNode;
    handleClick?: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
}

// Note: At this point, direction doesn't really do anything. This is here in order to remind the next person to work on this component that it should be added more options such as hideTitle, horizontal direction (row) etc.
function ImageTextElement({
    svg,
    imagePath,
    imagePosition = 'top',
    mediaFlags,
    title,
    description,
    customClass = '',
    children,
    handleClick
}: // direction = 'column'
Props): JSX.Element {
    return (
        <div
            tabIndex={0}
            role="button"
            onClick={handleClick}
            className={`image-text-element ${customClass} ${
                imagePosition
                    ? `image-text-element--image-${imagePosition}`
                    : ''
            }`}
        >
            {svg && svg}
            {imagePath && (
                <HBMediaElement
                    name={imagePath}
                    {...mediaFlags}
                    alt={title || ''}
                />
            )}
            <div className="image-text-element__text-container">
                {title && (
                    <h3 className="image-text-element__title">{title}</h3>
                )}
                {description && (
                    <div className="image-text-element__description">
                        {description}
                    </div>
                )}
            </div>
            {children}
        </div>
    );
}

export default memo(ImageTextElement);
