import { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ButtonStyles } from '../honeybook-ui/HBButton/HBButton';
import RegistrationForm from '../RegistrationForm/RegistrationForm';

import List from '../List/List';

import './feature-title-list.scss';

interface Props {
    title?: string | JSX.Element;
    subtitle?: string;
    icon?: string;
    listName: string;
    items: {
        id: string;
    }[];
    customClass?: string;
    withRegistrationForm?: boolean;
    registrationCta?: string;
    registrationSource: string;
    registrationCustomClass?: string;
}

function FeatureTitleList({
    title,
    subtitle,
    listName,
    icon = 'nx-check-16',
    items,
    customClass = '',
    withRegistrationForm,
    registrationCta = 'registrationForm.getStarted',
    registrationSource,
    registrationCustomClass = ''
}: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <section className={`feature-title-list ${customClass}`.trimEnd()}>
            <List
                title={title}
                subtitle={subtitle}
                icon={icon}
                listName={listName}
                items={items}
            />
            {withRegistrationForm && (
                <RegistrationForm
                    source={registrationSource}
                    customClass={`feature-title-list__registration ${registrationCustomClass}`}
                    buttonText={t(registrationCta)}
                    buttonStyle={ButtonStyles.PRIMARY}
                />
            )}
        </section>
    );
}

FeatureTitleList.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    subtitle: PropTypes.string,
    icon: PropTypes.string,
    listName: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    customClass: PropTypes.string,
    withRegistrationForm: PropTypes.bool,
    registrationCta: PropTypes.string,
    registrationSource: PropTypes.string,
    registrationCustomClass: PropTypes.string
};

export default memo(FeatureTitleList);
