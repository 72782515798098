import { useMemo } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import {
    FeatureBlockContent,
    FeatureBlockSection,
    FeatureBlockText
} from '../components/FeatureBlockElements';
import WhiteHero, {
    HERO_POSITIONS
} from '../components/Hero/WhiteHero/WhiteHero';
import Layout from '../components/Layout/Layout';
import ListQuoteFeatureBlocks from '../components/FeatureBlocks/ListQuoteFeatureBlocks/ListQuoteFeatureBlocks';
import TabsBlock from '../components/FeatureBlocks/TabsBlock/TabsBlock';
import CheckboxTabContent from '../components/CheckboxTabContent/CheckboxTabContent';
import CTABlock from '../components/FeatureBlocks/CTABlock/CTABlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';
import type { IBlockContent } from '../types/util-types';

import '../styles/client-portal.scss';

const blockContent: IBlockContent[] = [
    {
        transKey: 'clientPortalPage.blockContent.organize',
        testimonial: {
            memberName: 'Patrick Hellenga',
            avatar: 'marketing_site/client-portal/testimonials/Patrick_Hellenga.jpg'
        },
        images: [
            {
                publicId: 'marketing_site/client-portal/Module_1.png',
                width: 530
            }
        ]
    },
    {
        customClass: 'client-portal__feature-brand',
        transKey: 'clientPortalPage.blockContent.brand',
        testimonial: {
            memberName: 'Ashley Osuagwu',
            avatar: 'marketing_site/client-portal/testimonials/ashley.jpg'
        },
        images: [
            {
                publicId: 'marketing_site/client-portal/Module_2.gif',
                width: 510
            },
            {
                publicId:
                    'marketing_site/client-portal/Module_2_illustration.svg',
                width: 140,
                customClass: 'client-portal__feature-brand__img'
            }
        ]
    },
    {
        customClass: 'client-portal__feature-protect',
        transKey: 'clientPortalPage.blockContent.protect',
        testimonial: {
            memberName: 'Kelly Heck',
            avatar: 'marketing_site/client-portal/testimonials/kelly_heck.jpg'
        },
        images: [
            {
                publicId: 'marketing_site/client-portal/Module_3_mweb.png',
                width: 430,
                customClass: 'client-portal__feature-protect__img'
            }
        ]
    }
];

const faqs = [
    {
        id: 'clientPortalOverview'
    },
    {
        id: 'clientPortalClientAccess',
        answer: '#TEXT# <a href="/online-contract">#LINK_TEXT#</a><a href="https://help.honeybook.com/getting-started/clients-and-creatives/your-clients-honeybook-experience#sharing-your-client-portal">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'clientPortalClientSee'
    },
    {
        id: 'clientPortalAdvantage',
        answer: '#TEXT# <a href="/">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'clientPortalBrand'
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/client-portal-software',
    title: 'Client Portal Software for Small Businesses',
    description:
        'Learn about Honeybook’s client portal software that enables you to review files, send messages and make payments easily.'
};

function ClientPortalSoftwareTemplate(): JSX.Element {
    const { t } = useTranslation();

    const tabsContent = useMemo(
        () => [
            {
                name: t('clientPortalPage.tabsBlock.tab1.title'),
                id: 'meet',
                panel: (
                    <CheckboxTabContent bulletsString="clientPortalPage.tabsBlock.tab1.bullets" />
                )
            },
            {
                name: t('clientPortalPage.tabsBlock.tab2.title'),
                id: 'work',
                panel: (
                    <CheckboxTabContent bulletsString="clientPortalPage.tabsBlock.tab2.bullets" />
                )
            },
            {
                name: t('clientPortalPage.tabsBlock.tab3.title'),
                id: 'impress',
                panel: (
                    <CheckboxTabContent bulletsString="clientPortalPage.tabsBlock.tab3.bullets" />
                )
            }
        ],
        [t]
    );

    return (
        <Layout ogTags={ogTags}>
            <section className="client-portal">
                <WhiteHero
                    image="marketing_site/client-portal/Hero_image.png"
                    mainTitle={t('clientPortalPage.hero.title')}
                    description={t('clientPortalPage.hero.subtitle')}
                    imagePosition={HERO_POSITIONS.BOTTOM}
                    showRegistration
                />

                <FeatureBlockSection
                    lightBackground
                    customClass="client-portal__post-hero-block"
                >
                    <FeatureBlockContent>
                        <FeatureBlockText
                            fullWidth
                            preTitle={t(`clientPortalPage.postHero.title`)}
                            primary={t(`clientPortalPage.postHero.subtitle`)}
                        />
                        <HBMediaElement
                            name="marketing_site/client-portal/illustration.svg"
                            forceImgTag
                        />
                    </FeatureBlockContent>
                </FeatureBlockSection>

                {blockContent.map((block, index) => {
                    const isEven = index % 2 === 1;
                    return (
                        <ListQuoteFeatureBlocks
                            key={block.transKey}
                            registrationSource=""
                            blockContent={block}
                            isEven={isEven}
                            alternateLightBackground
                        />
                    );
                })}

                <CTABlock
                    lightBackground
                    customClass="client-portal__cta-block"
                    primary={t('clientPortalPage.ctaBlock.title')}
                    imageUrl="marketing_site/client-portal/firework-illustration.png"
                    mediaFlags={{ forceImgTag: true }}
                    showNoCcRequired
                />

                <TabsBlock
                    customClass="client-portal__tabs-block"
                    title={t('clientPortalPage.tabsBlock.title')}
                    tabsContent={tabsContent}
                    source="client_portal_software"
                />

                <FAQBlock
                    title={t('clientPortalPage.faqTitle')}
                    faqs={faqs}
                    displayContactLink
                />

                <FreeToTryBlockRebrand />
            </section>
        </Layout>
    );
}

export default ClientPortalSoftwareTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "clientPortal", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
