import { useState, useEffect, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import ImageTextElement from '../../ImageTextElement/ImageTextElement';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';
import HBText from '@honeybook-ui/HBText/HBText';
import Collapse from '../../../vendor/blueprintjs/collapse';

import './feature-value-props-block-new.scss';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import InvoicesIcon from '../../../svg/features/invoices-icon.inline.svg';
import ContractsIcon from '../../../svg/features/contracts-icon.inline.svg';
import AutomationsIcon from '../../../svg/features/automations-icon.inline.svg';
import PaymentsIcon from '../../../svg/features/payments-icon.inline.svg';
import SchedulingIcon from '../../../svg/features/scheduling-icon.inline.svg';
import NavigateLink from '../../Link/NavigateLink';

const boxLinkClass = 'feature-value-props-new__box-link';

const defaultValues = [
    {
        id: 'invoices',
        icon: <InvoicesIcon />,
        imagePath: 'marketing_site/home_page/features_list/v2/Invoice_3x.png',
        link: '/online-invoices'
    },
    {
        id: 'payments',
        icon: <ContractsIcon />,
        imagePath: 'marketing_site/home_page/features_list/v2/Payments_3x.png',
        link: '/online-payment-software'
    },
    {
        id: 'contracts',
        icon: <AutomationsIcon />,
        imagePath: 'marketing_site/home_page/features_list/v2/Contract_3x.png',
        link: '/online-contract'
    },
    {
        id: 'scheduling',
        icon: <PaymentsIcon />,
        imagePath: 'marketing_site/home_page/features_list/v2/Schedule_3x.png',
        link: '/meeting-scheduler'
    },

    {
        id: 'automations',
        icon: <SchedulingIcon />,
        imagePath:
            'marketing_site/home_page/features_list/v2/Automation_3x.png',
        link: '/automations'
    }
];

const FeatureValuePropsBlockNew = ({
    values = defaultValues,
    hideCta,
    title,
    titleTop,
    minimal
}) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const [autoTransition, setAutoTransition] = useState(true);
    const previousSelectedItemIndex = useRef();
    const selectorContainerRef = useRef();
    const timeoutRef = useRef();
    const [selectorDistanceFromTop, setSelectorDistanceFromTop] = useState(0);

    const updateSelectedElement = elementIndex => {
        setSelectedItemIndex(elementIndex);
        previousSelectedItemIndex.current = selectedItemIndex;
        if (breakpoints.largeUp) {
            const selectedElement =
                selectorContainerRef.current.querySelectorAll(
                    `.${boxLinkClass}`
                )[elementIndex];
            setSelectorDistanceFromTop(selectedElement.offsetTop);
        }
    };

    const onTrigger = elementIndex => {
        clearTimeout(timeoutRef.current);
        updateSelectedElement(elementIndex);
        setAutoTransition(false);
    };

    const continueTransition = () => {
        timeoutRef.current = setTimeout(() => {
            setAutoTransition(true);
        }, 150);
    };

    useEffect(() => {
        if (breakpoints.largeUp) {
            const interval = setInterval(() => {
                if (!autoTransition) {
                    return clearInterval(interval);
                }
                if (selectedItemIndex < values.length - 1) {
                    updateSelectedElement(selectedItemIndex + 1);
                } else {
                    updateSelectedElement(0);
                }
            }, 2500);
            return () => clearInterval(interval);
        }
    }, [autoTransition, selectedItemIndex, breakpoints]);

    useEffect(() => {
        return () => clearTimeout(timeoutRef.current);
    }, []);

    const selectedItemIndicator = (
        <div
            className="feature-value-props-new__selector"
            style={{
                transform: `translateY(${selectorDistanceFromTop}px`
            }}
        >
            {values[0].link && (
                <div className="feature-value-props-new__arrow">
                    <HBMediaElement
                        name="marketing_site/arrow-right-24.svg"
                        alt="Right Arrow"
                        forceImgTag
                    />
                </div>
            )}
        </div>
    );

    const titleContent = title ? (
        <HBText
            customClass="feature-value-props-new__title"
            tag="h2"
            type="title-m"
        >
            {title}
        </HBText>
    ) : (
        ''
    );

    const desktopLayout = (
        <div className="feature-value-props-new__container">
            <FeatureBlockContent customClass="feature-value-props-new__text-content">
                {title && !titleTop && titleContent}
                <div className="features-container" ref={selectorContainerRef}>
                    {values[0].link ? (
                        <NavigateLink
                            source="feature_values"
                            link={values[selectedItemIndex].link}
                            onClick={() =>
                                AnalyticsService.trackClick(
                                    AnalyticsEvents.feature_values_block_link,
                                    {
                                        source: values[selectedItemIndex].id
                                    }
                                )
                            }
                        >
                            {selectedItemIndicator}
                        </NavigateLink>
                    ) : (
                        selectedItemIndicator
                    )}
                    {values.map(({ id, link, icon }, index) => {
                        const boxContent = (
                            <div
                                key={id}
                                className={`${boxLinkClass} ${boxLinkClass}--${id}`}
                                onMouseEnter={() => onTrigger(index)}
                                onMouseLeave={continueTransition}
                            >
                                <ImageTextElement
                                    customClass={`feature-value-props-new__box ${
                                        index === selectedItemIndex &&
                                        'feature-value-props-new__box--active'
                                    } ${
                                        selectedItemIndex <
                                            previousSelectedItemIndex.current &&
                                        'feature-value-props-new__box--down'
                                    }`}
                                    imagePosition="left"
                                    svg={icon}
                                    mediaFlags={{ forceImgTag: true }}
                                    title={t(
                                        `featureValueProps.props.${id}.title`
                                    )}
                                    description={t(
                                        `featureValueProps.props.${id}.description`
                                    )}
                                />
                            </div>
                        );
                        return link ? (
                            <NavigateLink
                                key={id}
                                source="feature_values"
                                link={link}
                                onClick={() =>
                                    AnalyticsService.trackClick(
                                        AnalyticsEvents.feature_values_block_link,
                                        {
                                            source: id
                                        }
                                    )
                                }
                            >
                                {boxContent}
                            </NavigateLink>
                        ) : (
                            boxContent
                        );
                    })}
                </div>
            </FeatureBlockContent>
            <FeatureBlockContent customClass="feature-value-props-new__media">
                <div className="feature-value-props-new__media-container">
                    {values.map(({ id, imagePath }, index) => (
                        <Fragment key={id}>
                            <HBMediaElement
                                customClass={`feature-value-props-new__media-img ${
                                    index == selectedItemIndex &&
                                    'feature-value-props-new__media-img--active'
                                }`}
                                key={id}
                                name={imagePath}
                                lazyLoad={false}
                                alt={t(`featureValueProps.props.${id}.title`)}
                            />
                        </Fragment>
                    ))}
                </div>
            </FeatureBlockContent>
        </div>
    );

    const [openAccordionId, setOpenAccordionId] = useState(null);

    const handleAccordionClick = index => {
        AnalyticsService.trackClick(
            AnalyticsEvents.feature_value_accordion_toggle,
            {
                source: values[index].id
            }
        );
        index !== openAccordionId
            ? setOpenAccordionId(index)
            : setOpenAccordionId(null);
    };

    const mobileLayout = (
        <div className="feature-value-props-new">
            {title && !titleTop && titleContent}
            {values.map(({ id, imagePath, link, icon }, index) => (
                <div
                    key={id}
                    id={`feature-value-props-new__value--${index}`}
                    className={`feature-value-props-new__value feature-value-props-new__value--${id} ${
                        index === openAccordionId &&
                        'feature-value-props-new__value--active'
                    } ${link && 'feature-value-props-new__value--link'}`}
                    onClick={() => handleAccordionClick(index)}
                >
                    <HBIcon
                        customClass="feature-value-props-new__value__arrow"
                        name="nx-arrow-down-16"
                    />
                    <span className='class="media-element svg-content feature-value-props-new__value__icon'>
                        {icon}
                    </span>
                    <div className="feature-value-props-new__value__text-container">
                        <h3 className="feature-value-props-new__value__title">
                            {t(`featureValueProps.props.${id}.title`)}
                        </h3>
                        <p className="feature-value-props-new__value__description">
                            {t(`featureValueProps.props.${id}.description`)}
                        </p>
                    </div>
                    <Collapse
                        isOpen={index === openAccordionId}
                        transitionDuration={400}
                    >
                        <div className="feature-value-props-new__value__drawer">
                            <HBMediaElement name={imagePath} />
                            {link && (
                                <NavigateLink
                                    source="feature_values"
                                    link={link}
                                    className="hbui-button hbui-button--primary hbui-button--medium"
                                    onClick={() =>
                                        AnalyticsService.trackClick(
                                            AnalyticsEvents.feature_values_block_link,
                                            {
                                                source: values[index].id
                                            }
                                        )
                                    }
                                >
                                    {t('featureValueProps.buttonText')}{' '}
                                    {t(
                                        `featureValueProps.props.${id}.buttonText`
                                    )}{' '}
                                    <HBIcon name="nx-arrow-right-16" />
                                </NavigateLink>
                            )}
                        </div>
                    </Collapse>
                </div>
            ))}
        </div>
    );

    return (
        <div
            className={`feature-value-props-new ${
                minimal ? 'feature-value-props-new--minimal' : ''
            }`}
            id="feature-value-props-new"
        >
            <FeatureBlockSection halfSize lightBackground>
                {title && titleTop && (
                    <FeatureBlockContent customClass="feature-value-props-new__title-top">
                        {titleContent}
                    </FeatureBlockContent>
                )}
                {breakpoints.largeUp ? desktopLayout : mobileLayout}
                {!hideCta && (
                    <FeatureBlockContent>
                        <RegistrationForm
                            size="large"
                            showSSO
                            source="feature value props block"
                            direction="row"
                            showNoCcRequired
                        />
                    </FeatureBlockContent>
                )}
            </FeatureBlockSection>
        </div>
    );
};

export default FeatureValuePropsBlockNew;
