import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import HBText from '../../honeybook-ui/HBText/HBText';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../svg/cross.inline.svg';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './pricing-banner-block.scss';

interface Props {
    onClose: () => void;
}

const PricingBannerBlock = ({ onClose }: Props) => {
    const { t } = useTranslation();

    const handleClose = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.pricing_update_banner_close
        );
        onClose();
    };

    return (
        <FeatureBlockSection>
            <FeatureBlockContent>
                <div className="pricing-banner-block">
                    <HBText
                        tag="h3"
                        type="body-s"
                        customClass="pricing-banner-block__title"
                    >
                        {t('pricingBanner.title') as string}
                    </HBText>
                    <HBText
                        tag="p"
                        type="body-m"
                        customClass="pricing-banner-block__description"
                    >
                        {t('pricingBanner.description') as string}
                    </HBText>
                    <a
                        className="pricing-banner-block__link"
                        href="//help.honeybook.com/en/articles/10112611"
                    >
                        {t('pricingBanner.buttonText') as string}
                    </a>
                    <CloseIcon
                        className="pricing-banner-block__close"
                        onClick={handleClose}
                        width={16}
                        height={16}
                    />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default PricingBannerBlock;
