import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

import './save-to-photos-button.scss';

const handleClick = () => {
    AnalyticsService.trackClick(AnalyticsEvents.biz_test_download_photo, {});
};

const SaveToPhotosButton = ({ t, imageUrl, buttonText }) => {
    return (
        <a
            className="save-to-photos-button hbui-button hbui-button--medium hbui-button--invisible"
            href={imageUrl}
            onClick={handleClick}
            download
        >
            <HBIcon name="download2-b" />
            {buttonText || t(`general.saveToPhotos`)}
        </a>
    );
};

SaveToPhotosButton.propTypes = {
    t: PropTypes.func,
    imageUrl: PropTypes.string.isRequired,
    buttonText: PropTypes.string
};

export default withTranslation()(SaveToPhotosButton);
