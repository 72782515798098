import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStroke } from 'perfect-freehand';
import HBButton from '../../honeybook-ui/HBButton/HBButton';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import ResetIcon from '../../../svg/reset.inline.svg';
import DownloadIcon from '../../../svg/download.inline.svg';

import './esign-preview-block.scss';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

interface TPoint {
    x: number;
    y: number;
}

const OPTIONS = {
    size: 4,
    thinning: 0,
    smoothing: 0,
    streamline: 0
};

const EsignPreviewBlock = () => {
    const { t } = useTranslation();
    const [paths, setPaths] = useState([] as string[]);
    const [points, setPoints] = useState([] as TPoint[]);

    const stroke = getStroke(points, OPTIONS);

    const svgRef = useRef({} as any);

    const handlePointerDown = ev => {
        ev.target.setPointerCapture(ev.pointerId);
        const { x, y } = getPositionWithinContainer(ev);
        setPoints([{ x, y }]);
    };

    const handlePointerMove = ev => {
        if (ev.buttons !== 1) return;
        const { x, y } = getPositionWithinContainer(ev);
        setPoints([...points, { x, y }]);
    };

    const handlePointerUp = () => {
        const stroke = getStroke(points, OPTIONS);
        const path = getSvgPathFromStroke(stroke);
        setPaths([...paths, path]);
        setPoints([]);
    };

    const getPositionWithinContainer = ev => {
        var rect = svgRef.current.getBoundingClientRect();
        var x = ev.clientX - rect.left;
        var y = ev.clientY - rect.top;
        return { x, y };
    };

    const getSvgPathFromStroke = stroke => {
        if (!stroke.length) return '';
        const d = stroke.reduce(
            (acc, [x0, y0], i, arr) => {
                const [x1, y1] = arr[(i + 1) % arr.length];
                acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
                return acc;
            },
            ['M', ...stroke[0], 'Q']
        );
        d.push('Z');
        return d.join(' ');
    };

    const onClearAll = () => {
        setPoints([]);
        setPaths([]);
        AnalyticsService.trackClick(AnalyticsEvents.esign_clear_all);
    };

    const onDownload = () => {
        const elSvg = svgRef.current;
        if (elSvg) {
            elSvg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
            const svgData = elSvg.outerHTML;
            const preface = '<?xml version="1.0" standalone="no"?>\r\n';
            const svgBlob = new Blob([preface, svgData], {
                type: 'image/svg+xml;charset=utf-8'
            });
            const svgUrl = URL.createObjectURL(svgBlob);
            const downloadLink = document.createElement('a');
            downloadLink.href = svgUrl;
            downloadLink.download = 'eSign.svg';
            downloadLink.click();
            AnalyticsService.trackClick(AnalyticsEvents.esign_download);
        }
    };

    const pointsPath = getSvgPathFromStroke(stroke);

    const SVGContent = () => {
        return (
            <>
                {paths?.map((path, idx) => (
                    <path key={`path-${idx}`} d={path} />
                ))}
                <path d={pointsPath} />
            </>
        );
    };

    return (
        <FeatureBlockSection customClass="esign-preview-block">
            <FeatureBlockContent customClass="esign-preview-block__content">
                <div className="esign-preview-block__title-container">
                    <HBText
                        customClass="esign-preview-block__title"
                        tag="h2"
                        type="title-m"
                    >
                        {t('previewBlock.title')}
                    </HBText>
                    <HBText
                        customClass="esign-preview-block__subtitle"
                        tag="p"
                        type="body-m"
                    >
                        {t('previewBlock.subtitle')}
                    </HBText>
                </div>

                <div className="esign-preview-block__wrapper">
                    <div className="esign-preview-block__preview-container">
                        <HBMediaElement
                            customClass="esign-preview-block__preview-image"
                            name="marketing_site/esign-page/preview-mock.png"
                        />
                        <svg className="esign-preview-block__preview-container__svg esign-preview-block__preview-container__svg--desktop">
                            <SVGContent />
                        </svg>
                        <svg className="esign-preview-block__preview-container__svg esign-preview-block__preview-container__svg--mweb">
                            <SVGContent />
                        </svg>
                    </div>

                    <div className="esign-preview-block__editor-container">
                        <svg
                            className="esign-preview-block__editor-container__svg"
                            onPointerDown={handlePointerDown}
                            onPointerMove={handlePointerMove}
                            onPointerUp={handlePointerUp}
                            style={{ touchAction: 'none' }}
                            ref={svgRef}
                        >
                            <SVGContent />
                        </svg>

                        <button
                            data-tooltip={t('previewBlock.clearBtnText')}
                            data-tooltip-show-mweb={false}
                            className={`esign-preview-block__clear-btn ${
                                !!paths.length
                                    ? 'esign-preview-block__clear-btn--active'
                                    : ''
                            }`}
                            onClick={onClearAll}
                        >
                            <ResetIcon />
                        </button>
                    </div>

                    <div className="esign-preview-block__btn-container">
                        <div className="esign-preview-block__download-btn">
                            <DownloadIcon className="esign-preview-block__download-icon" />
                            <HBButton
                                onClick={onDownload}
                                text={t('previewBlock.downloadBtnText')}
                                buttonStyle="invisible"
                            />
                        </div>

                        <RegistrationForm
                            customClass="esign-preview-block__cta"
                            source="esing-preview-block"
                            buttonStyle="primary"
                            buttonText={t('previewBlock.CTABtnText')}
                            size="medium"
                        />
                    </div>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default EsignPreviewBlock;
