import { useTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockUserQuote
} from '../../FeatureBlockElements';
import FeatureTitleList from '../../FeatureTitleList/FeatureTitleList';
import FeaturesContractBlockStore from './FeaturesAutomationBlockStore';

import './features-automation-block.scss';

const { blockData } = FeaturesContractBlockStore;

const handleAnalytics = () => {
    AnalyticsService.trackClick(
        AnalyticsEvents.lp_features_hub_feature_redirect,
        {
            source: 'automation'
        }
    );
};

const FeaturesContractBlock = () => {
    const { t } = useTranslation();
    const listName = `featuresPage.${blockData.id}.list`;
    const listItems = Object.keys(
        t(listName, {
            returnObjects: true
        })
    ).map(key => ({ id: key }));

    return (
        <FeatureBlockSection customClass="features-automation-block">
            <FeatureBlockContent customClass="features-automation-block__text-container">
                <FeatureTitleList
                    title={t(`featuresPage.${blockData.id}.title`)}
                    subtitle={t(`featuresPage.${blockData.id}.subtitle`)}
                    listName={`featuresPage.${blockData.id}.list`}
                    items={listItems}
                />
                <div className="features-automation-block__link-container">
                    <a
                        onClick={handleAnalytics}
                        className="features-automation-block__link hbui-button hbui-button--medium hbui-button--secondary"
                        href={blockData.link}
                    >
                        {t(`featuresPage.ctaText`)}
                    </a>
                </div>

                <FeatureBlockUserQuote
                    name={blockData.quoteMember}
                    avatar={blockData.quoteAvatar}
                    jobTitle={t(
                        `featuresPage.${blockData.id}.testimonial.company`
                    )}
                    avatarWidth={40}
                    quote={t(`featuresPage.${blockData.id}.testimonial.quote`)}
                    avatarCrop="thumb"
                    avatarGravity="face"
                />
            </FeatureBlockContent>
            <FeatureBlockContent customClass="feature-block-img">
                <HBMediaElement
                    name={blockData.video}
                    type="video"
                    autoPlay
                    muted
                    loop
                    playsInline
                    width={500}
                    customClass="feature-block__slidein"
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default FeaturesContractBlock;
