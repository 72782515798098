import { memo } from 'react';

import './hb-splash.scss';

function HBSplash(): JSX.Element {
    return (
        <div className="hb-splash">
            <div className="root-spinner-container">
                <div className="root-spinner__item1" />
                <div className="root-spinner__item2" />
                <div className="root-spinner__item3" />
                <div className="root-spinner__item4" />
            </div>
        </div>
    );
}

export default memo(HBSplash);
