import UtilsService from '../Services/UtilsService';

function reportToDataDog(
    error: Error | string,
    context: { [key: string]: any }
) {
    const datadog = window?.DD_RUM;

    if (!datadog || !datadog.addError) {
        return;
    }
    datadog.addError(error, context);
}

export function trackError(
    error: Error | string,
    context: { [key: string]: any } = {}
) {
    if (UtilsService.isProduction()) {
        const errorToPass = error instanceof Error ? error : new Error(error);
        reportToDataDog(errorToPass, context);
    } else {
        console.error(error, context);
    }
}
