const FeaturesAutomationBlockStore = {
    blockData: {
        id: 'automation',
        video: 'marketing_site/features-hub/workflows_animation__cropped_',
        quoteMember: 'Allyn Childers',
        quoteAvatar:
            'marketing_site/features-hub/testimonial_headshots/allyn_childers.png',
        link: '/automations'
    }
};

export default FeaturesAutomationBlockStore;
