import { useTranslation } from 'react-i18next';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

import './watch-video-button-new.scss';

const WatchVideoButton = ({ onClick, source }) => {
    const { t } = useTranslation();

    const handleClick = () => {
        if (onClick) {
            AnalyticsService.trackClick(AnalyticsEvents.watch_video_button, {
                source
            });
            onClick();
        }
    };

    return (
        <div className="watch-video-button-new" onClick={handleClick}>
            <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M0 0L18 9L0 18V0Z" />
            </svg>
            {t('watchVideoButton.text')}
        </div>
    );
};

export default WatchVideoButton;
