import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './tips-block.scss';

import NumberTextElement from '../../NumberTextElement/NumberTextElement';

const TipsBlock = ({ t, lightBackground, darkBackground }) => {
    const tips = Object.keys(
        t(`featureBlock.tips.tips`, { returnObjects: true })
    );
    return (
        <FeatureBlockSection
            customClass="tips-block"
            lightBackground={lightBackground ? true : null}
            darkBackground={darkBackground ? true : null}
        >
            <FeatureBlockContent>
                <FeatureBlockText
                    fullWidth
                    primary={t(`featureBlock.tips.title`)}
                    secondary={t(`featureBlock.tips.subtitle`)}
                />

                <div className="tips-block__container">
                    {tips.map((tip, index) => (
                        <NumberTextElement
                            number={index + 1}
                            key={`featureBlock.tips.tips.${tip}`}
                            description={`featureBlock.tips.tips.${tip}.pattern`}
                            descriptionTransKey={`featureBlock.tips.tips.${tip}.content`}
                        />
                    ))}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

TipsBlock.propTypes = {
    t: PropTypes.func.isRequired,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool
};

export default withTranslation()(TipsBlock);
