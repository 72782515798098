import Layout from '../components/Layout/Layout';
import { graphql } from 'gatsby';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackError } from '../utils/error.utils';
import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';
import ApiService from '../Services/ApiService';
import HBButton from '../components/honeybook-ui/HBButton/HBButton';
import HBText from '../components/honeybook-ui/HBText/HBText';
import SignatureIllustration from '../svg/Signature.inline.svg';

import '../styles/email-signature-viewer.scss';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../components/FeatureBlockElements';

const ogTags = {
    url: 'https://www.honeybook.com/email-signature-viewer',
    title: 'Email Signature Generator - Free and Simple to Use',
    description:
        'Try our free email signature generator to easily create an effective email signature for your business. Simply choose a template and add your info.',
    noIndex: true
};

function EmailSignatureViewer() {
    const { t } = useTranslation();
    const [emailSignature, setEmailSignature] = useState('');
    const [isCopyLabelShown, setIsCopyLabelShown] = useState(false);

    const previewRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const id = new URLSearchParams(window.location.search).get('id');
        if (id) {
            ApiService.getEmailSignatureById(id)
                .then(signatureCode => {
                    if (signatureCode) {
                        setEmailSignature(signatureCode);

                        if (previewRef.current) {
                            previewRef.current.innerHTML = signatureCode;
                        }
                    }
                })
                .catch(err => {
                    AnalyticsService.track(
                        AnalyticsEvents.lp_email_signature_fetch_failed
                    );
                    trackError(err, {
                        message: 'failed to fetch email signature'
                    });
                });
        }
    }, []);

    const onCopyCode = () => {
        navigator.clipboard.writeText(emailSignature);
        setIsCopyLabelShown(true);
        setTimeout(() => {
            setIsCopyLabelShown(false);
        }, 3000);
    };

    return (
        <Layout ogTags={ogTags} customClass="email-signature-viewer" noFooter>
            {!!emailSignature ? (
                <>
                    <FeatureBlockSection customClass="email-signature-viewer__hero">
                        <FeatureBlockContent customClass="email-signature-viewer__hero__content">
                            <HBText
                                customClass="email-signature-viewer__title"
                                tag="h1"
                                type="title-l"
                            >
                                {t('emailSignatureViewer.title')}
                            </HBText>
                            <SignatureIllustration className="email-signature-viewer__illustration" />
                        </FeatureBlockContent>
                    </FeatureBlockSection>

                    <FeatureBlockSection customClass="email-signature-viewer__preview-container">
                        <FeatureBlockContent customClass="email-signature-viewer__preview-container__content">
                            <div
                                className="email-signature-viewer__preview"
                                ref={previewRef}
                            ></div>
                        </FeatureBlockContent>
                    </FeatureBlockSection>

                    <FeatureBlockSection customClass="email-signature-viewer__copy-container">
                        <FeatureBlockContent customClass="email-signature-viewer__copy-container__content">
                            <HBText
                                customClass="email-signature-viewer__instructions"
                                tag="p"
                                type="body-m"
                            >
                                {t('emailSignatureViewer.instructions')}
                            </HBText>

                            <div className="email-signature-viewer__html-container">
                                <input
                                    className="email-signature-viewer__html-input"
                                    type="text"
                                    value={emailSignature}
                                    readOnly={true}
                                />
                                <HBButton
                                    customClass="email-signature-viewer__copy-btn"
                                    text={t('emailSignatureViewer.copyBtnText')}
                                    onClick={onCopyCode}
                                />
                                <p
                                    className={`email-signature-viewer__copied email-signature-viewer__copied${
                                        isCopyLabelShown && '--active'
                                    }`}
                                >
                                    {t('emailSignatureViewer.copied')}
                                </p>
                            </div>
                        </FeatureBlockContent>
                    </FeatureBlockSection>
                </>
            ) : (
                <>
                    <FeatureBlockSection customClass="email-signature-viewer__hero email-signature-viewer__hero--no-signature">
                        <FeatureBlockContent customClass="email-signature-viewer__hero__content">
                            <HBText
                                customClass="email-signature-viewer__title"
                                tag="h1"
                                type="title-l"
                            >
                                {t('emailSignatureViewer.title')}
                            </HBText>

                            <HBText
                                customClass="email-signature-viewer__instructions"
                                tag="p"
                                type="body-m"
                            >
                                {t('emailSignatureViewer.noSignatureText')}
                            </HBText>

                            <HBButton
                                customClass="email-signature-viewer__create-new-btn"
                                buttonStyle="primary"
                                text={t(
                                    'emailSignatureViewer.noSignatureCtaText'
                                )}
                                onClick={() => {
                                    window.location.assign('/email-signature');
                                }}
                            />
                            <SignatureIllustration className="email-signature-viewer__illustration" />
                        </FeatureBlockContent>
                    </FeatureBlockSection>
                </>
            )}
            <FeatureBlockSection
                customClass={`email-signature-viewer__footer ${
                    !emailSignature
                        ? 'email-signature-viewer__footer--no-signature'
                        : ''
                }`}
            >
                <FeatureBlockContent>
                    <HBText
                        customClass="email-signature-viewer__footer__text"
                        tag="p"
                        type="body-s"
                    >
                        Copyright © 2023 HoneyBook Inc. All rights reserved.
                    </HBText>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </Layout>
    );
}

export default EmailSignatureViewer;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["emailSignatureGenerator", "common"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
