import { useSignatureTemplateData } from './useSignatureTemplateData';
import {
    SignatureField,
    SignatureImage,
    SignatureJobTitleAndCompany,
    SignatureSocialIcons
} from '../Email-signature-template';

function EmailSignatureTemplateCool() {
    const {
        name,
        imageUrl,
        jobTitle,
        company,
        phone,
        email,
        address,
        website,
        facebookUrl,
        twitterUrl,
        linkedinUrl,
        instagramUrl,
        pinterestUrl,
        youtubeUrl
    } = useSignatureTemplateData();

    const socialUrls = {
        facebookUrl,
        twitterUrl,
        linkedinUrl,
        instagramUrl,
        pinterestUrl,
        youtubeUrl
    };

    return (
        <div className="email-signature-template email-signature-template--cool">
            <SignatureImage imageUrl={imageUrl} />

            <div className="email-signature-template__name-container">
                <SignatureField fieldName="name" fieldValue={name} />

                <div className="email-signature-template__line">
                    <SignatureJobTitleAndCompany
                        jobTitle={jobTitle}
                        company={company}
                        spacer="at"
                    />
                </div>
            </div>

            <SignatureField
                fieldName="phone"
                fieldValue={phone}
                elPrefix={<p>mobile:</p>}
            />

            <SignatureField
                fieldName="email"
                fieldValue={email}
                elPrefix={<p>email:</p>}
            />

            <SignatureField
                fieldName="address"
                fieldValue={address}
                elPrefix={<p>address:</p>}
            />

            <SignatureField
                fieldName="website"
                fieldValue={website}
                href={website}
            />

            <SignatureSocialIcons socialUrls={socialUrls} />
        </div>
    );
}

export default EmailSignatureTemplateCool;
