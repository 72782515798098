import { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import TestimonialCard from '../../TestimonialCard/TestimonialCard';
import StarReviews from '../../StarReviews/StarReviews';
import HBText from '@honeybook-ui/HBText/HBText';

import type { Quote } from '../../../types/testimonial-quote';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './feature-testimonial-carousel-block.scss';

interface Props {
    title?: string;
    testimonials: Quote[];
    customClass?: string;
}

const FeatureTestimonialCarouselBlock = ({
    title,
    testimonials,
    customClass
}: Props) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    return (
        <FeatureBlockSection
            customClass={`feature-testimonial-carousel-block ${customClass}`}
        >
            <FeatureBlockContent customClass="feature-testimonial-carousel-block__content">
                <div className="feature-testimonial-carousel-block__header">
                    <div className="feature-testimonial-carousel-block__text-container">
                        <HBText
                            customClass="feature-testimonial-carousel-block__title"
                            tag="h2"
                            type="title-l"
                        >
                            <Trans
                                t={t}
                                i18nKey={
                                    title
                                        ? title
                                        : 'testimonialCardsBlock.title'
                                }
                            >
                                #TEXT#{' '}
                                <span className="feature-testimonial-carousel-block__highlight">
                                    #TEXT#
                                </span>{' '}
                                #TEXT#
                            </Trans>
                        </HBText>
                        <HBText
                            customClass="feature-testimonial-carousel-block__subtitle"
                            tag="h3"
                            type="body-m"
                        >
                            <Trans
                                t={t}
                                i18nKey="testimonialCardsBlock.subtitle"
                            >
                                #TEXT#{' '}
                                <span className="feature-testimonial-carousel-block__highlight">
                                    #TEXT#
                                </span>{' '}
                                #TEXT#
                            </Trans>
                        </HBText>
                    </div>

                    {breakpoints.mediumUp && (
                        <StarReviews customClass="feature-testimonial-carousel-block__reviews" />
                    )}
                </div>
                <div className="feature-testimonial-carousel-block__testimonials">
                    <Swiper
                        className="mySwiper"
                        slidesPerView={breakpoints.medium ? 1 : 3}
                        initialSlide={1}
                        loop={!breakpoints.mediumUp}
                        navigation={!breakpoints.mediumUp}
                        pagination={!breakpoints.mediumUp}
                        draggable={false}
                        mousewheel={false}
                        autoplay={
                            !breakpoints.mediumUp
                                ? {
                                      delay: 2500,
                                      pauseOnMouseEnter: true
                                  }
                                : false
                        }
                        keyboard={!breakpoints.mediumUp}
                        modules={[
                            Navigation,
                            Pagination,
                            Mousewheel,
                            Keyboard,
                            Autoplay
                        ]}
                    >
                        {testimonials.map(
                            ({
                                transId,
                                profileImage,
                                name,
                                logo,
                                personalInfo
                            }) => {
                                const testimonialType = personalInfo ? (
                                    <TestimonialCard
                                        key={name}
                                        text={t(`${transId}.quote`)}
                                        image={profileImage}
                                        personalInfo={{
                                            name,
                                            job: t(`${transId}.jobTitle`)
                                        }}
                                    />
                                ) : (
                                    <TestimonialCard
                                        key={name}
                                        text={t(`${transId}.quote`)}
                                        name={name}
                                        description={t(`${transId}.jobTitle`)}
                                        image={profileImage}
                                        companyLogo={logo}
                                    />
                                );

                                return (
                                    <SwiperSlide key={transId}>
                                        {testimonialType}
                                    </SwiperSlide>
                                );
                            }
                        )}
                    </Swiper>
                </div>

                {!breakpoints.mediumUp && (
                    <StarReviews customClass="feature-testimonial-carousel-block__reviews" />
                )}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default memo(FeatureTestimonialCarouselBlock);
