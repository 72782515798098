import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';

import PictureAndListBlock from '../components/FeatureBlocks/PictureAndListBlock/PictureAndListBlock';
import FeatureGridBlock from '../components/FeatureBlocks/FeatureGridBlock/FeatureGridBlock';
import ThinkOfSwitchingBlock from '../components/FeatureBlocks/ThinkOfSwitchingBlock/ThinkOfSwitchingBlock';
import TestimonialsContainer from '../components/TestimonialsContainer/TestimonialsContainer';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import ImageTextElementsBlock from '../components/FeatureBlocks/ImageTextElementsBlock/ImageTextElementsBlock';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';
import VerticalTemplatesIntro from '../components/FeatureBlocks/VerticalTemplatesIntro/VerticalTemplatesIntro';
import VerticalTemplatesBlock from '../components/FeatureBlocks/VerticalTemplatesBlock/VerticalTemplatesBlock';
import VerticalEmailTemplatesBlock from '../components/FeatureBlocks/VerticalEmailTemplatesBlock/VerticalEmailTemplatesBlock';

import '../styles/verticals.scss';

const imageTextElements = [
    {
        title: 'EventPlannerPage.imageTextElements.Streamline.title',
        text: 'EventPlannerPage.imageTextElements.Streamline.body',
        imagePath: 'marketing_site/vertical-lps/streamlined_icon.svg',
        altText: 'EventPlannerPage.imageTextElements.Streamline.title',
        mediaFlags: { forceImgTag: true, width: 50 }
    },
    {
        title: 'EventPlannerPage.imageTextElements.brand.title',
        text: 'EventPlannerPage.imageTextElements.brand.body',
        imagePath: 'marketing_site/vertical-lps/showcase_icon.svg',
        altText: 'EventPlannerPage.imageTextElements.brand.title',
        mediaFlags: { forceImgTag: true, width: 50 }
    },
    {
        title: 'EventPlannerPage.imageTextElements.booking.title',
        text: 'EventPlannerPage.imageTextElements.booking.body',
        imagePath: 'marketing_site/vertical-lps/booked_icon.svg',
        altText: 'EventPlannerPage.imageTextElements.booking.title',
        mediaFlags: { forceImgTag: true, width: 50 }
    }
];

const quotes = [
    {
        image: 'marketing_site/event-planner-lp/image2.png',
        text: 'EventPlannerPage.quotes.quote1.text',
        name: 'Katie Mast',
        description: 'EventPlannerPage.quotes.quote1.description'
    },
    {
        image: 'marketing_site/event-planner-lp/image3.png',
        text: 'EventPlannerPage.quotes.quote2.text',
        name: 'Kim Rico',
        description: 'EventPlannerPage.quotes.quote2.description'
    },
    {
        image: 'marketing_site/event-planner-lp/image1.png',
        text: 'EventPlannerPage.quotes.quote3.text',
        name: 'Erin Mcauly',
        description: 'EventPlannerPage.quotes.quote3.description'
    }
];

const templateBlocks = [
    'invoiceTemplates',
    'contractTemplates',
    'brocureTemplates',
    'proposalTemplates',
    'questionnarieTemplates'
];

const templateFeatureImages = {
    invoiceTemplates: {
        name: 'marketing_site/vertical-lps/template-images/invoice_all.png',
        alt: 'Invoice templates'
    },
    brocureTemplates: {
        name: 'marketing_site/vertical-lps/template-images/brochure_all.png',
        alt: 'Brochure templates'
    },
    proposalTemplates: {
        name: 'marketing_site/vertical-lps/template-images/proposal_all.png',
        alt: 'Proposal templates'
    },
    contractTemplates: {
        name: 'marketing_site/vertical-lps/template-images/vertical-groups/Group%203%20-%20Events/Group_56_Copy.png',
        alt: 'Contract templates'
    },
    questionnarieTemplates: {
        name: 'marketing_site/vertical-lps/template-images/group-42_3x.png',
        alt: 'Online Questionnaire'
    }
};

const faqs = [
    {
        id: 'eventHelp',
        answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'eventDifferent'
    },
    {
        id: 'eventOthers'
    },
    {
        id: 'eventQuestionnaire',
        answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/2209068-creating-a-questionnaire-template">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'eventTemplates'
    }
];

const EventPlannerPage = () => {
    const { t } = useTranslation();

    const ogTags = {
        url: 'https://www.honeybook.com/software-for-event-planners',
        title: 'Event Management CRM Software',
        description:
            'Discover why event planners love HoneyBook’s event management CRM including contracts, invoices, payments and more all in one place.'
    };

    const contentKey = 'EventPlannerPage';

    return (
        <div className={`vertical vertical-lp`}>
            <Layout ogTags={ogTags}>
                <WhiteHero
                    image="marketing_site/event-planner-lp/event_planner_hero.png"
                    mainTitle={t('EventPlannerPage.hero.title')}
                    description={t('EventPlannerPage.hero.subtitle')}
                    showRegistration
                    width={800}
                />

                <ImageTextElementsBlock
                    lightBackground
                    customClass="vertical-lp__image-text-block payments-block"
                    imageTextElements={imageTextElements}
                />

                <PictureAndListBlock
                    title="EventPlannerPage.featureTitleList.trackLeads.title"
                    listName="EventPlannerPage.featureTitleList.trackLeads.list"
                    image="marketing_site/event-planner-lp/event_planner_templates_module2.png"
                    imageWidth={540}
                />

                <PictureAndListBlock
                    title="EventPlannerPage.featureTitleList.communicateWithClients.title"
                    listName="EventPlannerPage.featureTitleList.communicateWithClients.list"
                    image="marketing_site/event-planner-lp/event_planner_workspace_module3.png"
                    imageWidth={550}
                />

                <PictureAndListBlock
                    title="EventPlannerPage.featureTitleList.manageYourInquiries.title"
                    listName="EventPlannerPage.featureTitleList.manageYourInquiries.list"
                    image="marketing_site/event-planner-lp/planner_mobile_module_4.png"
                    imageWidth={415}
                />

                <ThinkOfSwitchingBlock
                    title="EventPlannerPage.CTAStrip.title"
                    preTitle="EventPlannerPage.CTAStrip.preTitle"
                />

                <FeatureGridBlock
                    title={t('EventPlannerPage.featureGridTitle')}
                    lightBackground
                />

                <VerticalTemplatesIntro contentKey={contentKey} />

                {templateBlocks.map(templateBlock => (
                    <VerticalTemplatesBlock
                        key={'event-planners-' + templateBlock}
                        contentKey={contentKey}
                        templateBlock={templateBlock}
                        templateFeatureImages={templateFeatureImages}
                    />
                ))}

                <VerticalEmailTemplatesBlock contentKey={contentKey} />

                <TestimonialsContainer
                    customClass="dj-lp__testimonial"
                    quotes={quotes}
                />

                <FAQBlock title={t('EventPlannerPage.faqTitle')} faqs={faqs} />

                <FreeToTryBlockRebrand />
            </Layout>
        </div>
    );
};

export default EventPlannerPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "eventPlanners", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
