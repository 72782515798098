import React from 'react';
/* eslint-disable */
import {
  FeatureBlockSection,
  FeatureBlockContent
} from '../../components/FeatureBlockElements';

import './tab-content.scss';

const PrivacyOldTabContent = () => {
    return (
        <FeatureBlockSection customClass="tos-tab-content tab-content" style={{backgroundColor:"red"}}>
            <FeatureBlockContent fullWidth>
                <div id="privacyPage" className="terms-text">
                    <h5 className="date-revision">
                      Updated March 10st, 2022
                    </h5>

          <div className="terms-paragraph">
            <p>
              This Privacy Policy describes how HoneyBook, Inc.
              (“HoneyBook,” “we”, “us”, or “our”) handles personal
              information that we collect through our website and
              mobile applications (together with the website, the
              “Services”). The Services are designed to help our
              customers manage their businesses (“Businesses”).
            </p>
            <h2 className="terms-title">
              Personal Information We Collect
            </h2>

            <p>
              Information you provide to us. Personal information
              you may provide to us through the Services includes:
            </p>

            <p>
              <b className="terms-marker">
                Identification information:
              </b>{' '}
              including your name, email address, phone number,
              company name, date of birth, signature, and photos
              of your driver’s license and/or passport.
            </p>

            <p>
              <b className="terms-marker">Communications:</b> when
              you contact us with questions, feedback, respond to
              one of our surveys, or otherwise communicate with us
              we may collect the information in such
              communications.
            </p>

            <p>
              <b className="terms-marker">
                Payment and transactional information:
              </b>{' '}
              we collect information needed to process your orders
              or transmit direct deposits to you, including tax
              ID/Employer Identification Number (“EIN”), last four
              digits of your Social Security Number (“SSN”),
              payment card information, bank account number and
              related information, billing information, legal
              business name, doing business as name, business
              address, personal address, date of birth, job title,
              and transaction history.
              <ul className="terms-items">
                <li>
                  <b>Plaid:</b> Honeybook also collects bank
                  account numbers to transmit direct deposits
                  to Businesses. If you elect to link your
                  bank account automatically through the API
                  provided by Plaid Inc. (“Plaid”), HoneyBook
                  will also receive information related to the
                  transactions associated with that account,
                  including the transaction ID, transaction
                  category and type, merchant name, amount,
                  currency, date, and location. By using the
                  Plaid API, you grant HoneyBook and Plaid the
                  right to access and transmit your personal
                  and financial information from the relevant
                  financial institution and you agree that
                  your personal and financial information will
                  be transferred, stored, and processed by
                  Plaid in accordance with{' '}
                  <a href="https://plaid.com/legal/">
                    Plaid Privacy Policy
                  </a>
                  .
                </li>
              </ul>
            </p>

            <p>
              <b className="terms-marker">
                Marketing information:
              </b>{' '}
              such as your preferences for receiving our marketing
              communications, and details about your engagement
              with them.
            </p>

            <p>
              <b className="terms-marker">
                Third party account information:
              </b>{' '}
              if you choose to connect third party accounts to the
              Services, we may collect information associated with
              your third party account such as the name, email
              address, and profile information you provided to the
              relevant third party. In particular you are a
              Business and you opt to import or sync your Google
              account, contacts, iCal or similar accounts or
              services with our Services, we will collect and
              store information such as your contacts' (including
              contacts your services provider(s) or application
              automatically added to your address book when you
              communicated with addresses or numbers not already
              on your list) email address, name, address, website
              URL, phone number and other metadata assigned to a
              contact (collectively, “Contacts’ Information”). We
              obtain this data in order to create profiles,
              suggest existing profiles for you to connect with
              and invite new persons to use the Services.
              <ul className="terms-items">
                <li>
                  <b>Gmail integration:</b> If you integrate
                  your Gmail account with the Services, you
                  will be able to use the Services to send
                  emails (that you draft and send using our
                  email composer) and create calendar events
                  and we will have access to your inbox and
                  calendar (“Gmail Information”).{' '}
                </li>
                <li>
                  <b>Google API Services User Data Policy:</b>{' '}
                  Notwithstanding anything else in this
                  Privacy Policy, HoneyBook’s use of Gmail
                  Information will adhere to the{' '}
                  <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
                    Google API Services User Data Policy
                  </a>
                  , including the limited use requirements. In
                  particular, we:
                  <ol>
                    <li>
                      only use Gmail Information to
                      provide and improve the Services;
                    </li>
                    <li>
                      do not transfer Gmail Information to
                      third parties except as necessary to
                      provide or improve the Services, as
                      required by law, or in connection
                      with a merger, acquisition, or sale
                      of assets where we provide notice to
                      users;
                    </li>
                    <li>
                      do not use the Gmail Information for
                      serving advertisements; and
                    </li>
                    <li>
                      {' '}
                      do not permit humans (other than
                      intended recipients, if applicable)
                      to read Gmail Information, except
                      (a) if we obtain your affirmative
                      consent, (b) as necessary for
                      security purposes or to comply with
                      applicable law or (c) our use is
                      limited to internal operations and
                      the Gmail Information (including
                      derivations) has been aggregated and
                      anonymized.
                    </li>
                  </ol>
                </li>
              </ul>
            </p>
            <p>
              <b className="terms-marker">
                Social Media Information:
              </b>{' '}
              You may be able to create a HoneyBook account
              through a third party social media platform (“SMP”)
              and/or share your HoneyBook experiences on a SMP. To
              take advantage of this feature, we will ask you to
              log into or grant us permission via the relevant
              SMP. If you do so, we may receive information from
              your SMP (such as your contact details or friend
              lists). You may be able to adjust the information we
              receive by changing your SMP settings. Please note
              that the manner in which the SMP uses, stores and
              discloses your information is governed by the
              policies of the SMP and that if you share on an SMP
              you friends, followers, or subscribers will be able
              to view what you share.
            </p>
            <p>
              Location Information, that may be collected by our
              Services and third parties integrated into our
              Services (ex. a rough estimate of location from your
              IP address).
            </p>
            <p>
              <b className="terms-marker">Derived Information:</b>{' '}
              we may infer your preferences for certain products
              based on the personal information we collect about
              you.
            </p>

            <p>
              Third party sources. We may combine personal
              information we receive from you with personal
              information we obtain from other sources, such as:
            </p>
            <ul className="terms-items">
              <li>
                Data providers, such as information services and
                data licensors.
              </li>
              <li>
                Public sources, such as social media platforms.
              </li>
            </ul>

            <p>
              Automatic data collection. We, our service
              providers, and our advertising partners may
              automatically log information about you, your
              computer or mobile device, and your interaction over
              time with HoneyBook, our communications and other
              online services, such as:
            </p>
            <ul className="terms-items">
              <li>
                Device information, such as your computer’s or
                mobile device’s operating system type and
                version, manufacturer and model, browser type,
                screen resolution, RAM and disk size, CPU usage,
                device type (e.g., phone, tablet), IP address,
                unique identifiers (including identifiers used
                for advertising purposes), language settings,
                mobile device carrier, radio/network information
                (e.g., WiFi, LTE, 3G).
              </li>
              <li>
                Online activity information, such as pages or
                screens you viewed, how long you spent on a page
                or screen, the website you visited before
                browsing HoneyBook’s Services, navigation paths
                between pages or screens, information about your
                activity on a page or screen, access times, and
                duration of access, and whether you have opened
                our marketing emails or clicked links within
                them. We also use Google Analytics to monitor
                activity on the Site and to assist with
                advertising. Click here to learn how Google
                Analytics collects and processes data. Online
                activity information, such as pages or screens
                you viewed, how long you spent on a page or
                screen, the website you visited before browsing
                HoneyBook’s Services, navigation paths between
                pages or screens, information about your
                activity on a page or screen, access times, and
                duration of access, and whether you have opened
                our marketing emails or clicked links within
                them. We also use Google Analytics to monitor
                activity on the Site and to assist with
                advertising. Click here to learn how Google
                Analytics collects and processes data.
              </li>
            </ul>
            <p>
              Technologies we use for automated data collection
              include:
            </p>

            <ul className="terms-items">
              <li>
                Cookies, which are text files that websites
                store on a visitor‘s device to uniquely identify
                the visitor’s browser or to store information or
                settings in the browser for the purpose of
                helping you navigate between pages efficiently,
                remembering your preferences, enabling
                functionality, helping us understand user
                activity and patterns, and facilitating online
                advertising. For example, we use cookies to keep
                track of your shopping cart and to provide other
                personalized experiences.
              </li>
              <li>
                Local storage technologies, like HTML5, that
                provide cookie-equivalent functionality but can
                store larger amounts of data, including on your
                device outside of your browser in connection
                with specific applications.
              </li>
              <li>
                Web beacons, also known as pixel tags or clear
                GIFs, which are used to demonstrate that a
                webpage or email was accessed or opened, or that
                certain content was viewed or clicked.
              </li>
            </ul>

            <h2 className="terms-title">
              How We Use Your Personal Information
            </h2>
            <p>We use your personal information for:</p>
            <p>
              <b className="terms-marker">Service delivery:</b>{' '}
              including to:
            </p>
            <ul className="terms-items">
              <li>
                Provide, operate and improve the Services and
                our business of providing an all in one platform
                to manage Businesses;
              </li>
              <li>
                To create, maintain, and authenticate your
                account;
              </li>
              <li>
                Conduct research and analysis relating to
                HoneyBook, our Services, and our business;
              </li>
              <li>
                Transmit your payments (through third party
                vendors) and complete transactions with you;
              </li>
              <li>
                Communicate with you about HoneyBook, including
                by sending surveys, offers, announcements,
                updates, security alerts, and support and
                administrative messages;
              </li>
              <li>
                Understand your needs and interests, and
                personalize your experience with HoneyBook and
                our communications; and
              </li>
              <li>
                Provide support for HoneyBook, and respond to
                your requests, questions and feedback.
              </li>
            </ul>
            <p>
              <b className="terms-marker">
                Research and development:
              </b>{' '}
              We may de-identify personal information, as well as
              information available in Vendor contracts and
              related proposals that you may upload, and use the
              de-identified information for research and
              development, including to analyze and improve the
              Services and promote our business.HoneyBook does not
              use or aggregate gmail information for these
              purposes.
            </p>
            <p>
              <b className="terms-marker">
                Marketing and advertising:
              </b>{' '}
              We and our advertising partners may use your
              personal information for marketing and advertising
              purposes, including:
            </p>
            <ul className="terms-items">
              <li>
                Direct marketing. We or our advertising partners
                may send Businesses and clients of Businesses
                who use our Services direct marketing
                communications as permitted by law, including,
                but not limited to, notifying you of special
                promotions, offers and events via postal mail,
                email, telephone, text message, and other means.
                We do not use Contacts’ Information for direct
                marketing.
              </li>
              <li>
                Interest-based advertising. We engage
                advertising partners, including third party
                advertising companies and social media
                companies, to display ads on the Services and
                other online services. These companies may use
                cookies and similar technologies to collect
                information about your interaction (including
                the data described in the “Automatic data
                collection” section above) over time across the
                Services, our communications, and other online
                services, and use that information to serve
                online ads that they think will interest you.
                This is called interest-based advertising. We
                may also share information about our users with
                these companies to facilitate interest-based
                advertising to those or similar users on other
                online platforms. You can learn more about your
                choices for limiting interest-based advertising
                in the “Online tracking opt out” section below.
              </li>
            </ul>

            <p>
              <b className="terms-marker">Public profiles:</b> We
              may use Contacts’ Information (excluding Gmail
              Information and other information collected from
              Google services such as Gmail, Contacts, and
              Calendar), together with other publicly available
              information, to create public profiles for
              individuals who we think may be interested in our
              Services. Once we have created a profile, we may
              suggest that other users of the Services connect
              with the created profile. If any users take an
              action on the created profile, we will notify the
              profile owner so they may claim their page. If we
              have created a public profile for you, we will
              provide an "opt-out" link at the bottom of the
              profile if you wish to have your profile deleted.
            </p>
            <p>
              <b className="terms-marker">
                Connections and Invitations:
              </b>{' '}
              We may also use Contacts’ Information to suggest
              connections and to enable you to invite others to
              use our Services and connect with you. It is your
              choice whether to invite someone to our Services.
              For clarity, if an invitee accepts the invitation,
              then the invitee shall be subject to the terms and
              conditions of this Privacy Policy and our Terms of
              Service. We may also receive personal information
              about you when others import or sync their Contacts’
              Information.
            </p>
            <p>
              <b className="terms-marker">
                Compliance and protection:
              </b>{' '}
              We may use your personal information to:
              <ul className="terms-items">
                <li>
                  Comply with applicable laws, lawful
                  requests, and legal process, such as to
                  respond to subpoenas or requests from
                  government authorities;
                </li>
                <li>
                  Protect our, your or others’ rights,
                  privacy, safety or property (including by
                  making and defending legal claims);
                </li>
                <li>
                  Audit our internal processes for compliance
                  with legal and contractual requirements and
                  internal policies;
                </li>
                <li>
                  Enforce the terms and conditions that govern
                  HoneyBook and our Services; and
                </li>
                <li>
                  Prevent, identify, investigate and deter
                  fraudulent, harmful, unauthorized, unethical
                  or illegal activity, including cyberattacks
                  and identity theft.
                </li>
              </ul>
            </p>

            <h2 className="terms-title">
              How We Share Your Personal Information
            </h2>
            <p>
              We may share your personal information with the
              following parties and as otherwise described in this
              Privacy Policy or at the time of collection:
            </p>
            <p>
              <b className="terms-marker">Related Companies:</b>{' '}
              We may also share your personal information
              (excluding Gmail Information) with our subsidiaries
              and affiliates for purposes consistent with this
              Privacy Policy.
            </p>
            <p>
              <b className="terms-marker">Service providers:</b>{' '}
              Companies and individuals that provide services on
              our behalf or help us operate HoneyBook, the
              Services, or our business (such as hosting,
              information technology, customer support, email
              delivery, and website analytics services).
            </p>
            <p>
              <b className="terms-marker">
                Advertising partners:
              </b>{' '}
              Third party advertising companies and other third
              parties, including HoneyBook’s parent, affiliates,
              other brands and business partners for commercial
              purposes like the interest-based advertising
              purposes described above.
            </p>
            <p>
              <b className="terms-marker">
                Professional advisors:
              </b>{' '}
              Professional advisors, such as lawyers, auditors,
              bankers and insurers, where necessary in the course
              of the professional services that they render to us.
            </p>
            <p>
              <b className="terms-marker">
                Authorities and others:
              </b>{' '}
              Law enforcement, government authorities, and private
              parties, as we believe in good faith to be necessary
              or appropriate for the compliance and protection
              purposes described above.
            </p>
            <p>
              <b className="terms-marker">
                Business transferees:
              </b>{' '}
              Acquirers and other relevant participants in
              business transactions (or negotiations for such
              transactions) involving a corporate financing,
              divestiture, merger, consolidation, acquisition,
              reorganization, sale or other disposition of all or
              any portion of the business or assets of, or equity
              interests in, HoneyBook (including, in connection
              with a bankruptcy or similar proceedings).
            </p>
            <p>
              Please keep in mind that whenever you voluntarily
              make your personal information available for viewing
              by third parties or the public on or through the
              Services or posting via SMP, that information can be
              seen, collected and used by others. We are not
              responsible for any use of such information by
              others.
            </p>
            <h2 className="terms-title">Your Choices</h2>

            <p>
              Update or correct your information. To keep your
              information accurate, current, and complete, please
              contact us as specified below. We will take
              reasonable steps to update or correct information in
              our possession that you have previously submitted
              via the Services. Please also feel free to contact
              us if you have any questions.
              <br />
              <br />
              Opt out of marketing communications. You may opt out
              of marketing-related communications by following the
              opt out or unsubscribe instructions contained in the
              marketing communication we send you.
            </p>

            <p>
              Online tracking opt out. There are a number of ways
              to opt out of having your online activity and device
              information collected through the Services, which we
              have summarized below:
            </p>
            <ul className="terms-items">
              <li>
                Blocking cookies in your browser. Most browsers
                let you remove or reject cookies, including
                cookies used for interest-based advertising. To
                do this, follow the instructions in your browser
                settings. Many browsers accept cookies by
                default until you change your settings. Click
                here for more information about cookies,
                including how to see what cookies have been set
                on your device and how to manage and delete
                them.
              </li>
              <li>
                Blocking advertising ID use in your mobile
                settings. Your mobile device settings may
                provide functionality to limit use of the
                advertising ID associated with your mobile
                device for interest-based advertising purposes.
              </li>
              <li>
                Using privacy plug-ins or browsers. You can
                block our websites from setting cookies used for
                interest-based ads by using a browser with
                privacy features, like Brave, or installing
                browser plugins like Privacy Badger, Ghostery,
                or uBlock Origin, and configuring them to block
                third party cookies/trackers. You can also opt
                out of Google Analytics by downloading and
                installing the browser plug-in.
              </li>
              <li>
                Platform opt outs. The following advertising
                partners offer opt out features that let you opt
                out of use of your information for
                interest-based advertising:
                <br />
                <ul className="terms-items-inner">
                  <li>Google</li>
                  <li>Facebook</li>
                  <li>Twitter</li>
                </ul>
              </li>
              <li>
                Advertising industry opt out tools. You can also
                use these opt out options to limit use of your
                information for interest-based advertising by
                participating companies:
                <br />
                <ul className="terms-items-inner">
                  <li>
                    Digital Advertising Alliance for
                    Websites
                  </li>
                  <li>
                    Digital Advertising Alliance for Mobile
                    Apps
                  </li>
                  <li>Network Advertising Initiative</li>
                </ul>
              </li>
            </ul>
            <p>
              Note that you will need to opt out on every browser
              and device that you use because these opt out
              mechanisms are specific to the device or browser on
              which they are exercised.
              <br />
              <br />
              Do Not Track. Some Internet browsers may be
              configured to send “Do Not Track” signals to the
              online services that you visit. We currently do not
              respond to "Do Not Track" or similar signals. Learn
              more about "Do Not Track" here.
              <br />
              <br />
              Declining to provide information. We need to collect
              personal information to provide certain services. If
              you do not provide the information we identify as
              required or mandatory, we may not be able to provide
              those services.
            </p>
            <p>
              <b className="terms-marker">
                Other Sites and Services
              </b>
              <br />
              Our Services may contain links to websites and other
              online services operated by third parties. In
              addition, our content may be integrated into web
              pages or other online services that are not
              associated with us. These links and integrations are
              not an endorsement of, or representation that we are
              affiliated with, any third party. We do not control
              websites or online services operated by third
              parties, and we are not responsible for their
              actions.
            </p>
            <h2 className="terms-title">Security</h2>
            <p>
              We employ a number of technical, organizational and
              physical safeguards designed to protect the personal
              information we collect. However, no security
              measures are failsafe and we cannot guarantee the
              security of your personal information. You use the
              Services at your own risk.
            </p>
            <p>
              <b className="terms-marker">
                Processing of Data in the U.S.
              </b>
              <br />
              We are headquartered in the United States and may
              use services providers that operate in other
              countries. Your personal information may therefore
              be processed in the United States or transferred to
              other locations where privacy laws may not be as
              protective as those in your state, province, or
              country.
            </p>
            <h2 className="terms-title">Children</h2>
            <p>
              HoneyBook does not sell products for purchase by
              children under the age of 13. If you are under 13
              years of age and should not be used by such
              children. If we learn that we have collected
              personal information through the services from a
              child under 13 without the consent of the child’s
              parent or guardian, we will delete it.
            </p>

            <h2 className="terms-title">
              Notice to California Users
            </h2>

            <p>
              We are providing this supplemental privacy notice to
              consumers in California, pursuant to the California
              Consumer Privacy Act of 2018 (“CCPA”).
              <br />
              <br />
              Subject to certain exceptions, including those
              applicable to individuals acting as business
              representatives, the CCPA grants California
              residents the following rights.
            </p>

            <p>
              <b className="terms-marker">Information:</b> You can
              request information about how we used and shared
              your personal information through the Services
              during the past 12 months. Our Privacy Policy
              provides this information. We describe the sources
              through which we collect personal information and
              the types of personal information collected in the
              “Personal Information We Collect” section above. We
              describe the purposes for which we use and share
              this information in the “How We Use Your Personal
              Information” section above and the “How We Share
              Your Personal Information” section above.
            </p>
            <p>
              <b className="terms-marker">Access:</b> You can
              request a copy of the personal information that we
              maintain about you.
            </p>
            <p>
              <b className="terms-marker">Deletion:</b> You can
              ask to delete the personal information that we
              maintain about you.
            </p>
            <p>
              <b className="terms-marker">
                Opt Out Of Sale Of Your Personal Information:
              </b>{' '}
              You can instruct businesses that sell your personal
              information to stop doing so – Honeybook, however,
              does not sell your personal information.
            </p>
            <p>
              To exercise these rights, or request an alternative
              form of this Privacy Policy, please contact us. We
              will not treat you differently if you exercise these
              rights.
              <br />
              <br />
              To verify your identity prior to responding to your
              requests, we may ask you to confirm information that
              we have on file about you or your interactions with
              us. Where we ask for additional personal information
              to verify your identity, we will only use it to
              verify your identity or your authority to make the
              request on behalf of another consumer.
            </p>
            <p>
              <b className="terms-marker">Authorized agents:</b>{' '}
              California residents can empower an “authorized
              agent” to submit requests on their behalf.
            </p>
            <h2 className="terms-title">Privacy Shield</h2>

            <p>
              HoneyBook complies with the EU-U.S. and Swiss-U.S.
              Privacy Shield frameworks (“Frameworks”) as set
              forth by the U.S. Department of Commerce regarding
              the processing of personal data transferred from the
              EU and Switzerland to the U.S. (for these purposes,
              reference to the EU also includes the European
              Economic Area countries of Iceland, Liechtenstein
              and Norway). HoneyBook has certified that it adheres
              to the Privacy Shield Principles (described below).
              If there is any conflict between the policies in
              this Privacy Policy and the EU or Swiss Privacy
              Shield Principles, the Privacy Shield Principles
              shall govern. To learn more about the Frameworks and
              to view our certification page, please visit{' '}
              <a href="https://www.privacyshield.gov/">
                https://www.privacyshield.gov/
              </a>
              .
            </p>
            <p>
              General. We rely on our Privacy Shield certification
              to transfer personal information that we receive
              from the EU and Switzerland to HoneyBook in the U.S.
              and we process such personal information in
              accordance with the Privacy Shield Principles of
              Notice, Choice, Accountability for Onward Transfer,
              Security, Data Integrity and Purpose Limitation,
              Access, and Recourse, Enforcement and Liability
              (“Privacy Shield Principles”), as described below.
            </p>
            <p>
              Notice And Choice. This Privacy Policy provides
              notice of the personal information collected and
              transferred under the Privacy Shield and the choice
              that you have with respect to such personal
              information. It also provides information about
              other Privacy Shield Principles that are set forth
              below.
            </p>
            <p>
              Accountability for Onward Transfers. We may be
              accountable for the personal information we receive
              under the Privacy Shield that we may transfer to
              third-party service providers. If we are and our
              third party service providers process personal
              information in a manner inconsistent with the
              Privacy Shield Principles, we are responsible and
              liable for the harm caused, unless we prove that we
              are not responsible for the event giving rise to the
              damage.
            </p>
            <p>
              Security. We maintain security measures to protect
              personal information as described in the “Security”
              section of this Privacy Policy.
            </p>
            <p>
              Data Integrity and Purpose Limitation. We take
              reasonable steps to ensure that personal information
              is reliable for its intended use, and that it is
              accurate, complete and current for as long as we
              retain it. We will retain the data as long as
              necessary for the following purposes: delivering the
              Services, engaging in customer service, complying
              with legal obligations, auditing, performing
              security and fraud prevention, responding to legal
              and regulatory inquiries, and preserving or
              defending our legal rights or those of other users
              or third parties.
            </p>
            <p>
              Access. European Union users have certain rights to
              access, correct, amend, or delete personal
              information where it is inaccurate, or has been
              processed in violation of the Privacy Shield
              Principles. Please see the “European Union Clients”
              section above for more information on the rights of
              users in the EU (and, to the extent applicable,
              users in Switzerland).
            </p>
            <p>
              Recourse, Enforcement, Liability. In compliance with
              the Privacy Shield Principles, HoneyBook commits to
              resolve complaints about our processing of your
              personal information. European Union and Swiss users
              with inquiries or complaints regarding this Private
              Shield Policy should first contact HoneyBook as
              follows:
            </p>
            <p>
              • Email:{' '}
              <a href="mailto:concierge@honeybook.com">
                concierge@honeybook.com
              </a>
              • Attention: Privacy Shield • Postal Mail: 539
              Bryant St., Suite 200, San Francisco, CA 94107
            </p>
            <p>
              We must respond to such inquiries or complaints
              within 45 days.
            </p>
            <p>
              We have further committed to refer unresolved
              Privacy Shield complaints to an alternative dispute
              resolution provider. If you have an unresolved
              privacy or data use concern that we have not
              addressed satisfactorily, please contact our
              U.S.-based third party dispute resolution provider,
              International Centre for Dispute Resolution
              ("ICDR"), operated by the American Arbitration
              Association ("AAA") (free of charge) at{' '}
              <a href="http://go.adr.org/privacyshield.html">
                http://go.adr.org/privacyshield.html
              </a>
              . HoneyBook will cooperate with ICDR pursuant to the
              ICDR rules, available on the ICDR’s website at{' '}
              <a href="https://www.icdr.org/rules_forms_fees">
                https://www.icdr.org/rules_forms_fees
              </a>
            </p>
            <p>
              If your complaint is not resolved through these
              channels, under certain conditions a binding
              arbitration option may be available before a Privacy
              Shield Panel. For additional information, please
              visit:{' '}
              <a href="https://www.privacyshield.gov/article?id=ANNEX-I-introduction">
                https://www.privacyshield.gov/article?id=ANNEX-I-introduction
              </a>
              .
            </p>
            <p>
              We are subject to the investigatory and enforcement
              powers of the Federal Trade Commission with respect
              to personal information received or transferred
              pursuant to the Frameworks.
            </p>

            <h2 className="terms-title">
              Changes to This Privacy Policy
            </h2>

            <p>
              We reserve the right to modify this Privacy Policy
              at any time. If we make material changes to this
              Privacy Policy, we will notify you by updating the
              date of this Privacy Policy and posting it on our
              website and/or other Services.
            </p>

            <h2 className="terms-title">How to Contact Us</h2>

            <p>
              You can reach HoneyBook by email at{' '}
              <a href="mailto:concierge@honeybook.com">
                concierge@honeybook.com
              </a>{' '}
              or by writing to:
              <br />
              HoneyBook, Inc. Attention: Privacy
            </p>
            <p>
              539 Bryant St, Suite 200, San Francisco, CA 94107.
            </p>
          </div>
        </div>
      </FeatureBlockContent>
    </FeatureBlockSection>
  );
};
/* eslint-enable */

export default PrivacyOldTabContent;
