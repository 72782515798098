import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import TestimonialSlide from './TestimonialSlide';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './testimonial-carousel.scss';

const TestimonialCarousel = ({ transKey, testimonials }) => {
    const { t } = useTranslation();
    return (
        <Carousel
            className="testimonial-carousel"
            showArrows
            showStatus={false}
            useKeyboardArrows
            autoPlay
            infiniteLoop
            showThumbs={false}
        >
            {testimonials.map(testimonial => (
                <TestimonialSlide
                    key={testimonial.id}
                    name={t(`${transKey}.${testimonial.id}.name`)}
                    title={t(`${transKey}.${testimonial.id}.title`)}
                    testimonial={t(`${transKey}.${testimonial.id}.testimonial`)}
                    image={testimonial.image}
                />
            ))}
        </Carousel>
    );
};

TestimonialCarousel.propTypes = {
    transKey: PropTypes.string,
    testimonials: PropTypes.arrayOf(PropTypes.shape({}))
};

export default TestimonialCarousel;
