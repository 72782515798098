import CookiesService from './CookiesService';
import { STATSIG_ID_COOKIE } from '../../static/ab-test-shared-constants';
import { loadStatsigSDK } from '../utils/statsig-sdk-loader';

class StatsigService {
    private isSDKLoaded = false;
    private isInitialized = false;

    private async _initializeStatsig() {
        const statsigSdkKey = process.env.GATSBY_STATSIG_SDK_KEY;

        if (!statsigSdkKey) {
            return;
        }

        const stableID = CookiesService.getCookie(STATSIG_ID_COOKIE);

        if (!window.statsig) {
            return;
        }

        await window.statsig.initialize(
            statsigSdkKey,
            {
                customIDs: {
                    stableID
                },
                custom: {
                    requestUrl: window.location.href
                }
            },
            {
                environment: {
                    tier: process.env.GATSBY_env
                },
                overrideStableID: stableID
            }
        );

        this.isInitialized = true;
    }

    async initializeStatsig() {
        if (!this.isSDKLoaded) {
            await loadStatsigSDK();
            this.isSDKLoaded = true;
        }

        if (this.isInitialized) {
            return;
        }

        return this._initializeStatsig();
    }

    async manuallyLogExperimentExposure(testName: string) {
        if (!this.isInitialized) {
            await this.initializeStatsig();
        }

        window.statsig?.manuallyLogExperimentExposure(testName);
    }
}

export default new StatsigService();
