import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import HBText from '../../honeybook-ui/HBText/HBText';
import StarsIcon from '../../../svg/stars.inline.svg';
import IntroducingAiTab from './IntroducingAiTab';
import TabsBlock from '../TabsBlock/TabsBlock';
import type { TTabsContent } from '../../../types/util-types';

import './introducing-ai-block.scss';

export interface TAiPanelData {
    videoId?: string;
    imgUrl?: string;
    transKey: string;
}

const PANELS_DATA: TAiPanelData[] = [
    {
        videoId: '901464164',
        transKey: 'composer'
    },
    {
        videoId: '901464149',
        transKey: 'leads'
    },
    {
        imgUrl: 'marketing_site/introducing-ai/ai-image-studio_2x.png',
        transKey: 'studio'
    }
];

const TABS_CONTENT: TTabsContent[] = [
    {
        name: 'AI composer',
        id: 'composer',
        panel: <IntroducingAiTab {...PANELS_DATA[0]} />
    },
    {
        name: 'Priority leads',
        id: 'leads',
        panel: <IntroducingAiTab {...PANELS_DATA[1]} />
    },
    {
        name: 'AI image studio',
        id: 'studio',
        panel: <IntroducingAiTab {...PANELS_DATA[2]} />
    }
];

const IntroducingAiBlock = () => {
    const { t } = useTranslation();
    return (
        <FeatureBlockSection customClass="introducing-ai-block">
            <FeatureBlockContent customClass="introducing-ai-block__content">
                <div className="introducing-ai-block__title-container">
                    <HBText
                        customClass="introducing-ai-block__title"
                        tag="h2"
                        type="title-l"
                    >
                        {t('introducingAiBlock.title')}
                    </HBText>
                    <IntroducingAIBadge />
                </div>
                <HBText
                    customClass="introducing-ai-block__subtitle"
                    tag="h3"
                    type="body-xl"
                >
                    {t('introducingAiBlock.subtitle')}
                </HBText>

                <TabsBlock
                    customClass="introducing-ai-block__tabs-container"
                    tabsContent={TABS_CONTENT}
                    source="introducing-ai-block"
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default IntroducingAiBlock;

const IntroducingAIBadge = () => {
    return (
        <div className="introducing-ai-block__ai-badge">
            <div className="introducing-ai-block__ai-badge__wrapper">
                <StarsIcon className="introducing-ai-block__ai-badge__stars" />
                <span className="introducing-ai-block__ai-badge__text">AI</span>
            </div>
        </div>
    );
};
