import * as PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './invoice-generator-types-block.scss';

import InvoiceGeneratorTypesStore from './InvoiceGeneratorTypesStore';

const InvoiceGeneratorTypesBlock = ({
    contentKey,
    lightBackground,
    darkBackground
}) => {
    const { t } = useTranslation();
    const { list } = InvoiceGeneratorTypesStore;
    const { imagePath, imageAlt } = InvoiceGeneratorTypesStore[contentKey];
    return (
        <FeatureBlockSection
            customClass="invoice-generator-types"
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent>
                <FeatureBlockText
                    fullWidth
                    primary={t(
                        `invoiceGeneratorPages.${contentKey}.invoiceTypes.title`
                    )}
                    bodyText={t(
                        `invoiceGeneratorPages.${contentKey}.invoiceTypes.body`
                    )}
                />
                <HBMediaElement
                    name={imagePath}
                    crop="scale"
                    width={1500}
                    alt={
                        imageAlt ||
                        t(
                            `invoiceGeneratorPages.${contentKey}.invoiceTypes.title`
                        )
                    }
                />
                <ul className="invoice-generator-types__list">
                    {list.map(listItem => {
                        return (
                            <li
                                key={listItem}
                                className="invoice-generator-types__list-item"
                            >
                                <Trans
                                    i18nKey={`invoiceGeneratorPages.${contentKey}.invoiceTypes.types.${listItem}`}
                                />
                            </li>
                        );
                    })}
                </ul>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

InvoiceGeneratorTypesBlock.propTypes = {
    contentKey: PropTypes.string,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool
};

export default InvoiceGeneratorTypesBlock;
