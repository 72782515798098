const FeaturesAllInOneBlockStore = {
    blockData: {
        id: 'allInOne',
        image: 'marketing_site/features_page/all-in-one-rebrand.png',
        quoteMember: 'Hacker Medias',
        quoteAvatar:
            'https://res.cloudinary.com/honeybook/image/upload/c_thumb,g_face,f_auto,q_auto,w_100/marketing_site/testimonial-avatars/Kristal.png',
        link: '/all-in-one-business-platform'
    }
};

export default FeaturesAllInOneBlockStore;
