import { memo } from 'react';
import PropTypes from 'prop-types';

import './speech-bubble.scss';

const SpeechBubble = ({ text, quotes, rightSide }) => (
    <div
        className={`speech-bubble ${quotes ? 'speech-bubble--quotes' : ''} ${
            rightSide ? 'speech-bubble--right' : ''
        }`}
    >
        <p className="speech-bubble__text">{text}</p>
    </div>
);

SpeechBubble.propTypes = {
    text: PropTypes.string,
    quotes: PropTypes.bool,
    rightSide: PropTypes.bool
};

export default memo(SpeechBubble);
