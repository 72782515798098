const FAQStore = {
    busy: {
        id: 'busy',
        answer: '#TEXT# <a href="/free-account-migration">#LINK_TEXT#</a>#TEXT#'
    },
    tutorials: {
        id: 'tutorials',
        answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg">#LINK_TEXT#</a> #TEXT# <a href="/product-education">#LINK_TEXT#</a> #TEXT#'
    },
    HBCost: {
        id: 'HBCost',
        answer: '#TEXT# <a href="/pricing">#LINK_TEXT#</a>#TEXT#'
    },
    marketersHelp: {
        id: 'marketersHelp',
        answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ">#LINK_TEXT#</a> #TEXT#'
    },
    PRHelp: {
        id: 'PRHelp',
        answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ">#LINK_TEXT#</a> #TEXT#'
    },
    marketingOthers: {
        id: 'marketingOthers'
    },
    marketingTemplates: {
        id: 'marketingTemplates'
    },
    marketingOtherTemplates: {
        id: 'marketingOtherTemplates'
    },
    barberHelp: {
        id: 'barberHelp',
        answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ">#LINK_TEXT#</a> #TEXT#'
    },
    barberOthers: {}
};

export default FAQStore;
