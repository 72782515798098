import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import HBMediaElement from '../components/honeybook-ui/HBMediaElement/HBMediaElement';
import HorizontalHero from '../components/Hero/HorizontalHero/HorizontalHero';
import ListQuoteFeatureBlocks from '../components/FeatureBlocks/ListQuoteFeatureBlocks/ListQuoteFeatureBlocks';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';
import {
    FeatureBlockContent,
    FeatureBlockSection,
    FeatureBlockText
} from '../components/FeatureBlockElements';
import TimeManagementBlock from '../components/FeatureBlocks/TimeManagementBlock/TimeManagementBlock';
import type { IBlockContent, TImageBlock, TOGTags } from '../types/util-types';
import '../styles/generic-industry-contract.scss';

type TIndustry =
    | 'cinematographers'
    | 'videographers'
    | 'weddingPlanners'
    | 'photography'
    | 'designer';
type TBlockTypes = 'readyToGo' | 'automatic' | 'seamless' | 'track';

interface Props {
    pageContext: {
        contentKey: TIndustry;
    };
}

type THeroImages = {
    desktopImage: string;
    mobileImage: string;
};

type TImagesPerBlock = {
    [industry in TIndustry]: {
        [block in TBlockTypes]: TImageBlock[];
    };
};

type TDataPerIndustry = {
    [industry in TIndustry]: {
        ogTags: TOGTags;
        heroImages: THeroImages;
        blocksContent: IBlockContent[];
    };
};

const heroImagesForDesigners: THeroImages = {
    desktopImage: 'marketing_site/designer-contract/hero-image-all.png',
    mobileImage: 'marketing_site/designer-contract/mweb-hero-all.png'
};

const heroImagesForPhotographers: THeroImages = {
    desktopImage: 'marketing_site/photography-contract/hero3.jpg',
    mobileImage: 'marketing_site/photography-contract/hero-mweb.png'
};

const imagesPerBlockPerIndustry: TImagesPerBlock = {
    cinematographers: {
        readyToGo: [
            {
                publicId: 'marketing_site/photography-contract/sec1.png'
            }
        ],
        automatic: [
            {
                publicId: 'marketing_site/photography-contract/sec2.png'
            }
        ],
        seamless: [
            {
                publicId: 'marketing_site/photography-contract/sec3.png',
                width: 550
            }
        ],
        track: [
            {
                publicId: 'marketing_site/photography-contract/sec4.png',
                width: 550
            }
        ]
    },
    photography: {
        readyToGo: [
            {
                publicId: 'marketing_site/photography-contract/sec1.png'
            }
        ],
        automatic: [
            {
                publicId: 'marketing_site/photography-contract/sec2.png'
            }
        ],
        seamless: [
            {
                publicId: 'marketing_site/photography-contract/sec3.png'
            }
        ],
        track: [
            {
                publicId: 'marketing_site/photography-contract/sec4.png'
            }
        ]
    },
    videographers: {
        readyToGo: [
            {
                publicId: 'marketing_site/designer-contract/Sec01-img-all.png'
            }
        ],
        automatic: [
            {
                publicId: 'marketing_site/designer-contract/Sec02-img-all.png'
            }
        ],
        seamless: [
            {
                publicId: 'marketing_site/designer-contract/Sec03-img.png'
            }
        ],
        track: [
            {
                publicId: 'marketing_site/designer-contract/Sec04-imgall_2x.png'
            }
        ]
    },
    weddingPlanners: {
        readyToGo: [
            {
                publicId: 'marketing_site/designer-contract/Sec01-img-all.png'
            }
        ],
        automatic: [
            {
                publicId: 'marketing_site/designer-contract/Sec02-img-all.png'
            }
        ],
        seamless: [
            {
                publicId: 'marketing_site/designer-contract/Sec03-img.png'
            }
        ],
        track: [
            {
                publicId: 'marketing_site/designer-contract/Sec04-imgall_2x.png'
            }
        ]
    },
    designer: {
        readyToGo: [
            {
                publicId: 'marketing_site/designer-contract/Sec01-img-all.png'
            }
        ],
        automatic: [
            {
                publicId: 'marketing_site/designer-contract/Sec02-img-all.png'
            }
        ],
        seamless: [
            {
                publicId: 'marketing_site/designer-contract/Sec03-img.png'
            }
        ],
        track: [
            {
                publicId: 'marketing_site/designer-contract/Sec04-imgall_2x.png'
            }
        ]
    }
};

const ContractsPerIndustry = ({
    pageContext: { contentKey: industry }
}: Props) => {
    const { t } = useTranslation();

    const blocksContent: IBlockContent[] = [
        {
            id: 'readyToGo',
            transKey: `VerticalLayoutTemplate.${industry}.blockContent.readyToGo`,
            images: imagesPerBlockPerIndustry[industry].readyToGo
        },
        {
            id: 'automatic',
            customClass: 'generic-industry-contract__feature-automatic',
            transKey: `VerticalLayoutTemplate.${industry}.blockContent.automatic`,
            images: imagesPerBlockPerIndustry[industry].automatic
        },
        {
            id: 'seamless',
            customClass: 'generic-industry-contract__feature-seamless',
            transKey: `VerticalLayoutTemplate.${industry}.blockContent.seamless`,
            images: imagesPerBlockPerIndustry[industry].seamless
        },
        {
            id: 'track',
            customClass: 'generic-industry-contract__feature-track',
            transKey: `VerticalLayoutTemplate.${industry}.blockContent.track`,
            images: imagesPerBlockPerIndustry[industry].track
        }
    ];

    const dataPerIndustry: TDataPerIndustry = {
        cinematographers: {
            ogTags: {
                url: 'https://www.honeybook.com/contracts-for-cinematographers',
                title: 'Contracts for Cinematographers',
                description:
                    'Access online contract templates for cinematographers to easily create, edit, and send ready-to-sign contacts to your clients. Try HoneyBook free for 7 days.'
            },
            heroImages: heroImagesForPhotographers,
            blocksContent
        },
        videographers: {
            ogTags: {
                url: 'https://www.honeybook.com/contracts-for-videographers',
                title: 'Contracts for Videographers',
                description:
                    'Access online contract templates for cinematographers to easily create, edit, and send ready-to-sign contacts to your clients. Try HoneyBook free for 7 days.'
            },
            heroImages: heroImagesForDesigners,
            blocksContent
        },
        weddingPlanners: {
            ogTags: {
                url: 'https://www.honeybook.com/contracts-for-event-and-wedding-planners',
                title: 'Contracts for Event & Wedding Planners',
                description:
                    'Access online contract templates for event and wedding planners to easily create, edit, and send ready-to-sign contacts to your clients. Try HoneyBook free for 7 days.'
            },
            heroImages: heroImagesForDesigners,
            blocksContent
        },
        designer: {
            ogTags: {
                url: 'https://www.honeybook.com/designer-contract-template',
                title: 'Design Contracts and Agreement Templates',
                description:
                    'Looking for a freelance contract template or consulting contract template? Customize and sign them online with HoneyBook for free.'
            },
            heroImages: heroImagesForDesigners,
            blocksContent
        },
        photography: {
            ogTags: {
                url: 'https://www.honeybook.com/photography-contract-templates',
                title: `Photography Contracts and Agreement Templates Free`,
                description: `Photography contracts and agreement templates (free) make it easy to book your clients faster and with less legwork. Protect your business with HoneyBook.`
            },
            heroImages: heroImagesForPhotographers,
            blocksContent
        }
    };

    return (
        <section className="generic-industry-contract">
            <Layout ogTags={dataPerIndustry[industry].ogTags}>
                <HorizontalHero
                    mainTitle={t(
                        `VerticalLayoutTemplate.${industry}.hero.title`
                    )}
                    description={t(
                        `VerticalLayoutTemplate.${industry}.hero.subtitle`
                    )}
                    image={dataPerIndustry[industry].heroImages.desktopImage}
                    mobileImage={
                        dataPerIndustry[industry].heroImages.mobileImage
                    }
                    showRegistration
                />
                <FeatureBlockSection
                    lightBackground
                    customClass="generic-industry-contract__post-hero-block"
                >
                    <FeatureBlockContent>
                        <FeatureBlockText
                            fullWidth
                            preTitle={t(
                                `VerticalLayoutTemplate.generic.postHero.title`
                            )}
                            primary={t(
                                `VerticalLayoutTemplate.generic.postHero.subtitle`
                            )}
                        />
                        <HBMediaElement
                            name="marketing_site/photography-contract/contract-illu.svg"
                            forceImgTag
                            alt="photography contract"
                        />
                    </FeatureBlockContent>
                </FeatureBlockSection>
                {dataPerIndustry[industry].blocksContent.map((block, index) => {
                    const isEven = index % 2 === 1;

                    return (
                        <ListQuoteFeatureBlocks
                            registrationSource={`contract feature block - ${block.id}`}
                            key={block.id}
                            blockContent={block}
                            isEven={isEven}
                            alternateLightBackground
                            withRegistrationForm
                        />
                    );
                })}
                <TimeManagementBlock />
                <FreeToTryBlockRebrand />
            </Layout>
        </section>
    );
};

export default ContractsPerIndustry;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "contractsPerIndustry"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
