import { memo } from 'react';
import * as PropTypes from 'prop-types';

import './hero-title.scss';

interface Props {
    customClass?: string;
    preTitle?: string;
    title?: string | React.ReactElement;
    subtitle?: string | React.ReactElement;
}

function HeroTitle({
    customClass = '',
    title,
    subtitle,
    preTitle
}: Props): JSX.Element {
    return (
        <div className={`hero__text-container ${customClass}`.trimEnd()}>
            {preTitle && (
                <h3 className="hero__text-container__pre-title">{preTitle}</h3>
            )}
            {title && <h1 className="hero__text-container__title">{title}</h1>}
            {subtitle && (
                <h2 className="hero__text-container__subtitle">{subtitle}</h2>
            )}
        </div>
    );
}

HeroTitle.propTypes = {
    customClass: PropTypes.string,
    preTitle: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default memo(HeroTitle);
