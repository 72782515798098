import { memo, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';

import HBQuote from '../../HBQuote/HBQuote';
import VideoWithModal from '../../VideoWithModal/VideoWithModal';

import './coaching-testimonial.scss';

import type { Bio } from '../CoachingHero/CoachingHero';

interface Props {
    bio: Bio;
    customClass?: string;
}

function CoachingTestimonial({ bio, customClass }: Props): JSX.Element {
    const { t } = useTranslation();

    const context = useMemo(
        () => ({
            source: 'coaching/photography',
            variant: bio.id
        }),
        [bio.id]
    );

    return (
        <FeatureBlockSection
            customClass={`coaching-testimonial video-quote-block ${customClass}`}
        >
            <FeatureBlockContent>
                <div className="coaching-testimonial__container">
                    <FeatureBlockText
                        fullWidth
                        primary={t(
                            `coaching.photography.${bio.id}.testimonialTitle`
                        )}
                    />

                    <HBQuote customClass="coaching-testimonial__quote">
                        {t(`coaching.photography.${bio.id}.testimonial`)}
                    </HBQuote>

                    <a
                        className="coaching-testimonial__website-link"
                        href={bio.websiteUrl}
                    >
                        <HBMediaElement
                            name={bio.logoImgUrl}
                            customClass="coaching-testimonial__logo"
                            alt={t(
                                `coaching.photography.${bio.id}.companyName`
                            )}
                        />
                        <br />
                        <p className="coaching-testimonial__job-title">
                            {t(`coaching.photography.${bio.id}.companyName`)}
                        </p>
                    </a>
                </div>

                <VideoWithModal selectedVideo={bio.video} context={context} />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

CoachingTestimonial.propTypes = {
    bio: PropTypes.shape({
        id: PropTypes.string.isRequired,
        bioImgUrl: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        memberSince: PropTypes.string.isRequired,
        websiteUrl: PropTypes.string.isRequired,
        logoImgUrl: PropTypes.string.isRequired,
        video: PropTypes.string.isRequired
    }),
    customClass: PropTypes.string
};

export default memo(CoachingTestimonial);
