import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HBModal from '@honeybook-ui/HBModal/HBModal';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';
import * as DOMPurify from 'dompurify';

import ApiService from '../../Services/ApiService';
import AnalyticsService, {
    AnalyticsEvents,
    GTM_ANALYTICS_EVENTS
} from '../../Services/AnalyticsService';

import './template-download-button.scss';

import TemplateDownloadModalStepCollectEmail from './TemplateDownloadModalStepCollectEmail';
import TemplateDownloadModalStepConfirmEmail from './TemplateDownloadModalStepConfirmEmail';
import TemplateDownloadModalStepComplete from './TemplateDownloadModalStepComplete';
import StorageService from '../../Services/StorageService';

import getApiUrl from '../../env';

const sanitizeHtml = text => DOMPurify.sanitize(text);

const getUserEmailFromStorage = () => {
    return (
        sanitizeHtml(StorageService.getItem('hasGatedDownloadEmail')) || null
    );
};

const TemplateDownloadButton = ({
    t,
    invoiceName,
    downloadUrl,
    hasGatedDownload,
    templateTypeId
}) => {
    const [userEmail, setUserEmail] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [hasEmailPermission, setHasEmailPermission] = useState(true);

    const handleToggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const initializeModal = () => {
        const userEmailFromStorage = getUserEmailFromStorage() || null;
        const initialStep = userEmailFromStorage ? 1 : 0;

        setUserEmail(userEmailFromStorage);
        setCurrentStep(initialStep);
        handleToggleModal();
    };

    const handleDownloadButtonClick = () => {
        AnalyticsService.trackClick(AnalyticsEvents.lead_invoice_download);
        AnalyticsService.trackGtm(GTM_ANALYTICS_EVENTS.cta_click, {
            section: 'download_template'
        });

        if (hasGatedDownload) {
            initializeModal();
        } else {
            const w = window.open(downloadUrl, '_blank');

            w.opener = null;
        }
    };

    const handleEmailSubmit = userInputEmail => {
        setUserEmail(sanitizeHtml(userInputEmail));
        setCurrentStep(1);
    };

    const handleToggleEmailPermission = () => {
        setHasEmailPermission(!hasEmailPermission);
    };

    const handleEmailConfirm = e => {
        e.preventDefault();

        AnalyticsService.trackClick(AnalyticsEvents.gated_invoice_form_submit, {
            source: invoiceName
        });

        AnalyticsService.reportIterableEvent(
            'lead_created',
            {
                email: userEmail,
                lead_type: templateTypeId
            },
            { email: userEmail }
        );

        const apiUrl = `${getApiUrl()}/api/v2/lead_free_invoice`;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: userEmail,
                invoice_name: invoiceName,
                pdf: downloadUrl,
                accept_getting_emails: hasEmailPermission
            })
        };

        ApiService._apiFetch(apiUrl, requestOptions)
            .then(
                StorageService.setItem({
                    key: 'hasGatedDownloadEmail',
                    data: userEmail
                })
            )
            .then(setCurrentStep(2));
    };

    const handleChangeEmail = () => {
        setIsEmailInvalid(false);
        setCurrentStep(0);
    };

    let modalContent;

    switch (currentStep) {
        case 0:
            modalContent = (
                <TemplateDownloadModalStepCollectEmail
                    onEmailSubmit={handleEmailSubmit}
                    isEmailInvalid={isEmailInvalid}
                    invalidateEmail={() => setIsEmailInvalid(true)}
                />
            );
            break;
        case 1:
            modalContent = (
                <TemplateDownloadModalStepConfirmEmail
                    userEmail={userEmail}
                    onConfirmEmail={handleEmailConfirm}
                    onChangeEmail={handleChangeEmail}
                    hasEmailPermission={hasEmailPermission}
                    onToggleEmailPermission={handleToggleEmailPermission}
                />
            );
            break;
        case 2:
            modalContent = (
                <TemplateDownloadModalStepComplete
                    onToggleModal={handleToggleModal}
                />
            );
            break;
        default:
            modalContent = <TemplateDownloadModalStepCollectEmail />;
    }

    return (
        <>
            <button
                onClick={handleDownloadButtonClick}
                className="template-download-button__button-link hbui-button hbui-button--medium hbui-button--full hbui-button--secondary"
            >
                <HBIcon name="nx-download-16" />{' '}
                {t(`TemplateElements.common.ctaDownload`)}
            </button>

            <HBModal
                className="template-download-button__modal"
                isOpen={isModalOpen}
                onClose={handleToggleModal}
            >
                {modalContent}
            </HBModal>
        </>
    );
};

TemplateDownloadButton.propTypes = {
    t: PropTypes.func,
    invoiceName: PropTypes.string,
    templateTypeId: PropTypes.string,
    downloadUrl: PropTypes.string,
    hasGatedDownload: PropTypes.bool
};

export default withTranslation()(TemplateDownloadButton);
