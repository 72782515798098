import AnalyticsService from '../../Services/AnalyticsService';
import { useSubscribeToIframeEvent } from '../RecipientPage/useSubscribeToIframeEvent';
import { useRecipientPageContext } from '../RecipientPage/context/recipient-page-context';

export type UsePublicTemplatePreviewParams = {
    clickUseTemplateSubscriber: (data: {
        data: {
            signupUrl: any;
        };
    }) => void;
};

export const usePublicTemplatePreview = ({
    clickUseTemplateSubscriber
}: UsePublicTemplatePreviewParams) => {
    const { templateData, postMessageToChild, setIsIframeLoaded } =
        useRecipientPageContext();

    useSubscribeToIframeEvent({
        eventName: 'click_use_template',
        subscriber: clickUseTemplateSubscriber
    });

    useSubscribeToIframeEvent({
        eventName: 'loaded',
        subscriber: () => {
            AnalyticsService.track('public template iframe loaded');
            setIsIframeLoaded(true);
            postMessageToChild({
                event: 'template_data',
                data: {
                    isInIframePreview:
                        !templateData?.public_configuration.is_gallery_template
                }
            });
        }
    });
};
