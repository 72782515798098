import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import ImageTextElementsBlock from '../ImageTextElementsBlock/ImageTextElementsBlock';
import { FeatureBlockSection } from '../../FeatureBlockElements';
import ReactHtmlParser from 'react-html-parser';

import './meet-our-instructors-block.scss';

import MeetOurInstructorsBlockStore from './MeetOurInstructorsBlockStore';

const MeetOurInstructorsBlock = ({ t }) => {
    return (
        <div className="meet-instructors">
            <ImageTextElementsBlock
                title={t(`productEducationPage.meetInstructors.title`)}
                imageTextElements={MeetOurInstructorsBlockStore.instructors}
            />
            <FeatureBlockSection>
                <div className="meet-instructors__body-text-container">
                    <p className="meet-instructors__body-text">
                        {t(`productEducationPage.meetInstructors.body`)}
                    </p>
                </div>
            </FeatureBlockSection>
        </div>
    );
};

MeetOurInstructorsBlock.propTypes = {
    t: PropTypes.func.isRequired
};

export default withTranslation()(MeetOurInstructorsBlock);
