import { memo } from 'react';
import * as PropTypes from 'prop-types';

import HBTabs from '../../honeybook-ui/HBTabs/HBTabs';

import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './tabs-block.scss';

import type { TSource, TabItem } from '../../honeybook-ui/HBTabs/HBTabs';
import type { TabId } from '../../../vendor/blueprintjs/tab';

interface Props {
    customClass?: string;
    title?: string;
    tabsContent: TabItem[];
    lightBackground?: boolean;
    updateSelectedTabId?: (nextSelectedTabId: TabId) => void;
    defaultTabId?: TabId;
    source: TSource;
}

function TabsBlock({
    customClass = '',
    title,
    tabsContent,
    lightBackground,
    updateSelectedTabId,
    defaultTabId,
    source
}: Props): JSX.Element {
    return (
        <FeatureBlockSection
            lightBackground={lightBackground}
            customClass={`${customClass} tabs-block`}
        >
            <FeatureBlockContent position="center">
                {title && (
                    <FeatureBlockText
                        customClass="tabs-block__title"
                        fullWidth
                        primary={title}
                    />
                )}
                <HBTabs
                    tabs={tabsContent}
                    updateSelectedTabId={updateSelectedTabId}
                    defaultTabId={defaultTabId ? defaultTabId : undefined}
                    source={source}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

TabsBlock.propTypes = {
    customClass: PropTypes.string,
    title: PropTypes.string,
    tabsContent: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    lightBackground: PropTypes.bool
};

export default memo(TabsBlock);
