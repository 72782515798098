import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import WebinarRecording from '../WebinarRecording/WebinarRecording';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

const handleAnalytics = title => {
    AnalyticsService.trackClick(AnalyticsEvents.webinar, {
        source: title
    });
};

const WebinarRecordingsContainer = ({ t, recordings }) => {
    return recordings.map(recording => {
        return (
            <WebinarRecording
                key={recording.id}
                title={t(
                    `productEducationPage.recordings.${recording.id}.title`
                )}
                description={t(
                    `productEducationPage.recordings.${recording.id}.description`,
                    ''
                )}
                linkUrl={recording.link}
                onClick={() => {
                    handleAnalytics(recording.id);
                }}
            />
        );
    });
};

WebinarRecordingsContainer.propTypes = {
    t: PropTypes.func,
    recordings: PropTypes.arrayOf(PropTypes.object)
};

export default withTranslation()(WebinarRecordingsContainer);
