import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import FeatureTitleList from '../../FeatureTitleList/FeatureTitleList';
import { breakPoints } from '../../../Services/UtilsService';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockImage
} from '../../FeatureBlockElements';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './workflows-customize.scss';

const WorkflowsCustomizeBlock = () => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const [ref, inView] = useInView({
        threshold: 0.5
    });

    const activeClass = inView ? 'feature-block--in-view' : '';

    const listName = 'workflowsPage.customWorkflows.list';
    const listItems = Object.keys(
        t(listName, {
            returnObjects: true
        })
    ).map(key => ({ id: key }));

    return (
        <div ref={ref}>
            <FeatureBlockSection
                customClass={`workflows-customize ${activeClass}`}
            >
                <FeatureBlockContent customClass="workflows-customize__text-container">
                    <FeatureTitleList
                        title={t(`workflowsPage.customWorkflows.title`)}
                        listName="workflowsPage.customWorkflows.list"
                        items={listItems}
                        withRegistrationForm
                        registrationSource="workflows-customize"
                    />
                </FeatureBlockContent>
                <FeatureBlockContent customClass="workflows-customize__image-container">
                    {breakpoint.medium ? (
                        <HBMediaElement
                            name="marketing_site/lp/workflows-02-mob_2x.png"
                            customClass="workflows-customize__img workflows-customize__img--mweb"
                        />
                    ) : (
                        <>
                            <FeatureBlockImage
                                customClass="workflows-customize__img workflows-customize__img--inquiry"
                                featureImage="marketing_site/workflows/card1-inquery.png"
                                width={488}
                                crop="scale"
                            />
                            <HBMediaElement
                                name="marketing_site/workflows/card1-mail.png"
                                width={488}
                                customClass="workflows-customize__img workflows-customize__img--mail"
                            />
                            <HBMediaElement
                                name="marketing_site/workflows/toast.png"
                                width={470}
                                customClass="workflows-customize__img workflows-customize__img--toast"
                            />
                            <HBMediaElement
                                name="marketing_site/workflows/Envelope_2x.png"
                                width={94}
                                customClass="workflows-customize__img workflows-customize__img--envelope"
                            />
                        </>
                    )}
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

export default WorkflowsCustomizeBlock;
