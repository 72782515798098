const InvoiceGeneratorFreeInvoiceBlockStore = {
    images: {
        generic: {
            software: 'marketing_site/invoice-hub/invoicing-software',
            generator: 'marketing_site/invoice-hub/invoice-generator'
        },
        doula: {
            software:
                'marketing_site/invoice-generator-lp/group-27-resized.png',
            generator:
                'marketing_site/invoice-generator-lp/doula/invoce-generator.png'
        },
        design: {
            software:
                'marketing_site/invoice-generator-lp/designers/Module%201/Invoicing_Software_design.png',
            generator:
                'marketing_site/invoice-generator-lp/graphic-design/group-28_2x.png'
        },
        graphicDesign: {
            software:
                'marketing_site/invoice-generator-lp/graphic-design/group-27_2x.png',
            generator:
                'marketing_site/invoice-generator-lp/graphic-design/group-28_2x.png'
        },
        interiorDesign: {
            software:
                'marketing_site/invoice-generator-lp/interior-design/invoicesoftware.png',
            generator:
                'marketing_site/invoice-generator-lp/interior-design/interior-generator.png'
        },
        consultants: {
            software:
                'marketing_site/invoice-generator-lp/consultants/invoiceSoftware.png',
            generator:
                'marketing_site/invoice-generator-lp/consultants/consultantInvoiceGen.png'
        },
        photographers: {
            software:
                'marketing_site/invoice-generator-lp/photographers/invoiceSoftware.png',
            generator:
                'marketing_site/invoice-generator-lp/photographers/invoiceGenerator.png'
        },
        coach: {
            software:
                'marketing_site/invoice-generator-lp/coach/invoiceSoftware.png',
            generator:
                'marketing_site/invoice-generator-lp/coach/invoiceGenerator2.png'
        },
        tutors: {
            software:
                'marketing_site/invoice-generator-lp/tutors/Module%201/Invoicing_Software.png',
            generator:
                'marketing_site/invoice-generator-lp/tutors/Module%201/Invoice_Generator.png'
        },
        cleaners: {
            software:
                'marketing_site/invoice-generator-lp/cleaners/Module%201/Invoicing_Software.png',
            generator:
                'marketing_site/invoice-generator-lp/cleaners/Module%201/Invoice_Generator.png'
        },
        virtualAssistant: {
            software:
                'marketing_site/invoice-generator-lp/virtual-assistant/Module%201/Invoicing_Software_assistant.png',
            generator:
                'marketing_site/invoice-generator-lp/virtual-assistant/Module%201/Invoice_generator_assistant.png'
        }
    }
};

export default InvoiceGeneratorFreeInvoiceBlockStore;
