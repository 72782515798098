import { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import './testimonial-carousel-card-block.scss';

import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import TestimonialCard from '../../TestimonialCard/TestimonialCard';
import StarReviews from '../../StarReviews/StarReviews';
import type { Quote } from '../../../types/testimonial-quote';

import HBText from '@honeybook-ui/HBText/HBText';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import ArrowLeftIcon from '../../../svg/common/arrow-left.inline.svg';
import ArrowRightIcon from '../../../svg/common/arrow-right.inline.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

const defaultTestimonialQuotes: Quote[] = [
    {
        profileImage: 'marketing_site/testimonials/member-3.png',
        transId: 'testimonialCardsBlock.testimonials.angie',
        name: 'Angie M.',
        logo: 'marketing_site/testimonials/member-logo-4.png'
    },
    {
        profileImage: 'marketing_site/testimonials/member-1.png',
        transId: 'testimonialCardsBlock.testimonials.dianuh',
        name: 'Dianuh A.',
        logo: 'marketing_site/testimonials/member-logo-3.png'
    },
    {
        profileImage: 'marketing_site/testimonials/member-5.png',
        transId: 'testimonialCardsBlock.testimonials.danielle',
        name: 'Danielle J.',
        logo: 'marketing_site/testimonials/member-logo-1.png'
    },
    {
        profileImage: 'marketing_site/testimonials/member-2.png',
        transId: 'testimonialCardsBlock.testimonials.mark',
        name: 'Mark D.',
        logo: 'marketing_site/testimonials/member-logo-2.png'
    }
];

interface Props {
    customTitleTransId?: string;
    testimonials?: Quote[];
    autoScroll?: boolean;
    isAltTheme?: boolean;
    hideStarReviews?: boolean;
    hideNavigation?: boolean;
    headerImage?: string;
}

const TestimonialCarouselCardBlock = ({
    customTitleTransId,
    testimonials = defaultTestimonialQuotes,
    autoScroll = true,
    isAltTheme,
    hideStarReviews = false,
    hideNavigation = false,
    headerImage
}: Props) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();
    const blockClassName = `testimonial-carousel-card-block ${
        isAltTheme ? 'testimonial-carousel-card-block--alt-theme' : ''
    }`;

    return (
        <div className={blockClassName}>
            <FeatureBlockSection customClass="testimonial-carousel-card-block-intro">
                <FeatureBlockContent customClass="testimonial-carousel-card-block__text-container">
                    <div className="testimonial-carousel-card-block__text-content">
                        {headerImage && (
                            <HBMediaElement
                                customClass="testimonial-carousel-card-block__header-image"
                                name={headerImage}
                            />
                        )}
                        <HBText
                            customClass="testimonial-carousel-card-block__title"
                            tag="h2"
                            type="title-l"
                        >
                            <Trans
                                t={t}
                                i18nKey={
                                    customTitleTransId
                                        ? customTitleTransId
                                        : 'testimonialCardsBlock.title'
                                }
                            >
                                #TEXT#{' '}
                                <span className="testimonial-carousel-card-block__highlight">
                                    #TEXT#
                                </span>{' '}
                                #TEXT#
                            </Trans>
                        </HBText>
                        <HBText
                            customClass="testimonial-carousel-card-block__subtitle"
                            tag="h3"
                            type="body-m"
                        >
                            <Trans
                                t={t}
                                i18nKey="testimonialCardsBlock.subtitle"
                            >
                                #TEXT#{' '}
                                <span className="testimonial-carousel-card-block__highlight">
                                    #TEXT#
                                </span>{' '}
                                #TEXT#
                            </Trans>
                        </HBText>
                    </div>
                </FeatureBlockContent>
            </FeatureBlockSection>
            <FeatureBlockSection customClass="testimonial-carousel-card-block__swiper-container">
                <FeatureBlockContent customClass="feature-block--swiper">
                    <div className="swiper-container">
                        <Swiper
                            className="mySwiper"
                            slidesPerView={breakpoints.medium ? 1 : 3}
                            initialSlide={1}
                            loop={autoScroll}
                            navigation={
                                hideNavigation && breakpoints.mediumUp
                                    ? false
                                    : {
                                          prevEl: '.swiper-navigation--prev',
                                          nextEl: '.swiper-navigation--next'
                                      }
                            }
                            pagination={
                                hideNavigation && breakpoints.mediumUp
                                    ? false
                                    : autoScroll
                            }
                            draggable={false}
                            mousewheel={false}
                            autoplay={
                                autoScroll
                                    ? {
                                          delay: 2500,
                                          pauseOnMouseEnter: true
                                      }
                                    : false
                            }
                            keyboard={autoScroll}
                            modules={[
                                Navigation,
                                Pagination,
                                Mousewheel,
                                Keyboard,
                                Autoplay
                            ]}
                        >
                            {testimonials.map(
                                ({ transId, profileImage, name, logo }) => (
                                    <SwiperSlide key={transId}>
                                        <TestimonialCard
                                            key={name}
                                            text={t(`${transId}.quote`)}
                                            name={name}
                                            description={t(
                                                `${transId}.jobTitle`
                                            )}
                                            image={profileImage}
                                            companyLogo={logo}
                                        />
                                    </SwiperSlide>
                                )
                            )}
                            <ArrowLeftIcon
                                className="swiper-navigation swiper-navigation--prev"
                                tabIndex={0}
                            />
                            <ArrowRightIcon
                                className="swiper-navigation swiper-navigation--next"
                                tabIndex={0}
                            />
                        </Swiper>
                    </div>
                </FeatureBlockContent>
            </FeatureBlockSection>
            {!hideStarReviews && (
                <FeatureBlockSection>
                    <FeatureBlockContent>
                        <div className="testimonial-carousel-card-block__reviews">
                            <StarReviews />
                        </div>
                    </FeatureBlockContent>
                </FeatureBlockSection>
            )}
        </div>
    );
};

export default memo(TestimonialCarouselCardBlock);
