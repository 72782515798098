import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import Hero from '../Hero/Hero';
import HeroTitle from '../HeroTitle/HeroTitle';
import HeroStrip from '../HeroStrip/HeroStrip';
import HeroColumn from '../HeroColumn/HeroColumn';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './scheduler-hero.scss';

const SchedulerHero = () => {
    const { t } = useTranslation();

    return (
        <Hero customClass="scheduler-hero">
            <HeroStrip>
                <HeroColumn
                    columnWidth={1}
                    customClass="hero__column hero__column--text"
                >
                    <HeroTitle
                        title={t(`schedulerPage.hero.title`)}
                        subtitle={t(`schedulerPage.hero.subtitle`)}
                    />
                    <div className="feature-block__signup-wrapper feature-block__signup-wrapper--left">
                        <RegistrationForm
                            source="hero"
                            size="large"
                            showNoCcRequired
                        />
                    </div>
                </HeroColumn>

                <HeroColumn
                    customClass="hero__column hero__column--img"
                    columnWidth={1}
                >
                    <HBMediaElement
                        lazyLoad={false}
                        customClass="hero__img"
                        width={700}
                        name="marketing_site/scheduler-page/scheduler-hero.png"
                        alt={t(`schedulerPage.hero.title`)}
                    />
                </HeroColumn>
            </HeroStrip>
        </Hero>
    );
};

export default memo(SchedulerHero);
