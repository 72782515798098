import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import TableCheckIcon from '../../../svg/check.inline.svg';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';

interface Props {
    feature: { id: string; cellContents: (string | boolean)[] };
}
const FeatureTableRow = ({ feature }: Props) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const generateCellContent = content => {
        if (typeof content === 'string') {
            return (
                <div className="pricing-table-block__cell-text">
                    {t(`pricingTable.${content}`)}
                </div>
            );
        }
        return content === true ? (
            <TableCheckIcon className="pricing-table-block__check-icon" />
        ) : (
            <HBIcon name="nx-close" />
        );
    };

    return (
        <div key={feature.id} className="pricing-table-block__row-container">
            <div className="pricing-table-block__row pricing-table-block__row">
                <div className="pricing-table-block__cell">
                    <div className="pricing-table-block__title">
                        {t(`pricingTable.tableContents.${feature.id}.title`)}
                    </div>
                    {breakpoints.mediumUp && (
                        <div className="pricing-table-block__description">
                            {t(
                                `pricingTable.tableContents.${feature.id}.description`
                            )}
                        </div>
                    )}
                </div>
                {feature.cellContents.map((element, index) => {
                    let featureId;
                    if (index === 0) {
                        featureId = 'starter';
                    } else if (index === 1) {
                        featureId = 'essentials';
                    } else {
                        featureId = 'premium';
                    }
                    const key = `${feature.id} - ${featureId}`;
                    return (
                        <div
                            key={key}
                            className="pricing-table-block__cell pricing-table-block__cell--tier"
                        >
                            {generateCellContent(element)}
                        </div>
                    );
                })}
            </div>
            {breakpoints.medium && (
                <div className="pricing-table-block__row pricing-table-block__row--full-width">
                    <div className="pricing-table-block__cell pricing-table-block__description">
                        {t(
                            `pricingTable.tableContents.${feature.id}.description`
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FeatureTableRow;
