import { memo } from 'react';

import { useTranslation } from 'react-i18next';
import HBModal from '../../honeybook-ui/HBModal/HBModal';

import './mission-statement-sent-modal.scss';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

interface Props {
    isOpen: boolean;
    customClass?: string;
    onClose: () => void;
}

function MissionStatementSentModal({
    isOpen,
    customClass = '',
    onClose
}: Props): JSX.Element | null {
    const { t } = useTranslation();

    if (!isOpen) return null;

    return (
        <HBModal
            className={`mission-statement-sent-modal ${customClass}`}
            isOpen={isOpen}
            onClose={onClose}
        >
            <div className="mission-statement-sent-modal__container">
                <HBMediaElement
                    customClass="mission-statement-sent-modal__image"
                    name=""
                />
                <HBText
                    customClass="mission-statement-sent-modal__title"
                    tag="h2"
                    type="subtitle-l"
                >
                    {t('missionStatement.sentModal.title')}
                </HBText>
                <HBText
                    customClass="mission-statement-sent-modal__description"
                    tag="p"
                    type="body-m"
                >
                    {t('missionStatement.sentModal.description')}
                </HBText>
                <RegistrationForm
                    source="mission statement sent modal"
                    size="large"
                />
            </div>
        </HBModal>
    );
}

export default memo(MissionStatementSentModal);
