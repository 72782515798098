import { memo, useCallback } from 'react';
import * as PropTypes from 'prop-types';

import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';

import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

import './blog-link.scss';

import type { ReactNode } from 'react';

export interface MediaFlags {
    type?: string;
    forceImgTag?: boolean;
    width?: number | string;
    height?: number;
    crop?: string;

    alt?: string;
    customClass?: string;
    x?: number;
    y?: number;
    gravity?: string;
    radius?: string;
    fetchFormat?: string;
    flags?: string;
    quality?: string;
    dpr?: number | string;
    responsive?: boolean;
    autoPlay?: boolean;
    muted?: boolean;
    loop?: boolean;
    controls?: boolean;
    playsInline?: boolean;
    zoom?: number;
}

interface Props {
    url: string;
    title: string;
    imagePath?: string;
    mediaFlags?: MediaFlags;
    children?: ReactNode;
}

function BlogLink({
    imagePath,
    mediaFlags,
    title,
    children,
    url
}: Props): JSX.Element {
    const onClick = useCallback(() => {
        AnalyticsService.trackClick(AnalyticsEvents.blog_post, {
            source: title
        });
    }, [title]);

    return (
        <div className="blog-link">
            <a
                className="blog-link__anchor"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClick}
            >
                {imagePath ? (
                    <HBMediaElement
                        name={imagePath}
                        type={mediaFlags?.type}
                        forceImgTag={mediaFlags?.forceImgTag}
                        width={mediaFlags?.width}
                        height={mediaFlags?.height}
                        crop={mediaFlags?.crop}
                        alt={mediaFlags?.alt}
                        customClass={mediaFlags?.customClass}
                        x={mediaFlags?.x}
                        y={mediaFlags?.y}
                        gravity={mediaFlags?.gravity}
                        radius={mediaFlags?.radius}
                        fetchFormat={mediaFlags?.fetchFormat}
                        flags={mediaFlags?.flags}
                        quality={mediaFlags?.quality}
                        dpr={mediaFlags?.dpr}
                        responsive={mediaFlags?.responsive}
                        autoPlay={mediaFlags?.autoPlay}
                        muted={mediaFlags?.muted}
                        loop={mediaFlags?.loop}
                        controls={mediaFlags?.controls}
                        playsInline={mediaFlags?.playsInline}
                        zoom={mediaFlags?.zoom}
                    />
                ) : null}
                <div className="blog-link__text-container">
                    {title && <h3 className="blog-link__title">{title}</h3>}
                </div>
                {children}
            </a>
        </div>
    );
}

BlogLink.propTypes = {
    imagePath: PropTypes.string,
    mediaFlags: PropTypes.shape({}),
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
    url: PropTypes.string.isRequired
};

export default memo(BlogLink);
