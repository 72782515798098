import { memo, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';

import './hero.scss';

import type { ReactNode } from 'react';

interface Props {
    children?: ReactNode;
    customClass?: string;
    heroImage?: string;
    darkText?: boolean;
}

function Hero({
    children,
    customClass,
    darkText,
    heroImage = ''
}: Props): JSX.Element {
    const style = useMemo(() => {
        return { backgroundImage: `url(${heroImage})` };
    }, [heroImage]);

    const className = classnames('hero', {
        [`${customClass}`]: Boolean(customClass),
        'hero--dark-text': darkText
    });

    return (
        <section className={className} style={style}>
            <div className="hero__content">{children}</div>
        </section>
    );
}

Hero.propTypes = {
    children: PropTypes.node.isRequired,
    customClass: PropTypes.string,
    heroImage: PropTypes.string,
    darkText: PropTypes.bool
};

export default memo(Hero);
