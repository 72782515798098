import { memo } from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import type { ReactNode } from 'react';

import UtilsService from '../../../Services/UtilsService';

import './feature-block-text.scss';

import type { Display } from '../../../Services/UtilsService';
import { TRANSLATION_COMPONENTS } from '../../../constants/constants';

interface Props {
    display?: Display;
    preTitle?: string | JSX.Element;
    primary?: string | JSX.Element;
    secondary?: string | JSX.Element;
    subheading?: string;
    bodyText?: string;
    smallSubtext?: string;
    fullWidth?: boolean;
    customClass?: string;
    children?: ReactNode;
}

const FeatureBlockText = ({
    preTitle,
    primary,
    secondary,
    subheading,
    bodyText,
    smallSubtext,
    display,
    fullWidth,
    customClass,
    children
}: Props) => {
    const className = classnames({
        [UtilsService.screens(display)]: Boolean(display),
        'feature-block__text--full-width': fullWidth,
        [`${customClass}`]: Boolean(customClass)
    });

    return (
        <>
            {preTitle ? (
                <Trans key="preTitle">
                    <h2
                        className={`feature-block__text-title-pre ${className}`}
                    >
                        {preTitle}
                    </h2>
                </Trans>
            ) : null}
            {primary ? (
                <Trans key="primary">
                    <h2 className={`feature-block__text-title ${className}`}>
                        {primary}
                    </h2>
                </Trans>
            ) : null}
            {secondary ? (
                <h3
                    className={`feature-block__text-title-secondary ${className}`}
                >
                    <Trans
                        i18nKey={secondary}
                        components={TRANSLATION_COMPONENTS}
                    >
                        {secondary}
                    </Trans>
                </h3>
            ) : null}
            {subheading ? (
                <Trans key="subheading">
                    <h4
                        className={`feature-block__text-title-subheading ${className}`}
                    >
                        {subheading}
                    </h4>
                </Trans>
            ) : null}
            {bodyText ? (
                <p className={`feature-block__text-body ${className}`}>
                    <Trans
                        i18nKey={bodyText}
                        components={TRANSLATION_COMPONENTS}
                    />
                </p>
            ) : null}
            {smallSubtext ? (
                <Trans key="smallSubtext">
                    <p className={`feature-block__text--small ${className}`}>
                        {smallSubtext}
                    </p>
                </Trans>
            ) : null}
            {children}
        </>
    );
};

export default memo(FeatureBlockText);
