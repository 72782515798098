import { memo } from 'react';
import * as PropTypes from 'prop-types';

import './hb-loader.scss';

interface Props {
    size?: string;
}

function HBLoader({ size = 'xs' }: Props): JSX.Element {
    return (
        <div className="hb-loader__wrapper">
            <div className={`hb-loader hb-loader--${size}`} />
        </div>
    );
}

HBLoader.propTypes = {
    size: PropTypes.oneOf(['xs', 'small', 'medium', 'large'])
};

export default memo(HBLoader);
