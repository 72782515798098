export type EventBusOnFunctionArgs = {
    eventName: string;
    subscriber: (...args: any) => void;
};

export type EventBusEmitFunctionArgs = {
    eventName: string;
    data?: any;
};

class EventBus {
    events: Record<string, ((...args: any) => void)[]> = {};

    emit({ eventName, data }: EventBusEmitFunctionArgs) {
        this.events[eventName]?.forEach(fn => fn(data));
    }

    on({ eventName, subscriber }: EventBusOnFunctionArgs) {
        this.events[eventName] ||= [];
        this.events[eventName]!.push(subscriber);
        return () => {
            this.events[eventName] = this.events[eventName]!.filter(
                fn => fn !== subscriber
            );
        };
    }
}

export default new EventBus();
