import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import HBVideo from '../../honeybook-ui/HBVideo/HBVideo';

import './showcase-services-block.scss';

const ShowcaseServicesBlock = ({
    videoUrl,
    videoMobileUrl,
    lightBackground,
    darkBackground,
    showRegistration
}) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const videoUrlMobile = videoMobileUrl || videoUrl;

    return (
        <FeatureBlockSection
            customClass="showcase-services-block"
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent>
                <FeatureBlockText
                    primary={t('ShowcaseServicesBlock.title')}
                    secondary={t('ShowcaseServicesBlock.subtitle')}
                    fullWidth
                />
                <div className="showcase-services-block__media-container">
                    <HBVideo
                        autoplay
                        loop
                        muted
                        videoId="829437018"
                        title={t(`valuePropsHero.title`)}
                        padding="56.25% 0 0 0"
                    />
                </div>
                {showRegistration && (
                    <RegistrationForm
                        source="showcase services module"
                        showNoCcRequired
                        size="large"
                    />
                )}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

ShowcaseServicesBlock.propTypes = {
    videoUrl: PropTypes.string,
    videoMobileUrl: PropTypes.string,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool,
    showRegistration: PropTypes.bool
};

export default ShowcaseServicesBlock;
