import {
    GOOGLE_ONE_TAP_PARENT_ID,
    GOOGLE_ONE_TAP_TIMEOUT_SUPPRESSION
} from './constants';
import type {
    TGoogleOneTapReasons,
    TGoogleOneTapStatesHandlers,
    PromptMomentNotification,
    TGoogleOneTapStates
} from './googleOneTap.types';
import { trackErrors, trackSuccess } from './googleOneTapAnalytics';
import { handleOneTapResponse, shouldLoadOneTap } from './utils';

let isOneTapCanceled = false;

const googleOneTapOptions = {
    auto_select: false,
    callback: result => {
        trackSuccess('one_tap_credential_returned');
        handleOneTapResponse(result);
    },
    cancel_on_tap_outside: false,
    client_id: process.env.GATSBY_google_api_client_id!,
    context: 'marketing_site',
    prompt_parent_id: GOOGLE_ONE_TAP_PARENT_ID,
    prompt_suppress_sec: GOOGLE_ONE_TAP_TIMEOUT_SUPPRESSION,
    state_cookie_domain: 'honeybook.com',
    use_fedcm_for_prompt: true
};

const handleError = (reason: TGoogleOneTapReasons) => {
    trackErrors(reason);
    cancelOneTap();
};

const handleOneTapMoments: TGoogleOneTapStatesHandlers = {
    skipped(_notification: PromptMomentNotification) {},
    dismissed(notification: PromptMomentNotification) {
        const reason = notification.getDismissedReason();
        // credential_returned is the reason when google return as the user credentials successfully even thought its inside dismissed state
        if (reason !== 'credential_returned') {
            handleError(reason);
        }
    }
};

const handleOnLoad = () => {
    if (isOneTapCanceled) {
        return;
    }
    const onNotification = (notification: PromptMomentNotification) => {
        const momentType: TGoogleOneTapStates = notification.getMomentType();
        if (handleOneTapMoments[momentType]) {
            handleOneTapMoments[momentType](notification);
        }
    };
    trackSuccess('load');
    trackSuccess('display');
    window.google?.accounts.id.initialize(googleOneTapOptions);
    window.google?.accounts.id.prompt(onNotification);
};

const cancelOneTap = () => window.google?.accounts?.id?.cancel();

export const initGoogleOneTap = async () => {
    const _shouldLoadOneTap = await shouldLoadOneTap();

    if (!_shouldLoadOneTap) {
        return;
    }
    const googleOneTapScript = document.createElement('script');
    googleOneTapScript.setAttribute('type', 'text/javascript');
    googleOneTapScript.setAttribute(
        'src',
        'https://accounts.google.com/gsi/client'
    );
    googleOneTapScript.onload = handleOnLoad;
    googleOneTapScript.onerror = () => handleError('load_error');
    document.head.appendChild(googleOneTapScript);
};
