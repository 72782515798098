import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import Layout from '../components/Layout/Layout';

import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';
import ImageTextElementBlock from '../components/FeatureBlocks/ImageTextElementBlock/ImageTextElementBlock';
import { FeatureBlockText } from '../components/FeatureBlockElements';
import PictureAndTextBlock from '../components/FeatureBlocks/PictureAndTextBlock/PictureAndTextBlock';

import InvestorsBlock from '../components/FeatureBlockElements/InvestorsBlock/InvestorsBlock';
import PressFeaturedInBlock from '../components/FeatureBlocks/PressFeaturedInBlock/PressFeaturedInBlock';

import '../styles/about-us.scss';

const articles = [
    {
        id: 'techCrunch',
        imagePath: 'marketing_site/press_page/logo-tech_2x.png',
        link: 'https://techcrunch.com/2021/11/03/honeybook-doubles-valuation-with-new-round-as-it-helps-independent-workers-get-paid/?guccounter=1'
    },
    {
        id: 'reuters',
        imagePath:
            'marketing_site/press_page/featured_in_logos/reuters-logo.png',
        link: 'https://www.reuters.com/technology/business-software-firm-honeybook-valued-24-bln-after-tiger-global-led-funding-2021-11-03/'
    },
    {
        id: 'forbesWomen',
        imagePath:
            'marketing_site/press_page/featured_in_logos/forbes-women2.png',
        link: 'https://www.forbes.com/sites/geristengel/2021/06/16/focusing-on-values-profits-a-tech-company-serving-solopreneurs/?sh=31248a2728f3'
    },
    {
        id: 'cnbc',
        imagePath: 'marketing_site/press_page/logo-cnbc_2x.png',
        link: 'https://www.cnbc.com/2020/03/17/freelancers-should-do-this-now-to-protect-against-crises-like-covid-19.html'
    },
    {
        id: 'forbes',
        imagePath: 'marketing_site/press_page/logo-forbs_2x.png',
        link: 'https://www.forbes.com/sites/jonyounger/2020/07/23/three-cheers-for-event-industry-freelancers--adapting-to-a-new-normal/?sh=35c19c2c44f5'
    },
    {
        id: 'ozy',
        imagePath: 'marketing_site/about_page/ozy-logo2.png',
        link: 'https://www.ozy.com/news-and-politics/this-gender-pay-gap-is-being-replaced-by-a-different-gap/277889/'
    },
    {
        id: 'newYorker',
        imagePath: 'marketing_site/press_page/logo-new-yorker_2x.png',
        link: 'https://www.newyorker.com/culture/cultural-comment/the-gig-economy-is-especially-susceptible-to-sexual-harassment'
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/about',
    title: 'About Us: The HoneyBook Story',
    description:
        'Our core values drive every decision we make — from hiring to product to customer service. Learn where HoneyBook’s been and where it’s going.'
};

function AboutUsTemplate(): JSX.Element {
    const { t } = useTranslation();

    return (
        <Layout ogTags={ogTags}>
            <div className="about">
                <WhiteHero
                    image="marketing_site/about_page/About-HeroImage.png"
                    mobileImage="marketing_site/about_page/About-HeroImage.png"
                    mainTitle={t('AboutUsPage.hero.title')}
                    description={t('AboutUsPage.hero.subtitle')}
                    showRegistration={false}
                    imageWidth="auto"
                />

                <ImageTextElementBlock
                    customClass="about__post-hero"
                    imageUrl="marketing_site/about_page/post-hero-stars.svg"
                    mediaFlags={{ forceImgTag: true }}
                >
                    <FeatureBlockText
                        secondary={t('AboutUsPage.postHero.title')}
                    />
                </ImageTextElementBlock>

                <PictureAndTextBlock
                    customClass="about__what-we-do"
                    primary={t('AboutUsPage.whatWeDo.title')}
                    secondary={t('AboutUsPage.whatWeDo.text')}
                    image="marketing_site/about_page/erez.png"
                    imageWidth={470}
                />

                <PictureAndTextBlock
                    customClass="about__who-we-are"
                    primary={t('AboutUsPage.whoWeAre.title')}
                    secondary={t('AboutUsPage.whoWeAre.text')}
                    image="marketing_site/about_page/who470.jpg"
                    imageWidth={470}
                />

                <PictureAndTextBlock
                    customClass="about__core-values"
                    primary={t('AboutUsPage.coreValues.title')}
                    secondary={t('AboutUsPage.coreValues.text')}
                    image="marketing_site/about_page/core_values-new.png"
                    imageWidth={470}
                />

                <InvestorsBlock lightBackground />

                <PressFeaturedInBlock
                    articles={articles}
                    footerText={
                        <Trans
                            i18nKey="FeaturedInBlock.footerText"
                            components={ReactHtmlParser(
                                "#TEXT# <a href='/press'>#LINK_TEXT#</a> #TEXT#"
                            )}
                        />
                    }
                />
            </div>
        </Layout>
    );
}

export default AboutUsTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "about"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
