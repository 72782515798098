class RegistrationFormError extends Error {
    date: Date;
    data: { type: string; url?: string };

    constructor(data: { type: string; url?: string }, ...params: string[]) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, RegistrationFormError);
        }

        this.name = 'RegistrationFormError';

        this.data = data;
        this.date = new Date();
    }
}

export default RegistrationFormError;
