import React from 'react';
import PropTypes from 'prop-types';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import HBQuote from '../HBQuote/HBQuote';

import './testimonial-slide.scss';

const TestimonialSlide = ({ name, title, testimonial, image }) => (
    <div className="testimonial-slide">
        <div className="testimonial-slide__text-container">
            <HBQuote>{testimonial}</HBQuote>
            <p className="testimonial-slide__name">{name}</p>
            <p className="testimonial-slide__title">{title}</p>
        </div>
        <HBMediaElement
            customClass="testimonial-slide__image"
            name={image}
            width={592}
            crop="scale"
            lazyLoad={false}
        />
    </div>
);

TestimonialSlide.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    testimonial: PropTypes.string.isRequired,
    image: PropTypes.string
};

export default TestimonialSlide;
