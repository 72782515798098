import { useTranslation } from 'react-i18next';
import Star from '../../svg/review-star.inline.svg';
import HBText from '../honeybook-ui/HBText/HBText';

import './stars-input.scss';

const STARS = [5, 4, 3, 2, 1];

const StarsInput = ({ rate, setRate, labels, isError }) => {
    const { t } = useTranslation(undefined, {
        keyPrefix: 'feedback_form'
    });

    const onStarClick = selectedRate => {
        setRate(selectedRate);
    };

    return (
        <div className="stars-input">
            <div className="stars-input__stars-container">
                {STARS.map(star => (
                    <Star
                        className={`stars-input__star ${
                            rate === star && 'stars-input__star--selected'
                        } ${isError && 'stars-input__star--error'}`}
                        onClick={() => {
                            onStarClick(star);
                        }}
                    />
                ))}
            </div>
            <div className="stars-input__labels-container">
                <HBText
                    customClass="stars-input__label stars-input__label--min"
                    tag="p"
                    type="body-xs"
                >
                    {labels.min}
                </HBText>
                <HBText
                    customClass="stars-input__label stars-input__label--max"
                    tag="p"
                    type="body-xs"
                >
                    {labels.max}
                </HBText>
            </div>
            {isError && (
                <HBText customClass="stars-input__error" tag="p" type="body-s">
                    {t('error') as string}
                </HBText>
            )}
        </div>
    );
};

export default StarsInput;
