export type TFeaturesCheckListAnalyticsData = {
    intent_selected: 'none' | string;
    intent_selected_count: number;
};

export const FEATURES_LIST = {
    invoices: 'marketing_site/icons/checkboxes/intentHero/Invoice_2x.png',
    contracts: 'marketing_site/icons/checkboxes/intentHero/Contract_2x.png',
    scheduling: 'marketing_site/icons/checkboxes/intentHero/Calendar_2x.png',
    automation: 'marketing_site/icons/checkboxes/intentHero/Automation_2x.png',
    inquiries: 'marketing_site/icons/checkboxes/intentHero/Inquiries_2x.png',
    projects: 'marketing_site/icons/checkboxes/intentHero/Briefcase_2x.png',
    forms: 'marketing_site/icons/checkboxes/intentHero/Document_2x.png',
    payments: 'marketing_site/icons/checkboxes/intentHero/Credit_Card_2x.png',
    else: 'marketing_site/icons/checkboxes/intentHero/else.png'
} as const;

export type TKeyOfChecklistItemList = keyof typeof FEATURES_LIST;
