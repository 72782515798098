import { useContext, useState } from 'react';
import { CloudinaryService } from '../../../Services/CloudinaryService';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import { trackError } from '../../../utils/error.utils';
import { EmailSignatureFormContext } from '../EmailSignatureBlock';
import HBIcon from '../../honeybook-ui/HBIcon/HBIcon';

import './email-signature-info-form.scss';
import HBLoader from '../../honeybook-ui/HBLoader/HBLoader';

function EmailSignatureInfoForm() {
    const { formData, setFormData } = useContext(EmailSignatureFormContext);
    const [isLoaderShown, setIsLoaderShown] = useState(false);

    const uploadImage = async ({ target }) => {
        try {
            setIsLoaderShown(true);
            const userImageUrl = await CloudinaryService.uploadImage(
                target.files[0]
            );
            setFormData({ ...formData, imageUrl: userImageUrl });
            AnalyticsService.track(
                AnalyticsEvents.lp_email_signature_upload_logo
            );
        } catch (err) {
            AnalyticsService.track(
                AnalyticsEvents.lp_email_signature_upload_logo_failed
            );
            trackError(err, {
                message: 'failed to upload image (email signature)'
            });
        } finally {
            setIsLoaderShown(false);
        }
    };

    return (
        <form className="email-signature-info-form" autoComplete="off">
            <div className="email-signature-info-form__header">
                <div className="email-signature-info-form__image-upload-container">
                    {!formData.imageUrl ? (
                        <label htmlFor="user-image">
                            <HBIcon name="nx-image-16" />
                            <span>Logo</span>
                            {isLoaderShown && <HBLoader />}
                        </label>
                    ) : (
                        <>
                            <img src={formData.imageUrl} />
                            <HBIcon
                                customClass="close-btn"
                                name="nx-close"
                                onClick={() => {
                                    setFormData({ ...formData, imageUrl: '' });
                                }}
                            />
                        </>
                    )}
                    <input
                        type="file"
                        name="user-image"
                        onChange={uploadImage}
                        className="email-signature-input"
                    />
                </div>

                <div className="email-signature-info-form__input-container">
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        placeholder="Name"
                        onChange={ev =>
                            setFormData({ ...formData, name: ev.target.value })
                        }
                        className="email-signature-input"
                    />
                    <input
                        type="text"
                        name="jobTitle"
                        value={formData.jobTitle}
                        placeholder="Job title"
                        onChange={ev =>
                            setFormData({
                                ...formData,
                                jobTitle: ev.target.value
                            })
                        }
                        className="email-signature-input"
                    />
                </div>
            </div>
            <input
                type="text"
                name="company"
                value={formData.company}
                placeholder="Company"
                onChange={ev =>
                    setFormData({ ...formData, company: ev.target.value })
                }
                className="email-signature-input"
            />
            <input
                type="text"
                name="email"
                value={formData.email}
                placeholder="Email"
                onChange={ev =>
                    setFormData({ ...formData, email: ev.target.value })
                }
                className="email-signature-input"
            />
            <input
                type="text"
                name="address"
                value={formData.address}
                placeholder="Address"
                onChange={ev =>
                    setFormData({ ...formData, address: ev.target.value })
                }
                className="email-signature-input"
            />
            <input
                type="text"
                name="phone"
                value={formData.phone}
                placeholder="Phone"
                onChange={ev =>
                    setFormData({ ...formData, phone: ev.target.value })
                }
                className="email-signature-input"
            />
            <input
                type="text"
                name="website"
                value={formData.website}
                placeholder="Website"
                onChange={ev =>
                    setFormData({ ...formData, website: ev.target.value })
                }
                className="email-signature-input"
            />
        </form>
    );
}

export default EmailSignatureInfoForm;
