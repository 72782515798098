import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import './tos-links-block.scss';
import NavigateLink from '../../Link/NavigateLink';

const TOSLinksBlock = ({ contentId }) => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection>
            <FeatureBlockContent fullWidth>
                <nav className="tos-links-block__nav">
                    <NavigateLink
                        source="tos"
                        link="/terms/terms-of-service"
                        className={`tos-links-block__nav-item ${
                            contentId === 'termsOfService'
                                ? 'tos-links-block__nav-item--active'
                                : ''
                        }`}
                    >
                        {t('TOSPage.navItems.TOS')}
                    </NavigateLink>
                    <NavigateLink
                        source="tos"
                        link="/terms/privacy"
                        className={`tos-links-block__nav-item ${
                            contentId === 'privacy' ||
                            contentId === 'privacy-original'
                                ? 'tos-links-block__nav-item--active'
                                : ''
                        }`}
                    >
                        {t('TOSPage.navItems.privacy')}
                    </NavigateLink>
                </nav>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

TOSLinksBlock.propTypes = {
    contentId: PropTypes.string
};

export default TOSLinksBlock;
