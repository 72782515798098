import { useTranslation, Trans } from 'react-i18next';
import TestimonialCard from '../../TestimonialCard/TestimonialCard';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';
import HBText from '@honeybook-ui/HBText/HBText';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import StarReviews from '../../StarReviews/StarReviews';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import type { Quote } from '../../../types/testimonial-quote';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './testimonial-cards-block.scss';

const defaultQuotes = [
    {
        profileImage: 'marketing_site/testimonials/member-5.png',
        transId: 'testimonialCardsBlock.testimonials.danielle',
        name: 'Danielle J.',
        logo: 'marketing_site/testimonials/member-logo-1.png'
    },
    {
        profileImage: 'marketing_site/testimonials/member-3.png',
        transId: 'testimonialCardsBlock.testimonials.angie',
        name: 'Angie M.',
        logo: 'marketing_site/testimonials/member-logo-4.png'
    },
    {
        profileImage: 'marketing_site/testimonials/member-2.png',
        transId: 'testimonialCardsBlock.testimonials.mark',
        name: 'Mark D.',
        logo: 'marketing_site/testimonials/member-logo-2.png'
    },
    {
        profileImage: 'marketing_site/testimonials/member-1.png',
        transId: 'testimonialCardsBlock.testimonials.dianuh',
        name: 'Dianuh A.',
        logo: 'marketing_site/testimonials/member-logo-3.png'
    }
];

const handleLinkClick = () => {
    AnalyticsService.trackClick(
        AnalyticsEvents.testimonial_cards_block_reviews_link,
        {
            source: 'read more reviews link'
        }
    );
};

interface Props {
    customClass?: string;
    diagonalBackground?: boolean;
    quotes?: Quote[];
    hideReviewsLink?: boolean;
    title?: string;
    showStarReviews?: boolean;
}
const TestimonialCardsBlock = ({
    customClass = '',
    diagonalBackground = false,
    quotes = defaultQuotes,
    hideReviewsLink = false,
    title,
    showStarReviews = false
}: Props) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const _title = title || t('testimonialCardsBlock.title');
    const half = Math.ceil(quotes.length / 2);
    const firstHalf = quotes.slice(0, half - (quotes.length % 2));
    const secondHalf = quotes.slice(-half);

    return (
        <FeatureBlockSection
            customClass={`testimonial-cards-block ${customClass} ${
                diagonalBackground
                    ? 'testimonial-cards-block--diagonal-background'
                    : ''
            }`}
        >
            <FeatureBlockContent customClass="testimonial-cards-block__text-content">
                <HBText
                    customClass="testimonial-cards-block__title"
                    tag="h2"
                    type="title-m"
                >
                    {_title}
                </HBText>
                <HBText
                    customClass="testimonial-cards-block__subtitle"
                    tag="h3"
                    type="body-l"
                >
                    <Trans>{t('testimonialCardsBlock.subtitle')}</Trans>
                </HBText>
                {breakpoints.medium && (
                    <div className="testimonial-cards-block__recommended-text">
                        {<Trans>testimonialCardsBlock.recommendedText</Trans>}
                    </div>
                )}

                {showStarReviews && !breakpoints.medium && <StarReviews />}
            </FeatureBlockContent>
            {!breakpoints.medium ? (
                <FeatureBlockContent customClass="testimonial-cards-block__reviews-content">
                    <div className="testimonial-cards-block__column-one">
                        {firstHalf.map(
                            ({
                                name,
                                transId,
                                profileImage,
                                logo,
                                businessName
                            }) => {
                                return (
                                    <TestimonialCard
                                        key={name}
                                        customClass={customClass}
                                        text={t(`${transId}.quote`)}
                                        name={name}
                                        description={t(`${transId}.jobTitle`)}
                                        image={profileImage}
                                        companyLogo={logo}
                                        businessName={businessName}
                                    />
                                );
                            }
                        )}
                    </div>
                    <div
                        className={`testimonial-cards-block__column-two ${
                            quotes.length % 2 === 0 &&
                            'testimonial-cards-block__column-two--offset'
                        }`}
                    >
                        {secondHalf.map(
                            ({
                                name,
                                transId,
                                profileImage,
                                logo,
                                businessName
                            }) => {
                                return (
                                    <TestimonialCard
                                        key={name}
                                        customClass={customClass}
                                        text={t(`${transId}.quote`)}
                                        name={name}
                                        description={t(`${transId}.jobTitle`)}
                                        image={profileImage}
                                        companyLogo={logo}
                                        businessName={businessName}
                                    />
                                );
                            }
                        )}
                        {!hideReviewsLink && (
                            <a
                                className="testimonial-cards-block__link"
                                href="/reviews"
                                onClick={handleLinkClick}
                            >
                                {t('testimonialCardsBlock.linkText')}{' '}
                                <HBIcon
                                    customClass="testimonial-cards-block__arrow"
                                    name="nx-nx-arrow-right-42"
                                />
                            </a>
                        )}
                    </div>
                </FeatureBlockContent>
            ) : (
                <FeatureBlockSection customClass="testimonial-cards-block__swiper-container">
                    <FeatureBlockContent customClass="feature-block--swiper">
                        <Swiper
                            className="mySwiper"
                            slidesPerView={3}
                            initialSlide={2}
                            autoplay={{
                                delay: 2500,
                                pauseOnMouseEnter: true
                            }}
                            loop
                            draggable
                            keyboard
                            centeredSlides
                            slideToClickedSlide
                            modules={[Keyboard, Autoplay]}
                        >
                            {quotes.map(
                                ({
                                    name,
                                    transId,
                                    profileImage,
                                    logo,
                                    businessName
                                }) => (
                                    <SwiperSlide key={name}>
                                        <TestimonialCard
                                            key={name}
                                            customClass={customClass}
                                            text={t(`${transId}.quote`)}
                                            name={name}
                                            description={t(
                                                `${transId}.jobTitle`
                                            )}
                                            image={profileImage}
                                            companyLogo={logo}
                                            businessName={businessName}
                                        />
                                    </SwiperSlide>
                                )
                            )}
                        </Swiper>
                        {showStarReviews && <StarReviews />}
                    </FeatureBlockContent>
                </FeatureBlockSection>
            )}
        </FeatureBlockSection>
    );
};

export default TestimonialCardsBlock;
