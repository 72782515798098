import { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout/Layout';
import BusinessPersonalityToolResultHero from '../components/Hero/BusinessPersonalityToolResultHero/BusinessPersonalityToolResultHero';
import BusinessPersonalityDescriptionBlock from '../components/FeatureBlocks/BusinessPersonalityDescriptionBlock/BusinessPersonalityDescriptionBlock';

import BusinessPersonalityToolActionableTipsBlock from '../components/FeatureBlocks/BusinessPersonalityToolActionableTipsBlock/BusinessPersonalityToolActionableTipsBlock';
import BusinessPersonalityToolFreeGuideBlock from '../components/FeatureBlocks/BusinessPersonalityToolFreeGuideBlock/BusinessPersonalityToolFreeGuideBlock';
import BusinessPersonalityToolSocialBlock from '../components/FeatureBlocks/BusinessPersonalityToolSocialBlock/BusinessPersonalityToolSocialBlock';
import BusinessPersonalityToolIGTemplateBlock from '../components/FeatureBlocks/BusinessPersonalityToolIGTemplateBlock/BusinessPersonalityToolIGTemplateBlock';
import BusinessPersonalityToolAlmost30Block from '../components/FeatureBlocks/BusinessPersonalityToolAlmost30Block/BusinessPersonalityToolAlmost30Block';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../components/FeatureBlockElements';

import '../styles/business-personality-tool-result.scss';

import type { StaticPage } from '../types/util-types';

const metaTags = {
    visionaryRisingStar: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Visionary Rising Star',
        description:
            'Small business tips for visionary business owners who are ramping up.',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329709/marketing_site/business_personality_tool/OGImages/OG_Visionary_Rising_Star.png'
    },
    visionaryWaveMaker: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Visionary Wave Maker',
        description:
            'Small business tips for visionary business owners who want to take their business to the next level.',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329709/marketing_site/business_personality_tool/OGImages/OG_Visionary_Wave_Maker.png'
    },
    visionaryDrivingForce: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Visionary Driving Force',
        description:
            'Small business tips for visionary business owners who want to create a lasting business. ',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329709/marketing_site/business_personality_tool/OGImages/OG_Visionary_Driving_Force.png'
    },
    visionaryEmpireBuilder: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Visionary Empire Builder',
        description:
            'Small business tips for visionary business owners who want to create sustainable business.',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329709/marketing_site/business_personality_tool/OGImages/OG_Visionary_Empire_Builder.png'
    },

    creativeRisingStar: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Creative Rising Star',
        description:
            'Small business tips for creative business owners who are ramping up their business.',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329709/marketing_site/business_personality_tool/OGImages/OG_Creative_Rising_Star.png'
    },
    creativeWaveMaker: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Creative Wave Maker',
        description:
            'Small business tips for creative business owners who want to reach the next level.',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329709/marketing_site/business_personality_tool/OGImages/OG_Creative_Wave_Maker.png'
    },
    creativeDrivingForce: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Creative Driving Force',
        description:
            'Small business tips for creative business owners who want to create a lasting business.',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329709/marketing_site/business_personality_tool/OGImages/OG_Creative_Driving_Force.png'
    },
    creativeEmpireBuilder: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Creative Empire Builder',
        description:
            'Small business tips for creative business owners who want to create a sustainable business.',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329709/marketing_site/business_personality_tool/OGImages/OG_Creative_Empire_Builder.png'
    },
    actualizerRisingStar: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Actualizer Rising Star',
        description:
            'Small business tips for execution-oriented business owners who are ramping up their business.',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329708/marketing_site/business_personality_tool/OGImages/OG_Actualizer_Rising_Star.png'
    },
    actualizerWaveMaker: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Actualizer Wave Maker',
        description:
            'Small business tips for execution-oriented business owners who want to take their business to the next level.',
        image: ''
    },
    actualizerDrivingForce: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Actualizer Driving Force',
        description:
            'Small business tips for execution-oriented business owners who want to grow their business.',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329708/marketing_site/business_personality_tool/OGImages/OG_Actualizer_Wave_Maker.png'
    },
    actualizerEmpireBuilder: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Actualizer Empire Builder',
        description:
            'Small business tips for execution-oriented business owners who want to create a sustainable business.',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329708/marketing_site/business_personality_tool/OGImages/OG_Actualizer_Empire_Builder.png'
    },
    connectorRisingStar: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Connector Rising Star',
        description:
            'Small business tips for collaborative, resourceful business owners ramping up their business.',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329708/marketing_site/business_personality_tool/OGImages/OG_Connector_Rising_Star.png'
    },
    connectorWaveMaker: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Connector Wave Maker',
        description:
            'Small business tips for collaborative, resourceful business owners want to take their business to the next level.',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329709/marketing_site/business_personality_tool/OGImages/OG_Connector_Wave_Maker.png'
    },
    connectorDrivingForce: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Connector Driving Force',
        description:
            'Small business tips for collaborative, resourceful business owners want to grow their business.',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329708/marketing_site/business_personality_tool/OGImages/OG_Connector_Driving_Force.png'
    },
    connectorEmpireBuilder: {
        url: '/business-personality-test',
        title: 'Business Personality Test - Connector Empire Builder',
        description:
            'Small business tips for collaborative, resourceful business owners who want to create a sustainable business.',
        image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1575329708/marketing_site/business_personality_tool/OGImages/OG_Connector_Empire_Builder.png'
    }
};

function addFontLink(fontUrl: string): HTMLLinkElement {
    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', fontUrl);
    document.head.appendChild(link);

    return link;
}

function BusinessPersonalityTestResultTemplate({
    pageContext: { resultKey }
}: StaticPage): JSX.Element {
    const { t } = useTranslation();

    useEffect(() => {
        const oswald = addFontLink(
            'https://fonts.googleapis.com/css?family=Oswald:700&display=swap'
        );
        const sacramento = addFontLink(
            'https://fonts.googleapis.com/css?family=Sacramento&display=swap'
        );

        return () => {
            document.head.removeChild(oswald);
            document.head.removeChild(sacramento);
        };
    });

    return (
        <Layout navBarTools ogTags={metaTags[resultKey]}>
            <section className="bptr__page">
                <BusinessPersonalityToolResultHero resultKey={resultKey} />
                <BusinessPersonalityDescriptionBlock resultKey={resultKey} />
                <BusinessPersonalityToolActionableTipsBlock
                    resultKey={resultKey}
                />
                <BusinessPersonalityToolFreeGuideBlock />
                <BusinessPersonalityToolIGTemplateBlock resultKey={resultKey} />
                <BusinessPersonalityToolSocialBlock resultKey={resultKey} />
                <BusinessPersonalityToolAlmost30Block resultKey={resultKey} />
                <FeatureBlockSection customClass="bptr-outro">
                    <FeatureBlockContent>
                        <FeatureBlockText
                            fullWidth
                            primary={t(
                                `businessPersonalityTool.simplify.title`
                            )}
                            bodyText={t(
                                `businessPersonalityTool.simplify.body`
                            )}
                        />
                    </FeatureBlockContent>
                </FeatureBlockSection>
            </section>
        </Layout>
    );
}

export default BusinessPersonalityTestResultTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "businessPersonality"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
