import { memo, useCallback, ReactNode } from 'react';
interface SocialIconData {
    id: string;
    link: string;
    icon: ReactNode;
}

interface Props {
    item: SocialIconData;
    handleAnalytics: (item: SocialIconData) => void;
}

function SocialIcon({ item, handleAnalytics }: Props): JSX.Element {
    const onClick = useCallback(() => {
        handleAnalytics(item);
    }, [handleAnalytics, item]);

    return (
        <a
            key={item.id}
            data-id={item.id}
            href={item.link}
            onClick={onClick}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={item.id}
        >
            {item.icon}
        </a>
    );
}

export default memo(SocialIcon);
