import { graphql } from 'gatsby';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import FeatureHeroBlock from '../components/FeatureBlocks/FeatureHeroBlock/FeatureHeroBlock';
import FeaturePreviewBlock from '../components/FeatureBlocks/FeaturePreviewBlock/FeaturePreviewBlock';
import FeatureSignatureBlock from '../components/FeatureBlocks/FeatureSignatureBlock/FeatureSignatureBlock';
import FeatureTextBlock from '../components/FeatureBlocks/FeatureTextBlock/FeatureTextBlock';
import StepFeatureBlock from '../components/FeatureBlocks/StepFeatureBlock/StepFeatureBlock';
import FeatureTemplateGallery from '../components/FeatureBlockElements/FeatureTemplateGallery/FeatureTemplateGallery';
import TestimonialCarouselCardBlock from '../components/FeatureBlocks/TestimonialCarouselCardBlock/TestimonialCarouselCardBlock';
import FeatureSuperchargeBlock from '../components/FeatureBlocks/FeatureSuperchargeBlock/FeatureSuperchargeBlock';
import FeatureIntegrationBlock from '../components/FeatureBlocks/FeatureIntegrationBlock/FeatureIntegrationBlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import ExploreFeaturesBlock from '../components/FeatureBlocks/ExploreFeaturesBlock/ExploreFeaturesBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import { usePromoCode } from '../hooks/usePromoCode';
import BellIcon from '../svg/bell.inline.svg';
import CheckCircleIcon from '../svg/check-circle.inline.svg';
import ClockIcon from '../svg/clock.inline.svg';
import TemplatesIcon from '../svg/templates.inline.svg';
import SparksIcon from '../svg/sparks.inline.svg';

import '../styles/online-template.scss';

const ogTags = {
    url: 'https://www.honeybook.com/online-contract',
    title: 'Online Contract Signing for Small Business Owners',
    description:
        'Create and sign a professional online contract to get paid faster. Customize contract templates that meet your business needs, now.'
};

const stepBlocksOne = [
    {
        subTransId: 'browse',
        iconSVG: <TemplatesIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Browse_legally-vetted_templates.png'
    },
    {
        subTransId: 'autoFill',
        iconSVG: <SparksIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Auto-fill_client_details.png'
    }
];

const stepBlocksTwo = [
    {
        subTransId: 'schedule',
        iconSVG: <ClockIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Send_at_the_perfect_moment.png'
    },
    {
        subTransId: 'clientSuccess',
        iconUrl: 'marketing_site/online-contracts/templates/Team_avatars.png',
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Manage_client_access.png'
    }
];

const stepBlocksThree = [
    {
        subTransId: 'statusUpdates',
        iconSVG: <CheckCircleIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Receive_status_updates.png'
    },
    {
        subTransId: 'reminders',
        iconSVG: <BellIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Automate_gentle_reminders.png'
    }
];

const integrationsIcons = {
    outer: [
        'marketing_site/online-contracts/integration/outer_1_2x.png',
        'marketing_site/online-contracts/integration/outer_2_2x.png',
        'marketing_site/online-contracts/integration/outer_3_2x.png',
        'marketing_site/online-contracts/integration/outer_4_2x.png',
        'marketing_site/online-contracts/integration/outer_5_2x.png',
        'marketing_site/online-contracts/integration/outer_6_2x.png'
    ],
    inner: [
        'marketing_site/online-contracts/integration/inner_1_2x.png',
        'marketing_site/online-contracts/integration/inner_2_2x.png',
        'marketing_site/online-contracts/integration/inner_3_2x.png',
        'marketing_site/online-contracts/integration/inner_4_2x.png'
    ]
};

const exploreLinksContent = [
    {
        iconUrl: 'marketing_site/online-contracts/explore/Automations.png',
        text: 'Automations',
        linkUrl: '/automations'
    },
    {
        iconUrl: 'marketing_site/online-contracts/explore/Scheduler.png',
        text: 'Scheduler',
        linkUrl: '/meeting-scheduler'
    },
    {
        iconUrl: 'marketing_site/online-contracts/explore/Invoices.png',
        text: 'Invoices',
        linkUrl: '/online-invoices'
    },
    {
        iconUrl: 'marketing_site/online-contracts/explore/Projects.png',
        text: 'Projects',
        linkUrl: '/all-in-one-business-platform'
    },
    {
        iconUrl: 'marketing_site/online-contracts/explore/Inquiries.png',
        text: 'Inquiries',
        linkUrl: '/all-in-one-business-platform'
    },
    {
        iconUrl: 'marketing_site/online-contracts/explore/Payments.png',
        text: 'Payments',
        linkUrl: '/online-payment-software'
    },
    {
        iconUrl: 'marketing_site/online-contracts/explore/Forms.png',
        text: 'Forms',
        linkUrl: '/all-in-one-business-platform'
    }
];

const previewContent = {
    client: {
        imgUrl: 'marketing_site/online-contracts/preview/Clientview.png',
        mobileImgUrl:
            'marketing_site/online-contracts/preview/client_view_mobile.png'
    },
    your: {
        imgUrl: 'marketing_site/online-contracts/preview/Yourview.png',
        mobileImgUrl:
            'marketing_site/online-contracts/preview/your_view_mobile.png'
    }
};

const signatureCardContents = [
    {
        iconUrl: 'marketing_site/online-contracts/signature/Signature.svg',
        transId: 'effortless'
    },
    {
        iconUrl: 'marketing_site/online-contracts/signature/Phone.svg',
        transId: 'onTheGo'
    },
    {
        iconUrl: 'marketing_site/online-contracts/signature/Stars.svg',
        transId: 'impression'
    }
];

const faqs = [
    {
        id: 'contractDigital'
    },
    {
        id: 'contractCreate',
        answer: '#TEXT# <a href="/blog/how-to-create-an-invoice">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'contractGoodBusiness'
    },
    {
        id: 'contractFreelancers'
    },
    {
        id: 'contractMobile',
        answer: `#TEXT# <a href="https://play.google.com/store/apps/details?id=com.honeybook.alfred&hl=en_US">#LINK_TEXT#</a> #TEXT# <a href="https://apps.apple.com/us/app/honeybook/id${process.env.GATSBY_honeybookAppId}">#LINK_TEXT#</a> #TEXT#`
    },
    {
        id: 'contractSign',
        answer: '#TEXT# <a href="/online-invoices">#LINK_TEXT#</a> #TEXT# <a href="/online-payment-software">#LINK_TEXT#</a> #TEXT# '
    },
    {
        id: 'contractLegal'
    },
    {
        id: 'contractTemplates'
    }
];

function OnlineContract(): JSX.Element {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    const stats = useMemo(
        () => [
            {
                title: (
                    <Trans>{t('contractsStatsAnimation.signed.title')}</Trans>
                ),
                subtitle: (
                    <Trans>
                        {t('contractsStatsAnimation.signed.subtitle')}
                    </Trans>
                )
            },
            {
                title: (
                    <Trans>{t('contractsStatsAnimation.value.title')}</Trans>
                ),
                subtitle: (
                    <Trans>{t('contractsStatsAnimation.value.subtitle')}</Trans>
                )
            },
            {
                title: (
                    <Trans>{t('contractsStatsAnimation.daily.title')}</Trans>
                ),
                subtitle: (
                    <Trans>{t('contractsStatsAnimation.daily.subtitle')}</Trans>
                )
            }
        ],
        []
    );

    const linkTransKey = 'linkText';
    const transKey = `stepsFeatureBlock.${linkTransKey}`;

    return (
        <Layout ogTags={ogTags} mobileStickyCTA>
            <div className="online-template">
                <FeatureHeroBlock
                    label={t('featureHeroBlock.label')}
                    title={t('featureHeroBlock.title')}
                    subtitle={t('featureHeroBlock.subtitle')}
                    stats={stats}
                />
                <FeaturePreviewBlock
                    transId="contractToggle"
                    previewContent={previewContent}
                />
                <FeatureSignatureBlock
                    cardContents={signatureCardContents}
                    ctaSource="contract signature block"
                />
                <FeatureTextBlock
                    title={t('featureTextBlock.title')}
                    imageUrl="marketing_site/online-contracts/Contract.png"
                />
                <StepFeatureBlock
                    transId="stepsFeatureBlock.step1"
                    stepSubBlocks={stepBlocksOne}
                    linkText={t(transKey)}
                />
                <StepFeatureBlock
                    reverse
                    transId="stepsFeatureBlock.step2"
                    stepSubBlocks={stepBlocksTwo}
                    linkText={t(transKey)}
                />
                <StepFeatureBlock
                    transId="stepsFeatureBlock.step3"
                    stepSubBlocks={stepBlocksThree}
                    linkText={t(transKey)}
                />
                <FeatureTemplateGallery ctaSource="Contract template gallery footer" />
                <TestimonialCarouselCardBlock />
                <FeatureSuperchargeBlock />
                <FeatureIntegrationBlock
                    title={t('featureIntegrationBlock.title')}
                    subtitle={t('featureIntegrationBlock.subtitle')}
                    icons={integrationsIcons}
                />
                <FAQBlock
                    customClass="online-template__faq"
                    title={t('FaqBlock.title')}
                    faqs={faqs}
                />
                <ExploreFeaturesBlock
                    title={t('exploreFeaturesBlock.title')}
                    linksContent={exploreLinksContent}
                />
                <PromoBlockLeft
                    customClass="verticals-paid-page--new__promo-block-left promo-block-left--yellow"
                    title={t(`${promoCode}.promoBlockLeft.title`)}
                    subtitle={t(`${promoCode}.promoBlockLeft.subtitle`, {
                        defaultValue: null
                    })}
                    source="promo block left - yellow"
                    promoCode={promoCode}
                />
            </div>
        </Layout>
    );
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "translations", "onlineContract", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default OnlineContract;
