import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import './testimonial-card.scss';

interface Props {
    image: string;
    text: string;
    name?: string;
    description?: string;
    companyLogo?: string;
    businessName?: string;
    customClass?: string;
    lazyLoad?: boolean;
    personalInfo?: { name: string; job: string; location: string };
}

const TestimonialCard = ({
    image,
    text,
    name = '',
    description = '',
    companyLogo,
    businessName,
    customClass = '',
    personalInfo,
    lazyLoad = true
}: Props) => {
    const { t } = useTranslation();

    return (
        <div className={`testimonial-card ${customClass}`}>
            {!personalInfo ? (
                <div className="testimonial-card__image-container">
                    <HBMediaElement
                        customClass="testimonial-card__face"
                        radius="max"
                        gravity="faces"
                        crop="thumb"
                        name={image}
                        dpr={2}
                        width={80}
                        height={80}
                        lazyLoad={lazyLoad}
                        alt={t(name)}
                    />
                    {companyLogo && (
                        <HBMediaElement
                            customClass="testimonial-card__logo"
                            name={companyLogo}
                            dpr={2}
                            alt={t(description)}
                        />
                    )}
                    {!companyLogo && businessName && (
                        <p className="testimonial-card__business-name">
                            {businessName}
                        </p>
                    )}
                </div>
            ) : (
                <div className="testimonial-card__header-container">
                    <HBMediaElement
                        customClass="testimonial-card__face"
                        radius="max"
                        gravity="faces"
                        crop="thumb"
                        name={image}
                        dpr={2}
                        width={80}
                        height={80}
                        alt={t(name)}
                    />
                    <div className="testimonial-card__header-text-container">
                        <p className="testimonial-card__header-name">
                            {personalInfo.name}
                        </p>
                        <p className="testimonial-card__header-job">
                            {personalInfo.job}
                        </p>
                        {personalInfo.location && (
                            <p className="testimonial-card__header-location">
                                {personalInfo.location}
                            </p>
                        )}
                    </div>
                </div>
            )}
            <div className="testimonial-card__text">
                &ldquo;{t(text)}&rdquo;
            </div>
            {name && <div className="testimonial-card__name">{t(name)}</div>}
            {description && (
                <div className="testimonial-card__title">{t(description)}</div>
            )}
        </div>
    );
};

export default memo(TestimonialCard);
