import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText,
    FeatureBlockImage
} from '../../FeatureBlockElements';

import './contract-template-post-hero.scss';

const ContractTemplatePostHero = ({ t, contentKey, imageUrl }) => {
    return (
        <div className="contract-template-post-hero">
            <FeatureBlockText
                primary={t(`contractTemplatePage.${contentKey}.postHero.title`)}
                fullWidth
            />
            <FeatureBlockSection>
                <FeatureBlockContent customClass="contract-template-post-hero__text">
                    <FeatureBlockText
                        bodyText={t(
                            `contractTemplatePage.${contentKey}.postHero.bodyText`
                        )}
                    />
                    <RegistrationForm
                        source="design ideal agreement"
                        buttonText={t(
                            `contractTemplatePage.${contentKey}.postHero.ctaText`
                        )}
                    />
                </FeatureBlockContent>
                <FeatureBlockContent customClass="contract-template-post-hero__image">
                    <FeatureBlockImage
                        featureImage={imageUrl}
                        width={800}
                        crop="scale"
                        lazyLoad={false}
                        alt={
                            t(
                                `contractTemplatePage.${contentKey}.postHero.imageAlt`
                            ) ||
                            t(
                                `contractTemplatePage.${contentKey}.postHero.title`
                            )
                        }
                    />
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

ContractTemplatePostHero.propTypes = {
    t: PropTypes.func,
    contentKey: PropTypes.string,
    imageUrl: PropTypes.string
};

export default withTranslation()(ContractTemplatePostHero);
