import { useState } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import MissionStatementHero from '../components/FeatureBlocks/MissionStatementHero/MissionStatementHero';

import '../styles/mission-statement.scss';
import HowItWorksBlock from '../components/FeatureBlockElements/HowItWorksBlock/HowItWorksBlock';
import MissionStatementQuiz from '../components/FeatureBlocks/MissionStatementQuiz/MissionStatementQuiz';
import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';
import NeedMissionStatementBlock from '../components/FeatureBlockElements/NeedMissionStatementBlock/NeedMissionStatementBlock';
import MissionStatementExamplesBlock from '../components/FeatureBlockElements/MissionStatementExamplesBlock/MissionStatementExamplesBlock';
import MissionStatementSamplesBlock from '../components/FeatureBlockElements/MissionStatementSamplesBlock/MissionStatementSamplesBlock';
import MissionStatementTipsBlock from '../components/FeatureBlockElements/MissionStatementTipsBlock/MissionStatementTipsBlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import BlogLinkBlock from '../components/BlogLinkBlock/BlogLinkBlock';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import HBButton from '../components/honeybook-ui/HBButton/HBButton';

const ogTags = {
    url: 'https://www.honeybook.com/mission-statement',
    title: 'Mission Statement Generator & Examples',
    description:
        'Bring your vision to light! Our mission statement generator is free and easy to use. See examples of the best business mission statements. Then, write your own.'
};

const blogLinks = [
    {
        titleId: 'missionStatement.blogLinks.blogs.blog1',
        imagePath:
            'marketing_site/mission-statement/mission_statement_post_2.png',
        url: '/blog/mission-statement'
    },
    {
        titleId: 'missionStatement.blogLinks.blogs.blog2',
        imagePath:
            'marketing_site/mission-statement/mission_statement_post_1.png',
        url: '/blog/mission-statement-examples'
    }
];

const faqs = [
    {
        id: 'missionStatementCreate'
    },
    {
        id: 'missionStatementWho'
    },
    {
        id: 'missionStatementVision'
    },
    {
        id: 'missionStatementGood'
    },
    {
        id: 'missionStatementExamples'
    },
    {
        id: 'missionStatementContents'
    }
];

const MissionStatement = () => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();
    const [isQuizOpen, setIsQuizOpen] = useState(false);

    const handleOpenModal = source => {
        AnalyticsService.trackClick(
            AnalyticsEvents.lp_invoice_mission_statement_start_quiz,
            {
                source
            }
        );
        setIsQuizOpen(true);
    };

    const handleCloseModal = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.lp_invoice_mission_statement_close_quiz
        );
        setIsQuizOpen(false);
    };

    return (
        <Layout ogTags={ogTags}>
            <div className="mission-statement">
                <link
                    href="https://fonts.googleapis.com/css?family=Playfair+Display:700|Roboto+Slab"
                    rel="stylesheet"
                ></link>
                <MissionStatementQuiz
                    isOpen={isQuizOpen}
                    onClose={handleCloseModal}
                />
                <MissionStatementHero
                    onClick={() => handleOpenModal('hero cta')}
                />
                {breakpoints.mediumUp && (
                    <>
                        <HowItWorksBlock
                            onClick={() =>
                                handleOpenModal('how it works block cta')
                            }
                        />
                        <NeedMissionStatementBlock />
                        <MissionStatementExamplesBlock />
                    </>
                )}
                <MissionStatementSamplesBlock />

                {breakpoints.mediumUp && <MissionStatementTipsBlock />}

                <BlogLinkBlock
                    title={t('missionStatement.blogLinks.title')}
                    subtitle={t('missionStatement.blogLinks.subtitle')}
                    blogs={blogLinks}
                >
                    {
                        <HBButton
                            customClass="mission-statement__blog-links__cta"
                            onClick={() =>
                                handleOpenModal('blog links block cta')
                            }
                            text={t('missionStatement.hero.ctaText')}
                            size="large"
                        />
                    }
                </BlogLinkBlock>

                <FAQBlock
                    title={t(`missionStatement.faqs.title`)}
                    faqs={faqs}
                />
            </div>
        </Layout>
    );
};

export default MissionStatement;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["missionStatement", "common", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
