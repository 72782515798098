import { memo } from 'react';
import classnames from 'classnames';

import type { ReactNode } from 'react';

import UtilsService from '../../../Services/UtilsService';

import './feature-block-content.scss';

import type { Display } from '../../../Services/UtilsService';

interface Props {
    children?: ReactNode;
    customClass?: string;
    position?: string;
    display?: Display;
}

function FeatureBlockContent({
    customClass = '',
    children,
    position,
    display
}: Props): React.ReactElement {
    const className = classnames('feature-block__content', {
        [`${customClass}`]: Boolean(customClass),
        'feature-block__content--right': position === 'right',
        'feature-block__content--center': position === 'center',
        'feature-block__content--left': position === 'left',
        [UtilsService.screens(display)]: Boolean(display)
    });

    return <div className={className}>{children}</div>;
}

export default memo(FeatureBlockContent);
