import { memo } from 'react';
import { createPortal } from 'react-dom';

import './hb-drawer.scss';
import type { ReactNode } from 'react';

interface Props {
    customClass?: string;
    e2eTestLocator?: string;
    children: ReactNode;
}

function Drawer({ children }) {
    return createPortal(
        children,
        document.querySelector('body') as HTMLBodyElement
    );
}

function HBDrawer({
    customClass,
    children,
    e2eTestLocator
}: Props): JSX.Element {
    return (
        <Drawer>
            <div
                e2e-test-locator={e2eTestLocator}
                className={`${customClass} hbui-drawer__container`}
            >
                {children}
            </div>
        </Drawer>
    );
}

export default memo(HBDrawer);
