import Layout from '../components/Layout/Layout';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { usePromoCode } from '../hooks/usePromoCode';
import { useState } from 'react';
import EmailSignatureHero from '../components/EmailSignature/EmailSignatureHero';
import EmailSignatureBlock from '../components/EmailSignature/EmailSignatureBlock';
import EmailSignatureTemplateGallery from '../components/EmailSignature/EmailSignatureTemplateGallery';
import AllInOnePlaceCtaBlock from '../components/EmailSignature/AllInOnePlaceCtaBlock';
import DiscoverFreeToolsBlock, {
    TFreeToolsData
} from '../components/FeatureBlocks/DiscoverFreeToolsBlock/DiscoverFreeToolsBlock';
import FeatureListBlock from '../components/FeatureBlocks/FeatureListBlock/FeatureListBlock';
import MobileReviewsBlock from '../components/FeatureBlocks/MobileReviewsBlock/MobileReviewsBlock';
import DiscoverLinkBlock, {
    LinkDataType
} from '../components/FeatureBlocks/DiscoverLinkBlock/DiscoverLinkBlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';

import { INITIAL_EMAIL_SIGNATURE_DISPLAY_VALUE } from '../constants/constants';

import '../styles/email-signature.scss';

const ogTags = {
    url: 'https://www.honeybook.com/email-signature',
    title: 'Email Signature Generator | Free, Fast & Easy ',
    description:
        "Effortlessly create a professional email signature with HoneyBook's free email signature generator. Enhance your communication and make a lasting impression."
};

const cardLinkData: LinkDataType[] = [
    {
        imageUrl:
            '/marketing_site/email-signature/card-links-block/email-signature.png',
        id: 'emailSignature',
        url: 'https://www.honeybook.com/blog/email-signature-templates'
    },
    {
        imageUrl:
            '/marketing_site/email-signature/card-links-block/best-practices.png',
        id: 'bestPractices',
        url: 'https://www.honeybook.com/blog/email-signature-best-practices'
    },
    {
        imageUrl:
            '/marketing_site/email-signature/card-links-block/automations.png',
        id: 'gmail',
        url: 'https://www.honeybook.com/blog/gmail-signature'
    }
];

const freeToolsData: TFreeToolsData[] = [
    {
        imgUrl: 'marketing_site/email-signature/discover-free-tools/online-invoice-maker.png',
        label: 'Online invoice maker',
        url: '/online-invoices'
    },
    {
        imgUrl: 'marketing_site/email-signature/discover-free-tools/mission-statement.png',
        label: 'Mission statement generator',
        url: '/mission-statement'
    },
    {
        imgUrl: 'marketing_site/email-signature/discover-free-tools/business-personality-test.png',
        label: 'Business personality test',
        url: '/business-personality-test'
    }
];

const faqs = [
    {
        id: 'whoShouldUseEmailSignature'
    },
    {
        id: 'howCreateEmailSignature'
    },
    {
        id: 'whatShouldIncludeEmailSignature'
    },
    {
        id: 'howToPickEmailSignature'
    },
    {
        id: 'gmailOnlyEmailSignature'
    },
    {
        id: 'gmailEmailSignature'
    },
    {
        id: 'yahooEmailSignature'
    },
    {
        id: 'outlookEmailSignature'
    }
];

function EmailSignature() {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();
    const [selectedTemplateName, setSelectedTemplateName] = useState(
        INITIAL_EMAIL_SIGNATURE_DISPLAY_VALUE.selectedTemplateName
    );

    return (
        <Layout ogTags={ogTags} customClass="email-signature">
            <EmailSignatureHero />
            <EmailSignatureTemplateGallery
                setSelectedTemplateName={setSelectedTemplateName}
                isMobile
            />
            <EmailSignatureBlock
                selectedTemplateNameProp={selectedTemplateName}
                setSelectedTemplateNameProp={setSelectedTemplateName}
            />
            <EmailSignatureTemplateGallery
                setSelectedTemplateName={setSelectedTemplateName}
            />
            <AllInOnePlaceCtaBlock />
            <DiscoverFreeToolsBlock
                title={t('discoverFreeToolsBlock.title')}
                freeToolsData={freeToolsData}
            />
            <FeatureListBlock title={t('emailSignature.features.title')} />
            <MobileReviewsBlock />
            <DiscoverLinkBlock
                customTitle={t('DiscoverLinkBlock.title')}
                customSubtitle={t('DiscoverLinkBlock.subtitle')}
                linkData={cardLinkData}
            />
            <FAQBlock
                customClass="email-signature__faqs"
                faqs={faqs}
                title={t('faqsBlock.title')}
            />
            <PromoBlockLeft
                customClass="email-signature__promo-block-left promo-block-left--yellow"
                title={t(`${promoCode}.promoBlockLeft.title`)}
                subtitle={t(`${promoCode}.promoBlockLeft.subtitle`, {
                    defaultValue: null
                })}
                source="promo block left - yellow"
                promoCode={promoCode}
            />
        </Layout>
    );
}

export default EmailSignature;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: ["emailSignatureGenerator", "home", "common", "faqs"]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
