import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import './basic-stats-block.scss';
import HBText from '../../honeybook-ui/HBText/HBText';

export interface TStats {
    title: JSX.Element;
    subtitle: JSX.Element;
}
interface Props {
    stats: TStats[];
}

const BasicStatsBlock = ({ stats }: Props) => {
    return (
        <FeatureBlockSection customClass="basic-stats-block">
            <FeatureBlockContent>
                <div className="basic-stats-block-container">
                    {stats.map(({ title, subtitle }) => {
                        return (
                            <div className="basic-stat">
                                <HBText
                                    tag="h4"
                                    type="title-l"
                                    customClass="basic-stat__number"
                                >
                                    {title}
                                </HBText>
                                <HBText
                                    tag="p"
                                    type="body-l"
                                    customClass="basic-stat__description"
                                >
                                    {subtitle}
                                </HBText>
                            </div>
                        );
                    })}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default BasicStatsBlock;
