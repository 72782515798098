import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';

import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import './image-text-element-block.scss';

const ImageTextElementBlock = ({
    children,
    customClass,
    imageUrl,
    mediaFlags,
    lightBackground,
    darkBackground,
    imageOnTop
}) => {
    return (
        <FeatureBlockSection
            customClass={`${customClass} illustration-secondary-block`}
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent>
                {imageOnTop && imageUrl && (
                    <HBMediaElement name={imageUrl} {...mediaFlags} />
                )}
                {children}
                {!imageOnTop && imageUrl && (
                    <HBMediaElement name={imageUrl} {...mediaFlags} />
                )}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

ImageTextElementBlock.propTypes = {
    children: PropTypes.node,
    customClass: PropTypes.string,
    lightBackground: PropTypes.bool,
    imageUrl: PropTypes.string,
    mediaFlags: PropTypes.shape({}),
    darkBackground: PropTypes.bool,
    imageOnTop: PropTypes.bool
};

export default withTranslation()(ImageTextElementBlock);
