import type {
    FallbackVariants,
    TExposedUserTests,
    TUserABTests,
    TUserTestProperties,
    TestNames,
    TVariant
} from '../ab-tests/types/ab-test-types';
import AnalyticsService, { AnalyticsEvents } from './AnalyticsService';
import CookiesService from './CookiesService';
import StorageService from './StorageService';
import {
    AB_TESTS_STORAGE_KEY,
    AB_TEST_COOKIE_KEY_STATSIG,
    TESTS_SEPARATOR,
    TEST_PROPERTIES_SEPARATOR
} from '../../static/ab-test-shared-constants';
import UtilsService from './UtilsService';
import { testsConfig } from '../../static/tests-config';
import { isTestExist } from '../../static/ab-test-shared-utils';
import { FALLBACK_VARIANT } from '../ab-tests/constants';

export class ABTestToolUtils {
    static getUserTestsFromCookie() {
        return CookiesService.getCookie(AB_TEST_COOKIE_KEY_STATSIG).split(
            TESTS_SEPARATOR
        );
    }

    static getUserExposedTests(): TExposedUserTests {
        return StorageService.getItem(AB_TESTS_STORAGE_KEY) || {};
    }

    static setUserExposedTests(updatedExposureTests: TExposedUserTests) {
        StorageService.setItem({
            key: AB_TESTS_STORAGE_KEY,
            data: updatedExposureTests
        });
    }

    static updateUserExposedTests(userTests: TUserABTests) {
        const exposureTests = this.getUserExposedTests();
        const updatedExposureTests = Object.keys(exposureTests)
            .filter(testName => !!userTests[testName])
            .reduce((exposureTests, testName) => {
                exposureTests[testName] = true;
                return exposureTests;
            }, {});
        this.setUserExposedTests(updatedExposureTests);
    }

    static setAbTestCookie(cookieValue: string) {
        CookiesService.setCookie({
            cookieName: AB_TEST_COOKIE_KEY_STATSIG,
            cookieValue
        });
    }

    static getFallbackVariantsFromSessionStorage(): FallbackVariants {
        return (
            StorageService.getItem(
                FALLBACK_VARIANT.STORAGE_KEY,
                'sessionStorage'
            ) || {}
        );
    }

    static getFallbackVariant(path: string) {
        const fallbackVariants = this.getFallbackVariantsFromSessionStorage();
        return fallbackVariants[path] || {};
    }

    static handleFallbackVariants() {
        const { pathname } = window.location;
        const fallbackVariants = this.getFallbackVariantsFromSessionStorage();
        if (fallbackVariants[pathname]) {
            delete fallbackVariants[pathname];
            this.setFallbackVariant(fallbackVariants);
        }

        const fallbackVariantsElement = document.querySelector(
            `.${FALLBACK_VARIANT.CLASS_NAME}`
        ) as HTMLElement;
        const pageFallbackVariants = fallbackVariantsElement?.innerText;
        if (pageFallbackVariants) {
            fallbackVariants[pathname] = JSON.parse(pageFallbackVariants);
            this.setFallbackVariant(fallbackVariants);
        }
    }

    static setFallbackVariant(data: FallbackVariants) {
        StorageService.setItem({
            key: FALLBACK_VARIANT.STORAGE_KEY,
            data,
            storageType: 'sessionStorage'
        });
    }

    static reportMissingTests({
        test_name,
        fallback_variant,
        caller,
        path
    }: {
        test_name: string;
        fallback_variant: string;
        caller;
        path: string;
    }) {
        const AB_TEST_TOOL_ERROR_DATA = {
            caller,
            is_cookies_enabled: window.navigator.cookieEnabled,
            ab_test_tool_cookie: CookiesService.getCookie(
                AB_TEST_COOKIE_KEY_STATSIG
            ),
            test_name,
            fallback_variant,
            page_path: path
        };

        AnalyticsService.track(
            AnalyticsEvents.ab_test_tool_error,
            AB_TEST_TOOL_ERROR_DATA
        );

        if (process.env.GATSBY_env !== 'production') {
            console.info(AB_TEST_TOOL_ERROR_DATA);
        }
    }

    static getTestProperties(
        testName: TestNames,
        variant: TVariant,
        isFallbackVariant: boolean
    ): TUserTestProperties {
        const testConfig = testsConfig[testName]!;
        return {
            ab_test_name: testName,
            ab_test_variation: variant,
            description: testConfig.description,
            variant: isFallbackVariant
                ? testConfig.controlVariantName
                : variant,
            ...(isFallbackVariant && {
                is_not_passed_gate: true
            })
        };
    }

    static handleDevCookie() {
        const userTests = this.getUserTestsFromCookie();
        const userTestsMap = userTests.reduce((userTestsMap, test) => {
            const [testName, variant] = test.split(TEST_PROPERTIES_SEPARATOR);
            if (!isTestExist(testName, variant)) {
                return userTestsMap;
            }
            userTestsMap[testName] = variant;
            return userTestsMap;
        }, {});
        const testNames = Object.keys(testsConfig);
        const cookieValue = testNames
            .map(testName => this.getTestCookieString(testName, userTestsMap))
            .join(TESTS_SEPARATOR);
        this.setAbTestCookie(cookieValue);
    }

    static getTestCookieString(
        testName: string,
        userTestsMap: Record<string, string>
    ) {
        let variant;
        const testConfig = testsConfig[testName];
        const paramVariant = UtilsService.getParameterByName(testName) || '';
        if (testConfig.variants[paramVariant]) {
            variant = paramVariant;
        } else {
            variant = userTestsMap[testName] || testConfig.controlVariantName;
            !!paramVariant &&
                console.error(
                    `%cError: Variant "${paramVariant}" for test "${testName}" test is not valid!
                    \nfallback to "${variant}"`,
                    'color: red; font-size: 14px; font-weight: bold;'
                );
        }
        return `${testName}${TEST_PROPERTIES_SEPARATOR}${variant}`;
    }
}
