import { memo } from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';

import './feature-block-divider.scss';

interface Props {
    hideBorder?: boolean;
    paddingBottomNone?: boolean;
}

const FeatureBlockDivider = ({
    hideBorder,
    paddingBottomNone
}: Props): JSX.Element => {
    const className = classnames('feature-block__divider', {
        'feature-block__divider--hide-border': hideBorder,
        'feature-block__divider--padding-bottom-none': paddingBottomNone
    });

    return <div className={className} />;
};

FeatureBlockDivider.propTypes = {
    hideBorder: PropTypes.bool,
    paddingBottomNone: PropTypes.bool
};

export default memo(FeatureBlockDivider);
