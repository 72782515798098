import { LegacyRef, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import Card from '../../HBCard/Card';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import UtilsService from '../../../Services/UtilsService';
import type { TButtonStyle } from '../../honeybook-ui/HBButton/HBButton';

import './feature-signature-block.scss';

const SIGNATURE_LENGTH = 2254;

interface Card {
    iconUrl: string;
    transId: string;
}
interface Props {
    cardContents: Card[];
    ctaSource: string;
    buttonStyle?: TButtonStyle;
    transId?: string;
}

function FeatureSignatureBlock({
    cardContents,
    buttonStyle = 'primary--dark',
    ctaSource,
    transId = 'featureSignatureBlock'
}: Props): JSX.Element {
    const { t } = useTranslation();
    const isVisible = useRef(false);
    const signatureRef: LegacyRef<any> = useRef();
    const titleRef: LegacyRef<any> = useRef();

    const scrollHandler = () => {
        if (isVisible.current) {
            const sectionYPos =
                signatureRef.current.getBoundingClientRect().top /
                window.innerHeight;

            if (sectionYPos > 1) return;

            signatureRef.current.style.strokeDashoffset = Math.max(
                sectionYPos * SIGNATURE_LENGTH,
                0
            );

            titleRef.current.style.setProperty(
                '--marker-width',
                120 - (100 * sectionYPos) / 0.75 + '%'
            );
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            isVisible.current = entries[0].isIntersecting ? true : false;
        });
        observer.observe(signatureRef.current);
        window.addEventListener(
            'scroll',
            UtilsService.throttle(() => {
                scrollHandler();
            }, 50)
        );
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    return (
        <FeatureBlockSection customClass="feature-signature-block">
            <FeatureBlockContent customClass="feature-signature-block__content">
                <div
                    className="feature-signature-block__title-wrapper"
                    ref={titleRef}
                >
                    <HBText
                        customClass="feature-signature-block__title"
                        tag="h2"
                        type="title-l"
                    >
                        {t(`${transId}.title`)}
                    </HBText>
                </div>
                <div className="feature-signature-block__cards-container">
                    {cardContents.map(cardContent => (
                        <Card
                            customClass="feature-signature-block__card"
                            key={cardContent.transId}
                        >
                            <HBMediaElement name={cardContent.iconUrl} />
                            <HBText
                                tag="h3"
                                type="title-m"
                                customClass="feature-signature-block__card__title"
                            >
                                {t(
                                    `${transId}.cards.${cardContent.transId}.title`
                                )}
                            </HBText>
                            <HBText
                                tag="p"
                                type="body-m"
                                customClass="feature-signature-block__card__description"
                            >
                                {t(
                                    `${transId}.cards.${cardContent.transId}.description`
                                )}
                            </HBText>
                        </Card>
                    ))}
                </div>

                <RegistrationForm
                    customClass="feature-signature-block__button"
                    source={ctaSource}
                    buttonStyle={buttonStyle}
                    size="large"
                    showNoCcRequired
                />
            </FeatureBlockContent>

            <svg
                width="1280"
                height="440"
                viewBox="0 0 1280 440"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="feature-signature-block__signature"
                ref={signatureRef}
                style={{
                    strokeDasharray: SIGNATURE_LENGTH,
                    strokeDashoffset: SIGNATURE_LENGTH
                }}
            >
                <path
                    d="M-59 212.469C-1.4 247.669 73 358 382 296.351C481 271.187 654.25 178.913 662.5 78.2602C670.75 -22.3921 547 19.5359 514 111.808C481 204.08 472.75 321.516 588.25 296.351C703.75 271.187 728.5 145.365 736.75 212.469C743.913 270.733 728.5 321.516 778 296.351C827.5 271.187 852.25 262.801 893.5 296.351C962.5 362.068 1048 472 1330 393.5"
                    stroke="#F4EAE0"
                    strokeWidth="38.0904"
                />
            </svg>
        </FeatureBlockSection>
    );
}

export default FeatureSignatureBlock;
