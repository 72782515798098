const DEVICE_OPTIONS = [
    {
        regex: /Android/i,
        deviceType: 'Android'
    },
    {
        regex: /BlackBerry/i,
        deviceType: 'BlackBerry'
    },
    {
        regex: /iPhone/i,
        deviceType: 'iPhone'
    },
    {
        regex: /iPad/i,
        deviceType: 'iPad'
    },
    {
        regex: /iPod/i,
        deviceType: 'iPod'
    },
    {
        regex: /Opera Mini/i,
        deviceType: 'Opera'
    },
    {
        regex: /IEMobile/i,
        deviceType: 'Windows'
    }
] as const;

const MOBILE_IOS = 'iOS';
const MOBILE_ANDROID = 'Android';

type TMobileOs = {
    mobile_os: typeof MOBILE_IOS | typeof MOBILE_ANDROID;
    ios_version?: string;
    android_version?: string;
};

class DeviceService {
    private _mobileDevice: typeof DEVICE_OPTIONS[number]['deviceType'];
    private mobileOs: TMobileOs;
    constructor() {
        this.setMobileDevice();
    }

    private setMobileDevice() {
        if (typeof window === 'undefined') return;
        for (const device of DEVICE_OPTIONS) {
            if (navigator.userAgent.match(device.regex)) {
                this._mobileDevice = device.deviceType;
            }
        }
    }

    getMobileOs(): TMobileOs {
        if (this.mobileOs) {
            return this.mobileOs;
        }
        const osParams = {} as TMobileOs;
        const ua = navigator.userAgent;
        let uaindex;

        // determine OS
        if (ua.match(/iPad/i) || ua.match(/iPod/i) || ua.match(/iPhone/i)) {
            osParams.mobile_os = MOBILE_IOS;
            uaindex = ua.indexOf('OS ');
        } else if (ua.match(/Android/i)) {
            osParams.mobile_os = MOBILE_ANDROID;
            uaindex = ua.indexOf('Android ');
        }

        // determine version
        if (osParams.mobile_os === MOBILE_IOS && uaindex > -1) {
            const indexOfIosVersion = uaindex + 3;
            osParams.ios_version = ua
                .substring(
                    indexOfIosVersion,
                    ua.indexOf(' ', indexOfIosVersion)
                )
                .replace(/_/g, '.');
        } else if (osParams.mobile_os === MOBILE_ANDROID && uaindex > -1) {
            const indexOfAndroidVersion = uaindex + 8;
            osParams.android_version = ua.substring(
                indexOfAndroidVersion,
                ua.indexOf(';', indexOfAndroidVersion)
            );
        }
        this.mobileOs = osParams;
        return this.mobileOs;
    }

    get isMobile() {
        return !!this._mobileDevice;
    }

    get mobileDevice() {
        return this._mobileDevice;
    }

    get isChrome() {
        return navigator.userAgent.includes('Chrome');
    }

    get isIOS() {
        return this.getMobileOs().mobile_os === MOBILE_IOS;
    }
}

export default new DeviceService();
