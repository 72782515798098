import { memo, useCallback, useMemo, ReactNode } from 'react';
import * as PropTypes from 'prop-types';

import AnalyticsService, {
    AnalyticsEvents,
    GTM_ANALYTICS_EVENTS
} from '../../Services/AnalyticsService';
import SocialIcon from './SocialIcon';

import FacebookIcon from '../../svg/socialMedia/facebook.inline.svg';
import TwitterIcon from '../../svg/socialMedia/twitter.inline.svg';
import InstagramIcon from '../../svg/socialMedia/instagram.inline.svg';
import LinkedInIcon from '../../svg/socialMedia/linkedIn.inline.svg';
import PinterestIcon from '../../svg/socialMedia/pinterest.inline.svg';
import YoutubeIcon from '../../svg/socialMedia/youtube.inline.svg';

import './social-icons.scss';

import type { CustomData } from '../Coaching/CoachingHero/CoachingHero';

interface Props {
    context: {
        source: string;
    };
    customData?: CustomData[];
    source: string;
}

interface TSocialMediaData {
    id: string;
    link: string;
    icon: ReactNode;
}

const SocialIconsStore: TSocialMediaData[] = [
    {
        id: 'facebook',
        link: '//www.facebook.com/honeybook.co',
        icon: <FacebookIcon />
    },
    {
        id: 'instagram',
        link: '//instagram.com/honeybook',
        icon: <InstagramIcon />
    },
    {
        id: 'youtube',
        link: '//www.youtube.com/@HoneyBook',
        icon: <YoutubeIcon />
    },
    {
        id: 'pinterest',
        link: '//www.pinterest.com/honeybook',
        icon: <PinterestIcon />
    },
    {
        id: 'twitter',
        link: '//twitter.com/honeybook',
        icon: <TwitterIcon />
    },
    {
        id: 'linkedin',
        link: '//www.linkedin.com/company/honeybook',
        icon: <LinkedInIcon />
    }
];

function SocialIcons({ context, customData, source }: Props): JSX.Element {
    const handleAnalytics = useCallback(
        ({ id, link }: CustomData) => {
            AnalyticsService.trackGtm(GTM_ANALYTICS_EVENTS.social_interaction, {
                interaction_type: 'follow',
                social: id,
                link,
                section: source
            });
            if (customData) {
                AnalyticsService.trackClick(
                    AnalyticsEvents.social_media_links,
                    {
                        source: context.source,
                        variant: id
                    }
                );
            } else {
                AnalyticsService.track(
                    AnalyticsEvents.footer_navigation_button_click,
                    {
                        variant: id
                    }
                );
            }
        },
        [context.source, customData]
    );

    const socialData = useMemo(
        () => customData || SocialIconsStore,
        [customData]
    );

    return (
        <div className="social-icons">
            {socialData.map((item: CustomData) => (
                <SocialIcon
                    key={item.id}
                    item={item}
                    handleAnalytics={handleAnalytics}
                />
            ))}
        </div>
    );
}

SocialIcons.propTypes = {
    context: PropTypes.shape({
        source: PropTypes.string.isRequired
    }),
    customData: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired
        })
    )
};

export default memo(SocialIcons);
