import Layout from '../components/Layout/Layout';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import TaxCalculatorHero from '../components/FeatureBlocks/TaxCalculatorHero/TaxCalculatorHero';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import { usePromoCode } from '../hooks/usePromoCode';

import '../styles/tax-calculator.scss';
import DiscoverLinkBlock from '../components/FeatureBlocks/DiscoverLinkBlock/DiscoverLinkBlock';
import TaxFilingBlock from '../components/FeatureBlocks/TaxFilingBlock/TaxFilingBlock';
import TaxFAQBlock from '../components/FeatureBlocks/TaxFAQBlock/TaxFAQBlock';
import StarReviews from '../components/StarReviews/StarReviews';

const blogLinkData = [
    {
        id: 'diy',
        url: '/blog/the-diy-tax-filing-checklist-that-every-small-business-owner-needs'
    },
    {
        id: 'deductions',
        url: '/blog/creative-business-tax-deductions'
    },
    {
        id: 'expenses',
        url: '/blog/how-to-track-expenses-for-small-business-owners'
    },
    {
        id: 'creative',
        url: '/blog/creative-business-tax-deductions'
    }
];

interface Props {
    pageContext: {
        USState: string;
    };
}

function TaxCalculatorPage({ pageContext: { USState } }: Props): JSX.Element {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    const pageTitle = USState
        ? `Free Self-Employment Tax Calculator for ${t(`states.${USState}`)}`
        : 'Free Self-Employment Tax Calculator';

    const pageUrl = USState
        ? `https://www.honeybook.com/tools/self-employment-tax-calculator-${t(
              `states.${USState}`
          )
              .toLowerCase()
              .replace(/ /g, '-')}`
        : 'https://www.honeybook.com/tools/self-employment-tax-calculator';

    const ogTags = {
        url: pageUrl,
        title: pageTitle,
        description:
            "Calculate the total income tax estimation for your independent business with HoneyBook's free tax calculator. Start your 7-day free trial with HoneyBook."
    };

    return (
        <Layout customClass="tax-calculator-page" ogTags={ogTags}>
            <TaxCalculatorHero USState={USState} />
            <TaxFAQBlock USState={USState} />
            <TaxFilingBlock />
            <DiscoverLinkBlock
                customTitle={t(`taxCalculator.discoverLinkBlock.title`)}
                customSubtitle={t(`taxCalculator.discoverLinkBlock.subtitle`)}
                linkData={blogLinkData}
            />
            <StarReviews />
            <PromoBlockLeft
                customClass="tax-calculator-page--new__promo-block-left promo-block-left--yellow"
                title={t(`${promoCode}.promoBlockLeft.title`)}
                subtitle={t(`${promoCode}.promoBlockLeft.subtitle`, {
                    defaultValue: null
                })}
                source="promo block left - yellow"
                promoCode={promoCode}
            />
        </Layout>
    );
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["taxCalculator", "common", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default TaxCalculatorPage;
