import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './think-of-switching.scss';

const ThinkOfSwitchingBlock = ({ t, title, preTitle }) => {
    return (
        <FeatureBlockSection lightBackground customClass="think-of-switching">
            <FeatureBlockContent>
                <FeatureBlockText
                    fullWidth
                    primary={t(title)}
                    preTitle={t(preTitle)}
                />
                <div className="feature-block__signup-wrapper">
                    <RegistrationForm
                        source="thinking-of-switching"
                        customClass=""
                        showNoCcRequired
                    />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

ThinkOfSwitchingBlock.propTypes = {
    t: PropTypes.func.isRequired,
    title: PropTypes.string,
    preTitle: PropTypes.string
};

export default withTranslation()(ThinkOfSwitchingBlock);
