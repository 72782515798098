import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import { useTranslation, Trans } from 'react-i18next';

import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './vertical-email-templates-block.scss';

interface Props {
    contentKey: string;
    imageAlt?: string;
    image?: string;
}

const VerticalEmailTemplatesBlock = ({
    contentKey,
    imageAlt,
    image = 'marketing_site/vertical-lps/template-images/email-template-1.png'
}: Props) => {
    const { t } = useTranslation();

    return (
        <div className="vertical-email-templates-block">
            <FeatureBlockSection customClass="vertical-email-templates-block__text-content">
                <FeatureBlockContent>
                    <FeatureBlockText
                        fullWidth
                        primary={t(`${contentKey}.emailTemplates.title`)}
                    />
                    {/*@ts-ignore*/}
                    <Trans key={t(`${contentKey}.emailTemplates.text`)}>
                        <div className="feature-block__text-body ">
                            {t(`${contentKey}.emailTemplates.text`)}
                        </div>
                    </Trans>
                </FeatureBlockContent>
            </FeatureBlockSection>

            <FeatureBlockSection customClass="vertical-email-templates-block__gallery">
                <HBMediaElement
                    crop="scale"
                    width={800}
                    name="marketing_site/vertical-lps/template-images/email-template-2.png"
                    alt={imageAlt || 'Email templates'}
                />
                <HBMediaElement
                    crop="scale"
                    width={800}
                    name={image}
                    alt={imageAlt || 'Email templates'}
                />
            </FeatureBlockSection>
        </div>
    );
};

export default VerticalEmailTemplatesBlock;
