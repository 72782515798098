import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import ToggleSelector from '../../ToggleSelector/ToggleSelector';

import './feature-preview-block.scss';
import HBVideo from '../../honeybook-ui/HBVideo/HBVideo';

interface ContentType {
    videoId?: string;
    imgUrl?: string;
    mobileImgUrl: string;
}
interface PreviewContent {
    client: ContentType;
    your: ContentType;
}

interface Props {
    transId: string;
    previewContent: PreviewContent;
}

const FeaturePreviewBlock = ({ transId, previewContent }: Props) => {
    const { t } = useTranslation();
    const { mediumUp } = useBreakpoint();

    const [isClientView, setIsClientView] = useState(true);

    const toggleButtons = [
        {
            id: 'client-view',
            text: t(`${transId}.client`)
        },
        {
            id: 'your-view',
            text: t(`${transId}.your`)
        }
    ];

    const handleToggle = () => {
        setIsClientView(!isClientView);
    };

    const clientContent =
        previewContent.client.videoId && mediumUp ? (
            <HBVideo
                autoplay
                loop
                muted
                videoId={previewContent.client.videoId}
                title={t('BestExperienceBlock.title')}
                padding="66.18% 0 0 0"
                iframeClass="feature-preview-block__video"
            />
        ) : (
            <HBMediaElement
                name={
                    mediumUp
                        ? previewContent.client.imgUrl
                        : previewContent.client.mobileImgUrl
                }
                customClass="feature-preview-block__preview feature-preview-block__preview--client"
                lazyLoad={false}
            />
        );

    return (
        <FeatureBlockSection customClass="feature-preview-block">
            <FeatureBlockContent customClass="feature-preview-block__content">
                <ToggleSelector
                    buttons={toggleButtons}
                    handleToggle={handleToggle}
                    defaultSelectedId={toggleButtons[0].id}
                />
                <div className="feature-preview-block__preview-container">
                    {isClientView ? (
                        clientContent
                    ) : (
                        <HBMediaElement
                            name={
                                mediumUp
                                    ? previewContent.your.imgUrl
                                    : previewContent.your.mobileImgUrl
                            }
                            customClass="feature-preview-block__preview feature-preview-block__preview--your"
                            lazyLoad={false}
                        />
                    )}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default FeaturePreviewBlock;
