import { useContext } from 'react';
import { EmailSignatureFormContext } from '../EmailSignatureBlock';
import FacebookIcon from './../../../svg/socialMedia/facebook-color.inline.svg';
import TwitterIcon from './../../../svg/socialMedia/twitter.inline.svg';
import LinkedinIcon from './../../../svg/socialMedia/linkedIn-color.inline.svg';
import InstagramIcon from './../../../svg/socialMedia/instagram.inline.svg';
import PinterestIcon from './../../../svg/socialMedia/pinterest-color.inline.svg';
import YoutubeIcon from './../../../svg/socialMedia/youtube-color.inline.svg';

import './email-signature-social-form.scss';

function EmailSignatureSocialForm() {
    const { formData, setFormData } = useContext(EmailSignatureFormContext);

    return (
        <form className="email-signature-social-form" autoComplete="off">
            <div className="email-signature-social-form__input-container">
                <FacebookIcon className="input-icon" />
                <input
                    type="text"
                    name="facebook"
                    value={formData.facebookUrl}
                    placeholder="Facebook"
                    onChange={ev =>
                        setFormData({
                            ...formData,
                            facebookUrl: ev.target.value
                        })
                    }
                    className="email-signature-input"
                />
            </div>
            <div className="email-signature-social-form__input-container">
                <TwitterIcon className="input-icon" />
                <input
                    type="text"
                    name="twitter"
                    value={formData.twitterUrl}
                    placeholder="Twitter"
                    onChange={ev =>
                        setFormData({
                            ...formData,
                            twitterUrl: ev.target.value
                        })
                    }
                    className="email-signature-input"
                />
            </div>
            <div className="email-signature-social-form__input-container">
                <LinkedinIcon className="input-icon" />
                <input
                    type="text"
                    name="linkedin"
                    value={formData.linkedinUrl}
                    placeholder="Linkedin"
                    onChange={ev =>
                        setFormData({
                            ...formData,
                            linkedinUrl: ev.target.value
                        })
                    }
                    className="email-signature-input"
                />
            </div>
            <div className="email-signature-social-form__input-container">
                <InstagramIcon className="input-icon" />
                <input
                    type="text"
                    name="instagram"
                    value={formData.instagramUrl}
                    placeholder="Instagram"
                    onChange={ev =>
                        setFormData({
                            ...formData,
                            instagramUrl: ev.target.value
                        })
                    }
                    className="email-signature-input"
                />
            </div>
            <div className="email-signature-social-form__input-container">
                <PinterestIcon className="input-icon" />
                <input
                    type="text"
                    name="pinterest"
                    value={formData.pinterestUrl}
                    placeholder="Pinterest"
                    onChange={ev =>
                        setFormData({
                            ...formData,
                            pinterestUrl: ev.target.value
                        })
                    }
                    className="email-signature-input"
                />
            </div>
            <div className="email-signature-social-form__input-container">
                <YoutubeIcon className="input-icon" />
                <input
                    type="text"
                    name="youtube"
                    value={formData.youtubeUrl}
                    placeholder="Youtube"
                    onChange={ev =>
                        setFormData({
                            ...formData,
                            youtubeUrl: ev.target.value
                        })
                    }
                    className="email-signature-input"
                />
            </div>
        </form>
    );
}

export default EmailSignatureSocialForm;
