import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import HBMediaElement from '../../../honeybook-ui/HBMediaElement/HBMediaElement';

import './feature-client-animation.scss';

const IMAGE_URL_PREFIX = 'marketing_site/crm-page/feature-info-block/client/';

interface Props {
    customClass: string;
}

const FeatureClientAnimation = ({ customClass }: Props) => {
    const { t } = useTranslation();

    const [messageContent, setMessageContent] = useState('');

    const handleScroll = position => {
        const messageLength = t('featureClientAnimation.messageText').length;
        const endCharIdx = messageLength * position;
        const partialMessage = t(
            'featureClientAnimation.messageText'
        ).substring(0, Math.floor(endCharIdx));
        setMessageContent(partialMessage);
    };

    return (
        <div className={`feature-client-animation ${customClass}`}>
            <ParallaxProvider scrollAxis="vertical">
                <Parallax onProgressChange={handleScroll} />
                <div className="feature-client-animation__content">
                    <HBMediaElement
                        customClass="feature-client-animation__workspace"
                        name={IMAGE_URL_PREFIX + 'workspace.png'}
                    />
                    <div className="feature-client-animation__message">
                        <div className="message-header">
                            <HBMediaElement
                                customClass="message-header__avatar"
                                name={IMAGE_URL_PREFIX + 'avatar.png'}
                            />
                            <div className="message-header__text-container">
                                <p className="feature-client-animation__text-emphasized">
                                    {t('featureClientAnimation.from')}
                                </p>
                                <p> {t('featureClientAnimation.myServices')}</p>
                            </div>
                        </div>

                        <p className="message-content">{messageContent}</p>

                        <div className="message-signature">
                            <HBMediaElement
                                customClass="message-signature__logo"
                                name={IMAGE_URL_PREFIX + 'logo.png'}
                            />
                            <div className="message-signature__text-container">
                                <p className="feature-client-animation__text-emphasized">
                                    {t('featureClientAnimation.signature.name')}
                                </p>
                                <p>
                                    {t(
                                        'featureClientAnimation.signature.description'
                                    )}
                                </p>
                                <p>
                                    {t(
                                        'featureClientAnimation.signature.address'
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="message-attachment">
                            <HBMediaElement
                                customClass="message-attachment__attachment-preview"
                                name={IMAGE_URL_PREFIX + 'attachment.png'}
                            />
                            <p> {t('featureClientAnimation.attachment')}</p>
                            <HBMediaElement
                                customClass="message-attachment__dots"
                                name={IMAGE_URL_PREFIX + 'dots.png'}
                            />
                        </div>
                    </div>
                </div>
            </ParallaxProvider>
        </div>
    );
};

export default FeatureClientAnimation;
