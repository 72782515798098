import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import PricingHeroBlock from '../components/FeatureBlocks/PricingHeroBlock/PricingHeroBlock';
import PricingBlock from '../components/FeatureBlocks/PricingBlock/PricingBlock';
import PricingTableBlock from '../components/FeatureBlocks/PricingTableBlock/PricingTableBlock';
import AllPlansIncludeBlock from '../components/FeatureBlocks/AllPlansIncludeBlock/AllPlansIncludeBlock';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';
import SocialProofBlockRebrand from '../components/FeatureBlocks/SocialProofBlockRebrand/SocialProofBlockRebrand';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import { usePricingPlans } from '../hooks/usePricingPlans';
import { useABTestContext } from '../ab-tests/context/useABTestContext';

import '../styles/pricing.scss';

const faqs = [
    {
        id: 'whatIs'
    },
    {
        id: 'HBCostPricing'
    },
    {
        id: 'freeTrialWork'
    },
    {
        id: 'plansIncluded'
    },
    {
        id: 'discountCode'
    },
    {
        id: 'changePlan'
    },
    {
        id: 'cancelPlan'
    },
    {
        id: 'paymentProcessingFees',
        answer: '#TEXT# <a href="/contact-us">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'clientSetup'
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/pricing',
    title: 'HoneyBook Pricing - Plans that scale with your business.',
    description:
        'Start growing your business today, pick a plan later. Try HoneyBook free for 7 days, no credit card required.'
};

const PricingPage = () => {
    const { isPriceMonthly, plansForDisplay, togglePricing, defaultToggleIdx } =
        usePricingPlans();

    const { t } = useTranslation();

    const {
        variantPerTest: { new_pricing_content }
    } = useABTestContext();

    const isPricingTest = new_pricing_content === 'newPricing';

    return (
        <div className={`pricing ${isPricingTest ? 'pricing--test' : ''}`}>
            <Layout ogTags={ogTags}>
                <PricingHeroBlock isPriceMonthly={isPriceMonthly} />

                <PricingBlock
                    isPriceMonthly={isPriceMonthly}
                    togglePricing={togglePricing}
                    plans={plansForDisplay}
                    defaultToggleIdx={defaultToggleIdx}
                    isPricingTest={isPricingTest}
                />

                <PricingTableBlock
                    isPriceMonthly={isPriceMonthly}
                    plans={plansForDisplay}
                />

                <AllPlansIncludeBlock />

                <SocialProofBlockRebrand />

                <FAQBlock title={t('pricingPage.faqTitle')} faqs={faqs} />

                <FreeToTryBlockRebrand />
            </Layout>
        </div>
    );
};

export default PricingPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "pricing", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
