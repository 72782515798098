const FeaturesInvoiceBlockStore = {
    blockData: {
        id: 'integrations',
        quoteMember: 'Mandy Ogaz',
        quoteAvatar:
            'marketing_site/features_page/integrations/quote-avatar.jpg',
        links: [
            {
                id: 'zoom',
                link: 'https://help.honeybook.com/en/articles/4487157-integration-with-zoom',
                image: 'marketing_site/features_page/integrations/zoom.png'
            },
            {
                id: 'quickbooks',
                link: 'https://help.honeybook.com/integrations/quickbooks-integration/quickbooks-integration-overview-faq',
                image: 'marketing_site/features_page/integrations/_svg_logo_quickbooks.svg'
            },
            {
                id: 'zapier',
                link: 'https://help.honeybook.com/integrations/calendly-and-zapier-integrations/integrating-with-zapier',
                image: 'marketing_site/features_page/integrations/_svg_logo_zapier.svg'
            },
            {
                id: 'gmail',
                link: 'https://help.honeybook.com/integrations/gmail-and-google-integration/syncing-with-gmail',
                image: 'marketing_site/features_page/integrations/_svg_logo_gmail.svg'
            },
            {
                id: 'gcalendar',
                link: 'https://help.honeybook.com/integrations/gmail-and-google-integration/understanding-your-google-calendar-integration',
                image: 'marketing_site/features_page/integrations/_svg_logo_google-cal.svg'
            }
        ]
    }
};

export default FeaturesInvoiceBlockStore;
