import { useTranslation } from 'react-i18next';
import TabsBlock from '../FeatureBlocks/TabsBlock/TabsBlock';
import HBText from '../honeybook-ui/HBText/HBText';
import EmailSignatureInfoForm from './forms/EmailSignatureInfoForm';
import EmailSignatureSocialForm from './forms/EmailSignatureSocialForm';
import EmailSignatureTemplateForm from './forms/EmailSignatureTemplateForm';
import type { TabItem } from '../honeybook-ui/HBTabs/HBTabs';

import './email-signature-form.scss';

function EmailSignatureForm() {
    const { t } = useTranslation();

    const tabsContent: TabItem[] = [
        {
            name: 'info',
            id: 'info',
            panel: <EmailSignatureInfoForm />
        },
        {
            name: 'social',
            id: 'social',
            panel: <EmailSignatureSocialForm />
        },
        {
            name: 'templates',
            id: 'templates',
            panel: <EmailSignatureTemplateForm />
        }
    ];

    return (
        <div className="email-signature-form">
            <HBText
                customClass="email-signature-form__title"
                tag="h2"
                type="title-m"
            >
                {t('emailSignatureBlock.form.title')}
            </HBText>
            <TabsBlock
                customClass="email-signature-form__tabs-block"
                tabsContent={tabsContent}
                source="email_signature_block"
            />
        </div>
    );
}

export default EmailSignatureForm;
