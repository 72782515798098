import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import FeatureTitleList from '../../FeatureTitleList/FeatureTitleList';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockUserQuote,
    FeatureBlockImage
} from '../../FeatureBlockElements';

import FeaturesInvoiceBlockStore from './FeaturesInvoiceBlockStore';
import './features-invoice-block.scss';

const { blockData } = FeaturesInvoiceBlockStore;

const handleAnalytics = () => {
    AnalyticsService.trackClick(
        AnalyticsEvents.lp_features_hub_feature_redirect,
        {
            source: 'invoice'
        }
    );
};

const FeaturesInvoiceBlock = () => {
    const { t } = useTranslation();

    const [ref, inView] = useInView({
        threshold: 0.5
    });

    const activeClass = inView ? 'feature-block--in-view' : '';

    const listName = `featuresPage.${blockData.id}.list`;
    const listItems = Object.keys(
        t(listName, {
            returnObjects: true
        })
    ).map(key => ({ id: key }));

    return (
        <div ref={ref}>
            <FeatureBlockSection
                customClass={`features-invoice-block ${activeClass}`}
            >
                <FeatureBlockContent customClass="features-invoice-block__text-container">
                    <FeatureTitleList
                        title={t(`featuresPage.${blockData.id}.title`)}
                        subtitle={t(`featuresPage.${blockData.id}.subtitle`)}
                        listName={`featuresPage.${blockData.id}.list`}
                        items={listItems}
                    />
                    <div className="features-invoice-block__link-container">
                        <a
                            onClick={handleAnalytics}
                            className="features-invoice-block-link hbui-button hbui-button--medium hbui-button--secondary"
                            href={blockData.link}
                        >
                            {t(`featuresPage.ctaText`)}
                        </a>
                    </div>

                    <FeatureBlockUserQuote
                        name={blockData.quoteMember}
                        avatar={blockData.quoteAvatar}
                        jobTitle={t(
                            `featuresPage.${blockData.id}.testimonial.company`
                        )}
                        avatarWidth={40}
                        quote={t(
                            `featuresPage.${blockData.id}.testimonial.quote`
                        )}
                        avatarCrop="thumb"
                        avatarGravity="face"
                    />
                </FeatureBlockContent>
                <FeatureBlockContent customClass="feature-block-img">
                    <FeatureBlockImage
                        customClass="feature-block__features-invoice-img"
                        featureImage={blockData.image}
                        width={405}
                        crop="scale"
                    />
                    <HBMediaElement
                        name={blockData.slideInImage}
                        width={83}
                        customClass="feature-block__slide-in"
                        lazyLoad={false}
                    />
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

export default FeaturesInvoiceBlock;
