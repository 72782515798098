import * as PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import HBButton from '@honeybook-ui/HBButton/HBButton';
import HBModal from '@honeybook-ui/HBModal/HBModal';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './international-accept-modal.scss';

const InternationalAcceptModal = ({ isOpen, onClose, email, country }) => {
    const { t } = useTranslation();

    const handleClickClose = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.on_boarding_international_accept_close
        );

        if (onClose) {
            onClose();
        }
    };

    const ButtonGroup = () => (
        <div className="international-accept-modal__button-group">
            <HBButton
                customClass="international-accept-modal__button international-accept-modal__button-continue"
                text={t('internationalAcceptModal.ctaText')}
                onClick={handleClickClose}
            />
        </div>
    );

    return (
        <HBModal
            className="international-accept-modal"
            isOpen={isOpen}
            onClose={onClose}
            title=""
            footerContent={<ButtonGroup />}
        >
            <HBMediaElement
                height={120}
                width={120}
                name="marketing_site/like-illu.svg"
                forceImgTag
            />
            <h2 className="international-accept-modal__title">
                {t('internationalAcceptModal.title')}
            </h2>
            <p className="international-accept-modal__body">
                {t('internationalAcceptModal.bodyText1')} {email}{' '}
                {t('internationalAcceptModal.bodyText2')} {country}
                <Trans i18nKey="internationalAcceptModal.bodyText3">
                    #TEXT#
                    <a
                        className="international-accept-modal__link"
                        href="/blog"
                    >
                        #LINK_TEXT#
                    </a>
                    #TEXT#
                </Trans>
            </p>
        </HBModal>
    );
};

InternationalAcceptModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    email: PropTypes.string,
    country: PropTypes.string
};

export default InternationalAcceptModal;
