import { useTranslation } from 'react-i18next';
import { FeatureBlockSection, FeatureBlockContent } from '..';
import HBText from '../../honeybook-ui/HBText/HBText';

import './mission-statement-samples-block.scss';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

const MissionStatementSamplesBlock = () => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="mission-statement-samples-block">
            <FeatureBlockContent>
                <HBText
                    customClass="mission-statement-samples-block__title"
                    tag="h2"
                    type="title-m"
                >
                    {t('missionStatement.missionStatementSamples.title')}
                </HBText>
                <HBText
                    customClass="mission-statement-samples-block__subtitle"
                    tag="h2"
                    type="body-l"
                >
                    {t('missionStatement.missionStatementSamples.subtitle')}
                </HBText>
                <div className="mission-statement-samples-block__image-container">
                    <HBMediaElement
                        name="marketing_site/mission-statement/mission-statement-1.png"
                        customClass="mission-statement-samples-block__image"
                    />
                    <HBMediaElement
                        name="marketing_site/mission-statement/mission-statement-2.png"
                        customClass="mission-statement-samples-block__image"
                    />
                    <HBMediaElement
                        name="marketing_site/mission-statement/mission-statement-3.png"
                        customClass="mission-statement-samples-block__image"
                    />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default MissionStatementSamplesBlock;
