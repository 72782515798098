import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';
import FeatureGridBlock from '../components/FeatureBlocks/FeatureGridBlock/FeatureGridBlock';
import VideoWithModal from '../components/VideoWithModal/VideoWithModal';
import ImageTextElementsBlock from '../components/FeatureBlocks/ImageTextElementsBlock/ImageTextElementsBlock';
import PictureAndListBlock from '../components/FeatureBlocks/PictureAndListBlock/PictureAndListBlock';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../components/FeatureBlockElements';
import TestimonialsContainer from '../components/TestimonialsContainer/TestimonialsContainer';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';
import RegistrationForm from '../components/RegistrationForm/RegistrationForm';
import VerticalTemplatesIntro from '../components/FeatureBlocks/VerticalTemplatesIntro/VerticalTemplatesIntro';
import VerticalTemplatesBlock from '../components/FeatureBlocks/VerticalTemplatesBlock/VerticalTemplatesBlock';
import VerticalEmailTemplatesBlock from '../components/FeatureBlocks/VerticalEmailTemplatesBlock/VerticalEmailTemplatesBlock';

import '../styles/venue-management-software.scss';

const imageTextElements = [
    {
        title: 'venuePage.imageTextElements.trackLeads.title',
        text: 'venuePage.imageTextElements.trackLeads.body',
        imagePath: 'marketing_site/vertical-lps/streamlined_icon.svg',
        altText: 'venuePage.imageTextElements.trackLeads.title',
        mediaFlags: {
            forceImgTag: true,
            width: 50
        }
    },
    {
        title: 'venuePage.imageTextElements.standOut.title',
        text: 'venuePage.imageTextElements.standOut.body',
        imagePath: 'marketing_site/vertical-lps/showcase_icon.svg',
        altText: 'venuePage.imageTextElements.standOut.title',
        mediaFlags: {
            forceImgTag: true,
            width: 50
        }
    },
    {
        title: 'venuePage.imageTextElements.payFaster.title',
        text: 'venuePage.imageTextElements.payFaster.body',
        imagePath: 'marketing_site/vertical-lps/booked_icon.svg',
        altText: 'venuePage.imageTextElements.payFaster.title',
        mediaFlags: {
            forceImgTag: true,
            width: 50
        }
    }
];

const videoItem = {
    videoURL: 'https://www.youtube.com/embed/LR1YQT17kyI',
    posterURL:
        'https://res.cloudinary.com/honeybook/image/upload/v1581616932/marketing_site/VenuePage/venue_video_still.png',
    quote: 'HoneyBook has really streamlined our process and given us space we need to focus on what’s important for growing our business.'
};

const quotes = [
    {
        image: 'marketing_site/VenuePage/venue1.jpg',
        text: 'venuePage.quotes.quote1.text',
        name: 'Laura Isenberg',
        description: 'venuePage.quotes.quote1.description'
    },
    {
        image: 'marketing_site/VenuePage/venue2.jpg',
        text: 'venuePage.quotes.quote2.text',
        name: 'Mark and Jean Wonser',
        description: 'venuePage.quotes.quote2.description'
    },
    {
        image: 'marketing_site/VenuePage/venue3.jpg',
        text: 'venuePage.quotes.quote3.text',
        name: 'John Alden and Lane Nittler',
        description: 'venuePage.quotes.quote3.description'
    }
];

const templateBlocks = [
    'invoiceTemplates',
    'contractTemplates',
    'brocureTemplates',
    'proposalTemplates',
    'questionnarieTemplates'
];

const templateFeatureImages = {
    invoiceTemplates: {
        name: 'marketing_site/vertical-lps/template-images/invoice_all.png'
    },
    contractTemplates: {
        name: 'marketing_site/vertical-lps/template-images/vertical-groups/Group%203%20-%20Events/Group_56_Copy.png'
    },
    brocureTemplates: {
        name: 'marketing_site/vertical-lps/template-images/brochure_all.png'
    },
    proposalTemplates: {
        name: 'marketing_site/vertical-lps/template-images/proposal_all.png'
    },
    questionnarieTemplates: {
        name: 'marketing_site/vertical-lps/template-images/group-42_3x.png'
    }
};

const faqs = [
    {
        id: 'venueGoodFit'
    },
    {
        id: 'venueTeam',
        answer: '#TEXT# <a href="https://intercom.help/honeybook-5891e2a409ff/en/articles/2209044-team-member-roles-in-honeybook">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'venueOthers',
        answer: '#TEXT# <a href="https://www.honeybook.com/blog/see-canyon-fruit-ranch-wedding-venue-business">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'venueServiceCharges'
    },
    {
        id: 'venueBusy',
        answer: '#TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'tutorials',
        answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg">#LINK_TEXT#</a>#TEXT#'
    },
    {
        id: 'HBCost',
        answer: '#TEXT# <a href="/pricing">#LINK_TEXT#</a>#TEXT#'
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/venue-management-software',
    title: 'Venue Management Software CRM to Grow Your Business',
    description:
        'Use our venue management software CRM for all-in-one seamless venue booking, scheduling, event management, catering, contract, and invoicing management.'
};

const contentKey = 'venuePage';

function VenueManagementSoftwareTemplate() {
    const { t } = useTranslation();

    return (
        <div className="venue-lp">
            <Layout ogTags={ogTags}>
                <WhiteHero
                    image="marketing_site/VenuePage/venue_hero_2.png"
                    imageAltTag={t('venuePage.hero.imageAltTag')}
                    mainTitle={t('venuePage.hero.title')}
                    description={t('venuePage.hero.subtitle')}
                    showRegistration
                    width={800}
                />

                <ImageTextElementsBlock
                    lightBackground
                    customClass="venue-lp__image-text-block payments-block"
                    imageTextElements={imageTextElements}
                />

                <PictureAndListBlock
                    title="venuePage.trackLeads.title"
                    listName="venuePage.trackLeads.list"
                    image="marketing_site/VenuePage/Copy_of_pipeline.png"
                    imageAltTag="venuePage.trackLeads.imageAltTag"
                />

                <PictureAndListBlock
                    title="venuePage.communicate.title"
                    listName="venuePage.communicate.list"
                    image="marketing_site/VenuePage/venue_proposal.png"
                    imageAltTag="venuePage.communicate.imageAltTag"
                    imageWidth={550}
                />

                <PictureAndListBlock
                    title="venuePage.unique.title"
                    listName="venuePage.unique.list"
                    image="marketing_site/VenuePage/sellyourvenue_branded_venue.png"
                    imageAltTag="venuePage.unique.imageAltTag"
                    registrationSource="unique"
                    imageWidth={540}
                />

                <FeatureBlockSection
                    lightBackground
                    customClass="venue-lp__cta-strip"
                >
                    <FeatureBlockContent>
                        <FeatureBlockText
                            fullWidth
                            preTitle={t(`venuePage.CTAStrip.preTitle`)}
                            primary={t(`venuePage.CTAStrip.title`)}
                        />
                        <div className="feature-block__signup-wrapper">
                            <RegistrationForm
                                source="interstitial"
                                size="large"
                                showNoCcRequired
                            />
                        </div>
                    </FeatureBlockContent>
                </FeatureBlockSection>

                <FeatureGridBlock title={t('venuePage.featureGridTitle')} />

                <FeatureBlockSection
                    fullWidth
                    lightBackground
                    customClass="venue-lp__video-block"
                >
                    <FeatureBlockContent>
                        <FeatureBlockText
                            fullWidth
                            primary={t('venuePage.videoWithModal.title')}
                        />
                        <VideoWithModal selectedVideo={videoItem} />
                    </FeatureBlockContent>
                </FeatureBlockSection>

                <VerticalTemplatesIntro contentKey={contentKey} />

                {templateBlocks.map(templateBlock => (
                    <VerticalTemplatesBlock
                        key={'venue-management-' + templateBlock}
                        contentKey={contentKey}
                        templateBlock={templateBlock}
                        templateFeatureImages={templateFeatureImages}
                    />
                ))}

                <VerticalEmailTemplatesBlock contentKey={contentKey} />

                <TestimonialsContainer
                    customClass="dj-lp__testimonial"
                    quotes={quotes}
                />

                <FAQBlock title={t('venuePage.faqs.title')} faqs={faqs} />

                <FreeToTryBlockRebrand />
            </Layout>
        </div>
    );
}

export default VenueManagementSoftwareTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "venue", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
