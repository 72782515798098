import React from 'react';
import PropTypes from 'prop-types';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';

import './integration-link.scss';

// Note: At this point, direction doesn't really do anything. This is here in order to remind the next person to work on this component that it should be added more options such as hideTitle, horizontal direction (row) etc.
const IntegrationLink = ({
    imagePath,
    mediaFlags,
    title,
    linkDescription,
    linkUrl,
    customClass = '',
    handleClick
    // direction = 'column'
}) => {
    return (
        <div className={`integration-link ${customClass}`.trimEnd()}>
            {imagePath && <HBMediaElement name={imagePath} {...mediaFlags} />}
            <div className="integration-link__text-container">
                {title && <p className="integration-link__title">{title}</p>}
                {linkDescription && (
                    <a
                        onClick={handleClick}
                        href={linkUrl}
                        className="integration-link__description"
                    >
                        {linkDescription}
                    </a>
                )}
            </div>
        </div>
    );
};

IntegrationLink.propTypes = {
    imagePath: PropTypes.string,
    mediaFlags: PropTypes.shape({}),
    title: PropTypes.string,
    linkDescription: PropTypes.string,
    linkUrl: PropTypes.string,
    customClass: PropTypes.string,
    handleClick: PropTypes.func
};

export default IntegrationLink;
