import { useTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../FeatureBlockElements';

import HBText from '../honeybook-ui/HBText/HBText';
import SignatureIcon from './../../svg/Signature.inline.svg';

import './email-signature-hero.scss';

function EmailSignatureHero() {
    const { t } = useTranslation();
    return (
        <FeatureBlockSection customClass="email-signature-hero">
            <FeatureBlockContent customClass="email-signature-hero__content">
                <HBText
                    customClass="email-signature-hero__title"
                    tag="h1"
                    type="title-l"
                >
                    {t('emailSignature.hero.title')}
                </HBText>
                <HBText
                    customClass="email-signature-hero__subtitle"
                    tag="h2"
                    type="body-l"
                >
                    {t('emailSignature.hero.subtitle')}
                </HBText>
                <SignatureIcon className="email-signature-hero__illustration" />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default EmailSignatureHero;
