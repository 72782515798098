import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';

import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';

import {
    FeatureBlockContent,
    FeatureBlockSection,
    FeatureBlockText
} from '../components/FeatureBlockElements';
import WhiteHero, {
    HERO_POSITIONS
} from '../components/Hero/WhiteHero/WhiteHero';
import ListQuoteFeatureBlocks from '../components/FeatureBlocks/ListQuoteFeatureBlocks/ListQuoteFeatureBlocks';
import TabsBlock from '../components/FeatureBlocks/TabsBlock/TabsBlock';
import CheckboxTabContent from '../components/CheckboxTabContent/CheckboxTabContent';
import CTABlock from '../components/FeatureBlocks/CTABlock/CTABlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';

import '../styles/payment-reminders.scss';

const blockContent = [
    {
        transKey: 'paymentRemindersPage.blockContent.invoicing',
        testimonial: {
            memberName: 'Breesa Lee Everett',
            avatar: 'marketing_site/payment-reminders/breesa.jpg'
        },
        images: [
            {
                publicId: 'marketing_site/payment-reminders/module1.png',
                width: 580
            }
        ]
    },
    {
        transKey: 'paymentRemindersPage.blockContent.setup',
        testimonial: {
            memberName: 'Kristal Hacker',
            avatar: 'marketing_site/payment-reminders/kristal.jpg'
        },
        images: [
            {
                publicId: 'marketing_site/payment-reminders/module2_static.png',
                width: 550
            }
        ]
    },
    {
        transKey: 'paymentRemindersPage.blockContent.onePlace',
        testimonial: {
            memberName: 'Elizabeth Megill',
            avatar: 'marketing_site/payment-reminders/elizabeth.jpg'
        },
        images: [
            {
                publicId:
                    'marketing_site/online-payments/instant-deposit-notification-rebrand.png',
                width: 370
            }
        ]
    },
    {
        transKey: 'paymentRemindersPage.blockContent.yourBusiness',
        images: [
            {
                publicId:
                    'marketing_site/payment-reminders/module3-rebrand.png',
                width: 370
            }
        ]
    }
];

const faqs = [
    {
        id: 'paymentRemindersOverview'
    },
    {
        id: 'paymentRemindersPaid'
    },
    {
        id: 'paymentRemindersVersus'
    },
    {
        id: 'paymentRemindersAutomatic'
    },
    {
        id: 'paymentRemindersWriting'
    },
    {
        id: 'paymentAutomate'
    },
    {
        id: 'paymentsApp',
        answer: '#TEXT# <a href="//help.honeybook.com/en/articles/2673568-what-features-are-available-in-the-ios-and-android-apps">#LINK_TEXT#</a> #TEXT#'
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/payment-reminders',
    title: 'Automatic Payment Reminders for Small Businesses',
    description:
        'Never get paid late again with automatic payment reminders. Get started in minutes with templates and proactively remind clients when to pay. Try Free for 7 days'
};

function PaymentRemindersTemplate() {
    const { t } = useTranslation();

    const tabsContent = [
        {
            name: t('paymentRemindersPage.tabsBlock.tab1.title'),
            id: 'meet',
            panel: (
                <CheckboxTabContent bulletsString="paymentRemindersPage.tabsBlock.tab1.bullets" />
            )
        },
        {
            name: t('paymentRemindersPage.tabsBlock.tab2.title'),
            id: 'work',
            panel: (
                <CheckboxTabContent bulletsString="paymentRemindersPage.tabsBlock.tab2.bullets" />
            )
        },
        {
            name: t('paymentRemindersPage.tabsBlock.tab3.title'),
            id: 'impress',
            panel: (
                <CheckboxTabContent bulletsString="paymentRemindersPage.tabsBlock.tab3.bullets" />
            )
        }
    ];

    return (
        <section className="payment-reminders">
            <Layout ogTags={ogTags}>
                <WhiteHero
                    image="marketing_site/payment-reminders/hero-img.png"
                    mainTitle={t('paymentRemindersPage.hero.title')}
                    description={t('paymentRemindersPage.hero.subtitle')}
                    imagePosition={HERO_POSITIONS.BOTTOM}
                    showRegistration
                />

                <FeatureBlockSection
                    lightBackground
                    customClass="payment-reminders__post-hero-block"
                >
                    <FeatureBlockContent>
                        <FeatureBlockText
                            fullWidth
                            preTitle={t(`paymentRemindersPage.postHero.title`)}
                            primary={t(
                                `paymentRemindersPage.postHero.subtitle`
                            )}
                        />
                        <HBMediaElement
                            name="marketing_site/payment-reminders/get_paid_illustration.svg"
                            forceImgTag
                            alt={t(`paymentRemindersPage.postHero.title`)}
                        />
                    </FeatureBlockContent>
                </FeatureBlockSection>

                {blockContent.map((block, index) => {
                    const isEven = index % 2 === 1;
                    return (
                        <ListQuoteFeatureBlocks
                            key={block.transKey}
                            blockContent={block}
                            isEven={isEven}
                            alternateLightBackground
                        />
                    );
                })}

                <CTABlock
                    customClass="payment-reminders__cta-block"
                    primary={t('paymentRemindersPage.ctaBlock.title')}
                    imageUrl="marketing_site/payment-reminders/firework_illustration.svg"
                    mediaFlags={{ forceImgTag: true }}
                    showNoCcRequired
                />

                <TabsBlock
                    lightBackground
                    customClass="payment-reminders__tabs-block"
                    title={t('paymentRemindersPage.tabsBlock.title')}
                    tabsContent={tabsContent}
                />

                <FAQBlock
                    title={t('paymentRemindersPage.faqTitle')}
                    faqs={faqs}
                    displayContactLink
                />

                <FreeToTryBlockRebrand />
            </Layout>
        </section>
    );
}

export default PaymentRemindersTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "paymentReminders", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
