import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';

const StepTwo = () => {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState(null);

    const selectOptions = Object.values(
        t('missionStatement.quiz.step2.answers', {
            returnObjects: true
        })
    );

    const images = {
        creative: 'marketing_site/mission-statement/personality/Creative.png',
        sophisticated:
            'marketing_site/mission-statement/personality/Sophisticated.png',
        funny: 'marketing_site/mission-statement/personality/Funny.png',
        authentic: 'marketing_site/mission-statement/personality/Authentic.png',
        exciting: 'marketing_site/mission-statement/personality/Exciting.png',
        powerful: 'marketing_site/mission-statement/personality/Powerful.png',
        thoughtful:
            'marketing_site/mission-statement/personality/Thoughtful.png',
        rugged: 'marketing_site/mission-statement/personality/Rugged.png'
    };

    const handleClick = e => {
        setSelectedOption(e.target.value);
    };

    return (
        <div
            className={`mission-statement-step mission-statement-step--two ${
                selectedOption &&
                'mission-statement-step mission-statement-step--two--selected'
            }`}
        >
            <HBText
                tag="p"
                type="body-l"
                customClass="mission-statement-step__question"
            >
                <Trans>{t('missionStatement.quiz.step2.question')}</Trans>
            </HBText>
            {selectOptions.map(option => (
                <div
                    className={`mission-statement-step--two__option ${
                        selectedOption === option
                            ? 'mission-statement-step--two__option--selected'
                            : 'mission-statement-step--two__option--deselected'
                    }`}
                    key={option}
                >
                    <label className="mission-statement-step--two__label">
                        <input
                            className="mission-statement-step--two__input"
                            type="radio"
                            name="personality"
                            value={option}
                            id={`personality-${option}`}
                            onClick={handleClick}
                        />
                        <HBMediaElement name={images[option.toLowerCase()]} />
                        {option}
                    </label>
                </div>
            ))}
        </div>
    );
};

export default StepTwo;
