import { useTranslation } from 'react-i18next';
import IntegrationLink from '../../IntegrationLink/IntegrationLink';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockUserQuote,
    FeatureBlockText
} from '../../FeatureBlockElements';

import FeaturesIntegrationBlockStore from './FeaturesIntegrationBlockStore';

import './features-integration-block.scss';

const { blockData } = FeaturesIntegrationBlockStore;

const handleAnalytics = context => {
    AnalyticsService.track(
        AnalyticsEvents.lp_features_hub_help_center_article,
        context
    );
};

const FeaturesIntegrationBlock = () => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="features-integration-block">
            <FeatureBlockContent customClass="features-integration-block__text-container">
                <FeatureBlockText
                    primary={t(`featuresPage.integrations.title`)}
                    secondary={t(`featuresPage.integrations.subtitle`)}
                    subheading={t(`featuresPage.integrations.body`)}
                />

                <FeatureBlockUserQuote
                    name={blockData.quoteMember}
                    avatar={blockData.quoteAvatar}
                    jobTitle={t(
                        `featuresPage.${blockData.id}.testimonial.company`
                    )}
                    avatarWidth={40}
                    quote={t(`featuresPage.${blockData.id}.testimonial.quote`)}
                    avatarCrop="thumb"
                    avatarGravity="face"
                />
            </FeatureBlockContent>
            <FeatureBlockContent customClass="features-integration-block__links">
                <div className="features-integration-block__links-container">
                    {blockData.links.map(link => (
                        <IntegrationLink
                            key={link.id}
                            imagePath={link.image}
                            title={t(
                                `featuresPage.integrations.links.${link.id}`
                            )}
                            linkDescription={t(
                                'featuresPage.integrations.linkText'
                            )}
                            linkUrl={link.link}
                            handleClick={() =>
                                handleAnalytics({ source: link.id })
                            }
                        />
                    ))}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default FeaturesIntegrationBlock;
