import { useSubscribeToEvent } from '../../hooks/useSubscribeToEvent';
import { EventBusOnFunctionArgs } from '../../Services/EventBus';
import { IncomingEvents } from '../PublicTemplatePreview/type';

export const useSubscribeToIframeEvent = ({
    eventName,
    subscriber
}: {
    eventName: IncomingEvents;
    subscriber: EventBusOnFunctionArgs['subscriber'];
}) => {
    useSubscribeToEvent({ eventName, subscriber });
};
