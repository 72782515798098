import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';
import TestimonialCard from '../../TestimonialCard/TestimonialCard';
import StarReviews from '../../StarReviews/StarReviews';

import './social-proof-block-rebrand.scss';

const quotes = [
    {
        profileImage: 'marketing_site/testimonials/member-5.png',
        transId: 'testimonials.danielle',
        name: 'Danielle J.',
        logo: 'marketing_site/testimonials/member-logo-1.png'
    },
    {
        profileImage: 'marketing_site/testimonials/member-3.png',
        transId: 'testimonials.angie',
        name: 'Angie M.',
        logo: 'marketing_site/testimonials/member-logo-4.png'
    },
    {
        profileImage: 'marketing_site/testimonials/member-2.png',
        transId: 'testimonials.mark',
        name: 'Mark D.',
        logo: 'marketing_site/testimonials/member-logo-2.png'
    },
    {
        profileImage: 'marketing_site/testimonials/member-1.png',
        transId: 'testimonials.dianuh',
        name: 'Dianuh A.',
        logo: 'marketing_site/testimonials/member-logo-3.png'
    }
];

const SocialProofBlock = () => {
    const { t } = useTranslation();

    const half = Math.ceil(quotes.length / 2);
    const firstHalf = quotes.slice(0, half - (quotes.length % 2));
    const secondHalf = quotes.slice(-half);

    return (
        <div className="social-proof-rebrand">
            <FeatureBlockSection>
                <FeatureBlockContent>
                    <FeatureBlockText
                        fullWidth
                        primary={t(`socialProof.title`)}
                    />

                    <div className="social-proof-rebrand__container">
                        <StarReviews />
                    </div>
                </FeatureBlockContent>
            </FeatureBlockSection>

            <FeatureBlockContent customClass="testimonial-cards-block__reviews-content">
                <div className="testimonial-cards-block__column-one">
                    {firstHalf.map(({ name, transId, profileImage, logo }) => {
                        return (
                            <TestimonialCard
                                key={name}
                                text={t(`${transId}.quote`)}
                                name={name}
                                description={t(`${transId}.jobTitle`)}
                                image={profileImage}
                                companyLogo={logo}
                            />
                        );
                    })}
                </div>
                <div
                    className={`testimonial-cards-block__column-two ${
                        quotes.length % 2 === 0 &&
                        'testimonial-cards-block__column-two--offset'
                    }`}
                >
                    {secondHalf.map(({ name, transId, profileImage, logo }) => {
                        return (
                            <TestimonialCard
                                key={name}
                                text={t(`${transId}.quote`)}
                                name={name}
                                description={t(`${transId}.jobTitle`)}
                                image={profileImage}
                                companyLogo={logo}
                            />
                        );
                    })}
                </div>
            </FeatureBlockContent>
        </div>
    );
};

export default memo(SocialProofBlock);
