import { createContext, useContext } from 'react';
import {
    IncomingEvents,
    OutgoingEvents,
    TTemplateData
} from '../../PublicTemplatePreview/type';

type RecipientPageContextValue = {
    iframeRef: React.RefObject<HTMLIFrameElement>;
    postMessageToChild: ({
        data,
        event
    }: {
        event: OutgoingEvents;
        data?: any;
    }) => void;
    checkIsEventValid: (event: IncomingEvents) => boolean;
    emitToIframeEvent: ({
        eventName,
        data
    }: {
        eventName: IncomingEvents;
        data: { data: any };
    }) => void;
    templateData: TTemplateData;
    isIframeLoaded: boolean;
    setIsIframeLoaded: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RecipientPageContext = createContext(
    {} as RecipientPageContextValue
);

export const useRecipientPageContext = () => {
    return useContext(RecipientPageContext);
};
