import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useTranslation } from 'react-i18next';
import UtilsService from '../../../Services/UtilsService';
import Hero from '../Hero/Hero';
import HeroStrip from '../HeroStrip/HeroStrip';
import HeroColumn from '../HeroColumn/HeroColumn';
import HeroTitle from '../HeroTitle/HeroTitle';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import OnlinePaymentSoftwareHeroStore from './OnlinePaymentSoftwareHeroStore';

import './online-payment-software-hero.scss';

const { heroImage, heroImageMweb } = OnlinePaymentSoftwareHeroStore;

const OnlinePaymentSoftware = () => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    return (
        <Hero customClass="online-payment-software-hero">
            <HeroStrip>
                <HeroColumn columnWidth={1}>
                    <HeroTitle
                        title={t('onlinePaymentSoftware.hero.title')}
                        subtitle={t('onlinePaymentSoftware.hero.subtitle')}
                    />
                    <RegistrationForm
                        source="hero"
                        customClass=""
                        showNoCcRequired
                    />
                </HeroColumn>
                <HeroColumn
                    customClass="online-payment-software-hero__media-column"
                    columnWidth={1}
                >
                    <HBMediaElement
                        customClass="online-payment-software-hero__media-element"
                        name={breakpoint.medium ? heroImageMweb : heroImage}
                        width={666}
                        alt={t(`onlinePaymentSoftware.hero.imgAltText`)}
                        lazyLoad={false}
                    />
                </HeroColumn>
            </HeroStrip>
        </Hero>
    );
};

export default OnlinePaymentSoftware;
