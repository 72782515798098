import { createPortal } from 'react-dom';
import HBIcon from '../HBIcon/HBIcon';

import './hb-modal.scss';

import { ReactNode, SyntheticEvent, useCallback, useRef } from 'react';

function Dialog({ children, onClose, className }) {
    const ref = useRef<HTMLDivElement>();

    const _onClose = useCallback(
        event => {
            event.stopPropagation();
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }

            onClose();
        },
        [onClose]
    );

    return createPortal(
        <div className="hbui-modal__backdrop" onClick={_onClose}>
            <div className={`hbui-modal__container`} ref={ref}>
                <div className={className}>{children}</div>
            </div>
        </div>,
        document.querySelector('body')
    );
}

interface Props {
    isOpen?: boolean;
    onClose?: (event: SyntheticEvent<HTMLElement, Event>) => void;
    className?: string;
    children?: ReactNode;
    footerContent?: ReactNode;
    header?: boolean;
    headerTitle?: string;
    e2eTestLocator?: string;
}

function HBModal({
    isOpen,
    onClose,
    children,
    className,
    e2eTestLocator,
    footerContent,
    headerTitle,
    header = true
}: Props) {
    if (!isOpen) {
        return null;
    }

    return (
        <Dialog className={`hbui-modal ${className}`} onClose={onClose}>
            {header && (
                <header className="hbui-modal__header">
                    {headerTitle && (
                        <h4 className="hbui-modal__header-title">
                            {headerTitle}
                        </h4>
                    )}
                    <HBIcon
                        name="close-modal"
                        customClass="hbui-modal__header-close icon-hb-nx-close"
                        data-testid="hb-modal-close--button"
                        onClick={onClose}
                    />
                </header>
            )}

            <section
                e2e-test-locator={e2eTestLocator}
                className="hbui-modal__body"
            >
                {children}
            </section>
            {footerContent && <footer>{footerContent}</footer>}
        </Dialog>
    );
}

export default HBModal;
