import { useTranslation } from 'react-i18next';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import HeroColumn from '../../Hero/HeroColumn/HeroColumn';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBButton from '@honeybook-ui/HBButton/HBButton';

import './careers-hero.scss';

const CareersHero = () => {
    const { t } = useTranslation();

    const handleClick = () => {
        document.querySelector('#careers-open-positions')?.scrollIntoView({
            behavior: 'smooth'
        });
    };

    return (
        <FeatureBlockSection customClass="careers-hero">
            <FeatureBlockContent customClass="careers-hero__content">
                <HeroColumn customClass="careers-hero__column" columnWidth={2}>
                    <FeatureBlockText
                        customClass="careers-hero__text"
                        primary={t(`careersPageRebrand.hero.title`)}
                        secondary={t(`careersPageRebrand.hero.subtitle`)}
                        fullWidth
                    />
                    <HBButton
                        type="button"
                        size="large"
                        onClick={handleClick}
                        text={t('careersPageRebrand.hero.ctaText')}
                    />
                </HeroColumn>
                <HeroColumn customClass="careers-hero__column" columnWidth={3}>
                    <HBMediaElement
                        customClass="careers-hero__image"
                        name="/marketing_site/careers_page/career_hero/hero-grid-new.png"
                        alt=""
                    />
                </HeroColumn>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default CareersHero;
