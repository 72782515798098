import { useTranslation } from 'react-i18next';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';

import './invoice-template-demo.scss';

export interface InvoiceItemType {
    name: String;
    description: String;
    price: number;
}

interface InvoiceTemplateItemProps {
    updateItem: (key: string, value: string, index: number) => void;
    removeItem: (index: number) => void;
    invoiceItem: InvoiceItemType;
    index: number;
}

const InvoiceTemplateItem = ({
    updateItem,
    removeItem,
    invoiceItem,
    index
}: InvoiceTemplateItemProps) => {
    const { t } = useTranslation();

    const handleInputChange = e => {
        const type = e.target.dataset.type;
        const value =
            type === 'price'
                ? parseFloat(e.target.value.replace(/^\D+/g, ''))
                : e.target.value;

        if (type === 'price' && isNaN(value)) {
            updateItem(type, 0, index);
        } else {
            updateItem(type, value, index);
        }
    };

    return (
        <div className="invoice-template-item">
            <input
                type="text"
                className="invoice-template-item__input invoice-template-item__input--name"
                placeholder={t('InvoiceTemplateItem.placeholderText.name')}
                data-type="name"
                onChange={handleInputChange}
                value={invoiceItem.name.toString()}
            />
            <input
                type="text"
                className="invoice-template-item__input invoice-template-item__input--price"
                data-type="price"
                placeholder="$0"
                value={`$${invoiceItem.price}`}
                onChange={handleInputChange}
            />
            <input
                type="text"
                className="invoice-template-item__input invoice-template-item__input--description"
                placeholder={t(
                    'InvoiceTemplateItem.placeholderText.description'
                )}
                data-type="description"
                value={invoiceItem.description.toString()}
                onChange={handleInputChange}
            />
            <span
                className="invoice-template-item__trash-btn"
                onClick={() => removeItem(index)}
            >
                <HBIcon name="nx-trash" />
            </span>
        </div>
    );
};

export default InvoiceTemplateItem;
