import { memo } from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';

import HBLabel from '../HBLabel/HBLabel';

import './hb-textarea.scss';

import type { ChangeEventHandler, FocusEventHandler } from 'react';

interface Props {
    id?: string;
    name?: string;
    placeholder?: string;
    customClass?: string;
    label?: string;
    isRequired?: boolean;
    onFocus?: FocusEventHandler<HTMLTextAreaElement>;
    onBlur?: FocusEventHandler<HTMLTextAreaElement>;
    isValid?: boolean;
    onChange?: ChangeEventHandler<HTMLTextAreaElement>;
    errorMessage?: string;
    e2eTestLocator?: string;
}

function HBTextArea({
    name,
    placeholder,
    customClass,
    label,
    isRequired,
    onFocus,
    onBlur,
    isValid,
    onChange,
    id,
    errorMessage,
    e2eTestLocator
}: Props): JSX.Element {
    const className = classnames(`hbui-textarea`, {
        [`${customClass}`]: Boolean(customClass),
        error: !isValid
    });

    return (
        <div className={className}>
            <HBLabel
                label={label}
                isRequired={isRequired}
                customClass="textarea-label"
            />
            <textarea
                id={id}
                name={name}
                placeholder={placeholder}
                className="textarea-wrapper"
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                e2e-test-locator={e2eTestLocator}
            />

            {!isValid && <div className="error-message">{errorMessage}</div>}
        </div>
    );
}

HBTextArea.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    customClass: PropTypes.string,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    isValid: PropTypes.bool,
    onChange: PropTypes.func,
    errorMessage: PropTypes.string,
    e2eTestLocator: PropTypes.string
};

export default memo(HBTextArea);
