import { memo, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import ComparisonTableCell from './ComparisonTableCell';

import './comparison-table-row.scss';
import { TRANSLATION_COMPONENTS } from '../../../constants/constants';

interface Props {
    customClass?: string;
    columns: string[];
    comparisonData: {
        id: string;
        noFeature: boolean;
        cellContent: {
            hasTitle: boolean;
            hasDescription: boolean;
            cellElement: JSX.Element;
        }[];
    };
    transKey?: string;
}

interface Props2 {
    col: string;
    comparisonData: {
        id: string;
        noFeature: boolean;
        cellContent: {
            hasTitle: boolean;
            hasDescription: boolean;
            cellElement: JSX.Element;
        }[];
    };
    transKey?: string;
    customClass?: string;
}

function ComparisionTableColumn({
    col,
    comparisonData,
    transKey,
    customClass
}: Props2): JSX.Element {
    const { t } = useTranslation();

    const title = comparisonData.cellContent[col].hasTitle ? (
        <Trans
            i18nKey={`${transKey}.cells.${comparisonData.id}.text.${col}`}
            components={TRANSLATION_COMPONENTS}
        />
    ) : undefined;

    return (
        <ComparisonTableCell
            key={col}
            customClass={`comparison-table-cell--${col} ${customClass}`}
            cellElement={comparisonData.cellContent[col].cellElement}
            title={title}
            description={
                comparisonData.cellContent[col].hasDescription
                    ? t(
                          `${transKey}.cells.${comparisonData.id}.description.${col}`
                      )
                    : ''
            }
        />
    );
}

function ComparisonTableRow({
    customClass,
    columns,
    comparisonData,
    transKey
}: Props): JSX.Element {
    const title = useMemo(() => {
        return (
            <Trans
                i18nKey={`${transKey}.cells.${comparisonData.id}.title`}
                components={TRANSLATION_COMPONENTS}
            />
        );
    }, [comparisonData.id, transKey]);

    return (
        <div className="comparison-table-row">
            {comparisonData.noFeature ? (
                <ComparisonTableCell featureCell />
            ) : (
                <ComparisonTableCell featureCell title={title} />
            )}
            {columns.map(
                (col: string): JSX.Element => (
                    <ComparisionTableColumn
                        key={col}
                        col={col}
                        comparisonData={comparisonData}
                        transKey={transKey}
                        customClass={customClass}
                    />
                )
            )}
        </div>
    );
}

ComparisonTableRow.propTypes = {
    customClass: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    comparisonData: PropTypes.shape({}).isRequired,
    transKey: PropTypes.string.isRequired
};

export default memo(ComparisonTableRow);
