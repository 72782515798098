import { useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HBButton, { ButtonStyles } from '@honeybook-ui/HBButton/HBButton';
import HBModal from '@honeybook-ui/HBModal/HBModal';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import UtilsService from '../../../Services/UtilsService';
import './international-availability-modal.scss';
import InternationalAcceptModal from '../InternationalAcceptModal/InternationalAcceptModal';

const countryList = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antigua & Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bosnia & Herzegovina',
    'Botswana',
    'Brazil',
    'British Virgin Islands',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Cape Verde',
    'Cayman Islands',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Congo',
    'Cook Islands',
    'Costa Rica',
    'Cote D Ivoire',
    'Croatia',
    'Cruise Ship',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Estonia',
    'Ethiopia',
    'Falkland Islands',
    'Faroe Islands',
    'Fiji',
    'Finland',
    'France',
    'French Polynesia',
    'French West Indies',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kuwait',
    'Kyrgyz Republic',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macau',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Namibia',
    'Nepal',
    'Netherlands',
    'Netherlands Antilles',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Norway',
    'Oman',
    'Pakistan',
    'Palestine',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Reunion',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Pierre & Miquelon',
    'Samoa',
    'San Marino',
    'Satellite',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'South Africa',
    'South Korea',
    'Spain',
    'Sri Lanka',
    'St Kitts & Nevis',
    'St Lucia',
    'St Vincent',
    'St. Lucia',
    'Sudan',
    'Suriname',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    "Timor L'Este",
    'Togo',
    'Tonga',
    'Trinidad & Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks & Caicos',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'Uruguay',
    'Uzbekistan',
    'Venezuela',
    'Vietnam',
    'Virgin Islands (US)',
    'Yemen',
    'Zambia',
    'Zimbabwe'
];

const InternationalAvailabilityModal = ({
    isOpen,
    closeInternationalAvailabilityModal
}) => {
    const [isInternationalAcceptModalOpen, setIsInternationalAcceptModalOpen] =
        useState(false);

    const [internationalAcceptModalData, setInternationalAcceptModalData] =
        useState();
    const { t } = useTranslation();

    const emailInputRef = useRef();
    const selectCountryRef = useRef();

    const onConfirmInternationalAvailabilityModal = (
        userEmail,
        userCountry
    ) => {
        const eventProps = {
            email: userEmail,
            country: userCountry
        };

        AnalyticsService.reportIterableEvent(
            'international_user_capture',
            eventProps,
            eventProps
        );
        setInternationalAcceptModalData({
            internationalUserEmail: userEmail,
            internationalUserCountry: userCountry
        });
        closeInternationalAvailabilityModal();
        setIsInternationalAcceptModalOpen(true);
    };

    const handleFormSubmit = e => {
        e.preventDefault();

        AnalyticsService.trackClick(
            AnalyticsEvents.on_boarding_international_continue
        );
        onConfirmInternationalAvailabilityModal(
            UtilsService.sanitizeHtml(emailInputRef.current.value),
            selectCountryRef.current.value
        );
    };

    const handleClickCancel = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.on_boarding_international_cancel
        );
        closeInternationalAvailabilityModal?.();
    };

    if (isOpen) {
        AnalyticsService.track(AnalyticsEvents.on_boarding_international_view);
    }

    return (
        <>
            <HBModal
                className="international-modal"
                isOpen={isOpen}
                onClose={closeInternationalAvailabilityModal}
            >
                <h2 className="international-modal__title">
                    {t('internationalAvailabilityModal.title')}
                </h2>
                <p className="international-modal__body">
                    {t('internationalAvailabilityModal.bodyText')}
                </p>
                <div className="international-modal__form">
                    <p className="international-modal__label">
                        {t('internationalAvailabilityModal.email')}:
                    </p>
                    <input
                        className="international-modal__input"
                        ref={emailInputRef}
                        id="international-modal__email"
                        name="international-modal__email"
                        type="email"
                        required
                    />
                    <p className="international-modal__label">
                        {t('internationalAvailabilityModal.country')}:
                    </p>
                    <div className="international-modal__select">
                        <select
                            required
                            defaultValue=""
                            className="international-modal__input international-modal__select-element"
                            ref={selectCountryRef}
                        >
                            <option value="" disabled hidden>
                                {t(
                                    'internationalAvailabilityModal.defaultSelectText'
                                )}
                            </option>
                            {countryList.map(country => (
                                <option key={country} value={country}>
                                    {country}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="international-modal__button-group">
                        <HBButton
                            customClass="international-modal__button international-modal__button-cancel"
                            text={t('internationalAvailabilityModal.else')}
                            onClick={handleClickCancel}
                            buttonStyle={ButtonStyles.MINIMAL}
                        />
                        <HBButton
                            onClick={handleFormSubmit}
                            customClass="international-modal__button"
                            text={t('internationalAvailabilityModal.usaBased')}
                        />
                    </div>
                </div>
            </HBModal>
            <InternationalAcceptModal
                isOpen={isInternationalAcceptModalOpen}
                onClose={() => setIsInternationalAcceptModalOpen(false)}
                email={internationalAcceptModalData?.internationalUserEmail}
                country={internationalAcceptModalData?.internationalUserCountry}
            />
        </>
    );
};

InternationalAvailabilityModal.propTypes = {
    closeInternationalAvailabilityModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool
};

export default InternationalAvailabilityModal;
