import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import HBButton from '@honeybook-ui/HBButton/HBButton';
import List from '../../List/List';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './business-personality-tool-free-guide.scss';

import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

const listItems = [
    { id: 'li1' },
    { id: 'li2' },
    { id: 'li3' },
    { id: 'li4' },
    { id: 'li5' }
];

function BusinessPersonalityToolFreeGuideBlock(): JSX.Element {
    const { t } = useTranslation();

    const handleCtaClick = useCallback(() => {
        AnalyticsService.trackClick(AnalyticsEvents.biz_download_guide);

        const win = window.open(
            'https://files.convertkitcdnm.com/assets/documents/34025/2635071/RT_Best_of_2019.pdf',
            '_blank'
        );

        if (win !== null) {
            win.focus();
        }
    }, []);

    return (
        <FeatureBlockSection customClass="bptr-guide">
            <FeatureBlockContent customClass="bptr-guide__guide-container">
                <HBMediaElement
                    customClass="bptr-guide__shape"
                    name="marketing_site/business_personality_tool/instagram-templates/Shape_bg.svg"
                    width={209}
                />
                <HBMediaElement
                    customClass="bptr-guide__guide-img"
                    name="marketing_site/business_personality_tool/Stacked-Covers-Dec-2019-Transparent2.png"
                    width={500}
                />
            </FeatureBlockContent>

            <FeatureBlockContent customClass="bptr-guide__text-content">
                <FeatureBlockText
                    primary="<strong>Download</strong> the free guide"
                    secondary={t(`businessPersonalityTool.guide.body`)}
                />

                <List
                    listName="businessPersonalityTool.guide.list"
                    items={listItems}
                    icon="nx-check-16"
                />

                <HBButton
                    customClass="bptr-guide__cta"
                    onClick={handleCtaClick}
                    text={t(`businessPersonalityTool.guide.cta`)}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default memo(BusinessPersonalityToolFreeGuideBlock);
