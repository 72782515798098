import { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import './toggle-selector.scss';

const ToggleSelector = ({
    buttons,
    handleToggle,
    defaultSelectedId = buttons[0].id
}) => {
    const [selectedToggleButtonId, setSelectedToggleButtonId] =
        useState(defaultSelectedId);
    const [toggleSelectorWidth, setToggleSelectorWidth] = useState();
    const [toggleSelectorOffsetLeft, setToggleSelectorOffsetLeft] = useState();

    const setToggleButtonLocation = id => {
        const element = document.getElementById(id);
        setSelectedToggleButtonId(id);
        setToggleSelectorWidth(element.offsetWidth);
        setToggleSelectorOffsetLeft(element.offsetLeft);
    };

    useEffect(() => {
        setToggleButtonLocation(defaultSelectedId);
    }, [defaultSelectedId]);

    const onClick = e => {
        if (e.target.id != selectedToggleButtonId) {
            setToggleButtonLocation(e.target.id);
            handleToggle();
        }
    };

    return (
        <div className="toggle-selector__container">
            <div className="toggle-selector__button">
                <div className="toggle-selector__content">
                    <span
                        style={{
                            width: toggleSelectorWidth,
                            left: toggleSelectorOffsetLeft
                        }}
                        className={`toggle-selector__button__selector`}
                    ></span>
                    <div
                        className="toggle-selector__options"
                        onClick={e => {
                            onClick(e);
                        }}
                    >
                        {buttons.map(({ id, text }) => (
                            <span
                                key={id}
                                id={id}
                                className={`toggle-selector__button__option ${
                                    selectedToggleButtonId === id
                                        ? 'toggle-selector__button__option--selected'
                                        : ''
                                }`}
                            >
                                {text}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

ToggleSelector.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleToggle: PropTypes.func.isRequired,
    defaultSelectedId: PropTypes.string
};

export default ToggleSelector;
