import React from 'react';
import PropTypes from 'prop-types';
import './webinar-recording.scss';

const WebinarRecording = ({ onClick, title, description, linkUrl }) => {
    const hasDescription = description.length > 0;
    return (
        <a
            href={linkUrl}
            className={`webinar-recording ${
                hasDescription ? 'webinar-recording--align-top' : ''
            }`}
            onClick={onClick}
        >
            <div className="webinar-recording__text-container">
                <h4 className="webinar-recording__title">{title}</h4>
                {hasDescription && (
                    <p className="webinar-recording__description">
                        {description}
                    </p>
                )}
            </div>
            <button className="webinar-recording__play-button">
                <span className="webinar-recording__play-button-text">▶︎</span>
            </button>
        </a>
    );
};

WebinarRecording.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    linkUrl: PropTypes.string
};

export default WebinarRecording;
