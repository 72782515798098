import { memo, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';

import './hero-column.scss';

import type { ReactNode } from 'react';

interface Props {
    children?: ReactNode;
    customClass?: string;
    columnWidth?: number;
}

function HeroColumn({
    children,
    customClass,
    columnWidth = 1
}: Props): JSX.Element {
    const style = useMemo(() => {
        return { flex: `${columnWidth}` };
    }, [columnWidth]);

    const className = classnames('hero__column', {
        [`${customClass}`]: Boolean(customClass)
    });

    return (
        <div className={className} style={style}>
            {children}
        </div>
    );
}

HeroColumn.propTypes = {
    children: PropTypes.node.isRequired,
    customClass: PropTypes.string,
    columnWidth: PropTypes.number
};

export default memo(HeroColumn);
