import UtilsService from './UtilsService';

type TStorageType = 'localStorage' | 'sessionStorage';

interface Params {
    key: string;
    data: unknown;
    shouldThrowError?: boolean;
    storageType?: TStorageType;
}

export default class StorageService {
    static setItem({
        key,
        data,
        shouldThrowError = false,
        storageType = 'localStorage'
    }: Params) {
        try {
            if (UtilsService.isBrowser() && window[storageType]) {
                window[storageType].setItem(key, JSON.stringify(data));
            } else if (shouldThrowError) {
                throw new Error();
            }
        } catch (err) {
            if (shouldThrowError) {
                throw err;
            }
        }
    }

    static getItem(key: string, storageType: TStorageType = 'localStorage') {
        try {
            if (UtilsService.isBrowser() && window[storageType]) {
                return JSON.parse(window[storageType].getItem(key) || 'null');
            }
        } catch {
            return null;
        }
    }

    static removeItem(key: string, storageType: TStorageType = 'localStorage') {
        try {
            if (UtilsService.isBrowser() && window[storageType]) {
                return window[storageType].removeItem(key);
            }
        } catch {
            return;
        }
    }
}
