// TODO - Change GeoIP lookup service.
// TODO: https://www.npmjs.com/package/maxmind is not deprecated, and has Typescript support.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import geoip2 from '../vendor/geoip2';
import StorageService from './StorageService';

export interface GeoData {
    city: string;
    country_name: string;
    country_code: string;
    latitude: string;
    longitude: string;
    postal_code?: string;
    region?: string;
}

class GeolocationService {
    static maxmindLocationPromise: Promise<GeoData>;

    static maxmindValidCountriesPromise: Promise<boolean>;

    private static readonly IS_FROM_VALID_COUNTRIES_KEY =
        'isFromValidCountries';
    private static readonly LOCATION_KEY = 'location';

    static getLocationPromise(): Promise<GeoData> {
        if (!GeolocationService.maxmindLocationPromise) {
            const location = StorageService.getItem(
                GeolocationService.LOCATION_KEY
            );
            if (!!location) {
                GeolocationService.maxmindLocationPromise =
                    Promise.resolve(location);
            } else {
                GeolocationService.maxmindLocationPromise = new Promise(
                    (resolve, reject) => {
                        // TODO: https://www.npmjs.com/package/maxmind is not deprecated, and has Typescript support.
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        return geoip2.city(
                            (response: {
                                city: { names: { en: string } };
                                country: {
                                    names: { en: string };
                                    iso_code: string;
                                };
                                location: {
                                    latitude: string;
                                    longitude: string;
                                };
                                postal: {
                                    code: string;
                                };
                                subdivisions: {
                                    iso_code: string;
                                }[];
                            }) => {
                                const location = {
                                    city: response.city.names.en,
                                    country_name: response.country.names.en,
                                    country_code: response.country.iso_code,
                                    latitude: response.location.latitude,
                                    longitude: response.location.longitude,
                                    postal_code: response.postal.code,
                                    region: response.subdivisions[0].iso_code
                                };
                                StorageService.setItem({
                                    key: GeolocationService.LOCATION_KEY,
                                    data: location
                                });
                                resolve(location);
                            },
                            (error: Error) => reject(error)
                        );
                    }
                );
            }
        }

        return GeolocationService.maxmindLocationPromise;
    }

    static isFromValidCountriesPromise(): Promise<boolean> {
        if (!GeolocationService.maxmindValidCountriesPromise) {
            const isFromValidCountries = StorageService.getItem(
                GeolocationService.IS_FROM_VALID_COUNTRIES_KEY
            );
            if (typeof isFromValidCountries === 'boolean') {
                GeolocationService.maxmindValidCountriesPromise =
                    Promise.resolve(isFromValidCountries);
            } else {
                GeolocationService.maxmindValidCountriesPromise = new Promise(
                    (resolve, reject) => {
                        // TODO: https://www.npmjs.com/package/maxmind is not deprecated, and has Typescript support.
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        return geoip2.city(
                            (response: {
                                country: { iso_code: 'US' | 'CA' };
                            }) => {
                                const isFromValidCountries =
                                    ['US', 'CA'].indexOf(
                                        response.country.iso_code
                                    ) > -1;
                                StorageService.setItem({
                                    key: GeolocationService.IS_FROM_VALID_COUNTRIES_KEY,
                                    data: isFromValidCountries
                                });
                                return resolve(isFromValidCountries);
                            },
                            (error: Error) => reject(error)
                        );
                    }
                );
            }
        }
        return GeolocationService.maxmindValidCountriesPromise;
    }

    static formatGeolocationParams(rawUserGeoData: {
        city: { names: { en?: string } };
        country: { names: { en?: string }; iso_code?: string };
        location: {
            longitude: string;
            latitude: string;
        };
        postal: {
            code?: string;
        };
        subdivisions: {
            iso_code?: string;
        }[];
    }): {
        city: string;
        country_name: string;
        country_code: string;
        longitude: string;
        latitude: string;
        postal_code: string;
        region: string;
    } {
        if (!rawUserGeoData) {
            return {
                city: '',
                country_name: 'USA',
                country_code: 'US',
                longitude: '',
                latitude: '',
                postal_code: '',
                region: ''
            };
        }

        return {
            city: rawUserGeoData.city.names.en || '',
            country_name: rawUserGeoData.country.names.en || '',
            country_code: rawUserGeoData.country.iso_code || '',
            longitude: String(rawUserGeoData.location.longitude) || '',
            latitude: String(rawUserGeoData.location.latitude) || '',
            postal_code: rawUserGeoData.postal.code || '',
            region: rawUserGeoData.subdivisions[0].iso_code || ''
        };
    }
}

export default GeolocationService;
