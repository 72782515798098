import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import HBMediaElement from '../../../honeybook-ui/HBMediaElement/HBMediaElement';

import './feature-automations-animation.scss';

interface Props {
    customClass: string;
}

const ICON_URL_PREFIX =
    'marketing_site/crm-page/feature-info-block/automations/';

const FeatureAutomationsAnimation = ({ customClass }: Props) => {
    const { t } = useTranslation();
    const [position, setPosition] = useState(0);

    const handleScroll = position => {
        setPosition(position);
    };

    const startBtnClass = useMemo(
        () =>
            `feature-automations-animation__start-btn ${
                position < 0.25 && 'border'
            }`,
        [position]
    );

    const stepOneClass = useMemo(
        () =>
            `feature-automations-animation__step ${
                position >= 0.25 && position < 0.5 && 'border'
            }`,
        [position]
    );

    const stepTwoClass = useMemo(
        () =>
            `feature-automations-animation__step ${
                position >= 0.5 && 'border'
            }`,
        [position]
    );

    return (
        <div className={`feature-automations-animation ${customClass}`}>
            <ParallaxProvider scrollAxis="vertical">
                <Parallax
                    onProgressChange={handleScroll}
                    style={{ position: 'absolute', inset: '0px' }}
                />
                <div className="feature-automations-animation__content">
                    <div className={startBtnClass}>
                        {t('featureAutomationAnimation.startBtn')}
                    </div>
                    <div className={stepOneClass}>
                        <p className="feature-automations-animation__text-primary">
                            {t('featureAutomationAnimation.stepOne.firstLine')}
                        </p>
                        <HBMediaElement
                            customClass="icon"
                            name={ICON_URL_PREFIX + 'envelop.svg'}
                        />
                        <p className="feature-automations-animation__text-emphasized">
                            {t('featureAutomationAnimation.stepOne.secondLine')}
                        </p>
                        <p className="feature-automations-animation__text-secondary">
                            {t('featureAutomationAnimation.stepOne.thirdLine')}
                        </p>
                    </div>
                    <div className={stepTwoClass}>
                        <p className="feature-automations-animation__text-primary">
                            {t('featureAutomationAnimation.stepTwo.firstLine')}
                        </p>
                        <p className="feature-automations-animation__text-secondary">
                            {' '}
                            {t('featureAutomationAnimation.stepTwo.secondLine')}
                        </p>
                        <HBMediaElement
                            customClass="icon"
                            name={ICON_URL_PREFIX + 'list.svg'}
                        />
                        <p className="feature-automations-animation__text-emphasized">
                            {' '}
                            {t('featureAutomationAnimation.stepTwo.thirdLine')}
                        </p>
                        <p className="feature-automations-animation__text-secondary">
                            {' '}
                            {t('featureAutomationAnimation.stepTwo.fourthLine')}
                        </p>
                    </div>

                    <HBMediaElement
                        customClass="feature-automations-animation__add-btn"
                        name={ICON_URL_PREFIX + 'plus.svg'}
                    />
                </div>
            </ParallaxProvider>
        </div>
    );
};

export default FeatureAutomationsAnimation;
