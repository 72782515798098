import InvoicesIcon from '../svg/features/invoices-icon.inline.svg';
import ContractsIcon from '../svg/features/contracts-icon.inline.svg';
import AutomationsIcon from '../svg/features/automations-icon.inline.svg';
import PaymentsIcon from '../svg/features/payments-icon.inline.svg';
import SchedulingIcon from '../svg/features/scheduling-icon.inline.svg';

const verticalPaidStore = {
    generic: {
        values: [
            {
                id: 'invoices',
                icon: <InvoicesIcon />,
                imagePath:
                    'marketing_site/home_page/features_list/Feature-new.png'
            },
            {
                id: 'payments',
                icon: <PaymentsIcon />,
                imagePath:
                    'marketing_site/home_page/features_list/Feature-1-new-fixed.png'
            },
            {
                id: 'contracts',
                icon: <ContractsIcon />,
                imagePath:
                    'marketing_site/home_page/features_list/Feature-2-new.png'
            },
            {
                id: 'scheduling',
                icon: <SchedulingIcon />,
                imagePath:
                    'marketing_site/home_page/features_list/Feature-3-new.png'
            },

            {
                id: 'automations',
                icon: <AutomationsIcon />,
                imagePath:
                    'marketing_site/home_page/features_list/Feature-4-new.png'
            }
        ]
    },
    photographers: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-photographers',
            title: 'Everything your Photography business needs to grow',
            description:
                'HoneyBook has everything your Photography business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/photographers/hero-photo-1-new.png',
            image2: 'marketing_site/vertical-lps/photographers/hero-photo-2-new.png'
        },
        showcaseServices: {
            videoUrl:
                'marketing_site/vertical-lps/photographers/showcase-desktop-photographers',
            videoMobileUrl:
                'marketing_site/vertical-lps/photographers/showcase-mobile-photographers'
        },
        quotes: [
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Caley_Petty.jpg',
                transId: 'testimonialCardsBlock.testimonials.caley',
                name: 'Caley Petty',
                businessName: 'Caley Petty Photography'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Penny_McGoey.jpg',
                transId: 'testimonialCardsBlock.testimonials.penny',
                name: 'Penny McGoey',
                businessName: 'Becoming Images'
            },
            {
                profileImage: 'marketing_site/testimonials/member-2.png',
                transId: 'testimonialCardsBlock.testimonials.mark',
                name: 'Mark D.',
                businessName: 'Mark Dickinson Photography'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Jason_Bump.jpg',
                transId: 'testimonialCardsBlock.testimonials.jason',
                name: 'Jason Bump',
                businessName: 'Jason Bump Photography'
            }
        ],
        footer: {
            image: 'marketing_site/paid/Footer_-_Image_1.png'
        }
    },
    cateringPage: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-caterers',
            title: 'Everything your Catering business needs to grow',
            description:
                'HoneyBook has everything your catering business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/caterers/hero-1.png',
            image2: 'marketing_site/vertical-lps/caterers/hero-2.png'
        },
        showcaseServices: {
            videoUrl:
                'marketing_site/vertical-lps/caterers/showcase-caterers-desktop-2023',
            videoMobileUrl:
                'marketing_site/vertical-lps/caterers/showcase-caterers-mobile-2023'
        },
        quotes: [
            {
                profileImage: 'marketing_site/testimonial-avatars/andrea.png',
                transId: 'testimonialCardsBlock.testimonials.andrea',
                name: 'Andrea Alvarez Reno',
                businessName: 'AR Calligraphy & Cakes'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/kacey.png',
                transId: 'testimonialCardsBlock.testimonials.kacey',
                name: 'Kacey Grady',
                businessName: 'Kacey Grady Cooks'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Aimee_Nieto.jpg',
                transId: 'testimonialCardsBlock.testimonials.aimee',
                name: 'Aimee Nieto',
                businessName: 'Olive Grazing'
            }
        ],
        footer: {
            image: 'marketing_site/vertical-lps/caterers/Verticalized_End_Card_Image.jpg'
        }
    },
    eventPlannersPage: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-event-planners',
            title: 'Everything your Event Planning business needs to grow',
            description:
                'HoneyBook has everything your event planning business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/event-planners/hero-1.png',
            image2: 'marketing_site/vertical-lps/event-planners/Event_Planner___Photo_3.jpg'
        },
        showcaseServices: {
            videoUrl:
                'marketing_site/vertical-lps/event-planners/showcase-desktop',
            videoMobileUrl:
                'marketing_site/vertical-lps/event-planners/showcase-mobile'
        },
        quotes: [
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Amanda_Caputo.png',
                transId: 'testimonialCardsBlock.testimonials.amanda',
                name: 'Amanda Caputo',
                businessName: 'ACE Weddings & Events'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Lindsay_McLaughlin.jpg',
                transId: 'testimonialCardsBlock.testimonials.lindsay',
                name: 'Lindsay McLaughlin',
                businessName: 'Paper Hat Weddings'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Tom_Bidot.jpg',
                transId: 'testimonialCardsBlock.testimonials.tom',
                name: 'Tom Bidot',
                businessName: 'The Event Agency'
            }
        ],
        footer: {
            image: 'marketing_site/vertical-lps/event-planners/Event_Planner___Photo_2.jpg'
        }
    },
    venuesPage: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-venue-managers',
            title: 'Everything your venue rental business needs to grow',
            description:
                'HoneyBook has everything your venue rental business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/venue-managers/hero-1.jpg',
            image2: 'marketing_site/vertical-lps/venue-managers/hero-2.jpg'
        },
        showcaseServices: {
            videoUrl:
                'marketing_site/vertical-lps/venue-managers/showcase-desktop',
            videoMobileUrl:
                'marketing_site/vertical-lps/venue-managers/showcase-mobile'
        },
        quotes: [
            {
                profileImage: 'marketing_site/testimonial-avatars/Molly_S.png',
                transId: 'testimonialCardsBlock.testimonials.mollyS',
                name: 'Molly S',
                logo: 'marketing_site/vertical-lps/venue-managers/company-logo-1.png'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/Jodi_C.png',
                transId: 'testimonialCardsBlock.testimonials.jodiC',
                name: 'Jodi C',
                logo: 'marketing_site/vertical-lps/venue-managers/company-logo-2.png'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Jennifer_And_John_H.png',
                transId: 'testimonialCardsBlock.testimonials.jenniferAndJohnH',
                name: 'Jennifer & John H',
                logo: 'marketing_site/vertical-lps/venue-managers/company-logo-3.png'
            }
        ],
        footer: {
            image: 'marketing_site/vertical-lps/venue-managers/footer.jpg'
        }
    },
    socialMedia: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-social-media',
            title: 'Everything your social media business needs to grow',
            description:
                'HoneyBook has everything your social media business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/social-media/Social_Media-Hero_1.png',
            image2: 'marketing_site/vertical-lps/social-media/Social_Media-Hero_2.png'
        },
        quotes: [
            {
                profileImage: 'marketing_site/testimonial-avatars/Pamela_G.png',
                transId: 'testimonialCardsBlock.testimonials.pamelaG',
                name: 'Pamela G',
                businessName: 'The Pamela Reneé'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/Kalea_S.jpg',
                transId: 'testimonialCardsBlock.testimonials.kaleaS',
                name: 'Kalea S',
                logo: 'marketing_site/vertical-lps/social-media/company-logo-2.png'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Cassandra_G.jpg',
                transId: 'testimonialCardsBlock.testimonials.cassandraG',
                name: 'Cassandra G',
                logo: 'marketing_site/vertical-lps/social-media/company-logo-3.png'
            }
        ],
        footer: {
            image: 'marketing_site/vertical-lps/social-media/footer.jpg'
        }
    },
    cinematographers: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-cinematographers',
            title: 'Everything your cinematography business needs to grow',
            description:
                'HoneyBook has everything your cinematography business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/cinematographers/hero-1.png',
            image2: 'marketing_site/vertical-lps/cinematographers/hero-2.jpg'
        },
        quotes: [
            {
                profileImage: 'marketing_site/testimonial-avatars/Amanda_B.png',
                transId: 'testimonialCardsBlock.testimonials.amandaB',
                name: 'Amanda B.',
                logo: 'marketing_site/vertical-lps/cinematographers/company-logo-1.png'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Jean_Alexander_D.jpg',
                transId: 'testimonialCardsBlock.testimonials.jeanAlexanderD',
                name: 'Jean-Alexander D.',
                businessName: 'When Two Become One'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/Bray_B.jpg',
                transId: 'testimonialCardsBlock.testimonials.bray',
                name: 'Bray B.',
                logo: 'marketing_site/vertical-lps/cinematographers/company-logo-3.png'
            }
        ],
        footer: {
            image: 'marketing_site/vertical-lps/cinematographers/footer.jpg'
        }
    },
    consultant: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-consultants',
            title: 'Everything your consulting business needs to grow',
            description:
                'HoneyBook has everything your consulting business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/consultant/Consultants-Hero-1.png',
            image2: 'marketing_site/vertical-lps/consultant/Consultants-Hero-2.png'
        },
        quotes: [
            {
                profileImage: 'marketing_site/testimonial-avatars/Sasha_F.png',
                transId: 'testimonialCardsBlock.testimonials.sashaF',
                name: 'Sasha F',
                logo: 'marketing_site/vertical-lps/consultant/company-logo-1.png'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Danielle_J.jpg',
                transId: 'testimonialCardsBlock.testimonials.danielleJ',
                name: 'Danielle J',
                logo: 'marketing_site/vertical-lps/consultant/company-logo-2.png'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/Steven_M.jpg',
                transId: 'testimonialCardsBlock.testimonials.stevenM',
                name: 'Steven M',
                logo: 'marketing_site/vertical-lps/consultant/company-logo-3.png'
            }
        ],
        footer: {
            image: 'marketing_site/vertical-lps/consultant/footer.jpg'
        }
    },
    creatives: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-creative-designer',
            title: 'Everything your creative design business needs to grow',
            description:
                'HoneyBook has everything your creative design business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/creatives/hero-photo-1.png',
            image2: 'marketing_site/vertical-lps/creatives/hero-photo-2.png'
        },
        quotes: [
            {
                profileImage: 'marketing_site/testimonial-avatars/paige.png',
                transId: 'testimonialCardsBlock.testimonials.paige',
                name: 'Paige S.',
                businessName: 'Particular Paige Designs'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/alexis.png',
                transId: 'testimonialCardsBlock.testimonials.alexis',
                name: 'Alexis C.',
                businessName: 'Lex Creative Studios'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/kellyB.png',
                transId: 'testimonialCardsBlock.testimonials.kelly',
                name: 'Kelly B.'
            }
        ],
        footer: {
            image: 'marketing_site/vertical-lps/creatives/footer.png'
        }
    },
    interiorDesign: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-interior-design',
            title: 'Everything your interior design business needs to grow',
            description:
                'HoneyBook has everything your interior design business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/interior-designers/hero-photo-1.png',
            image2: 'marketing_site/vertical-lps/interior-designers/hero-photo-2.png'
        },
        quotes: [
            {
                profileImage:
                    'marketing_site/testimonial-avatars/ashley_osuagwu.jpg',
                transId: 'testimonialCardsBlock.testimonials.ashley',
                name: 'Ashley O.',
                businessName: 'Essential N Design'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/alexis.png',
                transId: 'testimonialCardsBlock.testimonials.alexia',
                name: 'Alexia B.',
                businessName: 'Apt. 5 Interiors'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/erin_loftin.jpg',
                transId: 'testimonialCardsBlock.testimonials.erin',
                name: 'Erin L.',
                businessName: 'E.L. Designs'
            }
        ],
        footer: {
            image: 'marketing_site/vertical-lps/interior-designers/footer.png'
        }
    },
    freelancers: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-freelancers',
            title: 'Everything your freelance business needs to grow',
            description:
                'HoneyBook has everything your freelance business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/freelancers/hero-photo-1.png',
            image2: 'marketing_site/vertical-lps/freelancers/hero-photo-2.png'
        },
        quotes: [
            {
                profileImage: 'marketing_site/testimonial-avatars/heather.jpg',
                transId: 'testimonialCardsBlock.testimonials.heather',
                name: 'Heather F.',
                businessName: 'H L Farthing Ltd Co.'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Danielle_J.jpg',
                transId: 'testimonialCardsBlock.testimonials.danielleJ',
                name: 'Danielle J.',
                businessName: 'Danielle Jacek LLC'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/paige.png',
                transId: 'testimonialCardsBlock.testimonials.paigeS',
                name: 'Paige S.',
                businessName: 'Particular Paige Designs'
            }
        ],
        footer: {
            image: 'marketing_site/vertical-lps/freelancers/footer.jpg'
        }
    },
    weddingPlanners: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-wedding-planners',
            title: 'Everything your wedding planning business needs to grow',
            description:
                'HoneyBook has everything your wedding planning business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/wedding-planners/hero-photo-1.png',
            image2: 'marketing_site/vertical-lps/wedding-planners/hero-photo-2.png'
        },
        quotes: [
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Amanda_Caputo.png',
                transId: 'testimonialCardsBlock.testimonials.amanda',
                name: 'Amanda Caputo',
                businessName: 'ACE Weddings & Events'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Lindsay_McLaughlin.jpg',
                transId: 'testimonialCardsBlock.testimonials.lindsay',
                name: 'Lindsay McLaughlin',
                businessName: 'Paper Hat Weddings'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Emmaleigh.jpg',
                transId: 'testimonialCardsBlock.testimonials.emma',
                name: 'Emmaleigh W.',
                businessName: 'Emmaleigh White Weddings & Events'
            }
        ],
        footer: {
            image: 'marketing_site/vertical-lps/wedding-planners/footer.jpg'
        }
    },
    videographers: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-videographers',
            title: 'Everything your videography business needs to grow',
            description:
                'HoneyBook has everything your videography business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/videographers/hero-photo-1.png',
            image2: 'marketing_site/vertical-lps/videographers/hero-photo-2.jpg'
        },
        quotes: [
            {
                profileImage: 'marketing_site/testimonial-avatars/brian.png',
                transId: 'testimonialCardsBlock.testimonials.brian',
                name: 'Brian H.',
                businessName: 'Brian Harrell Photo Video'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/jean-alexander.png',
                transId: 'testimonialCardsBlock.testimonials.jean',
                name: 'Jean-Alexander D.',
                businessName: 'When Two Become One'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/bray.png',
                transId: 'testimonialCardsBlock.testimonials.bray',
                name: 'Bray B.',
                businessName: 'Hik Creative'
            }
        ],
        footer: {
            image: 'marketing_site/vertical-lps/videographers/footer.jpg'
        }
    },
    marketing: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-marketing',
            title: 'Everything your marketing business needs to grow',
            description:
                'HoneyBook has everything your marketing business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/marketing/Hero_Vertical.png',
            image2: 'marketing_site/vertical-lps/marketing/Hero_Horizontal.png'
        },
        quotes: [
            {
                profileImage: 'marketing_site/testimonial-avatars/Adam_C.png',
                transId: 'testimonialCardsBlock.testimonials.adamC',
                name: 'Adam C',
                logo: 'marketing_site/vertical-lps/marketing/company-logo-1.png'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/Paige_P.jpg',
                transId: 'testimonialCardsBlock.testimonials.paigeP',
                name: 'Paige P',
                logo: 'marketing_site/vertical-lps/marketing/company-logo-2.png'
            },
            {
                profileImage:
                    'marketing_site/testimonial-avatars/Patrice_P.jpg',
                transId: 'testimonialCardsBlock.testimonials.patriceP',
                name: 'Patrice P',
                logo: 'marketing_site/vertical-lps/marketing/company-logo-3.png'
            }
        ],
        footer: {
            image: 'marketing_site/vertical-lps/marketing/footer.jpg'
        }
    },
    florists: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-florists',
            title: 'Everything your florist business needs to grow',
            description:
                'HoneyBook has everything your florist business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/florists/hero-photo-1.png',
            image2: 'marketing_site/vertical-lps/florists/hero-photo-2.jpg'
        },
        quotes: [
            {
                profileImage: 'marketing_site/testimonial-avatars/elaine.png',
                transId: 'testimonialCardsBlock.testimonials.elaine',
                name: 'Elaine R.',
                businessName: 'Fusion Floral Art'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/kathleen.png',
                transId: 'testimonialCardsBlock.testimonials.kathleen',
                name: 'Kathleen R.',
                businessName: 'Nurture State'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/breesa.png',
                transId: 'testimonialCardsBlock.testimonials.breese',
                name: 'Breese E.',
                businessName: 'Blooms Design House'
            }
        ],
        footer: {
            image: 'marketing_site/vertical-lps/florists/footer.jpg'
        }
    },
    designers: {
        ogTags: {
            url: 'https://www.honeybook.com/industry-designers',
            title: 'Everything your design business needs to grow',
            description:
                'HoneyBook has everything your design business needs to organize client communication, contracts, and payments all in one place.',
            noIndex: true
        },
        hero: {
            image1: 'marketing_site/vertical-lps/designers/hero-photo-1.png',
            image2: 'marketing_site/vertical-lps/designers/hero-photo-2.jpg'
        },
        quotes: [
            {
                profileImage: 'marketing_site/testimonial-avatars/paige.png',
                transId: 'testimonialCardsBlock.testimonials.paige',
                name: 'Paige S.'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/alexis.png',
                transId: 'testimonialCardsBlock.testimonials.alexis',
                name: 'Alexis C.',
                businessName: 'Lex Creative Studios'
            },
            {
                profileImage: 'marketing_site/testimonial-avatars/kellyB.png',
                transId: 'testimonialCardsBlock.testimonials.kelly',
                name: 'Kelly B.'
            }
        ],
        footer: {
            image: 'marketing_site/vertical-lps/designers/footer.jpg'
        }
    }
};

export default verticalPaidStore;
