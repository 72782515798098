import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import TestimonialCard from '../../TestimonialCard/TestimonialCard';
import type { Quote } from '../../../types/testimonial-quote';
import HBText from '../../honeybook-ui/HBText/HBText';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import StarReviews from '../../../svg/starReviews.inline.svg';
import ChevronLeft from '../../../svg/chevron-left.inline.svg';
import UtilsService from '../../../Services/UtilsService';
import { CAPTERRA_REVIEWS } from '../../../constants/constants';

import 'swiper/css';
import './reviews-hero.scss';

const TESTIMONIAL_QUOTES: Quote[] = [
    {
        profileImage: 'marketing_site/testimonials/member-3.png',
        transId: 'testimonialCardsBlock.testimonials.angie',
        name: 'Angie M.'
    },
    {
        profileImage: 'marketing_site/testimonials/member-1.png',
        transId: 'testimonialCardsBlock.testimonials.dianuh',
        name: 'Dianuh A.'
    },
    {
        profileImage: 'marketing_site/testimonials/member-5.png',
        transId: 'testimonialCardsBlock.testimonials.danielle',
        name: 'Danielle J.'
    },
    {
        profileImage: 'marketing_site/testimonials/member-2.png',
        transId: 'testimonialCardsBlock.testimonials.mark',
        name: 'Mark D.'
    },
    {
        profileImage:
            'marketing_site/testimonial-avatars/member-image-jenny-w_2x.png',
        transId: 'testimonialCardsBlock.testimonials.jenny',
        name: 'Jenny W.'
    },
    {
        profileImage:
            'marketing_site/testimonial-avatars/member-image-daija-moore-evans_2x.png',
        transId: 'testimonialCardsBlock.testimonials.daija',
        name: 'Daija M.'
    },
    {
        profileImage:
            'marketing_site/testimonial-avatars/member-image-ethan-deleon_2x.png',
        transId: 'testimonialCardsBlock.testimonials.ethan',
        name: 'Ethan D.'
    },
    {
        profileImage:
            'marketing_site/testimonial-avatars/member-image-heather-w_2x.png',
        transId: 'testimonialCardsBlock.testimonials.heather',
        name: 'Heather W.'
    },
    {
        profileImage:
            'marketing_site/testimonial-avatars/member-image-natasha-m_2x.png',
        transId: 'testimonialCardsBlock.testimonials.natasha',
        name: 'Natasha M.'
    },
    {
        profileImage:
            'marketing_site/testimonial-avatars/member-image-alexis-c_2x.png',
        transId: 'testimonialCardsBlock.testimonials.alexis',
        name: 'Alexis C.'
    },
    {
        profileImage:
            'marketing_site/testimonial-avatars/member-image-aimee-n_2x.png',
        transId: 'testimonialCardsBlock.testimonials.aimee',
        name: 'Aimee N.'
    },
    {
        profileImage:
            'marketing_site/testimonial-avatars/member-image-jody-h_2x.png',
        transId: 'testimonialCardsBlock.testimonials.jody',
        name: 'Jody H.'
    },
    {
        profileImage:
            'marketing_site/testimonial-avatars/member-image-brenda-d_2x.png',
        transId: 'testimonialCardsBlock.testimonials.brenda',
        name: 'Brenda D.'
    },
    {
        profileImage:
            'marketing_site/testimonial-avatars/member-image-jayne-b_2x.png',
        transId: 'testimonialCardsBlock.testimonials.jayne',
        name: 'Jayne B.'
    },
    {
        profileImage:
            'marketing_site/testimonial-avatars/member-image-kay-c_2x.png',
        transId: 'testimonialCardsBlock.testimonials.kay',
        name: 'Kay C.'
    },
    {
        profileImage:
            'marketing_site/testimonial-avatars/member-image-kim-b_2x.png',
        transId: 'testimonialCardsBlock.testimonials.kim',
        name: 'Kim B.'
    }
];

const midpoint = Math.ceil(TESTIMONIAL_QUOTES.length / 2);
const col1 = TESTIMONIAL_QUOTES.slice(0, midpoint);
const col2 = TESTIMONIAL_QUOTES.slice(midpoint);

const IMAGE_URL_PREFIX = 'marketing_site/reviews/hero';
const SCROLL_SPEED = 1;

const handleScrollToElement = () => {
    UtilsService.scrollToElementId('members-spotlight');
};

const ReviewsHero = () => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const scrollRef = useRef<HTMLDivElement>(null);
    const [direction, setDirection] = useState(-1);
    const [isScrolling, setIsScrolling] = useState(true);
    const [contentLoaded, setContentLoaded] = useState(false);
    const SLIDE_COUNT = breakpoints.mediumUp ? 6 : 3;

    useEffect(() => {
        const timer = setTimeout(() => {
            setContentLoaded(true);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (!isScrolling) return;

        const scrollElement = scrollRef.current;

        const scrollContent = () => {
            if (!scrollElement) return;
            const maxScrollTop =
                scrollElement.scrollHeight - scrollElement.clientHeight;

            if (
                scrollElement.scrollTop >= maxScrollTop - 1 &&
                direction === 1
            ) {
                setDirection(-1);
            } else if (scrollElement.scrollTop <= 0 && direction === -1) {
                setDirection(1);
            }

            scrollElement.scrollTop += SCROLL_SPEED * direction;
        };

        const scrollInterval = setInterval(scrollContent, 20);

        return () => clearInterval(scrollInterval);
    }, [direction, isScrolling, contentLoaded, scrollRef.current]);

    const stopScrolling = () => setIsScrolling(false);
    const startScrolling = () => setIsScrolling(true);

    const MobileLayout = (
        <Swiper
            className={`reviews-hero__swiper`}
            slidesPerView={SLIDE_COUNT}
            mousewheel={false}
            loop={true}
            autoplay={true}
            spaceBetween={24}
            centeredSlides={true}
            modules={[Autoplay]}
        >
            {TESTIMONIAL_QUOTES.map(({ name, transId, profileImage }) => (
                <SwiperSlide key={transId}>
                    <TestimonialCard
                        key={name}
                        text={t(`${transId}.quote`)}
                        image={profileImage}
                        personalInfo={{
                            name,
                            job: t(`${transId}.jobTitle`)
                        }}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );

    const DesktopLayout = (
        <div
            ref={scrollRef}
            className="reviews-hero__testimonials-container"
            onMouseOver={stopScrolling}
            onMouseLeave={startScrolling}
        >
            <div className="reviews-hero__testimonials">
                <div className="reviews-hero__testimonials-col reviews-hero__testimonials-col--one">
                    {col1.map(({ name, transId, profileImage }) => (
                        <TestimonialCard
                            key={name}
                            text={t(`${transId}.quote`)}
                            image={profileImage}
                            personalInfo={{
                                name,
                                job: t(`${transId}.jobTitle`)
                            }}
                            lazyLoad={false}
                        />
                    ))}
                </div>
                <div className="reviews-hero__testimonials-col reviews-hero__testimonials-col--two">
                    {col2.map(({ name, transId, profileImage }) => (
                        <TestimonialCard
                            key={name}
                            text={t(`${transId}.quote`)}
                            image={profileImage}
                            lazyLoad={false}
                            personalInfo={{
                                name,
                                job: t(`${transId}.jobTitle`)
                            }}
                        />
                    ))}
                </div>
            </div>
            <div className="reviews-hero__shadow"></div>
        </div>
    );

    const StarReviewBlock = (
        <div className="reviews-hero__reviews-container">
            <div className="reviews-hero__reviews">
                <div className="reviews-hero__reviews__stars">
                    {CAPTERRA_REVIEWS.rating}
                    <StarReviews />
                </div>
                <span className="reviews-hero__reviews__based-on">
                    {t('ReviewsPage.hero.basedOn')}
                </span>
            </div>
            <div className="reviews-hero__logos">
                <HBMediaElement
                    name={`${IMAGE_URL_PREFIX}/capterra-logo_2x.png`}
                />
                <HBMediaElement
                    name={`${IMAGE_URL_PREFIX}/Get-app-logo_2x.png`}
                />
                <HBMediaElement
                    name={`${IMAGE_URL_PREFIX}/G2-crowd-logo_2x.png`}
                />
            </div>
        </div>
    );

    return (
        <FeatureBlockSection customClass="reviews-hero">
            <FeatureBlockContent customClass="reviews-hero__container">
                <div className="reviews-hero__text-content">
                    <HBText tag="h1" type="title-m">
                        {t('ReviewsPage.hero.title')}
                    </HBText>
                    <HBText tag="h3" type="body-m">
                        {t('ReviewsPage.hero.subtitle')}
                    </HBText>
                    <div
                        className="reviews-hero__scroll-link reviews-hero__scroll-link--desktop"
                        onClick={handleScrollToElement}
                    >
                        <HBText tag="p" type="body-l">
                            {t('ReviewsPage.hero.look')} <ChevronLeft />
                        </HBText>
                    </div>
                    {breakpoints.largeUp && StarReviewBlock}
                </div>
                {breakpoints.largeUp ? DesktopLayout : MobileLayout}
                {!breakpoints.largeUp && StarReviewBlock}

                <div
                    className="reviews-hero__scroll-link reviews-hero__scroll-link--mobile"
                    onClick={handleScrollToElement}
                >
                    <HBText tag="p" type="body-l">
                        {t('ReviewsPage.hero.look')} <ChevronLeft />
                    </HBText>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default ReviewsHero;
