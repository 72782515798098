import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import FeatureTitleList from '../../FeatureTitleList/FeatureTitleList';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockUserQuote
} from '../../FeatureBlockElements';
import HBVideo from '@honeybook-ui/HBVideo/HBVideo';

import './its-easy.scss';

const ItsEasyBlock = ({ t }) => {
    const translationKeysArray = Object.keys(
        t('schedulerPage.itsEasyBlock.list', { returnObjects: true })
    );

    const translationKeysArrayList = translationKeysArray.map(value => {
        return { id: value };
    });
    const breakpoints = useBreakpoint();

    return (
        <FeatureBlockSection customClass="its-easy">
            <FeatureBlockContent>
                <Fragment>
                    <HBMediaElement
                        alt={t(`schedulerPage.itsEasyBlock.title`)}
                        name="marketing_site/scheduler-page/graphic-element.svg"
                        customClass="its-easy__graphic"
                        width={breakpoints.mediumUp ? 255 : 100}
                        forceImgTag
                        lazyLoad={false}
                    />
                    <HBVideo
                        customClass="its-easy__video"
                        videoId="804073820"
                        padding="56.25% 0 0 0"
                        title="HoneyBook Scheduling Tool"
                        autoplay={true}
                        muted={true}
                        controls={true}
                    />
                </Fragment>
            </FeatureBlockContent>

            <FeatureBlockContent>
                <FeatureTitleList
                    title={t('schedulerPage.itsEasyBlock.title')}
                    listName="schedulerPage.itsEasyBlock.list"
                    items={translationKeysArrayList}
                    registrationSource="scheduler-meeting"
                />
                <FeatureBlockUserQuote
                    avatar="marketing_site/scheduler-page/gina-image.jpg"
                    name={t('schedulerPage.itsEasyBlock.quotee')}
                    jobTitle={t('schedulerPage.itsEasyBlock.quoteeJobTitle')}
                    quote={t('schedulerPage.itsEasyBlock.quote')}
                    avatarWidth={40}
                    lazyLoad={false}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

ItsEasyBlock.propTypes = {
    t: PropTypes.func
};

export default withTranslation()(ItsEasyBlock);
