import { useContext } from 'react';
import { EmailSignatureFormContext } from '../EmailSignatureBlock';
import { INITIAL_EMAIL_SIGNATURE_DISPLAY_VALUE } from '../../../constants/constants';

export function useSignatureTemplateData() {
    const { formData } = useContext(EmailSignatureFormContext);

    const isDataFilled = Object.values(formData).some(data => !!data);

    const signatureTemplateData = isDataFilled
        ? formData
        : INITIAL_EMAIL_SIGNATURE_DISPLAY_VALUE;

    return signatureTemplateData;
}
