import { memo } from 'react';
import * as PropTypes from 'prop-types';

import './hero-strip.scss';

import type { ReactNode } from 'react';

interface Props {
    children?: ReactNode;
}

function HeroStrip({ children }: Props): JSX.Element {
    return <div className="hero__strip-content">{children}</div>;
}

HeroStrip.propTypes = {
    children: PropTypes.node.isRequired
};

export default memo(HeroStrip);
