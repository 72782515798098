import { memo } from 'react';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';

export interface IPerkDetails {
    logo: string;
    title: string;
    description: string;
}

function Perk({ logo, title, description }: IPerkDetails): JSX.Element {
    return (
        <div className="perk-card">
            <HBMediaElement name={logo} />
            <FeatureBlockText primary={title} secondary={description} />
        </div>
    );
}

export default memo(Perk);
