import { useTranslation } from 'react-i18next';
import FeatureBlockContent from '../../../components/FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockSection from '../../../components/FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import RotatingTitle from '../../../components/RotatingTitle/RotatingTitle';

import './rotating-title-block.scss';

const titleArrKeys = [
    'photography',
    'webDesign',
    'eventPlanning',
    'graphicDesign',
    'florist',
    'consulting',
    'accounting',
    'bookkeeping',
    'marketing',
    'interiorDesign',
    'doula',
    'venueManagement',
    'virtualAssistant',
    'socialMedia',
    'lifeCoach',
    'DJ',
    'freelance'
] as const;

const RotatingTitleBlock = () => {
    const { t } = useTranslation();

    const titleArr = titleArrKeys.map(key =>
        t(`valueProps.rotatingTitles.${key}`)
    );

    return (
        <FeatureBlockSection>
            <FeatureBlockContent>
                <div className="rotating-title-block">
                    <h2 className="rotating-title-block__title">
                        {t(`valueProps.titleBefore`)} <br />
                        <RotatingTitle textArr={titleArr} />
                        <br />
                        {t(`valueProps.titleAfter`)}
                    </h2>
                    <h3 className="rotating-title-block__subtitle">
                        {t(`valueProps.subtitle`)}
                    </h3>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default RotatingTitleBlock;
