import { memo, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HBButton from '../../honeybook-ui/HBButton/HBButton';

import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText,
    FeatureBlockImage
} from '../../FeatureBlockElements';

import './press-blog-block.scss';

interface Props {
    lightBackground?: boolean;
    companyNewsLink: string;
}

function PressBlogBlock({
    lightBackground,
    companyNewsLink
}: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection
            lightBackground={lightBackground}
            customClass="press-page__blog-block"
        >
            <FeatureBlockContent>
                <FeatureBlockText bodyText={t(`pressPage.blocks.blog.title`)} />
                <FeatureBlockImage
                    customClass="feature-press-img"
                    featureImage="marketing_site/press_page/Company_news-rebrand.png"
                />
                <a className="feature-block__button" href={companyNewsLink}>
                    <HBButton text={t(`pressPage.blocks.blog.button`)} />
                </a>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

PressBlogBlock.propTypes = {
    lightBackground: PropTypes.bool
};

export default memo(PressBlogBlock);
