import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';

import Hero from '../Hero/Hero';
import HeroTitle from '../HeroTitle/HeroTitle';
import HeroStrip from '../HeroStrip/HeroStrip';
import HeroColumn from '../HeroColumn/HeroColumn';

import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './invoice-generator-hero.scss';

const data = {
    doula: {
        heroImage: 'marketing_site/invoice-generator-lp/heroes/doula-hero2.png'
    },
    design: {
        heroImage:
            'marketing_site/invoice-generator-lp/designers/Hero/Hero_image.png'
    },
    graphicDesign: {
        heroImage: 'marketing_site/invoice-generator-lp/heroes/graphic-hero.png'
    },
    interiorDesign: {
        heroImage:
            'marketing_site/invoice-generator-lp/heroes/interior-hero.png'
    },
    consultants: {
        heroImage:
            'marketing_site/invoice-generator-lp/heroes/consultant-hero.png'
    },
    photographers: {
        heroImage: 'marketing_site/invoice-generator-lp/heroes/photo-hero.png'
    },
    coach: {
        heroImage:
            'marketing_site/invoice-generator-lp/heroes/coaching-hero.png'
    },
    tutors: {
        heroImage:
            'marketing_site/invoice-generator-lp/tutors/Hero/Hero_image.png'
    },
    cleaners: {
        heroImage:
            'marketing_site/invoice-generator-lp/cleaners/Hero/Hero_cleaning.png'
    },
    virtualAssistant: {
        heroImage:
            'marketing_site/invoice-generator-lp/virtual-assistant/Hero/Hero_image.png'
    }
};

interface Props {
    contentKey: string;
}

function InvoiceGeneratorHero({ contentKey }: Props): JSX.Element {
    const { t } = useTranslation();

    const { heroImage } = data[contentKey];

    return (
        <Hero customClass="invoice-generator-hero">
            <HeroStrip>
                <HeroColumn
                    columnWidth={1}
                    customClass="hero__column hero__column--text"
                >
                    <HeroTitle
                        title={t(
                            `invoiceGeneratorPages.${contentKey}.hero.title`
                        )}
                        subtitle={t(
                            `invoiceGeneratorPages.${contentKey}.hero.subtitle`
                        )}
                    />
                    <RegistrationForm
                        customClass={
                            contentKey !== 'photographers'
                                ? 'invoice-generator-hero__start-trial'
                                : ''
                        }
                        source="contract template hero"
                        showNoCcRequired
                        buttonText={t(
                            'invoiceGeneratorPages.generic.hero.registrationButtonText'
                        )}
                    />
                    <HBMediaElement
                        crop="scale"
                        width={1200}
                        name={heroImage}
                        lazyLoad={false}
                        alt={
                            t(
                                `invoiceGeneratorPages.${contentKey}.hero.imageAlt`
                            ) ||
                            t(`invoiceGeneratorPages.${contentKey}.hero.title`)
                        }
                    />
                </HeroColumn>
            </HeroStrip>
        </Hero>
    );
}

export default memo(InvoiceGeneratorHero);
