import { useTranslation } from 'react-i18next';
import EmailSignatureTemplate from './Email-signature-template';

import './email-signature-preview.scss';

function EmailSignaturePreview() {
    const { t } = useTranslation();

    return (
        <div className="email-signature-preview">
            <div className="email-signature-preview__header">
                <div className="email-signature-preview__header__field">
                    <p className="email-signature-preview__header__label">
                        {t('emailSignatureBlock.preview.header.label.to')}
                    </p>
                    <span className="email-signature-preview__header__to">
                        {t('emailSignatureBlock.preview.header.content.to')}
                    </span>
                </div>
                <div className="email-signature-preview__header__field">
                    <p className="email-signature-preview__header__label">
                        {t('emailSignatureBlock.preview.header.label.subject')}
                    </p>
                    <span className="email-signature-preview__header__subject">
                        {t(
                            'emailSignatureBlock.preview.header.content.subject'
                        )}
                    </span>
                </div>
            </div>
            <div className="email-signature-preview__content">
                <p> {t('emailSignatureBlock.preview.content.lineA')}</p>
                <p> {t('emailSignatureBlock.preview.content.lineB')}</p>
                <p> {t('emailSignatureBlock.preview.content.lineC')}</p>
            </div>
            <EmailSignatureTemplate />
        </div>
    );
}

export default EmailSignaturePreview;
