import { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import UtilsService from '../../Services/UtilsService';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../FeatureBlockElements';
import StorageService from '../../Services/StorageService';

import '../../styles/educators.scss';

const STARTING_DOLLAR_AMOUNT = 2000157;
const DOLLAR_LIMIT = 3015000;

function EducatorStats(): JSX.Element {
    const { t } = useTranslation();

    const startingDollars =
        StorageService.getItem('educatorRevenue') || STARTING_DOLLAR_AMOUNT;

    const [dollars, setDollars] = useState(startingDollars);

    useEffect(() => {
        const timer = window.setTimeout(() => {
            const randomNumber = Math.floor(Math.random() * 100) + 100;
            const newDollarValue = dollars + randomNumber;

            if (dollars < DOLLAR_LIMIT) setDollars(newDollarValue);

            StorageService.setItem({
                key: 'educatorRevenue',
                data: newDollarValue
            });
        }, 1000);

        return () => {
            window.clearTimeout(timer);
        };
    }, [dollars]);

    return (
        <FeatureBlockSection customClass="educator-lp__stat-bar">
            <FeatureBlockContent>
                <div className="educator-lp__stat-bar-container">
                    <div className="educator-lp__stat-bar__stat">
                        <p className="educator-lp__stat-bar__title">
                            ${UtilsService.numberWithCommas(dollars)}+
                        </p>
                        <p className="educator-lp__stat-bar__subtitle">
                            {t('EducatorPage.stats.earnings')}
                        </p>
                    </div>
                    {/* <div className="educator-lp__stat-bar__stat">
							<p className="educator-lp__stat-bar__title educator-lp__stat-bar__title--highlight">
								796
							</p>
							<p className="educator-lp__stat-bar__subtitle">
								{t('EducatorPage.stats.educators')}
							</p>
						</div>
						<div className="educator-lp__stat-bar__stat">
							<p className="educator-lp__stat-bar__title">
								15,352+
							</p>
							<p className="educator-lp__stat-bar__subtitle">
								{t('EducatorPage.stats.referrals')}
							</p>
						</div> */}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default memo(EducatorStats);
