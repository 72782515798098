import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import './testimonial-block.scss';

const TestimonialBlock = ({ image, text, name, description, customClass }) => {
    const { t } = useTranslation();

    return (
        <div className={`testimonial-block ${customClass}`}>
            <HBMediaElement
                name="marketing_site/DJPage/quotes_2x.svg"
                width={23}
                height={18}
                alt="quote"
            />
            <div className="testimonial-block__text">{t(text)}</div>
            <div className="testimonial-block__quoter">
                <HBMediaElement
                    customClass="testimonial-block__face"
                    radius="max"
                    gravity="faces"
                    crop="thumb"
                    name={image}
                    dpr={2}
                    width={40}
                    height={40}
                    alt={t(name)}
                />
                <div className="testimonial-block__description">
                    <div className="testimonial-block__description__name">
                        {t(name)}
                    </div>
                    <div className="testimonial-block__description__title">
                        {t(description)}
                    </div>
                </div>
            </div>
        </div>
    );
};

TestimonialBlock.propTypes = {
    image: PropTypes.string,
    text: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    customClass: PropTypes.string
};

export default memo(TestimonialBlock);
