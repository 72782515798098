export function getCustomFeatureGridData(transIdPrefix: string) {
    return [
        {
            transId: `${transIdPrefix}.featureGrid.allInOne`,
            imagePath: 'marketing_site/pricing/icons/ALL-IN-ONE-hover_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/features'
        },
        {
            transId: `${transIdPrefix}.featureGrid.tracking`,
            imagePath: 'marketing_site/pricing/icons/track-icon_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/all-in-one-business-platform'
        },
        {
            transId: `${transIdPrefix}.featureGrid.invoices`,
            imagePath: 'system_web/templates/invoice-icon.svg',
            mediaFlags: {
                width: 35,
                height: 35,
                forceImgTag: true
            },
            url: '/online-invoices'
        },
        {
            transId: `${transIdPrefix}.featureGrid.proposals`,
            imagePath: 'marketing_site/pricing/icons/proposal-icon_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/proposal-software'
        },
        {
            transId: `${transIdPrefix}.featureGrid.contracts`,
            imagePath: 'marketing_site/pricing/icons/illu-agreement_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/online-contract'
        },
        {
            transId: `${transIdPrefix}.featureGrid.scheduling`,
            imagePath: 'marketing_site/pricing/icons/icon-calendar_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/meeting-scheduler'
        },
        {
            transId: `${transIdPrefix}.featureGrid.onlinePayments`,
            imagePath:
                'marketing_site/pricing/icons/online-payments-icon_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/online-payment-software'
        },
        {
            transId: `${transIdPrefix}.featureGrid.workflows`,
            imagePath: 'marketing_site/pricing/icons/auto-icon_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/automations'
        },
        {
            transId: `${transIdPrefix}.featureGrid.accountMigration`,
            imagePath: 'marketing_site/pricing/icons/icon-home-projects_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/free-account-migration'
        },
        {
            transId: `${transIdPrefix}.featureGrid.clientPortal`,
            imagePath: 'marketing_site/pricing/icons/proposal-icon_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/client-portal-software'
        },
        {
            transId: `${transIdPrefix}.featureGrid.paymentReminders`,
            imagePath:
                'marketing_site/pricing/icons/online-payments-icon_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/payment-reminders'
        },
        {
            transId: `${transIdPrefix}.featureGrid.mobile`,
            imagePath: 'marketing_site/pricing/icons/mobile.svg',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: 'https://honeybook.onelink.me/850908038/kgmrfodc'
        }
    ];
}
