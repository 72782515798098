import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import {
    FeatureBlockContent,
    FeatureBlockSection,
    FeatureBlockText
} from '../../FeatureBlockElements';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

import './intro-block.scss';

function IntroBlock(): JSX.Element {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    return (
        <FeatureBlockSection customClass="intro-block">
            <FeatureBlockContent customClass="intro-block__content">
                <HBMediaElement
                    customClass="intro-block__image"
                    name={`${
                        breakpoints.mediumUp
                            ? '/marketing_site/careers_page/what-we-do-desktop.png'
                            : '/marketing_site/careers_page/what-we-do-mobile.png'
                    }`}
                />
                <div className="intro-block__text-container">
                    <FeatureBlockText
                        customClass="intro-block__text"
                        primary={t(`careersPageRebrand.intro.title`)}
                        secondary={t(`careersPageRebrand.intro.subtitle`)}
                    />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default memo(IntroBlock);
