import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import type { TButtonSize } from '@honeybook-ui/HBButton/HBButton';
import Hero from '../Hero/Hero';
import HeroTitle from '../HeroTitle/HeroTitle';
import HeroStrip from '../HeroStrip/HeroStrip';
import HeroColumn from '../HeroColumn/HeroColumn';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './white-hero.scss';

export const HERO_POSITIONS = { BOTTOM: 'bottom', CENTER: 'center' } as const;

interface Props {
    image: string;
    mainTitle: string;
    description?: string;
    showRegistration: boolean;
    imageAltTag?: string;
    width?: number;
    imageWidth?: number | 'auto';
    imageHeight?: number;
    mImageWidth?: number;
    mImageHeight?: number;
    mobileImage?: string;
    buttonSize?: TButtonSize;
    preTitle?: string;
    customClass?: string;
    titleCustomClass?: string;
    isVideo?: boolean;
    imagePosition?: 'bottom' | 'center';
    children?: any;
    imageProps?: any; // TODO
}

const WhiteHero = ({
    image,
    imageAltTag,
    preTitle,
    mainTitle,
    description,
    imagePosition,
    imageWidth = 'auto',
    imageHeight,
    mImageWidth,
    mImageHeight,
    mobileImage,
    showRegistration,
    customClass = '',
    titleCustomClass = '',
    isVideo,
    children,
    buttonSize = 'medium',
    ...imageProps
}: Props) => (
    <Hero customClass={`white-hero ${customClass}`}>
        <HeroStrip>
            <HeroColumn
                customClass={`white-hero__column white-hero__column--text ${
                    showRegistration ? '' : 'white-hero__column--height'
                }`}
            >
                <HeroTitle
                    customClass={titleCustomClass}
                    preTitle={preTitle}
                    title={mainTitle}
                    subtitle={description}
                />
                {children}
                {showRegistration ? (
                    <RegistrationForm
                        size={buttonSize}
                        source="hero"
                        showNoCcRequired
                    />
                ) : null}
            </HeroColumn>
            <HeroColumn customClass="hero__column hero__column--img">
                <HBMediaElement
                    customClass={`hero__img hero__img--${
                        imagePosition || 'center'
                    } ${mobileImage ? 'white-hero__media-element' : ''}`}
                    name={image}
                    width={imageWidth}
                    height={imageHeight}
                    crop="scale"
                    type={isVideo ? 'video' : 'Image'}
                    forceImgTag
                    alt={imageAltTag || mainTitle}
                    {...imageProps}
                    playsInline
                    autoPlay
                    muted
                    lazyLoad={false}
                />
                {mobileImage ? (
                    <HBMediaElement
                        customClass="hero__img white-hero__media-element--mobile"
                        name={mobileImage}
                        alt={imageAltTag || mainTitle}
                        width={mImageWidth}
                        height={mImageHeight}
                        crop="scale"
                        {...imageProps}
                        lazyLoad={false}
                    />
                ) : null}
            </HeroColumn>
        </HeroStrip>
    </Hero>
);

export default WhiteHero;
