import type { ReactNode } from 'react';
import { Trans } from 'react-i18next';

export type TextType =
    | 'title-l'
    | 'title-m'
    | 'title-s'
    | 'subtitle-l'
    | 'subtitle-s'
    | 'body-xl'
    | 'body-l'
    | 'body-m'
    | 'body-s'
    | 'body-xs';

interface Props {
    children?: ReactNode;
    type: TextType;
    tag: keyof JSX.IntrinsicElements;
    customClass?: string;
}

const typeClasses = {
    'title-l': 'hb-title-l',
    'title-m': 'hb-title-m',
    'title-s': 'hb-title-s',
    'subtitle-l': 'hb-subtitle-l',
    'subtitle-s': 'hb-subtitle-s',
    'body-xl': 'hb-body-text-xl',
    'body-l': 'hb-body-text-l',
    'body-m': 'hb-body-text-m',
    'body-s': 'hb-body-text-s',
    'body-xs': 'hb-body-text-xs'
};

const HBText = ({
    children,
    type = 'body-m',
    tag: ElementTag = 'p',
    customClass = ''
}: Props): JSX.Element => {
    return (
        <ElementTag className={`hb-text ${typeClasses[type]} ${customClass}`}>
            <Trans>{children}</Trans>
        </ElementTag>
    );
};

export default HBText;
