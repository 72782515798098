import Layout from '../components/Layout/Layout';
import Photography from '../components/Coaching/Photography/Photography';

import UtilsService from '../Services/UtilsService';
import { useEffect } from 'react';
import { graphql } from 'gatsby';

const bio = {
    id: 'joy',
    name: 'Joy Michelle',
    bioImgUrl: 'marketing_site/educator/joy-clipped.png',
    logoImgUrl: 'marketing_site/educator/joy-michelle-logo.png',
    heroLink: '',
    memberSince: '2016',
    websiteUrl: 'https://www.joymichellephotography.com/',
    video: {
        id: 'joy-michelle',
        posterURL:
            '//res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1563833804/marketing_site/educator/video-preview.png',
        videoURL: 'https://www.youtube.com/embed/Ayn7cMh4Sqs',
        thumbImage: ''
    },
    services: ['service1', 'service2', 'service3'],
    social: [
        {
            id: 'facebook',
            link: '//www.facebook.com/joymichellephotography',
            icon: 'nx-facebook-square'
        },
        {
            id: 'twitter',
            link: '//twitter.com/joymphotography',
            icon: 'nx-twitter'
        },
        {
            id: 'instagram',
            link: '//www.instagram.com/joyymichelle/',
            icon: 'instagram'
        },
        {
            id: 'pinterest',
            link: '//www.pinterest.com/joyymichelle/',
            icon: 'nx-pinterest'
        },
        {
            id: 'youtube',
            link: '//www.youtube.com/channel/UC-Ou6jRKxcjMrVMxWxLO_fQ',
            icon: 'nx-youtube'
        }
    ]
};

const ogTags = {
    url: 'https://www.honeybook.com/coaching/photography/joy-michelle',
    title: 'Photography Business Coach - Joy Michelle',
    description:
        'Learn from one of the best pro photographers with Joy Michelle. See how she uses HoneyBook’s robust features and workflow to seamlessly book clients.'
};

const promoCode = 'fTi8j';

function CoachingPhotographyTemplate(): JSX.Element {
    useEffect(() => {
        UtilsService.storeInitialUtms(promoCode);
        UtilsService.storeCoupon(promoCode);
    }, []);

    return (
        <Layout ogTags={ogTags}>
            <Photography bio={bio} />
        </Layout>
    );
}

export default CoachingPhotographyTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "coaching"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
