import { memo } from 'react';
import * as PropTypes from 'prop-types';

import NavSubMenuItem from '../NavSubMenuItem/NavSubMenuItem';

import './nav-submenu.scss';

import type { NavBarSubItem } from '../NavBar/NavBar';

interface Props {
    customClass: string;
    items: NavBarSubItem[];
}

function NavSubMenu({ customClass, items }: Props): JSX.Element {
    return (
        <div className={`nav-submenu nav-submenu--${customClass}`}>
            {items.map(item => {
                return (
                    <NavSubMenuItem
                        key={item.title}
                        icon={item.icon}
                        title={item.title}
                        description={item.description}
                        onClick={item.onClick}
                        link={item.link}
                        onMouseEnter={undefined}
                    />
                );
            })}
        </div>
    );
}

NavSubMenu.propTypes = {
    customClass: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default memo(NavSubMenu);
