import { memo, ReactElement } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';
import Article from './article';

import './press-featured-in-block.scss';

interface Props {
    footerText: string | ReactElement;
    articles: {
        id: string;
        imagePath: string;
        customClass?: string;
        link: string;
    }[];
    customClass?: string;
    lightBackground?: boolean;
}

function PressFeaturedInBlock({
    articles,
    customClass = '',
    lightBackground,
    footerText
}: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection
            customClass={`featured-in-block ${customClass}`}
            lightBackground={lightBackground}
        >
            <FeatureBlockContent>
                <FeatureBlockText
                    fullWidth
                    primary={t(`FeaturedInBlock.title`)}
                />
            </FeatureBlockContent>
            <FeatureBlockContent customClass="featured-in-block__articles">
                {articles.map(article => {
                    return (
                        <Article
                            key={article.id}
                            imagePath={article.imagePath}
                            id={article.id}
                            customClass={article.customClass}
                            link={article.link}
                        />
                    );
                })}
            </FeatureBlockContent>
            {footerText && (
                <FeatureBlockContent>
                    <p className="featured-in-block__footer-text">
                        {footerText}
                    </p>
                </FeatureBlockContent>
            )}
        </FeatureBlockSection>
    );
}

PressFeaturedInBlock.propTypes = {
    articles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            imagePath: PropTypes.string.isRequired,
            customClass: PropTypes.string,
            link: PropTypes.string.isRequired
        })
    ).isRequired,
    footerText: PropTypes.object,
    customClass: PropTypes.string,
    lightBackground: PropTypes.bool
};

export default memo(PressFeaturedInBlock);
