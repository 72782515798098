export const KEYWORD_INSERTION_STORE = {
    onlineInvoices: {
        titles: {
            148715514864: 'agencies',
            148715556664: 'architects',
            148715554544: 'audioEngineers',
            148715555664: 'audioProducers',
            139219335582: 'bandManagers',
            148715553544: 'bookkeepers',
            148715552544: 'accountants',
            139219328342: 'caterers',
            139219327342: 'caterers',
            139219323102: 'cinematographers',
            151075946592: 'weddingFilmmakers',
            148715550424: 'cleaners',
            148715551424: 'janitors',
            148715529584: 'coaches',
            148715530704: 'onlineCoaches',
            148715527584: 'consultants',
            148888069619: 'marketingConsultants',
            148888069899: 'digitalMarketingConsultants',
            151939651911: 'businessConsultants',
            149437034075: 'socialMediaConsultants',
            148715549424: 'contractors',
            148715548304: 'copywriters',
            148715525464: 'designers',
            147247562633: 'designers',
            140769220973: 'djs',
            140769221973: 'weddingDjs',
            148715547304: 'dogTrainers',
            148715515864: 'doulas',
            148715540064: 'teachers',
            148715541064: 'tutors',
            139219331462: 'estheticians',
            139219321102: 'eventPlanners',
            139219322102: 'weddingPlanners',
            139219316822: 'florists',
            148715516864: 'franchises',
            149661731663: 'freelancers',
            149661731743: 'freelancers',
            149661731983: 'freelancers',
            149661731703: 'freelancers',
            149661731903: 'freelancers',
            149661732183: 'freelancers',
            149661731943: 'freelancers',
            149661731503: 'freelancers',
            149661731263: 'freelancers',
            149661731423: 'freelancers',
            149661731463: 'freelancers',
            149661732143: 'freelancers',
            148715544184: 'groupPractices',
            139219333582: 'hairStylists',
            148715528824: 'healthCoaches',
            148715533824: 'interiorDesigners',
            148715532704: 'interiorDesigners',
            148715546304: 'lawyers',
            148715531704: 'lifeCoaches',
            139219332462: 'makeupArtists',
            148888070779: 'marketers',
            148715534824: 'digitalMarketers',
            139219334582: 'officiants',
            139219329342: 'photoboothRentalBusinesses',
            139219317982: 'photographers',
            139219319982: 'eventPhotographers',
            139219318982: 'weddingPhotographers',
            148715545184: 'privatePractices',
            148715543184: 'publicRelationProfessionals',
            148715542064: 'realEstateAgents',
            139219330462: 'rentalCompanies',
            148715522344: 'socialMediaManagers',
            148715524464: 'socialMediaMarketers',
            139219326222: 'venueRentalCompanies',
            139219325222: 'weddingVideographers',
            148715519984: 'virtualAssistants',
            148715538944: 'visualArtists',
            148715521344: 'webDesigners',
            148715537944: 'yogaInstructors',
            148715536944: 'yogaStudios'
        }
    }
} as const;
