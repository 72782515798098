import StorageService from '../Services/StorageService';

import { REFERRAL_DISCOUNT_KEY } from '../constants/constants';

const useReferral = () => {
    const storeReferralData = data => {
        StorageService.setItem({
            key: REFERRAL_DISCOUNT_KEY,
            data: {
                discountAmount: data.discount_amount,
                discountDuration: data.discount_duration
            },
            storageType: 'sessionStorage'
        });
    };

    const referralData = StorageService.getItem(
        REFERRAL_DISCOUNT_KEY,
        'sessionStorage'
    );
    const isReferral = !!referralData;

    return {
        storeReferralData,
        referralData,
        isReferral
    };
};

export default useReferral;
