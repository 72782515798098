import type {
    IABTestsDescription,
    IFallbackVariants,
    IStorageUserABTest,
    IUserABTest,
    IUserABTestProperties,
    TVariant
} from '../types/ABTest-types';
import {
    AB_TEST_COOKIE_KEY,
    AB_TEST_STORAGE_KEY,
    FALLBACK_VARIANT_STORAGE_KEY
} from './ABTestToolService';
import AnalyticsService, { AnalyticsEvents } from './AnalyticsService';
import CookiesService from './CookiesService';
import StorageService from './StorageService';
import { CURRENT_AB_TESTS } from '../constants/current-ab-tests';
import UtilsService from './UtilsService';

export abstract class ABTestToolUtils {
    static getUpdatedAbTestsFromLocalStorage(userAbTests: IUserABTest) {
        const abTestsFromLocalStorage =
            (StorageService.getItem(
                AB_TEST_STORAGE_KEY
            ) as IStorageUserABTest) || {};

        const userAbTestsPathsSet = new Set([
            ...Object.keys(userAbTests),
            ...Object.keys(abTestsFromLocalStorage)
        ]);
        [...userAbTestsPathsSet].forEach(path => {
            const userAbTest = userAbTests[path];
            if (userAbTest) {
                if (
                    !abTestsFromLocalStorage[path] ||
                    !abTestsFromLocalStorage[path].version ||
                    abTestsFromLocalStorage[path].version !== userAbTest.version
                ) {
                    abTestsFromLocalStorage[path] = {
                        isSentAnalytics: false,
                        version: userAbTest.version
                    };
                }
            } else {
                delete abTestsFromLocalStorage[path];
            }
        });
        return abTestsFromLocalStorage;
    }

    /** @param userAbTests Will be available only if not production, represents the user ab tests to make sure we not set default variant if variant already exist*/
    static getAbTestCookieString(
        abTests: IUserABTest | IABTestsDescription,
        defaultVariant?: TVariant,
        userAbTests?: IUserABTest | undefined
    ) {
        return Object.values(abTests)
            .map((test: IUserABTestProperties) => {
                let cookieString = `${test.page_path}:`;
                const _variant =
                    defaultVariant &&
                    CURRENT_AB_TESTS[test.page_path]?.variants[defaultVariant]
                        ? defaultVariant
                        : 'a';

                if (
                    userAbTests &&
                    userAbTests[test.page_path] &&
                    !!CURRENT_AB_TESTS[test.page_path][
                        userAbTests[test.page_path].variant as string
                    ]
                ) {
                    cookieString += `${userAbTests[test.page_path].variant}`;
                } else {
                    cookieString += `${_variant ?? test.variant}`;
                }
                if (CURRENT_AB_TESTS[test.page_path]?.version) {
                    cookieString += `:${
                        CURRENT_AB_TESTS[test.page_path].version
                    }`;
                }

                return cookieString;
            })
            .join('&');
    }

    static setAbTestCookie(cookieValue: string) {
        CookiesService.setCookie({
            cookieName: AB_TEST_COOKIE_KEY,
            cookieValue
        });
    }

    static abTestNotExistOrStaging(path: string) {
        return (
            CURRENT_AB_TESTS[path] === undefined ||
            (UtilsService.isStaging() && !UtilsService.isQA())
        );
    }

    static getFallbackVariantsFromSessionStorage(): IFallbackVariants {
        return (
            StorageService.getItem(
                FALLBACK_VARIANT_STORAGE_KEY,
                'sessionStorage'
            ) || {}
        );
    }

    static getFallbackVariant(path: string): TVariant {
        const fallbackVariants =
            ABTestToolUtils.getFallbackVariantsFromSessionStorage();
        return fallbackVariants[path];
    }

    static setFallbackVariant(data: IFallbackVariants) {
        StorageService.setItem({
            key: FALLBACK_VARIANT_STORAGE_KEY,
            data,
            storageType: 'sessionStorage'
        });
    }

    static reportToMixpanelAbTestTool({
        message,
        path = window.location.pathname,
        abTest,
        caller
    }: {
        message: string;
        path?: string;
        abTest: IUserABTestProperties;
        caller: string;
    }) {
        const AB_TEST_TOOL_ERROR_DATA = {
            caller,
            fallback_variant: ABTestToolUtils.getFallbackVariant(path),
            is_cookies_enabled: window.navigator.cookieEnabled,
            prod_ab_test_version: CURRENT_AB_TESTS[path].version,
            ab_test_tool_cookie: CookiesService.getCookie(AB_TEST_COOKIE_KEY),
            message,
            page_path: path,
            ...(abTest && {
                ab_test_version: abTest.version,
                should_use_ab_test: abTest.shouldUseABTest,
                variant: abTest.variant,
                path: abTest.page_path,
                is_current_version_and_prod_version_not_equal:
                    CURRENT_AB_TESTS[path].version !== abTest.version
            })
        };

        AnalyticsService.track(
            AnalyticsEvents.ab_test_tool_error,
            AB_TEST_TOOL_ERROR_DATA
        );

        if (process.env.GATSBY_env !== 'production') {
            console.info(AB_TEST_TOOL_ERROR_DATA);
        }
    }
}
