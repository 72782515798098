import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import FeatureTableRow from './FeatureTableRow';
import TierHeader from './TierHeader';
import TableContents from './FeaturesTableStore';
import type { TPlansForDisplay } from '../../../types/util-types';

import './pricing-table-block.scss';
import HBText from '../../honeybook-ui/HBText/HBText';

interface Props {
    isPriceMonthly: boolean;
    plans: TPlansForDisplay[];
}

const PricingTableBlock = ({ plans, isPriceMonthly }: Props) => {
    const { t, i18n } = useTranslation();

    const [isTableOpen, setIsTableOpen] = useState(false);

    const toggleTableExpand = () => {
        setIsTableOpen(isTableOpen => !isTableOpen);
        AnalyticsService.trackClick(AnalyticsEvents.pricing_table_toggle, {
            source: `pricing table - ${isTableOpen ? 'close' : 'open'} `
        });
    };

    return (
        <div className="pricing-table-block">
            <FeatureBlockSection>
                <FeatureBlockContent customClass="pricing-table-block__expand">
                    <div
                        className={`hbui-button hbui-button--large hbui-button--plain-primary pricing-table-block__expand-btn ${
                            isTableOpen
                                ? 'pricing-table-block__expand-btn--open'
                                : ''
                        }`}
                        onClick={toggleTableExpand}
                    >
                        {t('pricingTable.expandButtonText')}
                        <HBIcon
                            customClass="icon-arrow"
                            name="nx-arrow-down-16"
                        />
                    </div>
                </FeatureBlockContent>
            </FeatureBlockSection>
            {isTableOpen && (
                <FeatureBlockSection customClass="pricing-table-block__container">
                    <FeatureBlockContent>
                        <div
                            id="tier-header-row"
                            className="pricing-table-block__row pricing-table-block__row--header pricing-table-block__row__fixed-top"
                        >
                            <div className="pricing-table-block__cell" />
                            {plans.map(
                                ({
                                    plan,
                                    originalPrice,
                                    priceAfterDiscount
                                }) => (
                                    <TierHeader
                                        isMonthly={isPriceMonthly}
                                        key={plan}
                                        tierId={plan}
                                        price={
                                            priceAfterDiscount ?? originalPrice
                                        }
                                    />
                                )
                            )}
                        </div>

                        {TableContents.map(section => {
                            return (
                                <div key={section.id}>
                                    <div className="pricing-table-block__row pricing-table-block__row--section-header">
                                        <div className="pricing-table-block__cell">
                                            <HBText
                                                tag="h3"
                                                type="title-s"
                                                customClass={`pricing-table-block__cell__${section.id}`}
                                            >
                                                {t(
                                                    `pricingTable.tableContents.${section.id}.title`
                                                )}
                                            </HBText>
                                            {i18n.exists(
                                                `pricingTable.tableContents.${section.id}.subtitle`
                                            ) ? (
                                                <HBText tag="p" type="body-xs">
                                                    {t(
                                                        `pricingTable.tableContents.${section.id}.subtitle`
                                                    )}
                                                </HBText>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div className="pricing-table-block__cell pricing-table-block__cell--tier" />
                                        <div className="pricing-table-block__cell pricing-table-block__cell--tier" />
                                        <div className="pricing-table-block__cell pricing-table-block__cell--tier" />
                                    </div>
                                    <div className="pricing-table-block__features-list">
                                        {section.features.map(feature => {
                                            return (
                                                <FeatureTableRow
                                                    key={feature.id}
                                                    feature={feature}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </FeatureBlockContent>
                </FeatureBlockSection>
            )}
        </div>
    );
};

export default memo(PricingTableBlock);
