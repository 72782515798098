import { useTranslation } from 'react-i18next';
import HBText from '../../honeybook-ui/HBText/HBText';

interface Props {
    hasFailed: boolean;
}

const StepSeven = ({ hasFailed }: Props) => {
    const { t } = useTranslation();

    return (
        <div className="mission-statement-step mission-statement-step--seven">
            <HBText
                tag="p"
                type="body-l"
                customClass="mission-statement-step__question"
            >
                {t('missionStatement.quiz.step7.question')}
            </HBText>
            <input
                autoFocus
                required
                className="mission-statement-step__input"
                type="email"
                placeholder={t('missionStatement.quiz.step7.placeholder')}
            />
            {hasFailed && (
                <HBText
                    tag="p"
                    type="body-s"
                    customClass="mission-statement-step__error"
                >
                    {t('missionStatement.quiz.errors.invalidEmail')}
                </HBText>
            )}
        </div>
    );
};

export default StepSeven;
