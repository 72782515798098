import { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './services-block.scss';

import type { Bio } from '../../Coaching/CoachingHero/CoachingHero';

interface Props {
    bio: Bio;
}

function ServicesBlock({ bio }: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <section className="services">
            <div className="services__container">
                <h2 className="services__title">
                    {t(`coaching.photography.${bio.id}.servicesTitle`)}
                </h2>

                <div className="services__buckets">
                    {bio.services.map((id: string) => (
                        <div className="services__bucket" key={id}>
                            <h3 className="services__subtitle">
                                {t(
                                    `coaching.photography.${bio.id}.services.${id}.title`
                                )}
                            </h3>
                            <p className="services__text">
                                {t(
                                    `coaching.photography.${bio.id}.services.${id}.text`
                                )}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

ServicesBlock.propTypes = {
    bio: PropTypes.shape({
        id: PropTypes.string.isRequired,
        services: PropTypes.arrayOf(PropTypes.string).isRequired
    })
};

export default memo(ServicesBlock);
