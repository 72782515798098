import { memo, useState } from 'react';
import StarRatings from 'react-star-ratings';

// import fetch from "node-fetch"

import './app-store-rating.scss';

function AppStoreRating(): JSX.Element {
    // const hbAppId = process.env.GATSBY_honeybookAppId
    const [appRating, _setAppRating] = useState({
        averageUserRating: 4.7,
        userRatingCount: 6.7
    });

    // TODO: setup env variables
    //   useEffect(() => {
    //     fetch(`//itunes.apple.com/lookup?id=${hbAppId}`)
    //       .then(response => response.json())
    //       .then(rating => {
    //         const { averageUserRating, userRatingCount } = rating.results[0]

    //         setAppRating({ averageUserRating, userRatingCount })
    //       })
    //   }, [])

    return (
        <div className="app-store-rating">
            {`${appRating.averageUserRating} •
			  ${appRating.userRatingCount}K Ratings`}

            <StarRatings
                rating={appRating.averageUserRating}
                starRatedColor="#F19938"
                starDimension="15px"
                starSpacing="0"
                numberOfStars={5}
                name="appRating"
            />
        </div>
    );
}

export default memo(AppStoreRating);
