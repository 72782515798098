import { useState, useEffect, memo, PropsWithChildren } from 'react';
import classnames from 'classnames';
import HBIcon from '../../components/honeybook-ui/HBIcon/HBIcon';
import StorageService from '../../Services/StorageService';
import UtilsService from '../../Services/UtilsService';

import './promo-banner.scss';

export type TPromoBannerTheme = 'light' | 'dark' | 'purple';

interface Props {
    id?: string;
    onClose?: () => void;
    userCanDismiss: boolean;
    customClass?: string;
    theme?: TPromoBannerTheme;
    shouldShowBanner?: boolean;
    path?: string;
}

const BANNER_CLOSE_CLASS_NAME = 'promo-banner-close';
const BANNER_OPEN_CLASS_NAME = 'promo-banner-open';

const PromoBanner = ({
    id,
    children,
    onClose,
    userCanDismiss = true,
    customClass,
    theme = 'light',
    shouldShowBanner,
    path
}: PropsWithChildren<Props>) => {
    const bannerId = `bannerTriggered-${id}`;

    const [hasDismissedPromo, setHasDismissedPromo] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [className, setClassName] = useState(
        `promo-banner promo-banner--${theme} ${customClass}`
    );

    useEffect(() => {
        const dismissedPromoStatus = StorageService.getItem(bannerId) !== null;
        setHasDismissedPromo(dismissedPromoStatus);
    }, []);

    useEffect(() => {
        setIsVisible(!hasDismissedPromo && !!shouldShowBanner);
    }, [shouldShowBanner, hasDismissedPromo]);

    useEffect(() => {
        const bodyClassList = document.body.classList;
        if (isVisible) {
            if (bodyClassList.contains(BANNER_CLOSE_CLASS_NAME)) {
                bodyClassList.replace(
                    BANNER_CLOSE_CLASS_NAME,
                    BANNER_OPEN_CLASS_NAME
                );
            } else {
                bodyClassList.add(BANNER_OPEN_CLASS_NAME);
            }
        } else {
            if (bodyClassList.contains(BANNER_OPEN_CLASS_NAME)) {
                bodyClassList.replace(
                    BANNER_OPEN_CLASS_NAME,
                    BANNER_CLOSE_CLASS_NAME
                );
            } else {
                bodyClassList.add(BANNER_CLOSE_CLASS_NAME);
            }
        }
    }, [isVisible]);

    const handleOnClose = () => {
        StorageService.setItem({ key: bannerId, data: 'true' });
        setHasDismissedPromo(true);
        onClose?.();
    };

    useEffect(() => {
        setClassName(
            classnames('promo-banner', `promo-banner--${theme}`, customClass, {
                show: UtilsService.isBrowser() && isVisible
            })
        );
    }, [theme, isVisible]);

    if (UtilsService.isBrowser() && (!isVisible || path === '/*')) {
        return null;
    }

    return (
        <div className={className}>
            {userCanDismiss && (
                <HBIcon
                    name="nx-close"
                    customClass="promo-banner__close"
                    onClick={handleOnClose}
                />
            )}
            <div className="promo-banner__content">{children}</div>
        </div>
    );
};

export default memo(PromoBanner);
