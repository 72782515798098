import { useTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import HBText from '../../honeybook-ui/HBText/HBText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import { usePromoCode } from '../../../hooks/usePromoCode';

import './pricing-hero-block.scss';

type PricingHeroProps = {
    isPriceMonthly: boolean;
};

const PricingHeroBlock = ({ isPriceMonthly }: PricingHeroProps) => {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    return (
        <FeatureBlockSection
            customClass={`pricing-hero pricing-hero--${promoCode}`}
        >
            <FeatureBlockContent customClass="pricing-hero__content">
                <div className="pricing-hero__text-content">
                    <HBText
                        customClass="pricing-hero__title"
                        tag="h1"
                        type="title-m"
                    >
                        {t('pricingPage.hero.title')}
                    </HBText>
                    <HBText
                        customClass="pricing-hero__subtitle"
                        tag="p"
                        type="body-m"
                    >
                        {t(`pricingPage.hero.subtitle`)}
                    </HBText>

                    <div className="pricing-hero__cta-container">
                        <RegistrationForm
                            customClass="pricing-hero__cta pricing-hero__cta--primary"
                            source={`pricing-hero primary-cta new-design`}
                            buttonText={t('pricingPage.hero.ctaText')}
                            size="large"
                        />
                        <RegistrationForm
                            customClass="pricing-hero__cta pricing-hero__cta--secondary"
                            source={`pricing-hero secondary-cta new-design`}
                            buttonText={t('pricingPage.hero.buyNow')}
                            size="large"
                            buttonStyle="plain-primary"
                            dataForIntakeFormTest={{
                                buyNowData: {
                                    source: 'hero',
                                    period: isPriceMonthly
                                        ? 'monthly'
                                        : 'annual'
                                }
                            }}
                        />
                    </div>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default PricingHeroBlock;
