import { useSignatureTemplateData } from './useSignatureTemplateData';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import {
    SignatureField,
    SignatureImage,
    SignatureJobTitleAndCompany,
    SignatureSocialIcons
} from '../Email-signature-template';

function EmailSignatureTemplateClassic() {
    const {
        name,
        imageUrl,
        jobTitle,
        company,
        phone,
        email,
        address,
        website,
        facebookUrl,
        twitterUrl,
        linkedinUrl,
        instagramUrl,
        pinterestUrl,
        youtubeUrl
    } = useSignatureTemplateData();

    const socialUrls = {
        facebookUrl,
        twitterUrl,
        linkedinUrl,
        instagramUrl,
        pinterestUrl,
        youtubeUrl
    };

    return (
        <div className="email-signature-template email-signature-template--classic">
            <div className="email-signature-template__content">
                <div className="email-signature-template__image-container">
                    <SignatureImage imageUrl={imageUrl} />
                </div>
                <div className="email-signature-template__text-container">
                    <SignatureField fieldName="name" fieldValue={name} />
                    <div className="email-signature-template__line">
                        <SignatureJobTitleAndCompany
                            jobTitle={jobTitle}
                            company={company}
                            spacer="|"
                        />
                    </div>
                    <div className="email-signature-template__line">
                        <SignatureField
                            fieldName="phone"
                            fieldValue={phone}
                            elPrefix={
                                <HBMediaElement name="marketing_site/email-signature/info-icons/Mobile_2_2x.png" />
                            }
                        />
                        <SignatureField
                            fieldName="email"
                            fieldValue={email}
                            elPrefix={
                                <HBMediaElement name="marketing_site/email-signature/info-icons/Web_2_2x.png" />
                            }
                        />
                    </div>
                    <SignatureField
                        fieldName="address"
                        fieldValue={address}
                        elPrefix={
                            <HBMediaElement name="marketing_site/email-signature/info-icons/Location_3_2x.png" />
                        }
                    />
                </div>
            </div>
            <div className="email-signature-template__footer">
                <SignatureField
                    fieldName="website"
                    fieldValue={website}
                    href={website}
                />
                <SignatureSocialIcons socialUrls={socialUrls} />
            </div>
        </div>
    );
}

export default EmailSignatureTemplateClassic;
