import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HBButton from '@honeybook-ui/HBButton/HBButton';

import UtilsService from '../../Services/UtilsService';

import './template-download-button.scss';

const TemplateDownloadModalStepCollectEmail = ({
    t,
    isEmailInvalid,
    invalidateEmail,
    onEmailSubmit
}) => {
    const emailInput = useRef(null);

    const handleEmailSubmit = () => {
        const inputEmail = emailInput.current.value;

        if (UtilsService.isEmailValid(inputEmail)) {
            onEmailSubmit(inputEmail);
        } else {
            invalidateEmail();
        }
    };
    return (
        <>
            <h2 className="template-download-button__title">
                {t('templateDownloadButton.step1.title')}
            </h2>
            <p className="template-download-button__text">
                {t('templateDownloadButton.step1.subtitle')}
            </p>
            <div>
                <div className="hbui-input">
                    <input
                        ref={emailInput}
                        name="email"
                        type="email"
                        className="template-download-button__input input-wrapper"
                        placeholder={t(
                            'templateDownloadButton.step1.inputPlaceholderText'
                        )}
                        tabIndex={0}
                    />
                </div>

                <HBButton
                    onClick={handleEmailSubmit}
                    text={t('templateDownloadButton.step1.ctaText')}
                />
                {isEmailInvalid && (
                    <p className="template-download-button__text">
                        {t('templateDownloadButton.step1.invalid')}
                    </p>
                )}
            </div>
        </>
    );
};

TemplateDownloadModalStepCollectEmail.propTypes = {
    t: PropTypes.func,
    isEmailInvalid: PropTypes.bool,
    invalidateEmail: PropTypes.func,
    onEmailSubmit: PropTypes.func
};

export default withTranslation()(TemplateDownloadModalStepCollectEmail);
