import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Collapse from '../../../vendor/blueprintjs/collapse';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import HBText from '../../honeybook-ui/HBText/HBText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import HBIcon from '../../honeybook-ui/HBIcon/HBIcon';

import AllInOneIcon from '../../../svg/features/all-in-one-icon.inline.svg';
import ProjectTrackingIcon from '../../../svg/features/project-tracking-icon.inline.svg';
import InvoicesIcon from '../../../svg/features/invoices-icon.inline.svg';
import ProposalsIcon from '../../../svg/features/proposals-icon.inline.svg';
import ContractsIcon from '../../../svg/features/contracts-icon.inline.svg';
import SchedulingIcon from '../../../svg/features/scheduling-icon.inline.svg';
import OnlinePaymentsIcon from '../../../svg/features/payments-icon.inline.svg';
import AutomationsIcon from '../../../svg/features/automations-icon.inline.svg';
import AccountMigrationIcon from '../../../svg/features/account-migration-icon.inline.svg';
import ClientPortalIcon from '../../../svg/features/client-portal-icon.inline.svg';
import PaymentRemindersIcon from '../../../svg/features/payment-reminders-icon.inline.svg';
import AiIcon from '../../../svg/features/ai-icon.inline.svg';

import './feature-list-block.scss';

interface Props {
    title: string;
    customClass?: string;
}

const FEATURES_DATA = [
    {
        key: 'allInOne',
        icon: <AllInOneIcon />,
        url: '/all-in-one-business-platform'
    },
    {
        key: 'projectTracking',
        icon: <ProjectTrackingIcon />,
        url: '/features'
    },
    {
        key: 'invoices',
        icon: <InvoicesIcon />,
        url: '/online-invoices'
    },
    {
        key: 'proposals',
        icon: <ProposalsIcon />,
        url: '/proposal-software'
    },
    {
        key: 'contracts',
        icon: <ContractsIcon />,
        url: '/online-contract'
    },
    {
        key: 'scheduling',
        icon: <SchedulingIcon />,
        url: '/meeting-scheduler'
    },
    {
        key: 'onlinePayments',
        icon: <OnlinePaymentsIcon />,
        url: 'online-payment-software'
    },
    {
        key: 'automations',
        icon: <AutomationsIcon />,
        url: '/automations'
    },
    {
        key: 'accountMigration',
        icon: <AccountMigrationIcon />,
        url: '/account-migration'
    },
    {
        key: 'clientPortal',
        icon: <ClientPortalIcon />,
        url: '/client-portal-software'
    },
    {
        key: 'PaymentReminders',
        icon: <PaymentRemindersIcon />,
        url: '/payment-reminders'
    },
    {
        key: 'ai',
        icon: <AiIcon />,
        url: '/lp/ai-start'
    }
];

const FeatureListBlock = ({ customClass, title }: Props) => {
    const { mediumUp } = useBreakpoint();

    const [selectedFeature, setSelectedFeature] = useState('');

    return (
        <FeatureBlockSection customClass={`feature-list-block ${customClass}`}>
            <FeatureBlockContent customClass="feature-list-block__content">
                <HBText
                    customClass="feature-list-block__title"
                    tag="h2"
                    type="title-m"
                >
                    {title}
                </HBText>

                <div
                    className={`feature-list-block__list feature-list-block__list--${
                        mediumUp ? 'desktop' : 'mobile'
                    }`}
                >
                    {FEATURES_DATA.map(feature =>
                        mediumUp ? (
                            <DesktopFeaturePreview
                                key={feature.key}
                                transKey={feature.key}
                                icon={feature.icon}
                                url={feature.url}
                            />
                        ) : (
                            <MobileFeaturePreview
                                key={feature.key}
                                transKey={feature.key}
                                icon={feature.icon}
                                url={feature.url}
                                isSelected={selectedFeature === feature.key}
                                setSelectedFeature={setSelectedFeature}
                            />
                        )
                    )}
                </div>

                <RegistrationForm
                    customClass="feature-list-block__cta"
                    showNoCcRequired={true}
                    source="email_signature_block"
                    buttonStyle="primary"
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

interface DesktopProps {
    transKey: string;
    icon: JSX.Element;
    url: string;
}

const DesktopFeaturePreview = ({ transKey, icon, url }: DesktopProps) => {
    const { t } = useTranslation();

    const handleClick = () => {
        window.open(url, '_blank');
    };

    return (
        <div
            className="feature-list-block__preview feature-list-block__preview--desktop"
            onClick={handleClick}
        >
            {icon}
            <div className="feature-list-block__preview__text-container">
                <p className="feature-list-block__preview__title">
                    {t(`featureListBlock.${transKey}.title`)}
                </p>
                <p className="feature-list-block__preview__description">
                    {t(`featureListBlock.${transKey}.description`)}
                </p>
            </div>
        </div>
    );
};

interface MobileProps {
    transKey: string;
    icon: JSX.Element;
    url: string;
    isSelected: boolean;
    setSelectedFeature: (selectedFeature: string) => void;
}

const MobileFeaturePreview = ({
    transKey,
    icon,
    url,
    isSelected,
    setSelectedFeature
}: MobileProps) => {
    const { t } = useTranslation();

    const handleClick = () => {
        window.open(url, '_blank');
    };

    const className = `
    feature-list-block__preview
    feature-list-block__preview--mobile
    feature-list-block__preview${isSelected ? '--selected' : ''}
    `;

    return (
        <div
            className={className}
            onClick={() => {
                setSelectedFeature(isSelected ? '' : transKey);
            }}
        >
            {icon}
            <div className="feature-list-block__preview__text-container">
                <p className="feature-list-block__preview__title">
                    {t(`featureListBlock.${transKey}.title`)}
                </p>

                <Collapse isOpen={isSelected}>
                    <p
                        className="feature-list-block__preview__description"
                        onClick={handleClick}
                    >
                        {t(`featureListBlock.${transKey}.description`)}
                    </p>
                </Collapse>
            </div>

            <HBIcon
                customClass="feature-list-block__preview__arrow"
                name="nx-arrow-down-16"
            />
        </div>
    );
};

export default FeatureListBlock;
