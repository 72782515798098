import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout/Layout';
import OneBusinessPlatformStore from '../components/FeatureBlocks/OneBusincessPlatformBlock/OneBusinessPlatformBlockStore';

import OneBusinessPlatformHero from '../components/Hero/OneBusinessPlatformHero/OneBusinessPlatformHero';
import OneBusinessPlatformBlock from '../components/FeatureBlocks/OneBusincessPlatformBlock/OneBusinessPlatformBlock';
import TimeManagementBlock from '../components/FeatureBlocks/TimeManagementBlock/TimeManagementBlock';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';

import '../styles/one-business-platform.scss';

const ogTags = {
    url: 'https://www.honeybook.com/all-in-one-business-platform',
    title: 'All-in-One Small Business Management Software',
    description: `Discover HoneyBook's all-in-one small business management software. Streamline everything you need to operate your business in one place.`
};

function AllInOneBusinessPlatformTemplate(): JSX.Element {
    const { t } = useTranslation();

    return (
        <section className="one-business-platform">
            <Layout ogTags={ogTags}>
                <OneBusinessPlatformHero />

                {OneBusinessPlatformStore.blockContent.map((block, index) => {
                    const isEven =
                        index % 2 === 1
                            ? 'one-business-platform-block__even'
                            : '';

                    return (
                        <OneBusinessPlatformBlock
                            block={block}
                            key={block.id}
                            t={t}
                            id={block.id}
                            customClass={`one-business-platform-block ${isEven}`}
                            index={index}
                        />
                    );
                })}
                <TimeManagementBlock customClass="one-business-platform-block__time-management" />
                <FreeToTryBlockRebrand />
            </Layout>
        </section>
    );
}

export default AllInOneBusinessPlatformTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "oneBusinessPlatform"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
