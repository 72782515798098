import { Fragment, memo } from 'react';
import classnames from 'classnames';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Collapse from '../../vendor/blueprintjs/collapse';

import HBIcon from '../honeybook-ui/HBIcon/HBIcon';
import MobileNavSubMenu from '../MobileNavSubMenu/MobileNavSubMenu';

import './mobile-menu-item.scss';

import type { MouseEventHandler } from 'react';
import type { NavBarItem } from '../NavBar/NavBar';

interface Props {
    item: NavBarItem;
    onClick: MouseEventHandler<HTMLButtonElement> &
        MouseEventHandler<HTMLAnchorElement>;
    isCollapseOpen: boolean;
    index: number;
}

function MobileMenuItem({
    item,
    onClick,
    isCollapseOpen,
    index
}: Props): JSX.Element {
    const { t } = useTranslation();

    if (item.subItems) {
        const divClassName = classnames('mobile-menu-item', {
            'mobile-menu-item--open': isCollapseOpen
        });

        const iconClassName = classnames('mobile-menu-item__menu-arrow', {
            'mobile-menu-item__menu-arrow--up': isCollapseOpen
        });

        return (
            <Fragment>
                <div
                    className={divClassName}
                    data-item-id={item.id}
                    data-item-link={item.link}
                    onClick={onClick}
                    role="button"
                    tabIndex="-1"
                >
                    {t(`navBarButtons.${[item.id]}`)}

                    <HBIcon
                        key="2"
                        name="nx-arrow-down-16"
                        customClass={iconClassName}
                    />
                </div>

                <Collapse isOpen={isCollapseOpen} key={item.id}>
                    <MobileNavSubMenu items={item.subItems} />
                </Collapse>
            </Fragment>
        );
    }

    return (
        <a
            href={item.link}
            className="mobile-menu-item"
            data-item-id={item.id}
            data-item-link={item.link}
            onClick={onClick}
            tabIndex={index}
        >
            {t(`navBarButtons.${[item.id]}`)}
        </a>
    );
}

MobileMenuItem.propTypes = {
    item: PropTypes.shape({ id: PropTypes.string }),
    onClick: PropTypes.func,
    isCollapseOpen: PropTypes.bool,
    index: PropTypes.number
};

export default memo(MobileMenuItem);
