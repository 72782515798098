import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

import FacebookIcon from './../../svg/socialMedia/share-facebook.inline.svg';
import TwitterIcon from './../../svg/socialMedia/share-twitter.inline.svg';
import LinkIcon from './../../svg/socialMedia/share-link.inline.svg';

import './social-share-button.scss';

const icons = {
    twitter: <TwitterIcon />,
    facebook: <FacebookIcon />,
    link: <LinkIcon />
};

export type TSocialType = 'facebook' | 'twitter' | 'link';

interface Props {
    buttonStyle: 'dark' | 'color';
    type: TSocialType;
    customClass?: string;
    customMessage?: string;
    customUrl?: string;
}

function SocialShareButton({
    buttonStyle = 'dark',
    type,
    customClass,
    customMessage,
    customUrl
}: Props): JSX.Element {
    const { t } = useTranslation();

    const [copySuccess, setCopySuccess] = useState(false);

    const linkUrl = customUrl || window.location.href;

    const handleSocialShareClick = useCallback(() => {
        AnalyticsService.trackClick(
            AnalyticsEvents.social_share_buttons_click,
            {
                source: type
            }
        );

        const height = 450;
        const width = 650;
        const topPosition = window.screen.height / 2 - height / 2;
        const leftPosition = window.screen.width / 2 - width / 2;

        const queryStringArray = [
            'menubar=no',
            'toolbar=no',
            'resizable=yes',
            'scrollbars=yes',
            `width=${width}`,
            `height=${height}`,
            `top=${topPosition}`,
            `left=${leftPosition}`
        ];

        const queryString = queryStringArray.join(',');

        const message = customMessage?.split(' ').join('%20');

        const baseUrl = {
            twitter: `https://twitter.com/share?${
                message ? `text=${message}` : ''
            }?&url=`,
            facebook: 'https://www.facebook.com/sharer/sharer.php?u='
        };

        window.open(baseUrl[type] + linkUrl, 'Share', queryString);
    }, [type, customUrl, customMessage]);

    const handleCopyClick = useCallback(() => {
        AnalyticsService.trackClick(
            AnalyticsEvents.social_share_buttons_copy_link,
            {
                source: type
            }
        );
        navigator.clipboard.writeText(linkUrl);
        setCopySuccess(true);
        setTimeout(() => {
            setCopySuccess(false);
        }, 2000);
    }, []);

    return (
        <>
            {type !== 'link' ? (
                <button
                    className={`social-share-button social-share-button--${type} social-share-button--${buttonStyle} ${customClass}`}
                    onClick={handleSocialShareClick}
                >
                    {icons[type]}
                </button>
            ) : (
                <>
                    <button
                        className={`social-share-button social-share-button--${type} social-share-button--${buttonStyle} ${customClass}`}
                        onClick={handleCopyClick}
                    >
                        {!copySuccess ? (
                            icons[type]
                        ) : (
                            <span
                                className={`social-share-button__copy-success-text`}
                            >
                                {t(`general.copied`)}
                            </span>
                        )}
                    </button>
                </>
            )}
        </>
    );
}

export default memo(SocialShareButton);
