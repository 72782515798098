import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HBButton, { ButtonStyles } from '@honeybook-ui/HBButton/HBButton';

import './webinar-time-slot.scss';

const getDay = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-us', { weekday: 'long' });
};

const WebinarTimeSlot = ({ t, date, startTime, endTime, onClick, linkUrl }) => {
    return (
        <a href={linkUrl} className="webinar-time-slot" onClick={onClick}>
            <div className="webinar-time-slot__text">
                <p className="webinar-time-slot__date">
                    {getDay(date)}, {date}
                </p>
                <p className="webinar-time-slot__times">
                    {startTime} - {endTime} PT
                </p>
            </div>
            <HBButton
                type="submit"
                className=""
                text={t('productEducationPage.webinars.ctaText')}
                buttonStyle={ButtonStyles.PLAIN}
                size="small"
            />
        </a>
    );
};

WebinarTimeSlot.propTypes = {
    t: PropTypes.func,
    date: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    linkUrl: PropTypes.string
};

export default withTranslation()(WebinarTimeSlot);
