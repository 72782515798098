import { useTranslation } from 'react-i18next';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText,
    FeatureBlockImage
} from '../../FeatureBlockElements';

import './were-first-class-block.scss';

const WereFirstClassBlock = () => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection
            customClass="were-first-class-block"
            darkBackground
            smallHeight
        >
            <FeatureBlockContent position="center">
                <FeatureBlockImage
                    customClass="feature-block__getting-started-img"
                    featureImage="marketing_site/member-love/trophy_2x.png"
                />
                <FeatureBlockText
                    fullWidth
                    primary={t('featureBlock.wereFirstClass.title')}
                    bodyText={t('featureBlock.wereFirstClass.body')}
                />

                <div className="feature-block__signup-wrapper">
                    <RegistrationForm source="were-first-class module" />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default WereFirstClassBlock;
