import { useTranslation } from 'react-i18next';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockUserQuote,
    FeatureBlockImage
} from '../../FeatureBlockElements';
import FeatureTitleList from '../../FeatureTitleList/FeatureTitleList';

import FeaturesInvoiceBlockStore from './FeaturesAllInOneBlockStore';

import './features-all-in-one-block.scss';

const { blockData } = FeaturesInvoiceBlockStore;

const handleAnalytics = () => {
    AnalyticsService.trackClick(
        AnalyticsEvents.lp_features_hub_feature_redirect,
        {
            source: 'all in one'
        }
    );
};

const FeaturesInvoiceBlock = () => {
    const { t } = useTranslation();
    const listName = `featuresPage.${blockData.id}.list`;
    const listItems = Object.keys(
        t(listName, {
            returnObjects: true
        })
    ).map(key => ({ id: key }));

    return (
        <FeatureBlockSection customClass="features-all-in-one-block">
            <FeatureBlockContent customClass="features-all-in-one-block__text-container">
                <FeatureTitleList
                    title={t(`featuresPage.${blockData.id}.title`)}
                    subtitle={t(`featuresPage.${blockData.id}.subtitle`)}
                    listName={`featuresPage.${blockData.id}.list`}
                    items={listItems}
                />
                <div className="features-all-in-one-block__link-container">
                    <a
                        onClick={handleAnalytics}
                        className="features-all-in-one-block__link hbui-button hbui-button--medium hbui-button--secondary"
                        href={blockData.link}
                    >
                        {t(`featuresPage.ctaText`)}
                    </a>
                </div>

                <FeatureBlockUserQuote
                    name={blockData.quoteMember}
                    avatar={blockData.quoteAvatar}
                    jobTitle={t(
                        `featuresPage.${blockData.id}.testimonial.company`
                    )}
                    avatarWidth={40}
                    quote={t(`featuresPage.${blockData.id}.testimonial.quote`)}
                    avatarCrop="thumb"
                    avatarGravity="face"
                />
            </FeatureBlockContent>
            <FeatureBlockContent customClass="feature-block-img">
                <FeatureBlockImage
                    customClass="feature-block__features-all-in-one-img"
                    featureImage={blockData.image}
                    width={360}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default FeaturesInvoiceBlock;
