import HoneybookLogo from '../../svg/honeybook-logo.inline.svg';
import NavigateLink from '../Link/NavigateLink';
import { TScreenModeTabsProps, ScreenMode } from './ScreenMode';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

export const RecipientPageHeader = (props: TScreenModeTabsProps) => {
    const { largeUp } = useBreakpoint();

    return (
        <div className="template-preview__recipient-page__header">
            <NavigateLink
                className="template-preview__recipient-page__header__logo"
                source="template-preview"
                link="/"
            >
                <HoneybookLogo />
            </NavigateLink>
            {largeUp && <ScreenMode {...props} />}
        </div>
    );
};
