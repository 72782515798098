import { memo } from 'react';
import classnames from 'classnames';

import type { InputHTMLAttributes, ReactNode, FormEventHandler } from 'react';

type Alignment = 'center' | 'left' | 'right';

/**
 * Renders common control elements, with additional props to customize appearance.
 * This component is not exported and is only used in this file for `Checkbox`, `Radio`, and `Switch` below.
 */
function alignmentClass(alignment: Alignment | undefined) {
    switch (alignment) {
        case 'left':
            return `bp3-align-left`;
        case 'right':
            return `bp3-align-right`;
        default:
            return undefined;
    }
}

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
    indicatorChildren?: ReactNode;

    // NOTE: HTML props are duplicated here to provide control-specific documentation

    /**
     * Alignment of the indicator within container.
     *
     * @default "left"
     */
    alignIndicator?: Alignment;

    /** Whether the control is checked. */
    checked?: boolean;

    /** JSX label for the control. */
    children?: ReactNode;

    /** Whether the control is initially checked (uncontrolled mode). */
    defaultChecked?: boolean;

    /** Whether the control is non-interactive. */
    disabled?: boolean;

    /** Whether the control should appear as an inline element. */
    inline?: boolean;

    /**
     * Text label for the control.
     *
     * Use `children` or `labelElement` to supply JSX content. This prop actually supports JSX elements,
     * but TypeScript will throw an error because `HTMLAttributes` only allows strings.
     */
    label?: string;

    /** Whether this control should use large styles. */
    large?: boolean;

    /** Event handler invoked when input value is changed. */
    onChange?: FormEventHandler<HTMLInputElement>;

    /**
     * Name of the HTML tag that wraps the checkbox.
     *
     * By default a `<label>` is used, which effectively enlarges the click
     * target to include all of its children. Supply a different tag name if
     * this behavior is undesirable or you're listening to click events from a
     * parent element (as the label can register duplicate clicks).
     *
     * @default "label"
     */
}

function Checkbox({
    id,
    style,
    onChange,
    disabled,
    inline,
    large,
    indicatorChildren,
    alignIndicator,
    className,
    label,
    checked,
    children,
    ...inputHtmlAttributes
}: Props): JSX.Element {
    const classes = classnames(
        `bp3-control-group`,
        `bp3-checkbox`,
        {
            [`bp3-disabled`]: disabled,
            [`bp3-inline`]: inline,
            [`bp3-large`]: large
        },
        alignmentClass(alignIndicator),
        className
    );

    return (
        <label className={classes} htmlFor={id}>
            <input
                id={id}
                type="checkbox"
                onChange={onChange}
                disabled={disabled}
                checked={checked}
                {...inputHtmlAttributes}
            />

            <span className={`bp3-control-group-indicator`}>
                {indicatorChildren}
            </span>

            {label}
            {children}
        </label>
    );
}

export default memo(Checkbox);
