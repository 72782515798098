import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FAQContainer from '../../FAQContainer/FAQContainer';

interface Props {
    title?: string;
    subtitle?: string;
    faqs: {
        id: string;
        answer?: string;
    }[];
    customClass?: string;
    displayContactLink?: boolean;
}

function FAQBlock({
    title,
    subtitle,
    customClass = '',
    faqs,
    displayContactLink
}: Props): JSX.Element {
    const { t } = useTranslation();

    const items = useMemo(() => {
        return faqs.map(({ id, answer }) => {
            return {
                props: {
                    id,
                    answer,
                    question: t(`faqs.${id}.question`),
                    answerTransKey: `faqs.${id}.answer`,
                    context: {
                        source: id
                    }
                }
            };
        });
    }, [t, faqs]);

    return (
        <FAQContainer
            title={title || t('faqs.defaultTitle')}
            subtitle={subtitle}
            customClass={customClass}
            displayContactLink={displayContactLink}
            items={items}
        />
    );
}

export default FAQBlock;
