import { memo, MouseEvent } from 'react';
import * as PropTypes from 'prop-types';

import HBIcon from '@honeybook-ui/HBIcon/HBIcon';
import NumberTextElement from '../../NumberTextElement/NumberTextElement';
import Card from '../../HBCard/Card';
import CardHeader from '../../HBCard/CardHeader/CardHeader';
import CardBody from '../../HBCard/CardBody/CardBody';
import CardFooter from '../../HBCard/CardFooter/CardFooter';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './business-personality-tool-actionable-tips-block.scss';
import BusinessPersonalityToolActionableTipsBlockStore from './BusinessPersonalityToolActionableTipsBlockStore';

const BusinessPersonalityToolActionableTipsBlock = ({ resultKey }) => {
    const sectionTitle =
        BusinessPersonalityToolActionableTipsBlockStore.sectionTitles[
            resultKey
        ];

    const tips =
        BusinessPersonalityToolActionableTipsBlockStore.tips[resultKey];

    const handleClick = (e: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
        const { currentTarget } = e;
        if (currentTarget.tagName === 'A') {
            if (currentTarget.dataset.hbTip) {
                // 'How HoneyBook can help' card link
                AnalyticsService.trackClick(AnalyticsEvents.biz_test_card, {
                    source: currentTarget.innerText,
                    target: (currentTarget as HTMLAnchorElement).href
                });
            } else {
                // Inline links in within actionable tip block
                AnalyticsService.trackClick(AnalyticsEvents.biz_test_tip_link, {
                    source: currentTarget.innerText,
                    target: (currentTarget as HTMLAnchorElement).href
                });
            }
        }
    };

    return (
        <FeatureBlockSection customClass="bptr-actionable-tips">
            <FeatureBlockContent>
                <FeatureBlockText
                    primary={`<strong>Actionable tips.</strong> ${sectionTitle}`}
                />

                {tips.map((tip, index) => (
                    <div
                        className="bptr-actionable-tips__container"
                        onClick={handleClick}
                        key={tip.title}
                    >
                        <NumberTextElement
                            number={index + 1}
                            key={tip.title}
                            title={tip.title}
                            description={tip.body}
                            descriptionTransKey={tip.bodyTranslationKey}
                        />
                        {tip.card && (
                            <Card>
                                <CardHeader>
                                    <HBIcon name="nx-spark" size="large" />
                                    <h2>How HoneyBook can help</h2>
                                </CardHeader>
                                <CardBody>
                                    <p>{tip.card.body}</p>
                                </CardBody>
                                <CardFooter>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-hb-tip
                                        href={tip.card.linkUrl}
                                    >
                                        {tip.card.linkText}
                                        <HBIcon name="nx-arrow-right-16" />
                                    </a>
                                </CardFooter>
                            </Card>
                        )}
                    </div>
                ))}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

BusinessPersonalityToolActionableTipsBlock.propTypes = {
    resultKey: PropTypes.string.isRequired
};

export default memo(BusinessPersonalityToolActionableTipsBlock);
