import InvoicesIcon from '../svg/features/invoices-icon.inline.svg';
import ContractsIcon from '../svg/features/contracts-icon.inline.svg';
import AutomationsIcon from '../svg/features/automations-icon.inline.svg';
import PaymentsIcon from '../svg/features/payments-icon.inline.svg';
import SchedulingIcon from '../svg/features/scheduling-icon.inline.svg';

const InsightsStore = {
    featureValues: [
        {
            id: 'invoices',
            icon: <InvoicesIcon />,
            imagePath:
                'marketing_site/home_page/features_list/v2/Invoice_3x.png',
            link: '/online-invoices'
        },
        {
            id: 'payments',
            icon: <PaymentsIcon />,
            imagePath: 'marketing_site/home_page/features_list/Payments3.png',
            link: '/online-payment-software'
        },
        {
            id: 'templates',
            icon: <ContractsIcon />,
            imagePath:
                'marketing_site/home_page/features_list/v2/Contract_3x.png',
            link: '/online-contract'
        },
        {
            id: 'scheduling',
            icon: <SchedulingIcon />,
            imagePath:
                'marketing_site/home_page/features_list/v2/Schedule_3x.png',
            link: '/meeting-scheduler'
        },
        {
            id: 'automations',
            icon: <AutomationsIcon />,
            imagePath:
                'marketing_site/home_page/features_list/v2/Automation_3x.png',
            link: '/automations'
        }
    ],
    carouselSlideData: [
        {
            id: 'bray',
            imageUrl: 'marketing_site/testimonial-avatars/bray-new.png'
        },
        {
            id: 'ashley',
            imageUrl: 'marketing_site/testimonial-avatars/ashley-new.png'
        },
        {
            id: 'alexis',
            imageUrl: 'marketing_site/testimonial-avatars/alexis-new.png'
        },
        {
            id: 'pamela',
            imageUrl: 'marketing_site/testimonial-avatars/pamela-new.png'
        },
        {
            id: 'amber',
            imageUrl: 'marketing_site/testimonial-avatars/amber-new.png'
        }
    ]
};

export default InsightsStore;
