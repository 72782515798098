import { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './rotating-title.scss';

const RotatingTitle = ({ animationSpeed = 1200, delay = 2000, textArr }) => {
    const totalWords = textArr.length;
    const textRef = useRef();
    const wrapperRef = useRef();
    const [offset, setOffset] = useState(0);

    if (animationSpeed > delay) {
        delay = animationSpeed;
    }

    const updateLoop = () => {
        const elementHeight = textRef.current.offsetHeight;
        const maxHeight = elementHeight * totalWords;
        if (offset >= maxHeight - elementHeight) {
            setOffset(offset => offset + elementHeight);
            setTimeout(() => {
                if (wrapperRef.current) {
                    wrapperRef.current.style.transition = 'none 0s ease';
                }
                setOffset(0);
            }, animationSpeed);
        } else {
            setOffset(offset => offset + elementHeight);
            if (wrapperRef.current) {
                wrapperRef.current.style.transition = `transform ${
                    animationSpeed / 1000
                }s ease`;
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(updateLoop, delay);
        return () => clearInterval(interval);
    }, [offset]);

    const elementStyle = { transform: `translateY(-${offset}px)` };

    return (
        <div className="rotating-title">
            <div
                ref={wrapperRef}
                className="rotating-title__wrapper"
                style={elementStyle}
            >
                {textArr.map(text => (
                    <span key={text} className="rotating-title__text">
                        {text}
                    </span>
                ))}
                <span ref={textRef} className="rotating-title__text">
                    {textArr[0]}
                </span>
            </div>
        </div>
    );
};

RotatingTitle.propTypes = {
    animationSpeed: PropTypes.number,
    delay: PropTypes.number,
    textArr: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default memo(RotatingTitle);
