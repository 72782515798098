import { useEffect } from 'react';
import EventBus, { EventBusOnFunctionArgs } from '../Services/EventBus';

export const useSubscribeToEvent = ({
    eventName,
    subscriber
}: EventBusOnFunctionArgs) => {
    useEffect(() => {
        const unsubscribe = EventBus.on({
            eventName,
            subscriber
        });

        return unsubscribe;
    }, [eventName, subscriber]);
};
