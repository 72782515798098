import { memo, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import VideoWithModal from '../../VideoWithModal/VideoWithModal';

import ReactHtmlParser from 'react-html-parser';

import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './busyness-video-block.scss';

import type { VideoItem } from '../../VideoCarousel/VideoCarousel';

const videoItem: VideoItem = {
    id: 'business-video-block',
    videoURL: 'https://www.youtube.com/embed/iYAxvUBb_yQ',
    posterURL:
        'https://res.cloudinary.com/honeybook/image/upload/v1588095658/marketing_site/busyness/video-placeholder.png',
    thumbImage:
        'https://res.cloudinary.com/honeybook/image/upload/v1588095658/marketing_site/busyness/video-placeholder.png'
};

const BusynessVideoBlock = () => {
    const { t } = useTranslation();

    const secondary = useMemo<JSX.Element>(() => {
        return (
            <Trans
                i18nKey="onlinePaymentsSoftware.videoBlock.subtitle"
                components={ReactHtmlParser(
                    '#TEXT# <a href="/features">#LINK_TEXT#</a>'
                )}
            />
        );
    }, []);

    return (
        <FeatureBlockSection customClass="busyness-video-block" lightBackground>
            <FeatureBlockContent>
                <FeatureBlockText
                    fullWidth
                    primary={t('onlinePaymentsSoftware.videoBlock.title')}
                    secondary={secondary}
                />
                <VideoWithModal selectedVideo={videoItem} />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default memo(BusynessVideoBlock);
