import { memo } from 'react';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';

export interface ClickableIconProps {
    iconImage: string;
    link: string;
}

function ClickableIcon({ link, iconImage }: ClickableIconProps): JSX.Element {
    return (
        <a href={link} target="_blank">
            <HBMediaElement name={iconImage} />
        </a>
    );
}

export default memo(ClickableIcon);
