import { createContext, useState, createRef, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import RegistrationService from '../../Services/RegistrationService';
import ApiService from '../../Services/ApiService';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';
import { trackError } from '../../utils/error.utils';
import HBText from '../honeybook-ui/HBText/HBText';
import HBModal from '../honeybook-ui/HBModal/HBModal';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../FeatureBlockElements';
import EmailSignatureForm from './EmailSignatureForm';
import EmailSignaturePreview from './EmailSignaturePreview';
import EmailSignatureCta from './EmailSignatureCta';
import EmailSignatureFinishModal from './EmailSignatureFinishModal';
import { INITIAL_EMAIL_SIGNATURE_DISPLAY_VALUE } from '../../constants/constants';

import './email-signature-block.scss';

interface TFormData {
    imageUrl: string;
    name: string;
    jobTitle: string;
    company: string;
    email: string;
    address: string;
    phone: string;
    website: string;
    facebookUrl: string;
    twitterUrl: string;
    linkedinUrl: string;
    instagramUrl: string;
    pinterestUrl: string;
    youtubeUrl: string;
}

const INITIAL_VALUE = {
    imageUrl: '',
    name: '',
    jobTitle: '',
    company: '',
    email: '',
    address: '',
    phone: '',
    website: '',
    facebookUrl: '',
    twitterUrl: '',
    linkedinUrl: '',
    instagramUrl: '',
    pinterestUrl: '',
    youtubeUrl: ''
};

interface TContextValue {
    formData: TFormData;
    setFormData: (formData: TFormData) => void;
    selectedTemplateName: string;
    setSelectedTemplateName: (value: string) => void;
    elementToScrollRef: RefObject<HTMLDivElement>;
}
export const EmailSignatureFormContext = createContext({} as TContextValue);

interface Props {
    selectedTemplateNameProp?: string;
    setSelectedTemplateNameProp?: (value: string) => void;
}

function EmailSignatureBlock({
    selectedTemplateNameProp,
    setSelectedTemplateNameProp
}: Props) {
    const { t } = useTranslation();

    const [formData, setFormData] = useState(INITIAL_VALUE);
    const [userEmail, setUserEmail] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [selectedTemplateNameState, setSelectedTemplateNameState] = useState(
        INITIAL_EMAIL_SIGNATURE_DISPLAY_VALUE.selectedTemplateName
    );

    const elementToScrollRef = createRef<HTMLDivElement>();

    const selectedTemplateName =
        selectedTemplateNameProp || selectedTemplateNameState;

    const setSelectedTemplateName =
        setSelectedTemplateNameProp || setSelectedTemplateNameState;

    const contextValue: TContextValue = {
        formData,
        setFormData,
        selectedTemplateName,
        setSelectedTemplateName,
        elementToScrollRef
    };

    const onSubmitForm = async () => {
        const utmParams = RegistrationService.getUtms();
        const organizedData = {
            logo_image_url: formData.imageUrl,
            full_name: formData.name,
            job_title: formData.jobTitle,
            company_name: formData.company,
            stripped_email: formData.email,
            company_address: formData.address,
            company_phone: formData.phone,
            website_url: formData.website,
            facebook_url: formData.facebookUrl,
            instagram_url: formData.instagramUrl,
            twitter_url: formData.twitterUrl,
            pinterest_url: formData.pinterestUrl,
            linkedin_url: formData.linkedinUrl,
            youtube_url: formData.youtubeUrl,
            template_name: selectedTemplateName,
            email: formData.email,
            accept_getting_emails: true,
            utm_params: utmParams,
            email_to_send: userEmail
        };

        try {
            await ApiService.createEmailSignature(organizedData);
            AnalyticsService.reportIterableEvent(
                'lead_created',
                {
                    lead_type: 'lead_email_signature',
                    company_name: formData.company,
                    email: organizedData.email
                },
                { email: organizedData.email }
            );
            return true;
        } catch (err) {
            AnalyticsService.track(
                AnalyticsEvents.lp_email_signature_create_failed
            );
            trackError(err, {
                message: 'failed to create email signature'
            });
            return false;
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
        AnalyticsService.track(AnalyticsEvents.lp_email_signature_load_modal);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        AnalyticsService.track(AnalyticsEvents.lp_email_signature_cancel);
    };

    return (
        <div id="email-signature-block">
            <EmailSignatureFormContext.Provider value={contextValue}>
                <FeatureBlockSection customClass="email-signature-block">
                    <FeatureBlockContent customClass="email-signature-block__content">
                        <EmailSignatureForm />

                        <div
                            className="email-signature-block__preview-container"
                            ref={elementToScrollRef}
                        >
                            <HBText
                                customClass="email-signature-block__preview-container__title"
                                tag="h2"
                                type="title-s"
                            >
                                {t('emailSignatureBlock.preview.title')}
                            </HBText>
                            <EmailSignaturePreview />
                            <EmailSignatureCta openModal={openModal} />
                        </div>
                    </FeatureBlockContent>
                    <HBModal
                        className="email-signature-block__finish-modal"
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        children={
                            <EmailSignatureFinishModal
                                email={formData.email}
                                userEmail={userEmail}
                                setUserEmail={setUserEmail}
                                onSubmitForm={onSubmitForm}
                            />
                        }
                    />
                </FeatureBlockSection>
            </EmailSignatureFormContext.Provider>
        </div>
    );
}

export default EmailSignatureBlock;
