const TaxStore = {
    FILING_STATUSES_MAXIMUMS: {
        single: 160200,
        marriedJointly: 320400,
        marriedSeparately: 160200
    },
    SELF_EMPLOYMENT_RATE: 0.153,
    SELF_EMPLOYMENT_TAX_DEDUCTION_RATE: 0.5,
    SLIDER_RANGES: {
        income1099: {
            min: 5000,
            max: 300000,
            step: 5000
        },
        incomeW2: {
            min: 5000,
            max: 300000,
            step: 5000
        },
        expenses: {
            min: 5000,
            max: 300000,
            step: 5000
        }
    },
    STANDARD_DEDUCTIONS: {
        single: 13850,
        marriedJointly: 27700,
        marriedSeparately: 13850
    },
    FED_TAX_BRACKETS: {
        single: [
            { limit: 11000, rate: 0.1 },
            { limit: 44725, rate: 0.12 },
            { limit: 95375, rate: 0.22 },
            { limit: 182100, rate: 0.24 },
            { limit: 231250, rate: 0.32 },
            { limit: 578125, rate: 0.35 },
            { limit: Infinity, rate: 0.37 }
        ],
        marriedJointly: [
            { limit: 22000, rate: 0.1 },
            { limit: 89450, rate: 0.12 },
            { limit: 190750, rate: 0.22 },
            { limit: 364200, rate: 0.24 },
            { limit: 462500, rate: 0.32 },
            { limit: 693750, rate: 0.35 },
            { limit: Infinity, rate: 0.37 }
        ],
        marriedSeparately: [
            { limit: 11000, rate: 0.1 },
            { limit: 44725, rate: 0.12 },
            { limit: 95375, rate: 0.22 },
            { limit: 182100, rate: 0.24 },
            { limit: 231250, rate: 0.32 },
            { limit: 346875, rate: 0.35 },
            { limit: Infinity, rate: 0.37 }
        ]
    },
    STATE_TAX_BRACKETS: {
        AL: {
            single: [
                { limit: 500, rate: 0.02 },
                { limit: 3000, rate: 0.04 },
                { limit: Infinity, rate: 0.05 }
            ],
            marriedJointly: [
                { limit: 500, rate: 0.02 },
                { limit: 3000, rate: 0.04 },
                { limit: Infinity, rate: 0.05 }
            ]
        },
        AK: {
            single: null,
            marriedJointly: null
        },
        AZ: {
            single: [{ limit: Infinity, rate: 0.025 }],
            marriedJointly: [{ limit: Infinity, rate: 0.025 }]
        },
        AR: {
            single: [
                { limit: 4300, rate: 0.02 },
                { limit: 8500, rate: 0.04 },
                { limit: Infinity, rate: 0.049 }
            ],
            marriedJointly: [
                { limit: 4300, rate: 0.02 },
                { limit: 8500, rate: 0.04 },
                { limit: Infinity, rate: 0.049 }
            ]
        },
        CA: {
            single: [
                { limit: 10099, rate: 0.01 },
                { limit: 23942, rate: 0.02 },
                { limit: 37788, rate: 0.04 },
                { limit: 52455, rate: 0.06 },
                { limit: 66295, rate: 0.08 },
                { limit: 338639, rate: 0.093 },
                { limit: 406364, rate: 0.103 },
                { limit: 677275, rate: 0.113 },
                { limit: 1000000, rate: 0.123 },
                { limit: Infinity, rate: 0.133 }
            ],
            marriedJointly: [
                { limit: 20198, rate: 0.01 },
                { limit: 47884, rate: 0.02 },
                { limit: 75576, rate: 0.04 },
                { limit: 104910, rate: 0.06 },
                { limit: 132590, rate: 0.08 },
                { limit: 677278, rate: 0.093 },
                { limit: 812728, rate: 0.103 },
                { limit: 1000000, rate: 0.113 },
                { limit: 1354550, rate: 0.123 },
                { limit: Infinity, rate: 0.133 }
            ]
        },
        CO: {
            single: [{ limit: Infinity, rate: 0.044 }],
            marriedJointly: [{ limit: Infinity, rate: 0.044 }]
        },
        CT: {
            single: [
                { limit: 10000, rate: 0.03 },
                { limit: 50000, rate: 0.05 },
                { limit: 100000, rate: 0.055 },
                { limit: 200000, rate: 0.06 },
                { limit: 250000, rate: 0.065 },
                { limit: 500000, rate: 0.069 },
                { limit: Infinity, rate: 0.0699 }
            ],
            marriedJointly: [
                { limit: 20000, rate: 0.03 },
                { limit: 100000, rate: 0.05 },
                { limit: 200000, rate: 0.055 },
                { limit: 400000, rate: 0.06 },
                { limit: 500000, rate: 0.065 },
                { limit: 1000000, rate: 0.069 },
                { limit: Infinity, rate: 0.0699 }
            ]
        },
        DE: {
            single: [
                { limit: 5000, rate: 0.022 },
                { limit: 10000, rate: 0.039 },
                { limit: 20000, rate: 0.048 },
                { limit: 25000, rate: 0.052 },
                { limit: 60000, rate: 0.0555 },
                { limit: Infinity, rate: 0.066 }
            ],
            marriedJointly: [
                { limit: 5000, rate: 0.022 },
                { limit: 10000, rate: 0.039 },
                { limit: 20000, rate: 0.048 },
                { limit: 25000, rate: 0.052 },
                { limit: 60000, rate: 0.0555 },
                { limit: Infinity, rate: 0.066 }
            ]
        },
        FL: {
            single: null,
            marriedJointly: null
        },
        GA: {
            single: [
                { limit: 750, rate: 0.01 },
                { limit: 2250, rate: 0.02 },
                { limit: 3750, rate: 0.03 },
                { limit: 5250, rate: 0.04 },
                { limit: 7000, rate: 0.05 },
                { limit: Infinity, rate: 0.0575 }
            ],
            marriedJointly: [
                { limit: 1000, rate: 0.01 },
                { limit: 3000, rate: 0.02 },
                { limit: 5000, rate: 0.03 },
                { limit: 7000, rate: 0.04 },
                { limit: 10000, rate: 0.05 },
                { limit: Infinity, rate: 0.0575 }
            ]
        },
        HI: {
            single: [
                { limit: 2400, rate: 0.014 },
                { limit: 4800, rate: 0.032 },
                { limit: 9600, rate: 0.055 },
                { limit: 14400, rate: 0.064 },
                { limit: 19200, rate: 0.068 },
                { limit: 24000, rate: 0.072 },
                { limit: 36000, rate: 0.076 },
                { limit: 48000, rate: 0.079 },
                { limit: 150000, rate: 0.0825 },
                { limit: 175000, rate: 0.09 },
                { limit: 200000, rate: 0.1 },
                { limit: Infinity, rate: 0.11 }
            ],
            marriedJointly: [
                { limit: 4800, rate: 0.014 },
                { limit: 9600, rate: 0.032 },
                { limit: 19200, rate: 0.055 },
                { limit: 28800, rate: 0.064 },
                { limit: 38400, rate: 0.068 },
                { limit: 48000, rate: 0.072 },
                { limit: 72000, rate: 0.076 },
                { limit: 96000, rate: 0.079 },
                { limit: 300000, rate: 0.0825 },
                { limit: 350000, rate: 0.09 },
                { limit: 400000, rate: 0.1 },
                { limit: Infinity, rate: 0.11 }
            ]
        },
        ID: {
            single: [{ limit: Infinity, rate: 0.058 }],
            marriedJointly: [{ limit: Infinity, rate: 0.058 }]
        },
        IL: {
            single: [{ limit: Infinity, rate: 0.0495 }],
            marriedJointly: [{ limit: Infinity, rate: 0.0495 }]
        },
        IN: {
            single: [{ limit: Infinity, rate: 0.0315 }],
            marriedJointly: [{ limit: Infinity, rate: 0.0315 }]
        },
        IA: {
            single: [
                { limit: 6000, rate: 0.044 },
                { limit: 30000, rate: 0.0482 },
                { limit: 75000, rate: 0.057 },
                { limit: Infinity, rate: 0.06 }
            ],
            marriedJointly: [
                { limit: 12000, rate: 0.044 },
                { limit: 60000, rate: 0.0482 },
                { limit: 150000, rate: 0.057 },
                { limit: Infinity, rate: 0.06 }
            ]
        },
        KS: {
            single: [
                { limit: 15000, rate: 0.031 },
                { limit: 30000, rate: 0.0525 },
                { limit: Infinity, rate: 0.057 }
            ],
            marriedJointly: [
                { limit: 30000, rate: 0.031 },
                { limit: 60000, rate: 0.0525 },
                { limit: Infinity, rate: 0.057 }
            ]
        },
        KY: {
            single: [{ limit: Infinity, rate: 0.045 }],
            marriedJointly: [{ limit: Infinity, rate: 0.045 }]
        },
        LA: {
            single: [
                { limit: 12500, rate: 0.0185 },
                { limit: 50000, rate: 0.035 },
                { limit: Infinity, rate: 0.0425 }
            ],
            marriedJointly: [
                { limit: 25000, rate: 0.0185 },
                { limit: 100000, rate: 0.035 },
                { limit: Infinity, rate: 0.0425 }
            ]
        },
        ME: {
            single: [
                { limit: 24500, rate: 0.058 },
                { limit: 58050, rate: 0.0675 },
                { limit: Infinity, rate: 0.0715 }
            ],
            marriedJointly: [
                { limit: 49050, rate: 0.058 },
                { limit: 116100, rate: 0.0675 },
                { limit: Infinity, rate: 0.0715 }
            ]
        },
        MD: {
            single: [
                { limit: 1000, rate: 0.02 },
                { limit: 2000, rate: 0.03 },
                { limit: 3000, rate: 0.04 },
                { limit: 100000, rate: 0.0475 },
                { limit: 125000, rate: 0.05 },
                { limit: 150000, rate: 0.0525 },
                { limit: 250000, rate: 0.055 },
                { limit: Infinity, rate: 0.0575 }
            ],
            marriedJointly: [
                { limit: 1000, rate: 0.02 },
                { limit: 2000, rate: 0.03 },
                { limit: 3000, rate: 0.04 },
                { limit: 150000, rate: 0.0475 },
                { limit: 175000, rate: 0.05 },
                { limit: 225000, rate: 0.0525 },
                { limit: 300000, rate: 0.055 },
                { limit: Infinity, rate: 0.0575 }
            ]
        },
        MA: {
            single: [
                { limit: 1000000, rate: 0.05 },
                { limit: Infinity, rate: 0.09 }
            ],
            marriedJointly: [
                { limit: 1000000, rate: 0.05 },
                { limit: Infinity, rate: 0.09 }
            ]
        },
        MI: {
            single: [
                { limit: 10000, rate: 0 },
                { limit: Infinity, rate: 0.05 }
            ],
            marriedJointly: [
                { limit: 10000, rate: 0 },
                { limit: Infinity, rate: 0.05 }
            ]
        },
        MN: {
            single: [
                { limit: 30070, rate: 0.0535 },
                { limit: 98760, rate: 0.068 },
                { limit: 183340, rate: 0.0785 },
                { limit: Infinity, rate: 0.0985 }
            ],
            marriedJointly: [
                { limit: 43950, rate: 0.0535 },
                { limit: 174610, rate: 0.068 },
                { limit: 304970, rate: 0.0785 },
                { limit: Infinity, rate: 0.0985 }
            ]
        },
        MS: {
            single: [{ limit: Infinity, rate: 0 }],
            marriedJointly: [{ limit: Infinity, rate: 0 }]
        },
        MO: {
            single: [
                { limit: 2242, rate: 0.02 },
                { limit: 3363, rate: 0.025 },
                { limit: 4484, rate: 0.03 },
                { limit: 5605, rate: 0.035 },
                { limit: 6726, rate: 0.04 },
                { limit: 7847, rate: 0.045 },
                { limit: Infinity, rate: 0.0495 }
            ],
            marriedJointly: [
                { limit: 2242, rate: 0.02 },
                { limit: 3363, rate: 0.025 },
                { limit: 4484, rate: 0.03 },
                { limit: 5605, rate: 0.035 },
                { limit: 6726, rate: 0.04 },
                { limit: 7847, rate: 0.045 },
                { limit: Infinity, rate: 0.0495 }
            ]
        },
        MT: {
            single: [
                { limit: 3600, rate: 0.01 },
                { limit: 6300, rate: 0.02 },
                { limit: 9700, rate: 0.03 },
                { limit: 13000, rate: 0.04 },
                { limit: 16800, rate: 0.05 },
                { limit: 21600, rate: 0.06 },
                { limit: Infinity, rate: 0.0675 }
            ],
            marriedJointly: [
                { limit: 3600, rate: 0.01 },
                { limit: 6300, rate: 0.02 },
                { limit: 9700, rate: 0.03 },
                { limit: 13000, rate: 0.04 },
                { limit: 16800, rate: 0.05 },
                { limit: 21600, rate: 0.06 },
                { limit: Infinity, rate: 0.0675 }
            ]
        },
        NE: {
            single: [
                { limit: 3700, rate: 0.0246 },
                { limit: 22170, rate: 0.0351 },
                { limit: 35730, rate: 0.0501 },
                { limit: Infinity, rate: 0.0664 }
            ],
            marriedJointly: [
                { limit: 7390, rate: 0.0246 },
                { limit: 44350, rate: 0.0351 },
                { limit: 71460, rate: 0.0501 },
                { limit: Infinity, rate: 0.0664 }
            ]
        },
        NV: {
            single: null,
            marriedJointly: null
        },
        NH: {
            single: null,
            marriedJointly: null
        },
        NJ: {
            single: [
                { limit: 20000, rate: 0.014 },
                { limit: 35000, rate: 0.0175 },
                { limit: 40000, rate: 0.035 },
                { limit: 75000, rate: 0.0525 },
                { limit: 500000, rate: 0.0637 },
                { limit: 1000000, rate: 0.0897 },
                { limit: Infinity, rate: 0.1075 }
            ],
            marriedJointly: [
                { limit: 20000, rate: 0.014 },
                { limit: 50000, rate: 0.0175 },
                { limit: 70000, rate: 0.0245 },
                { limit: 80000, rate: 0.035 },
                { limit: 150000, rate: 0.0525 },
                { limit: 500000, rate: 0.0637 },
                { limit: Infinity, rate: 0.0897 }
            ]
        },
        NM: {
            single: [
                { limit: 5500, rate: 0.017 },
                { limit: 11000, rate: 0.032 },
                { limit: 16000, rate: 0.047 },
                { limit: 210000, rate: 0.049 },
                { limit: Infinity, rate: 0.059 }
            ],
            marriedJointly: [
                { limit: 8000, rate: 0.017 },
                { limit: 16000, rate: 0.032 },
                { limit: 24000, rate: 0.047 },
                { limit: 315000, rate: 0.049 },
                { limit: Infinity, rate: 0.059 }
            ]
        },
        NY: {
            single: [
                { limit: 8500, rate: 0.04 },
                { limit: 11700, rate: 0.045 },
                { limit: 13900, rate: 0.0525 },
                { limit: 80650, rate: 0.055 },
                { limit: 215400, rate: 0.06 },
                { limit: 1077550, rate: 0.0685 },
                { limit: 5000000, rate: 0.0965 },
                { limit: 25000000, rate: 0.103 },
                { limit: Infinity, rate: 0.109 }
            ],
            marriedJointly: [
                { limit: 17150, rate: 0.04 },
                { limit: 23600, rate: 0.045 },
                { limit: 27900, rate: 0.0525 },
                { limit: 161550, rate: 0.055 },
                { limit: 323200, rate: 0.06 },
                { limit: 2155350, rate: 0.0685 },
                { limit: 5000000, rate: 0.0965 },
                { limit: 25000000, rate: 0.103 },
                { limit: Infinity, rate: 0.109 }
            ]
        },
        NC: {
            single: [{ limit: Infinity, rate: 0.0475 }],
            marriedJointly: [{ limit: Infinity, rate: 0.0475 }]
        },
        ND: {
            single: [
                { limit: 41775, rate: 0.011 },
                { limit: 101050, rate: 0.0204 },
                { limit: 210825, rate: 0.0227 },
                { limit: 458350, rate: 0.0264 },
                { limit: Infinity, rate: 0.029 }
            ],
            marriedJointly: [
                { limit: 69700, rate: 0.011 },
                { limit: 168450, rate: 0.0204 },
                { limit: 256650, rate: 0.0227 },
                { limit: 458350, rate: 0.0264 },
                { limit: Infinity, rate: 0.029 }
            ]
        },
        OH: {
            single: [
                { limit: 46100, rate: 0.02765 },
                { limit: 92150, rate: 0.03226 },
                { limit: 115300, rate: 0.03688 },
                { limit: Infinity, rate: 0.0399 }
            ],
            marriedJointly: [
                { limit: 46100, rate: 0.02765 },
                { limit: 92150, rate: 0.03226 },
                { limit: 115300, rate: 0.03688 },
                { limit: Infinity, rate: 0.0399 }
            ]
        },
        OK: {
            single: [
                { limit: 1000, rate: 0.0025 },
                { limit: 2500, rate: 0.0075 },
                { limit: 3750, rate: 0.0175 },
                { limit: 4900, rate: 0.0275 },
                { limit: 7200, rate: 0.0375 },
                { limit: Infinity, rate: 0.0475 }
            ],
            marriedJointly: [
                { limit: 2000, rate: 0.0025 },
                { limit: 5000, rate: 0.0075 },
                { limit: 7500, rate: 0.0175 },
                { limit: 9800, rate: 0.0275 },
                { limit: 12200, rate: 0.0375 },
                { limit: Infinity, rate: 0.0475 }
            ]
        },
        OR: {
            single: [
                { limit: 4050, rate: 0.0475 },
                { limit: 10200, rate: 0.0675 },
                { limit: 125000, rate: 0.0875 },
                { limit: Infinity, rate: 0.099 }
            ],
            marriedJointly: [
                { limit: 8100, rate: 0.0475 },
                { limit: 20400, rate: 0.0675 },
                { limit: 250000, rate: 0.0875 },
                { limit: Infinity, rate: 0.099 }
            ]
        },
        PA: {
            single: [{ limit: Infinity, rate: 0.0307 }],
            marriedJointly: [{ limit: Infinity, rate: 0.0307 }]
        },
        RI: {
            single: [
                { limit: 68200, rate: 0.0375 },
                { limit: 155050, rate: 0.0475 },
                { limit: Infinity, rate: 0.0599 }
            ],
            marriedJointly: [
                { limit: 68200, rate: 0.0375 },
                { limit: 155050, rate: 0.0475 },
                { limit: Infinity, rate: 0.0599 }
            ]
        },
        SC: {
            single: [
                { limit: 3200, rate: 0.0 },
                { limit: 16040, rate: 0.03 },
                { limit: Infinity, rate: 0.065 }
            ],
            marriedJointly: [
                { limit: 3200, rate: 0.0 },
                { limit: 16040, rate: 0.03 },
                { limit: Infinity, rate: 0.065 }
            ]
        },
        SD: {
            single: null,
            marriedJointly: null
        },
        TN: {
            single: null,
            marriedJointly: null
        },
        TX: {
            single: null,
            marriedJointly: null
        },
        UT: {
            single: [{ limit: Infinity, rate: 0.0485 }],
            marriedJointly: [{ limit: Infinity, rate: 0.0485 }]
        },
        VT: {
            single: [
                { limit: 42150, rate: 0.0335 },
                { limit: 102200, rate: 0.066 },
                { limit: 213150, rate: 0.076 },
                { limit: Infinity, rate: 0.0875 }
            ],
            marriedJointly: [
                { limit: 70450, rate: 0.0335 },
                { limit: 170300, rate: 0.066 },
                { limit: 259500, rate: 0.076 },
                { limit: Infinity, rate: 0.0875 }
            ]
        },
        VA: {
            single: [
                { limit: 3000, rate: 0.02 },
                { limit: 5000, rate: 0.03 },
                { limit: 17000, rate: 0.05 },
                { limit: Infinity, rate: 0.0575 }
            ],
            marriedJointly: [
                { limit: 3000, rate: 0.02 },
                { limit: 5000, rate: 0.03 },
                { limit: 17000, rate: 0.05 },
                { limit: Infinity, rate: 0.0575 }
            ]
        },
        WA: {
            single: null,
            marriedJointly: null
        },
        WV: {
            single: [
                { limit: 10000, rate: 0.03 },
                { limit: 25000, rate: 0.04 },
                { limit: 40000, rate: 0.045 },
                { limit: 60000, rate: 0.06 },
                { limit: Infinity, rate: 0.065 }
            ],
            marriedJointly: [
                { limit: 10000, rate: 0.03 },
                { limit: 25000, rate: 0.04 },
                { limit: 40000, rate: 0.045 },
                { limit: 60000, rate: 0.06 },
                { limit: Infinity, rate: 0.065 }
            ]
        },
        WI: {
            single: [
                { limit: 13810, rate: 0.0354 },
                { limit: 27630, rate: 0.0465 },
                { limit: 304170, rate: 0.053 },
                { limit: Infinity, rate: 0.0765 }
            ],
            marriedJointly: [
                { limit: 18420, rate: 0.0354 },
                { limit: 36840, rate: 0.0465 },
                { limit: 405550, rate: 0.053 },
                { limit: Infinity, rate: 0.0765 }
            ]
        },
        WY: {
            single: null,
            marriedJointly: null
        }
    },
    INDUSTRIES: [
        'accounting',
        'advertising',
        'artGalleriesAndStudios',
        'artist',
        'bakery',
        'balloonSpecialistArtist',
        'bookkeeping',
        'brandDesign',
        'brandPhotographer',
        'businessCoaching',
        'businessConsulting',
        'calligraphy',
        'careerCoaching',
        'catering',
        'cleaningServices',
        'coaching',
        'consulting',
        'contentCreation',
        'contentMarketing',
        'creativeStrategyConsulting',
        'digitalMarketing',
        'dj',
        'doula',
        'educationalConsulting',
        'entertainmentServices',
        'eventPlanning',
        'eventRentalsAndSupplies',
        'executiveCoaching',
        'financialCoaching',
        'financialPlanningAndAdvisory',
        'floralDesign',
        'foodDrinks',
        'furnitureDesign',
        'graphicDesign',
        'hairMakeup',
        'healthAndCounselingServices',
        'healthAndWellnessCoaching',
        'healthcareStrategyConsulting',
        'homeAppraisalAndInspection',
        'homeOrganization',
        'homeRenovationAndImprovement',
        'homeStaging',
        'humanResourcesConsulting',
        'illustrator',
        'influencerMarketing',
        'interiorDesignAndDecor',
        'investmentAndWealthManagement',
        'itConsulting',
        'landscapingAndLawnCare',
        'leadershipCoaching',
        'lifeCoaching',
        'lightingAv',
        'managementConsulting',
        'marketingAgency',
        'marketingConsultingStrategy',
        'musicAndArtsEducation',
        'musicProductionAndRecording',
        'officiant',
        'performingArtsAndTheater',
        'personalChef',
        'personalConcierge',
        'personalStyling',
        'personalTrainingAndFitness',
        'petCareAndServices',
        'photography',
        'productDesign',
        'publicRelations',
        'relationshipCoaching',
        'rentalsPhotobooth',
        'searchEngineOptimization',
        'sleepCoach',
        'socialMediaMarketing',
        'stationery',
        'strategyConsulting',
        'talentAndArtistManagement',
        'technologyStrategyConsulting',
        'travelPlanningAndConsultation',
        'tutoringAndTestPreparation',
        'venue',
        'videography',
        'virtualAssistant',
        'visualDesign',
        'webDesign',
        'writingAndEditing'
    ],
    US_STATES: [
        'AL',
        'AK',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'FL',
        'GA',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY'
    ],
    BUSINESS_TYPES: ['llc', 'sole', 'partnership', 'corporation']
};

export default TaxStore;
