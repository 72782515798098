import { useTranslation } from 'react-i18next';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import Card from '../HBCard/Card';
import type { TPricingPlans } from '../../types/util-types';
import HBText from '../honeybook-ui/HBText/HBText';
import { usePromoCode } from '../../hooks/usePromoCode';
import HBIcon from '../honeybook-ui/HBIcon/HBIcon';
import Tooltip from '../Tooltip/Tooltip';
import InfoCircleIcon from '../../svg/infoCircle.inline.svg';

import './pricing-card.scss';

const BENEFITS: Record<TPricingPlans, string[]> = {
    starter: [
        'unlimitedClientsAndProjects',
        'invoicesAndPayments',
        'proposalsAndContracts',
        'calender',
        'allProfessionalTemplates',
        'clientPortal',
        'basicReports'
    ],
    essentials: [
        'scheduler',
        'automations',
        'quickbooks',
        'upToTwoMembers',
        'expenseManagement',
        'poweredBy',
        'standardReports',
        'aiEssentials'
    ],
    premium: [
        'unlimitedTeamMembers',
        'prioritySupport',
        'multipleCompanies',
        'onboarding',
        'advancedReports',
        'aiPremium'
    ]
};

interface Props {
    plan: TPricingPlans;
    popular?: boolean;
    originalPrice: number;
    newPrice?: number;
    priceAfterDiscount?: string | number;
    isMonthly: boolean;
    discountPercentage?: number;
    selectedTooltipId: string;
    setSelectedTooltipId: (selectedTooltipId: string) => void;
}

const PricingCard = ({
    plan,
    popular = false,
    originalPrice,
    newPrice,
    priceAfterDiscount,
    isMonthly,
    discountPercentage,
    selectedTooltipId,
    setSelectedTooltipId
}: Props) => {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();
    const payPeriod = isMonthly ? 'payPeriodMonthly' : 'payPeriodAnnually';

    return (
        <Card
            customClass={`pricing-card ${
                popular ? 'pricing-card--popular' : ''
            }`}
            testId={`pricing-card-${plan}`}
        >
            {popular && (
                <HBText
                    customClass="pricing-card__ribbon"
                    tag="p"
                    type="body-s"
                >
                    {t('pricingPopular')}
                </HBText>
            )}
            <HBText customClass="pricing-card__title" tag="h3" type="body-xl">
                {t(`pricingPlans.${plan}.title`)}
            </HBText>
            <div className="pricing-card__price-container">
                <HBText
                    customClass="pricing-card__price"
                    tag="p"
                    type="title-l"
                >
                    ${t(`${priceAfterDiscount ?? originalPrice}`)}
                </HBText>
                <div className="pricing-card__price-info">
                    <div className="pricing-card__discount-info">
                        {originalPrice !== priceAfterDiscount && (
                            <HBText
                                customClass="pricing-card__original-price"
                                tag="p"
                                type="body-xl"
                            >
                                ${t(`${originalPrice}`)}
                            </HBText>
                        )}
                        {!!discountPercentage && (
                            <HBText
                                customClass="pricing-card__discount"
                                tag="p"
                                type="body-xs"
                            >
                                {t(`pricingPage.card.discount_label`, {
                                    discountPercentage: discountPercentage
                                })}
                            </HBText>
                        )}
                    </div>

                    <HBText
                        customClass="pricing-card__pay-period pricing-card__pay-period--row"
                        tag="p"
                        type="body-xs"
                    >
                        {t(`${promoCode}.pricingBlock.${payPeriod}`)}
                    </HBText>
                </div>
            </div>
            <HBText
                customClass="pricing-card__pay-period pricing-card__pay-period--column"
                tag="p"
                type="body-xs"
            >
                {t(`${promoCode}.pricingBlock.${payPeriod}`)}
            </HBText>
            {newPrice && (
                <HBText
                    customClass="pricing-card__price-change"
                    tag="p"
                    type="body-l"
                >
                    {t(`priceChange.changeInfo`)}
                    <span className="pricing-card__price-new">{` $${newPrice}`}</span>
                    <Tooltip
                        selectedTooltipId={selectedTooltipId}
                        setSelectedTooltipId={setSelectedTooltipId}
                        tooltipId={plan}
                        tooltipText={t('priceChange.tooltip')}
                    >
                        <InfoCircleIcon />
                    </Tooltip>
                </HBText>
            )}

            <HBText
                customClass="pricing-card__description"
                tag="p"
                type="body-l"
            >
                {t(`pricingPlans.${plan}.description`)}
            </HBText>

            <div className="pricing-card__benefit-list">
                <HBText
                    customClass="pricing-card__included"
                    tag="p"
                    type="body-s"
                >
                    {t(`pricingPlans.${plan}.included`)}
                </HBText>

                {BENEFITS[plan].map(transKey => (
                    <Tooltip
                        key={transKey}
                        selectedTooltipId={selectedTooltipId}
                        setSelectedTooltipId={setSelectedTooltipId}
                        tooltipId={transKey}
                        tooltipText={t(
                            `pricingPlans.${plan}.benefits.${transKey}.tooltip`
                        )}
                    >
                        <div className="pricing-card__benefit-list-item">
                            <HBIcon name={'nx-check-16'} />
                            <HBText
                                customClass={`pricing-card__benefit-list-item__text pricing-card__benefit-list-item--${transKey}`}
                                tag="p"
                                type="body-s"
                            >
                                {t(
                                    `pricingPlans.${plan}.benefits.${transKey}.text`
                                )}
                            </HBText>
                        </div>
                    </Tooltip>
                ))}
            </div>

            <div className="pricing-card__cta-container">
                <RegistrationForm
                    customClass="pricing-card__cta"
                    source={`pricing-card primary-cta ${plan}`}
                    buttonText={t(`pricingCtaText`)}
                    size="large"
                    buttonStyle={popular ? 'primary' : 'plain-primary'}
                />
                <RegistrationForm
                    customClass="pricing-card__cta"
                    source={`pricing-card secondary-cta ${plan}`}
                    buttonText={t(`buyNow`)}
                    dataForIntakeFormTest={{
                        buyNowData: {
                            source: plan,
                            period: isMonthly ? 'monthly' : 'annual'
                        }
                    }}
                    size="large"
                    buttonStyle="plain"
                    testId={'buy-now--button'}
                />
            </div>
        </Card>
    );
};

export default PricingCard;
