import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import {
    FeatureBlockContent,
    FeatureBlockSection,
    FeatureBlockText
} from '../../FeatureBlockElements';

import InvoiceGeneratorImportanceStore from './InvoiceGeneratorImportanceStore';

import './invoice-generator-importance.scss';

const InvoiceGeneratorImportance = ({
    i18n,
    customClass = '',
    contentKey,
    textBlocks,
    transKey,
    image,
    lightBackground,
    darkBackground,
    children
}) => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection
            customClass={`invoice-generator-importance ${customClass}`}
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent>
                <FeatureBlockText fullWidth primary={t(`${transKey}.title`)} />
                <div className="invoice-generator-importance__container">
                    <div className="invoice-generator-importance__column invoice-generator-importance__column--text">
                        {textBlocks.map(block => {
                            return (
                                <div
                                    key={block}
                                    className="invoice-generator-importance__text-block"
                                >
                                    <h3 className="invoice-generator-importance__title">
                                        {t(`${transKey}.${block}.title`)}
                                    </h3>
                                    <p className="invoice-generator-importance__body">
                                        {t(`${transKey}.${block}.body`)}
                                    </p>
                                </div>
                            );
                        })}
                        {children}
                    </div>
                    <div className="invoice-generator-importance__column invoice-generator-importance__column--image">
                        <HBMediaElement
                            name={
                                image ||
                                InvoiceGeneratorImportanceStore[contentKey]
                                    .imagePath
                            }
                            alt={
                                InvoiceGeneratorImportanceStore[contentKey]
                                    .imageAlt || t(`${transKey}.title`)
                            }
                            crop="scale"
                            width={800}
                        />
                    </div>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

InvoiceGeneratorImportance.propTypes = {
    customClass: PropTypes.string,
    i18n: PropTypes.func,
    contentKey: PropTypes.string,
    textBlocks: PropTypes.arrayOf(PropTypes.string),
    transKey: PropTypes.string,
    image: PropTypes.string,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool,
    children: PropTypes.node
};

export default InvoiceGeneratorImportance;
