import { memo, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import './collapse.scss';

import type { ReactNode } from 'react';

interface Props {
    /** A space-delimited list of class names to pass along to a child element. */
    className?: string;
    children: ReactNode;
    /**
     * Whether the component is open or closed.
     *
     * @default false
     */
    isOpen?: boolean;
    /**
     * Whether the child components will remain mounted when the `Collapse` is closed.
     * Setting to true may improve performance by avoiding re-mounting children.
     *
     * @default false
     */
    keepChildrenMounted?: boolean;

    /**
     * The length of time the transition takes, in milliseconds. This must match
     * the duration of the animation in CSS. Only set this prop if you override
     * Blueprint's default transitions with new transitions of a different
     * length.
     *
     * @default 200
     */
    transitionDuration?: number;
}

/**
 * `Collapse` can be in one of six states, enumerated here.
 * When changing the `isOpen` prop, the following happens to the states:
 * isOpen={true}  : CLOSED -> OPEN_START -> OPENING -> OPEN
 * isOpen={false} : OPEN -> CLOSING_START -> CLOSING -> CLOSED
 */
const animationStates = {
    /**
     * The body is re-rendered, height is set to the measured body height and
     * the body Y is set to 0.
     */
    OPEN_START: 'OPEN_START',

    /**
     * Animation begins, height is set to auto. This is all animated, and on
     * complete, the state changes to OPEN.
     */
    OPENING: 'OPENING',

    /**
     * The collapse height is set to auto, and the body Y is set to 0 (so the
     * element can be seen as normal).
     */
    OPEN: 'OPEN',

    /**
     * Height has been changed from auto to the measured height of the body to
     * prepare for the closing animation in CLOSING.
     */
    CLOSING_START: 'CLOSING_START',

    /**
     * Height is set to 0 and the body Y is at -height. Both of these properties
     * are transformed, and then after the animation is complete, the state
     * changes to CLOSED.
     */
    CLOSING: 'CLOSING',

    /**
     * The contents of the collapse is not rendered, the collapse height is 0,
     * and the body Y is at -height (so that the bottom of the body is at Y=0).
     */
    CLOSED: 'CLOSED'
} as const;

function Collapse({
    className = '',
    isOpen = false,
    keepChildrenMounted = false,
    transitionDuration = 200,
    children
}: Props): JSX.Element {
    const [animationState, setAnimationState] = useState<
        keyof typeof animationStates
    >(isOpen ? animationStates.OPEN : animationStates.CLOSED);
    const [height, setHeight] = useState<string>(isOpen ? 'auto' : '0px');
    const [heightWhenOpen, setHeightWhenOpen] = useState<number | undefined>();

    let timeoutIds: number[] = [];

    const invokeTimeout = (callback: () => void, timeout?: number) => {
        const handle = window.setTimeout(callback, timeout);

        timeoutIds.push(handle);

        return () => {
            window.clearTimeout(handle);
        };
    };

    useEffect(() => {
        return () => {
            if (timeoutIds.length > 0) {
                for (const timeoutId of timeoutIds) {
                    window.clearTimeout(timeoutId);
                }

                timeoutIds = [];
            }
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            switch (animationState) {
                // no-op
                case animationStates.OPEN:
                // allow Collapse#onDelayedStateChange() to handle the transition here
                case animationStates.OPENING: {
                    break;
                }
                default: {
                    setAnimationState(animationStates.OPEN_START);
                }
            }
        } else {
            switch (animationState) {
                // no-op
                case animationStates.CLOSED:
                // allow Collapse#onDelayedStateChange() to handle the transition here
                case animationStates.CLOSING: {
                    break;
                }
                default: {
                    // need to set an explicit height so that transition can work
                    setAnimationState(animationStates.CLOSING_START);
                    setHeight(`${heightWhenOpen}px`);
                }
            }
        }
    }, [isOpen]);

    const isContentVisible = animationState !== animationStates.CLOSED;
    const shouldRenderChildren = isContentVisible || keepChildrenMounted;
    const displayWithTransform =
        isContentVisible && animationState !== animationStates.CLOSING;
    const isAutoHeight = height === 'auto';

    const containerClassName = classnames(`bp3-collapse`, className);

    const containerStyle = useMemo(() => {
        return {
            height: isContentVisible ? height : undefined,
            overflowY: isAutoHeight ? 'visible' : undefined,
            // transitions don't work with height: auto
            transition: isAutoHeight ? 'none' : undefined
        } as const;
    }, [height]);

    const contentsStyle = useMemo(() => {
        return {
            // only use heightWhenOpen while closing
            transform: displayWithTransform
                ? 'translateY(0)'
                : `translateY(-${heightWhenOpen}px) `,
            // transitions don't work with height: auto
            transition: isAutoHeight ? 'none' : undefined
        };
    }, []);

    let contents: HTMLDivElement | null = null;

    const contentsRefHandler = (el: HTMLDivElement | null) => {
        contents = el;

        if (contents !== null) {
            const h = contents.clientHeight;

            setAnimationState(
                isOpen ? animationStates.OPEN : animationStates.CLOSED
            );
            setHeight(h === 0 ? 'auto' : `${h}px`);
            setHeightWhenOpen(h === 0 ? undefined : h);
        }
    };

    const onDelayedStateChange = () => {
        switch (animationState) {
            case animationStates.OPENING: {
                setAnimationState(animationStates.OPEN);
                setHeight('auto');

                break;
            }
            case animationStates.CLOSING: {
                setAnimationState(animationStates.CLOSED);

                break;
            }
            default: {
                break;
            }
        }
    };

    useEffect(() => {
        if (contents == null) {
            return;
        }

        if (contents !== null) {
            if (animationState === animationStates.OPEN_START) {
                const { clientHeight } = contents;

                setAnimationState(animationStates.OPENING);
                setHeight(`${clientHeight}px`);
                setHeightWhenOpen(clientHeight);

                invokeTimeout(() => {
                    onDelayedStateChange();
                }, transitionDuration);
            } else if (animationState === animationStates.CLOSING_START) {
                const { clientHeight } = contents;

                invokeTimeout(() => {
                    setAnimationState(animationStates.CLOSING);
                    setHeight('0px');
                    setHeightWhenOpen(clientHeight);
                });

                invokeTimeout(() => {
                    onDelayedStateChange();
                }, transitionDuration);
            }
        }
    }, [animationState]);

    return (
        <div className={containerClassName} style={containerStyle}>
            <div
                className={`bp3-collapse-body`}
                ref={contentsRefHandler}
                style={contentsStyle}
                aria-hidden={!isContentVisible && keepChildrenMounted}
            >
                {shouldRenderChildren ? children : null}
            </div>
        </div>
    );
}

export default memo(Collapse);
