import { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import {
    FeatureBlockContent,
    FeatureBlockSection,
    FeatureBlockText
} from '../../FeatureBlockElements';

import type { ReactNode } from 'react';

import './picture-and-text-wrap-block.scss';

interface Props {
    image: string;
    children: ReactNode;
    imageAltTag: string;
    primary?: string;
    secondary?: string;
    imageWidth?: number;
    customClass?: string;
    lightBackground?: boolean;
    darkBackground?: boolean;
}

function PictureAndTextWrapBlock({
    image,
    imageAltTag,
    children,
    customClass = '',
    lightBackground,
    darkBackground,
    primary,
    secondary,
    imageWidth
}: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection
            customClass={`${customClass} picture-and-text-wrap-block`}
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent customClass="picture-and-text-wrap-block__feature-text-container">
                <HBMediaElement
                    crop="scale"
                    width={imageWidth}
                    name={image}
                    alt={t(imageAltTag)}
                />
                <FeatureBlockText primary={primary} secondary={secondary} />
                {children}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

PictureAndTextWrapBlock.propTypes = {
    primary: PropTypes.string,
    secondary: PropTypes.string,
    image: PropTypes.string,
    imageAltTag: PropTypes.string,
    imageWidth: PropTypes.number,
    customClass: PropTypes.string,
    children: PropTypes.node,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool
};

export default memo(PictureAndTextWrapBlock);
