import { memo } from 'react';
import * as PropTypes from 'prop-types';
// import { useSpring, animated } from 'react-spring';

import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

import './FeatureBlockTimeMgt.scss';

interface Props {
    title: string;
    assets: {
        featureImage: string;
        bubble: string;
        mobile: string;
    };
}

// const springOptions = { from: { val: 0 }, to: { val: 80 } };

function FeatureBlockTimeMgt({ title, assets }: Props): JSX.Element {
    // remove react spring as it was a huge bundle size for such a small feature!
    // const spring = useSpring(springOptions);

    return (
        <>
            <div className="feature-block__time-mgt-container">
                <p className="feature-block__time-mgt-text">{title}</p>

                <span className="feature-block__time-mgt-feature">
                    <HBMediaElement
                        name={assets.featureImage}
                        forceImgTag
                        type="Image"
                    />
                </span>

                <span className="feature-block__time-mgt-bubble">
                    <HBMediaElement
                        name={assets.bubble}
                        forceImgTag
                        type="Image"
                    />
                </span>

                <span className="feature-block__time-mgt-percent">%</span>
                {/* 
				<animated.div className="feature-block__time-mgt-value">{spring.val.interpolate((val) => Math.floor(val))}</animated.div> */}
                <div className="feature-block__time-mgt-value">80</div>

                <div className="feature-block__time-mgt-donut--large">
                    <svg
                        preserveAspectRatio="xMinYMin meet"
                        viewBox="0 0 300 300"
                    >
                        <ellipse
                            rx="145"
                            ry="145"
                            cx="150"
                            cy="150"
                            stroke="#f6f7f8"
                            fill="none"
                            strokeWidth="10"
                        />
                        <path
                            fill="none"
                            strokeWidth="10"
                            stroke="#000021"
                            d="M150,5 A145,145 0 0 1 287.90319486279725,105.19253581563262"
                        />
                    </svg>
                </div>

                <div className="feature-block__time-mgt-donut--small">
                    <svg
                        preserveAspectRatio="xMinYMin meet"
                        viewBox="0 0 200 200"
                    >
                        <ellipse
                            rx="77"
                            ry="77"
                            cx="80"
                            cy="80"
                            stroke="#f6f7f8"
                            fill="none"
                            strokeWidth="7"
                        />
                        <path
                            fill="none"
                            strokeWidth="7"
                            stroke="#597eff"
                            d="M80,3 A77,77 0 1 1 6.7686482452731696,56.20569143312906"
                        />
                    </svg>
                </div>
            </div>

            <div className="feature-block__time-mgt-container--mobile">
                <HBMediaElement forceImgTag name={assets.mobile} type="Image" />
            </div>
        </>
    );
}

FeatureBlockTimeMgt.propTypes = {
    title: PropTypes.string.isRequired,
    assets: PropTypes.objectOf(PropTypes.string).isRequired
};

export default memo(FeatureBlockTimeMgt);
