import { memo, useState } from 'react';
import * as PropTypes from 'prop-types';
import HBLoader from '../HBLoader/HBLoader';

import type { MouseEvent as ReactMouseEvent, MouseEventHandler } from 'react';
import type { SsoData, SsoError } from '../../../Services/RegistrationService';
import useLoadGoogleApi from './useLoadGoogleApi';

interface FacebookAnalyticsEvents {
    signup_with_facebook: string;
    already_signup_with_facebook: string;
    signup_with_facebook_error: string;
    signup_with_facebook_successful: string;
}

interface GoogleAnalyticsEvents {
    signup_with_google: string;
    signup_with_google_loading_popup: string;
    signup_with_google_user_approval: string;
    already_signup_with_google: string;
    signup_with_google_successful: string;
    signup_with_google_error: string;
    signup_with_google_user_closed_popup: string;
    signup_with_google_user_closed_email_chooser: string;
}

interface Arg {
    type: 'facebook' | 'google';
    successCallback: (ssoData: SsoData | SsoError) => void;
    trackAnalytics: (signup_with_facebook: string, options?: unknown) => void;
    analyticsEvents: FacebookAnalyticsEvents | GoogleAnalyticsEvents;
}

interface AuthResponse {
    accessToken: string;
}

interface SsoType {
    onClick: MouseEventHandler;
    buttonClassName: string;
    text: string;
    iconClass: string;
    iconImage: string;
}

const onSuccessFacebookSSO = (
    authResponse: AuthResponse,
    successCallback: (ssoData: SsoData) => void
) => {
    const ssoData: SsoData = {
        fullName: '',
        is_sso: true,
        sso_type: 'facebook',
        auth_token: authResponse.accessToken,
        first_name: '',
        name: '',
        email: '',
        profile_img_url: '',
        auth_code: ''
    };

    type ResponseMe = {
        first_name: string;
        name: string;
        email: string;
        profile_img_url: string;
    } & {
        error: string;
    };

    if (typeof window !== 'undefined') {
        if (typeof window.FB !== 'undefined') {
            window.FB.api(
                '/me',
                { fields: 'name, email, first_name' },
                (responseMe: ResponseMe): void => {
                    if (!responseMe.error) {
                        // ssoData.first_name = responseMe.first_name
                        ssoData.name = responseMe.name;
                        ssoData.email = responseMe.email;

                        type ResponsePicture = {
                            data: {
                                url: string;
                            };
                            error?: unknown;
                        };

                        window.FB.api(
                            '/me/picture?width=400&redirect=false',
                            (responsePicture: ResponsePicture): void => {
                                if (!responsePicture.error) {
                                    ssoData.profile_img_url =
                                        responsePicture.data.url;

                                    successCallback(ssoData);
                                }
                            }
                        );
                    }
                }
            );
        }
    }
};

function useSSOButton({
    type,
    successCallback,
    trackAnalytics,
    analyticsEvents
}: Arg) {
    const [isLoading, setIsLoading] = useState(false);

    const ssoTypes: Record<'facebook' | 'google', SsoType> = {
        facebook: {
            onClick: (event: ReactMouseEvent<HTMLButtonElement>) => {
                event.preventDefault();

                setIsLoading(true);

                trackAnalytics(
                    (analyticsEvents as FacebookAnalyticsEvents)
                        .signup_with_facebook
                );

                interface GetLoginStatusResponse {
                    status: string;
                    authResponse: AuthResponse;
                }

                if (typeof window !== 'undefined') {
                    if (typeof window.FB !== 'undefined') {
                        window.FB.getLoginStatus(
                            (response: GetLoginStatusResponse) => {
                                if (
                                    response &&
                                    response.status === 'connected'
                                ) {
                                    // "user already signup with facebook" (user is already logged in to FB)
                                    trackAnalytics(
                                        (
                                            analyticsEvents as FacebookAnalyticsEvents
                                        ).already_signup_with_facebook
                                    );

                                    onSuccessFacebookSSO(
                                        response.authResponse,
                                        successCallback
                                    );
                                }
                            }
                        );

                        window.FB.login(
                            loginResponse => {
                                if (loginResponse.authResponse) {
                                    // "signup with facebook finished successfully" (user logged in to FB successfully)
                                    trackAnalytics(
                                        (
                                            analyticsEvents as FacebookAnalyticsEvents
                                        ).signup_with_facebook_successful
                                    );

                                    onSuccessFacebookSSO(
                                        loginResponse.authResponse,
                                        successCallback
                                    );

                                    setIsLoading(false);
                                } else {
                                    // "error signing up with facebook"
                                    setIsLoading(false);
                                    trackAnalytics(
                                        (
                                            analyticsEvents as FacebookAnalyticsEvents
                                        ).signup_with_facebook_error
                                    );
                                }
                            },
                            { scope: 'email' }
                        );
                    } else {
                        // "error signing up with facebook"
                        setIsLoading(false);
                        trackAnalytics(
                            (analyticsEvents as FacebookAnalyticsEvents)
                                .signup_with_facebook_error,
                            {
                                error_type: 'FB undefined'
                            }
                        );

                        // TODO: Add this
                        // $('#fbUndefinedWarningModal').foundation('reveal', 'open');

                        successCallback({
                            errorObject: { hbStatusText: 'Facebook undefined' }
                        });
                    }
                }
            },
            buttonClassName:
                'registration-form__button-facebook js-optimizely-email-saved',
            text: 'Continue with Facebook',
            iconClass: 'fb-icon',
            iconImage:
                'https://s3.amazonaws.com/honeybook_cdn/assets_system/system_web/icons/facebook_f_icn.png'
        },
        google: {
            onClick: (event: ReactMouseEvent<HTMLButtonElement>) => {
                event.preventDefault();

                if (typeof window !== 'undefined') {
                    if (typeof window.gapi !== 'undefined') {
                        if (typeof window.gapi.auth2 !== 'undefined') {
                            setIsLoading(true);

                            trackAnalytics(
                                (analyticsEvents as GoogleAnalyticsEvents)
                                    .signup_with_google
                            );

                            const onSuccessGoogleSSO = authResponse => {
                                const ga = window.gapi.auth2.getAuthInstance();
                                trackAnalytics(
                                    (analyticsEvents as GoogleAnalyticsEvents)
                                        .signup_with_google_loading_popup
                                );

                                ga.signIn()
                                    .then(
                                        function (success) {
                                            const googleAuth =
                                                gapi.auth2.getAuthInstance();
                                            const googleUser =
                                                googleAuth.currentUser.get();
                                            const auth =
                                                googleUser.getAuthResponse();
                                            trackAnalytics(
                                                (
                                                    analyticsEvents as GoogleAnalyticsEvents
                                                )
                                                    .signup_with_google_user_approval
                                            );
                                            const ssoData = {
                                                is_sso: true,
                                                sso_type: 'google',
                                                auth_token: auth.id_token,
                                                auth_code: auth.access_token
                                            };
                                            if (
                                                googleAuth &&
                                                googleAuth.currentUser.get()
                                            ) {
                                                const googleUser =
                                                    googleAuth.currentUser.get();
                                                const userInfo =
                                                    googleUser.getBasicProfile();
                                                (
                                                    ssoData as SsoData
                                                ).first_name =
                                                    userInfo.getGivenName();
                                                (ssoData as SsoData).name =
                                                    userInfo.getName();
                                                (ssoData as SsoData).email =
                                                    userInfo.getEmail();
                                                (
                                                    ssoData as SsoData
                                                ).profile_img_url =
                                                    userInfo.getImageUrl();
                                                setTimeout(() => {
                                                    setIsLoading(false);
                                                }, 10000);
                                                trackAnalytics(
                                                    (
                                                        analyticsEvents as GoogleAnalyticsEvents
                                                    )
                                                        .signup_with_google_successful,
                                                    {
                                                        email: (
                                                            ssoData as SsoData
                                                        ).email
                                                    }
                                                );
                                                successCallback(
                                                    ssoData as SsoData
                                                );
                                            }
                                        },
                                        function (error) {
                                            setIsLoading(false);
                                            trackAnalytics(
                                                (
                                                    analyticsEvents as GoogleAnalyticsEvents
                                                )
                                                    .signup_with_google_user_closed_email_chooser,
                                                { error_type: error }
                                            );
                                        }
                                    )
                                    .catch((error: Error) => {
                                        setIsLoading(false);

                                        trackAnalytics(
                                            (
                                                analyticsEvents as GoogleAnalyticsEvents
                                            ).signup_with_google_error,
                                            {
                                                error_type: error
                                            }
                                        );
                                    });
                            };

                            const googleAuth = gapi.auth2.getAuthInstance();

                            if (googleAuth && googleAuth.isSignedIn.get()) {
                                const googleUser = googleAuth.currentUser.get();

                                trackAnalytics(
                                    (analyticsEvents as GoogleAnalyticsEvents)
                                        .already_signup_with_google
                                );
                                onSuccessGoogleSSO(
                                    googleUser.getAuthResponse()
                                );
                            } else {
                                onSuccessGoogleSSO(googleAuth);
                            }
                        }
                    } else {
                        setIsLoading(false);

                        trackAnalytics(
                            (analyticsEvents as GoogleAnalyticsEvents)
                                .signup_with_google_error,
                            {
                                error_type: 'Google undefined'
                            }
                        );
                        successCallback({
                            errorObject: {
                                hbStatusText: 'Google undefined'
                            }
                        });
                    }
                }
            },
            buttonClassName:
                'registration-form__button-google js-optimizely-email-saved',
            text: 'Continue with Google',
            iconClass: 'google-icon',
            iconImage:
                'https://res.cloudinary.com/honeybook/image/upload/v1475279642/google-sso_xgcjaq.png'
        }
    };

    const handleSubmit = (event: ReactMouseEvent<HTMLButtonElement>): void => {
        ssoTypes[type].onClick(event);
    };

    return {
        ssoTypes,
        isLoading,
        handleSubmit
    };
}

interface Props {
    type: 'facebook' | 'google';
    successCallback: (ssoData: SsoData | SsoError) => void;
    e2eTestLocator?: string;
    trackAnalytics: (eventName: string) => void;
    analyticsEvents: FacebookAnalyticsEvents | GoogleAnalyticsEvents;
}

function HBSSOButton({
    type,
    successCallback,
    e2eTestLocator,
    trackAnalytics,
    analyticsEvents
}: Props): JSX.Element {
    const { ssoTypes, isLoading, handleSubmit } = useSSOButton({
        type,
        successCallback,
        trackAnalytics,
        analyticsEvents
    });

    useLoadGoogleApi();

    return (
        <button
            disabled={isLoading}
            type="button"
            onClick={handleSubmit}
            className={ssoTypes[type].buttonClassName}
            e2e-test-locator={e2eTestLocator}
        >
            <span className="sso-button-text">{ssoTypes[type].text}</span>
            {isLoading && <HBLoader />}
        </button>
    );
}

HBSSOButton.propTypes = {
    type: PropTypes.string,
    successCallback: PropTypes.func,
    e2eTestLocator: PropTypes.string,
    trackAnalytics: PropTypes.func,
    analyticsEvents: PropTypes.objectOf(PropTypes.string)
};

export default memo(HBSSOButton);
