import { memo } from 'react';
import * as PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

import ReactHtmlParser from 'react-html-parser';

import './number-text-element.scss';

import type { ReactNode } from 'react';

interface Props {
    number?: number;
    title?: string;
    description?: string;
    descriptionTransKey?: string;
    customClass?: string;
    children?: ReactNode;
    handleClick?: () => void;
}

function NumberTextElement({
    number,
    title,
    description,
    descriptionTransKey,
    customClass,
    children,
    handleClick
}: Props): JSX.Element {
    const className = classnames('number-text-element', {
        [`${customClass}`]: Boolean(customClass),
        'number-text-element__title-layout': Boolean(title)
    });

    return (
        <div className={className} onClick={handleClick}>
            <div className="number-text-element__number-wrapper">
                {number && (
                    <p className="number-text-element__number">{number}</p>
                )}
                {title && (
                    <h3 className="number-text-element__title">{title}</h3>
                )}
            </div>

            {description && (
                <div className="number-text-element__description">
                    <Trans
                        i18nKey={descriptionTransKey}
                        defaults={description}
                        components={ReactHtmlParser(description)}
                    />
                </div>
            )}
            {children}
        </div>
    );
}

NumberTextElement.propTypes = {
    number: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    descriptionTransKey: PropTypes.string,
    customClass: PropTypes.string,
    children: PropTypes.node,
    handleClick: PropTypes.func
};

export default memo(NumberTextElement);
