import React from 'react';
import PropTypes from 'prop-types';

import './card-header.scss';

const CardHeader = ({ children }) => {
    return <div className="card__header">{children}</div>;
};

CardHeader.propTypes = {
    children: PropTypes.node.isRequired
};

export default CardHeader;
