import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import ImageTextElement from '../../ImageTextElement/ImageTextElement';
import ImageTextItemCarousel from '../../ImageTextItemCarousel/ImageTextItemCarousel';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import './benefits-block.scss';

const data = [
    {
        id: 'saveTime',
        imagePath: 'marketing_site/lp/proposal/conversation.png',
        customClass: 'save-time-bucket'
    },
    {
        id: 'organizationPower',
        imagePath: 'marketing_site/lp/proposal/pipeline.png',
        customClass: 'organization-power-bucket'
    },
    {
        id: 'showcaseBrand',
        imagePath: 'marketing_site/lp/proposal/brand-star.png',
        customClass: 'showcase-brand-bucket'
    }
];

const BenefitsBlock = (): JSX.Element => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    return (
        <FeatureBlockSection customClass="benefits-block" lightBackground>
            <FeatureBlockContent position="center">
                <div className="benefits-block__container">
                    <h2 className="benefits-block__title">
                        {t(`featureBlock.benefits.title`)}
                    </h2>
                    <div className="benefits-block__buckets">
                        {data.map(item => {
                            return (
                                <ImageTextElement
                                    key={item.id}
                                    imagePath={item.imagePath}
                                    title={t(
                                        `featureBlock.benefits.${item.id}.title`
                                    )}
                                    description={t(
                                        `featureBlock.benefits.${item.id}.description`
                                    )}
                                    customClass={item.customClass}
                                />
                            );
                        })}
                    </div>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default memo(BenefitsBlock);
