import { useContext } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import HBIcon from '../../honeybook-ui/HBIcon/HBIcon';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import { EmailSignatureFormContext } from '../EmailSignatureBlock';

import './email-signature-template-form.scss';

const TEMPLATE_NAMES = [
    'conservative',
    'modern',
    'classic',
    'cute',
    'crisp',
    'urban',
    'cool',
    'romantic'
];

function EmailSignatureTemplateForm() {
    const { mediumUp } = useBreakpoint();
    const templateNamesForDisplay = TEMPLATE_NAMES.filter(
        templateName =>
            mediumUp || (templateName !== 'crisp' && templateName !== 'urban')
    );

    return (
        <div className="email-signature-template-form">
            {templateNamesForDisplay.map(templateName => (
                <EmailTemplatePreview
                    key={templateName}
                    templateName={templateName}
                />
            ))}
        </div>
    );
}

function EmailTemplatePreview({ templateName }: { templateName: string }) {
    const { selectedTemplateName, setSelectedTemplateName } = useContext(
        EmailSignatureFormContext
    );
    const isSelected = templateName === selectedTemplateName;

    const { elementToScrollRef } = useContext(EmailSignatureFormContext);

    const onSelectTemplate = templateName => {
        AnalyticsService.track(
            AnalyticsEvents.lp_email_signature_change_to_template_card
        );
        setSelectedTemplateName(templateName);

        const previewTop =
            elementToScrollRef?.current?.getBoundingClientRect()?.top;
        if (previewTop) {
            window.scrollTo({
                top: window.scrollY + previewTop - 90,
                behavior: 'smooth'
            });
        }
    };

    const imgUrl = `marketing_site/email-signature/template-previews/${
        isSelected ? 'selected' : 'regular'
    }/${templateName}.svg`;

    const className = `
    signature-template-preview
    signature-template-preview--${templateName}
    signature-template-preview${isSelected ? '--selected' : ''}
    `;

    return (
        <div
            className={className}
            onClick={() => {
                onSelectTemplate(templateName);
            }}
        >
            <p className="signature-template-preview__template-name">
                {templateName}
            </p>
            <HBMediaElement
                customClass="signature-template-preview__template-img"
                name={imgUrl}
                lazyLoad={false}
            />
            <HBIcon
                customClass="signature-template-preview__check-icon"
                name="nx-check"
            />
        </div>
    );
}

export default EmailSignatureTemplateForm;
