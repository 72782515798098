import { PureComponent, Children } from 'react';
import classnames from 'classnames';

import * as Keys from './keys';

import Tab, { TabProps, TabId } from './tab';
import TabTitle, { generateTabPanelId, generateTabTitleId } from './tabTitle';

// TODO URI: implement tabs
import './tabs.scss';

import type {
    KeyboardEvent as ReactKeyboardEvent,
    MouseEvent as ReactMouseEvent,
    ReactNode
} from 'react';

export type KeyAllowList<T> = {
    include: Array<keyof T>;
};
export type KeyDenyList<T> = {
    exclude: Array<keyof T>;
};

export function Expander(): JSX.Element {
    return <div className={'bp3-flex-expander'} />;
}

/**
 * Returns true if the arrays are equal. Elements will be shallowly compared by
 * default, or they will be compared using the custom `compare` function if one
 * is provided.
 */
export function arraysEqual(
    arrA: unknown[],
    arrB: unknown[],
    compare = (a: unknown, b: unknown) => a === b
): boolean {
    // treat `null` and `undefined` as the same
    if (arrA == null && arrB == null) {
        return true;
    } else if (arrA == null || arrB == null || arrA.length !== arrB.length) {
        return false;
    } else {
        return arrA.every((a, i) => compare(a, arrB[i]));
    }
}

function isAllowList<T>(keys: any): keys is KeyAllowList<T> {
    return keys != null && (keys as KeyAllowList<T>).include != null;
}

function isDenyList<T>(keys: any): keys is KeyDenyList<T> {
    return keys != null && (keys as KeyDenyList<T>).exclude != null;
}

function arrayToObject(arr: any[]) {
    return arr.reduce((obj: any, element: any) => {
        obj[element] = true;

        return obj;
    }, {});
}

function filterKeys<T>(
    objA: T,
    objB: T,
    keys: KeyDenyList<T> | KeyAllowList<T>
) {
    if (isAllowList(keys)) {
        return keys.include;
    } else if (isDenyList(keys)) {
        const keysA = Object.keys(objA);
        const keysB = Object.keys(objB);

        // merge keys from both objects into a big set for quick access
        const keySet = arrayToObject(keysA.concat(keysB));

        // delete denied keys from the key set
        keys.exclude.forEach(key => delete keySet[key]);

        // return the remaining keys as an array
        return Object.keys(keySet) as Array<keyof T>;
    }

    return [];
}

/**
 * Partial shallow comparison between objects using the given list of keys.
 */
function shallowCompareKeysImpl<T extends object>(
    objA: T,
    objB: T,
    keys: KeyDenyList<T> | KeyAllowList<T>
) {
    return filterKeys(objA, objB, keys).every(key => {
        return (
            objA.hasOwnProperty(key) === objB.hasOwnProperty(key) &&
            objA[key] === objB[key]
        );
    });
}

/**
 * Shallow comparison between objects. If `keys` is provided, just that subset
 * of keys will be compared otherwise, all keys will be compared.
 *
 * @returns true if items are equal.
 */
export function shallowCompareKeys<T extends {}>(
    objA: T,
    objB: T,
    keys?: KeyDenyList<T> | KeyAllowList<T>
) {
    // treat `null` and `undefined` as the same
    if (objA == null && objB == null) {
        return true;
    } else if (objA == null || objB == null) {
        return false;
    } else if (Array.isArray(objA) || Array.isArray(objB)) {
        return false;
    } else if (keys != null) {
        return shallowCompareKeysImpl(objA, objB, keys);
    } else {
        // shallowly compare all keys from both objects
        const keysA = Object.keys(objA) as Array<keyof T>;
        const keysB = Object.keys(objB) as Array<keyof T>;
        return (
            shallowCompareKeysImpl(objA, objB, { include: keysA }) &&
            shallowCompareKeysImpl(objA, objB, { include: keysB })
        );
    }
}

export function isElementOfType<P = {}>(
    element: any,
    ComponentType: React.ComponentType<P>
): element is React.ReactElement<P> {
    return (
        element != null &&
        element.type != null &&
        element.type.type.name != null &&
        element.type.type.name === 'Tab'
    );
}

type TabElement = React.ReactElement<TabProps & { children: React.ReactNode }>;

const TAB_SELECTOR = `.bp3-tab`;

export interface Props {
    className: string;
    /**
     * Whether the selected tab indicator should animate its movement.
     *
     * @default true
     */
    animate?: boolean;

    /**
     * Unique identifier for this `Tabs` container. This will be combined with the `id` of each
     * `Tab` child to generate ARIA accessibility attributes. IDs are required and should be
     * unique on the page to support server-side rendering.
     */
    id: TabId;

    /**
     * Selected tab `id`, for controlled usage.
     * Providing this prop will put the component in controlled mode.
     * Unknown ids will result in empty selection (no errors).
     */
    selectedTabId?: TabId;

    /**
     * A callback function that is invoked when a tab in the tab list is clicked.
     */
    onChange?(
        newTabId: TabId,
        prevTabId: TabId | undefined,
        event: React.MouseEvent<HTMLElement>
    ): void;
    title: string;
}

export interface State {
    indicatorWrapperStyle?: React.CSSProperties;
    selectedTabId?: TabId;
}

export default class Tabs extends PureComponent<Props, State> {
    /** Insert a `Tabs.Expander` between any two children to right-align all subsequent children. */
    public static Expander = Expander;

    public static Tab = Tab;

    public static defaultProps: Partial<Props> = {
        animate: true
    };

    public static getDerivedStateFromProps({ selectedTabId }: Props) {
        if (selectedTabId !== undefined) {
            // keep state in sync with controlled prop, so state is canonical source of truth
            return { selectedTabId };
        }

        return null;
    }

    private tablistElement: HTMLDivElement | null = null;

    private tablist = (tabElement: HTMLDivElement) =>
        (this.tablistElement = tabElement);

    constructor(props: Props) {
        super(props);

        const selectedTabId = this.getInitialSelectedTabId();

        this.state = { selectedTabId };
    }

    public render() {
        const classes = classnames('bp3-tabs', this.props.className);

        return (
            <div className={classes}>
                <div
                    className={'bp3-tab-list'}
                    onKeyDown={this.handleKeyDown}
                    onKeyPress={this.handleKeyPress}
                    ref={this.tablist}
                    role="tablist"
                >
                    <div
                        className={`bp3-tab-indicator-wrapper`}
                        style={this.state.indicatorWrapperStyle}
                    >
                        <div className={`bp3-tab-indicator`} />
                    </div>

                    {Children.map(this.props.children, this.renderTabTitle)}
                </div>

                {this.getTabChildren().map(this.renderTabPanel)}
            </div>
        );
    }

    public componentDidMount() {
        this.moveSelectionIndicator(false);
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.state.selectedTabId !== prevState.selectedTabId) {
            this.moveSelectionIndicator();
        } else if (prevState.selectedTabId != null) {
            // comparing React nodes is difficult to do with simple logic, so
            // shallowly compare just their props as a workaround.
            const didChildrenChange = !arraysEqual(
                this.getTabChildrenProps(prevProps),
                this.getTabChildrenProps(),
                shallowCompareKeys
            );

            if (didChildrenChange) {
                this.moveSelectionIndicator();
            }
        }
    }

    private getInitialSelectedTabId() {
        // NOTE: providing an unknown ID will hide the selection
        const { selectedTabId } = this.props;

        if (selectedTabId !== undefined) {
            return selectedTabId;
        } else {
            // select first tab in absence of user input
            const tabs = this.getTabChildren();
            return tabs.length === 0 ? undefined : tabs[0].props.id;
        }
    }

    private getKeyCodeDirection(e: ReactKeyboardEvent<HTMLElement>) {
        if (isEventKeyCode(e, Keys.ARROW_LEFT, Keys.ARROW_UP)) {
            return -1;
        } else if (isEventKeyCode(e, Keys.ARROW_RIGHT, Keys.ARROW_DOWN)) {
            return 1;
        }
        return undefined;
    }

    private getTabChildrenProps(
        props: Props & { children?: React.ReactNode } = this.props
    ) {
        return this.getTabChildren(props).map(child => child.props);
    }

    /** Filters children to only `<Tab>`s */
    private getTabChildren(
        props: Props & { children?: React.ReactNode } = this.props
    ) {
        return Children.toArray(props.children).filter(isTabElement);
    }

    /** Queries root HTML element for all tabs with optional filter selector */
    private getTabElements(subSelector = ''): HTMLDivElement[] {
        if (this.tablistElement == null) {
            return [];
        }

        return Array.from(
            this.tablistElement.querySelectorAll<HTMLDivElement>(
                TAB_SELECTOR + subSelector
            )
        );
    }

    private handleKeyDown = (e: ReactKeyboardEvent<HTMLDivElement>) => {
        const focusedElement =
            document.activeElement?.closest<HTMLDivElement>(TAB_SELECTOR);
        // rest of this is potentially expensive and futile, so bail if no tab is focused
        if (typeof focusedElement === 'undefined' || focusedElement === null) {
            return;
        }

        // must rely on DOM state because we have no way of mapping `focusedElement` to a JSX.Element
        const enabledTabElements = this.getTabElements().filter(
            el => el.getAttribute('aria-disabled') === 'false'
        );
        const focusedIndex = enabledTabElements.indexOf(focusedElement);
        const direction = this.getKeyCodeDirection(e);

        if (focusedIndex >= 0 && direction !== undefined) {
            e.preventDefault();
            const { length } = enabledTabElements;
            // auto-wrapping at 0 and `length`
            const nextFocusedIndex =
                (focusedIndex + direction + length) % length;

            enabledTabElements[nextFocusedIndex].focus();
        }
    };

    private handleKeyPress = (event: ReactKeyboardEvent<HTMLDivElement>) => {
        const targetTabElement = event.currentTarget.closest(
            TAB_SELECTOR
        ) as HTMLElement;
        // HACKHACK: https://github.com/palantir/blueprint/issues/4165
        if (targetTabElement != null && Keys.isKeyboardClick(event.which)) {
            event.preventDefault();
            targetTabElement.click();
        }
    };

    private handleTabClick = (
        newTabId: TabId,
        event: ReactMouseEvent<HTMLElement>
    ) => {
        this.props.onChange?.(newTabId, this.state.selectedTabId, event);

        if (this.props.selectedTabId === undefined) {
            this.setState({ selectedTabId: newTabId });
        }
    };

    /**
     * Calculate the new height, width, and position of the tab indicator.
     * Store the CSS values so the transition animation can start.
     */
    private moveSelectionIndicator(animate = true) {
        if (this.tablistElement == null || !this.props.animate) {
            return;
        }

        const tabIdSelector = `${TAB_SELECTOR}[data-tab-id="${this.state.selectedTabId}"]`;
        const selectedTabElement = this.tablistElement.querySelector(
            tabIdSelector
        ) as HTMLElement;

        let indicatorWrapperStyle: React.CSSProperties = { display: 'none' };
        if (selectedTabElement != null) {
            const { clientWidth, offsetLeft } = selectedTabElement;
            indicatorWrapperStyle = {
                height: '100%',
                transform: `translateX(${Math.floor(offsetLeft)}px)`,
                width: clientWidth
            };

            if (!animate) {
                indicatorWrapperStyle.transition = 'none';
            }
        }
        this.setState({ indicatorWrapperStyle });
    }

    private renderTabPanel = (tab: TabElement) => {
        if (tab.props.panel === undefined) {
            return undefined;
        }

        return (
            <div
                aria-labelledby={generateTabTitleId(tab.props.id)}
                aria-hidden={tab.props.id !== this.state.selectedTabId}
                className={classnames('bp3-tab-panel', tab.props.className)}
                id={generateTabPanelId(tab.props.id)}
                key={tab.props.id}
                role="tabpanel"
            >
                {tab.props.panel}
            </div>
        );
    };

    private renderTabTitle = (child: ReactNode) => {
        if (isTabElement(child)) {
            return (
                <TabTitle
                    {...child.props}
                    onClick={this.handleTabClick}
                    selected={child.props.id === this.state.selectedTabId}
                />
            );
        }

        return child;
    };
}

function isEventKeyCode(
    event: ReactKeyboardEvent<HTMLElement>,
    ...codes: number[]
) {
    return codes.indexOf(event.which) >= 0;
}
//In production because of the build process the name of
//the properties change so this function always return false
function isTabElement(child: any): child is TabElement {
    return true;
}
