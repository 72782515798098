const getApiUrl = () => {
  if (process.env.GATSBY_baseApiPrefix === '/'){
      return window.location.origin.replace('.web.', '.api.')
  }
  else{
      return process.env.GATSBY_baseApiPrefix
  }
} 


export default getApiUrl