import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './discover-free-tools-block.scss';

export interface TFreeToolsData {
    imgUrl: string;
    label: string;
    url?: string;
}

interface Props {
    title: string;
    customClass?: string;
    freeToolsData: TFreeToolsData[];
}
function DiscoverFreeToolsBlock({ title, customClass, freeToolsData }: Props) {
    const { t } = useTranslation();

    const handleClick = url => {
        if (!url) return;
        window.location.href = url;
    };
    return (
        <FeatureBlockSection customClass={`discover-free-tools ${customClass}`}>
            <FeatureBlockContent customClass="discover-free-tools__content">
                <HBText
                    customClass="discover-free-tools__title"
                    tag="h2"
                    type="title-m"
                >
                    {title}
                </HBText>

                <div className="discover-free-tools__tool-list">
                    {freeToolsData.map(data => {
                        return (
                            <div
                                key={data.label}
                                className="discover-free-tools__tool-preview"
                                onClick={() => {
                                    handleClick(data.url);
                                }}
                            >
                                <HBMediaElement
                                    customClass="discover-free-tools__tool-preview__img"
                                    name={data.imgUrl}
                                />
                                <p className="discover-free-tools__tool-preview__label">
                                    {data.label}
                                </p>
                            </div>
                        );
                    })}
                </div>

                <RegistrationForm
                    size="large"
                    source="email-signature"
                    showNoCcRequired={true}
                    buttonText={t('discoverFreeToolsBlock.btnText')}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default DiscoverFreeToolsBlock;
