import { ReactNode, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import './hb-video.scss';
import type { TCssUnits } from '../../../types/util-types';

const VIMEO_APP_ID = '58479';

interface HBVideoProps {
    videoId: string;
    title?: string;
    badge?: boolean;
    autopause?: boolean;
    autoplay?: boolean;
    playerId?: boolean;
    muted?: boolean;
    controls?: boolean;
    loop?: boolean;
    appId?: string;
    width?: number | string;
    height?: number;
    customClass?: string;
    iframeClass?: string;
    poster?: string;
    position?: 'center' | 'left' | 'right';
    ratio?: number;
    children?: ReactNode;
    shouldLoadScript?: boolean;
    padding?: number | string;
    shouldCursorPointer?: boolean;
}

function booleanToString(bool: boolean) {
    return bool ? '1' : '0';
}

const getWithUnits = (
    value: string | number | undefined,
    defaultValue?: string | number | undefined,
    unit: TCssUnits = 'px'
) => {
    if (!!value) {
        return typeof value === 'string' ? value : `${value}${unit}`;
    }
    return defaultValue;
};

let isVimeoLoaded = false;
function HBVideo({
    videoId,
    title = '',
    badge = false,
    autopause = false,
    autoplay = true,
    playerId = false,
    muted = true,
    controls = false,
    appId = '58479',
    loop = false,
    customClass,
    iframeClass,
    poster,
    shouldLoadScript = true,
    width,
    height,
    position = 'left',
    padding,
    ratio = 1,
    children,
    shouldCursorPointer = false
}: HBVideoProps) {
    const [shouldRenderIframe, setShouldRenderIframe] = useState(false);
    const videoContainerRef = useRef<HTMLDivElement>();

    const className = classnames(`hbui-video`, customClass);

    useEffect(() => {
        const scrollCallback = () => {
            if (!isVimeoLoaded && shouldLoadScript) {
                isVimeoLoaded = true;
                const script = document.createElement('script');
                script.src = 'https://player.vimeo.com/api/player.js';
                script.defer = true;
                videoContainerRef.current?.appendChild(script);
            }
            setShouldRenderIframe(true);
        };
        const observer = new IntersectionObserver(entries => {
            //if true the element is visible
            if (entries[0].intersectionRatio > 0) {
                scrollCallback();
            } else {
                window.addEventListener('scroll', scrollCallback, {
                    once: true
                });
            }
            observer.unobserve(videoContainerRef.current as Element);
        });
        observer.observe(videoContainerRef.current as Element);

        return () => window.removeEventListener('scroll', scrollCallback);
    }, []);

    const src = `https://player.vimeo.com/video/${videoId}?badge=0&title=0&autopause=0&autoplay=${booleanToString(
        autoplay
    )}&muted=${booleanToString(muted)}&loop=${booleanToString(
        loop
    )}&controls=${booleanToString(
        controls
    )}&player_id=0&app_id=${VIMEO_APP_ID}`;

    const leftPosition =
        position === 'center' ? '50%' : position === 'right' ? 'auto' : '0';
    const rightPosition = position === 'right' ? '0' : 'auto';
    const transform = position === 'center' ? 'translateX(-50%)' : 'none';
    const isShorthandPadding =
        typeof padding === 'string' && padding.split(' ').length > 1;
    if (!isShorthandPadding) {
        padding = getWithUnits(padding);
    }
    const _padding = isShorthandPadding
        ? padding
        : padding
        ? `${padding} 0 0 0`
        : !!height
        ? `${height}px 0 0 0`
        : `${ratio * 100}% 0 0 0`;

    const iframeWidth = getWithUnits(width, '100%');

    return (
        <div
            style={{
                padding: _padding,
                ...(!!poster
                    ? {
                          backgroundColor: 'transparent',
                          backgroundImage: `url(${poster})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat'
                      }
                    : {})
            }}
            className={className}
            ref={videoContainerRef as any}
        >
            {shouldCursorPointer && <div className="overlay-video"></div>}
            {shouldRenderIframe && (
                <iframe
                    className={iframeClass}
                    src={src}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{
                        left: leftPosition,
                        right: rightPosition,
                        width: iframeWidth,
                        transform
                    }}
                    title={title}
                ></iframe>
            )}
            {children && children}
        </div>
    );
}

export default HBVideo;
