import { graphql } from 'gatsby';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import FeatureIntegrationBlock from '../components/FeatureBlocks/FeatureIntegrationBlock/FeatureIntegrationBlock';
import FeatureValuePropsBlock from '../components/FeatureBlocks/FeatureValuePropsBlock/FeatureValuePropsBlock';
import TestimonialCarouselCardBlock from '../components/FeatureBlocks/TestimonialCarouselCardBlock/TestimonialCarouselCardBlock';
import TestimonialCarouselBlock from '../components/FeatureBlocks/TestimonialCarouselBlock/TestimonialCarouselBlock';
import ShowcaseServicesBlock from '../components/FeatureBlocks/ShowcaseServicesBlock/ShowcaseServicesBlock';
import SmarterSystemBlock from '../components/FeatureBlocks/SmarterSystemBlock/SmarterSystemBlock';
import BestExperienceBlock from '../components/FeatureBlocks/BestExperienceBlock/BestExperienceBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import StatsAnimation from '../components/Hero/StatsAnimation/StatsAnimation';
import FeatureChecklistHero from '../components/Hero/FeatureChecklistHero/FeatureChecklistHero';
import { TAdditionalDataForClickStartTrialModules } from '../types/util-types';
import InsightsStore from '../stores/InsightsStore.js';

import '../styles/insights.scss';

const ogTags = {
    url: 'https://www.honeybook.com/getstarted-honeybook',
    title: 'Clientflow Management for Independent Businesses',
    description:
        'Book clients, manage projects, and get paid all in one place on HoneyBook. The clientflow management platform for independent businesses. Get started with a free 7-day trial.',
    noIndex: true
};

const { carouselSlideData, featureValues } = InsightsStore;

const InsightsPage = () => {
    const { t } = useTranslation();

    const stats = useMemo(
        () => [
            {
                title: <Trans>{t('homeStatsAnimation.earned.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.earned.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('homeStatsAnimation.invoices.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.invoices.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('homeStatsAnimation.members.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.members.subtitle')}</Trans>
                )
            }
        ],
        []
    );

    return (
        <Layout ogTags={ogTags}>
            <div className="home insights">
                <div className="feature-block__wrapper">
                    <FeatureChecklistHero
                        title={t('insights.hero.title')}
                        subtitle={t('insights.hero.subtitle')}
                        module={
                            TAdditionalDataForClickStartTrialModules.CHECK_LIST_HOME_PAGE
                        }
                        customButtonText={t('insights.hero.customButtonText')}
                    />
                    <StatsAnimation stats={stats} />
                    <FeatureValuePropsBlock
                        hideCta={true}
                        values={featureValues}
                        title={t('featureValueProps.title')}
                    />
                    <TestimonialCarouselBlock slideData={carouselSlideData} />
                    <ShowcaseServicesBlock videoUrl="marketing_site/homepage2/your-services" />
                    <BestExperienceBlock
                        subtitle={t('insights.bestExperience.subtitle')}
                        videoId="798848864"
                    />
                    <FeatureIntegrationBlock
                        title={t('integrationsBlock.title')}
                        subtitle={t('integrationsBlock.subtitle')}
                    />
                    <SmarterSystemBlock />
                    <TestimonialCarouselCardBlock />
                    <PromoBlockLeft
                        title={t('insights.promoBlockLeft.title')}
                        subtitle={t('insights.promoBlockLeft.subtitle')}
                        customClass="promo-block-left--yellow"
                        source="promo block left - yellow"
                        buttonStyle="primary--dark"
                        ctaText={t('insights.promoBlockLeft.ctaText')}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default InsightsPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: [
                        "home"
                        "common"
                        "templates"
                        "checklistHero"
                        "insights"
                        "faqs"
                    ]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
