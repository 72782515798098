import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HBButton, { ButtonStyles } from '@honeybook-ui/HBButton/HBButton';
import HBCheckbox from '@honeybook-ui/HBCheckbox/HBCheckbox';

import './template-download-button.scss';

const TemplateDownloadModalStepConfirmEmail = ({
    t,
    userEmail,
    onConfirmEmail,
    onChangeEmail,
    hasEmailPermission,
    onToggleEmailPermission
}) => (
    <>
        <h2 className="template-download-button__title">
            {t('templateDownloadButton.step2.title')}
        </h2>
        <p className="template-download-button__text">
            {t('templateDownloadButton.step2.subtitle')}
        </p>
        <p className="template-download-button__text">{userEmail}</p>

        <HBButton
            onClick={onConfirmEmail}
            text={t('templateDownloadButton.step2.ctaConfirm')}
        />

        <HBButton
            buttonStyle={ButtonStyles.INVISIBLE}
            onClick={onChangeEmail}
            customClass="template-download-button__modal-cancel"
            text={t('templateDownloadButton.step2.cancel')}
        />

        <HBCheckbox
            isChecked={hasEmailPermission}
            onChange={onToggleEmailPermission}
            label={t('templateDownloadButton.step2.emailPermission')}
        />
    </>
);

TemplateDownloadModalStepConfirmEmail.propTypes = {
    t: PropTypes.func,
    userEmail: PropTypes.string,
    onConfirmEmail: PropTypes.func,
    onChangeEmail: PropTypes.func,
    hasEmailPermission: PropTypes.bool,
    onToggleEmailPermission: PropTypes.func
};

export default withTranslation()(TemplateDownloadModalStepConfirmEmail);
