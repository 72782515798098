import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import HBButton from '../../honeybook-ui/HBButton/HBButton';
import HBText from '../../honeybook-ui/HBText/HBText';

import PencilIcon from '../../../svg/pencil.inline.svg';
import './mission-statement-editor.scss';

interface Colors {
    id: string;
    code: string;
}

interface Props {
    onClose: () => void;
    statement: string;
    setFontStyle: Function;
    setBackgroundColor: Function;
    setMissionStatement: Function;
    selectedFont: string;
    selectedBackgroundColor: Colors;
    fontOptions: Array<string>;
    colorOptions: Colors[];
}

const MissionStatementEditor = ({
    onClose,
    statement,
    setFontStyle,
    setBackgroundColor,
    setMissionStatement,
    selectedFont,
    selectedBackgroundColor,
    fontOptions,
    colorOptions
}: Props) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

    const editMissionStatement = e => {
        setMissionStatement(e.target.value);
    };

    const handleSetFontStyle = e => {
        setFontStyle(e.target.value);
    };

    const handleSetBackgroundColor = color => {
        setBackgroundColor(color);
    };

    const closeColorPicker = e => {
        if (
            !e.target.classList.contains(
                'mission-statement-editor__color-picker-trigger'
            )
        ) {
            setIsColorPickerOpen(false);
        }
    };

    const openColorPicker = () => {
        setIsColorPickerOpen(true);
    };

    return (
        <div className="mission-statement-editor" onClick={closeColorPicker}>
            <textarea
                className={`mission-statement-editor__textarea mission-statement-editor__textarea--${selectedFont}`}
                onChange={editMissionStatement}
                value={statement}
            />
            <div className="mission-statement-editor__controls">
                <div className="mission-statement-editor__controls__font">
                    <HBText
                        customClass="mission-statement-editor__text"
                        tag="p"
                        type="body-m"
                    >
                        {t('missionStatement.editor.fontStyle')}:
                    </HBText>
                    <select
                        className="mission-statement-editor__select"
                        onChange={handleSetFontStyle}
                        value={selectedFont}
                    >
                        {fontOptions.map(font => (
                            <option key={font} value={font}>
                                {t(`missionStatement.editor.fonts.${font}`)}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mission-statement-editor__controls__background">
                    <HBText
                        customClass="mission-statement-editor__text"
                        tag="p"
                        type="body-m"
                    >
                        {t('missionStatement.editor.backgroundColor')}:
                    </HBText>
                    {breakpoints.mediumUp ? (
                        <>
                            <div
                                className={`mission-statement-editor__color-button hbui-button mission-statement-editor__color-button--${selectedBackgroundColor.id} mission-statement-editor__color-picker-trigger`}
                                onClick={openColorPicker}
                            >
                                {t(
                                    `missionStatement.editor.colors.${selectedBackgroundColor.id}`
                                )}
                                <PencilIcon />
                            </div>
                            {isColorPickerOpen && (
                                <div className="mission-statement-editor__color-picker mission-statement-editor__color-picker-trigger">
                                    {colorOptions.map(color => {
                                        const isSelected =
                                            color.id ===
                                            selectedBackgroundColor.id;
                                        return (
                                            <span
                                                key={color.id}
                                                onClick={() =>
                                                    handleSetBackgroundColor(
                                                        color
                                                    )
                                                }
                                                className={`mission-statement-editor__color mission-statement-editor__color-picker-trigger mission-statement-editor__color--${
                                                    color.id
                                                } ${
                                                    isSelected
                                                        ? 'mission-statement-editor__color--selected'
                                                        : ''
                                                }`}
                                            ></span>
                                        );
                                    })}
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="mission-statement-editor__color-select-container">
                            <select
                                className="mission-statement-editor__select"
                                onChange={e => {
                                    handleSetBackgroundColor(
                                        colorOptions[e.target.value]
                                    );
                                }}
                            >
                                {colorOptions.map((color, index) => {
                                    const isSelected =
                                        color.id === selectedBackgroundColor.id;
                                    return (
                                        <option
                                            key={color.id}
                                            value={index}
                                            selected={isSelected}
                                        >
                                            {t(
                                                `missionStatement.editor.colors.${color.id}`
                                            )}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}
                </div>
            </div>
            <HBButton
                customClass="mission-statement-editor__finish-button"
                buttonStyle="plain"
                onClick={onClose}
                text={t('missionStatement.editor.finish')}
            />
        </div>
    );
};

export default MissionStatementEditor;
