import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout/Layout';
import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';
import TestimonialsContainer from '../components/TestimonialsContainer/TestimonialsContainer';

import PictureAndListBlock from '../components/FeatureBlocks/PictureAndListBlock/PictureAndListBlock';
import FeatureGridBlock from '../components/FeatureBlocks/FeatureGridBlock/FeatureGridBlock';
import ThinkOfSwitchingBlock from '../components/FeatureBlocks/ThinkOfSwitchingBlock/ThinkOfSwitchingBlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import ImageTextElementsBlock from '../components/FeatureBlocks/ImageTextElementsBlock/ImageTextElementsBlock';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';
import '../styles/photographer.scss';
import VerticalTemplatesIntro from '../components/FeatureBlocks/VerticalTemplatesIntro/VerticalTemplatesIntro';
import VerticalTemplatesBlock from '../components/FeatureBlocks/VerticalTemplatesBlock/VerticalTemplatesBlock';
import VerticalEmailTemplatesBlock from '../components/FeatureBlocks/VerticalEmailTemplatesBlock/VerticalEmailTemplatesBlock';
import { getCustomFeatureGridData } from '../utils/custom-feature-grid-data';

const imageTextElements = [
    {
        title: 'PhotographerPage.imageTextElements.Streamline.title',
        text: 'PhotographerPage.imageTextElements.Streamline.body',
        imagePath: 'marketing_site/vertical-lps/streamlined_icon.svg',
        altText: 'PhotographerPage.imageTextElements.Streamline.title',
        mediaFlags: { forceImgTag: true, width: 50 }
    },
    {
        title: 'PhotographerPage.imageTextElements.brand.title',
        text: 'PhotographerPage.imageTextElements.brand.body',
        imagePath: 'marketing_site/vertical-lps/showcase_icon.svg',
        altText: 'PhotographerPage.imageTextElements.brand.title',
        mediaFlags: { forceImgTag: true, width: 50 }
    },
    {
        title: 'PhotographerPage.imageTextElements.booking.title',
        text: 'PhotographerPage.imageTextElements.booking.body',
        imagePath: 'marketing_site/vertical-lps/booked_icon.svg',
        altText: 'PhotographerPage.imageTextElements.booking.title',
        mediaFlags: { forceImgTag: true, width: 50 }
    }
];

const quotes = [
    {
        image: 'marketing_site/photographers/Testimonials/Tony-Wodarck-Portrait.jpg',
        text: 'PhotographerPage.quotes.tony.text',
        name: 'Tony Wodarck',
        description: 'PhotographerPage.quotes.tony.description'
    },
    {
        image: 'marketing_site/photographers/Testimonials/john_branch.jpg',
        text: 'PhotographerPage.quotes.john.text',
        name: 'John Branch',
        description: 'PhotographerPage.quotes.john.description'
    },
    {
        image: 'marketing_site/photographers/Testimonials/Screen_Shot_2020-03-02_at_12.07.44_PM.png',
        text: 'PhotographerPage.quotes.allyson.text',
        name: 'Allyson Broecker',
        description: 'PhotographerPage.quotes.allyson.description'
    }
];

const faqs = [
    {
        id: 'photographerOther',
        answer: '#TEXT# <a href="https://www.youtube.com/watch?v=k3YJT3h_9Zo" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/watch?v=zK8mb49vx6w" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/watch?v=CueEFDuWWGQ" target="_blank">#LINK_TEXT#</a>#TEXT#'
    },
    {
        id: 'photographerContract'
    },
    {
        id: 'busy',
        answer: '#TEXT# <a href="/free-account-migration" target="_blank">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'photographerConsultations',
        answer: '<a href="https://honeybook.com/meeting-scheduler" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/3613950-scheduling-in-honeybook" target="_blank">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'integration',
        answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/2209205-integrating-with-zapier" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2209135-syncing-invoices-from-honeybook-to-quickbooks" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/3335988-capturing-honeybook-leads-with-facebook-lead-ads" target="_blank">#TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2308785-syncing-with-gmail" target="_blank"></a> #TEXT#'
    },
    {
        id: 'photographerClientPortal',
        answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/2814167-your-clients-honeybook-experience" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2559878-video-tutorial-a-client-side-view-of-using-honeybook" target="_blank">#LINK_TEXT#M</a> #TEXT#'
    },
    {
        id: 'tutorials',
        answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/product-education" target="_blank">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'photographerHBCost',
        answer: '#TEXT# <a href="/pricing" target="_blank">#LINK_TEXT#</a> #TEXT#'
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/crm-for-photographers',
    title: 'Photography CRM Software - Fully Customizable',
    description:
        'Manage and grow your photography business with HoneyBook’s photography CRM software. Completely customizable to fit your needs.'
};

const contentKey = 'PhotographerPage';

const templateBlocks = [
    'invoiceTemplates',
    'contractTemplates',
    'brocureTemplates',
    'proposalTemplates',
    'questionnarieTemplates'
];
const templateFeatureImages = {
    invoiceTemplates: {
        name: 'marketing_site/vertical-lps/template-images/invoice_all.png',
        alt: 'Photography invoice templates through our Photography CRM Software'
    },
    brocureTemplates: {
        name: 'marketing_site/vertical-lps/template-images/brochure_all.png',
        alt: 'Photography contract templates through our Photography CRM Software'
    },
    proposalTemplates: {
        name: 'marketing_site/vertical-lps/template-images/proposal_all.png',
        alt: 'Photography brochure templates through our Photography CRM Software'
    },
    contractTemplates: {
        name: 'marketing_site/vertical-lps/template-images/vertical-groups/Group%203%20-%20Events/Group_56_Copy.png',
        alt: 'Photography proposal templates through our Photography CRM Software'
    },
    questionnarieTemplates: {
        name: 'marketing_site/vertical-lps/template-images/group-42_3x.png',
        alt: 'Online Questionnaire through our Photography CRM Software'
    }
};

function CrmForPhotographersTemplate() {
    const { t } = useTranslation();

    return (
        <div className={`photographer-lp vertical`}>
            <Layout ogTags={ogTags}>
                <WhiteHero
                    image="marketing_site/photographers/photo_hero.png"
                    imageAltTag={t('PhotographerPage.hero.imageAlt')}
                    mainTitle={t('PhotographerPage.hero.title')}
                    description={t('PhotographerPage.hero.subtitle')}
                    showRegistration
                    width={800}
                />

                <ImageTextElementsBlock
                    lightBackground
                    customClass="photographer-lp__image-text-block payments-block"
                    imageTextElements={imageTextElements}
                />

                <PictureAndListBlock
                    title="PhotographerPage.featureTitleList.trackLeads.title"
                    listName="PhotographerPage.featureTitleList.trackLeads.list"
                    image="marketing_site/photographers/1_photo_pipeline.png"
                    imageAltTag="PhotographerPage.featureTitleList.trackLeads.imageAlt"
                />

                <PictureAndListBlock
                    title="PhotographerPage.featureTitleList.communicateWithClients.title"
                    listName="PhotographerPage.featureTitleList.communicateWithClients.list"
                    image="marketing_site/photographers/2_photo_workspace.png"
                />

                <PictureAndListBlock
                    title="PhotographerPage.featureTitleList.showcase.title"
                    listName="PhotographerPage.featureTitleList.showcase.list"
                    image="marketing_site/photographers/3_photo_brand.png"
                />

                <ThinkOfSwitchingBlock
                    title="PhotographerPage.CTAStrip.title"
                    preTitle="PhotographerPage.CTAStrip.preTitle"
                />

                <FeatureGridBlock
                    title={t('PhotographerPage.featureGridTitle')}
                    customData={getCustomFeatureGridData('PhotographerPage')}
                    lightBackground
                    t={t}
                />

                <VerticalTemplatesIntro contentKey={contentKey} />

                {templateBlocks.map(templateBlock => (
                    <VerticalTemplatesBlock
                        key={'photographers-' + templateBlock}
                        contentKey={contentKey}
                        templateBlock={templateBlock}
                        templateFeatureImages={templateFeatureImages}
                    />
                ))}

                <VerticalEmailTemplatesBlock contentKey={contentKey} />

                <TestimonialsContainer
                    customClass="photographer-lp__testimonial"
                    quotes={quotes}
                />

                <FAQBlock
                    title={t('PhotographerPage.faqs.title')}
                    faqs={faqs}
                />

                <FreeToTryBlockRebrand />
            </Layout>
        </div>
    );
}

export default CrmForPhotographersTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "photographers", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
