import { memo } from 'react';
import * as PropTypes from 'prop-types';

import './hb-label.scss';

interface Props {
    label?: string;
    id?: string;
    htmlFor?: string;
    customClass?: string;
    isRequired?: boolean;
}

function HBLabel({
    id,
    htmlFor,
    customClass,
    label,
    isRequired
}: Props): JSX.Element | null {
    if (!label) {
        return null;
    }

    return (
        <label
            id={id}
            htmlFor={htmlFor}
            className={`hbui-label ${customClass || ''}`}
        >
            {`${label} ${isRequired ? '*' : ''}`}
        </label>
    );
}

HBLabel.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    htmlFor: PropTypes.string,
    customClass: PropTypes.string,
    isRequired: PropTypes.bool
};

export default memo(HBLabel);
