import { memo, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBButton from '../../honeybook-ui/HBButton/HBButton';

import Hero from '../../Hero/Hero/Hero';
import HeroStrip from '../../Hero/HeroStrip/HeroStrip';
import HeroColumn from '../../Hero/HeroColumn/HeroColumn';

import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './coaching-hero.scss';

import type { VideoItem } from '../../VideoCarousel/VideoCarousel';

export interface CustomData {
    id: string;
    link: string;
    icon: string;
}

export interface Bio {
    id: string;
    bioImgUrl: string;
    name: string;
    memberSince: string;
    websiteUrl: string;
    logoImgUrl: string;
    video: VideoItem;
    social: CustomData[];
    services: string[];
}

interface Props {
    bio: Bio;
    handleClick?: () => void;
}

function CoachingHero({ bio, handleClick }: Props): JSX.Element {
    const { t } = useTranslation();

    const handleClickLink = useCallback(() => {
        AnalyticsService.trackClick(AnalyticsEvents.watch_video_scroll, {
            source: 'coaching/photography',
            variant: bio.id
        });

        if (typeof handleClick !== 'undefined') {
            handleClick();
        }
    }, [bio.id, handleClick]);

    return (
        <Hero customClass="coaching-hero">
            <HeroStrip>
                <HeroColumn
                    columnWidth={1}
                    customClass="hero__column hero__column--text"
                >
                    <h1 className="hero__text-container__title">
                        {t(`coaching.photography.${bio.id}.introTitle`)}
                        <br />
                        {t(`coaching.photography.${bio.id}.jobTitle`)}
                    </h1>
                    <h2 className="hero__text-container__subtitle">
                        {t(`coaching.photography.${bio.id}.subtitle`)}
                    </h2>

                    <HBButton
                        customClass="hero__link"
                        onClick={handleClickLink}
                        text={t('coaching.photography.videoLink')}
                        icon="nx-arrow-down"
                    />
                </HeroColumn>

                <HeroColumn
                    customClass="hero__column hero__column--img"
                    columnWidth={1}
                >
                    <HBMediaElement
                        customClass="hero__img"
                        name={bio.bioImgUrl}
                        alt={bio.name}
                    />
                    <p className="hero__member-text">
                        {t('coaching.photography.memberText')} {bio.memberSince}
                    </p>
                </HeroColumn>
            </HeroStrip>
        </Hero>
    );
}

CoachingHero.propTypes = {
    bio: PropTypes.shape({
        id: PropTypes.string.isRequired,
        bioImgUrl: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        memberSince: PropTypes.string.isRequired,
        websiteUrl: PropTypes.string.isRequired,
        logoImgUrl: PropTypes.string.isRequired,
        video: PropTypes.string.isRequired
    }).isRequired,
    handleClick: PropTypes.func
};

export default memo(CoachingHero);
