import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockUserQuote
} from '../../FeatureBlockElements';

import './busyness-testimonial-block.scss';

const BusynessTestimonialBlock = ({ t }) => {
    return (
        <FeatureBlockSection customClass="busyness-testimonial-block">
            <FeatureBlockContent>
                <FeatureBlockUserQuote
                    quote={t('onlinePaymentsSoftware.userQuote.quote')}
                    name="Amanda Carter"
                    jobTitle={t('onlinePaymentsSoftware.userQuote.jobTitle')}
                    avatar="marketing_site/testimonial-avatars/image10.png"
                    avatarCrop="thumb"
                    avatarGravity="face"
                    avatarWidth={40}
                    avatarHeight={40}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

BusynessTestimonialBlock.propTypes = {
    t: PropTypes.func
};

export default withTranslation()(BusynessTestimonialBlock);
