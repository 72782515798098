const FeaturesProposalBlockStore = {
    blockData: {
        id: 'proposals',
        image: 'marketing_site/features-hub/Proposal_2x.png',
        slideInImages: {
            phone: 'marketing_site/features-hub/phone-signed.png',
            plant: 'marketing_site/features-hub/plant_2x.png',
            bell: 'marketing_site/features-hub/notification-icon_2x.png',
            toast: 'marketing_site/features-hub/notification_2x.png'
        },
        quoteMember: 'Kelly Hillis',
        quoteAvatar:
            'marketing_site/features-hub/testimonial_headshots/kelly_hills.jpg',
        link: '/proposal-software'
    }
};

export default FeaturesProposalBlockStore;
