import { useTranslation } from 'react-i18next';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';

import './accept-online-payments.scss';

const AcceptOnlinePaymentsBlock = () => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection
            lightBackground
            customClass="accept-online-payments"
        >
            <FeatureBlockContent>
                <FeatureBlockText
                    fullWidth
                    primary={t('featureBlock.acceptOnlinePayments.title')}
                    secondary={t('featureBlock.acceptOnlinePayments.subtitle')}
                />
                <div className="feature-block__signup-wrapper">
                    <RegistrationForm
                        source="hero"
                        customClass=""
                        showNoCcRequired
                    />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default AcceptOnlinePaymentsBlock;
