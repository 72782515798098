import RegistrationForm from '../RegistrationForm/RegistrationForm';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';
import { useTranslation } from 'react-i18next';

import './registration-form-as-text.scss';

interface Props {
    linkText?: string;
    source: string;
    templateId?: string;
    size?: 'small' | 'x-small' | 'medium' | 'large' | undefined;
    customClass?: string;
    color?: 'dark' | 'yellow';
}

const RegistrationFormAsText = ({
    linkText,
    source,
    templateId,
    size,
    customClass,
    color = 'yellow'
}: Props) => {
    const { t } = useTranslation();
    const buttonText = linkText || t('registrationForm.startFreeTrial');

    return (
        <div
            className={`registration-form-as-text registration-form-as-text--${size} registration-form-as-text--${color} ${customClass}`}
        >
            <RegistrationForm
                buttonText={buttonText}
                source={source}
                showNoCcRequired={false}
                templateId={templateId}
                size={size}
            />
            <HBIcon name="nx-arrow-right-16" />
        </div>
    );
};

export default RegistrationFormAsText;
