import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './invoice-generator-other-features-block.scss';

import InvoiceGeneratorOtherFeaturesBlockStore from './InvoiceGeneratorOtherFeaturesBlockStore';
import ImageTextElement from '../../ImageTextElement/ImageTextElement';

const { sections } = InvoiceGeneratorOtherFeaturesBlockStore;

const handleAnalytics = feature => {
    AnalyticsService.trackClick(AnalyticsEvents.other_features_block, {
        type: feature
    });
};

const InvoiceGeneratorOtherFeaturesBlock = ({
    t,
    contentKey,
    lightBackground,
    darkBackground
}) => {
    return (
        <FeatureBlockSection
            customClass="other-features-block"
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent>
                <FeatureBlockText
                    fullWidth
                    primary={t(
                        `invoiceGeneratorPages.${contentKey}.otherFeatures.title`
                    )}
                />
                <div className="other-features-block__container">
                    {sections.map(section => {
                        return (
                            <a
                                key={section.id}
                                onClick={() => handleAnalytics(section.id)}
                                className="other-features-block__link"
                                href={section.linkUrl}
                            >
                                <ImageTextElement
                                    title={t(
                                        `invoiceGeneratorPages.generic.otherFeatures.${section.id}.title`
                                    )}
                                    description={t(
                                        `invoiceGeneratorPages.${contentKey}.otherFeatures.${section.id}.body`
                                    )}
                                    imagePath={section.image}
                                    imagePosition="left"
                                    mediaFlags={{
                                        forceImgTag: true,
                                        alt: t(
                                            `invoiceGeneratorPages.${contentKey}.otherFeatures.${section.id}.title`
                                        )
                                    }}
                                />
                            </a>
                        );
                    })}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

InvoiceGeneratorOtherFeaturesBlock.propTypes = {
    t: PropTypes.func,
    contentKey: PropTypes.string,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool
};

export default withTranslation()(InvoiceGeneratorOtherFeaturesBlock);
