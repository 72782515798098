import * as PropTypes from 'prop-types';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import { InputStyles } from '@honeybook-ui/HBInput/HBInput';
import Hero from '../../Hero/Hero/Hero';
import HeroStrip from '../../Hero/HeroStrip/HeroStrip';
import HeroColumn from '../../Hero/HeroColumn/HeroColumn';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import { useTranslation } from 'react-i18next';

import './invites-hero.scss';

const InvitesHero = ({ title, TOSLink = null, isFallback }) => {
    const { t } = useTranslation();
    return (
        <Hero customClass="invites-hero">
            <HeroStrip>
                <HeroColumn
                    customClass="invites-hero__regform-container"
                    columnWidth={1.2}
                >
                    <div className="invites-hero__text-container">
                        <h1 className="invites-hero__title">{title}</h1>
                    </div>
                    <RegistrationForm
                        source="hero"
                        inputStyle={InputStyles.SIMPLE_INPUT}
                        direction="column"
                        buttonText={
                            isFallback
                                ? undefined
                                : t('invitesPage.hero.ctaText')
                        }
                    />
                    <p className="invites-hero__custom-no-cc-text">
                        {t('invitesPage.hero.customNoCcText')}
                    </p>
                    {TOSLink && (
                        <p className="invites-hero__link-text">
                            <a href={TOSLink}>
                                {t('invitesPage.hero.linkText')}
                            </a>
                        </p>
                    )}
                </HeroColumn>
                <HeroColumn
                    customClass="invites-hero__image-container"
                    columnWidth={2}
                >
                    <HBMediaElement
                        name="marketing_site/invites/invites-hero.png"
                        lazyLoad={false}
                    />
                </HeroColumn>
            </HeroStrip>
        </Hero>
    );
};

InvitesHero.propTypes = {
    title: PropTypes.string,
    TOSLink: PropTypes.string
};

export default InvitesHero;
