import PropTypes from 'prop-types';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';

import './educator-image-badge.scss';

const EducatorImageBadge = ({ name, jobTitle, image }) => (
    <div className="educator-image-badge">
        <div className="educator-image-badge__text-container">
            <p
                className="educator-image-badge__name educator-image-badge__fade"
                key={name}
            >
                {name}
            </p>
            <p
                className="educator-image-badge__job-title educator-image-badge__fade"
                key={jobTitle}
            >
                {jobTitle}
            </p>
        </div>
        <HBMediaElement
            customClass="educator-image-badge__badge educator-image-badge__image"
            name="marketing_site/educator/educators_badge-rebrand.png"
            width={117}
            alt="educator"
            forceImgTag
        />
        <HBMediaElement
            key={name}
            customClass="educator-image-badge__educator educator-image-badge__fade"
            name={image}
            crop="scale"
            width={550}
            alt={name}
        />
        <HBMediaElement
            customClass="educator-image-badge__arrow educator-image-badge__image"
            name="marketing_site/educator/arrow-curl-light.png"
            alt="arrow"
        />
        <HBMediaElement
            customClass="educator-image-badge__star educator-image-badge__image"
            name="marketing_site/educator/star.svg"
            alt="star"
        />
    </div>
);

EducatorImageBadge.propTypes = {
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    jobTitle: PropTypes.string
};

export default EducatorImageBadge;
