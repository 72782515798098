import { graphql } from 'gatsby';
import { useTranslation, Trans } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import UtilsService from '../Services/UtilsService';
import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';
import CTABlock from '../components/FeatureBlocks/CTABlock/CTABlock';

import '../styles/busyness-page.scss';

import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import BusynessFeatureBlocks from '../components/FeatureBlocks/BusynessFeatureBlocks/BusynessFeatureBlocks';
import BusynessVideoBlock from '../components/FeatureBlocks/BusynessVideoBlock/BusynessVideoBlock';
import BusynessTestimonialBlock from '../components/FeatureBlocks/BusynessTestimonialBlock/BusynessTestimonialBlock';

const faqs = [
    {
        id: 'busynessHelp',
        answer: '#TEXT# <a href="/features">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'busynessIncluded',
        answer: '#TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'busynessRedeem',
        answer: ''
    },
    {
        id: 'busynessFees',
        answer: '#TEXT# <a href="//help.honeybook.com/en/articles/2209033-getting-paid-through-honeybook#transaction-fees">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'busynessFirstYear',
        answer: ''
    },
    {
        id: 'busynessCancel',
        answer: ''
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/online-payment-software',
    title: 'Online Tools to Help Your Small Business Thrive',
    description:
        'Elevate your clients experience and get organized for the busy times ahead. Try HoneyBook free for 7 days.'
};

const handleTermsClick = () => {
    UtilsService.scrollToElementId('offer-details');
};

function BusynessTemplate(): JSX.Element {
    const { t } = useTranslation();

    return (
        <Layout ogTags={ogTags}>
            <div className="busyness-lp">
                <WhiteHero
                    image="marketing_site/busyness/hero"
                    isVideo
                    mainTitle={t('onlinePaymentsSoftware.hero.title')}
                    description={t('onlinePaymentsSoftware.hero.subtitle')}
                    showRegistration
                >
                    <p className="hero__text hero__text--discount">
                        <Trans i18nKey="onlinePaymentsSoftware.hero.discountText" />
                    </p>
                    <p className="hero__text hero__text--promo">
                        {t('onlinePaymentsSoftware.hero.promoCode')}
                    </p>
                    <button
                        onClick={handleTermsClick}
                        className="hero__terms-link"
                    >
                        {t('onlinePaymentsSoftware.hero.terms')}
                    </button>
                </WhiteHero>

                <BusynessTestimonialBlock />

                <BusynessFeatureBlocks />

                <BusynessVideoBlock />

                <CTABlock
                    customClass="busyness-lp__cta-block"
                    primary={t('onlinePaymentsSoftware.CTABlock.title')}
                    secondary={t('onlinePaymentsSoftware.CTABlock.subtitle')}
                    imageUrl="marketing_site/busyness/plant.svg"
                    mediaFlags={{ forceImgTag: true }}
                    buttonText={t('onlinePaymentsSoftware.CTABlock.buttonText')}
                    showNoCcRequired
                />

                <p id="offer-details" className="busyness-lp__offer-details">
                    {t('onlinePaymentsSoftware.terms')}
                </p>

                <FAQBlock
                    title={t('onlinePaymentsSoftware.faqTitle')}
                    faqs={faqs}
                />
            </div>
        </Layout>
    );
}

export default BusynessTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "busyness", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
