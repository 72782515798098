import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '@honeybook-ui/HBText/HBText';

import './money-back-guarantee.scss';

const MoneyBackGuarantee = ({ t }) => (
    <div className="money-back-guarantee">
        <HBMediaElement
            crop="scale"
            name="marketing_site/pricing/60-day-money-back-guarantee.png"
        />
        <div className="money-back-guarantee__text-container">
            <HBText
                customClass="money-back-guarantee__title"
                tag="p"
                type="body-xs"
            >
                {t('moneyBackGuarantee.title')}
            </HBText>
        </div>
    </div>
);

MoneyBackGuarantee.propTypes = {
    t: PropTypes.func.isRequired
};

export default withTranslation()(MoneyBackGuarantee);
