import { createRef, memo, useCallback, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HBIcon from '@honeybook-ui/HBIcon/HBIcon';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

import './shareable-social-icon.scss';

const shareLinkBase = {
    twitter:
        "https://twitter.com/share?text=I%20just%20discovered%20my%20business%20personality%20type.%20What's%20yours?&url=",
    facebook: 'https://www.facebook.com/sharer/sharer.php?u='
};

const iconClassNames = {
    twitter: 'nx-twitter',
    facebook: 'facebook'
};

interface Props {
    buttonStyle: string;
    type: string;
    customUrl?: string;
    customClass: string;
    analyticsProperties: Record<string, unknown>;
}

function ShareableSocialIcon({
    buttonStyle,
    type,
    customClass,
    customUrl,
    analyticsProperties
}: Props): JSX.Element {
    const { t } = useTranslation();

    const textArea = createRef<HTMLTextAreaElement>();
    const [copySuccess, setCopySuccess] = useState(false);

    const buttonStyleClass = `shareable-social-icon--${buttonStyle}`;

    const handleSocialShareClick = useCallback(() => {
        AnalyticsService.trackClick(AnalyticsEvents.biz_test_social_sharing, {
            source: type,
            ...analyticsProperties
        });

        const linkUrl = customUrl || window.location.href;

        const height = 450;
        const width = 650;
        const topPosition = window.screen.height / 2 - height / 2;
        const leftPosition = window.screen.width / 2 - width / 2;

        const queryStringArray = [
            'menubar=no',
            'toolbar=no',
            'resizable=yes',
            'scrollbars=yes',
            `width=${width}`,
            `height=${height}`,
            `top=${topPosition}`,
            `left=${leftPosition}`
        ];

        const queryString = queryStringArray.join(',');

        window.open(shareLinkBase[type] + linkUrl, 'Share', queryString);
    }, [type, customUrl, analyticsProperties]);

    const handleCopyClick = useCallback(() => {
        AnalyticsService.trackClick(AnalyticsEvents.biz_test_social_sharing, {
            source: 'link',
            ...analyticsProperties
        });

        textArea.current?.select();

        document.execCommand('copy');
        setCopySuccess(true);

        window.setTimeout(() => {
            setCopySuccess(false);
        }, 1000);
    }, []);

    const textAreaValue = useMemo(() => {
        if (typeof window !== 'undefined') {
            return customUrl || window.location.href;
        } else {
            return customUrl || '';
        }
    }, [customUrl]);

    return (
        <>
            {type && (
                <button
                    className={`shareable-social-icon shareable-social-icon--${type} ${buttonStyleClass} ${customClass}`}
                    onClick={handleSocialShareClick}
                >
                    <HBIcon name={iconClassNames[type]} />
                </button>
            )}

            {!type && (
                <>
                    <button
                        className={`shareable-social-icon shareable-social-icon--link ${buttonStyleClass} ${customClass}`}
                        onClick={handleCopyClick}
                    >
                        <HBIcon name="link" />
                        <span
                            className={`shareable-social-icon__feedback-text ${
                                copySuccess &&
                                'shareable-social-icon__feedback-text--animating'
                            }`}
                        >
                            {t(`general.copied`)}
                        </span>
                    </button>

                    <textarea
                        className="shareable-social-icon__textarea"
                        ref={textArea}
                        readOnly
                        value={textAreaValue}
                    />
                </>
            )}
        </>
    );
}

ShareableSocialIcon.propTypes = {
    type: PropTypes.string,
    buttonStyle: PropTypes.string,
    customClass: PropTypes.string,
    customUrl: PropTypes.string,
    analyticsProperties: PropTypes.shape({})
};

export default memo(ShareableSocialIcon);
