import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockUserQuote,
    FeatureBlockImage
} from '../../FeatureBlockElements';
import FeatureTitleList from '../../FeatureTitleList/FeatureTitleList';

import FeaturesProposalBlockStore from './FeaturesProposalBlockStore';

import './features-proposal-block.scss';

const { blockData } = FeaturesProposalBlockStore;

const handleAnalytics = () => {
    AnalyticsService.trackClick(
        AnalyticsEvents.lp_features_hub_feature_redirect,
        {
            source: 'proposal'
        }
    );
};

const FeaturesProposalBlock = () => {
    const { t } = useTranslation();

    const listName = `featuresPage.${blockData.id}.list`;
    const listItems = Object.keys(
        t(listName, {
            returnObjects: true
        })
    ).map(key => ({ id: key }));

    const [ref, inView] = useInView({
        threshold: 1
    });

    const activeClass = inView ? 'feature-block--in-view' : '';

    return (
        <div ref={ref}>
            <FeatureBlockSection
                customClass={`features-proposal-block ${activeClass}`}
            >
                <FeatureBlockContent customClass="features-proposal-block__text-container">
                    <FeatureTitleList
                        title={t(`featuresPage.${blockData.id}.title`)}
                        subtitle={t(`featuresPage.${blockData.id}.subtitle`)}
                        listName={`featuresPage.${blockData.id}.list`}
                        items={listItems}
                    />
                    <div className="features-proposal-block__link-container">
                        <a
                            onClick={handleAnalytics}
                            className="features-page__block-link hbui-button hbui-button--medium hbui-button--secondary"
                            href={blockData.link}
                        >
                            {t(`featuresPage.ctaText`)}
                        </a>
                    </div>

                    <FeatureBlockUserQuote
                        name={blockData.quoteMember}
                        avatar={blockData.quoteAvatar}
                        jobTitle={t(
                            `featuresPage.${blockData.id}.testimonial.company`
                        )}
                        avatarWidth={40}
                        quote={t(
                            `featuresPage.${blockData.id}.testimonial.quote`
                        )}
                        avatarCrop="thumb"
                        avatarGravity="face"
                    />
                </FeatureBlockContent>
                <FeatureBlockContent customClass="feature-block-img">
                    <FeatureBlockImage
                        customClass="feature-block__proposal-img"
                        featureImage={blockData.image}
                        width={370}
                        crop="scale"
                    />
                    <HBMediaElement
                        name={blockData.slideInImages.toast}
                        width={250}
                        customClass="feature-block__slide-in feature-block__notification-img"
                    />
                    <HBMediaElement
                        name={blockData.slideInImages.phone}
                        width={300}
                        customClass="feature-block__slide-in feature-block__phone-sig-img"
                    />
                    <HBMediaElement
                        name={blockData.slideInImages.bell}
                        width={83}
                        customClass="feature-block__slide-in feature-block__bell-img"
                    />
                    <HBMediaElement
                        name={blockData.slideInImages.plant}
                        width={83}
                        customClass="feature-block__slide-in feature-block__plant-img"
                    />
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

export default FeaturesProposalBlock;
