import { graphql } from 'gatsby';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import ClientRelationshipsHero from '../components/FeatureBlocks/ClientRelationshipsHero/ClientRelationshipsHero';
import FeatureInfoBlock, {
    IFeature
} from '../components/FeatureBlocks/FeatureInfoBlock/FeatureInfoBlock';
import FeatureLeadAnimation from '../components/FeatureBlocks/FeatureInfoBlock/Animations/FeatureLeadAnimation';
import FeatureIntegrationBlock from '../components/FeatureBlocks/FeatureIntegrationBlock/FeatureIntegrationBlock';
import FeatureTemplateGallery from '../components/FeatureBlockElements/FeatureTemplateGallery/FeatureTemplateGallery';
import FeatureTestimonialCarouselBlock from '../components/FeatureBlocks/FeatureTestimonialCarouselBlock/FeatureTestimonialCarouselBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import LeadGenFormBlock from '../components/LeadGenFormBlock/LeadGenFormBlock';
import BasicStatsBlock from '../components/FeatureBlocks/BasicStatsBlock/BasicStatsBlock';
import HBMediaElement from '../components/honeybook-ui/HBMediaElement/HBMediaElement';
import HBVideo from '../components/honeybook-ui/HBVideo/HBVideo';

import ClientRelationshipsStore from '../stores/ClientRelationshipsStore';

import '../styles/online-template.scss';
import '../styles/client-relationships.scss';

const ogTags = {
    url: 'https://www.honeybook.com/client-relationships',
    title: 'Client relationships management platform',
    description:
        'Meet HoneyBook—the platform for turning leads into lasting client relationships. Capture, nurture, and book your dream clients.',
    image: 'https://res.cloudinary.com/honeybook/image/upload/v1729290789/marketing_site/october_gtm/og.png'
};

const { integrationItems, testimonials, templateData } =
    ClientRelationshipsStore;

interface Props {
    pageContext: {
        contentKey: string;
    };
}

const ClientRelationships = ({
    pageContext: { contentKey = 'default' }
}: Props): JSX.Element => {
    const { t } = useTranslation();

    const stats = useMemo(
        () => [
            {
                id: 'revenue',
                title: (
                    <Trans>
                        {t('clientRelationships.stats.relationships.title')}
                    </Trans>
                ),
                subtitle: (
                    <Trans>
                        {t('clientRelationships.stats.relationships.subtitle')}
                    </Trans>
                )
            },
            {
                id: 'leads',
                title: (
                    <Trans>{t('clientRelationships.stats.leads.title')}</Trans>
                ),
                subtitle: (
                    <Trans>
                        {t('clientRelationships.stats.leads.subtitle')}
                    </Trans>
                )
            },
            {
                id: 'paid',
                title: (
                    <Trans>
                        {t('clientRelationships.stats.payments.title')}
                    </Trans>
                ),
                subtitle: (
                    <Trans>
                        {t('clientRelationships.stats.payments.subtitle')}
                    </Trans>
                )
            }
        ],
        []
    );

    const features: IFeature[] = useMemo(
        () => [
            {
                transKey: 'lead',
                animationCmp: (
                    <FeatureLeadAnimation
                        customClass="feature-info-block__animation"
                        customImageLeft="marketing_site/october_gtm/left_side_lead_mgmt_templates_-_no_shadow_x4.png"
                        customImageRight="marketing_site/october_gtm/right_side_lead_mgmt_templates_-_no_shadow_x4.png"
                    />
                )
            },
            {
                transKey: 'automations',
                animationCmp: (
                    <HBMediaElement
                        customClass="feature-info-section__automation-img"
                        name="marketing_site/october_gtm/features%20assets/automations2.png"
                    />
                )
            },
            {
                transKey: 'proposals',
                animationCmp: (
                    <div className="feature-info-section__video-container">
                        <HBVideo
                            videoId="1021878197"
                            height="427"
                            autoplay
                            loop
                            muted
                        />
                    </div>
                )
            },
            {
                transKey: 'ai',
                animationCmp: (
                    <div className="feature-info-section__video-container">
                        <HBVideo
                            videoId="1021064728"
                            height="600"
                            autoplay
                            loop
                            muted
                        />
                    </div>
                )
            },
            {
                transKey: 'payments',
                animationCmp: (
                    <HBMediaElement
                        customClass="feature-info-section__automation-img"
                        name="marketing_site/october_gtm/Payments_updated_date.png"
                    />
                )
            }
        ],
        []
    );

    return (
        <Layout ogTags={ogTags} slimNav mobileStickyCTA customClass="octGtm">
            <div className="online-template client-relationships">
                <ClientRelationshipsHero
                    title={t(`ClientRelationshipsHero.${contentKey}.title`)}
                    subtitle={t(
                        `ClientRelationshipsHero.${contentKey}.subtitle`
                    )}
                    intentTitle={t(
                        `ClientRelationshipsHero.${contentKey}.intentTitle`
                    )}
                    imgUrl={'marketing_site/crm-page/crm-hero-v4.png'}
                />
                <BasicStatsBlock stats={stats} />
                <LeadGenFormBlock transId="clientRelationships.leadGenForm" />
                <FeatureInfoBlock
                    customTitle={t(
                        `clientRelationships.featureInfoBlock.title`
                    )}
                    transId={contentKey}
                    features={features}
                    customButtonText={t(
                        `clientRelationships.featureInfoBlock.ctaText`
                    )}
                    hasHeaderCTA
                />
                <FeatureTemplateGallery
                    title={t(
                        'clientRelationships.featureTemplateGallery.title'
                    )}
                    subtitle={t(
                        'clientRelationships.featureTemplateGallery.subtitle'
                    )}
                    customTemplateData={templateData}
                    ctaSource="crm template gallery"
                />
                <FeatureTestimonialCarouselBlock testimonials={testimonials} />
                <FeatureIntegrationBlock
                    title={t(
                        `clientRelationships.featureIntegrationBlock.title`
                    )}
                    subtitle={t(
                        `clientRelationships.featureIntegrationBlock.subtitle`
                    )}
                    items={integrationItems}
                />
                <PromoBlockLeft
                    customClass="verticals-paid-page--new__promo-block-left promo-block-left--yellow"
                    title={t(`clientRelationships.promoBlockLeft.title`)}
                    ctaText={t(`clientRelationships.promoBlockLeft.ctaText`)}
                    source="promo block left - client relationships"
                />
            </div>
        </Layout>
    );
};

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "clientRelationships", "crm"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default ClientRelationships;
