import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HBButton, { ButtonStyles } from '@honeybook-ui/HBButton/HBButton';
import HBModal from '@honeybook-ui/HBModal/HBModal';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './non-usa-warning-modal.scss';

const NonUsaWarningModal = ({
    isOpen,
    onClose,
    onConfirm,
    onSecondaryAction
}) => {
    const { t } = useTranslation();

    const handleClickAccept = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.on_boarding_non_usa_warning_continue
        );

        if (onConfirm) {
            onConfirm();
        }
    };

    const handleSecondaryAction = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.on_boarding_non_usa_warning_cancel
        );

        if (onSecondaryAction) {
            onSecondaryAction();
        }
    };

    const ButtonGroup = () => (
        <div className="usa-modal__button-group">
            <HBButton
                customClass="usa-modal__button usa-modal__button-cancel"
                text={t('nonUsaWarningModal.else')}
                onClick={handleSecondaryAction}
                buttonStyle={ButtonStyles.MINIMAL}
                data-testid="hb-modal-cancel--button"
            />
            <HBButton
                customClass="usa-modal__button usa-modal__button-continue"
                text={t('nonUsaWarningModal.usaBased')}
                onClick={handleClickAccept}
                data-testid="hb-modal-accept--button"
            />
        </div>
    );

    return (
        <HBModal
            className="usa-modal"
            isOpen={isOpen}
            onClose={onClose}
            title=""
            footerContent={<ButtonGroup />}
        >
            <HBMediaElement
                height={120}
                width={120}
                name="marketing_site/Location-illu.svg"
                forceImgTag
            />
            <h2 className="usa-modal__title">
                {t('nonUsaWarningModal.title')}
            </h2>
            <p className="usa-modal__body">
                {t('nonUsaWarningModal.bodyText')}
            </p>
        </HBModal>
    );
};

NonUsaWarningModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    onConfirm: PropTypes.func,
    onSecondaryAction: PropTypes.func
};

export default NonUsaWarningModal;
