import { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import NavBarTools from '../../NavBar/NavBarTools';
import Hero from '../Hero/Hero';
import HeroTitle from '../HeroTitle/HeroTitle';
import HeroStrip from '../HeroStrip/HeroStrip';
import HeroColumn from '../HeroColumn/HeroColumn';
import ShareableSocialIcon from '../../ShareableSocialIcon/ShareableSocialIcon';
import SaveToPhotosButton from '../../SaveToPhotosButton/SaveToPhotosButton';

import './business-personality-tool-result-hero.scss';

const shareableSocialCustomUrl =
    'https://www.honeybook.com/business-personality-test';

const heroImages = {
    visionaryRisingStar: {
        avatar: 'marketing_site/business_personality_tool/avatars/visionary.png',
        badge: 'marketing_site/business_personality_tool/badges/Rising_Star_Badge.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316819/marketing_site/business_personality_tool/downloadables/Downloadable_Visionary_Rising_Star.png'
    },
    visionaryWaveMaker: {
        avatar: 'marketing_site/business_personality_tool/avatars/visionary.png',
        badge: 'marketing_site/business_personality_tool/badges/Wave_Maker_Badge.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316823/marketing_site/business_personality_tool/downloadables/Downloadable_Visionary_Wave_Maker.png'
    },
    visionaryDrivingForce: {
        avatar: 'marketing_site/business_personality_tool/avatars/visionary.png',
        badge: 'marketing_site/business_personality_tool/badges/Driving_Force_Bagde.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316818/marketing_site/business_personality_tool/downloadables/Downloadable_Visionary_Driving_Force.png'
    },
    visionaryEmpireBuilder: {
        avatar: 'marketing_site/business_personality_tool/avatars/visionary.png',
        badge: 'marketing_site/business_personality_tool/badges/Empire_Builder_Badge.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316819/marketing_site/business_personality_tool/downloadables/Downloadable_Visionary_Empire_Builder.png'
    },
    creativeRisingStar: {
        avatar: 'marketing_site/business_personality_tool/avatars/creative.png',
        badge: 'marketing_site/business_personality_tool/badges/Rising_Star_Badge.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316818/marketing_site/business_personality_tool/downloadables/Downloadable_Creative_Rising_Star.png'
    },
    creativeWaveMaker: {
        avatar: 'marketing_site/business_personality_tool/avatars/creative.png',
        badge: 'marketing_site/business_personality_tool/badges/Wave_Maker_Badge.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316818/marketing_site/business_personality_tool/downloadables/Downloadable_Creative_Wave_Maker.png'
    },
    creativeDrivingForce: {
        avatar: 'marketing_site/business_personality_tool/avatars/creative.png',
        badge: 'marketing_site/business_personality_tool/badges/Driving_Force_Bagde.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316814/marketing_site/business_personality_tool/downloadables/Downloadable_Creative_Driving_Force.png'
    },
    creativeEmpireBuilder: {
        avatar: 'marketing_site/business_personality_tool/avatars/creative.png',
        badge: 'marketing_site/business_personality_tool/badges/Empire_Builder_Badge.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316817/marketing_site/business_personality_tool/downloadables/Downloadable_Creative_Empire_Builder.png'
    },
    actualizerRisingStar: {
        avatar: 'marketing_site/business_personality_tool/avatars/actualizer.png',
        badge: 'marketing_site/business_personality_tool/badges/Rising_Star_Badge.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316811/marketing_site/business_personality_tool/downloadables/Downloadable_Actualizer_Rising_Star.png'
    },
    actualizerWaveMaker: {
        avatar: 'marketing_site/business_personality_tool/avatars/actualizer.png',
        badge: 'marketing_site/business_personality_tool/badges/Wave_Maker_Badge.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316811/marketing_site/business_personality_tool/downloadables/Downloadable_Actualizer_Wave_Maker.png'
    },
    actualizerDrivingForce: {
        avatar: 'marketing_site/business_personality_tool/avatars/actualizer.png',
        badge: 'marketing_site/business_personality_tool/badges/Driving_Force_Bagde.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316816/marketing_site/business_personality_tool/downloadables/Downloadable_Actualizer_Driving_Force.png'
    },
    actualizerEmpireBuilder: {
        avatar: 'marketing_site/business_personality_tool/avatars/actualizer.png',
        badge: 'marketing_site/business_personality_tool/badges/Empire_Builder_Badge.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316811/marketing_site/business_personality_tool/downloadables/Downloadable_Actualizer_Empire_Builder.png'
    },
    connectorRisingStar: {
        avatar: 'marketing_site/business_personality_tool/avatars/connector.png',
        badge: 'marketing_site/business_personality_tool/badges/Rising_Star_Badge.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316812/marketing_site/business_personality_tool/downloadables/Downloadable_Connector_Rising_Star.png'
    },
    connectorWaveMaker: {
        avatar: 'marketing_site/business_personality_tool/avatars/connector.png',
        badge: 'marketing_site/business_personality_tool/badges/Wave_Maker_Badge.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316817/marketing_site/business_personality_tool/downloadables/Downloadable_Connector_Wave_Maker.png'
    },
    connectorDrivingForce: {
        avatar: 'marketing_site/business_personality_tool/avatars/connector.png',
        badge: 'marketing_site/business_personality_tool/badges/Driving_Force_Bagde.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316811/marketing_site/business_personality_tool/downloadables/Downloadable_Connector_Driving_Force.png'
    },
    connectorEmpireBuilder: {
        avatar: 'marketing_site/business_personality_tool/avatars/connector.png',
        badge: 'marketing_site/business_personality_tool/badges/Empire_Builder_Badge.svg',
        downloadPath:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575316816/marketing_site/business_personality_tool/downloadables/Downloadable_Connector_Empire_Builder.png'
    }
};

interface Props {
    resultKey: string;
}

function BusinessPersonalityToolResultHero({ resultKey }: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <Hero customClass="bptr-hero">
            <HeroStrip>
                <HeroColumn
                    customClass="bptr-hero__hero-column"
                    columnWidth={1}
                >
                    <HBMediaElement
                        customClass="bptr-hero__cluster"
                        name="marketing_site/business_personality_tool/avatars/cluster-light_1x.svg"
                        forceImgTag
                        width={145}
                    />

                    <HBMediaElement
                        customClass="bptr-hero__avatar"
                        name={heroImages[resultKey].avatar}
                        forceImgTag
                        width={430}
                    />
                </HeroColumn>
                <HeroColumn
                    customClass="bptr-hero__title-column"
                    columnWidth={1}
                >
                    <div className="bptr-hero__results">
                        <HeroTitle
                            title={t(
                                `businessPersonalityTool.result.${resultKey}.hero.title`
                            )}
                        />
                        <HBMediaElement
                            customClass="bptr-hero__title-badge"
                            name={heroImages[resultKey].badge}
                        />
                        <HeroTitle
                            subtitle={t(
                                `businessPersonalityTool.result.${resultKey}.hero.subtitle`
                            )}
                        />
                        <div className="bptr-hero__social-container">
                            <ShareableSocialIcon
                                customClass="bptr-hero__social-icon"
                                buttonStyle="light"
                                type="facebook"
                                customUrl={shareableSocialCustomUrl}
                                analyticsProperties={{ position: 'hero' }}
                            />
                            <ShareableSocialIcon
                                customClass="bptr-hero__social-icon"
                                buttonStyle="light"
                                type="twitter"
                                customUrl={shareableSocialCustomUrl}
                                analyticsProperties={{ position: 'hero' }}
                            />
                            <ShareableSocialIcon
                                customClass="bptr-hero__social-icon"
                                buttonStyle="light"
                                customUrl={shareableSocialCustomUrl}
                                analyticsProperties={{ position: 'hero' }}
                            />
                        </div>
                        <div className="bptr-hero__save-img-container">
                            <SaveToPhotosButton
                                imageUrl={heroImages[resultKey].downloadPath}
                            />
                        </div>
                    </div>
                </HeroColumn>
            </HeroStrip>
        </Hero>
    );
}

BusinessPersonalityToolResultHero.propTypes = {
    resultKey: PropTypes.string.isRequired
};

export default memo(BusinessPersonalityToolResultHero);
