import { memo } from 'react';
import * as PropTypes from 'prop-types';

import './hb-quote.scss';

import type { ReactNode } from 'react';

interface Props {
    customClass?: string;
    children?: ReactNode;
    hideQuotes?: boolean;
}

function HBQuote({ children, customClass, hideQuotes }: Props): JSX.Element {
    return (
        <blockquote
            className={`hb-blockquote ${
                hideQuotes ? 'hide-quotes' : ''
            } ${customClass}`}
        >
            {children}
        </blockquote>
    );
}

HBQuote.propTypes = {
    customClass: PropTypes.string,
    children: PropTypes.node,
    hideQuotes: PropTypes.bool
};

export default memo(HBQuote);
