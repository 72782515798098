export function loadStatsigSDK() {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src =
            'https://cdn.jsdelivr.net/npm/statsig-js/build/statsig-prod-web-sdk.min.js';
        script.async = true;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            const error = new Error('Failed to load the Statsig SDK');
            reject(error);
        };

        document.head.appendChild(script);
    });
}
