import { graphql } from 'gatsby';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { usePromoCode } from '../hooks/usePromoCode';
import Layout from '../components/Layout/Layout';
import FeaturedIn from '../components/FeaturedIn/FeaturedIn';
import FeatureIntegrationBlock from '../components/FeatureBlocks/FeatureIntegrationBlock/FeatureIntegrationBlock';
import DiscoverLinkBlock from '../components/FeatureBlocks/DiscoverLinkBlock/DiscoverLinkBlock';
import FeatureValuePropsBlock from '../components/FeatureBlocks/FeatureValuePropsBlock/FeatureValuePropsBlock';
import TestimonialCarouselCardBlock from '../components/FeatureBlocks/TestimonialCarouselCardBlock/TestimonialCarouselCardBlock';
import TestimonialCarouselBlock from '../components/FeatureBlocks/TestimonialCarouselBlock/TestimonialCarouselBlock';
import BestExperienceBlock from '../components/FeatureBlocks/BestExperienceBlock/BestExperienceBlock';
import ShowcaseServicesBlock from '../components/FeatureBlocks/ShowcaseServicesBlock/ShowcaseServicesBlock';
import SmarterSystemBlock from '../components/FeatureBlocks/SmarterSystemBlock/SmarterSystemBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import MobileReviewsBlock from '../components/FeatureBlocks/MobileReviewsBlock/MobileReviewsBlock';
import StatsAnimation from '../components/Hero/StatsAnimation/StatsAnimation';
import FeatureChecklistHero from '../components/Hero/FeatureChecklistHero/FeatureChecklistHero';
import { TAdditionalDataForClickStartTrialModules } from '../types/util-types';

import '../styles/home.scss';

const ogTags = {
    url: 'https://www.honeybook.com/',
    title: 'HoneyBook | Client relationship platform for independent businesses',
    description:
        'Book clients, manage projects, and get paid all on HoneyBook. The client relationship management platform for independent businesses. Get started with a free 7-day trial',
    image: 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1675367094/marketing_site/paid/OG_image2.png',
    noIndex: false
};

const featuredItems = [
    {
        imgUrl: 'marketing_site/featured_in/fortune-dark.png',
        alt: 'Fortune'
    },
    {
        imgUrl: 'marketing_site/featured_in/cnbc-dark.png',
        alt: 'CNBC'
    },
    {
        imgUrl: 'marketing_site/featured_in/fast-company-dark.png',
        alt: 'Fast Company'
    },
    {
        imgUrl: 'marketing_site/featured_in/forbes-dark.png',
        alt: 'Forbes'
    },
    {
        imgUrl: 'marketing_site/featured_in/reuters-dark.png',
        alt: 'Reuters'
    },
    {
        imgUrl: 'marketing_site/featured_in/tc-dark.png',
        alt: 'Tech Crunch'
    }
];

export const carouselSlideData = [
    {
        id: 'bray',
        imageUrl: 'marketing_site/testimonial-avatars/bray-new.png'
    },
    {
        id: 'ashley',
        imageUrl: 'marketing_site/testimonial-avatars/ashley-new.png'
    },
    {
        id: 'alexis',
        imageUrl: 'marketing_site/testimonial-avatars/alexis-new.png'
    },
    {
        id: 'pamela',
        imageUrl: 'marketing_site/testimonial-avatars/pamela-new.png'
    },
    {
        id: 'amber',
        imageUrl: 'marketing_site/testimonial-avatars/amber-new.png'
    }
];

const Home = () => {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    const stats = useMemo(
        () => [
            {
                title: <Trans>{t('homeStatsAnimation.earned.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.earned.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('homeStatsAnimation.invoices.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.invoices.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('homeStatsAnimation.members.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.members.subtitle')}</Trans>
                )
            }
        ],
        []
    );

    const promoKey = promoCode || 'default';

    return (
        <Layout ogTags={ogTags} mobileStickyCTA>
            <div className={'home'}>
                <div className="feature-block__wrapper">
                    <FeatureChecklistHero
                        title={t('homepageHero.title')}
                        subtitle={t('homepageHero.subtitle')}
                        module={
                            TAdditionalDataForClickStartTrialModules.CHECK_LIST_HOME_PAGE
                        }
                    />
                    <StatsAnimation stats={stats} />
                    <FeatureValuePropsBlock
                        hideCta={true}
                        title={t('featureValueProps.title')}
                    />
                    <TestimonialCarouselBlock slideData={carouselSlideData} />
                    <BestExperienceBlock videoId="798848864" />
                    <ShowcaseServicesBlock videoUrl="marketing_site/homepage2/your-services" />
                    <PromoBlockLeft
                        title={t(`${promoKey}.promoBlockLeft.title`)}
                        subtitle={t(`${promoKey}.promoBlockLeft.subtitle`, {
                            defaultValue: null
                        })}
                        buttonStyle="primary--bright"
                        darkTheme
                        source="promo block left - dark"
                        promoCode={promoCode}
                    />
                    <FeatureIntegrationBlock
                        title={t('integrationsBlock.title')}
                        subtitle={t('integrationsBlock.subtitle')}
                    />
                    <SmarterSystemBlock />
                    <MobileReviewsBlock />
                    <TestimonialCarouselCardBlock />
                    <FeaturedIn featuredItems={featuredItems} />
                    <DiscoverLinkBlock />
                    <PromoBlockLeft
                        title={t('PromoBlockLeftYellow.title')}
                        customClass="promo-block-left--yellow"
                        source="promo block left - yellow"
                        buttonStyle="primary--dark"
                    />
                </div>
            </div>
        </Layout>
    );
};

export default Home;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "home", "checklistHero"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
