import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import ListItem from '../../List/ListItem/ListItem';
import PictureAndTextBlock from '../PictureAndTextBlock/PictureAndTextBlock';

import { FeatureBlockUserQuote } from '../../FeatureBlockElements';

import './busyness-feature-blocks.scss';

const brand = [
    {
        transKey: 'onlinePaymentsSoftware.featureBlocks.brand.list.item1',
        icon: 'nx-questionnaire'
    },
    {
        transKey: 'onlinePaymentsSoftware.featureBlocks.brand.list.item2',
        icon: 'nx-brochure'
    },
    {
        transKey: 'onlinePaymentsSoftware.featureBlocks.brand.list.item3',
        icon: 'nx-proposal'
    },
    {
        transKey: 'onlinePaymentsSoftware.featureBlocks.brand.list.item4',
        icon: 'nx-dollar-circle'
    }
];

const efficiency = [
    {
        transKey: 'onlinePaymentsSoftware.featureBlocks.efficiency.list.item1',
        icon: 'nx-file'
    },
    {
        transKey: 'onlinePaymentsSoftware.featureBlocks.efficiency.list.item2',
        icon: 'nx-calendar'
    },
    {
        transKey: 'onlinePaymentsSoftware.featureBlocks.efficiency.list.item3',
        icon: 'nx-mobile-phone'
    },
    {
        transKey: 'onlinePaymentsSoftware.featureBlocks.efficiency.list.item4',
        icon: 'nx-bell'
    }
];

function BusynessFeatureBlocks(): JSX.Element {
    const { t } = useTranslation();

    return (
        <>
            <PictureAndTextBlock
                customClass="busyess-feature-block busyess-feature-block--brand"
                primary={t('onlinePaymentsSoftware.featureBlocks.brand.title')}
                secondary={t(
                    'onlinePaymentsSoftware.featureBlocks.brand.subtitle'
                )}
                image="marketing_site/busyness/contract-invoice.png"
                imageWidth={570}
            >
                <ul className="list">
                    {brand.map(listItem => {
                        return (
                            <ListItem
                                key={listItem.transKey}
                                text={t(listItem.transKey)}
                                icon={listItem.icon}
                            />
                        );
                    })}
                </ul>
                <FeatureBlockUserQuote
                    quote={t(
                        'onlinePaymentsSoftware.featureBlocks.brand.testimonial.quote'
                    )}
                    name="Meghan Jester"
                    jobTitle={t(
                        'onlinePaymentsSoftware.featureBlocks.brand.testimonial.jobTitle'
                    )}
                    avatar="marketing_site/testimonial-avatars/image12.jpg"
                    avatarCrop="thumb"
                    avatarGravity="face"
                    avatarWidth={40}
                    avatarHeight={40}
                />
            </PictureAndTextBlock>

            <PictureAndTextBlock
                customClass="busyess-feature-block busyess-feature-block--efficiency"
                primary={t(
                    'onlinePaymentsSoftware.featureBlocks.efficiency.title'
                )}
                secondary={t(
                    'onlinePaymentsSoftware.featureBlocks.efficiency.subtitle'
                )}
                image="marketing_site/busyness/scheduling-notifications.png"
                imageWidth={570}
            >
                <ul className="list">
                    {efficiency.map(listItem => {
                        return (
                            <ListItem
                                key={listItem.transKey}
                                text={t(listItem.transKey)}
                                icon={listItem.icon}
                            />
                        );
                    })}
                </ul>
                <FeatureBlockUserQuote
                    quote={t(
                        'onlinePaymentsSoftware.featureBlocks.efficiency.testimonial.quote'
                    )}
                    name="Jesse Debusk"
                    jobTitle={t(
                        'onlinePaymentsSoftware.featureBlocks.efficiency.testimonial.jobTitle'
                    )}
                    avatar="marketing_site/testimonial-avatars/image13.jpg"
                    avatarCrop="thumb"
                    avatarGravity="face"
                    avatarWidth={40}
                    avatarHeight={40}
                />
            </PictureAndTextBlock>
        </>
    );
}

export default memo(BusynessFeatureBlocks);
