import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import FooterNavList from '../FooterNavList/FooterNavList';
import type { FooterNavbarItem } from '../Footer/Footer';
import CookieSettingsButton from '../CookieSettingsPreferences/CookieSettingsButton';

interface Props {
    items: FooterNavbarItem[];
}
const ONE_TRUST_ITEM_ID = 'honeybook';
function FooterNav({ items }: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <>
            {items.map(item => {
                return (
                    <div
                        key={`footer-column-${item.id}`}
                        className="footer-column"
                    >
                        <h3 className="footer-column__title">
                            {t(`footer.footerNavLinks.${[item.id]}`)}
                        </h3>
                        <ul className="footer-list">
                            <FooterNavList links={item.links} />
                            {item.id === ONE_TRUST_ITEM_ID && (
                                <CookieSettingsButton />
                            )}
                        </ul>
                    </div>
                );
            })}
        </>
    );
}

export default memo(FooterNav);
