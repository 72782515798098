import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import List from '../../List/List';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import FeatureBlockUserQuote from '../../FeatureBlockElements/FeatureBlockUserQuote/FeatureBlockUserQuote';
import type { ReactNode } from 'react';

export interface Props {
    isLeftSide?: boolean;
    featureName: string;
    featureClassName: string;
    mediumUpMediaElement: ReactNode;
    mediumDownMediaElement: ReactNode;
    listItems: { id: string }[];
    listIcon: string;
    quoteProps: {
        name: string;
        avatar: string;
    };
}

const ImageListAndQuoteBlock = ({
    isLeftSide = false,
    featureName,
    featureClassName,
    mediumUpMediaElement,
    mediumDownMediaElement,
    listItems,
    listIcon,
    quoteProps
}: Props) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const content = (
        <FeatureBlockContent
            customClass={`${featureClassName}__text-container`}
        >
            <FeatureBlockText
                primary={t(`featureBlock.${featureName}.title`)}
            />
            {breakpoints.medium && mediumDownMediaElement}
            <List
                listName={`featureBlock.${featureName}.list`}
                items={listItems}
                icon={listIcon}
            />
            <FeatureBlockUserQuote
                quote={t(`featureBlock.${featureName}.quote`)}
                name={quoteProps.name}
                jobTitle={t(`featureBlock.${featureName}.jobTitle`)}
                avatar={quoteProps.avatar}
            />
        </FeatureBlockContent>
    );

    if (isLeftSide) {
        return (
            <FeatureBlockSection customClass={featureClassName}>
                {content}
                {!breakpoints.medium && (
                    <FeatureBlockContent
                        customClass={`${featureClassName}__media-container`}
                    >
                        {mediumUpMediaElement}
                    </FeatureBlockContent>
                )}
            </FeatureBlockSection>
        );
    }

    return (
        <FeatureBlockSection customClass={featureClassName}>
            {!breakpoints.medium && (
                <FeatureBlockContent
                    customClass={`${featureClassName}__media-container`}
                >
                    {mediumUpMediaElement}
                </FeatureBlockContent>
            )}
            <FeatureBlockContent
                customClass={`${featureClassName}__text-container`}
            >
                <FeatureBlockText
                    primary={t(`featureBlock.${featureName}.title`)}
                />
                {breakpoints.medium && mediumDownMediaElement}
                <List
                    listName={`featureBlock.${featureName}.list`}
                    items={listItems}
                    icon={listIcon}
                />
                <FeatureBlockUserQuote
                    quote={t(`featureBlock.${featureName}.quote`)}
                    name={quoteProps.name}
                    jobTitle={t(`featureBlock.${featureName}.jobTitle`)}
                    avatar={quoteProps.avatar}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default ImageListAndQuoteBlock;
