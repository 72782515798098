import { memo, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import HBButton from '@honeybook-ui/HBButton/HBButton';
import HBVideo from '@honeybook-ui/HBVideo/HBVideo';
import ShareableSocialIcon from '../../ShareableSocialIcon/ShareableSocialIcon';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './business-personality-tool-ig-template.scss';

interface Props {
    resultKey: string;
}

const data = {
    visionaryRisingStar: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Visionary_Rising_Star.png',
        videoId: '770383259',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1607993879/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Visionary_Rising_Star.png'
    },
    visionaryWaveMaker: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Visionary_Wave_Maker.png',
        videoId: '770383272',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575929223/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Visionary_Wave_Maker.png'
    },
    visionaryDrivingForce: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Visionary_Driving_Force.png',
        videoId: '770383229',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1607993879/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Visionary_Driving_Force.png'
    },
    visionaryEmpireBuilder: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Visionary_Empire_Builder.png',
        videoId: '770383241',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1607993879/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Visionary_Empire_Builder.png'
    },
    creativeRisingStar: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Creative_Rising_Star.png',
        videoId: '770383198',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1607993879/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Creative_Rising_Star.png'
    },
    creativeWaveMaker: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Creative_Wave_Maker.png',
        videoId: '770383211',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1607993879/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Creative_Wave_Maker.png'
    },
    creativeDrivingForce: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Creative_Driving_Force.png',
        videoId: '770383159',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1607993879/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Creative_Driving_Force.png'
    },
    creativeEmpireBuilder: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Creative_Empire_Builder.png',
        videoId: '770383179',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1607993879/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Creative_Empire_Builder.png'
    },
    actualizerRisingStar: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Actualizer_Rising_Star.png',
        videoId: '770383062',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1607993879/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Actualizer_Rising_Star.png'
    },
    actualizerWaveMaker: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Actualizer_Wave_Maker.png',
        videoId: '770383080',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575929221/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Actualizer_Wave_Maker.png'
    },
    actualizerDrivingForce: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Actualizer_Driving_Force.png',
        videoId: '770383025',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575929221/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Actualizer_Driving_Force.png'
    },
    actualizerEmpireBuilder: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Actualizer_Empire_Builder.png',
        videoId: '770383045',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575929221/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Actualizer_Empire_Builder.png'
    },
    connectorRisingStar: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Connector_Rising_Star.png',
        videoId: '770383132',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1607993879/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Connector_Rising_Star.png'
    },
    connectorWaveMaker: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Connector_Wave_Maker.png',
        videoId: '770383145',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1607993879/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Connector_Wave_Maker.png'
    },
    connectorDrivingForce: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Connector_Driving_Force.png',
        videoId: '770383090',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575929221/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Connector_Driving_Force.png'
    },
    connectorEmpireBuilder: {
        templateBlank:
            'marketing_site/business_personality_tool/instagram-templates/templates/Template_Connector_Empire_Builder.png',
        videoId: '770383114',
        downloadUrl:
            'https://res.cloudinary.com/honeybook/image/upload/fl_attachment/v1575929221/marketing_site/business_personality_tool/downloadables/2020%20Versions/Template_Connector_Empire_Builder.png'
    }
};

const VIDEO_WIDTH = 235;

function BusinessPersonalityToolIGTemplate({ resultKey }: Props): JSX.Element {
    const { t } = useTranslation();

    const { downloadUrl, templateBlank, videoId } = useMemo(
        () => data[resultKey],
        [resultKey]
    );

    const handleCtaClick = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.biz_test_download_ig_template
        );
    };

    return (
        <FeatureBlockSection customClass="bptr-ig-template" lightBackground>
            <FeatureBlockContent>
                <h1 className="bptr-ig-template__title">
                    {t(`businessPersonalityTool.instagram.title1`)}
                    <br />
                    <span className="bptr-ig-template__title-highlight">
                        {t(`businessPersonalityTool.instagram.title2`)}
                    </span>
                </h1>

                <FeatureBlockText
                    secondary={t(`businessPersonalityTool.instagram.body`)}
                    bodyText="#MyBusinessSuperpower"
                />

                <a href={downloadUrl} download>
                    <HBButton
                        customClass="bptr-ig-template__cta"
                        onClick={handleCtaClick}
                        size="medium"
                        text={t(`businessPersonalityTool.instagram.cta`)}
                    />
                </a>

                <div className="bptr-ig-template__social-container">
                    <ShareableSocialIcon
                        customClass="bptr-hero__social-icon shareable-social-icon shareable-social-icon--facebook"
                        type="facebook"
                        customUrl="https://www.honeybook.com/business-personality-test"
                        context={{ position: 'instagram-template' }}
                    />
                    <ShareableSocialIcon
                        customClass="bptr-hero__social-icon shareable-social-icon shareable-social-icon--twitter"
                        type="twitter"
                        context={{ position: 'instagram-template' }}
                    />
                    <ShareableSocialIcon
                        customClass="bptr-hero__social-icon shareable-social-icon shareable-social-icon--link"
                        context={{ position: 'instagram-template' }}
                    />
                </div>
            </FeatureBlockContent>

            <FeatureBlockContent customClass="bptr-ig-template__template-container">
                <HBMediaElement
                    customClass="bptr-ig-template__template-phone"
                    name="marketing_site/business_personality_tool/instagram-templates/phone-stand_2x.png"
                    width={290}
                />
                <HBMediaElement
                    customClass="bptr-ig-template__template-blank"
                    name={templateBlank}
                    width={235}
                />
                <HBMediaElement
                    customClass="bptr-ig-template__template-shape"
                    name="marketing_site/business_personality_tool/instagram-templates/Shape_bg.svg"
                    width={209}
                />

                <HBVideo
                    autoplay
                    loop
                    muted
                    videoId={videoId}
                    height={VIDEO_WIDTH * 1.77}
                    position="center"
                    customClass="bptr-ig-template__template-filled"
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

BusinessPersonalityToolIGTemplate.propTypes = {
    resultKey: PropTypes.string.isRequired
};

export default memo(BusinessPersonalityToolIGTemplate);
