import { useCallback, memo } from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';

import Checkbox from '../../../vendor/blueprintjs/checkbox';

import './hb-checkbox.scss';

interface Props {
    label: string;
    onChange?: (label: string, isChecked: boolean) => void;
    defaultChecked?: boolean;
    isRequired?: boolean;
    size?: string;
    inline?: boolean;
    isDisabled?: boolean;
    customClass?: string;
    isChecked?: boolean;
    e2eTestLocator?: string;
}

function HBCheckbox({
    label,
    isDisabled,
    defaultChecked,
    size,
    inline,
    isRequired,
    isChecked,
    customClass,
    onChange,
    e2eTestLocator
}: Props): JSX.Element {
    const onCheckboxChange = useCallback(() => {
        if (isDisabled) {
            return;
        }

        if (onChange) {
            onChange(label, !isChecked);
        }
    }, [isDisabled, onChange, label, isChecked]);

    const className = classnames(`hbui-checkbox`, {
        'hbui-checkbox--checked': isChecked,
        'hbui-checkbox--disabled': isDisabled,
        [`${customClass}`]: Boolean(customClass)
    });

    const large = size !== 'small';

    return (
        <Checkbox
            className={className}
            label={`${label} ${isRequired ? '*' : ''}`}
            large={large}
            inline={inline}
            onChange={onCheckboxChange}
            disabled={isDisabled}
            defaultChecked={defaultChecked}
            checked={isChecked}
            e2e-test-locator={e2eTestLocator}
        />
    );
}

HBCheckbox.propTypes = {
    onChange: PropTypes.func,
    defaultChecked: PropTypes.bool,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    size: PropTypes.string,
    isDisabled: PropTypes.bool,
    customClass: PropTypes.string,
    isChecked: PropTypes.bool,
    e2eTestLocator: PropTypes.string
};

export default memo(HBCheckbox);
