import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './image-text-elements-block.scss';

import ImageTextElement from '../../ImageTextElement/ImageTextElement';

const ImageTextElementsBlock = ({
    t,
    customClass = '',
    imageTextElements,
    title,
    subtitle,
    bodyText,
    imagePosition,
    lightBackground,
    darkBackground
}) => {
    return (
        <FeatureBlockSection
            customClass={`image-text-elements-block ${customClass}`}
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent position={imagePosition}>
                <FeatureBlockText
                    fullWidth
                    primary={title}
                    secondary={subtitle}
                    bodyText={bodyText}
                />
                <div className="image-text-elements-block__container">
                    {imageTextElements.map(item => (
                        <ImageTextElement
                            key={t(`${item.title}`)}
                            handleClick={item.handleClick}
                            analyticsCallback={item.analyticsCallback}
                            imagePath={item.imagePath}
                            mediaFlags={item.mediaFlags}
                            title={item.title && t(item.title)}
                            description={item.text && t(item.text)}
                        />
                    ))}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

ImageTextElementsBlock.propTypes = {
    t: PropTypes.func,
    customClass: PropTypes.string,
    imageTextElements: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.oneOfType(PropTypes.string, PropTypes.shape({})),
    subtitle: PropTypes.string,
    bodyText: PropTypes.string,
    imagePosition: PropTypes.string,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool
};

export default withTranslation()(ImageTextElementsBlock);
