import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import HBText from '../../honeybook-ui/HBText/HBText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import ChevronIcon from '../../../svg/chevron-left.inline.svg';

import './feature-info-block.scss';

export interface IFeature {
    transKey: string;
    animationCmp: JSX.Element;
}

interface IFeatureInfoBlock {
    customTitle?: string;
    transId: string;
    features: IFeature[];
    hasHeaderCTA?: boolean;
    customButtonText?: string;
}

const FeatureInfoBlock = ({
    customTitle,
    transId = 'default',
    features,
    hasHeaderCTA,
    customButtonText
}: IFeatureInfoBlock) => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="feature-info-block">
            <FeatureBlockContent customClass="feature-info-block__content">
                <div className="feature-info-block__text-container">
                    <HBText
                        customClass="feature-info-block__title"
                        tag="p"
                        type="title-l"
                    >
                        {customTitle || t(`featureInfoBlock.title`)}
                    </HBText>

                    <HBText
                        customClass="feature-info-block__subtitle"
                        tag="p"
                        type="body-m"
                    >
                        {t(`featureInfoBlock.subtitle`)}
                    </HBText>
                </div>

                {hasHeaderCTA && (
                    <RegistrationForm
                        customClass="feature-info-section__header-cta"
                        source={`feature info block - header`}
                        buttonStyle="invisible"
                        buttonText={customButtonText}
                        size="large"
                        showNoCcRequired
                    />
                )}

                <div className="feature-info-block__feature-list">
                    {features.map(feature => (
                        <FeatureInfoSection
                            key={feature.transKey}
                            transId={transId}
                            feature={feature}
                        />
                    ))}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default FeatureInfoBlock;

interface IFeatureInfoSection {
    transId: string;
    feature: IFeature;
}

const FeatureInfoSection = ({ transId, feature }: IFeatureInfoSection) => {
    const { t } = useTranslation();

    return (
        <div className="feature-info-section">
            <div className="feature-info-section__text-container">
                <HBText
                    customClass="feature-info-section__label"
                    tag="p"
                    type="body-m"
                >
                    {t(
                        `featureInfoBlock.features.${transId}.${feature.transKey}.label`
                    )}
                </HBText>
                <HBText
                    customClass="feature-info-section__title"
                    tag="p"
                    type="title-m"
                >
                    {t(
                        `featureInfoBlock.features.${transId}.${feature.transKey}.title`
                    )}
                </HBText>
                <HBText
                    customClass="feature-info-section__subtitle"
                    tag="p"
                    type="body-m"
                >
                    {t(
                        `featureInfoBlock.features.${transId}.${feature.transKey}.subtitle`
                    )}
                </HBText>

                <div className="feature-info-section__cta-container">
                    <RegistrationForm
                        customClass="feature-info-section__cta"
                        source={`feature info block - ${feature.transKey}`}
                        buttonStyle="invisible"
                    />
                    <ChevronIcon className="feature-info-section__cta-arrow" />
                </div>
            </div>
            {feature.animationCmp}
        </div>
    );
};
