import { memo, ReactNode } from 'react';
import NavSubMenuItem from '../NavSubMenuItem/NavSubMenuItem';

import './mobile-nav-submenu.scss';

import type { MouseEventHandler } from 'react';

interface SubItem {
    title: string;
    description: string;
    icon?: ReactNode;
    onMouseEnter?: MouseEventHandler<HTMLAnchorElement>;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    link: string;
}

interface Props {
    items: SubItem[];
}

function MobileNavSubMenu({ items }: Props): JSX.Element {
    return (
        <div className="mobile-nav-submenu">
            {items.map((item: SubItem): JSX.Element => {
                return (
                    <NavSubMenuItem
                        key={item.title}
                        icon={item.icon}
                        title={item.title}
                        description={item.description}
                        link={item.link}
                        onClick={item.onClick}
                    />
                );
            })}
        </div>
    );
}

export default memo(MobileNavSubMenu);
