import type { IABTestsDescription } from '../types/ABTest-types';

/* Finished tests latest versions -
    / (home) - version 10
    /pricing - version 5
    /getstarted - version 7
    /get-invoice-templates - version 2
    /online-contract - version 2
    /features - version 1
    /venue-management-software - version 1
    /software-for-event-planners - version 1
    /catering-management-software - version 1
    /crm-for-photographers - version 1
    /industry-venue-managers - version 1
    /industry-event-planners - version 1
    /free-invoice-templates - version 1
    /free-invoice-templates/photographers - version 2
    /free-contract-templates - version 1
    /photography-contract-templates - version 2
    /industry-photographers - version 2
    /industry-caterers - version 2
    /online-invoices - version 3
*/

// CURRENT_AB_TESTS represent the existing ab tests all over the marketing site
export const CURRENT_AB_TESTS: IABTestsDescription = {};
