import { useEffect, useState } from 'react';
import UtilsService from '../Services/UtilsService';

export const useIsBrowser = () => {
    const [isBrowser, setIsBrowser] = useState(false);
    useEffect(() => {
        if (UtilsService.isBrowser()) {
            setIsBrowser(true);
        }
    }, []);

    return { isBrowser };
};
