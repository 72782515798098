const PaymentsBlockStore = {
    imageTextElements: [
        {
            title: 'featureBlock.payments.dispute.title',
            text: 'featureBlock.payments.dispute.body',
            imagePath: 'marketing_site/online-payments/Dispute.svg',
            altText: 'featureBlock.payments.dispute.title',
            mediaFlags: { forceImgTag: true }
        },
        {
            title: 'featureBlock.payments.secure.title',
            text: 'featureBlock.payments.secure.body',
            imagePath: 'marketing_site/online-payments/secure.svg',
            altText: 'featureBlock.payments.secure.title',
            mediaFlags: { forceImgTag: true }
        },
        {
            title: 'featureBlock.payments.cashflow.title',
            text: 'featureBlock.payments.cashflow.body',
            imagePath: 'marketing_site/online-payments/cashflow.svg',
            altText: 'featureBlock.payments.cashflow.title',
            mediaFlags: { forceImgTag: true }
        }
    ]
};

export default PaymentsBlockStore;
