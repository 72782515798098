import { useTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import AppStoreRating from '../../AppStoreRating/AppStoreRating';
import SpeechBubble from '../../SpeechBubble/SpeechBubble';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import './mobile-reviews-block.scss';

const MobileReviewsBlock = () => {
    const { t } = useTranslation();
    const quotes = Object.values(
        t('MobileReviewsBlock.quotes', {
            returnObjects: true
        })
    ).map(key => {
        return { id: key };
    });
    const breakpoints = useBreakpoint();

    const onAppLinkClick = source => {
        AnalyticsService.trackClick(AnalyticsEvents.app_store_link, {
            source
        });
    };

    return (
        <FeatureBlockSection customClass="mobile-reviews-block">
            <FeatureBlockContent customClass="mobile-reviews-block__text-container">
                <FeatureBlockText
                    primary={t('MobileReviewsBlock.title')}
                    fullWidth
                />
                <AppStoreRating />
                <div className="mobile-reviews-block__app-store">
                    <a
                        onClick={() => onAppLinkClick('apple app store')}
                        href={`//itunes.apple.com/app/apple-store/id${process.env.GATSBY_honeybookAppId}?pt=118116061&ct=homepage&mt=8`}
                        aria-label="Apple App Store"
                    >
                        <HBMediaElement
                            name="marketing_site/mobile_reviews/apple-dark.svg"
                            alt={t('MobileReviewsBlock.alt.apple')}
                        />
                    </a>
                    <a
                        onClick={() => onAppLinkClick('google app store')}
                        href="//play.google.com/store/apps/details?id=com.honeybook.alfred"
                        aria-label="Google Play Store"
                    >
                        <HBMediaElement
                            name="marketing_site/mobile_reviews/android-dark.svg"
                            alt={t('MobileReviewsBlock.alt.android')}
                        />
                    </a>
                    <p className="mobile-reviews-block__app-store-text">
                        {t('MobileReviewsBlock.appStoreText')}
                    </p>
                </div>
                {breakpoints.largeUp && (
                    <>
                        <div className="mobile-reviews-block__quotes-container">
                            <ParallaxProvider>
                                <Parallax translateX={[10, 5]}>
                                    <SpeechBubble
                                        text={quotes[0].id}
                                        quotes
                                        rightSide
                                    />
                                </Parallax>
                                <Parallax translateX={[-50, 25]}>
                                    <SpeechBubble text={quotes[1].id} quotes />
                                </Parallax>
                                <Parallax translateX={[15, -25]}>
                                    <SpeechBubble
                                        text={quotes[2].id}
                                        quotes
                                        rightSide
                                    />
                                </Parallax>
                            </ParallaxProvider>
                        </div>
                    </>
                )}
            </FeatureBlockContent>
            <FeatureBlockContent customClass="mobile-reviews-block__media-container">
                <HBMediaElement
                    className="mobile-reviews-block__feature-image"
                    name="marketing_site/mobile_reviews/phones-light.png"
                    alt={t('MobileReviewsBlock.subtitle')}
                />
                {!breakpoints.largeUp && (
                    <>
                        <div className="mobile-reviews-block__quotes-container">
                            <ParallaxProvider>
                                <Parallax translateX={[0, 8]}>
                                    <SpeechBubble
                                        text={quotes[0].id}
                                        quotes
                                        rightSide
                                    />
                                </Parallax>
                                <Parallax translateX={[5, 0]}>
                                    <SpeechBubble text={quotes[2].id} quotes />
                                </Parallax>
                                <Parallax translateX={[-4, 0]}>
                                    <SpeechBubble
                                        text={quotes[1].id}
                                        quotes
                                        rightSide
                                    />
                                </Parallax>
                            </ParallaxProvider>
                        </div>
                    </>
                )}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default MobileReviewsBlock;
