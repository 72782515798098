import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '@honeybook-ui/HBText/HBText';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import RegistrationFormAsText from '../RegistrationFormAsText/RegistrationFormAsText';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import './contract-template.scss';

interface Props {
    templateId: string;
    imageUrl: string;
    transId: string;
}

const ContractTemplate = ({ templateId, imageUrl, transId }: Props) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    return (
        <div className="contract-template">
            <div className="contract-template__preview">
                <HBMediaElement
                    customClass="contract-template__image"
                    name={imageUrl}
                    lazyLoad={false}
                />
                {breakpoints.mediumUp && (
                    <RegistrationForm
                        customClass="contract-template__button"
                        direction="column"
                        templateId={templateId}
                        buttonText={t('featureTemplateGallery.buttonText')}
                        source={`Contract template - ${templateId}`}
                    />
                )}
            </div>
            <div className="contract-template__text-container">
                <HBText
                    customClass="contract-template__title"
                    tag="p"
                    type="subtitle-l"
                >
                    {t(transId)}
                </HBText>
                {!breakpoints.mediumUp && (
                    <RegistrationFormAsText
                        linkText={t('featureTemplateGallery.buttonText')}
                        source={`Contract template - ${templateId}`}
                        templateId={templateId}
                    />
                )}
            </div>
        </div>
    );
};

export default ContractTemplate;
