import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';
import HBText from '../honeybook-ui/HBText/HBText';
import EmailSignatureCta from './EmailSignatureCta';
import HBButton from '../honeybook-ui/HBButton/HBButton';
import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import SocialShareButton, {
    TSocialType
} from '../SocialShareButton/SocialShareButton';

import './email-signature-finish-modal.scss';

interface Props {
    email: string;
    userEmail: string;
    setUserEmail: (userEmail: string) => void;
    onSubmitForm: () => Promise<boolean>;
}

const SOCIAL_BUTTONS_TYPES: TSocialType[] = ['facebook', 'twitter', 'link'];

function EmailSignatureFinishModal({
    email,
    userEmail,
    setUserEmail,
    onSubmitForm
}: Props) {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const onSendSignature = async () => {
        AnalyticsService.track(AnalyticsEvents.lp_email_signature_complete);
        const isSuccess = await onSubmitForm();
        setIsFormSubmitted(isSuccess);
    };

    useEffect(() => {
        setUserEmail(email);
    }, []);

    return (
        <div className="email-signature-finish-modal">
            {!isFormSubmitted ? (
                <FirstStepContent
                    userEmail={userEmail}
                    setUserEmail={setUserEmail}
                    onSendSignature={onSendSignature}
                />
            ) : (
                <SecondStepContent />
            )}
        </div>
    );
}

interface TFirstStepContent {
    userEmail: string;
    setUserEmail: (userEmail: string) => void;
    onSendSignature: () => Promise<void>;
}
const FirstStepContent = ({
    userEmail,
    setUserEmail,
    onSendSignature
}: TFirstStepContent) => {
    const { t } = useTranslation();
    const [err, setErr] = useState('');

    const sendSignature = () => {
        const isValidEmail = validateEmail(userEmail);
        if (isValidEmail) onSendSignature();
    };

    const validateEmail = email => {
        const isValid = email.match(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        );
        if (isValid) {
            setErr('');
        } else {
            setErr("Oops! That doesn't look like a valid email");
        }
        return isValid;
    };

    return (
        <>
            <div className="email-signature-finish-modal__header">
                <HBMediaElement
                    customClass="email-signature-finish-modal__illustration"
                    name="marketing_site/email-signature/finish-modal/finish-modal-illustration.png"
                    lazyLoad={false}
                />
            </div>
            <div className="email-signature-finish-modal__content">
                <HBText
                    customClass="email-signature-finish-modal__title"
                    tag="h2"
                    type="title-s"
                >
                    {t('emailSignatureBlock.finishModal.title')}
                </HBText>
                <HBText
                    customClass="email-signature-finish-modal__subtitle"
                    tag="p"
                    type="body-s"
                >
                    {t('emailSignatureBlock.finishModal.subtitle')}
                </HBText>
                <div className="email-signature-finish-modal__input-container">
                    <input
                        type="text"
                        placeholder="Email address"
                        onChange={ev => setUserEmail(ev.target.value)}
                        onBlur={ev => {
                            validateEmail(ev.target.value);
                        }}
                        value={userEmail}
                        className={`email-signature-finish-modal__email-input ${
                            !!err &&
                            'email-signature-finish-modal__email-input--error'
                        }`}
                    ></input>
                    {!!err && (
                        <small className="email-signature-finish-modal__error">
                            {err}
                        </small>
                    )}
                </div>

                <HBButton
                    customClass="email-signature-finish-modal__submit-btn"
                    onClick={sendSignature}
                    buttonStyle="primary"
                    text={t('emailSignatureBlock.finishModal.buttonText')}
                />

                <p className="email-signature-finish-modal__small">
                    {t('emailSignatureBlock.finishModal.small')}
                </p>
            </div>
            <div className="email-signature-finish-modal__footer">
                <EmailSignatureCta />
            </div>
        </>
    );
};

const SecondStepContent = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="email-signature-finish-modal__header">
                <HBMediaElement
                    customClass="email-signature-finish-modal__illustration"
                    name="marketing_site/email-signature/finish-modal/finish-modal-illustration-v2.png"
                />
            </div>
            <div className="email-signature-finish-modal__content">
                <HBText
                    customClass="email-signature-finish-modal__title"
                    tag="h2"
                    type="title-s"
                >
                    {t('emailSignatureBlock.finishModal.titleAfterSubmit')}
                </HBText>
                <HBText
                    customClass="email-signature-finish-modal__subtitle"
                    tag="p"
                    type="body-s"
                >
                    {t('emailSignatureBlock.finishModal.subtitleAfterSubmit')}
                </HBText>
                <div className="email-signature-finish-modal__more-tools">
                    <HBText
                        customClass="email-signature-finish-modal__more-tools__text"
                        tag="p"
                        type="body-s"
                    >
                        {t('emailSignatureBlock.finishModal.moreTools')}
                    </HBText>
                    <RegistrationForm
                        source="email_signature_block"
                        customClass="email-signature-finish-modal__cta"
                        buttonStyle="plain"
                        buttonText={t(
                            'emailSignatureBlock.finishModal.ctaAfterSubmit'
                        )}
                    />
                </div>
                <HBText
                    customClass="email-signature-finish-modal__share-text"
                    tag="p"
                    type="body-s"
                >
                    {t('emailSignatureBlock.finishModal.share')}
                </HBText>
                <div className="email-signature-finish-modal__social-container">
                    {SOCIAL_BUTTONS_TYPES.map(buttonType => (
                        <SocialShareButton
                            key={buttonType}
                            customClass="email-signature-finish-modal__social-icon"
                            buttonStyle="dark"
                            type={buttonType}
                            customMessage={t(
                                'emailSignatureBlock.finishModal.socialPostText'
                            )}
                            customUrl="https://www.honeybook.com/email-signature"
                        />
                    ))}
                </div>
            </div>
            <div className="email-signature-finish-modal__footer">
                <HBText
                    customClass="email-signature-finish-modal__learn-text"
                    tag="p"
                    type="body-s"
                >
                    {t('emailSignatureBlock.finishModal.learn')}
                </HBText>
                <HBButton
                    buttonStyle="primary"
                    text={t('emailSignatureBlock.finishModal.learnBtnText')}
                    onClick={() => {
                        window.location.href = '/';
                    }}
                />
            </div>
        </>
    );
};

export default EmailSignatureFinishModal;
