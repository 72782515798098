import { memo, ReactNode, ReactElement } from 'react';

import './nav-submenu-item.scss';

import type { MouseEventHandler } from 'react';
import { NavigationMenuLink } from '@radix-ui/react-navigation-menu';
import { CustomNavLink } from '../NavBarBtn/NavBarBtn';

interface Props {
    title: string;
    description: string;
    link: string;
    icon?: ReactNode;
    onMouseEnter?: MouseEventHandler<HTMLAnchorElement>;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
}

function NavSubMenuItem({
    title,
    description,
    onMouseEnter,
    onClick,
    link,
    icon
}: Props): ReactElement {
    return (
        <NavigationMenuLink
            asChild
            onClick={onClick}
            onMouseEnter={onMouseEnter}
        >
            <CustomNavLink className="nav-submenu-item" link={link}>
                {icon && (
                    <div className="nav-submenu-item__icon-container">
                        <div className="media-element svg-content">{icon}</div>
                    </div>
                )}
                <div className="nav-submenu-item__text-container">
                    <div className="nav-submenu-item__text-container__title">
                        {title}
                    </div>
                    <div className="nav-submenu-item__text-container__description">
                        {description}
                    </div>
                </div>
            </CustomNavLink>
        </NavigationMenuLink>
    );
}

export default memo(NavSubMenuItem);
