import { LegacyRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import HBText from '../../honeybook-ui/HBText/HBText';
import UtilsService from '../../../Services/UtilsService';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import './animated-dollar-text-block.scss';
import { INITIAL_DOLLAR_AMOUNT } from '../../../constants/constants';

interface Card {
    iconUrl: string;
    transId: string;
}
interface Props {
    cardContents: Card[];
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function AnimatedDollarTextBlock({ cardContents }: Props): JSX.Element {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const sectionRef: LegacyRef<any> = useRef();
    const titleRef: LegacyRef<any> = useRef();
    const [dollarAmount, setDollarAmount] = useState(INITIAL_DOLLAR_AMOUNT);

    const [viewRef, inView] = useInView({
        threshold: breakpoints.mediumUp ? 0.6 : 0.2,
        triggerOnce: true
    });

    const scrollHandler = () => {
        const additionalIncrementSpeed = 11;
        setDollarAmount(
            dollarAmount => Math.round(dollarAmount) + additionalIncrementSpeed
        );
        if (inView) {
            const sectionBoundingBox =
                sectionRef.current?.getBoundingClientRect();
            if (sectionBoundingBox) {
                const sectionYPos = sectionBoundingBox.top / window.innerHeight;
                const propertyCalc = 120 - (100 * sectionYPos) / 0.75 + '%';

                if (sectionYPos < 0 || sectionYPos > 1) return;

                titleRef.current.style.setProperty(
                    '--marker-width',
                    propertyCalc
                );
            }
        }
    };

    useEffect(() => {
        window.addEventListener(
            'scroll',
            UtilsService.throttle(() => {
                scrollHandler();
            }, 50)
        );
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [inView]);

    return (
        <div ref={viewRef}>
            <div ref={sectionRef}>
                <FeatureBlockSection customClass="animated-dollar-text-block">
                    <FeatureBlockContent customClass="animated-dollar-text-block__content">
                        <div
                            className={`animated-dollar-text-block__title-wrapper ${
                                inView
                                    ? 'animated-dollar-text-block__title-wrapper--show'
                                    : ''
                            }`}
                            ref={titleRef}
                        >
                            <HBText
                                customClass="animated-dollar-text-block__title"
                                tag="h2"
                                type="title-l"
                            >
                                {t('AnimatedDollarTextBlock.title')}
                            </HBText>
                        </div>

                        <div
                            className={`animated-dollar-text-block__dollar-amount ${
                                inView
                                    ? 'animated-dollar-text-block__dollar-amount--show'
                                    : ''
                            }`}
                        >
                            <span
                                className={`animated-dollar-text-block__dollar-sign ${
                                    inView
                                        ? 'animated-dollar-text-block__dollar-sign--animate'
                                        : ''
                                }`}
                            >
                                ${numberWithCommas(dollarAmount)}
                            </span>
                        </div>
                    </FeatureBlockContent>
                </FeatureBlockSection>
            </div>
        </div>
    );
}

export default AnimatedDollarTextBlock;
