const TeamOfExpertsBlockStore = {
    benefitsList: [
        {
            id: 'time'
        },
        {
            id: 'focus'
        },
        {
            id: 'templates'
        }
    ],
    listIcon: 'nx-check-16'
};

export default TeamOfExpertsBlockStore;
