import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import FeatureTitleList from '../../FeatureTitleList/FeatureTitleList';
import './picture-and-list-block.scss';

const PictureAndListBlock = ({
    title,
    subtitle,
    listName,
    image,
    imageWidth = 570,
    imageAltTag,
    children,
    customClass
}) => {
    const { t } = useTranslation();
    const listItems = Object.keys(
        t(listName, {
            returnObjects: true
        })
    ).map(key => {
        return { id: key };
    });

    return (
        <FeatureBlockSection
            customClass={`picture-and-list-block ${customClass}'
            }`}
        >
            <FeatureBlockContent customClass="picture-and-list-block__feature-list-container">
                <FeatureTitleList
                    title={t(title)}
                    subtitle={t(subtitle)}
                    listName={listName}
                    items={listItems}
                    registrationSource="PictureAndListBlock"
                />
                {children}
            </FeatureBlockContent>
            <FeatureBlockContent>
                <HBMediaElement
                    width={imageWidth || 800}
                    name={image}
                    alt={t(imageAltTag)}
                    crop="scale"
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

PictureAndListBlock.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    listName: PropTypes.string,
    image: PropTypes.string,
    imageAltTag: PropTypes.string,
    imageWidth: PropTypes.number,
    children: PropTypes.node,
    customClass: PropTypes.string
};

export default memo(PictureAndListBlock);
