import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import FeaturesPageBody from '../components/FeaturesPageBody';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';
import '../styles/features.scss';
import { useTranslation } from 'react-i18next';
import { TAdditionalDataForClickStartTrialModules } from '../types/util-types';
import FeatureChecklistHero from '../components/Hero/FeatureChecklistHero/FeatureChecklistHero';

const ogTags = {
    url: 'https://www.honeybook.com/features',
    title: 'HoneyBook Features',
    description:
        "Learn more about HoneyBook's powerful features for small businesses. Includes invoices, proposals, contracts, online payments and more. Free 7 day trial."
};

function FeaturesTemplate() {
    const { t } = useTranslation();

    return (
        <div className="features-page">
            <Layout ogTags={ogTags}>
                <div className="feature-block__wrapper">
                    <FeatureChecklistHero
                        module={
                            TAdditionalDataForClickStartTrialModules.CHECK_LIST_FEATURES_PAGE
                        }
                        title={t('featuresPage.hero.title')}
                    />
                    <FeaturesPageBody />
                    <FreeToTryBlockRebrand />
                </div>
            </Layout>
        </div>
    );
}

export default FeaturesTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "features", "checklistHero"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
