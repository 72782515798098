import AnalyticsService, { AnalyticsEvents } from './AnalyticsService';
import UtilsService from './UtilsService';

let openedIntercomNotFromBubble = false;

type TSource = 'footer' | 'param' | 'bubble';

export default class IntercomService {
    static isIntercomLoaded(): boolean {
        return !!window.Intercom;
    }

    static handleIntercom() {
        if (AnalyticsService.shouldLoadSegment()) {
            UtilsService.handleThirdPartyLoad(
                this.handleIntercomListeners.bind(this),
                this.isIntercomLoaded
            );
        }
    }

    static showIntercom(source: TSource) {
        if (window.Intercom) {
            window.Intercom('show');
            this.sendIntercomShowAnalytic(source);
            if (source !== 'bubble') {
                openedIntercomNotFromBubble = true;
            }
        }
    }

    static async sendIntercomShowAnalytic(source: TSource) {
        AnalyticsService.track(AnalyticsEvents.show_intercom, { source });
    }

    static handleIntercomListeners() {
        if (window.Intercom) {
            window.Intercom('onShow', () => {
                let pixel_url;
                if (process.env.GATSBY_env === 'production') {
                    pixel_url = 'https://pixel.honeybook.com/mta?sk=1&js=1';
                } else {
                    pixel_url = 'https://pixel-dev.honeybook.com/mta?sk=1&js=1';
                }
                fetch(pixel_url, {
                    method: 'GET',
                    credentials: 'include'
                }).then(resp => {
                    resp.json().then(data => {
                        if (data && data.mta_uuid) {
                            window.Intercom('update', {
                                mta_uuid: data.mta_uuid
                            } as Intercom_.IntercomSettings);
                        }
                    });
                });
                // the footer link trigger Intercom('show') which trigger the onShow listener , and we can indicate bubble
                //  click only from here and we don't want to send analytic twice because we just sent from the footer click
                if (!openedIntercomNotFromBubble) {
                    IntercomService.sendIntercomShowAnalytic('bubble');
                }
                openedIntercomNotFromBubble = false;
            });

            window.Intercom('onHide', () => {
                AnalyticsService.track(AnalyticsEvents.hide_intercom);
            });

            if (UtilsService.getParameterByName('chat') === 'show') {
                this.showIntercom('param');
            }
        }
    }
}
