import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import ImageListAndQuoteBlock, {
    Props
} from '../ImageListAndQuoteBlock/ImageListAndQuoteBlock';

import InstantPaymentsBlockStore from './InstantPaymentsBlockStore';
import './instant-payments-block.scss';

const InstantPaymentsBlock = () => {
    const props: Props = {
        featureName: 'instantPayments',
        featureClassName: 'instant-payments',
        mediumUpMediaElement: (
            <HBMediaElement
                width={500}
                name="marketing_site/online-payments/instant-deposit-notification-rebrand"
            />
        ),
        mediumDownMediaElement: (
            <HBMediaElement
                customClass="instant-payments__mobile-image"
                width={440}
                name="marketing_site/online-payments/instant-deposit-notification-rebrand"
            />
        ),
        listItems: InstantPaymentsBlockStore.listItems,
        listIcon: InstantPaymentsBlockStore.listIcon,
        quoteProps: {
            name: 'Setch Halligan',
            avatar: InstantPaymentsBlockStore.avatar
        }
    };

    return <ImageListAndQuoteBlock {...props} />;
};

export default InstantPaymentsBlock;
