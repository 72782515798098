import { memo, useRef, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import HBButton from '../honeybook-ui/HBButton/HBButton';

import NavBarBtn from '../NavBarBtn/NavBarBtn';
import MobileMenuDrawer from '../MobileMenuDrawer/MobileMenuDrawer';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import UtilsService from '../../Services/UtilsService';

import HBLogo from '../../svg/honeybook-logo.inline.svg';
import CalendarNavIcon from '../../svg/navbar-icons/calendar-clock-24.inline.svg';
import AutomationNavIcon from '../../svg/navbar-icons/automation-24.inline.svg';
import ContractNavIcon from '../../svg/navbar-icons/contract-24.inline.svg';
import DollarNavIcon from '../../svg/navbar-icons/dollar-circle-24.inline.svg';
import GridNavIcon from '../../svg/navbar-icons/grid-24.inline.svg';
import InvoiceNavIcon from '../../svg/navbar-icons/invoice-24.inline.svg';
import ProposalNavIcon from '../../svg/navbar-icons/proposal-24.inline.svg';
import RocketNavIcon from '../../svg/navbar-icons/rocket-24.inline.svg';

import { logIn, sendAnalytics } from '../NavBar/NavBar';

import './navbar.scss';
import './navbar-tools.scss';

import type { NavBarItem } from './NavBar';

import type { MouseEvent as ReactMouseEvent } from 'react';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

let lastScrollY = 0;

const goToLink = (
    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
) => {
    /* TODO: Change to ReactRouter Navigation once we deploy more pages */
    event.preventDefault();

    const { itemLink } = event.currentTarget.dataset;

    window.location.href = `${window.location.origin}${itemLink}`;
};

const items: NavBarItem[] = [
    {
        id: 'explore',
        analyticsTarget: 'Features',
        mobileAnalyticsTarget: 'Features',
        onClick: (
            event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
        ) => {
            event.preventDefault();

            sendAnalytics(event, 'Features');
        },
        subItems: [
            {
                title: 'All-in-One Platform',
                link: '/all-in-one-business-platform',
                description:
                    'Everything you need to run your business in one place.',
                icon: <RocketNavIcon />,
                analyticsTarget: 'Features / All-in-one',
                mobileAnalyticsTarget: 'Mobile / All-in-one',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / All-in-one');
                }
            },
            {
                title: 'Online Payments',
                link: '/online-payment-software',
                description:
                    'Make it easy for clients to pay you with online payments.',
                icon: <DollarNavIcon />,
                analyticsTarget: 'Features / Online Payments',
                mobileAnalyticsTarget: 'Mobile / Online Payments',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Online Payments');
                }
            },
            {
                title: 'Online Contracts',
                link: '/online-contract',
                description: 'Customizable contracts that look professional.',
                icon: <ContractNavIcon />,
                analyticsTarget: 'Features / Online Contracts',
                mobileAnalyticsTarget: 'Mobile / Online Contracts',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Online Contracts');
                }
            },
            {
                title: 'Proposals',
                link: '/proposal-software',
                description:
                    'Easily book clients with invoice and contract all in one.',
                icon: <ProposalNavIcon />,
                analyticsTarget: 'Features / Proposals',
                mobileAnalyticsTarget: 'Mobile / Proposals',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Proposals');
                }
            },
            {
                title: 'Scheduling',
                link: '/meeting-scheduler',
                description:
                    'Skip the back and forth and book meetings 2x faster.',
                icon: <CalendarNavIcon />,
                analyticsTarget: 'Features / Scheduling',
                mobileAnalyticsTarget: 'Mobile / Scheduling',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Scheduling');
                }
            },
            {
                title: 'Features Overview',
                link: '/features',
                description: 'Powerful features built for small businesses.',
                icon: <GridNavIcon />,
                analyticsTarget: 'Features / Features',
                mobileAnalyticsTarget: 'Mobile / Features',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Features');
                }
            },
            {
                title: 'Online Invoices',
                link: '/online-invoices',
                description: 'Professional invoices that get you paid fast.',
                icon: <InvoiceNavIcon />,
                analyticsTarget: 'Features / Online Invoices',
                mobileAnalyticsTarget: 'Mobile / Online Invoices',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Online Invoices');
                }
            },
            {
                title: 'Automation',
                link: '/automations',
                description:
                    'Automating your tasks, setting your busywork on autopilot.',
                icon: <AutomationNavIcon />,
                analyticsTarget: 'Features / Scheduling',
                mobileAnalyticsTarget: 'Mobile / Scheduling',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Scheduling');
                }
            }
        ]
    },
    {
        id: 'tools',
        analyticsTarget: 'Tools',
        mobileAnalyticsTarget: 'Tools',
        onClick: (
            event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
        ) => {
            event.preventDefault();

            sendAnalytics(event, 'Tools');

            goToLink(event);
        },
        subItems: [
            {
                title: 'Email Signature',
                link: '/email-signature',
                description: 'Brand your business emails',
                iconPath: '',
                analyticsTarget: 'Tools / Email Signature',
                mobileAnalyticsTarget: 'Mobile / Email Signature',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.preventDefault();

                    sendAnalytics(event, 'Tools / Email Signature');

                    goToLink(event);
                }
            },
            {
                title: 'Mission Statement',
                link: '/mission-statement',
                description: 'Tell the world why your business matters',
                iconPath: '',
                analyticsTarget: 'Tools / Mission Statement',
                mobileAnalyticsTarget: 'Mobile / Mission Statement',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.preventDefault();

                    sendAnalytics(event, 'Tools / Mission Statement');

                    goToLink(event);
                }
            },
            {
                title: 'Invoice Template',
                link: '/invoice-template',
                description: 'Download an easy online template',
                iconPath: '',
                analyticsTarget: 'Tools / Invoice Template',
                mobileAnalyticsTarget: 'Mobile / Invoice Template',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.preventDefault();

                    sendAnalytics(event, 'Tools / Invoice Template');

                    goToLink(event);
                }
            },
            {
                title: 'Business Personality Test',
                link: '/business-personality-test',
                description: 'Discover your strengths and opportunities',
                iconPath: '',
                analyticsTarget: 'Tools / Business Personality Test',
                mobileAnalyticsTarget: 'Mobile / Business Personality Test',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.preventDefault();

                    sendAnalytics(event, 'Tools / Business Personality Test');

                    goToLink(event);
                }
            },
            {
                title: 'Explore HoneyBook',
                link: '/',
                description: 'The all-in-one business management platform',
                iconPath: '',
                analyticsTarget: 'Tools / Home',
                mobileAnalyticsTarget: 'Mobile / Home',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.preventDefault();

                    sendAnalytics(event, 'Tools / Home');

                    goToLink(event);
                }
            }
        ]
    }
];

interface Props {
    darkText?: boolean;
}

function NavBarTools({ darkText = false }: Props): JSX.Element {
    const [displayMobileDrawer, setDisplayMobileDrawer] =
        useState<boolean>(false);

    const { t } = useTranslation();

    const navRef = useRef<HTMLElement>(
        typeof window === 'undefined' ? null : document.createElement('nav')
    );

    const handleScroll = useCallback((): void => {
        lastScrollY = window.scrollY;

        if (navRef.current !== null) {
            if (lastScrollY > navRef.current.offsetHeight) {
                navRef.current.classList.add('fixed');
            } else {
                navRef.current.classList.remove('fixed');
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const handleNavItemClick = useCallback(
        (event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
            const { itemLink } = event.currentTarget.dataset;

            const clickedItem = items.find(item => item.link === itemLink); // Get item

            if (typeof clickedItem !== 'undefined') {
                sendAnalytics(event, clickedItem.analyticsTarget);

                if (typeof clickedItem.onClick !== 'undefined') {
                    clickedItem.onClick(event);
                }
            } else if (itemLink === logo.link) {
                AnalyticsService.trackClick(AnalyticsEvents.honeybook_logo, {
                    source: window.location.pathname
                });
            }
        },
        []
    );

    const onLoginMouseEnter = useCallback(
        (event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>): void => {
            event.preventDefault();

            sendAnalytics(event, 'Log In');
        },
        []
    );

    const toggleMobileDrawer = useCallback(() => {
        setDisplayMobileDrawer((bool: boolean) => !bool);
    }, []);

    const customClass = classnames('login', {
        'navbar__mode--dark-text': darkText
    });

    const logo = {
        id: 'home',
        link: '/',
        icon: <HBLogo />,
        customClass: 'navbar-btn__logo',
        analyticsTarget: 'Honeybook homepage',
        mobileAnalyticsTarget: 'Honeybook homepage'
    };

    return (
        <nav className="navbar navbar-tools" ref={navRef}>
            <div className="nav-logo">
                <NavBarBtn
                    key={logo.id}
                    id={logo.id}
                    icon={logo.icon}
                    link={logo.link}
                    onClick={handleNavItemClick}
                    onMouseEnter={undefined}
                    customClass={`${logo.customClass || ''}`}
                />
            </div>
            <div className="nav-buttons-container">
                <div className="nav-buttons-left">
                    {items.map(item => (
                        <NavBarBtn
                            key={item.id}
                            id={item.id}
                            icon={item.icon}
                            subItems={item.subItems}
                            link={item.link}
                            onClick={handleNavItemClick}
                            onMouseEnter={undefined}
                            customClass={item.customClass}
                        />
                    ))}
                </div>

                <div className="nav-buttons-right">
                    <NavBarBtn
                        id={t(`logIn`)}
                        link={
                            process.env.GATSBY_env === 'production'
                                ? 'https://www.honeybook.com/app/login'
                                : '/app/login'
                        }
                        onClick={logIn}
                        customClass={customClass}
                        onMouseEnter={onLoginMouseEnter}
                    />

                    <RegistrationForm
                        source="header"
                        size="small"
                        customClass="hide-for-mobile"
                        buttonStyle="primary--dark"
                    />
                </div>
            </div>

            <div className="mobile-menu-buttons-wrapper">
                <HBButton
                    icon="nx-hamburger-16"
                    customClass="drawer-button"
                    buttonStyle="invisible"
                    size="large"
                    onClick={toggleMobileDrawer}
                />
            </div>

            {displayMobileDrawer && (
                <MobileMenuDrawer
                    isMobileDrawerOpen={displayMobileDrawer}
                    toggleMobileDrawer={toggleMobileDrawer}
                    items={items}
                />
            )}
        </nav>
    );
}

export default memo(NavBarTools);
