import { memo } from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';

import AppDownloadButton from '../AppDownloadButton/AppDownloadButton';
import UtilsService from '../../Services/UtilsService';

import './mobile-app-buttons.scss';

import type { Display } from '../../Services/UtilsService';

interface Props {
    darkMode?: boolean;
    buttonWidth?: number;
    caption?: boolean;
    display?: Display;
    source?: string;
}

function MobileAppButtons({
    darkMode,
    buttonWidth,
    caption,
    display,
    source
}: Props): JSX.Element {
    const className = classnames('mobile-app-buttons', {
        [UtilsService.screens(display)]: Boolean(display)
    });

    return (
        <div className={className}>
            <AppDownloadButton
                source={source}
                darkMode={darkMode}
                width={buttonWidth}
                caption={caption}
                url={`//itunes.apple.com/app/apple-store/id${process.env.GATSBY_honeybookAppId}?pt=118116061&ct=homepage&mt=8`}
                platform="iphone"
                context="footer"
            />

            <AppDownloadButton
                source={source}
                darkMode={darkMode}
                width={buttonWidth}
                caption={caption}
                url="//play.google.com/store/apps/details?id=com.honeybook.alfred"
                platform="android"
                context="footer"
            />
        </div>
    );
}

MobileAppButtons.propTypes = {
    darkMode: PropTypes.bool,
    buttonWidth: PropTypes.number,
    caption: PropTypes.bool,
    display: PropTypes.objectOf(PropTypes.string),
    source: PropTypes.string
};

export default memo(MobileAppButtons);
