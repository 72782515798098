import InvoicesIcon from '../svg/features/invoices-icon.inline.svg';
import ContractsIcon from '../svg/features/contracts-icon.inline.svg';
import AutomationsIcon from '../svg/features/automations-icon.inline.svg';
import PaymentsIcon from '../svg/features/payments-icon.inline.svg';
import SchedulingIcon from '../svg/features/scheduling-icon.inline.svg';

const AdobeStore = {
    stepBlocksOne: [
        {
            subTransId: 'browse',
            iconUrl: 'marketing_site/online-invoices/02_Steps/palette.svg',
            imageUrl:
                'marketing_site/online-invoices/02_Steps/Desktop/Send_at_the_perfect_moment.png'
        },
        {
            subTransId: 'autoFill',
            iconUrl: 'marketing_site/online-invoices/02_Steps/payments.svg',
            imageUrl:
                'marketing_site/online-invoices/02_Steps/Desktop/Add_your_branding.png'
        }
    ],

    stepBlocksTwo: [
        {
            subTransId: 'schedule',
            iconUrl: 'marketing_site/online-invoices/02_Steps/dollar-cal.svg',
            imageUrl:
                'marketing_site/online-invoices/02_Steps/Desktop/Set_the_payment_terms.png'
        },
        {
            subTransId: 'clientSuccess',
            iconUrl: 'marketing_site/online-invoices/02_Steps/email.svg',
            imageUrl:
                'marketing_site/online-invoices/02_Steps/Desktop/Automate_gentle_reminders.png'
        }
    ],

    stepBlocksThree: [
        {
            subTransId: 'statusUpdates',
            iconUrl: 'marketing_site/online-invoices/02_Steps/dollar-bills.svg',
            imageUrl:
                'marketing_site/online-invoices/02_Steps/Desktop/keep_more_money.png'
        },
        {
            subTransId: 'reminders',
            iconUrl: 'marketing_site/online-invoices/02_Steps/shield.svg',
            imageUrl:
                'marketing_site/online-invoices/02_Steps/Desktop/Know_your_transactions_are_secure.png'
        }
    ],

    integrationsIcons: {
        outer: [
            'marketing_site/online-contracts/integration/outer_1_2x.png',
            'marketing_site/online-contracts/integration/outer_2_2x.png',
            'marketing_site/online-contracts/integration/outer_3_2x.png',
            'marketing_site/online-contracts/integration/outer_4_2x.png',
            'marketing_site/online-contracts/integration/outer_5_2x.png',
            'marketing_site/online-contracts/integration/outer_6_2x.png'
        ],
        inner: [
            'marketing_site/online-contracts/integration/inner_1_2x.png',
            'marketing_site/online-contracts/integration/inner_2_2x.png',
            'marketing_site/online-contracts/integration/inner_3_2x.png',
            'marketing_site/online-contracts/integration/inner_4_2x.png'
        ]
    },

    exploreLinksContent: [
        {
            iconUrl: 'marketing_site/online-invoices/contract.svg',
            text: 'Contracts',
            linkUrl: '/online-contract'
        },
        {
            iconUrl: 'marketing_site/online-contracts/explore/Scheduler.png',
            text: 'Scheduler',
            linkUrl: '/meeting-scheduler'
        },
        {
            iconUrl: 'marketing_site/online-contracts/explore/Automations.png',
            text: 'Automations',
            linkUrl: '/automations'
        },
        {
            iconUrl: 'marketing_site/online-contracts/explore/Projects.png',
            text: 'Projects',
            linkUrl: '/all-in-one-business-platform'
        },
        {
            iconUrl: 'marketing_site/online-contracts/explore/Inquiries.png',
            text: 'Inquiries',
            linkUrl: '/all-in-one-business-platform'
        },
        {
            iconUrl: 'marketing_site/online-contracts/explore/Payments.png',
            text: 'Payments',
            linkUrl: '/online-payment-software'
        },
        {
            iconUrl: 'marketing_site/online-contracts/explore/Forms.png',
            text: 'Forms',
            linkUrl: '/all-in-one-business-platform'
        }
    ],

    previewContent: {
        client: {
            videoId: '999707075',
            videoIdMobile: '999709178',
            mobileImgUrl:
                'marketing_site/online-invoices/00_Hero/Mobile/Client_View_Mobile.png'
        },
        your: {
            imgUrl: 'marketing_site/online-invoices/00_Hero/Desktop/Your_View.png',
            mobileImgUrl:
                'marketing_site/online-invoices/00_Hero/Mobile/Your_View_Mobile.png'
        }
    },

    signatureCardContents: [
        {
            iconUrl:
                'marketing_site/online-invoices/01_Numbers_section/Desktop/24.svg',
            transId: 'adobeB.animatedDollarBlock.cards.effortless'
        },
        {
            iconUrl:
                'marketing_site/online-invoices/01_Numbers_section/Desktop/Dollar/Autopay/24.svg',
            transId: 'adobeB.animatedDollarBlock.cards.onTheGo'
        },
        {
            iconUrl:
                'marketing_site/online-invoices/01_Numbers_section/Desktop/Dollar/Fast/24.svg',
            transId: 'adobeB.animatedDollarBlock.cards.impression'
        }
    ],

    templateData: [
        {
            templateId: '6368d7c8d0efc905b2f6f965',
            transId: 'templates.simpleInvoice',
            imageUrl:
                'marketing_site/online-invoices/03_Templates/Desktop/Card_Simpleinvoice.png'
        },
        {
            templateId: '618ac23a0f5d4201c2dd5588',
            transId: 'templates.invoiceImages',
            imageUrl:
                'marketing_site/online-invoices/03_Templates/Desktop/Card_invoicewithimages.png'
        },
        {
            templateId: '63d90d77ffaf4f0310911b4b',
            transId: 'templates.instantBooking',
            imageUrl:
                'marketing_site/online-invoices/03_Templates/Desktop/Card_instantbooking.png'
        },
        {
            templateId: '647ee51493801636c7bbf3b2',
            transId: 'templates.invoicePayment',
            imageUrl:
                'marketing_site/online-invoices/03_Templates/Desktop/Card_invoicewithpaymentmilestones.png'
        },
        {
            templateId: '647ee710b061802bece34967',
            transId: 'templates.bookingFile',
            imageUrl:
                'marketing_site/online-invoices/03_Templates/Desktop/Card_allinonebookingfile.png'
        },
        {
            templateId: '647ee77cca13550454a38200',
            transId: 'templates.minimalProposal',
            imageUrl:
                'marketing_site/online-invoices/03_Templates/Desktop/Card_minimalproposal.png'
        }
    ],

    quotes: [
        {
            profileImage:
                'marketing_site/online-invoices/06_Testimonials/Photo%20%2B%20Logo/Alexius_S.png',
            transId: 'testimonialCardsBlock.testimonials.alexius',
            name: 'Alexius S.',
            logo: 'marketing_site/online-invoices/06_Testimonials/Photo%20%2B%20Logo/BLCK_HEART_MEDIA.png'
        },
        {
            profileImage:
                'marketing_site/online-invoices/06_Testimonials/Photo%20%2B%20Logo/Danielle_B.png',
            transId: 'testimonialCardsBlock.testimonials.danielle',
            name: 'Danielle B.',
            logo: 'marketing_site/online-invoices/06_Testimonials/Photo%20%2B%20Logo/image_387.png'
        },
        {
            profileImage:
                'marketing_site/online-invoices/06_Testimonials/Photo%20%2B%20Logo/Jayne_B.png',
            transId: 'testimonialCardsBlock.testimonials.jayne',
            name: 'Jayne B.',
            logo: 'marketing_site/online-invoices/06_Testimonials/Photo%20%2B%20Logo/luna.png'
        }
    ],

    customSuperchargeData: [
        {
            transId: 'contract',
            imageUrl:
                'marketing_site/online-invoices/04_Addon/Desktop/Addon_contract.png',
            iconUrl:
                'marketing_site/online-invoices/04_Addon/Desktop/SVG%20Icons/Signature/24.svg'
        },
        {
            transId: 'scheduler',
            imageUrl:
                'marketing_site/online-invoices/04_Addon/Desktop/Addon_schedulemeeting.png',
            iconUrl:
                'marketing_site/online-invoices/04_Addon/Desktop/SVG%20Icons/Calendar/Clock/24.svg'
        }
    ],

    exploreLinksContent: [
        {
            iconUrl: 'marketing_site/online-contracts/explore/Automations.png',
            text: 'Automations',
            linkUrl: '/automations'
        },
        {
            iconUrl: 'marketing_site/online-contracts/explore/Scheduler.png',
            text: 'Scheduler',
            linkUrl: '/meeting-scheduler'
        },
        {
            iconUrl: 'marketing_site/online-contracts/explore/Invoices.png',
            text: 'Invoices',
            linkUrl: '/online-invoices'
        },
        {
            iconUrl: 'marketing_site/online-contracts/explore/Projects.png',
            text: 'Projects',
            linkUrl: '/all-in-one-business-platform'
        },
        {
            iconUrl: 'marketing_site/online-contracts/explore/Inquiries.png',
            text: 'Inquiries',
            linkUrl: '/all-in-one-business-platform'
        },
        {
            iconUrl: 'marketing_site/online-contracts/explore/Payments.png',
            text: 'Payments',
            linkUrl: '/online-payment-software'
        },
        {
            iconUrl: 'marketing_site/online-contracts/explore/Forms.png',
            text: 'Forms',
            linkUrl: '/all-in-one-business-platform'
        }
    ],
    featureValues: [
        {
            id: 'invoices',
            icon: <InvoicesIcon />,
            imagePath:
                'marketing_site/home_page/features_list/v2/Invoice_3x.png',
            link: '/online-invoices'
        },
        {
            id: 'payments',
            icon: <PaymentsIcon />,
            imagePath: 'marketing_site/home_page/features_list/Payments3.png',
            link: '/online-payment-software'
        },
        {
            id: 'templates',
            icon: <ContractsIcon />,
            imagePath:
                'marketing_site/home_page/features_list/v2/Contract_3x.png',
            link: '/online-contract'
        },
        {
            id: 'scheduling',
            icon: <SchedulingIcon />,
            imagePath:
                'marketing_site/home_page/features_list/v2/Schedule_3x.png',
            link: '/meeting-scheduler'
        },
        {
            id: 'automations',
            icon: <AutomationsIcon />,
            imagePath:
                'marketing_site/home_page/features_list/v2/Automation_3x.png',
            link: '/automations'
        }
    ],
    featuredItems: [
        {
            imgUrl: 'marketing_site/featured_in/fortune-dark.png',
            alt: 'Fortune'
        },
        {
            imgUrl: 'marketing_site/featured_in/cnbc-dark.png',
            alt: 'CNBC'
        },
        {
            imgUrl: 'marketing_site/featured_in/fast-company-dark.png',
            alt: 'Fast Company'
        },
        {
            imgUrl: 'marketing_site/featured_in/forbes-dark.png',
            alt: 'Forbes'
        },
        {
            imgUrl: 'marketing_site/featured_in/reuters-dark.png',
            alt: 'Reuters'
        },
        {
            imgUrl: 'marketing_site/featured_in/tc-dark.png',
            alt: 'Tech Crunch'
        }
    ],

    carouselSlideData: [
        {
            id: 'bray',
            imageUrl: 'marketing_site/testimonial-avatars/bray-new.png'
        },
        {
            id: 'ashley',
            imageUrl: 'marketing_site/testimonial-avatars/ashley-new.png'
        },
        {
            id: 'alexis',
            imageUrl: 'marketing_site/testimonial-avatars/alexis-new.png'
        },
        {
            id: 'pamela',
            imageUrl: 'marketing_site/testimonial-avatars/pamela-new.png'
        },
        {
            id: 'amber',
            imageUrl: 'marketing_site/testimonial-avatars/amber-new.png'
        }
    ],
    blockContent: [
        {
            id: 'blockOne',
            images: [
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-one__main',
                    publicId:
                        'marketing_site/lp/all-in-one/mobile/pipeline_3x.png',
                    width: 529,
                    altTransId: 'adobeC.blockContent.blockOne.altPipeline'
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-one__second',
                    publicId:
                        'marketing_site/lp/all-in-one/mobile/notification_3x.png',
                    width: 263,
                    altTransId: 'adobeC.blockContent.blockOne.altNotification'
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-one__third',
                    publicId:
                        'marketing_site/lp/all-in-one/mobile/notification-icon_3x.png',
                    width: 50,
                    altTransId: 'adobeC.blockContent.blockOne.altNotification'
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-one__fourth',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img1/Keep_track.png',
                    width: 120,
                    altTransId: 'adobeC.blockContent.blockOne.altKeepTrack'
                },
                {
                    customClass: 'one-business-platform-block__img--mobile',
                    publicId:
                        'marketing_site/lp/all-in-one/mobile/pipeline-1_2x.png',
                    width: 306,
                    altTransId: 'adobeC.blockContent.blockOne.altMobile'
                }
            ]
        },
        {
            id: 'blockTwo',
            images: [
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__main',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/files-tab_2x.png',
                    width: 493,
                    altTransId: 'adobeC.blockContent.blockTwo.altFiles'
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__second',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/phone-stand_2x.png',
                    width: 350,
                    altTransId: 'adobeC.blockContent.blockTwo.altPhone'
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__second-a',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/photo_2x.png',
                    width: 114
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__second-b',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/attach_2x.png',
                    width: 131
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__second-c',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/quest_2x.png',
                    width: 131
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__second-d',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/brouch_2x.png',
                    width: 117
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__second-e',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/plus-phone_2x.png',
                    width: 34
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__third',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/files.png',
                    width: 80
                },
                {
                    customClass: 'one-business-platform-block__img--mobile',
                    publicId:
                        'marketing_site/lp/all-in-one/mobile/files-mobile-2_2x.png',
                    width: 306,
                    altTransId: 'adobeC.blockContent.blockTwo.altMobile'
                }
            ]
        },
        {
            id: 'blockThree',
            images: [
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-three__main',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img3/files-tab_2x.png',
                    width: 493,
                    altTransId: 'adobeC.blockContent.blockThree.files'
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-three__second',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img3/toast_2x.png',
                    width: 356
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-three__third',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img3/communication.png',
                    width: 80,
                    altTransId:
                        'adobeC.blockContent.blockThree.altCommunication'
                },
                {
                    customClass: 'one-business-platform-block__img--mobile',
                    publicId:
                        'marketing_site/lp/all-in-one/mobile/client-communication-03_2x.png',
                    width: 306
                }
            ]
        },
        {
            id: 'blockFour',
            images: [
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-four__main',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img4/agreement-template02_2x.png',
                    width: 407,
                    altTransId: 'adobeC.blockContent.blockFour.altProposal'
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-four__second',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img4/phone-stand_2x.png',
                    width: 350
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-four__third',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img4/Coins_2x.png',
                    width: 80
                },
                {
                    customClass: 'one-business-platform-block__img--mobile',
                    publicId:
                        'marketing_site/lp/all-in-one/mobile/bookings-payments-4_2x.png',
                    width: 306
                }
            ]
        }
    ]
};

export default AdobeStore;
