import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import HBButton from '../../honeybook-ui/HBButton/HBButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Keyboard, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

import './mission-statement-hero.scss';
import HBText from '../../honeybook-ui/HBText/HBText';

interface Props {
    customClass?: string;
}
interface Props {
    onClick: () => void;
}

const CoachingHero = ({ onClick }: Props) => {
    const { t } = useTranslation();

    const slides = t('missionStatement.hero.slides', {
        returnObjects: true
    });

    const [swiperSlides, setSwiperSlides] = useState([slides[0]]);

    useEffect(() => {
        setSwiperSlides(slides);
    }, []);

    return (
        <FeatureBlockSection customClass="mission-statement-hero">
            <FeatureBlockContent>
                <HBText
                    tag="h1"
                    type="title-l"
                    customClass="mission-statement-hero__title"
                >
                    {t('missionStatement.hero.title')}
                </HBText>
                <HBText
                    tag="h2"
                    type="body-l"
                    customClass="mission-statement-hero__subtitle"
                >
                    {t('missionStatement.hero.subtitle')}
                </HBText>
                <div className="mission-statement-hero__slider">
                    <Swiper
                        slidesPerView={1}
                        initialSlide={0}
                        loop={true}
                        autoplay={{ delay: 2500 }}
                        draggable={false}
                        allowTouchMove={false}
                        pagination={{
                            el: '.mission-statement-hero__pagination'
                        }}
                        centeredSlides
                        keyboard={true}
                        modules={[Pagination, Keyboard, Autoplay]}
                    >
                        {swiperSlides.map(({ quote, name }, index) => {
                            return (
                                <SwiperSlide key={quote}>
                                    <div
                                        className={`mission-statement-hero__slide mission-statement-hero__slide--${index}`}
                                    >
                                        <HBText
                                            tag="p"
                                            type="title-s"
                                            customClass="mission-statement-hero__quote"
                                        >
                                            {quote}
                                        </HBText>
                                        <HBText
                                            tag="p"
                                            type="subtitle-l"
                                            customClass="mission-statement-hero__name"
                                        >
                                            {name}
                                        </HBText>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <div className="mission-statement-hero__pagination"></div>
                    <HBButton
                        onClick={onClick}
                        text={t('missionStatement.hero.ctaText')}
                        buttonStyle="primary"
                        size="large"
                    />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default CoachingHero;
