import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { FeatureBlockSection } from '../../FeatureBlockElements';
import ReactHtmlParser from 'react-html-parser';
import HBText from '../../honeybook-ui/HBText/HBText';

import './help-center-block.scss';

const MeetOurInstructorsBlock = () => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="help-center-block">
            <div className="help-center-block__body-text-container">
                <HBText
                    tag="h2"
                    type="title-m"
                    customClass="help-center-block__title"
                >
                    {t('productEducationPage.helpCenter.title')}
                </HBText>
                <HBText
                    tag="p"
                    type="body-m"
                    customClass="help-center-block__body-text"
                >
                    <Trans
                        i18nKey="productEducationPage.helpCenter.description"
                        components={ReactHtmlParser(
                            "#TEXT# <a href='//help.honeybook.com/'>#LINK_TEXT#</a> #TEXT#"
                        )}
                    />
                </HBText>
            </div>
        </FeatureBlockSection>
    );
};

export default MeetOurInstructorsBlock;
