import { useState, useEffect, useRef } from 'react';
import HBText from '@honeybook-ui/HBText/HBText';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import RegistrationFormAsText from '../../RegistrationFormAsText/RegistrationFormAsText';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import './step-feature-block.scss';
interface SubBlock {
    subTransId: string;
    iconSVG?: JSX.Element;
    iconUrl?: string;
    imageUrl: string;
}

interface Props {
    transId: string;
    stepSubBlocks: SubBlock[];
    linkText: string;
    reverse?: boolean;
    customClass?: string;
}

const StepFeatureBlock = ({
    transId,
    stepSubBlocks,
    linkText,
    reverse,
    customClass = ''
}: Props) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const [autoTransition, setAutoTransition] = useState(false);
    const previousSelectedItemIndex = useRef(0);
    const timeoutRef = useRef();

    const updateSelectedElement = elementIndex => {
        setSelectedItemIndex(elementIndex);
        previousSelectedItemIndex.current = selectedItemIndex;
    };

    const onTrigger = elementIndex => {
        clearTimeout(timeoutRef.current);
        updateSelectedElement(elementIndex);
        setAutoTransition(false);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (!autoTransition) {
                return clearInterval(interval);
            }
            if (selectedItemIndex < stepSubBlocks.length - 1) {
                updateSelectedElement(selectedItemIndex + 1);
            } else {
                updateSelectedElement(0);
            }
        }, 2500);
        return () => clearInterval(interval);
    }, [autoTransition, selectedItemIndex]);

    useEffect(() => {
        return () => clearTimeout(timeoutRef.current);
    }, []);

    const TitleContent = () => (
        <>
            <HBText
                tag="h2"
                type="body-l"
                customClass="step-feature-block__pre-title"
            >
                {t(`${transId}.preTitle`)}
            </HBText>
            <HBText
                tag="h1"
                type="title-m"
                customClass="step-feature-block__title"
            >
                {t(`${transId}.title`)}
            </HBText>
        </>
    );

    if (breakpoints.mediumUp) {
        return (
            <FeatureBlockSection
                customClass={`step-feature-block ${
                    reverse ? 'step-feature-block--reverse' : ''
                } ${customClass}`}
            >
                <FeatureBlockContent customClass="step-feature-block__wrapper">
                    <div className="step-feature-block__text-container">
                        <TitleContent />
                        {stepSubBlocks.map(
                            ({ subTransId, iconSVG, iconUrl }, index) => {
                                return (
                                    <div
                                        key={subTransId}
                                        className={`step-feature-block__sub-block ${
                                            index === selectedItemIndex
                                                ? 'step-feature-block__sub-block--selected'
                                                : ''
                                        }`}
                                        onMouseEnter={() => onTrigger(index)}
                                    >
                                        {iconSVG && iconSVG}
                                        {iconUrl && (
                                            <HBMediaElement
                                                customClass="step-feature-block__sub-block__icon"
                                                name={iconUrl}
                                                forceImgTag
                                            />
                                        )}
                                        <HBText
                                            customClass="step-feature-block__sub-block__title"
                                            tag="h3"
                                            type="body-l"
                                        >
                                            {t(
                                                `${transId}.${subTransId}.title`
                                            )}
                                        </HBText>
                                        <HBText
                                            customClass="step-feature-block__sub-block__description"
                                            tag="p"
                                            type="body-m"
                                        >
                                            {t(
                                                `${transId}.${subTransId}.description`
                                            )}
                                        </HBText>
                                    </div>
                                );
                            }
                        )}

                        <RegistrationFormAsText
                            linkText={linkText}
                            source={t(`${transId}.preTitle`)}
                        />
                    </div>

                    <div className="step-feature-block__media-container">
                        <HBMediaElement
                            customClass="step-feature-block__media"
                            name={stepSubBlocks[selectedItemIndex].imageUrl}
                            lazyLoad={false}
                            alt={
                                t(`${transId}.imageAlt`, {
                                    defaultValue: null
                                }) || t(`${transId}.title`)
                            }
                        />
                    </div>
                </FeatureBlockContent>
            </FeatureBlockSection>
        );
    }

    return (
        <FeatureBlockSection
            customClass={`step-feature-block ${
                reverse ? 'step-feature-block--reverse' : ''
            } ${customClass}`}
        >
            <FeatureBlockContent customClass="step-feature-block__wrapper">
                <div className="step-feature-block__text-container">
                    <TitleContent />

                    {stepSubBlocks.map(
                        ({ subTransId, iconUrl, iconSVG, imageUrl }, index) => {
                            return (
                                <div
                                    className={`step-feature-block__sub-block ${
                                        index === selectedItemIndex
                                            ? 'step-feature-block__sub-block--selected'
                                            : ''
                                    }`}
                                    key={subTransId}
                                    onMouseEnter={() => onTrigger(index)}
                                >
                                    {iconSVG && iconSVG}
                                    {iconUrl && (
                                        <HBMediaElement
                                            customClass="step-feature-block__sub-block__icon"
                                            name={iconUrl}
                                            forceImgTag
                                        />
                                    )}

                                    <HBText
                                        customClass="step-feature-block__sub-block__title"
                                        tag="h3"
                                        type="body-l"
                                    >
                                        {t(`${transId}.${subTransId}.title`)}
                                    </HBText>
                                    <HBText
                                        customClass="step-feature-block__sub-block__description"
                                        tag="p"
                                        type="body-m"
                                    >
                                        {t(
                                            `${transId}.${subTransId}.description`
                                        )}
                                    </HBText>
                                    <HBMediaElement
                                        customClass="step-feature-block__media"
                                        alt={
                                            t(`${transId}.imageAlt`, {
                                                defaultValue: null
                                            }) || t(`${transId}.title`)
                                        }
                                        name={imageUrl}
                                    />
                                </div>
                            );
                        }
                    )}

                    <RegistrationFormAsText
                        linkText={linkText}
                        source={t(`${transId}.preTitle`)}
                    />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default StepFeatureBlock;
