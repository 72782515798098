import Layout from '../components/Layout/Layout';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'react-i18next';
import { usePromoCode } from '../hooks/usePromoCode';

import FeatureHeroBlock from '../components/FeatureBlocks/FeatureHeroBlock/FeatureHeroBlock';
import EsignPreviewBlock from '../components/FeatureBlocks/EsignPreviewBlock/EsignPreviewBlock';
import FeatureTextBlock from '../components/FeatureBlocks/FeatureTextBlock/FeatureTextBlock';
import StepFeatureBlock from '../components/FeatureBlocks/StepFeatureBlock/StepFeatureBlock';
import FeatureTemplateGallery from '../components/FeatureBlockElements/FeatureTemplateGallery/FeatureTemplateGallery';
import TestimonialCarouselCardBlock from '../components/FeatureBlocks/TestimonialCarouselCardBlock/TestimonialCarouselCardBlock';
import FeatureSuperchargeBlock from '../components/FeatureBlocks/FeatureSuperchargeBlock/FeatureSuperchargeBlock';
import FeatureIntegrationBlock from '../components/FeatureBlocks/FeatureIntegrationBlock/FeatureIntegrationBlock';
import ExploreFeaturesBlock from '../components/FeatureBlocks/ExploreFeaturesBlock/ExploreFeaturesBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';

import BellIcon from '../svg/bell.inline.svg';
import CheckCircleIcon from '../svg/check-circle.inline.svg';
import ClockIcon from '../svg/clock.inline.svg';
import TemplatesIcon from '../svg/templates.inline.svg';
import SparksIcon from '../svg/sparks.inline.svg';

import '../styles/online-template.scss';
import { useMemo } from 'react';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';

const ogTags = {
    url: 'https://www.honeybook.com/online-document-signing',
    title: 'Free Online Document Signin',
    description:
        'Discover the ease and reliability of free online document signing through HoneyBook. Simplify your workflow using our intuitive platform for secure and legally binding contract signing.'
};

const stepBlocksOne = [
    {
        subTransId: 'browse',
        iconSVG: <TemplatesIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Browse_legally-vetted_templates.png'
    },
    {
        subTransId: 'autoFill',
        iconSVG: <SparksIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Auto-fill_client_details.png'
    }
];

const stepBlocksTwo = [
    {
        subTransId: 'schedule',
        iconSVG: <ClockIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Send_at_the_perfect_moment.png'
    },
    {
        subTransId: 'clientSuccess',
        iconUrl: 'marketing_site/online-contracts/templates/Team_avatars.png',
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Manage_client_access.png'
    }
];

const stepBlocksThree = [
    {
        subTransId: 'statusUpdates',
        iconSVG: <CheckCircleIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Receive_status_updates.png'
    },
    {
        subTransId: 'reminders',
        iconSVG: <BellIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Automate_gentle_reminders.png'
    }
];

const faqs = [
    { id: 'whatIsESig' },
    { id: 'eSigLegal' },
    {
        id: 'howESig',
        answer: '#TEXT# <a href="/online-contract">#LINK_TEXT#</a> #TEXT# <a href="/online-invoices">#LINK_TEXT#</a> #TEXT# <a href="/online-payment-software">#LINK_TEXT#</a> #TEXT#'
    },
    { id: 'eSigSecure', answer: '#TEXT# <a href="/">#LINK_TEXT#</a> #TEXT#' },
    {
        id: 'customDocuments',
        answer: '#TEXT# <a href="/templates/category/contract-templates">#LINK_TEXT#</a> #TEXT#'
    },
    { id: 'scheduleDocs' },
    { id: 'trackDocs' },
    { id: 'remindClients' }
];

const integrationsIcons = {
    outer: [
        'marketing_site/online-contracts/integration/outer_1_2x.png',
        'marketing_site/online-contracts/integration/outer_2_2x.png',
        'marketing_site/online-contracts/integration/outer_3_2x.png',
        'marketing_site/online-contracts/integration/outer_4_2x.png',
        'marketing_site/online-contracts/integration/outer_5_2x.png',
        'marketing_site/online-contracts/integration/outer_6_2x.png'
    ],
    inner: [
        'marketing_site/online-contracts/integration/inner_1_2x.png',
        'marketing_site/online-contracts/integration/inner_2_2x.png',
        'marketing_site/online-contracts/integration/inner_3_2x.png',
        'marketing_site/online-contracts/integration/inner_4_2x.png'
    ]
};

const exploreLinksContent = [
    {
        iconUrl: 'marketing_site/online-contracts/explore/Automations.png',
        text: 'Automations',
        linkUrl: '/automations'
    },
    {
        iconUrl: 'marketing_site/online-contracts/explore/Scheduler.png',
        text: 'Scheduler',
        linkUrl: '/meeting-scheduler'
    },
    {
        iconUrl: 'marketing_site/online-contracts/explore/Invoices.png',
        text: 'Invoices',
        linkUrl: '/online-invoices'
    },
    {
        iconUrl: 'marketing_site/online-contracts/explore/Projects.png',
        text: 'Projects',
        linkUrl: '/all-in-one-business-platform'
    },
    {
        iconUrl: 'marketing_site/online-contracts/explore/Inquiries.png',
        text: 'Inquiries',
        linkUrl: '/all-in-one-business-platform'
    },
    {
        iconUrl: 'marketing_site/online-contracts/explore/Payments.png',
        text: 'Payments',
        linkUrl: '/online-payment-software'
    },
    {
        iconUrl: 'marketing_site/online-contracts/explore/Forms.png',
        text: 'Forms',
        linkUrl: '/all-in-one-business-platform'
    }
];

function Esign(): JSX.Element {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    const stats = useMemo(
        () => [
            {
                title: <Trans>{t('esignStatsAnimation.signed.title')}</Trans>,
                subtitle: (
                    <Trans>{t('esignStatsAnimation.signed.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('esignStatsAnimation.value.title')}</Trans>,
                subtitle: (
                    <Trans>{t('esignStatsAnimation.value.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('esignStatsAnimation.daily.title')}</Trans>,
                subtitle: (
                    <Trans>{t('esignStatsAnimation.daily.subtitle')}</Trans>
                )
            }
        ],
        []
    );

    return (
        <Layout ogTags={ogTags} mobileStickyCTA>
            <div className="online-template online-template--esign">
                <FeatureHeroBlock
                    label={t('hero.label')}
                    title={t('hero.title')}
                    subtitle={t('hero.subtitle')}
                    stats={stats}
                />
                <EsignPreviewBlock />
                <FeatureTextBlock
                    title={t('textBlock.title')}
                    imageUrl="marketing_site/online-contracts/Contract.png"
                    showScrollAnimation
                />
                <StepFeatureBlock
                    transId="stepsFeatureBlock.step1"
                    stepSubBlocks={stepBlocksOne}
                    linkText={t('stepsFeatureBlock.linkText')}
                />
                <StepFeatureBlock
                    reverse
                    transId="stepsFeatureBlock.step2"
                    stepSubBlocks={stepBlocksTwo}
                    linkText={t('stepsFeatureBlock.linkText')}
                />
                <StepFeatureBlock
                    transId="stepsFeatureBlock.step3"
                    stepSubBlocks={stepBlocksThree}
                    linkText={t('stepsFeatureBlock.linkText')}
                />
                <FeatureTemplateGallery ctaSource="esign (seo) template gallery footer" />
                <TestimonialCarouselCardBlock />
                <FeatureSuperchargeBlock isLinkedCard={false} />
                <FeatureIntegrationBlock
                    title={t('featureIntegrationBlock.title')}
                    subtitle={t('featureIntegrationBlock.subtitle')}
                    icons={integrationsIcons}
                />
                <FAQBlock
                    customClass="online-template__faq"
                    title={t('FaqBlock.title')}
                    faqs={faqs}
                />
                <ExploreFeaturesBlock
                    title={t('exploreFeaturesBlock.title')}
                    linksContent={exploreLinksContent}
                />
                <PromoBlockLeft
                    customClass="online-template__promo-block-left promo-block-left--yellow"
                    title={t(`${promoCode}.promoBlockLeft.title`)}
                    subtitle={t(`${promoCode}.promoBlockLeft.subtitle`, {
                        defaultValue: null
                    })}
                    source="promo block left - yellow"
                    promoCode={promoCode}
                />
            </div>
        </Layout>
    );
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "onlineContract", "esign", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default Esign;
