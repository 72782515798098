import { useEffect, useRef } from 'react';
import type { LegacyRef } from 'react';
import UtilsService from '../../../Services/UtilsService';
import HBText from '@honeybook-ui/HBText/HBText';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import Star from '../../../svg/Star.inline.svg';

import './feature-text-block.scss';

const SIGNATURE_LENGTH = 2254;

interface Props {
    title: string;
    imageUrl: string;
    showScrollAnimation?: boolean;
}

const FeatureTextBlock = ({
    title,
    imageUrl,
    showScrollAnimation = false
}: Props) => {
    const isVisible = useRef(false);
    const signatureRef: LegacyRef<any> = useRef();

    const scrollHandler = () => {
        if (showScrollAnimation && isVisible.current) {
            const sectionYPos =
                signatureRef.current.getBoundingClientRect().top /
                window.innerHeight;
            if (sectionYPos < 0 || sectionYPos > 1) return;

            signatureRef.current.style.strokeDashoffset =
                sectionYPos * SIGNATURE_LENGTH;
        }
    };

    useEffect(() => {
        if (showScrollAnimation) {
            const observer = new IntersectionObserver(entries => {
                isVisible.current = !!entries[0].isIntersecting;
            });
            observer.observe(signatureRef.current);
            window.addEventListener(
                'scroll',
                UtilsService.throttle(() => {
                    scrollHandler();
                }, 20)
            );
            return () => {
                window.removeEventListener('scroll', scrollHandler);
            };
        }
    }, []);

    return (
        <FeatureBlockSection customClass="feature-text-block">
            <FeatureBlockContent>
                <HBMediaElement
                    customClass="feature-text-block__contract-img"
                    name={imageUrl}
                    forceImgTag
                />
                <Star className="feature-text-block__star feature-text-block__star--1" />
                <Star className="feature-text-block__star feature-text-block__star--2" />
                <Star className="feature-text-block__star feature-text-block__star--3" />
                <Star className="feature-text-block__star feature-text-block__star--4" />
                <Star className="feature-text-block__star feature-text-block__star--5" />
                <HBText
                    customClass="feature-text-block__title"
                    tag="h1"
                    type="title-l"
                >
                    {title}
                </HBText>
            </FeatureBlockContent>
            {showScrollAnimation && (
                <svg
                    width="1280"
                    height="440"
                    viewBox="0 0 1280 440"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="feature-text-block__signature"
                    ref={signatureRef}
                    style={{
                        strokeDasharray: SIGNATURE_LENGTH,
                        strokeDashoffset: SIGNATURE_LENGTH
                    }}
                >
                    <path
                        d="M-59 212.469C-1.4 247.669 73 358 382 296.351C481 271.187 654.25 178.913 662.5 78.2602C670.75 -22.3921 547 19.5359 514 111.808C481 204.08 472.75 321.516 588.25 296.351C703.75 271.187 728.5 145.365 736.75 212.469C743.913 270.733 728.5 321.516 778 296.351C827.5 271.187 852.25 262.801 893.5 296.351C962.5 362.068 1048 472 1330 393.5"
                        stroke="#2E323A"
                        strokeWidth="38.0904"
                    />
                </svg>
            )}
        </FeatureBlockSection>
    );
};

export default FeatureTextBlock;
