import { memo, useCallback } from 'react';
import classNames from 'classnames';

// import { removeNonHTMLProps } from "../../common/props";

import type { MouseEvent as ReactMouseEvent, ReactNode } from 'react';
import type { TabId } from './tab';

export function generateTabPanelId(tabId: TabId) {
    return `bp3-tab-panel_${tabId}`;
}

export function generateTabTitleId(tabId: TabId) {
    return `bp3-tab-title_${tabId}`;
}

interface Props {
    id: TabId;
    title?: string;
    /** Handler invoked when this tab is clicked. */
    onClick: (id: TabId, event: ReactMouseEvent<HTMLElement>) => void;

    /** Whether the tab is currently selected. */
    selected: boolean;

    className?: string;

    children?: ReactNode;
}

function TabTitle({
    className,
    children,
    id,
    selected,
    title,
    onClick
}: Props): JSX.Element {
    const handleClick = useCallback(
        (event: ReactMouseEvent<HTMLElement>) => {
            onClick(id, event);
        },
        [onClick, id]
    );

    return (
        <div
            aria-controls={generateTabPanelId(id)}
            aria-disabled={false}
            aria-expanded={selected}
            aria-selected={selected}
            className={classNames(`bp3-tab`, className)}
            data-tab-id={id}
            id={generateTabTitleId(id)}
            onClick={handleClick}
            role="tab"
            tabIndex={0}
        >
            {title}
            {children}
        </div>
    );
}

export default memo(TabTitle);
