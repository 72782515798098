import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../hooks/useWindowSize';
import HBText from '../../honeybook-ui/HBText/HBText';
import HBVideo from '../../honeybook-ui/HBVideo/HBVideo';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import type { TAiPanelData } from './IntroducingAiBlock';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

import './introducing-ai-tab.scss';

const MEDIA_SIZE = {
    xLargeUp: {
        height: 460,
        width: 582
    },
    largeUp: {
        height: 370,
        width: 470
    },
    mediumUp: {
        height: 417,
        width: 528
    },
    small: {
        height: 283,
        width: 359
    }
};

const IntroducingAiTab = ({ videoId, imgUrl, transKey }: TAiPanelData) => {
    const { isXLargeUp, isLargeUp, isMediumUp } = useWindowSize();
    const { t } = useTranslation();

    let mediaSizeKey: string;
    if (isXLargeUp) {
        mediaSizeKey = 'xLargeUp';
    } else if (isLargeUp) {
        mediaSizeKey = 'largeUp';
    } else if (isMediumUp) {
        mediaSizeKey = 'mediumUp';
    } else {
        mediaSizeKey = 'small';
    }

    return (
        <div className={`introducing-ai-tab introducing-ai-tab--${transKey}`}>
            <div className="introducing-ai-tab__content">
                <div className="introducing-ai-tab__header">
                    <HBText
                        customClass="introducing-ai-tab__title"
                        tag="h4"
                        type="title-s"
                    >
                        {t(`introducingAiBlock.tabs.${transKey}.title`)}
                    </HBText>
                    <div className="introducing-ai-tab__label">
                        {t(`introducingAiBlock.tabs.${transKey}.label`)}
                    </div>
                </div>

                <HBText
                    customClass="introducing-ai-tab__subtitle"
                    tag="h4"
                    type="body-xl"
                >
                    {t(`introducingAiBlock.tabs.${transKey}.subtitle`)}
                </HBText>

                <RegistrationForm
                    customClass="introducing-ai-tab__cta"
                    source={`introducing-ai-block ${transKey}`}
                    size="large"
                />
            </div>
            {!!videoId && (
                <HBVideo
                    customClass="introducing-ai-tab__media introducing-ai-tab__media--video"
                    videoId={videoId}
                    autoplay
                    loop
                    controls={false}
                    height={MEDIA_SIZE[mediaSizeKey].height}
                    width={MEDIA_SIZE[mediaSizeKey].width}
                />
            )}
            {!!imgUrl && (
                <HBMediaElement
                    customClass="introducing-ai-tab__media introducing-ai-tab__media--image"
                    name={imgUrl}
                />
            )}
        </div>
    );
};

export default IntroducingAiTab;
