import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY } from '../../constants/constants';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';
import StorageService from '../../Services/StorageService';
import UtilsService from '../../Services/UtilsService';
import { TAdditionalDataForClickStartTrialModules } from '../../types/util-types';
import type { TFeaturesCheckListAnalyticsData } from '../Hero/FeatureChecklistHero/constants-and-types';
import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../honeybook-ui/HBText/HBText';
import RegistrationForm from '../RegistrationForm/RegistrationForm';

import './intent-checkbox-list.scss';

interface IIntentChecklistItem {
    id: string;
    transKey: string;
    iconUrl: string;
}

interface Props {
    title: string;
}

const IntentCheckboxList = ({ title }: Props) => {
    const { t } = useTranslation();
    const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
    const [isCtaAnimationOn, setIsCtaAnimationOn] = useState(false);
    const intentCheckboxButtons: IIntentChecklistItem[] = useMemo(
        () => [
            {
                id: 'projects',
                transKey: 'projects',
                iconUrl:
                    'marketing_site/icons/checkboxes/intentHero/Briefcase_2x.png'
            },
            {
                id: 'leads',
                transKey: 'leads',
                iconUrl: 'marketing_site/crm-page/magnet.png'
            },
            {
                id: 'clients',
                transKey: 'clients',
                iconUrl: 'marketing_site/crm-page/avatar.png'
            },
            {
                id: 'book',
                transKey: 'book',
                iconUrl:
                    'marketing_site/icons/checkboxes/intentHero/Inquiries_2x.png'
            },
            {
                id: 'contracts',
                transKey: 'contracts',
                iconUrl: 'marketing_site/crm-page/signature.png'
            },
            {
                id: 'payments',
                transKey: 'payments',
                iconUrl: 'marketing_site/crm-page/dollar.png'
            }
        ],
        []
    );

    useEffect(() => {
        const additionalData =
            StorageService.getItem(
                CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY,
                'sessionStorage'
            ) || {};
        const additionalDataPerPath =
            additionalData?.[window.location.pathname] || {};

        const selectedFeaturesPerModule:
            | TFeaturesCheckListAnalyticsData
            | undefined =
            additionalDataPerPath[
                TAdditionalDataForClickStartTrialModules.CHECK_LIST_CRM_PAGE
            ];

        if (selectedFeaturesPerModule) {
            if (selectedFeaturesPerModule.intent_selected !== 'none') {
                setSelectedItemIds(
                    selectedFeaturesPerModule.intent_selected.split(',')
                );
            }
        } else {
            UtilsService.addAdditionalDataToClickStartTrial(
                {
                    intent_selected: 'none',
                    intent_selected_count: 0
                },
                TAdditionalDataForClickStartTrialModules.CHECK_LIST_CRM_PAGE
            );
        }
    }, []);

    const handleSelectItem = useCallback(
        (itemId: string) => {
            let status;
            let tempSelectedItemIds;
            if (selectedItemIds.includes(itemId)) {
                tempSelectedItemIds = selectedItemIds.filter(
                    selectedItemId => selectedItemId !== itemId
                );
                status = 'off';
            } else {
                tempSelectedItemIds = [...selectedItemIds, itemId];
                status = 'on';
                animateButton();
            }
            setSelectedItemIds(tempSelectedItemIds);
            AnalyticsService.trackClick(
                AnalyticsEvents.feature_checklist_select_item,
                {
                    type: itemId,
                    status,
                    variant: 'rebrand',
                    source: window.location.pathname
                }
            );
            const additionalData = {
                intent_selected: tempSelectedItemIds.join() || 'none',
                intent_selected_count: tempSelectedItemIds.length
            };
            UtilsService.addAdditionalDataToClickStartTrial(
                additionalData,
                TAdditionalDataForClickStartTrialModules.CHECK_LIST_CRM_PAGE
            );
        },
        [selectedItemIds]
    );

    const animateButton = () => {
        setIsCtaAnimationOn(true);
        setTimeout(() => {
            setIsCtaAnimationOn(false);
        }, 600);
    };

    return (
        <div className="intent-checkbox-list">
            <HBText
                customClass="intent-checkbox-list__title"
                tag="p"
                type="body-m"
            >
                {title}
            </HBText>
            <div className="intent-checkbox-list__list">
                {intentCheckboxButtons.map(intentCheckboxItem => (
                    <IntentCheckboxItem
                        key={intentCheckboxItem.id}
                        id={intentCheckboxItem.id}
                        label={t(
                            `intentCheckboxList.${intentCheckboxItem.transKey}`
                        )}
                        iconUrl={intentCheckboxItem.iconUrl}
                        isSelected={selectedItemIds.includes(
                            intentCheckboxItem.id
                        )}
                        onSelectItem={handleSelectItem}
                    />
                ))}
            </div>
            <RegistrationForm
                customClass={`intent-checkbox-list__cta ${
                    isCtaAnimationOn
                        ? 'intent-checkbox-list__cta--animated'
                        : ''
                }`}
                source="crm hero block"
                buttonStyle="primary"
                size="large"
                showNoCcRequired
            />
        </div>
    );
};

export default IntentCheckboxList;

const IntentCheckboxItem = ({
    id,
    iconUrl,
    label,
    isSelected,
    onSelectItem
}) => {
    return (
        <div
            className={`intent-checkbox-item intent-checkbox-item--${id} ${
                isSelected ? 'intent-checkbox-item--selected' : ''
            }`}
            onClick={() => onSelectItem(id)}
        >
            <HBMediaElement
                customClass="intent-checkbox-item__icon"
                name={iconUrl}
            />
            <p className="intent-checkbox-item__label">{label}</p>
            <div className="intent-checkbox-item__checkbox">
                {isSelected && (
                    <HBMediaElement
                        customClass="intent-checkbox-item__check"
                        name="marketing_site/crm-page/check.svg"
                    />
                )}
            </div>
        </div>
    );
};
