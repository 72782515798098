import * as PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { InputStyles } from '@honeybook-ui/HBInput/HBInput';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import ImageTextElement from '../../ImageTextElement/ImageTextElement';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import InvoiceGeneratorFreeInvoiceBlockStore from './InvoiceGeneratorFreeInvoiceBlockStore';
import { TRANSLATION_COMPONENTS } from '../../../constants/constants';

import './invoice-generator-free-invoice-block.scss';

const { images } = InvoiceGeneratorFreeInvoiceBlockStore;

const onClickInvoiceGenerator = () => {
    AnalyticsService.trackClick(
        AnalyticsEvents.lp_invoice_generator_free_invoice
    );
};

const InvoiceGeneratorFreeInvoiceBlock = ({
    contentKey,
    registrationSource
}) => {
    const { t, i18n } = useTranslation();

    const titleKey = i18n.exists(
        `invoiceGeneratorPages.${contentKey}.freeInvoice.title`
    )
        ? `invoiceGeneratorPages.${contentKey}.freeInvoice.title`
        : 'invoiceGeneratorPages.generic.freeInvoice.title';

    const softwareAltKey = i18n.exists(
        `invoiceGeneratorPages.${contentKey}.freeInvoice.software.imageAlt`
    )
        ? `invoiceGeneratorPages.${contentKey}.freeInvoice.software.imageAlt`
        : 'invoiceGeneratorPages.generic.freeInvoice.title';

    const generatorAltKey = i18n.exists(
        `invoiceGeneratorPages.${contentKey}.freeInvoice.generator.imageAlt`
    )
        ? `invoiceGeneratorPages.${contentKey}.freeInvoice.generator.imageAlt`
        : 'invoiceGeneratorPages.generic.freeInvoice.title';

    return (
        <div id="free-invoice">
            <FeatureBlockSection customClass="invoice-generator-free-invoice">
                <FeatureBlockContent>
                    <FeatureBlockText
                        fullWidth
                        customClass="invoice-generator-free-invoice__title"
                        primary={t(titleKey)}
                    />
                    <div className="invoice-generator-free-invoice__container">
                        <div className="invoice-generator-free-invoice__column ">
                            <ImageTextElement
                                key="software"
                                imagePath={images[contentKey].software}
                                description={
                                    <Trans
                                        i18nKey={`invoiceGeneratorPages.${contentKey}.freeInvoice.software.description`}
                                        components={TRANSLATION_COMPONENTS}
                                    />
                                }
                                mediaFlags={{
                                    alt: t(softwareAltKey),
                                    width: 500,
                                    crop: 'scale',
                                    lazyLoad: false
                                }}
                            >
                                <h2 className="image-text-element__title">
                                    {t(
                                        `invoiceGeneratorPages.${contentKey}.freeInvoice.software.title`
                                    )}{' '}
                                    {contentKey !== 'generic' && (
                                        <span className="image-text-element__title--highlight">
                                            {t(
                                                `invoiceGeneratorPages.generic.freeInvoice.software.title`
                                            )}
                                        </span>
                                    )}
                                </h2>
                            </ImageTextElement>
                            <RegistrationForm
                                customClass="invoice-generator-free-invoice__cta"
                                source={registrationSource}
                                inputStyle={InputStyles.SIMPLE_INPUT_DARK}
                                showNoCcRequired
                                buttonStyle="primary--dark"
                                buttonText={t(
                                    'invoiceGeneratorPages.generic.freeInvoice.primaryCTAText'
                                )}
                            />
                        </div>

                        <div className="invoice-generator-free-invoice__column ">
                            <ImageTextElement
                                key="generator"
                                imagePath={images[contentKey].generator}
                                description={t(
                                    `invoiceGeneratorPages.${contentKey}.freeInvoice.generator.description`
                                )}
                                mediaFlags={{
                                    alt: t(generatorAltKey),
                                    height: 800,
                                    crop: 'scale',
                                    lazyLoad: false
                                }}
                            >
                                <h2 className="image-text-element__title">
                                    {t(
                                        `invoiceGeneratorPages.${contentKey}.freeInvoice.generator.title`
                                    )}{' '}
                                    {contentKey !== 'generic' && (
                                        <span className="image-text-element__title--highlight">
                                            {t(
                                                `invoiceGeneratorPages.generic.freeInvoice.generator.title`
                                            )}
                                        </span>
                                    )}
                                </h2>
                            </ImageTextElement>
                            <a
                                onClick={onClickInvoiceGenerator}
                                href="/invoice-template"
                                className="invoice-generator-free-invoice__cta hbui-button hbui-button--medium hbui-button--plain-primary"
                            >
                                {t(
                                    'invoiceGeneratorPages.generic.freeInvoice.secondaryCTAText'
                                )}
                            </a>
                        </div>
                    </div>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

InvoiceGeneratorFreeInvoiceBlock.propTypes = {
    contentKey: PropTypes.string,
    registrationSource: PropTypes.string
};

export default InvoiceGeneratorFreeInvoiceBlock;
