import { memo, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import ImageTextElement from '../ImageTextElement/ImageTextElement';

import './checkbox-tab-content.scss';
import { TRANSLATION_COMPONENTS } from '../../constants/constants';

interface Props {
    bulletsString?: string;
}

const mediaFlags = { forceImgTag: true, lazyLoad: false };

function CheckboxTabContent({ bulletsString }: Props): JSX.Element {
    const { t } = useTranslation();

    const bullets = useMemo(
        () => Object.keys(t(`${bulletsString}`, { returnObjects: true })),
        [bulletsString, t]
    );

    return (
        <div className="checkbox-tab-content">
            {bullets.map((bullet: string): JSX.Element => {
                return (
                    <ImageTextElement
                        key={bullet}
                        imagePath="marketing_site/online-payments/Checkbox.svg"
                        imagePosition="left"
                        title={t(`${bulletsString}.${bullet}.title`)}
                        description={
                            <Trans
                                i18nKey={`${bulletsString}.${bullet}.subTitle`}
                                components={TRANSLATION_COMPONENTS}
                            />
                        }
                        mediaFlags={mediaFlags}
                    />
                );
            })}
        </div>
    );
}

CheckboxTabContent.propTypes = {
    bulletsString: PropTypes.string
};

export default memo(CheckboxTabContent);
