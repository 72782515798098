import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Layout from '../components/Layout/Layout';
import HBButton from '@honeybook-ui/HBButton/HBButton';
import BusinessPersonalityHero from '../components/Hero/BusinessPersonalityHero/BusinessPersonalityHero';
import ImageTextElementsBlock from '../components/FeatureBlocks/ImageTextElementsBlock/ImageTextElementsBlock';
import TipsBlock from '../components/FeatureBlocks/TipsBlock/TipsBlock';
import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';
import BlogLinkBlock from '../components/BlogLinkBlock/BlogLinkBlock';

import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';

import '../styles/business-personality.scss';

const ctaTextUrl = 'https://form.typeform.com/to/fVM5V1';

const personalityTypes = [
    {
        title: 'featureBlock.personalityTypes.types.focus.title',
        text: 'featureBlock.personalityTypes.types.focus.text'
    },
    {
        title: 'featureBlock.personalityTypes.types.action.title',
        text: 'featureBlock.personalityTypes.types.action.text'
    },
    {
        title: 'featureBlock.personalityTypes.types.growth.title',
        text: 'featureBlock.personalityTypes.types.growth.text'
    }
];

const blogLinks = [
    {
        titleId: 'businessPersonalityPage.blogLinks.blogs.tasks.title',
        imagePath: 'marketing_site/business_personality_tool/blog/ceerica.png',
        url: '/blog/how-graphic-designer-ceerica-booked-more-clients'
    },
    {
        titleId: 'businessPersonalityPage.blogLinks.blogs.business.title',
        imagePath:
            'marketing_site/business_personality_tool/blog/jess_bejot.png',
        url: '/blog/jess-used-honeybook-to-organize-her-web-graphic-design-business'
    },
    {
        titleId: 'businessPersonalityPage.blogLinks.blogs.bookings.title',
        imagePath:
            'marketing_site/business_personality_tool/blog/john_branch.png',
        url: '/blog/how-i-booked-30-more-client-meetings-with-a-scheduling-tool'
    }
];

const faqs = [
    {
        id: 'bizToolResults',
        answer: ''
    },
    {
        id: 'bizToolType',
        answer: ''
    },
    {
        id: 'bizToolCost',
        answer: ''
    },
    {
        id: 'bizToolTime',
        answer: ''
    },
    {
        id: 'bizToolCreated',
        answer: ''
    },
    {
        id: 'bizToolHB',
        answer: '#TEXT# <a href="/">#TEXT_LINK#</a> #TEXT# <a href="/features">#LINK_TEXT#</a> #TEXT# <a href="/invoices">#LINK_TEXT#</a> #TEXT# <a href="/online-contract">#LINK_TEXT#</a> #TEXT# <a href="/proposal-software">#LINK_TEXT#</a> #TEXT# <a href="/online-payments">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'bizToolHelps',
        answer: '#TEXT# <a href="/">#TEXT_LINK#</a> #TEXT# <a href="/blog"> #TEXT#'
    },
    {
        id: 'bizToolMyersBriggs',
        answer: '#TEXT# <a href="https://www.myersbriggs.org/my-mbti-personality-type/">#LINK_TEXT#</a> #TEXT# < href="https://growthlab.com/what-type-of-business-you-should-start-based-on-your-myers-briggs-type/">#LINK_TEXT#</> #TEXT#'
    },
    {
        id: 'bizToolBasedOn',
        answer: '#TEXT# <a href="http://thoughtensemble.com/personalities-and-communication-mbti-vs-the-quadrants/">#LINK_TEXT#</a>'
    },
    {
        id: 'bizToolRetake',
        answer: ''
    }
];

const ogTags = {
    url: '/business-personality-test',
    title: 'Free Business Personality Test',
    description:
        'Try our free business personality test and get small business tips that are recommended for you. Uncover your superpower and take action.'
};

const handleCtaClick = analytisProperties => {
    AnalyticsService.trackClick(AnalyticsEvents.biz_test_cta, {
        ...analytisProperties
    });

    window.location.href = ctaTextUrl;
};

function BusinessPersonalityTemplate(): JSX.Element {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    return (
        <Layout ogTags={ogTags}>
            <div className="biz-quiz-lp">
                <BusinessPersonalityHero />

                <div className="cta-row cta-row--hero">
                    <HBButton
                        size="large"
                        customClass="biz-quiz-lp__test-button"
                        onClick={() => handleCtaClick({ position: 'hero' })}
                        text={t('businessPersonalityPage.ctaText')}
                    />
                    <p className="cta-row__time">
                        {t('businessPersonalityPage.ctaSubText')}
                    </p>
                </div>

                {breakpoints.mediumUp && (
                    <ImageTextElementsBlock
                        customClass="personality-types"
                        title={t('featureBlock.personalityTypes.title')}
                        subtitle={t('featureBlock.personalityTypes.subtitle')}
                        imageTextElements={personalityTypes}
                    />
                )}

                {breakpoints.bptestQuery && <TipsBlock lightBackground />}

                <BlogLinkBlock
                    title={t('businessPersonalityPage.blogLinks.title')}
                    subtitle={t('businessPersonalityPage.blogLinks.subtitle')}
                    blogs={blogLinks}
                />

                <FAQBlock
                    title={t('businessPersonalityPage.faqTitle')}
                    faqs={faqs}
                />
            </div>
        </Layout>
    );
}

export default BusinessPersonalityTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "businessPersonality", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
