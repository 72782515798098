import AnalyticsService, { AnalyticsEvents } from './AnalyticsService';

export default class CloudflareBannerService {
    static hideAndTrackBanner = (): void => {
        setTimeout(() => {
            if (this.isBannerShown()) {
                this.hideBanner();
                AnalyticsService.track(AnalyticsEvents.cloudlflare_banner, {
                    bannerMessage: this.getBannerMessage()
                });
            }
        }, 0);
    };

    static getBanner = () => {
        return document?.getElementById('cf_alert_div');
    };

    static getBannerMessage = () => {
        const banner = this.getBanner();
        return banner?.getElementsByClassName('content')?.[0]?.children?.[0]
            ?.innerHTML;
    };

    static isBannerShown = () => {
        const banner = this.getBanner();
        return !!banner && banner.style.display !== 'none';
    };

    static hideBanner = () => {
        const banner = this.getBanner();
        if (banner) {
            banner.style.display = 'none';
        }
    };
}
