import { Trans, useTranslation } from 'react-i18next';
import HBText from '@honeybook-ui/HBText/HBText';
import { TRANSLATION_COMPONENTS } from '../../../constants/constants';
import type { ReactNode } from 'react';
import STATE_SPECIFIC_CONTENT from './TaxFAQBlockStore';

import './tax-faq-block.scss';

import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';

const taxFaqs = [
    'taxWhatIs',
    'taxHaveTo',
    'taxCalculator',
    'taxRate',
    'taxDeductions',
    'taxNotPay',
    'taxDifference',
    'taxPay',
    'taxStates',
    'deductionStates'
];

interface TaxFAQProps {
    question: string;
    answer: string;
    children?: ReactNode;
}

const TaxFAQ = ({ question, answer, children }: TaxFAQProps) => {
    return (
        <div className="tax-faq-block__faq">
            <HBText
                customClass="tax-faq-block__faq__question"
                tag="h2"
                type="title-s"
            >
                {question}
            </HBText>
            <HBText
                tag="div"
                type="body-s"
                customClass="tax-faq-block__faq__answer"
            >
                <Trans i18nKey={answer} components={TRANSLATION_COMPONENTS} />
            </HBText>
            {children && (
                <div className="tax-faq-block__faq__children">{children}</div>
            )}
        </div>
    );
};

interface Props {
    USState: string;
}

const TaxFAQBlock = ({ USState }: Props): JSX.Element => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="tax-faq-block">
            <FeatureBlockContent>
                <HBText
                    tag="h1"
                    type="title-l"
                    customClass="tax-faq-block__title"
                >
                    {USState && t(`states.${USState}`)} {t('TaxFAQBlock.title')}
                </HBText>
                {taxFaqs.map(faq => (
                    <TaxFAQ
                        key={faq}
                        question={t(`faqs.${faq}.question`)}
                        answer={`faqs.${faq}.answer`}
                    />
                ))}
                {USState && (
                    <div className="tax-faq-block__faq">
                        <TaxFAQ
                            question={t(
                                `TaxFAQBlock.states.${USState}.question`
                            )}
                            answer={t(`TaxFAQBlock.states.${USState}.answer`)}
                        >
                            <table id="Table">
                                <caption>
                                    2023 {t('TaxFAQBlock.table.caption')}
                                </caption>
                                <thead>
                                    <tr>
                                        <th colSpan={12}>
                                            {t('TaxFAQBlock.table.header')}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th colSpan={3}>
                                            {t(
                                                'TaxFAQBlock.table.singleFiling'
                                            )}
                                        </th>
                                        <th colSpan={3}>
                                            {t(
                                                'TaxFAQBlock.table.marriedFiling'
                                            )}
                                        </th>
                                        <th colSpan={2}>
                                            {t('TaxFAQBlock.table.standard')}
                                        </th>
                                        <th colSpan={3}>
                                            {t('TaxFAQBlock.table.personal')}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>{t('TaxFAQBlock.table.state')}</th>
                                        <th>{t('TaxFAQBlock.table.rates')}</th>
                                        <th></th>
                                        <th>
                                            {t('TaxFAQBlock.table.brackets')}
                                        </th>
                                        <th>{t('TaxFAQBlock.table.rates')}</th>
                                        <th></th>
                                        <th>
                                            {t('TaxFAQBlock.table.brackets')}
                                        </th>
                                        <th>{t('TaxFAQBlock.table.single')}</th>
                                        <th>{t('TaxFAQBlock.table.couple')}</th>
                                        <th>{t('TaxFAQBlock.table.single')}</th>
                                        <th>{t('TaxFAQBlock.table.couple')}</th>
                                        <th>
                                            {t('TaxFAQBlock.table.dependent')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{STATE_SPECIFIC_CONTENT[USState]}</tbody>
                            </table>
                        </TaxFAQ>
                    </div>
                )}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default TaxFAQBlock;
