const OneBusinessPlatformStore = {
    blockContent: [
        {
            id: 'blockOne',
            images: [
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-one__main',
                    publicId:
                        'marketing_site/lp/all-in-one/mobile/pipeline_3x.png',
                    width: 529,
                    altTransId:
                        'oneBusinessPlatform.blockContent.blockOne.altPipeline'
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-one__second',
                    publicId:
                        'marketing_site/lp/all-in-one/mobile/notification_3x.png',
                    width: 263,
                    altTransId:
                        'oneBusinessPlatform.blockContent.blockOne.altNotification'
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-one__third',
                    publicId:
                        'marketing_site/lp/all-in-one/mobile/notification-icon_3x.png',
                    width: 50,
                    altTransId:
                        'oneBusinessPlatform.blockContent.blockOne.altNotification'
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-one__fourth',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img1/Keep_track.png',
                    width: 120,
                    altTransId:
                        'oneBusinessPlatform.blockContent.blockOne.altKeepTrack'
                },
                {
                    customClass: 'one-business-platform-block__img--mobile',
                    publicId:
                        'marketing_site/lp/all-in-one/mobile/pipeline-1_2x.png',
                    width: 306,
                    altTransId:
                        'oneBusinessPlatform.blockContent.blockOne.altMobile'
                }
            ]
        },
        {
            id: 'blockTwo',
            images: [
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__main',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/files-tab_2x.png',
                    width: 493,
                    altTransId:
                        'oneBusinessPlatform.blockContent.blockTwo.altFiles'
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__second',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/phone-stand_2x.png',
                    width: 350,
                    altTransId:
                        'oneBusinessPlatform.blockContent.blockTwo.altPhone'
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__second-a',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/photo_2x.png',
                    width: 114
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__second-b',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/attach_2x.png',
                    width: 131
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__second-c',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/quest_2x.png',
                    width: 131
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__second-d',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/brouch_2x.png',
                    width: 117
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__second-e',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/plus-phone_2x.png',
                    width: 34
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-two__third',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img2/files.png',
                    width: 80
                },
                {
                    customClass: 'one-business-platform-block__img--mobile',
                    publicId:
                        'marketing_site/lp/all-in-one/mobile/files-mobile-2_2x.png',
                    width: 306,
                    altTransId:
                        'oneBusinessPlatform.blockContent.blockTwo.altMobile'
                }
            ]
        },
        {
            id: 'blockThree',
            images: [
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-three__main',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img3/files-tab_2x.png',
                    width: 493,
                    altTransId:
                        'oneBusinessPlatform.blockContent.blockThree.files'
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-three__second',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img3/toast_2x.png',
                    width: 356
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-three__third',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img3/communication.png',
                    width: 80,
                    altTransId:
                        'oneBusinessPlatform.blockContent.blockThree.altCommunication'
                },
                {
                    customClass: 'one-business-platform-block__img--mobile',
                    publicId:
                        'marketing_site/lp/all-in-one/mobile/client-communication-03_2x.png',
                    width: 306
                }
            ]
        },
        {
            id: 'blockFour',
            images: [
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-four__main',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img4/agreement-template02_2x.png',
                    width: 407,
                    altTransId:
                        'oneBusinessPlatform.blockContent.blockFour.altProposal'
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-four__second',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img4/phone-stand_2x.png',
                    width: 350
                },
                {
                    customClass:
                        'one-business-platform-block__img--desk one-business-platform-block__img-block-four__third',
                    publicId:
                        'marketing_site/lp/all-in-one/desktop/animate/img4/Coins_2x.png',
                    width: 80
                },
                {
                    customClass: 'one-business-platform-block__img--mobile',
                    publicId:
                        'marketing_site/lp/all-in-one/mobile/bookings-payments-4_2x.png',
                    width: 306
                }
            ]
        }
    ]
};

export default OneBusinessPlatformStore;
