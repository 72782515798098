import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import RegistrationFormAsText from '../../RegistrationFormAsText/RegistrationFormAsText';

import './pictime-block.scss';

interface CTAButtonProps {
    isMobile?: boolean;
}

const CTAButton = ({ isMobile = false }: CTAButtonProps) => (
    <div
        className={`pictime-block__buttons-container ${
            isMobile ? 'pictime-block__buttons-container--mobile' : ''
        }`}
    >
        <RegistrationFormAsText source="Pictime block" size="large" />
    </div>
);

const PictimeBlock = () => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    return (
        <FeatureBlockSection customClass="pictime-block">
            <FeatureBlockContent>
                <div className="pictime-block__text-container">
                    <HBMediaElement
                        name="marketing_site/integrations_module/pic-time_logo.png"
                        customClass="pictime-block__logo"
                    />

                    <HBText
                        tag="h2"
                        type="title-m"
                        customClass="pictime-block__title"
                    >
                        {t('PictimeBlock.title')}
                    </HBText>

                    <HBText
                        tag="h3"
                        type="body-m"
                        customClass="pictime-block__description"
                    >
                        {t('PictimeBlock.description')}
                    </HBText>

                    <CTAButton />
                </div>
                <div className="pictime-block__image-container">
                    <HBMediaElement
                        name={`${
                            breakpoints.mediumUp
                                ? 'marketing_site/integrations_module/Pic-time_module_image_desktop2.png'
                                : 'marketing_site/integrations_module/Pic-time_module_image_mweb.png'
                        }`}
                        customClass="pictime-block__image"
                    />

                    <CTAButton isMobile />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default PictimeBlock;
