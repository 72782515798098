import { GoogleAuthProvider } from 'firebase/auth';
import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';
import ApiService from '../Services/ApiService';
import RegistrationService from '../Services/RegistrationService';
import { analyticsEventsForGoogleSSO } from '../components/RegistrationForm/RegistrationForm';
import GeolocationService from '../Services/GeolocationService';
import DeviceService from '../Services/DeviceService';
import UtilsService from '../Services/UtilsService';
import StorageService from '../Services/StorageService';
import { CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY } from '../constants/constants';

const parseJwt = token => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        // deprecated just in node js
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
};

export const shouldLoadOneTap = async () => {
    const isValidGeolocation =
        await GeolocationService.isFromValidCountriesPromise();
    const forceGoogleOneTap =
        UtilsService.getParameterByName('forceGoogleOneTap');

    return (
        !!forceGoogleOneTap ||
        (isValidGeolocation &&
            DeviceService.isChrome &&
            !DeviceService.isMobile)
    );
};

export const handleOneTapResponse = async result => {
    const { idToken } = GoogleAuthProvider.credential(result.credential);
    if (!idToken) {
        AnalyticsService.track(
            analyticsEventsForGoogleSSO.signup_with_google_error,
            {
                data: 'idToken undefined'
            }
        );
        return;
    }
    const details = parseJwt(result.credential);
    details.credential = result.credential;

    const data = {
        auth_token: idToken,
        id_token: idToken,
        sso_type: 'google'
    };
    const ssoSignupUserResponse = await ApiService.ssoSignupUser(data);
    //if user exist so redirect to app
    if (ssoSignupUserResponse?._id) {
        RegistrationService.handleSSOUserExist(ssoSignupUserResponse);
        return;
    }

    const userData = {
        name: details.name,
        email: details.email,
        auth_token: idToken,
        sso_type: 'google_one_tap',
        auth_code: idToken,
        is_sso: true,
        is_firebase: true,
        profile_img_url: details.picture || ''
    };

    const dataForSignup = RegistrationService.handleRegistrationWithoutFields();
    const newData = { ...dataForSignup, userData };

    const additionalDataFromSessionStorage: Record<string, unknown> =
        StorageService.getItem(
            CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY,
            'sessionStorage'
        ) || {};

    const additionalDataFromSessionStoragePerPath =
        additionalDataFromSessionStorage[window.location.pathname] || {};

    const additionalDataForClickStartTrial = Object.values(
        additionalDataFromSessionStoragePerPath
    ).reduce<Record<string, unknown>>(
        (acc, curr: Record<string, unknown>) => ({
            ...acc,
            ...curr
        }),
        {}
    );

    AnalyticsService.trackClick(AnalyticsEvents.start_trial, {
        source: 'google_one_tap',
        signup_landing_page:
            UtilsService.getSignupLandingPageFromLocationPath(),
        start_trial_variant: 'single-cta',
        ...additionalDataForClickStartTrial
    });

    setTimeout(() => {
        RegistrationService.onRegistrationSuccessWithoutFields(newData);
    }, 100);
};
