export const GOOGLE_ONE_TAP_PARENT_ID = 'google-one-tap-container';
export const GOOGLE_ONE_TAP_TIMEOUT_SUPPRESSION = 100;
export const GOOGLE_ONE_TAP_MESSAGES = {
    browser_not_supported:
        'The current browser is not supported by Google One Tap.',
    invalid_client:
        'An invalid `client_id` was provided. Check your Google Client ID and ensure it is configured correctly.',
    issuing_failed:
        'Google failed to issue a credential. The selected session may have signed out of Google.',
    opt_out_or_no_session:
        'There are no sessions available to be used by Google One Tap. Possible causes are that the user not logged in to Google or that an ad-blocker is preventing the user from seeing his Google session.',
    unregistered_origin:
        'The given origin is not allowed for the given client ID.',
    suppressed_by_user: `The user previously opt-ed out of Google One Tap.`,
    user_cancel:
        'The user opt-ed out of the Google One Tap dialog by clicking the cancel button.',
    cancel_called: 'user cancel action before success',
    auto_cancel:
        'Google One Tap closes automatically due to no interaction with the One Tap UI.',
    sso_fallback: 'User got one_tap variant but received sso fallback',
    ad_blocker: 'Ad blocker is preventing google one tap to load',
    load_error: 'Error loading Google One Tap'
} as const;
