import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './feature-grid-block.scss';

import ImageTextElement from '../../ImageTextElement/ImageTextElement';
import FeatureGridBlockStore from './FeatureGridBlockStore';

const handleAnalytics = feature => {
    AnalyticsService.trackClick(AnalyticsEvents.feature_grid, {
        source: feature.transId
    });
};

const FeatureGridBlock = ({
    t,
    lightBackground,
    darkBackground,
    customData,
    title
}) => {
    // Use store data as default if no custom data is provided
    const featureData = customData || FeatureGridBlockStore.featureGrid;

    return (
        <FeatureBlockSection
            customClass="feature-grid"
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent>
                <FeatureBlockText fullWidth primary={title} />

                <div className="feature-block__container">
                    {featureData.map(feature => (
                        <a
                            className="feature-grid__link"
                            href={feature.url}
                            key={t(`${feature.transId}.title`)}
                        >
                            <ImageTextElement
                                imagePath={feature.imagePath}
                                mediaFlags={feature.mediaFlags}
                                handleClick={() => handleAnalytics(feature)}
                                title={t(`${feature.transId}.title`)}
                                description={t(
                                    `${feature.transId}.description`
                                )}
                            />
                        </a>
                    ))}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

FeatureGridBlock.propTypes = {
    t: PropTypes.func.isRequired,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool,
    customData: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string
};

export default withTranslation()(FeatureGridBlock);
