import Desktop from '../../svg/desktop.inline.svg';
import Mobile from '../../svg/mobile.inline.svg';
import type { TScreenMode } from '../PublicTemplatePreview/type';
import HBTabs from '../honeybook-ui/HBTabs/HBTabs';

export type TScreenModeTabsProps = {
    onViewSelect: (view: TScreenMode) => void;
    view: TScreenMode;
};

export const ScreenMode = ({ onViewSelect, view }: TScreenModeTabsProps) => {
    return (
        <HBTabs
            activeTabId={view}
            source="template_preview"
            tabs={[
                { id: 'Desktop', name: <Desktop /> },
                { id: 'Mobile', name: <Mobile /> }
            ]}
            activeTabClassName="active-tab"
            updateSelectedTabId={onViewSelect}
        />
    );
};
