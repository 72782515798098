import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../FeatureBlockElements';

import HBText from '../honeybook-ui/HBText/HBText';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';
import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';

import './lead-gen-form-block.scss';

const postSubmissionUrl =
    'https://demobusiness255758.hbportal.co/public/66f6c6ac6367c4002bc339c6/1-Welcome';

const industries = [
    'business_consulting',
    'coaching',
    'content_creation',
    'copywriting',
    'digital_marketing',
    'event_planning',
    'graphic_design',
    'marketing_agency',
    'marketing_consulting',
    'photography',
    'social_media',
    'web_design',
    'venue',
    'other'
];

function LeadGenFormBlock({ transId }): JSX.Element {
    const { t } = useTranslation();

    const handleFormSubmit = e => {
        e.preventDefault();

        const formElement = formRef.current;
        const inputs = formElement.querySelectorAll('input, select');
        let isValid = true;

        inputs.forEach(input => {
            if (!input.checkValidity()) {
                isValid = false;
                input.reportValidity();
            }
        });

        if (isValid) {
            const formValues = {
                first_name: formElement.querySelector('[name="first_name"]')
                    .value,
                last_name:
                    formElement.querySelector('[name="last_name"]').value,
                email: formElement.querySelector('[name="email"]').value,
                company_type_default: formElement.querySelector(
                    '[name="company_type_default"]'
                ).value
            };

            AnalyticsService.reportIterableEvent(
                'client_chemistry_lp_submission',
                formValues,
                {
                    email: formValues.email
                }
            )
                .then(() => {
                    return AnalyticsService.trackClick(
                        AnalyticsEvents.lead_gen_form_submit,
                        {
                            source: 'contract templates',
                            email: formValues.email
                        }
                    );
                })
                .then(() => {
                    window.open(postSubmissionUrl, '_blank').focus();
                })
                .catch(error => {
                    console.error('Error sending analytics data:', error);
                    window.open(postSubmissionUrl, '_blank').focus();
                });
        }
    };

    const formRef = useRef(null);

    return (
        <div id="preview">
            <FeatureBlockSection customClass="lead-gen-form-block">
                <FeatureBlockContent>
                    <div className="lead-gen-form-block__wrapper">
                        <div className="lead-gen-form-block__media-container">
                            <HBMediaElement
                                name="marketing_site/october_gtm/frame.png"
                                customClass="lead-gen-form-block__image"
                            />
                        </div>
                        <div className="lead-gen-form-block__form">
                            <HBText
                                tag="h2"
                                type="title-m"
                                customClass="lead-gen-form-block__title"
                            >
                                {t(`${transId}.title`)}
                            </HBText>

                            <HBText
                                tag="h3"
                                type="subtitle-s"
                                customClass="lead-gen-form-block__subtitle"
                            >
                                {t(`${transId}.subtitle`)}
                            </HBText>

                            <div
                                ref={formRef}
                                className="lead-gen-form-block__form"
                            >
                                <div className="lead-gen-form-block__input-group">
                                    <input
                                        name="first_name"
                                        type="text"
                                        required
                                        placeholder="First name"
                                        className="lead-gen-form-block__input"
                                    />
                                </div>
                                <input
                                    name="last_name"
                                    type="text"
                                    required
                                    placeholder="Last name"
                                    className="lead-gen-form-block__input"
                                />
                                <input
                                    name="email"
                                    type="email"
                                    required
                                    placeholder="Email"
                                    className="lead-gen-form-block__input"
                                />
                                <select
                                    name="company_type_default"
                                    required
                                    className="lead-gen-form-block__input"
                                >
                                    <option value="" disabled selected>
                                        Industry
                                    </option>
                                    {industries.map(industry => (
                                        <option value={industry}>
                                            {t(
                                                `clientRelationships.leadGenForm.selectOptions.${industry}`
                                            )}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type="submit"
                                    value={t(`${transId}.ctaText`)}
                                    className="hbui-button hbui-button--large hbui-button--primary"
                                    onClick={handleFormSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
}

export default LeadGenFormBlock;
