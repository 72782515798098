import AnalyticsService from '../../Services/AnalyticsService';

export const trackGetTemplateEvent = ({
    allow_save,
    id,
    data
}: {
    allow_save: boolean;
    id: string;
    data?: Record<string, any>;
}) => {
    const eventName = allow_save
        ? 'get template in public template'
        : 'start trial from public template';
    AnalyticsService.track(`click: ${eventName}`, {
        action: 'click',
        template_id: id,
        source: 'cta',
        ...data
    });
};
