import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import HBQuote from '../HBQuote/HBQuote';
import './testimonial-carousel-slide.scss';

const TestimonialCarouselSlide = ({
    name,
    company,
    jobTitle,
    quote,
    imageUrl
}) => {
    const { t } = useTranslation();

    return (
        <div className="testimonial-carousel-slide">
            <HBMediaElement
                lazyLoad={false}
                customClass="testimonial-carousel-slide__image"
                name={imageUrl}
                alt={t('testimonialCardsBlock.alt')}
            />
            <div className="testimonial-carousel-slide__text-container">
                <p className="testimonial-carousel-slide__member-info">
                    <span className="testimonial-carousel-slide__name">
                        {name},
                    </span>{' '}
                    <span className="testimonial-carousel-slide__company">
                        {company}
                    </span>{' '}
                    <span className="testimonial-carousel-slide__job-title">
                        {jobTitle}
                    </span>
                </p>
                <HBQuote customClass="testimonial-carousel-slide__quote">
                    {quote}
                </HBQuote>
            </div>
        </div>
    );
};

TestimonialCarouselSlide.propTypes = {
    name: PropTypes.string,
    company: PropTypes.string,
    jobTitle: PropTypes.string,
    quote: PropTypes.string,
    imageUrl: PropTypes.string
};

export default memo(TestimonialCarouselSlide);
