import { useTranslation } from 'react-i18next';

import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import HBText from '../../honeybook-ui/HBText/HBText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import Marquee from 'react-fast-marquee';

import './badges-block.scss';

const BadgesBlock = () => {
    const { t } = useTranslation();

    const badges = [
        {
            id: 'leader-1',
            imageUrl:
                'marketing_site/reviews/badges/Billing_Leader_Small-Business_Leader_2x-1.png',
            linkUrl: ''
        },
        {
            id: 'leader-2',
            imageUrl:
                'marketing_site/reviews/badges/Billing_MostImplementable_Total_2x.png',
            linkUrl: ''
        },
        {
            id: 'leader-3',
            imageUrl:
                'marketing_site/reviews/badges/InvoiceManagement_BestResults_Small-Business_Total_2x-1.png',
            linkUrl: ''
        },
        {
            id: 'leader-4',
            imageUrl:
                'marketing_site/reviews/badges/PaymentProcessing_BestResults_Total_2x.png',
            linkUrl: ''
        },
        {
            id: 'leader-5',
            imageUrl:
                'marketing_site/reviews/badges/OnlineAppointmentScheduling_HighestUserAdoption_Small-Business_Adoption_2x.png',
            linkUrl: ''
        }
    ];

    return (
        <div className="badges-block">
            <FeatureBlockSection>
                <FeatureBlockContent>
                    <HBText
                        tag="h2"
                        type="title-l"
                        customClass="badges-block__title"
                    >
                        {t('ReviewsPage.badges.title')}
                    </HBText>
                    <HBText
                        tag="h3"
                        type="body-xl"
                        customClass="badges-block__subtitle"
                    >
                        {t('ReviewsPage.badges.subtitle')}
                    </HBText>
                </FeatureBlockContent>
            </FeatureBlockSection>
            <FeatureBlockSection
                customClass="badges-block__badges-container"
                fullWidth
            >
                <FeatureBlockContent>
                    <div className="badges-block__badges">
                        <Marquee autoFill pauseOnHover>
                            {badges.map(badge => (
                                // For some reason using MediaElement breaks here
                                <div
                                    key={badge.id}
                                    className="media-element badges-block__badge"
                                >
                                    <img
                                        src={`https://res.cloudinary.com/honeybook/image/upload/c_scale,f_auto,fl_lossy,q_auto,w_auto/v1/${badge.imageUrl}`}
                                    />
                                </div>
                            ))}
                        </Marquee>
                    </div>
                    <RegistrationForm
                        size="large"
                        customClass="members-carousel__cta"
                        direction="column"
                        source="members carousel"
                        showNoCcRequired
                    />
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

export default BadgesBlock;
