import { TFunction, withTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import Hero from '../Hero/Hero/Hero';
import HeroStrip from '../Hero/HeroStrip/HeroStrip';
import HeroColumn from '../Hero/HeroColumn/HeroColumn';

import './contact-us-hero.scss';

interface Props {
    t: TFunction;
}

const ContactUsHero = withTranslation()(({ t }: Props) => {
    return (
        <Hero customClass="contact-hero">
            <HeroStrip>
                <HeroColumn columnWidth={2} customClass="">
                    <h1 className="contact-hero__title">
                        {t(`contactUsPage.hero.title`)}
                    </h1>
                    <h2 className="contact-hero__subtitle">
                        {t(`contactUsPage.hero.subtitle`)}
                    </h2>
                </HeroColumn>
                <HeroColumn columnWidth={3}>
                    <section className="contact-hero__media-wrapper">
                        <HBMediaElement
                            customClass="contact-hero__media-element"
                            width="568"
                            name="marketing_site/contact_us_page/member-experience-team"
                        />
                        <HBMediaElement
                            customClass="contact-hero__media-element contact-hero__media-element--hover"
                            width="568"
                            name="marketing_site/contact_us_page/member-experience-team-silly"
                        />
                    </section>
                </HeroColumn>
            </HeroStrip>
        </Hero>
    );
});

export default withTranslation()(ContactUsHero);
