const InvoiceGeneratorTypesStore = {
    list: [
        'standard',
        'recurring',
        'prepay',
        'time',
        'credit',
        'debit',
        'mixed'
    ],
    doula: {
        imagePath: 'marketing_site/invoice-generator-lp/doula/group.png'
    },
    design: {
        imagePath:
            'marketing_site/invoice-generator-lp/designers/Module%207/Invoices_group.png'
    },
    graphicDesign: {
        imagePath:
            'marketing_site/invoice-generator-lp/graphic-design/graphicTypes.png'
    },
    interiorDesign: {
        imagePath:
            'marketing_site/invoice-generator-lp/interior-design/interiorTypes.png'
    },
    photographers: {
        imagePath:
            'marketing_site/invoice-generator-lp/photographers/photoTypes.png',
        imageAlt: "HoneyBook's Types of Photography Invoice Templates"
    },
    consultants: {
        imagePath:
            'marketing_site/invoice-generator-lp/consultants/consultantTypes.png'
    },
    coach: {
        imagePath: 'marketing_site/invoice-generator-lp/coach/coachTypes.png'
    },
    tutors: {
        imagePath:
            'marketing_site/invoice-generator-lp/tutors/Module%207/Invoices_group.png'
    },
    cleaners: {
        imagePath:
            'marketing_site/invoice-generator-lp/cleaners/Module%207/Invoices_group.png'
    },
    virtualAssistant: {
        imagePath:
            'marketing_site/invoice-generator-lp/virtual-assistant/Module%207/Invoices_group.png'
    }
};

export default InvoiceGeneratorTypesStore;
