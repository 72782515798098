import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';
import { GOOGLE_ONE_TAP_MESSAGES } from './constants';
import {
    ONE_TAP_SUCCESS_EVENT_TYPES,
    TGoogleOneTapReasons,
    TSuccessEventType
} from './googleOneTap.types';

export const trackErrors = (errorReason: TGoogleOneTapReasons) => {
    AnalyticsService.track(
        AnalyticsEvents.marketing_site_google_gne_tap_error,
        {
            reason: errorReason,
            message: GOOGLE_ONE_TAP_MESSAGES[errorReason] || ''
        }
    );
};

export const trackSuccess = (eventType: TSuccessEventType) => {
    const eventName = ONE_TAP_SUCCESS_EVENT_TYPES[eventType];
    AnalyticsService.track(AnalyticsEvents[eventName], {
        ...(eventType === 'load' && { action: 'load' })
    });
};
