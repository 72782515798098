import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import ImageTextElement from '../../ImageTextElement/ImageTextElement';

import './vertical-templates-intro.scss';

const VerticalTempaltesIntro = ({ contentKey }) => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="vertical-lp__templates-intro">
            <FeatureBlockContent>
                <ImageTextElement
                    title={t(`${contentKey}.templatesIntro.title`)}
                    description={t(`${contentKey}.templatesIntro.subtitle`)}
                    imagePath="marketing_site/vertical-lps/template-images/template_icon.png"
                    mediaFlags={{ width: 90 }}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

VerticalTempaltesIntro.propTypes = {
    contentKey: PropTypes.string
};

export default VerticalTempaltesIntro;
