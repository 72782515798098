import { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';

import './feature-icon-grid.scss';

const featureIconGridStore = [
    {
        id: 'contracts',
        imageUrl: 'marketing_site/educator/contracts.svg'
    },
    {
        id: 'invoices',
        imageUrl: 'marketing_site/educator/invoices.svg'
    },
    {
        id: 'templates',
        imageUrl: 'marketing_site/educator/templates.svg'
    },
    {
        id: 'automation',
        imageUrl: 'marketing_site/educator/automation.svg'
    },
    {
        id: 'communication',
        imageUrl: 'marketing_site/educator/communication.svg'
    },
    {
        id: 'calendar',
        imageUrl: 'marketing_site/educator/calendar.svg'
    },
    {
        id: 'bookkeeping',
        imageUrl: 'marketing_site/educator/bookkeeping.svg'
    },
    {
        id: 'reports',
        imageUrl: 'marketing_site/educator/reports.svg'
    },
    {
        id: 'mobile',
        imageUrl: 'marketing_site/educator/mobileapp.svg'
    }
];

interface Props {
    customClass?: string;
}

const FeatureIconGrid = ({ customClass }: Props): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className={`feature-icon-grid ${customClass}`}>
            {featureIconGridStore.map(feature => (
                <div
                    className="feature-icon-grid__feature"
                    key={`feature-${feature.id}`}
                >
                    <HBMediaElement
                        name={feature.imageUrl}
                        customClass="feature-icon-grid__feature-img"
                        forceImgTag
                        alt={t(`featureIconGrid.${feature.id}`)}
                    />
                    <p className="feature-icon-grid__feature-text">
                        {t(`featureIconGrid.${feature.id}`)}
                    </p>
                </div>
            ))}
        </div>
    );
};

FeatureIconGrid.propTypes = {
    customClass: PropTypes.string
};

export default memo(FeatureIconGrid);
