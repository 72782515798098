import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './cta-block.scss';

interface Props {
    customClass?: string;
    primary: string;
    secondary?: string;
    lightBackground?: boolean;
    imageUrl?: string;
    // TODO: create type for media flags
    mediaFlags?: any;
    darkBackground?: boolean;
    showNoCcRequired?: boolean;
    buttonText?: string;
    children?: React.ReactChildren;
    ctaSource?: string;
    imageAltTag?: string;
}

const CTABlock = ({
    customClass = '',
    primary,
    secondary,
    children,
    imageUrl,
    mediaFlags,
    lightBackground = false,
    darkBackground,
    showNoCcRequired = false,
    buttonText = '',
    ctaSource,
    imageAltTag
}: Props) => {
    return (
        <FeatureBlockSection
            customClass={`${customClass} cta-block`}
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent>
                {imageUrl && (
                    <HBMediaElement
                        name={imageUrl}
                        {...mediaFlags}
                        alt={imageAltTag}
                        forceImgTag
                    />
                )}
                <FeatureBlockText
                    primary={primary}
                    secondary={secondary}
                    fullWidth
                />
                {children}
                <RegistrationForm
                    source={ctaSource || 'hero'}
                    showNoCcRequired={showNoCcRequired}
                    buttonText={buttonText}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default CTABlock;
