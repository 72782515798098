import { useTranslation } from 'react-i18next';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import type { TPricingPlans } from '../../../types/util-types';

interface Props {
    tierId: TPricingPlans;
    price: string | number;
    isMonthly: boolean;
}
const TierHeader = ({ tierId, price, isMonthly }: Props) => {
    const { t } = useTranslation();
    const transKey = isMonthly ? 'monthly' : 'annual';
    return (
        <div className="tier-header pricing-table-block__cell pricing-table-block__cell--tier">
            <h2 className="tier-header__title">
                {t(`pricingTiers.${tierId}`)}
            </h2>

            <div className="tier-header__details">
                <p className="tier-header__description">
                    <strong>${price}/mo</strong>
                    <br /> {t(`pricingTable.pricingDescription.${transKey}`)}
                </p>
                <RegistrationForm
                    source={`pricing table - ${tierId}`}
                    buttonStyle="primary--dark"
                    buttonText={t('pricingTable.ctaText')}
                />
            </div>
        </div>
    );
};

export default TierHeader;
