import { useState, useCallback, memo } from 'react';
import Tab from '../../../vendor/blueprintjs/tab';
import Tabs from '../../../vendor/blueprintjs/tabs';

import type { TabId } from '../../../vendor/blueprintjs/tab';

import './hb-tabs.scss';
import AnalyticsService, {
    GTM_ANALYTICS_EVENTS
} from '../../../Services/AnalyticsService';

export interface TabItem {
    id: string;
    name: string | JSX.Element;
    panel?: JSX.Element;
}

export type TSource =
    | 'introducing-ai-block'
    | 'swiper_pricing_block'
    | 'tabbed_features'
    | 'template_tabs_block'
    | 'client_portal_software'
    | 'email_signature_block'
    | 'online_invoices'
    | 'template_preview';

interface Props {
    tabs: TabItem[];
    updateSelectedTabId?: (nextSelectedTabId: TabId) => void;
    defaultTabId?: TabId;
    source: TSource;
    activeTabClassName?: string;
    activeTabId?: string;
}

function HBTabs({
    tabs,
    updateSelectedTabId,
    defaultTabId = tabs[0].id,
    source,
    activeTabClassName,
    activeTabId
}: Props): JSX.Element {
    const [selectedTabId, setSelectedTabId] = useState<TabId>(defaultTabId);
    const _activeTabId = activeTabId || selectedTabId;

    const handleOnChange = useCallback((nextSelectedTabId: TabId): void => {
        setSelectedTabId(nextSelectedTabId);
        AnalyticsService.trackGtm(GTM_ANALYTICS_EVENTS.tab_click, {
            section: source,
            selected_tab_id: nextSelectedTabId
        });
        updateSelectedTabId?.(nextSelectedTabId);
    }, []);

    return (
        <div className="hbui-tabs">
            <Tabs
                onChange={handleOnChange}
                className="tabs"
                selectedTabId={_activeTabId}
                animate
            >
                {tabs.map(
                    (tab: TabItem): JSX.Element => (
                        <Tab
                            key={tab.id}
                            id={tab.id}
                            title={tab.name}
                            className={`hbui-tab ${
                                _activeTabId === tab.id && activeTabClassName
                                    ? activeTabClassName
                                    : ''
                            }`}
                            panel={tab.panel}
                        />
                    )
                )}
            </Tabs>
        </div>
    );
}

export default memo(HBTabs);
