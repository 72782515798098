import React from 'react';
import PropTypes from 'prop-types';

import './card-footer.scss';

const CardFooter = ({ children }) => {
    return <div className="card__footer">{children}</div>;
};

CardFooter.propTypes = {
    children: PropTypes.node.isRequired
};

export default CardFooter;
