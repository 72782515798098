import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection,
    FeatureBlockText
} from '../../FeatureBlockElements';
import ImageTextElement from '../../ImageTextElement/ImageTextElement';

import './all-plans-include.scss';

const features = [
    {
        id: 'setup',
        imageUrl: 'marketing_site/pricing/setup.png'
    },
    {
        id: 'contracts',
        imageUrl: 'marketing_site/pricing/contracts.png'
    },
    {
        id: 'invoices',
        imageUrl: 'marketing_site/pricing/invoices.png'
    },
    {
        id: 'support',
        imageUrl: 'marketing_site/pricing/support.png'
    }
];

const AllPlansIncludeBlock = () => {
    const { t } = useTranslation();
    return (
        <FeatureBlockSection customClass="all-plans-include">
            <FeatureBlockContent>
                <FeatureBlockText
                    customClass="all-plans-include__title"
                    primary={t('allPlansInclude.title')}
                    fullWidth
                />
                <div className="all-plans-include__container">
                    {features.map(({ id, imageUrl }) => {
                        return (
                            <ImageTextElement
                                key={id}
                                title={t(`allPlansInclude.${id}.title`)}
                                description={t(
                                    `allPlansInclude.${id}.description`
                                )}
                                imagePath={imageUrl}
                            />
                        );
                    })}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default AllPlansIncludeBlock;
