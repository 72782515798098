import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import Hero from '../Hero/Hero';
import HeroTitle from '../HeroTitle/HeroTitle';
import HeroStrip from '../HeroStrip/HeroStrip';
import HeroColumn from '../HeroColumn/HeroColumn';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './contract-template-hero.scss';

interface Props {
    contentKey: string;
    image: string;
}

function ContractTemplateHero({ contentKey, image }: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <Hero customClass="contract-template-hero">
            <HeroStrip>
                <HeroColumn customClass="contract-template-hero__column contract-template-hero__column--text">
                    <HeroTitle
                        title={t(
                            `contractTemplatePage.${contentKey}.hero.title`
                        )}
                    />

                    <p className="contract-template-hero__body">
                        {t(`contractTemplatePage.${contentKey}.hero.subtitle`)}
                    </p>

                    <RegistrationForm
                        source="contract template hero"
                        showNoCcRequired
                    />

                    <HBMediaElement
                        customClass="hero__img contract-template-hero__media-element"
                        name={image}
                        alt={
                            t(
                                `contractTemplatePage.${contentKey}.hero.imageAlt`
                            ) ||
                            t(`contractTemplatePage.${contentKey}.hero.title`)
                        }
                        crop="scale"
                        lazyLoad={false}
                        width={1140}
                    />
                </HeroColumn>
            </HeroStrip>
        </Hero>
    );
}

export default memo(ContractTemplateHero);
