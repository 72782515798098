import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../components/honeybook-ui/HBText/HBText';
import NavigateLink from '../components/Link/NavigateLink';

import '../styles/unsupported.scss';

const Unsupported = () => {
    const { t } = useTranslation();

    const ogTags = {
        title: 'Unsupported',
        description: 'Page not supported',
        noindex: true
    };
    return (
        <Layout
            customClass="unsupported"
            ogTags={ogTags}
            slimNav
            noFooter
            noCta
        >
            <div className="unsupported__container">
                <div className="unsupported__content">
                    <HBMediaElement
                        customClass="unsupported__sorry"
                        name="marketing_site/404/Sorry_Lockup__as_one.svg"
                        alt=""
                        lazyLoad={false}
                        forceImgTag
                    />
                    <HBMediaElement
                        customClass="unsupported__sorry--mweb"
                        name="marketing_site/404/Sorry_Lockup.svg"
                        alt=""
                        forceImgTag
                        lazyLoad={false}
                    />
                    <HBText
                        customClass="unsupported__title"
                        tag="h1"
                        type="title-s"
                    >
                        {t('unsupported.title')}
                    </HBText>
                    <HBText
                        customClass="unsupported__subtitle"
                        tag="p"
                        type="body-m"
                    >
                        <Trans i18nKey="unsupported.subtitle">
                            #TEXT#
                            <a href="https://www.google.com/intl/en/chrome/browser/">
                                #LINK_TEXT#
                            </a>
                            <a href="https://support.apple.com/downloads/#safari/">
                                #LINK_TEXT#
                            </a>
                        </Trans>
                    </HBText>

                    <NavigateLink
                        source="unsupported_to_home"
                        link="/"
                        className="unsupported__link hbui-button hbui-button--large hbui-button--primary--dark"
                    >
                        {t('unsupported.buttonText')}
                    </NavigateLink>
                    <HBMediaElement
                        customClass="unsupported__arrow"
                        name="marketing_site/404/Arrow_Desktop.svg"
                        alt=""
                        forceImgTag
                        lazyLoad={false}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default Unsupported;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "unsupported"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
