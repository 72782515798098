const BusinessPersonalityToolSocialStore = {
    actualizerRisingStar: {
        title: 'Recommended Actualizers to follow',
        educators: [
            {
                name: 'Kelsey Chapman',
                handleText: '@kelschapman',
                imagePath:
                    'marketing_site/business_personality_tool/educators/kelsey_actualizer.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/kelschapman/';
                    }
                }
            },
            {
                name: 'Geomyra Pollard',
                handleText: '@coachforcreatives',
                imagePath:
                    'marketing_site/business_personality_tool/educators/geomyra_visionary.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/coachforcreatives/';
                    }
                }
            }
        ]
    },
    actualizerWaveMaker: {
        title: 'Recommended Actualizers to follow',
        educators: [
            {
                name: 'Kelsey Chapman',
                handleText: '@kelschapman',
                imagePath:
                    'marketing_site/business_personality_tool/educators/kelsey_actualizer.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/kelschapman/';
                    }
                }
            },
            {
                name: 'Geomyra Pollard',
                handleText: '@coachforcreatives',
                imagePath:
                    'marketing_site/business_personality_tool/educators/geomyra_visionary.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/coachforcreatives/';
                    }
                }
            }
        ]
    },
    actualizerDrivingForce: {
        title: 'Recommended Actualizers to follow',
        educators: [
            {
                name: 'Kelsey Chapman',
                handleText: '@kelschapman',
                imagePath:
                    'marketing_site/business_personality_tool/educators/kelsey_actualizer.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/kelschapman/';
                    }
                }
            },
            {
                name: 'Geomyra Pollard',
                handleText: '@coachforcreatives',
                imagePath:
                    'marketing_site/business_personality_tool/educators/geomyra_visionary.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/coachforcreatives/';
                    }
                }
            }
        ]
    },
    actualizerEmpireBuilder: {
        title: 'Recommended Actualizers to follow',
        educators: [
            {
                name: 'Kelsey Chapman',
                handleText: '@kelschapman',
                imagePath:
                    'marketing_site/business_personality_tool/educators/kelsey_actualizer.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/kelschapman/';
                    }
                }
            },
            {
                name: 'Geomyra Pollard',
                handleText: '@coachforcreatives',
                imagePath:
                    'marketing_site/business_personality_tool/educators/geomyra_visionary.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/coachforcreatives/';
                    }
                }
            }
        ]
    },
    connectorRisingStar: {
        title: 'Recommended Connectors to follow',
        educators: [
            {
                name: 'Evie and Lindsey',
                handleText: '@theheartuniversity',
                imagePath:
                    'marketing_site/business_personality_tool/educators/connector_evie_lindsey.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/theheartuniversity/';
                    }
                }
            },
            {
                name: 'Wyn Wiley',
                handleText: '@wynwileyphoto',
                imagePath:
                    'marketing_site/business_personality_tool/educators/wyn_creative.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/wynwileyphoto/';
                    }
                }
            }
        ]
    },
    connectorWaveMaker: {
        title: 'Recommended Connectors to follow',
        educators: [
            {
                name: 'Evie and Lindsey',
                handleText: '@theheartuniversity',
                imagePath:
                    'marketing_site/business_personality_tool/educators/connector_evie_lindsey.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/theheartuniversity/';
                    }
                }
            },
            {
                name: 'Wyn Wiley',
                handleText: '@wynwileyphoto',
                imagePath:
                    'marketing_site/business_personality_tool/educators/wyn_creative.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/wynwileyphoto/';
                    }
                }
            }
        ]
    },
    connectorDrivingForce: {
        title: 'Recommended Connectors to follow',
        educators: [
            {
                name: 'Evie and Lindsey',
                handleText: '@theheartuniversity',
                imagePath:
                    'marketing_site/business_personality_tool/educators/connector_evie_lindsey.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/theheartuniversity/';
                    }
                }
            },
            {
                name: 'Wyn Wiley',
                handleText: '@wynwileyphoto',
                imagePath:
                    'marketing_site/business_personality_tool/educators/wyn_creative.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/wynwileyphoto/';
                    }
                }
            }
        ]
    },
    connectorEmpireBuilder: {
        title: 'Recommended Connectors to follow',
        educators: [
            {
                name: 'Evie and Lindsey',
                handleText: '@theheartuniversity',
                imagePath:
                    'marketing_site/business_personality_tool/educators/connector_evie_lindsey.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/theheartuniversity/';
                    }
                }
            },
            {
                name: 'Wyn Wiley',
                handleText: '@wynwileyphoto',
                imagePath:
                    'marketing_site/business_personality_tool/educators/wyn_creative.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/wynwileyphoto/';
                    }
                }
            }
        ]
    },
    creativeRisingStar: {
        title: 'Recommended Creatives to follow',
        educators: [
            {
                name: 'Dianuh Aerin',
                handleText: '@chasinglinen',
                imagePath:
                    'marketing_site/business_personality_tool/educators/dianuh_creative.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/chasinglinen';
                    }
                }
            },
            {
                name: 'Henry Tieu',
                handleText: '@henrysdiary',
                imagePath:
                    'marketing_site/business_personality_tool/educators/henry_creative.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/henrysdiary/';
                    }
                }
            }
        ]
    },
    creativeWaveMaker: {
        title: 'Recommended Creatives to follow',
        educators: [
            {
                name: 'Dianuh Aerin',
                handleText: '@chasinglinen',
                imagePath:
                    'marketing_site/business_personality_tool/educators/dianuh_creative.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/chasinglinen';
                    }
                }
            },
            {
                name: 'Henry Tieu',
                handleText: '@henrysdiary',
                imagePath:
                    'marketing_site/business_personality_tool/educators/henry_creative.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/henrysdiary/';
                    }
                }
            }
        ]
    },
    creativeDrivingForce: {
        title: 'Recommended Creatives to follow',
        educators: [
            {
                name: 'Dianuh Aerin',
                handleText: '@chasinglinen',
                imagePath:
                    'marketing_site/business_personality_tool/educators/dianuh_creative.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/chasinglinen';
                    }
                }
            },
            {
                name: 'Henry Tieu',
                handleText: '@henrysdiary',
                imagePath:
                    'marketing_site/business_personality_tool/educators/henry_creative.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/henrysdiary/';
                    }
                }
            }
        ]
    },
    creativeEmpireBuilder: {
        title: 'Recommended Creatives to follow',
        educators: [
            {
                name: 'Dianuh Aerin',
                handleText: '@chasinglinen',
                imagePath:
                    'marketing_site/business_personality_tool/educators/dianuh_creative.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/chasinglinen';
                    }
                }
            },
            {
                name: 'Henry Tieu',
                handleText: '@henrysdiary',
                imagePath:
                    'marketing_site/business_personality_tool/educators/henry_creative.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/henrysdiary/';
                    }
                }
            }
        ]
    },
    visionaryRisingStar: {
        title: 'Recommended Visionaries to follow',
        educators: [
            {
                name: 'Candice Coppola',
                handleText: '@candice.coppola',
                imagePath:
                    'marketing_site/business_personality_tool/educators/Visionary_Candice.png',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/candice.coppola/';
                    }
                }
            },
            {
                name: 'Karina Ramos',
                handleText: '@thevoyagesociety',
                imagePath:
                    'marketing_site/business_personality_tool/educators/karina_visionary.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/thevoyagesociety/';
                    }
                }
            }
        ]
    },
    visionaryWaveMaker: {
        title: 'Recommended Visionaries to follow',
        educators: [
            {
                name: 'Candice Coppola',
                handleText: '@candice.coppola',
                imagePath:
                    'marketing_site/business_personality_tool/educators/Visionary_Candice.png',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/candice.coppola/';
                    }
                }
            },
            {
                name: 'Karina Ramos',
                handleText: '@thevoyagesociety',
                imagePath:
                    'marketing_site/business_personality_tool/educators/karina_visionary.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/thevoyagesociety/';
                    }
                }
            }
        ]
    },
    visionaryDrivingForce: {
        title: 'Recommended Visionaries to follow',
        educators: [
            {
                name: 'Candice Coppola',
                handleText: '@candice.coppola',
                imagePath:
                    'marketing_site/business_personality_tool/educators/Visionary_Candice.png',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/candice.coppola/';
                    }
                }
            },
            {
                name: 'Karina Ramos',
                handleText: '@thevoyagesociety',
                imagePath:
                    'marketing_site/business_personality_tool/educators/karina_visionary.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/thevoyagesociety/';
                    }
                }
            }
        ]
    },
    visionaryEmpireBuilder: {
        title: 'Recommended Visionaries to follow',
        educators: [
            {
                name: 'Candice Coppola',
                handleText: '@candice.coppola',
                imagePath:
                    'marketing_site/business_personality_tool/educators/Visionary_Candice.png',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/candice.coppola/';
                    }
                }
            },
            {
                name: 'Karina Ramos',
                handleText: '@thevoyagesociety',
                imagePath:
                    'marketing_site/business_personality_tool/educators/karina_visionary.jpg',
                mediaFlags: {
                    width: 200,
                    height: 200,
                    crop: 'fill',
                    gravity: 'face',
                    radius: 'max',
                    forceEagerLoad: true
                },
                onClick: () => {
                    if (typeof window !== 'undefined') {
                        window.location.href =
                            'https://www.instagram.com/thevoyagesociety/';
                    }
                }
            }
        ]
    }
};

export default BusinessPersonalityToolSocialStore;
