import { memo, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { useTranslation, Trans } from 'react-i18next';

import ReactHtmlParser from 'react-html-parser';

import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import FeatureTitleList from '../../FeatureTitleList/FeatureTitleList';

import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockUserQuote
} from '../../FeatureBlockElements';

import './list-quote-features-block.scss';
import type { IBlockContent } from '../../../types/util-types';

interface Props {
    customClass?: string;
    blockContent: IBlockContent;
    isEven?: boolean;
    alternateLightBackground?: boolean;
    withRegistrationForm?: boolean;
    registrationCta?: string;
    registrationSource: string;
}

function ListQuoteFeatureBlocks({
    customClass = '',
    blockContent,
    isEven,
    alternateLightBackground,
    withRegistrationForm,
    registrationCta,
    registrationSource
}: Props): JSX.Element {
    const { t } = useTranslation();

    const [ref, inView] = useInView({
        threshold: 0.7,
        triggerOnce: true
    });

    const activeClass = inView ? 'list-quote-features-block--active' : '';
    const alternatingBgClass = alternateLightBackground
        ? 'list-quote-features-block--alt-bg'
        : '';

    const listItems = Object.keys(
        t(`${blockContent.transKey}.list`, { returnObjects: true })
    ).map(key => ({
        id: key
    }));

    const title = useMemo(
        () => (
            <Trans
                i18nKey={`${blockContent.transKey}.title`}
                components={ReactHtmlParser(`${blockContent.transKey}.title`)}
            />
        ),
        [blockContent.transKey]
    );

    return (
        <div
            ref={ref}
            className={`list-quote-features-block ${customClass} ${activeClass} ${alternatingBgClass} ${
                isEven ? 'list-quote-features-block__even' : ''
            }`}
        >
            <FeatureBlockSection
                customClass={blockContent.customClass || ''}
                {...(isEven && alternateLightBackground
                    ? { lightBackground: true }
                    : null)}
            >
                <FeatureBlockContent customClass="list-quote-content list-quote-content--copy-block">
                    <div className="list-quote-content__copy">
                        <FeatureTitleList
                            title={title}
                            listName={`${blockContent.transKey}.list`}
                            items={listItems}
                            withRegistrationForm={withRegistrationForm}
                            registrationCta={registrationCta}
                            registrationSource={registrationSource}
                        />
                        {blockContent.testimonial && (
                            <FeatureBlockUserQuote
                                quote={t(
                                    `${blockContent.transKey}.testimonial.quote`
                                )}
                                avatar={blockContent.testimonial.avatar}
                                name={blockContent.testimonial.memberName}
                                jobTitle={t(
                                    `${blockContent.transKey}.testimonial.jobTitle`
                                )}
                                avatarCrop="thumb"
                                avatarGravity="face"
                                avatarWidth={40}
                                avatarHeight={40}
                            />
                        )}
                    </div>
                </FeatureBlockContent>
                <FeatureBlockContent
                    position={isEven ? 'left' : 'right'}
                    customClass="list-quote-content list-quote-content--image-block"
                >
                    <div className="list-quote-content__image-wrapper">
                        {blockContent.images &&
                            blockContent.images.map(image => (
                                <HBMediaElement
                                    customClass={image.customClass}
                                    key={image.publicId}
                                    name={image.publicId}
                                    width={image.width}
                                />
                            ))}
                    </div>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
}

ListQuoteFeatureBlocks.propTypes = {
    customClass: PropTypes.string,
    blockContent: PropTypes.shape({}),
    isEven: PropTypes.bool,
    alternateLightBackground: PropTypes.bool,
    withRegistrationForm: PropTypes.bool,
    registrationCta: PropTypes.string,
    registrationSource: PropTypes.string
};

export default memo(ListQuoteFeatureBlocks);
