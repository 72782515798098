import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TimeManagementBlockStore from './TimeManagementBlockStore';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockTimeMgt
} from '../../FeatureBlockElements';

import './time-management-block.scss';

const TimeManagementBlock = ({ t, customClass = '' }) => {
    return (
        <FeatureBlockSection customClass={`time-management ${customClass}`}>
            <FeatureBlockContent position="center">
                <h3 className="time-management__title">
                    {t(`featureBlock.timeManagement.title`)}
                </h3>
                <h4 className="time-management__subtitle">
                    {t(`featureBlock.timeManagement.subtitle`)}
                </h4>
                {/* TODO: Can we extract the pie chart, add dynamic 20%, and ditch the other junk? */}
                <FeatureBlockTimeMgt
                    title={t(`featureBlock.timeManagement.pieChartTitle`)}
                    assets={TimeManagementBlockStore.timeManagement.media}
                />
                <p className="time-management__footnote">
                    <span className="time-management__footnote-percentage">
                        20%
                    </span>{' '}
                    time managing your business
                </p>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

TimeManagementBlock.propTypes = {
    t: PropTypes.func,
    customClass: PropTypes.string
};

export default withTranslation()(TimeManagementBlock);
