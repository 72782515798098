import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import FeatureBlockImage from '../../FeatureBlockElements/FeatureBlockImage/FeatureBlockImage';
import { TRANSLATION_COMPONENTS } from '../../../constants/constants';

import './vertical-templates-block.scss';

const handleAnalytics = contentKey => {
    AnalyticsService.trackClick(AnalyticsEvents.vertical_link_to_invoices, {
        source: contentKey
    });
};

const VerticalTemplatesBlock = ({
    contentKey,
    templateBlock,
    templateFeatureImages,
    templateBlockLinks
}) => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection
            key={contentKey}
            customClass="vertical-templates-block"
        >
            <FeatureBlockContent customClass="vertical-templates-block__text">
                <FeatureBlockText
                    primary={t(`${contentKey}.${templateBlock}.title`)}
                />
                <div className="feature-block__text-body ">
                    <Trans
                        i18nKey={`${contentKey}.${templateBlock}.text`}
                        components={TRANSLATION_COMPONENTS}
                    />
                    {templateBlockLinks && templateBlockLinks[templateBlock] && (
                        <a
                            href={templateBlockLinks[templateBlock]}
                            onClick={() => handleAnalytics(contentKey)}
                        >
                            {t(`${contentKey}.${templateBlock}.linkText`)}
                        </a>
                    )}
                </div>
            </FeatureBlockContent>
            <FeatureBlockContent customClass="vertical-templates-block__image">
                <FeatureBlockImage
                    featureImage={
                        templateFeatureImages[templateBlock]?.name ||
                        templateFeatureImages[templateBlock]
                    }
                    alt={
                        t(`${contentKey}.${templateBlock}.imageAltText`, {
                            defaultValue: null
                        }) || t(`${contentKey}.${templateBlock}.title`)
                    }
                    width={800}
                    crop="scale"
                    lazyLoad={false}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

VerticalTemplatesBlock.propTypes = {
    contentKey: PropTypes.string,
    templateBlock: PropTypes.string,
    templateFeatureImages: PropTypes.shape({}),
    templateBlockLinks: PropTypes.shape({})
};

export default VerticalTemplatesBlock;
