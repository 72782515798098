import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HBButton from '@honeybook-ui/HBButton/HBButton';

import RegistrationForm from '../../components/RegistrationForm/RegistrationForm';

import './template-download-button.scss';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

const TemplateDownloadModalStepComplete = ({ t, onToggleModal }) => {
    const handleButtonAnalytics = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.template_download_modal_sent
        );
        onToggleModal();
    };

    return (
        <>
            <h2 className="template-download-button__title">
                {t('templateDownloadButton.step3.title')}
            </h2>
            <p className="template-download-button__text">
                {t('templateDownloadButton.step3.subtitle')}
            </p>

            <div className="template-download-button__test-b">
                <RegistrationForm source="template download button" />
                <HBButton
                    onClick={() => handleButtonAnalytics()}
                    buttonStyle="invisible"
                    text={t('templateDownloadButton.step3.ctaText')}
                />
            </div>
        </>
    );
};

TemplateDownloadModalStepComplete.propTypes = {
    t: PropTypes.func,
    onToggleModal: PropTypes.func
};

export default withTranslation()(TemplateDownloadModalStepComplete);
