import { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import HBText from '../honeybook-ui/HBText/HBText';
import { FeatureBlockSection } from '../FeatureBlockElements';
import type { TTabsContent } from '../../types/util-types';
import AnalyticsService, {
    GTM_ANALYTICS_EVENTS
} from '../../Services/AnalyticsService';

import 'swiper/css';
import './swiper-with-tabs.scss';

interface Props {
    tabsContent: TTabsContent[];
    customClass: string;
    cb?: (tabIdx: number) => void;
}

const SwiperWithTabs = ({ tabsContent, customClass, cb = () => {} }: Props) => {
    const [selectedTabIdx, setSelectedTabIdx] = useState(1);
    const swiperRef: any = useRef();

    let selectorClass;
    switch (selectedTabIdx) {
        case 0:
            selectorClass = 'left';
            break;
        case 1:
            selectorClass = 'center';
            break;
        case 2:
            selectorClass = 'right';
            break;
    }

    const moveToSlide = idx => {
        setSelectedTabIdx(idx);
        cb(idx);
        swiperRef.current.slideTo(idx);
    };

    const handleSetSelectedTabIdx = ({ activeIndex }) => {
        AnalyticsService.trackGtm(GTM_ANALYTICS_EVENTS.tab_click, {
            section: 'swiper_pricing_block',
            selected_tab_id: tabsContent[activeIndex].id
        });
        cb(activeIndex);
        setSelectedTabIdx(activeIndex);
    };

    return (
        <>
            <FeatureBlockSection
                customClass={`swiper-with-tabs ${customClass}`}
            >
                <div className="swiper-with-tabs__tabs">
                    {tabsContent.map(({ name }, idx) => (
                        <div
                            key={name}
                            className="swiper-with-tabs__tab-wrapper"
                            onClick={() => moveToSlide(idx)}
                        >
                            <HBText
                                customClass={`swiper-with-tabs__tab ${
                                    selectedTabIdx === idx ? 'selected' : ''
                                }`}
                                tag="p"
                                type="body-l"
                            >
                                {name}
                            </HBText>
                        </div>
                    ))}
                    <div
                        className={`swiper-with-tabs__selector swiper-with-tabs__selector--${selectorClass}`}
                    ></div>
                </div>
                <Swiper
                    className="swiper-with-tabs__swiper"
                    onSwiper={swiper => (swiperRef.current = swiper)}
                    onSlideChange={handleSetSelectedTabIdx}
                    tabIndex={selectedTabIdx}
                    slidesPerView={1}
                    initialSlide={1}
                    centeredSlides={true}
                >
                    {tabsContent.map(({ panel }) => (
                        <SwiperSlide key={panel.key}>{panel}</SwiperSlide>
                    ))}
                </Swiper>
            </FeatureBlockSection>
        </>
    );
};

export default SwiperWithTabs;
