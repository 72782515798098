import { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './feature-checklist-hero.scss';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';
import HBText from '@honeybook-ui/HBText/HBText';
import UtilsService from '../../../Services/UtilsService';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

import StorageService from '../../../Services/StorageService';
import { CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY } from '../../../constants/constants';
import type { TAdditionalDataForClickStartTrialModules } from '../../../types/util-types';
import {
    FEATURES_LIST,
    TFeaturesCheckListAnalyticsData
} from './constants-and-types';

const checklistIds = Object.keys(FEATURES_LIST);

const FeatureChecklistItem = ({ id, isSelected, onClick }) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    return (
        <div
            tabIndex={0}
            role="checkbox"
            aria-checked={isSelected}
            className={`feature-checklist-item feature-checklist-item--${id}
				${isSelected ? 'feature-checklist-item--selected' : ''}
			}`}
            onClick={() => onClick(id)}
            onKeyDown={event => event.key === 'Enter' && onClick(id)}
        >
            <div className="feature-checklist-item__checkbox">
                <div className="feature-checklist-item__check-mark">
                    {isSelected && <HBIcon name="nx-check" />}
                </div>
            </div>
            <div className="feature-checklist-item__icon">
                <HBMediaElement
                    name={FEATURES_LIST[id]}
                    lazyLoad={false}
                    alt={t(`checklistHero.featureChecklist.desktop.${id}`)}
                />
            </div>
            <div className="feature-checklist-item__description">
                {breakpoints.medium
                    ? t(`checklistHero.featureChecklist.mobile.${id}`)
                    : t(`checklistHero.featureChecklist.desktop.${id}`)}
            </div>
        </div>
    );
};
interface Props {
    hiddenFeatures?: string[];
    title?: string;
    subtitle?: string;
    titleImage?: string;
    increaseTitleFontSize?: boolean;
    module: TAdditionalDataForClickStartTrialModules;
    customButtonText?: string;
}

const FeatureChecklistHero = ({
    hiddenFeatures,
    title,
    subtitle,
    titleImage,
    increaseTitleFontSize = false,
    module,
    customButtonText
}: Props) => {
    const { t } = useTranslation();

    const [selectedItemList, setSelectedItemList] = useState<string[]>([]);
    const [isButtonAnimating, setIsButtonAnimating] = useState(false);

    const animateButton = () => {
        setIsButtonAnimating(true);
        setTimeout(() => {
            setIsButtonAnimating(false);
        }, 600);
    };
    useEffect(() => {
        const additionalData =
            StorageService.getItem(
                CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY,
                'sessionStorage'
            ) || {};
        const additionalDataPerPath =
            additionalData?.[window.location.pathname] || {};

        const selectedFeaturesPerModule:
            | TFeaturesCheckListAnalyticsData
            | undefined = additionalDataPerPath[module];

        if (selectedFeaturesPerModule) {
            if (selectedFeaturesPerModule.intent_selected !== 'none') {
                setSelectedItemList(
                    selectedFeaturesPerModule.intent_selected.split(',')
                );
            }
        } else {
            UtilsService.addAdditionalDataToClickStartTrial(
                {
                    intent_selected: 'none',
                    intent_selected_count: 0
                },
                module
            );
        }
    }, []);

    const handleListSelect = useCallback(
        (selectedItem: string) => {
            let status;
            let updatedSelectedItemList;
            if (selectedItemList.includes(selectedItem)) {
                updatedSelectedItemList = selectedItemList.filter(
                    v => v !== selectedItem
                );
                status = 'off';
            } else {
                updatedSelectedItemList = [...selectedItemList, selectedItem];
                status = 'on';
                animateButton();
            }
            setSelectedItemList(updatedSelectedItemList);
            AnalyticsService.trackClick(
                AnalyticsEvents.feature_checklist_select_item,
                {
                    type: selectedItem,
                    status,
                    variant: 'rebrand'
                }
            );
            const additionalData = {
                intent_selected: updatedSelectedItemList.join() || 'none',
                intent_selected_count: updatedSelectedItemList.length
            };
            UtilsService.addAdditionalDataToClickStartTrial(
                additionalData,
                module
            );
        },
        [selectedItemList]
    );

    const checklistIdsForDisplay = useMemo(
        () =>
            hiddenFeatures
                ? checklistIds.filter(
                      (id: keyof typeof FEATURES_LIST) =>
                          !hiddenFeatures.includes(id)
                  )
                : checklistIds,
        [hiddenFeatures]
    );

    return (
        <FeatureBlockSection
            customClass={`feature-checklist-hero ${
                increaseTitleFontSize ? 'increase-font-size' : ''
            }`}
        >
            <FeatureBlockContent>
                <div
                    className={`feature-checklist-hero__title-wrapper ${
                        increaseTitleFontSize ? 'increase-font-size' : ''
                    }`}
                >
                    {titleImage && (
                        <HBMediaElement
                            name={titleImage}
                            customClass="feature-checklist-hero__title-image"
                            lazyLoad={false}
                        />
                    )}
                    <HBText
                        customClass="feature-checklist-hero__title"
                        tag="h1"
                        type="title-m"
                    >
                        {title || t('checklistHero.title')}
                    </HBText>
                    <HBText
                        customClass="feature-checklist-hero__subtitle"
                        tag="h2"
                        type="body-xl"
                    >
                        {subtitle || t('checklistHero.subtitle')}
                    </HBText>
                </div>
                <div className="feature-checklist__checklist-container">
                    {checklistIdsForDisplay.map(checkboxId => (
                        <FeatureChecklistItem
                            key={checkboxId}
                            id={checkboxId}
                            isSelected={selectedItemList.includes(checkboxId)}
                            onClick={handleListSelect}
                        />
                    ))}
                </div>
                <RegistrationForm
                    customClass={`${
                        isButtonAnimating ? 'registration-form--animate' : ''
                    }`}
                    source="feature checklist hero"
                    showNoCcRequired
                    direction="column"
                    size="large"
                    buttonText={customButtonText}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default memo(FeatureChecklistHero);
