import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import ProductEducationHero from '../components/Hero/ProductEducationHero/ProductEducationHero';
import MeetOurInstructorsBlock from '../components/FeatureBlocks/MeetOurInstructorsBlockStore/MeetOurInstructorsBlock';
import WebinarBlock from '../components/FeatureBlocks/WebinarBlock/WebinarBlock';
import HelpCenterBlock from '../components/FeatureBlocks/HelpCenterBlock/HelpCenterBlock';

const webinars = [
    {
        id: 'welcome',
        link: 'https://academy.honeybook.com/sl/4479264e',
        image: 'marketing_site/webinars/welcome_webinar.png'
    },
    {
        id: 'hb101',
        link: 'https://academy.honeybook.com/sl/fbb7dca9',
        image: 'marketing_site/webinars/getting_started_webinar.jpg'
    }
];

const ogTags = {
    url: '//www.honeybook.com/product-education',
    title: 'Product Education Resources and Help Center',
    description:
        "Access the training to take full advantage of Honeybook's business tools. Maximize their integration into your business with our product education resources."
};

function ProductEducationTemplate() {
    return (
        <div className="product-education-page">
            <Layout ogTags={ogTags}>
                <ProductEducationHero />
                <WebinarBlock webinars={webinars} />
                <MeetOurInstructorsBlock />
                <HelpCenterBlock />
            </Layout>
        </div>
    );
}

export default ProductEducationTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "productEducation"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
