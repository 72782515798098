import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import TabsBlock from '../TabsBlock/TabsBlock';
import TemplateFeatureBlock from '../TemplateFeatureBlock/TemplateFeatureBlock';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import ImageTextElement from '../../ImageTextElement/ImageTextElement';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper';

import './template-tabs-block.scss';
import { useState } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';

const values = [
    {
        transId: 'services',
        sections: [
            {
                id: 'pricing',
                imagePath: 'marketing_site/smart-files/stage-1-1.png'
            },
            {
                id: 'services',
                imagePath: 'marketing_site/smart-files/stage-1-2.png'
            },
            {
                id: 'questionnaires',
                imagePath: 'marketing_site/smart-files/stage-1-3.png'
            },
            {
                id: 'brochures',
                imagePath: 'marketing_site/smart-files/stage-1-4.png'
            }
        ]
    },
    {
        transId: 'booked',
        sections: [
            {
                id: 'proposals',
                imagePath: 'marketing_site/smart-files/stage-2-1.png'
            },
            {
                id: 'invoices',
                imagePath: 'marketing_site/smart-files/stage-2-2.png'
            },
            {
                id: 'contracts',
                imagePath: 'marketing_site/smart-files/stage-2-3.png'
            }
        ]
    },
    {
        transId: 'projects',
        sections: [
            {
                id: 'welcome',
                imagePath: 'marketing_site/smart-files/stage-3-1.png'
            },
            {
                id: 'design',
                imagePath: 'marketing_site/smart-files/stage-3-2.png'
            },
            {
                id: 'moods',
                imagePath: 'marketing_site/smart-files/stage-3-3.png'
            },
            {
                id: 'timelines',
                imagePath: 'marketing_site/smart-files/stage-3-4.png'
            },
            {
                id: 'questionnaires',
                imagePath: 'marketing_site/smart-files/stage-3-5.png'
            }
        ]
    },
    {
        transId: 'feedback',
        sections: [
            {
                id: 'feedback',
                imagePath: 'marketing_site/smart-files/stage-4-2.png'
            },
            {
                id: 'reviews',
                imagePath: 'marketing_site/smart-files/stage-4-3.png'
            },
            {
                id: 'questionnaires',
                imagePath: 'marketing_site/smart-files/stage-4-1.png'
            }
        ]
    }
];

interface Props {
    showScrollToArrow?: boolean;
}

const TemplateTabsBlock = ({ showScrollToArrow = true }: Props) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();
    const [selectedTabId, setSelectedTabId] = useState(values[0].transId);

    const updateSelectedTabId = useCallback(nextSelectedTabId => {
        setSelectedTabId(nextSelectedTabId);
    }, []);

    const tabsContentDesktop = values.map(({ transId, sections }) => ({
        name: t(`TemplateTabsBlock.tabs.${transId}.tabButtonTitle`),
        id: transId,
        panel: (
            <TemplateFeatureBlock
                key={transId}
                transId={transId}
                values={sections}
                shouldAnimate={selectedTabId === transId}
            />
        )
    }));

    const scrollToView = () => {
        AnalyticsService.trackClick(AnalyticsEvents.interactive_files_scroll, {
            source: 'learn more scroll button'
        });
        const element = document.querySelector('.template-tabs-block');
        if (element !== null) {
            var headerOffset = 150;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition =
                elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };
    const tabsContentMobile = useMemo(
        () =>
            values.map(({ transId, sections }) => {
                const sliderContent = (
                    <Swiper
                        key={transId}
                        className="mySwiper"
                        centeredSlides={true}
                        slidesPerView={1}
                        initialSlide={1}
                        loop={false}
                        navigation={false}
                        pagination={true}
                        mousewheel={true}
                        draggable={false}
                        autoplay={{
                            delay: 2500,
                            pauseOnMouseEnter: true
                        }}
                        keyboard={true}
                        modules={[Pagination, Mousewheel, Keyboard, Autoplay]}
                    >
                        {sections.map(({ id, imagePath }) => (
                            <SwiperSlide key={id}>
                                <div className="swiper__box">
                                    <ImageTextElement
                                        imagePath={imagePath}
                                        mediaFlags={{
                                            lazyLoad: false
                                        }}
                                        title={t(
                                            `TemplateTabsBlock.tabs.${transId}.buttons.${id}`
                                        )}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                );

                return {
                    name: t(`TemplateTabsBlock.tabs.${transId}.tabButtonTitle`),
                    id: transId,
                    panel: sliderContent
                };
            }),
        []
    );

    return (
        <div
            className={`template-tabs-block ${
                showScrollToArrow ? 'template-tabs-block--show-arrow' : ''
            }`}
        >
            {showScrollToArrow && (
                <div
                    className="template-tabs-block__learn-more"
                    onClick={scrollToView}
                >
                    <p>{t('InteractiveFilesBlock.learnMore')}</p>
                    <HBMediaElement
                        name="marketing_site/smart-files/second-module-arrow-down-24-rebrand.svg"
                        forceImgTag
                    />
                </div>
            )}

            <TabsBlock
                title={t('TemplateTabsBlock.title')}
                tabsContent={
                    breakpoints.large ? tabsContentMobile : tabsContentDesktop
                }
                updateSelectedTabId={updateSelectedTabId}
                source="template_tabs_block"
            />
        </div>
    );
};

export default memo(TemplateTabsBlock);
