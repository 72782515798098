import { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './mobile-sticky-cta.scss';

const MobileStickyCTA = ({ pixelOffset = 100 }) => {
    const stickyCTARef = useRef();

    useEffect(() => {
        const handleScroll = () => {
            if (typeof window !== 'undefined' && stickyCTARef.current) {
                if (window.scrollY > pixelOffset) {
                    stickyCTARef.current.classList.add(
                        'mobile-sticky-cta--fixed'
                    );
                } else {
                    stickyCTARef.current.classList.remove(
                        'mobile-sticky-cta--fixed'
                    );
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pixelOffset]);

    const breakpoints = useBreakpoint();

    return breakpoints.mediumUp ? null : (
        <div className="mobile-sticky-cta" ref={stickyCTARef}>
            <RegistrationForm source="mobile sticky CTA" />
        </div>
    );
};

MobileStickyCTA.propTypes = {
    pixelOffset: PropTypes.number,
    delayDisplay: PropTypes.bool
};

export default memo(MobileStickyCTA);
