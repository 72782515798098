import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigation, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import HBText from '../../honeybook-ui/HBText/HBText';
import HBQuote from '../../HBQuote/HBQuote';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';

import ArrowIcon from '../../../svg/arrow-left-line.inline.svg';
import ChevronLeft from '../../../svg/chevron-left.inline.svg';

import 'swiper/css';
import './members-spotlight.scss';

const MembersSpotlight = () => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSetCurrentSlide = ({ activeIndex }) => {
        AnalyticsService.trackClick(
            AnalyticsEvents.reviews_carousel_navigation,
            {
                source: 'members spotlight',
                direction: activeIndex < currentSlide ? 'left' : 'right'
            }
        );

        setCurrentSlide(activeIndex + 1);
    };

    const caseStudies = [
        {
            imageUrl:
                'marketing_site/reviews/membersSpotlight/Desktop/india.png',
            imageUrlMweb:
                'marketing_site/reviews/membersSpotlight/Mobile/member-01-mweb_2x.png',
            transId: 'caseStudy1',
            linkUrl: '/blog/india-earl'
        },
        {
            imageUrl:
                'marketing_site/reviews/membersSpotlight/Desktop/Image_2x.png',
            imageUrlMweb:
                'marketing_site/reviews/membersSpotlight/Mobile/member-02-mweb_2x.png',
            transId: 'caseStudy2',
            linkUrl: '/blog/stefani-lefler'
        },
        {
            imageUrl:
                'marketing_site/reviews/membersSpotlight/Desktop/mary.png',
            imageUrlMweb:
                'marketing_site/reviews/membersSpotlight/Mobile/member-03-mweb_2x.png',
            transId: 'caseStudy3',
            linkUrl: '/blog/honeybook-for-photographers'
        }
    ];

    return (
        <div className="members-spotlight" id="members-spotlight">
            <FeatureBlockSection>
                <FeatureBlockContent customClass="members-spotlight__container">
                    <HBText
                        tag="h2"
                        type="title-m"
                        customClass="members-spotlight__title"
                    >
                        {t(`ReviewsPage.membersSpotlight.title`)}
                    </HBText>
                    <HBText
                        tag="h3"
                        type="body-l"
                        customClass="members-spotlight__subtitle"
                    >
                        {t(`ReviewsPage.membersSpotlight.subtitle`)}
                    </HBText>
                </FeatureBlockContent>
            </FeatureBlockSection>
            <FeatureBlockSection customClass="swiper-container">
                <FeatureBlockContent>
                    <Swiper
                        onSwiper={swiper =>
                            setCurrentSlide(swiper.activeIndex + 1)
                        }
                        onSlideChange={handleSetCurrentSlide}
                        className="members-spotlight__swiper"
                        slidesPerView={1}
                        draggable={true}
                        mousewheel={false}
                        spaceBetween={24}
                        speed={750}
                        navigation={{
                            prevEl: '.swiper-container__prev',
                            nextEl: '.swiper-container__next'
                        }}
                        modules={[Navigation, Keyboard]}
                    >
                        {caseStudies.map(
                            ({ imageUrl, imageUrlMweb, transId, linkUrl }) => {
                                const image = breakpoints.mediumUp
                                    ? imageUrl
                                    : imageUrlMweb;

                                return (
                                    <SwiperSlide key={transId}>
                                        <div
                                            className={`members-spotlight__case-study ${
                                                image
                                                    ? ''
                                                    : 'members-spotlight__case-study--text'
                                            } ${
                                                linkUrl
                                                    ? ''
                                                    : 'members-spotlight__case-study--final'
                                            }
                                    ${`members-spotlight__case-study--${transId}`}`}
                                        >
                                            <div className="members-spotlight__text-container">
                                                <HBText
                                                    tag="h4"
                                                    type="body-xl"
                                                    customClass="members-spotlight__case-study__name"
                                                >
                                                    {t(
                                                        `ReviewsPage.membersSpotlight.caseStudies.${transId}.name`
                                                    )}
                                                </HBText>
                                                <HBQuote customClass="members-spotlight__case-study__quote">
                                                    {t(
                                                        `ReviewsPage.membersSpotlight.caseStudies.${transId}.quote`
                                                    )}
                                                </HBQuote>

                                                <a
                                                    className="members-spotlight__case-study__link"
                                                    href={linkUrl}
                                                >
                                                    {t(
                                                        `ReviewsPage.membersSpotlight.caseStudies.${transId}.readMore`
                                                    )}
                                                    <ChevronLeft />
                                                </a>
                                            </div>
                                            <div className="members-spotlight__image-container">
                                                <HBMediaElement
                                                    name={image}
                                                    customClass="members-spotlight__image"
                                                    lazyLoad={false}
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                );
                            }
                        )}
                        <div className="swiper-container__controls">
                            <div
                                className={`swiper-container__prev ${
                                    currentSlide <= 1
                                        ? 'swiper-container__prev--disabled'
                                        : ''
                                }`}
                            >
                                <ArrowIcon />
                            </div>
                            <div
                                className={`swiper-container__next ${
                                    currentSlide > caseStudies.length - 1
                                        ? 'swiper-container__next--disabled'
                                        : ''
                                }`}
                            >
                                <ArrowIcon />
                            </div>
                            <HBText
                                tag="p"
                                type="body-m"
                                customClass="swiper-container__controls__text"
                            >
                                {currentSlide.toString()} {t('of')}{' '}
                                {caseStudies.length.toString()}
                            </HBText>
                        </div>
                    </Swiper>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

export default MembersSpotlight;
