import { useState, useEffect } from 'react';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import { useTranslation } from 'react-i18next';
import HBText from '../../honeybook-ui/HBText/HBText';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Keyboard, Autoplay } from 'swiper';
import Star from '../../../svg/Star.inline.svg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './template-preview-slider-hero.scss';

interface Slides {
    id: string;
    transId: string;
    imageUrlDesktop: string;
    imageUrlMobile: string;
    templateId: string;
}

interface Props {
    customClass?: string;
}

const AUTOPLAY_DELAY = 2500;

const slides: Slides[] = [
    {
        id: 'simpleInvoice',
        transId: 'templatePreviewSlider.slides.slide1',
        imageUrlDesktop:
            'marketing_site/invoice-template/slides/Simpleinvoice_desktop.png',
        imageUrlMobile:
            'marketing_site/invoice-template/slides/Simpleinvoice_mob.png',
        templateId: '647616d394b227051551b8f8'
    },
    {
        id: 'proposalBooking',
        transId: 'templatePreviewSlider.slides.slide2',
        imageUrlDesktop:
            'marketing_site/invoice-template/slides/proposal_desktop.png',
        imageUrlMobile:
            'marketing_site/invoice-template/slides/proposal_mob.png',
        templateId: '64d4878388474725c3d97229'
    },
    {
        id: 'miniSession',
        transId: 'templatePreviewSlider.slides.slide3',
        imageUrlDesktop:
            'marketing_site/invoice-template/slides/minisession_desktop.png',
        imageUrlMobile:
            'marketing_site/invoice-template/slides/minisession_mob.png',
        templateId: '64d4878a8ca4a32c0708b555'
    },
    {
        id: 'instantBooking',
        transId: 'templatePreviewSlider.slides.slide4',
        imageUrlDesktop:
            'marketing_site/invoice-template/slides/instantbooking_desktop.png',
        imageUrlMobile:
            'marketing_site/invoice-template/slides/instantbooking_mob.png',
        templateId: '63d90d77ffaf4f0310911b4b'
    },
    {
        id: 'allInOne',
        transId: 'templatePreviewSlider.slides.slide5',
        imageUrlDesktop:
            'marketing_site/invoice-template/slides/allinone_desktop.png',
        imageUrlMobile:
            'marketing_site/invoice-template/slides/allinone_mob.png',
        templateId: '647ee710b061802bece34967'
    }
];

const TemplatePreviewSliderHero = ({ customClass = '' }: Props) => {
    const { t } = useTranslation();

    const [swiperSlides, setSwiperSlides] = useState([slides[0]]);

    useEffect(() => {
        setSwiperSlides(slides);
    }, []);
    return (
        <FeatureBlockSection
            customClass={`template-preview-slider-hero ${customClass}`}
        >
            <FeatureBlockContent>
                <div className="template-preview-slider-hero__column template-preview-slider-hero__column--text">
                    <HBText
                        tag="p"
                        type="body-xs"
                        customClass="template-preview-slider-hero__label"
                    >
                        {t('templatePreviewSliderHero.label')}
                    </HBText>
                    <HBText
                        tag="h1"
                        type="title-l"
                        customClass="template-preview-slider-hero__title"
                    >
                        {t('templatePreviewSliderHero.title')}
                    </HBText>
                    <HBText
                        tag="h2"
                        type="body-xl"
                        customClass="template-preview-slider-hero__subtitle"
                    >
                        {t('templatePreviewSliderHero.subtitle')}
                    </HBText>
                    <RegistrationForm source="hero" showNoCcRequired />
                </div>
                <div className="template-preview-slider-hero__column template-preview-slider-hero__column--media">
                    <HBMediaElement
                        customClass="template-preview-slider-hero__calendar-img"
                        name="marketing_site/online-invoices/00_Hero/Desktop/Group_48096347.png"
                        forceImgTag
                    />
                    <HBMediaElement
                        customClass="template-preview-slider-hero__dollar-img"
                        name="marketing_site/online-invoices/00_Hero/Desktop/Document.png"
                        forceImgTag
                    />
                    <Star className="template-preview-slider-hero__star template-preview-slider-hero__star--1" />
                    <Star className="template-preview-slider-hero__star template-preview-slider-hero__star--2" />
                    <Star className="template-preview-slider-hero__star template-preview-slider-hero__star--3" />
                    <div className="template-preview-slider-hero__preview-container">
                        <div className="template-preview-slider-hero__screen-container">
                            <div className="template-preview-slider-hero__desktop-preview">
                                <Swiper
                                    slidesPerView={1}
                                    initialSlide={0}
                                    loop={true}
                                    autoplay={{ delay: AUTOPLAY_DELAY }}
                                    draggable={false}
                                    allowTouchMove={false}
                                    centeredSlides
                                    keyboard={true}
                                    modules={[Navigation, Keyboard, Autoplay]}
                                >
                                    {swiperSlides.map(
                                        ({
                                            templateId,
                                            transId,
                                            imageUrlDesktop
                                        }) => {
                                            return (
                                                <SwiperSlide key={templateId}>
                                                    <HBMediaElement
                                                        customClass="template-preview-slider-hero__slide-img"
                                                        lazyLoad={false}
                                                        name={imageUrlDesktop}
                                                        alt={t(
                                                            `${transId}.title`
                                                        )}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                                </Swiper>
                            </div>
                            <div className="template-preview-slider-hero__mobile-preview">
                                <Swiper
                                    slidesPerView={1}
                                    initialSlide={0}
                                    loop={true}
                                    autoplay={{ delay: AUTOPLAY_DELAY }}
                                    draggable={false}
                                    allowTouchMove={false}
                                    centeredSlides
                                    keyboard={true}
                                    modules={[Navigation, Keyboard, Autoplay]}
                                >
                                    {swiperSlides.map(
                                        ({
                                            templateId,
                                            transId,
                                            imageUrlMobile
                                        }) => {
                                            return (
                                                <SwiperSlide key={templateId}>
                                                    <HBMediaElement
                                                        customClass="template-preview-slider-hero__slide-img"
                                                        lazyLoad={false}
                                                        name={imageUrlMobile}
                                                        alt={t(
                                                            `${transId}.title`
                                                        )}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default TemplatePreviewSliderHero;
