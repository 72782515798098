import { memo } from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';

import type { MouseEventHandler } from 'react';

interface Props {
    name: string;
    size?: string;
    customClass?: string;
    onClick?: MouseEventHandler<HTMLSpanElement>;
    e2eTestLocator?: string;
    'data-testid'?: string;
}

function HBIcon({
    name,
    customClass,
    size,
    onClick,
    e2eTestLocator,
    'data-testid': testId
}: Props): JSX.Element {
    const iconClass = classnames(`icon-container ${name}-container`, {
        [`${customClass}`]: Boolean(customClass),
        [`icon-${size}`]: Boolean(size)
    });

    // TODO: Shouldn't span be a button?
    return (
        <span
            className={iconClass}
            onClick={onClick}
            e2e-test-locator={e2eTestLocator}
            data-testid={testId}
        >
            <i className={`icon icon-hb-${name}`} />
        </span>
    );
}

HBIcon.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.string,
    customClass: PropTypes.string,
    onClick: PropTypes.func,
    e2eTestLocator: PropTypes.string
};

export default memo(HBIcon);
