import { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './testimonial-carousel-block.scss';

import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import TestimonialCarouselSlide from '../../TestimonialCarouselSlide/TestimonialCarouselSlide';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Autoplay } from 'swiper';

import AnalyticsService from '../../../Services/AnalyticsService';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const defaultSlideData = [
    {
        id: 'bray',
        imageUrl: 'marketing_site/testimonial-avatars/member-2.png'
    },
    {
        id: 'ashley',
        imageUrl: 'marketing_site/testimonial-avatars/member-3.png'
    },
    {
        id: 'alexis',
        imageUrl: 'marketing_site/testimonial-avatars/member-1.png'
    },
    {
        id: 'pamela',
        imageUrl: 'marketing_site/testimonial-avatars/member-4.png'
    },
    {
        id: 'amber',
        imageUrl: 'marketing_site/testimonial-avatars/member-5.png'
    }
];

const TestimonialCarouselBlock = ({ slideData = defaultSlideData }) => {
    const { t } = useTranslation();

    return (
        <div className="testimonial-carousel-block">
            <FeatureBlockSection customClass="testimonial-carousel-block__swiper-container">
                <FeatureBlockContent
                    columnWidth={1}
                    customClass="feature-block--swiper"
                >
                    <div className="swiper-container">
                        <Swiper
                            className="mySwiper"
                            slidesPerView={3}
                            initialSlide={1}
                            autoplay={{
                                delay: 2500,
                                pauseOnMouseEnter: true
                            }}
                            loop
                            draggable
                            keyboard
                            centeredSlides
                            slideToClickedSlide
                            modules={[Keyboard, Autoplay]}
                        >
                            {slideData.map(({ id, imageUrl }) => (
                                <SwiperSlide key={id}>
                                    <div className="swiper__box">
                                        <TestimonialCarouselSlide
                                            name={t(
                                                `TestimonialCarouselBlock.testimonials.${id}.name`
                                            )}
                                            company={t(
                                                `TestimonialCarouselBlock.testimonials.${id}.company`
                                            )}
                                            jobTitle={t(
                                                `TestimonialCarouselBlock.testimonials.${id}.jobTitle`
                                            )}
                                            quote={t(
                                                `TestimonialCarouselBlock.testimonials.${id}.quote`
                                            )}
                                            imageUrl={imageUrl}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

TestimonialCarouselBlock.propTypes = {
    slideData: PropTypes.arrayOf(PropTypes.object)
};

export default memo(TestimonialCarouselBlock);
