import { memo, ReactElement } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

import './footer-nav-list.scss';

import type { MouseEvent as ReactMouseEvent } from 'react';
import NavigateLink from '../Link/NavigateLink';
import type { ILink } from '../Footer/Footer';
import IntercomService from '../../Services/IntercomService';

interface Props {
    links: ILink[];
}

function FooterNavList({ links }: Props): ReactElement {
    const { t } = useTranslation();

    const openIntercom = () => {
        IntercomService.showIntercom('footer');
    };

    const onClick = (event: ReactMouseEvent<HTMLAnchorElement>) => {
        const { link, id } = event.currentTarget.dataset;

        if (link) {
            AnalyticsService.track(
                AnalyticsEvents.footer_navigation_button_click,
                {
                    source: link
                }
            );
        }

        if (id === 'chat') {
            event.preventDefault();
            openIntercom();
        }

        return true;
    };

    return (
        <>
            {links.map(item => (
                <li key={item.id} className="footer-list-item">
                    {!item.link && item.clickHandler && (
                        <span
                            className="footer-link"
                            onClick={item.clickHandler}
                        >
                            {t(`footer.footerNavLinks.${[item.id]}`)}
                        </span>
                    )}
                    {item.link && (
                        <NavigateLink
                            source="footer"
                            className="footer-link"
                            dataLinkKey="data-link"
                            id={item.id}
                            link={item.link}
                            onClick={onClick}
                        >
                            {t(`footer.footerNavLinks.${[item.id]}`)}
                        </NavigateLink>
                    )}
                </li>
            ))}
        </>
    );
}

FooterNavList.propTypes = {
    links: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default memo(FooterNavList);
