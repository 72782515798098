import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import ContactImageTextBlock from '../components/FeatureBlocks/ContactImageTextBlock/ContactImageTextBlock';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';
import { FeatureBlockSection } from '../components/FeatureBlockElements';
import ContactUsHero from '../components/ContactUsHero/ContactUsHero';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';

import '../styles/contact-us.scss';

const ogTags = {
    url: 'https://www.honeybook.com/contact-us',
    title: 'Contact Us - Information and Resources',
    description:
        'All the information you need to get in contact with the HoneyBook team.'
};

const faqs = [
    {
        id: 'whatIs',
        answer: ''
    },
    {
        id: 'getStarted',
        answer: ''
    },
    {
        id: 'support',
        answer: ''
    },
    {
        id: 'HBCost',
        answer: ''
    },
    {
        id: 'whoUses',
        answer: ''
    },
    {
        id: 'features',
        answer: `#TEXT# <a href="/features">#LINK_TEXT#</a> #TEXT#`
    },
    {
        id: 'importTemplates',
        answer: `#TEXT# <a target="_blank" rel="noopener noreferrer" href="https://help.honeybook.com/en/articles/2902659-honeybook-s-free-account-setup">#LINK_TEXT#</a> #TEXT#`
    },
    {
        id: 'crm',
        answer: `#TEXT# <a href="https://help.honeybook.com/integrations/quickbooks-integration/quickbooks-integration-overview-faq">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/integrations/calendly-and-zapier-integrations/calendly-integration-faq">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/integrations/calendly-and-zapier-integrations/integrating-with-zapier">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/integrations/gmail-and-google-integration/syncing-with-gmail">#LINK_TEXT#</a> #TEXT# <span>##AND-placeholder##</span> #TEXT# <a href="https://help.honeybook.com/integrations/gmail-and-google-integration/understanding-your-google-calendar-integration">#LINK_TEXT#</a>`
    }
];

function ContactUsTemplate() {
    const { t } = useTranslation();

    return (
        <Layout ogTags={ogTags}>
            <div className="contact-us">
                <ContactUsHero />

                <FeatureBlockSection customClass="contact-us__intro">
                    <p className="contact-us__intro-description">
                        {t(`contactUsPage.description`)}
                    </p>
                </FeatureBlockSection>

                <ContactImageTextBlock />

                <div className="contact-us__map">
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe
                                title="honeyBookMap"
                                width="900"
                                height="450"
                                id="gmap_canvas"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.421912636066!2d-122.3990926835893!3d37.780150979758695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085807f40be2113%3A0x7acff7045a8fbe0f!2sHoneyBook!5e0!3m2!1sen!2sus!4v1649196451193!5m2!1sen!2sus"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight={0}
                                marginWidth={0}
                            />
                        </div>
                    </div>
                </div>

                <FAQBlock faqs={faqs} title={t(`contactUsPage.faqTitle`)} />

                <FreeToTryBlockRebrand />
            </div>
        </Layout>
    );
}

export default ContactUsTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "contactUs", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
