import { useSignatureTemplateData } from './useSignatureTemplateData';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import {
    SignatureField,
    SignatureImage,
    SignatureJobTitleAndCompany,
    SignatureSocialIcons
} from '../Email-signature-template';

function EmailSignatureTemplateUrban() {
    const {
        name,
        imageUrl,
        jobTitle,
        company,
        phone,
        email,
        address,
        website,
        facebookUrl,
        twitterUrl,
        linkedinUrl,
        instagramUrl,
        pinterestUrl,
        youtubeUrl
    } = useSignatureTemplateData();

    const socialUrls = {
        facebookUrl,
        twitterUrl,
        linkedinUrl,
        instagramUrl,
        pinterestUrl,
        youtubeUrl
    };

    return (
        <div className="email-signature-template email-signature-template--urban">
            <SignatureImage imageUrl={imageUrl} />

            <div className="email-signature-template__name-container">
                <SignatureField fieldName="name" fieldValue={name} />
                <SignatureSocialIcons socialUrls={socialUrls} />
            </div>

            <div className="email-signature-template__line">
                <SignatureJobTitleAndCompany
                    jobTitle={jobTitle}
                    company={company}
                    spacer="at"
                />
            </div>

            <div className="email-signature-template__line email-signature-template__line--with-border">
                <SignatureField
                    fieldName="phone"
                    fieldValue={phone}
                    elPrefix={
                        <HBMediaElement name="marketing_site/email-signature/info-icons/Mobile_2_2x.png" />
                    }
                />
                <SignatureField
                    fieldName="email"
                    fieldValue={email}
                    elPrefix={
                        <HBMediaElement name="marketing_site/email-signature/info-icons/Web_2_2x.png" />
                    }
                />
            </div>

            <SignatureField fieldName="address" fieldValue={address} />
            <SignatureField
                fieldName="website"
                fieldValue={website}
                href={website}
            />
        </div>
    );
}

export default EmailSignatureTemplateUrban;
