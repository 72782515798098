module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en"],"defaultLanguage":"en","siteUrl":"https://honeybook.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":"."},"pages":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HoneyBook: Clientflow Management for Independent Businesses","short_name":"HoneyBook","start_url":"/","background_color":"#131416FF","theme_color":"#131416FF","display":"standalone","icon":"src/images/favicon.hb.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2645a9c26c08af7005b81d59237fa59e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"medium":"(max-width: 768px)","large":"(max-width: 992px)","xlarge":"(max-width: 1200px)","largeUp":"(min-width: 992px)","mediumUp":"(min-width: 768px)","bptestQuery":"(min-width: 800px)"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
