import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './increase-chance-of-winning-block.scss';

const IncreaseChanceOfWinningBlock = ({ t }) => {
    return (
        <FeatureBlockSection
            lightBackground
            customClass="increase-chance-of-winning"
        >
            <FeatureBlockContent position="center">
                <HBMediaElement
                    name="marketing_site/scheduler-page/rocket.svg"
                    customClass="rocket-image"
                    forceImgTag
                />
                <FeatureBlockText
                    primary={t(`schedulerPage.increaseChanceOfWinning`)}
                    smallSubtext={t(`schedulerPage.honeyBookDataReport`)}
                    fullWidth
                />
                <RegistrationForm
                    source="scheduler increase chance"
                    showNoCcRequired
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

IncreaseChanceOfWinningBlock.propTypes = {
    t: PropTypes.func.isRequired
};

export default withTranslation()(IncreaseChanceOfWinningBlock);
