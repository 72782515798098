const TESTS_COMBINATIONS_VARIABLE_NAME = 'testsCombinations';
export const VARIANTS_PROPS_NAME = 'variantPerTest';
export const FALLBACK_VARIANT = {
    STORAGE_KEY: 'fallback_variants',
    VARIANT_NAME_FOR_TRACKING: 'missing_fallback_variant',
    CLASS_NAME: 'fallback-variants'
};
export const TESTS_COMBINATION = {
    FILE_NAME: 'tests-combinations.js',
    EXPORT_DECLARATION: `export const ${TESTS_COMBINATIONS_VARIABLE_NAME} = `
};
