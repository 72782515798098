import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './book-meetings-faster-block.scss';

const BookMeetingsFasterBlock = ({ t }) => {
    return (
        <FeatureBlockSection
            lightBackground
            customClass="book-meetings-faster-block"
        >
            <FeatureBlockContent position="center">
                <FeatureBlockText
                    primary={t('schedulerPage.bookFaster')}
                    fullWidth
                />
                <HBMediaElement
                    name="marketing_site/scheduler-page/scheduled.svg"
                    forceImgTag
                    lazyLoad={false}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

BookMeetingsFasterBlock.propTypes = {
    t: PropTypes.func.isRequired
};

export default withTranslation()(BookMeetingsFasterBlock);
