import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout/Layout';
import AdobeStore from '../stores/AdobeStore';

import OneBusinessPlatformHero from '../components/Hero/OneBusinessPlatformHero/OneBusinessPlatformHero';
import OneBusinessPlatformBlock from '../components/FeatureBlocks/OneBusincessPlatformBlock/OneBusinessPlatformBlock';

import '../styles/one-business-platform.scss';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import TestimonialCarouselCardBlock from '../components/FeatureBlocks/TestimonialCarouselCardBlock/TestimonialCarouselCardBlock';

const ogTags = {
    url: 'https://www.honeybook.com/adobe',
    title: 'Clientflow Management for Independent Businesses',
    description:
        'Book clients, manage projects, and get paid all in one place on HoneyBook. The clientflow management platform for independent businesses. Get started with a free 7-day trial.',
    noIndex: true
};

const AdobeTOSLink =
    'https://www.honeybook.com/lp/honeybook-adobe-terms-of-service';

const faqs = [
    {
        id: 'whatIs'
    },
    {
        id: 'HBCostAdobe',
        answer: '#TEXT# <a href="/lp/honeybook-adobe-terms-of-service">#LINK_TEXT#</a>#TEXT#'
    },
    {
        id: 'adobeWork'
    },
    {
        id: 'HBTemplates',
        answer: '#TEXT# <a href="/templates">#LINK_TEXT#</a>#TEXT#'
    },
    {
        id: 'integration'
    }
];

function AdobeC(): JSX.Element {
    const { t } = useTranslation();

    return (
        <section className="one-business-platform adobe adobe--c">
            <Layout ogTags={ogTags}>
                <OneBusinessPlatformHero
                    customTitle={t('adobeC.hero.title')}
                    titleImage="marketing_site/adobe/logos.png"
                    customSubtitle={t('adobeC.hero.subtitle')}
                    customCtaText={t('adobeC.hero.customButtonText')}
                />

                {AdobeStore.blockContent.map((block, index) => {
                    const isEven =
                        index % 2 === 1
                            ? 'one-business-platform-block__even'
                            : '';

                    return (
                        <OneBusinessPlatformBlock
                            block={block}
                            key={block.id}
                            t={t}
                            id={block.id}
                            customClass={`one-business-platform-block ${isEven}`}
                            index={index}
                            customTransId="adobeC.blockContent"
                        />
                    );
                })}
                <TestimonialCarouselCardBlock />
                <FAQBlock title={t('adobeA.faqTitle')} faqs={faqs} />
                <PromoBlockLeft
                    titleImage="marketing_site/adobe/logos.png"
                    title={t('adobe50.PromoBlockLeftYellow.title')}
                    subtitle={t('adobe50.PromoBlockLeftYellow.subtitle')}
                    customClass="promo-block-left--yellow"
                    source="promo block left - yellow"
                    buttonStyle="primary--dark"
                    tosUrl={AdobeTOSLink}
                    customTosText={t('adobe50.PromoBlockLeftYellow.tosText')}
                />
            </Layout>
        </section>
    );
}

export default AdobeC;
