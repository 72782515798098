import React from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import ImageTextElement from '../../ImageTextElement/ImageTextElement';
import { FeatureBlockSection } from '../../FeatureBlockElements';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './contact-image-text-block.scss';

const ContactImageTextBlock = ({ t }) => {
    const handleLinkClick = source => {
        AnalyticsService.trackClick(AnalyticsEvents.contact_us_page, {
            position: 'contact us',
            source
        });
    };

    return (
        <FeatureBlockSection customClass="contact-us__image-text-block">
            <ImageTextElement
                customClass="contact-us__image-text-element"
                imagePath="marketing_site/contact_us_page/sales"
                title={t(`featureBlock.contact.sales.title`)}
                description={t(`featureBlock.contact.sales.description`)}
            >
                <p className="image-text-element__description">
                    {t(`featureBlock.contact.sales.email`)}:{' '}
                    <a
                        onClick={() => handleLinkClick('sales-email')}
                        href="mailto:concierge@honeybook.com"
                    >
                        concierge@honeybook.com
                    </a>
                </p>
            </ImageTextElement>
            <ImageTextElement
                customClass="contact-us__image-text-element"
                imagePath="marketing_site/contact_us_page/support"
                title={t(`featureBlock.contact.support.title`)}
                description={t(`featureBlock.contact.support.description`)}
            >
                <p className="image-text-element__description">
                    {t(`featureBlock.contact.support.email`)}:{' '}
                    <a
                        onClick={() => handleLinkClick('support-email')}
                        href="mailto:concierge@honeybook.com"
                    >
                        concierge@honeybook.com
                    </a>
                </p>
                <p className="image-text-element__description">
                    {t(`featureBlock.contact.support.tel`)}:{' '}
                    <a
                        onClick={() => handleLinkClick('support-tel')}
                        href="tel:1-415-591-7768"
                    >
                        415-591-7768
                    </a>
                </p>
            </ImageTextElement>
            <ImageTextElement
                customClass="contact-us__image-text-element"
                imagePath="marketing_site/contact_us_page/security"
                title={t(`featureBlock.contact.security.title`)}
                description={t(`featureBlock.contact.security.description`)}
            >
                <p className="image-text-element__description">
                    {t(`featureBlock.contact.security.email`)}:{' '}
                    <a
                        onClick={() => handleLinkClick('security-email')}
                        href="mailto:security@honeybook.com"
                    >
                        security@honeybook.com
                    </a>
                </p>
                <p className="image-text-element__description">
                    <Trans
                        t={t}
                        i18nKey={'featureBlock.contact.security.researcher'}
                    >
                        <br />
                        <a
                            onClick={() =>
                                handleLinkClick(
                                    'security-bug-bounty-program-link'
                                )
                            }
                            href="https://www.honeybook.com/lp/bug-bounty-program-page?utm_source=contactus"
                        />
                    </Trans>
                </p>
                <p className="image-text-element__description">
                    {t(`featureBlock.contact.security.email`)}:{' '}
                    <a
                        onClick={() => handleLinkClick('security-email')}
                        href="mailto:BugBounty@honeybook.com"
                    >
                        BugBounty@honeybook.com
                    </a>
                </p>
            </ImageTextElement>
        </FeatureBlockSection>
    );
};

ContactImageTextBlock.propTypes = {
    t: PropTypes.func
};

export default withTranslation()(ContactImageTextBlock);
