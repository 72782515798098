import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import FeatureTitleList from '../../FeatureTitleList/FeatureTitleList';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockImage
} from '../../FeatureBlockElements';

import './workflows-follow-up.scss';

const WorkflowsFollowUpBlock = ({ lightBackground, darkBackground }) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const [ref, inView] = useInView({
        threshold: 0.5
    });

    const activeClass = inView ? 'feature-block--in-view' : '';

    const listName = 'workflowsPage.followUp.list';
    const listItems = Object.keys(
        t(listName, {
            returnObjects: true
        })
    ).map(key => ({ id: key }));

    return (
        <div ref={ref}>
            <FeatureBlockSection
                lightBackground={lightBackground}
                darkBackground={darkBackground}
                customClass={`workflows-follow-up ${activeClass}`}
            >
                <FeatureBlockContent customClass="workflows-follow-up__text-container">
                    <FeatureTitleList
                        title={t(`workflowsPage.followUp.title`)}
                        listName="workflowsPage.followUp.list"
                        items={listItems}
                        withRegistrationForm
                        registrationSource="workflows-follow-up"
                    />
                </FeatureBlockContent>
                <FeatureBlockContent customClass="workflows-follow-up__image-container">
                    {breakpoint.medium ? (
                        <HBMediaElement
                            name="marketing_site/lp/workflows-01-mobile_2x.png"
                            customClass="workflows-customize__img workflows-customize__img--mweb"
                        />
                    ) : (
                        <>
                            <FeatureBlockImage
                                customClass="workflows-follow-up__img workflows-follow-up__img--box"
                                featureImage="marketing_site/workflows/workspace-col.png"
                                width={340}
                                crop="scale"
                            />
                            <HBMediaElement
                                name="marketing_site/workflows/Group_34.png"
                                width={360}
                                customClass="workflows-follow-up__img workflows-follow-up__img--bell"
                            />
                        </>
                    )}
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

WorkflowsFollowUpBlock.propTypes = {
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool
};

export default WorkflowsFollowUpBlock;
