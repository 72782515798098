import React from 'react';
import PropTypes from 'prop-types';

import './card-body.scss';

const CardBody = ({ children }) => {
    return <div className="card__body">{children}</div>;
};

CardBody.propTypes = {
    children: PropTypes.node.isRequired
};

export default CardBody;
