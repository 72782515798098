import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';
import Card from '../HBCard/Card';
import { FeatureBlockUserQuote } from '../FeatureBlockElements';

import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

import './testimonials-carousel.scss';

interface Props {
    quotes: {
        name: string;
        transKey: string;
        thumbImage: string;
    }[];
}

function TestimonialsCarousel({ quotes }: Props): JSX.Element {
    const { t } = useTranslation();

    const [currentIndex, setCurrentIndex] = useState(0);

    const onClickArrowLeft = useCallback(() => {
        AnalyticsService.trackClick(
            AnalyticsEvents.testimonial_carousel_navigate
        );

        setCurrentIndex((index: number) => {
            return index <= 0 ? quotes.length - 1 : currentIndex - 1;
        });
    }, [currentIndex, quotes.length]);

    const onClickArrowRight = useCallback(() => {
        AnalyticsService.trackClick(
            AnalyticsEvents.testimonial_carousel_navigate
        );

        setCurrentIndex((index: number) => {
            return index >= quotes.length - 1 ? 0 : currentIndex + 1;
        });
    }, [currentIndex, quotes.length]);

    return (
        <div className="testimonials-carousel">
            <Card>
                <FeatureBlockUserQuote
                    hideQuotes
                    name={quotes[currentIndex].name}
                    jobTitle={t(`${quotes[currentIndex].transKey}.jobTitle`)}
                    quote={t(`${quotes[currentIndex].transKey}.quote`)}
                    avatar={quotes[currentIndex].thumbImage}
                    avatarWidth={40}
                    avatarHeight={40}
                    avatarCrop="thumb"
                    avatarGravity="face"
                />
            </Card>
            <HBIcon
                onClick={onClickArrowLeft}
                customClass="testimonials-carousel__nav-arrow testimonials-carousel__nav-arrow--left"
                name="nx-nx-arrow-left-42"
            />
            <HBIcon
                onClick={onClickArrowRight}
                customClass="testimonials-carousel__nav-arrow testimonials-carousel__nav-arrow--right"
                name="nx-nx-arrow-right-42"
            />
        </div>
    );
}

TestimonialsCarousel.propTypes = {
    quotes: PropTypes.arrayOf(PropTypes.shape({}))
};

export default memo(TestimonialsCarousel);
