import { useTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import HBText from '../../honeybook-ui/HBText/HBText';

import './need-mission-statement-block.scss';

const textTransIds = ['direction', 'connect', 'goals'];

const NeedMissionStatementBlock = ({
    transId = 'missionStatement.needMissionStatement',
    blockTextTransIds = textTransIds
}) => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="need-mission-statement-block">
            <FeatureBlockContent>
                <HBText
                    customClass="need-mission-statement-block__title"
                    tag="h2"
                    type="title-m"
                >
                    {t(`${transId}.title`)}
                </HBText>
                <div className="need-mission-statement-block__text-container">
                    {blockTextTransIds.map(blockTransId => (
                        <div
                            className="need-mission-statement-block__text"
                            key={blockTransId}
                        >
                            <HBText
                                tag="p"
                                type="subtitle-l"
                                customClass="need-mission-statement-block__subtitle"
                            >
                                {t(`${transId}.${blockTransId}.title`)}
                            </HBText>
                            <HBText
                                tag="p"
                                type="body-s"
                                customClass="need-mission-statement-block__description"
                            >
                                {t(`${transId}.${blockTransId}.description`)}
                            </HBText>
                        </div>
                    ))}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default NeedMissionStatementBlock;
