import {
    MAP_OF_PROMO_CODES_CONFIG,
    DEFAULT_PROMO_CODE
} from './../constants/promoCodes';
import UtilsService from '../Services/UtilsService';

export const usePromoCode = () => {
    const userPromoCode =
        UtilsService.getCouponFromQueryParams() ||
        (UtilsService.isBrowser() && UtilsService.getCouponFromCookies()) ||
        undefined;

    const isUserPromoCodeValid =
        !!userPromoCode && !!MAP_OF_PROMO_CODES_CONFIG[userPromoCode];

    const promoCode = isUserPromoCodeValid ? userPromoCode : DEFAULT_PROMO_CODE;

    return {
        promoCode
    };
};
