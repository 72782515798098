import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { RecipientPageContext } from './recipient-page-context';
import {
    IncomingEvents,
    OutgoingEvents,
    TTemplateData
} from '../../PublicTemplatePreview/type';
import EventBus, { EventBusEmitFunctionArgs } from '../../../Services/EventBus';
import { INCOMING_EVENTS } from '../../PublicTemplatePreview/constants';

type Props = {
    children: ReactNode;
    templateData: TTemplateData;
};

export const RecipientPageProvider = ({ children, templateData }: Props) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [isIframeLoaded, setIsIframeLoaded] = useState(false);
    const postMessageToChild = useCallback(
        ({ data, event }: { event: OutgoingEvents; data? }) => {
            if (!iframeRef.current?.contentWindow) {
                return;
            }
            iframeRef.current.contentWindow.postMessage(
                { type: event, data },
                '*'
            );
        },
        []
    );

    const checkIsEventValid = useCallback(
        (event: IncomingEvents) => INCOMING_EVENTS.includes(event),
        []
    );

    const emitToIframeEvent = useCallback(
        ({ eventName, data }: EventBusEmitFunctionArgs) => {
            EventBus.emit({ eventName, data });
        },
        []
    );

    const contextValue = useMemo(
        () => ({
            iframeRef,
            postMessageToChild,
            checkIsEventValid,
            emitToIframeEvent,
            templateData,
            isIframeLoaded,
            setIsIframeLoaded
        }),
        [
            iframeRef,
            postMessageToChild,
            checkIsEventValid,
            emitToIframeEvent,
            templateData,
            isIframeLoaded,
            setIsIframeLoaded
        ]
    );

    return (
        <RecipientPageContext.Provider value={contextValue}>
            {children}
        </RecipientPageContext.Provider>
    );
};
