import { memo } from 'react';
import * as PropTypes from 'prop-types';

import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

import UtilsService from '../../../Services/UtilsService';
import HBQuote from '../../HBQuote/HBQuote';

import './feature-block-user-quote.scss';

import type { Display } from '../../../Services/UtilsService';

const hideFor = (display?: Display) => {
    if (display) {
        const hiddenFor = UtilsService.screens(display);

        return `feature-block__user-quote--all ${hiddenFor}`;
    }

    return 'feature-block__user-quote--all';
};

interface Props {
    avatar: string;
    name: string;
    jobTitle?: string;
    quote?: string;
    display?: Display;
    avatarWidth?: number;
    avatarCrop?: string;
    avatarHeight?: number;
    avatarDpr?: number;
    avatarGravity?: string;
    hideQuotes?: boolean;
    lazyLoad?: boolean;
}

const FeatureBlockUserQuote = ({
    avatar,
    name,
    jobTitle,
    quote,
    display,
    avatarCrop,
    avatarHeight,
    avatarDpr = 2,
    avatarWidth = 64,
    avatarGravity,
    hideQuotes,
    lazyLoad = true
}: Props): JSX.Element => {
    return (
        <div className={hideFor(display)}>
            <div className="feature-block__user-quote">
                <span className="feature-block__user-quote-avatar">
                    <HBMediaElement
                        name={avatar}
                        type="Image"
                        forceImgTag
                        width={avatarWidth}
                        crop={avatarCrop}
                        height={avatarHeight}
                        dpr={avatarDpr}
                        gravity={avatarGravity}
                        alt={name}
                        lazyLoad={lazyLoad}
                    />
                </span>

                <div className="feature-block__user-quote-info">
                    <p className="feature-block__user-quote-name">{name}</p>
                    <p className="feature-block__user-quote-job-title">
                        {jobTitle}
                    </p>
                </div>
            </div>

            <HBQuote
                hideQuotes={hideQuotes}
                customClass="feature-block__user-quote-text"
            >
                {quote}
            </HBQuote>
        </div>
    );
};

FeatureBlockUserQuote.propTypes = {
    avatar: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    jobTitle: PropTypes.string,
    quote: PropTypes.string.isRequired,
    display: PropTypes.objectOf(PropTypes.string),
    avatarWidth: PropTypes.number,
    avatarCrop: PropTypes.string,
    avatarHeight: PropTypes.number,
    avatarDpr: PropTypes.number,
    avatarGravity: PropTypes.string,
    hideQuotes: PropTypes.bool
};

export default memo(FeatureBlockUserQuote);
