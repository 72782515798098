import { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Card from '../../HBCard/Card';
import CardHeader from '../../HBCard/CardHeader/CardHeader';
import List from '../../List/List';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './business-personality-description-block.scss';

interface Props {
    resultKey: string;
}

const descriptionData = {
    listIcon: 'nx-check-16',

    visionaryRisingStar: {
        heading: `Defining <strong>qualities</strong> of VRS business owners`,
        bodyCopy: [
            'Congratulations! You’ve taken the first step towards that big dream of yours and started your own business (can we get a heck yeah!).',
            'You have big ideas and a vision for how to make things better—you started your business to change the world after all.',
            'You’re the person everyone comes to for problem solving because you can think way outside the box. You thrive on creative brainstorms, aren’t afraid of blue sky ideas and are always game to try something new.',
            'Above all else you’re committed to your mission and will stop at nothing to make it happen. You know a better future is within reach and it’s this vision that empowers you to break barriers and overcome the obstacles that discourage others.',
            'As a VRS you know why you’re here and what you want to accomplish, but the question is, where to start?'
        ],
        strengths: [
            { id: 'str1' },
            { id: 'str2' },
            { id: 'str3' },
            { id: 'str4' }
        ],
        opportunities: [
            { id: 'opp1' },
            { id: 'opp2' },
            { id: 'opp3' },
            { id: 'opp4' }
        ]
    },
    actualizerRisingStar: {
        heading: `Defining <strong>qualities</strong> of ARS business owners`,
        bodyCopy: [
            'When it comes to GSD (Getting Stuff Done), execution is your middle name. You effortlessly blend your creativity with an innate ability to outline a clear path to reach your goals. (Which isn’t always the case for many creatives!)',
            'While you have a solid understanding of your business’ big picture, you’re a natural at deconstructing those big goals into smaller pieces that are easy to achieve. And achieve you do! It’s rare that you miss your goals.',
            'From real-life friends to industry connections to your IG followers, the people in your life admire how you always know exactly what you need to do (and are fearless in figuring it out when you don’t!). You’re a go-getter, a master of to-do lists and a goal-tracking extraordinaire.',
            'You’re on the right path. And you already did the hardest part which is getting over any doubts and fears of following your passion.',
            'At this point in your business everything might feel like a struggle and you’re basically doing everything. There’s so much to learn. And while you’re great at doing All The Things, don’t forget to keep moving towards your big picture.'
        ],
        strengths: [
            { id: 'str1' },
            { id: 'str2' },
            { id: 'str3' },
            { id: 'str4' }
        ],
        opportunities: [
            { id: 'opp1' },
            { id: 'opp2' },
            { id: 'opp3' },
            { id: 'opp4' }
        ]
    },
    actualizerWaveMaker: {
        heading: `Defining <strong>qualities</strong> of AWM business owners`,
        bodyCopy: [
            'You’re on a roll! You’ve done the hardest part of surviving the early days. (Which, let’s be real, is hardly a surprise. It’s in your nature to GSD, Getting Stuff Done!)',
            'When it comes to GSD, execution is your middle name. You effortlessly blend your creativity with an innate ability to outline a clear path to reach your goals. (Which isn’t always the case for many creatives!)',
            'While you have a solid understanding of your business’ big picture, you’re a natural at deconstructing those big goals into smaller pieces that are easy to achieve. And achieve you do! It’s rare that you miss your goals.',
            'From real-life friends to industry connections to your IG followers, the people in your life admire how you always know exactly what you need to do (and are fearless in figuring it out when you don’t!). You’re a go-getter, a master of to-do lists and a goal-tracking extraordinaire.',
            'Now’s the time to combine your analytical side with your strong focus to leverage the traction you’ve created.'
        ],
        strengths: [
            { id: 'str1' },
            { id: 'str2' },
            { id: 'str3' },
            { id: 'str4' }
        ],
        opportunities: [
            { id: 'opp1' },
            { id: 'opp2' },
            { id: 'opp3' },
            { id: 'opp4' }
        ]
    },
    actualizerDrivingForce: {
        heading: `Defining <strong>qualities</strong> of ADF business owners`,
        bodyCopy: [
            'You don’t need us to say this, but we’re going to say it anyway: You’ve hit your stride, Actualizer! From friends to clients to followers, people in your immediate circle and beyond are noticing and admiring your accomplishments.',
            'When it comes to GSD (Getting Stuff Done), execution is your middle name. You effortlessly blend your creativity with an innate ability to outline a clear path to reach your goals. (Which isn’t always the case for many creatives!)',
            'While you have a solid understanding of your business’ big picture, you’re a natural at deconstructing those big goals into smaller pieces that are easy to achieve. And achieve you do! It’s rare that you miss your goals. You’re a go-getter, a master of to-do lists and a goal-tracking extraordinaire.',
            'What comes next is scaling your business even further. It might sound scary, but you’ve got the focus, attention to detail and commitment to consistency to make it happen. Scaling also means getting more clients and/or revenue with less effort, so you can finally take that two-week vacation to the Bahamas.'
        ],
        strengths: [
            { id: 'str1' },
            { id: 'str2' },
            { id: 'str3' },
            { id: 'str4' }
        ],
        opportunities: [
            { id: 'opp1' },
            { id: 'opp2' },
            { id: 'opp3' },
            { id: 'opp4' }
        ]
    },
    actualizerEmpireBuilder: {
        heading: `Defining <strong>qualities</strong> of AEB business owners`,
        bodyCopy: [
            'You’ve got something good going on, and we’re not the only ones taking notice. You’re well-respected in your industry and beyond, with the proven ability to achieve whatever goal you set your mind to.',
            'When it comes to GSD (Getting Stuff Done), execution is your middle name. You effortlessly blend your creativity with an innate ability to outline a clear path to reach your goals. (Which isn’t always the case for many creatives!) While you have a solid understanding of your business’ big picture, you’re a natural at deconstructing those big goals into smaller pieces that are easy to achieve.',
            'From real-life friends to industry connections to your IG followers, the people in your life admire how you always know exactly what you need to do (and are fearless in figuring it out when you don’t!). You’re a go-getter, a master of to-do lists and a goal-tracking extraordinaire.',
            'What comes next is scaling your business even further.',
            'It might sound scary, but you’ve got the focus, attention to detail and commitment to consistency to make it happen. Scaling also means getting more clients and/or revenue with less effort, so you can finally take that two-week vacation to the Bahamas.'
        ],
        strengths: [
            { id: 'str1' },
            { id: 'str2' },
            { id: 'str3' },
            { id: 'str4' }
        ],
        opportunities: [
            { id: 'opp1' },
            { id: 'opp2' },
            { id: 'opp3' },
            { id: 'opp4' }
        ]
    },
    connectorRisingStar: {
        heading: `Defining <strong>qualities</strong> of CRS business owners`,
        bodyCopy: [
            'You thrive when building relationships and prefer quality over quantity. Working with a tight-knit team toward a common goal is when you feel the most energized and you’ve developed an acute sense of when to “do it yourself” versus when to lean on someone or something for expertise that’s outside your wheelhouse. Resourceful is your middle name and you’re a near-savant at connecting the right people, technologies and knowledge to get the job done. Plus, you don’t need to pull one over on anyone to get there — connection is all about trust.',
            'In terms of your business, you’ve already done the hardest part which is getting over any doubts and fears of following your passion. But at this point in your business the “solo” in solopreneur might be setting in hard for a connector like you. Be sure to use the relationships and resources to build a solid foundation for your business.'
        ],
        strengths: [
            { id: 'str1' },
            { id: 'str2' },
            { id: 'str3' },
            { id: 'str4' }
        ],
        opportunities: [
            { id: 'opp1' },
            { id: 'opp2' },
            { id: 'opp3' },
            { id: 'opp4' }
        ]
    },
    connectorWaveMaker: {
        heading: `Defining <strong>qualities</strong> of CWM business owners`,
        bodyCopy: [
            'You thrive when building relationships and prefer quality over quantity. Working with a tight-knit team towards a common goal is when you feel the most energized and you’ve developed an acute sense of when to “do it yourself” versus when to lean on someone or something for expertise that’s outside your wheelhouse.  Resourceful is your middle name and you’re a near-savant at connecting the right people, technologies and knowledge to get the job done.',
            'It’s exactly that connector power that’s helped your business make waves. You’ve scrapped, you’ve problem-solved, you’ve gone from “no idea” to “I’ve got this” so many times you’ve lost count. As you look to grow your business, be sure to bring a little structure and process into your scrappiness. '
        ],
        strengths: [
            { id: 'str1' },
            { id: 'str2' },
            { id: 'str3' },
            { id: 'str4' }
        ],
        opportunities: [{ id: 'opp1' }, { id: 'opp2' }, { id: 'opp3' }]
    },
    connectorDrivingForce: {
        heading: `Defining <strong>qualities</strong> of CDF business owners`,
        bodyCopy: [
            'You thrive when building relationships and prefer quality over quantity. Working with a tight-knit team toward a common goal is when you feel the most energized and you’ve developed an acute sense of when to “do it yourself” versus when to lean on someone or something for expertise that’s outside your wheelhouse. Resourceful is your middle name and you’re a near-savant at connecting the right people, technologies and knowledge to get the job done.',
            'What comes next is scaling your business even further. It might sound scary, but you’ve got the focus, relationships and commitment to consistency to make it happen. Scaling also means getting more clients and/or revenue with less effort, so you can finally take that two-week vacation to the Bahamas.'
        ],
        strengths: [{ id: 'str1' }, { id: 'str2' }, { id: 'str3' }],
        opportunities: [{ id: 'opp1' }, { id: 'opp2' }, { id: 'opp3' }]
    },
    connectorEmpireBuilder: {
        heading: `Defining <strong>qualities</strong> of CEB business owners`,
        bodyCopy: [
            'Keeping your work and personal life separate? It’s never occurred to you. You’re a whirlwind of productivity and building relationships, keeping your finger on the pulse of a million things. Friends and clients alike look to you to make introductions.',
            'You thrive when building relationships and prefer quality over quantity. Working with a tight-knit team toward a common goal is when you feel the most energized and you’ve developed an acute sense of when to “do it yourself” versus when to lean on someone or something for expertise that’s outside your wheelhouse. Resourceful is your middle name and you’re a near-savant at connecting the right people, technologies and knowledge to get the job done.',
            'What comes next is scaling your business even further. It might sound scary, but you’ve got the focus, relationships and commitment to consistency to make it happen. Scaling also means getting more clients and/or revenue with less effort, so you can finally take that two-week vacation to the Bahamas.'
        ],
        strengths: [{ id: 'str1' }, { id: 'str2' }, { id: 'str3' }],
        opportunities: [{ id: 'opp1' }, { id: 'opp2' }, { id: 'opp3' }]
    },
    creativeRisingStar: {
        heading: `Defining <strong>qualities</strong> of CRS business owners`,
        bodyCopy: [
            'Thinking outside the box is in your DNA because you were born to create. Whether you use artistry, language, or technology to put your talents to good use—you are uniquely energized by making something extraordinary from the ordinary.',
            'When others get stuck in a linear way of thinking, you are able to think differently and solve problems from a unique point of view. You walk the line between ideation and execution, leaning one way or the other depending on whether your energy is being refueled or depleted in different seasons of your life.',
            'As a CRS, you naturally wow your clients with your work. There’s no question that you can get the job done, and in a way that others cannot replicate.'
        ],
        strengths: [
            { id: 'str1' },
            { id: 'str2' },
            { id: 'str3' },
            { id: 'str4' }
        ],
        opportunities: [
            { id: 'opp1' },
            { id: 'opp2' },
            { id: 'opp3' },
            { id: 'opp4' }
        ]
    },
    creativeWaveMaker: {
        heading: `Defining <strong>qualities</strong> of CWM business owners`,
        bodyCopy: [
            'Congratulations, you’re making waves! As a CWM, you naturally wow your clients with your work. There’s no question that you can get the job done, and in a way that others cannot replicate.',
            'Thinking outside the box is in your DNA because you were born to create. Whether you use artistry, language, or technology to put your talents to good use—you are uniquely energized by making something extraordinary from the ordinary.',
            'When others get stuck in a linear way of thinking, you are able to think differently and solve problems from a unique point of view. You walk the line between ideation and execution, leaning one way or the other depending on whether your energy is being refueled or depleted in different seasons of your life.'
        ],
        strengths: [
            { id: 'str1' },
            { id: 'str2' },
            { id: 'str3' },
            { id: 'str4' }
        ],
        opportunities: [
            { id: 'opp1' },
            { id: 'opp2' },
            { id: 'opp3' },
            { id: 'opp4' }
        ]
    },
    creativeDrivingForce: {
        heading: `Defining <strong>qualities</strong> of CDF business owners`,
        bodyCopy: [
            'As a CDF, you are a force to be reckoned with. You naturally wow your clients with your work. There’s no question that you can get the job done, and you’ve made it to the big leagues.',
            'Thinking outside the box is in your DNA because you were born to create. Whether you use artistry, language, or technology to put your talents to good use—you are uniquely energized by making something extraordinary from the ordinary.',
            'When others get stuck in a linear way of thinking, you are able to think differently and solve problems from a unique point of view. You walk the line between ideation and execution, leaning one way or the other depending on whether your energy is being refueled or depleted in different seasons of your life.'
        ],
        strengths: [
            { id: 'str1' },
            { id: 'str2' },
            { id: 'str3' },
            { id: 'str4' }
        ],
        opportunities: [
            { id: 'opp1' },
            { id: 'opp2' },
            { id: 'opp3' },
            { id: 'opp4' }
        ]
    },
    creativeEmpireBuilder: {
        heading: `Defining <strong>qualities</strong> of CEB business owners`,
        bodyCopy: [
            'As a CEB, you naturally wow your clients with your work. You’ve mastered your trade, and you have a lot of knowledge in your field.',
            'Thinking outside the box is in your DNA because you were born to create. Whether you use artistry, language, or technology to put your talents to good use—you are uniquely energized by making something extraordinary from the ordinary.',
            'When others get stuck in a linear way of thinking, you are able to think differently and solve problems from a unique point of view. You walk the line between ideation and execution, leaning one way or the other depending on whether your energy is being refueled or depleted in different seasons of your life.'
        ],
        strengths: [
            { id: 'str1' },
            { id: 'str2' },
            { id: 'str3' },
            { id: 'str4' }
        ],
        opportunities: [
            { id: 'opp1' },
            { id: 'opp2' },
            { id: 'opp3' },
            { id: 'opp4' }
        ]
    },
    visionaryWaveMaker: {
        heading: `Defining <strong>qualities</strong> of VWM business owners`,
        bodyCopy: [
            'Look at you go, you’ve gotten past the growing pains of launching a business and are hitting your stride. But that’s no surprise, because challenges won’t crush your vision, they fuel it!',
            'You have big ideas and a vision for how to make things better—you started your business to change the world after all.',
            'You’re the person everyone comes to for problem solving because you can think way outside the box. You thrive on creative brainstorms, aren’t afraid of blue sky ideas and are always game to try something new.',
            'Above all else you’re committed to your mission and will stop at nothing to make it happen. You know a better future is within reach and it’s this vision that empowers you to break barriers and overcome the obstacles that discourage others.'
        ],
        strengths: [
            { id: 'str1' },
            { id: 'str2' },
            { id: 'str3' },
            { id: 'str4' }
        ],
        opportunities: [
            { id: 'opp1' },
            { id: 'opp2' },
            { id: 'opp3' },
            { id: 'opp4' }
        ]
    },
    visionaryDrivingForce: {
        heading: `Defining <strong>qualities</strong> of VDF business owners`,
        bodyCopy: [
            'You have big ideas and a vision for how to make things better—you started your business to change the world after all.',
            'You’re the person everyone comes to for problem solving because you can think way outside the box. You thrive on creative brainstorms, aren’t afraid of blue sky ideas and are always game to try something new.',
            'Above all else you’re committed to your mission and will stop at nothing to make it happen. You know a better future is within reach and it’s this vision that empowers you to break barriers and overcome the obstacles that discourage others.'
        ],
        strengths: [
            { id: 'str1' },
            { id: 'str2' },
            { id: 'str3' },
            { id: 'str4' }
        ],
        opportunities: [
            { id: 'opp1' },
            { id: 'opp2' },
            { id: 'opp3' },
            { id: 'opp4' }
        ]
    },
    visionaryEmpireBuilder: {
        heading: `Defining <strong>qualities</strong> of VEB business owners`,
        bodyCopy: [
            'You have big ideas and a vision for how to make things better—you started your business to change the world after all.',
            'You’re the person everyone comes to for problem solving because you can think way outside the box. You thrive on creative brainstorms, aren’t afraid of blue sky ideas and are always game to try something new.',
            'Above all else you’re committed to your mission and will stop at nothing to make it happen. You know a better future is within reach and it’s this vision that empowers you to break barriers and overcome the obstacles that discourage others.',
            'You’ve realized your vision of building a successful business that makes an impact on your clients. But, as a VEB, there’s always another goal on the horizon and nothing can contain that entrepreneurial vision of yours. So, it’s time to focus on how you can scale your business to make an evening more meaningful impact on your community.'
        ],
        strengths: [
            { id: 'str1' },
            { id: 'str2' },
            { id: 'str3' },
            { id: 'str4' }
        ],
        opportunities: [
            { id: 'opp1' },
            { id: 'opp2' },
            { id: 'opp3' },
            { id: 'opp4' }
        ]
    }
};

function BusinessPersonalityDescriptionBlock({
    resultKey
}: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="bptr-description">
            <FeatureBlockContent customClass="bptr-description__text-block">
                <FeatureBlockText
                    primary={descriptionData[resultKey].heading}
                />
                {descriptionData[resultKey].bodyCopy.map(paragraph => (
                    <FeatureBlockText bodyText={paragraph} key={paragraph} />
                ))}
            </FeatureBlockContent>
            <FeatureBlockContent
                customClass="bptr-description__card-block"
                position="right"
            >
                <Card customClass="bptr-description__card">
                    <CardHeader>
                        {t(`businessPersonalityTool.heading.strengths`)}
                    </CardHeader>
                    <List
                        listName={`businessPersonalityTool.result.${resultKey}.strengths`}
                        items={descriptionData[resultKey].strengths}
                        icon={descriptionData.listIcon}
                    />
                    <CardHeader>
                        {t(`businessPersonalityTool.heading.opportunities`)}
                    </CardHeader>
                    <List
                        listName={`businessPersonalityTool.result.${resultKey}.opportunities`}
                        items={descriptionData[resultKey].opportunities}
                        icon={descriptionData.listIcon}
                    />
                </Card>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

BusinessPersonalityDescriptionBlock.propTypes = {
    resultKey: PropTypes.string.isRequired
};

export default memo(BusinessPersonalityDescriptionBlock);
