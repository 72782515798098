import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';

import './core-values-block.scss';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import ImageTextElement from '../../ImageTextElement/ImageTextElement';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

interface CoreValue {
    title: string;
    description: string;
}

function CoreValuesBlock(): JSX.Element {
    const { t } = useTranslation();

    const values: CoreValue[] = t('careersPageRebrand.coreValues.values', {
        returnObjects: true
    });
    const valueIcons = [
        '/marketing_site/careers_page/careers_values/people-come-first',
        '/marketing_site/careers_page/careers_values/raise-the-bar',
        '/marketing_site/careers_page/careers_values/own-it',
        '/marketing_site/careers_page/careers_values/we-love-what-we-do',
        '/marketing_site/careers_page/careers_values/keep-it-real'
    ];

    return (
        <FeatureBlockSection customClass="core-values-block">
            <FeatureBlockContent>
                <FeatureBlockText
                    customClass="core-values-block__title"
                    primary={t('careersPageRebrand.coreValues.title')}
                    fullWidth
                />
                <div className={'core-values-block__list'}>
                    {values.map((coreValue, idx) => (
                        <div className="core-value" key={coreValue.title}>
                            <HBMediaElement
                                customClass="core-value__image-top"
                                name={`${valueIcons[idx]}-top.png`}
                            />
                            <ImageTextElement
                                customClass="core-value__content"
                                key={coreValue.title}
                                imagePath={`${valueIcons[idx]}-icon.png`}
                                title={coreValue.title}
                                description={coreValue.description}
                            />
                        </div>
                    ))}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default memo(CoreValuesBlock);
