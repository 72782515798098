import {useEffect} from 'react';
import OGTags from '../Services/OGTagsService';
import StorageService from "../Services/StorageService";

function tokenEventListener(event) {
  console.log('event', event.data);
  if (event.data?.type === 'take_this_token') {

    const { redirectTo, authData } = event.data;

    StorageService.setItem({
      key: 'jStorage',
      data: authData
    });

    window.location.href = redirectTo;
  }
}

function onLoad() {
  window.addEventListener('message', tokenEventListener);
  window.opener?.postMessage({type: 'www_tab_ready'}, 'https://malkut-v2.honeybook.luminatesec.com');
}

function onUnload() {
  window.removeEventListener('message', tokenEventListener);
}

function MalkutLoginAs(): JSX.Element {
  useEffect(() => {
    onLoad();

    return onUnload;
  }, []);

  return (
    <div style={{
      margin: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: 'calc(100vh - 100px)',
    }}>
      <OGTags noIndex title={'Malkut Helper'}/>
      <h1>Logging you in...</h1>
    </div>
  );
}

export default MalkutLoginAs;
