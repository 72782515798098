import { useEffect, useMemo } from 'react';
import { useRecipientPageContext } from '../RecipientPage/context/recipient-page-context';
import AnalyticsService from '../../Services/AnalyticsService';
import UtilsService from '../../Services/UtilsService';

export const PublicTemplateIframe = () => {
    const {
        iframeRef,
        checkIsEventValid,
        emitToIframeEvent,
        templateData: { public_url }
    } = useRecipientPageContext();

    const publicUrl = useMemo(() => {
        const hbAnonymousId = AnalyticsService.hbAnonymousId;
        const stableIdParamKey = UtilsService.HB_ANONYMOUS_ID_COOKIE_NAME;

        const currentSearchParams = new URLSearchParams(window.location.search);
        const publicUrlParams = new URLSearchParams(new URL(public_url).search);

        currentSearchParams.forEach((value, key) => {
            publicUrlParams.set(key, value);
        });

        publicUrlParams.set(stableIdParamKey, hbAnonymousId);

        const mergedParams = publicUrlParams.toString();
        const baseUrl = public_url.split('?')[0];

        return `${baseUrl}?${mergedParams}`;
    }, [public_url]);

    ///// keep it for future usage when public_url not gonna be part of templateData //////

    // const iframeUrl = useMemo(() => {
    //     if (!isBrowser) {
    //         return '';
    //     }
    //     const { protocol, host } = window.location;
    //     let urlHost = 'generic.portal.co';
    //     if (UtilsService.isStaging())
    //         urlHost = host.replace('hb-app-staging', 'hb-cx-staging');
    //     else if (UtilsService.isDev()) {
    //         urlHost = 'localhost:3000';
    //     }
    //     const cpu = UtilsService.getParameterByName('cpu')!;
    //     const shouldAddCpuParam = !UtilsService.isProduction() && !!cpu;

    //     const searchParams = new URLSearchParams({
    //         ...(shouldAddCpuParam && { cpu }),
    //         fetch_config_by_id: 'true'
    //     });
    //     return `${protocol}//${urlHost}/template-preview/${templateId}?${searchParams.toString()}`;
    // }, [templateId, isBrowser]);

    useEffect(() => {
        if (!publicUrl) {
            return;
        }

        const handler = (event: MessageEvent) => {
            const { origin } = new URL(publicUrl);
            if (event.origin !== origin) {
                return;
            }
            const iframeEvent = event.data.type;
            if (checkIsEventValid(iframeEvent)) {
                emitToIframeEvent({ eventName: iframeEvent, data: event.data });
            }
        };
        window.addEventListener('message', handler);
        return () => window.removeEventListener('message', handler);
    }, [publicUrl]);

    return <iframe ref={iframeRef} src={publicUrl} />;
};
