import { useSignatureTemplateData } from './useSignatureTemplateData';
import {
    SignatureField,
    SignatureImage,
    SignatureJobTitleAndCompany,
    SignatureSocialIcons
} from '../Email-signature-template';

function EmailSignatureTemplateModern() {
    const {
        name,
        imageUrl,
        jobTitle,
        company,
        phone,
        email,
        address,
        website,
        facebookUrl,
        twitterUrl,
        linkedinUrl,
        instagramUrl,
        pinterestUrl,
        youtubeUrl
    } = useSignatureTemplateData();

    const socialUrls = {
        facebookUrl,
        twitterUrl,
        linkedinUrl,
        instagramUrl,
        pinterestUrl,
        youtubeUrl
    };

    return (
        <div className="email-signature-template email-signature-template--modern">
            <div className="email-signature-template__text-container">
                <SignatureField fieldName="name" fieldValue={name} />
                <div className="email-signature-template__line">
                    <SignatureJobTitleAndCompany
                        jobTitle={jobTitle}
                        company={company}
                        spacer="|"
                    />
                </div>
                <SignatureField
                    fieldName="phone"
                    fieldValue={phone}
                    elPrefix={<strong>M:</strong>}
                />
                <SignatureField
                    fieldName="email"
                    fieldValue={email}
                    elPrefix={<strong>E:</strong>}
                />
                <SignatureField
                    fieldName="address"
                    fieldValue={address}
                    elPrefix={<strong>A:</strong>}
                />
                <SignatureSocialIcons socialUrls={socialUrls} isColor />
            </div>
            <div className="email-signature-template__image-container">
                <SignatureImage imageUrl={imageUrl} />
                <SignatureField
                    fieldName="website"
                    fieldValue={website}
                    href={website}
                />
            </div>
        </div>
    );
}

export default EmailSignatureTemplateModern;
