import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../FeatureBlockElements';
import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../honeybook-ui/HBText/HBText';
import RegistrationForm from '../RegistrationForm/RegistrationForm';

import './all-in-one-place-cta-block.scss';

function AllInOnePlaceCtaBlock() {
    const { t } = useTranslation();
    return (
        <FeatureBlockSection customClass="all-in-one-place-cta-block">
            <FeatureBlockContent customClass="all-in-one-place-cta-block__content">
                <HBMediaElement
                    customClass="all-in-one-place-cta-block__illustration"
                    name="marketing_site/email-signature/all-in-one-place-cta-block-illustration.png"
                />
                <HBText
                    customClass="all-in-one-place-cta-block__text"
                    tag="p"
                    type="body-l"
                >
                    {t('allInOnePlaceCtaBlock.text')}
                </HBText>
                <RegistrationForm
                    size="large"
                    source="all-in-one-place-module"
                    showNoCcRequired={true}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default AllInOnePlaceCtaBlock;
