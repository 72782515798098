import { useTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import TeamOfExpertsBlockStore from './TeamOfExpertsBlockStore';
import FeatureTitleList from '../../FeatureTitleList/FeatureTitleList';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import './team-of-experts.scss';

const { benefitsList } = TeamOfExpertsBlockStore;

const TeamOfExpertsBlock = () => {
    const { t } = useTranslation();
    return (
        <FeatureBlockSection customClass="team-experts">
            <FeatureBlockContent>
                <HBMediaElement
                    alt={t(`featureBlock.experts.title`)}
                    name="marketing_site/account-migration/our-team-image-desktop.png"
                />
            </FeatureBlockContent>

            <FeatureBlockContent>
                <FeatureTitleList
                    title={t(`featureBlock.experts.title`)}
                    listName="featureBlock.experts.list"
                    items={benefitsList}
                    withRegistrationForm
                    registrationSource="concierge"
                    registrationCta={t(`featureBlock.experts.ctaText`)}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default TeamOfExpertsBlock;
