import type { ReactNode } from 'react';
import { memo } from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';

import './card.scss';

interface Props {
    children: ReactNode;
    customClass?: string;
    testId?: string;
}

function Card({ children, customClass, testId }: Props): JSX.Element {
    const className = classnames('card', {
        [`${customClass}`]: Boolean(customClass)
    });

    return (
        <section className={className} data-testid={testId}>
            {children}
        </section>
    );
}

Card.propTypes = {
    children: PropTypes.node.isRequired,
    customClass: PropTypes.string
};

export default memo(Card);
