import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '@honeybook-ui/HBText/HBText';
import StarRatingsSVG from '../../../src/svg/starRatings.inline.svg';

import type { TMediaFlags } from '../../types/util-types';

import './star-review.scss';

interface Props {
    customClass?: string;
    review: {
        score: string;
        imagePath: string;
        totalReviews: number;
        url: string;
        key: string;
        mediaFlags: TMediaFlags;
    };
}

const StarReview = ({ review, customClass }: Props): JSX.Element => {
    const { t } = useTranslation();
    const { score, imagePath, totalReviews, url, key, mediaFlags } = review;

    const handleReviewClick = useCallback(() => {
        AnalyticsService.trackClick(AnalyticsEvents.lp_reviews_link, {
            source: key
        });
        const w = window.open(url, '_blank')!;
        w.opener = null;
    }, [key, url]);

    return (
        <div className={`star-review ${customClass}`}>
            <HBText customClass="star-review__score" tag="h3" type="title-l">
                {score.toString()}
            </HBText>
            <HBMediaElement
                customClass="star-review__logo"
                name={imagePath}
                alt=""
                {...mediaFlags}
            />
            <StarRatingsSVG className="star-review__star-rating" />
            <div onClick={handleReviewClick} className="star-review__link">
                {`${totalReviews} ${t('socialProof.reviews')}`}
            </div>
        </div>
    );
};

export default memo(StarReview);
