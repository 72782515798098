import { graphql } from 'gatsby';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import FeaturedIn from '../components/FeaturedIn/FeaturedIn';
import FeatureIntegrationBlock from '../components/FeatureBlocks/FeatureIntegrationBlock/FeatureIntegrationBlock';
import DiscoverLinkBlock from '../components/FeatureBlocks/DiscoverLinkBlock/DiscoverLinkBlock';
import FeatureValuePropsBlock from '../components/FeatureBlocks/FeatureValuePropsBlock/FeatureValuePropsBlock';
import TestimonialCarouselCardBlock from '../components/FeatureBlocks/TestimonialCarouselCardBlock/TestimonialCarouselCardBlock';
import TestimonialCarouselBlock from '../components/FeatureBlocks/TestimonialCarouselBlock/TestimonialCarouselBlock';
import BestExperienceBlock from '../components/FeatureBlocks/BestExperienceBlock/BestExperienceBlock';
import ShowcaseServicesBlock from '../components/FeatureBlocks/ShowcaseServicesBlock/ShowcaseServicesBlock';
import SmarterSystemBlock from '../components/FeatureBlocks/SmarterSystemBlock/SmarterSystemBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import MobileReviewsBlock from '../components/FeatureBlocks/MobileReviewsBlock/MobileReviewsBlock';
import StatsAnimation from '../components/Hero/StatsAnimation/StatsAnimation';
import FeatureChecklistHero from '../components/Hero/FeatureChecklistHero/FeatureChecklistHero';
import '../styles/home.scss';
import { TAdditionalDataForClickStartTrialModules } from '../types/util-types';
import { usePromoCode } from '../hooks/usePromoCode';

const ogTags = {
    url: 'https://www.honeybook.com/canada',
    title: 'HoneyBook Canada Client Management Software',
    description:
        'The next generation of client management software is here with HoneyBook Canada. Automation and customization for small businesses.'
};

import InvoicesIcon from '../svg/features/invoices-icon.inline.svg';
import ContractsIcon from '../svg/features/contracts-icon.inline.svg';
import AutomationsIcon from '../svg/features/automations-icon.inline.svg';
import PaymentsIcon from '../svg/features/payments-icon.inline.svg';
import SchedulingIcon from '../svg/features/scheduling-icon.inline.svg';

import '../../src/styles/canada.scss';

const featureValues = [
    {
        id: 'invoices',
        icon: <InvoicesIcon />,
        imagePath:
            'marketing_site/canada/feature-list/Invoice-CA-Desktop2x.png',
        link: '/online-invoices'
    },
    {
        id: 'payments',
        icon: <PaymentsIcon />,
        imagePath:
            'marketing_site/canada/feature-list/Payments-CA-Desktop2x.png',
        link: '/online-payment-software'
    },
    {
        id: 'contracts',
        icon: <ContractsIcon />,
        imagePath:
            'marketing_site/canada/feature-list/Contract-CA-Desktop2x.png',
        link: '/online-contract'
    },
    {
        id: 'scheduling',
        icon: <SchedulingIcon />,
        imagePath:
            'marketing_site/canada/feature-list/Schedule-CA-Desktop2x.png',
        link: '/meeting-scheduler'
    },
    {
        id: 'automations',
        icon: <AutomationsIcon />,
        imagePath:
            'marketing_site/canada/feature-list/Automation-CA-Desktop2x.png',
        link: '/automations'
    }
];

const featuredItems = [
    {
        imgUrl: 'marketing_site/featured_in/fortune-dark.png',
        alt: 'Fortune'
    },
    {
        imgUrl: 'marketing_site/featured_in/cnbc-dark.png',
        alt: 'CNBC'
    },
    {
        imgUrl: 'marketing_site/featured_in/fast-company-dark.png',
        alt: 'Fast Company'
    },
    {
        imgUrl: 'marketing_site/featured_in/forbes-dark.png',
        alt: 'Forbes'
    },
    {
        imgUrl: 'marketing_site/featured_in/reuters-dark.png',
        alt: 'Reuters'
    },
    {
        imgUrl: 'marketing_site/featured_in/tc-dark.png',
        alt: 'Tech Crunch'
    }
];

export const carouselSlideData = [
    {
        id: 'bray',
        imageUrl: 'marketing_site/testimonial-avatars/bray-new.png'
    },
    {
        id: 'ashley',
        imageUrl: 'marketing_site/testimonial-avatars/ashley-new.png'
    },
    {
        id: 'alexis',
        imageUrl: 'marketing_site/testimonial-avatars/alexis-new.png'
    },
    {
        id: 'pamela',
        imageUrl: 'marketing_site/testimonial-avatars/pamela-new.png'
    },
    {
        id: 'amber',
        imageUrl: 'marketing_site/testimonial-avatars/amber-new.png'
    }
];

const carouselSlides = [
    {
        profileImage: 'marketing_site/testimonial-avatars/deborah-canada.png',
        transId: 'canada.testimonialCardsBlock.testimonials.deborah',
        name: 'Deborah Nicholson'
    },
    {
        profileImage: 'marketing_site/testimonial-avatars/heather-canada.png',
        transId: 'canada.testimonialCardsBlock.testimonials.heather',
        name: 'Heather Warren'
    },
    {
        profileImage: 'marketing_site/testimonial-avatars/diane-canada.png',
        transId: 'canada.testimonialCardsBlock.testimonials.diane',
        name: 'Diane Hussein'
    }
];

const CanadaPage = () => {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    const stats = useMemo(
        () => [
            {
                title: <Trans>{t('homeStatsAnimation.earned.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.earned.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('homeStatsAnimation.invoices.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.invoices.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('homeStatsAnimation.members.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.members.subtitle')}</Trans>
                )
            }
        ],
        []
    );

    return (
        <Layout ogTags={ogTags}>
            <div className="canada">
                <div className="feature-block__wrapper">
                    <FeatureChecklistHero
                        title={t('canada.hero.title')}
                        titleImage="marketing_site/canada/CA.png"
                        module={
                            TAdditionalDataForClickStartTrialModules.CHECK_LIST_HOME_PAGE
                        }
                    />
                    <TestimonialCarouselBlock slideData={carouselSlideData} />
                    <FeatureValuePropsBlock
                        hideCta={true}
                        title={t('featureValueProps.title')}
                        values={featureValues}
                    />
                    <StatsAnimation stats={stats} />
                    <TestimonialCarouselCardBlock
                        customTitleTransId="canada.testimonialCarousel.title"
                        testimonials={carouselSlides}
                        hideNavigation
                    />
                    <BestExperienceBlock videoId="798848864" />
                    <ShowcaseServicesBlock videoUrl="marketing_site/homepage2/your-services" />
                    <PromoBlockLeft
                        title={t(`${promoCode}.promoBlockLeft.title`)}
                        subtitle={t(`${promoCode}.promoBlockLeft.subtitle`, {
                            defaultValue: null
                        })}
                        buttonStyle="primary--bright"
                        darkTheme
                        source="promo block left - dark"
                        promoCode={promoCode}
                    />
                    <FeaturedIn featuredItems={featuredItems} />
                    <FeatureIntegrationBlock
                        title={t('canada.featureIntegrationBlock.title')}
                        subtitle={t('canada.featureIntegrationBlock.subtitle')}
                    />
                    <SmarterSystemBlock />
                    <MobileReviewsBlock />
                    <DiscoverLinkBlock />
                    <PromoBlockLeft
                        title={t('PromoBlockLeftYellow.title')}
                        customClass="promo-block-left--yellow"
                        source="promo block left - yellow"
                        buttonStyle="primary--dark"
                    />
                </div>
            </div>
        </Layout>
    );
};

export default CanadaPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "canada", "checklistHero"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
