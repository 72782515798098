import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './text-links-block.scss';

const handleAnalytics = source => {
    AnalyticsService.trackClick(AnalyticsEvents.text_links_block, {
        source
    });
};

const TextLinksBlock = ({ title, links, lightBackground, darkBackground }) => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection
            customClass="text-links-block"
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent>
                <FeatureBlockText fullWidth primary={t(title)} />
                <div className="text-links-block__tools-container">
                    {links.map(link => (
                        <a
                            key={link.text}
                            onClick={() => handleAnalytics(t(link.text))}
                            className="text-links-block__tools-link"
                            href={link.url}
                        >
                            {t(link.text)}
                        </a>
                    ))}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

TextLinksBlock.propTypes = {
    title: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool
};

export default TextLinksBlock;
