import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Hero from '../Hero/Hero';
import HeroStrip from '../HeroStrip/HeroStrip';
import HeroColumn from '../HeroColumn/HeroColumn';
import HeroTitle from '../HeroTitle/HeroTitle';

import Slider from '../../Slider/Slider';

import './business-personality-hero.scss';

const slides = [
    {
        id: 'creative',
        title: 'businessPersonalityPage.slides.creative.title',
        description: 'businessPersonalityPage.slides.creative.description',
        imagePath:
            'marketing_site/business_personality_tool/carousel-avatars/creative.png'
    },
    {
        id: 'visionary',
        title: 'businessPersonalityPage.slides.visionary.title',
        description: 'businessPersonalityPage.slides.visionary.description',
        imagePath:
            'marketing_site/business_personality_tool/carousel-avatars/visionary.png'
    },
    {
        id: 'connector',
        title: 'businessPersonalityPage.slides.connector.title',
        description: 'businessPersonalityPage.slides.connector.description',
        imagePath:
            'marketing_site/business_personality_tool/carousel-avatars/connector.png'
    },
    {
        id: 'actualizer',
        title: 'businessPersonalityPage.slides.actualizer.title',
        description: 'businessPersonalityPage.slides.actualizer.description',
        imagePath:
            'marketing_site/business_personality_tool/carousel-avatars/actualizer.png'
    }
];

function BusinessPersonalityHero(): JSX.Element {
    const { t } = useTranslation();

    return (
        <Hero
            customClass="business-personality-hero"
            heroImage="https://res.cloudinary.com/honeybook/image/upload/v1576261627/marketing_site/business_personality_tool/hero-background-mobile.svg"
        >
            <HeroStrip>
                <HeroColumn
                    columnWidth={1}
                    customClass="hero__column hero__column--text"
                >
                    <HeroTitle
                        preTitle={t(`businessPersonalityPage.hero.preTitle`)}
                        title={t(`businessPersonalityPage.hero.title`)}
                        subtitle={t(`businessPersonalityPage.hero.subtitle`)}
                    />
                    <Slider slides={slides} />
                </HeroColumn>
            </HeroStrip>
        </Hero>
    );
}

export default memo(BusinessPersonalityHero);
