import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import './explore-features-block.scss';
import NavigateLink from '../../Link/NavigateLink';

const ExploreFeaturesBlock = ({ title, linksContent }) => {
    return (
        <FeatureBlockSection customClass="explore-features-block">
            <FeatureBlockContent customClass="explore-features-block__content">
                <HBText
                    tag="h2"
                    type="title-l"
                    customClass="explore-features-block__title"
                >
                    {title}
                </HBText>
                <div className="explore-features-block__features-container">
                    {linksContent.map(linkContent => {
                        return (
                            <NavigateLink
                                link={linkContent.linkUrl}
                                source="feature_values"
                                className="explore-features-block__feature"
                                key={linkContent.text}
                            >
                                <HBMediaElement
                                    name={linkContent.iconUrl}
                                    width={'auto'}
                                    customClass="explore-features-block__feature__icon"
                                />
                                <HBText
                                    tag="p"
                                    type="title-s"
                                    customClass="explore-features-block__feature__text"
                                >
                                    {linkContent.text}
                                </HBText>
                            </NavigateLink>
                        );
                    })}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default ExploreFeaturesBlock;
