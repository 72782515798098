import { getPageTests } from '../../static/ab-test-shared-utils';
import ABTestToolServiceV2 from '../Services/ABTestToolService.v2';
import { ABTestToolUtils } from '../Services/ABTestToolServiceUtils.v2';
import UtilsService from '../Services/UtilsService';
import { FALLBACK_VARIANT } from './constants';

// will populate the missing tests with the variants the page generated with including tracking
export const handleMissingTests = (pathname, variantPerTest, caller) => {
    const allTestsInPage = getPageTests(pathname);
    const missingTests = Object.keys(allTestsInPage).filter(
        testName => !variantPerTest[testName]
    );
    if (missingTests.length) {
        const fallbackVariants = ABTestToolUtils.getFallbackVariant(
            window.location.pathname
        );
        missingTests.forEach(testName => {
            if (fallbackVariants[testName]) {
                variantPerTest[testName] = fallbackVariants[testName];
            }
            ABTestToolUtils.reportMissingTests({
                test_name: testName,
                fallback_variant:
                    fallbackVariants[testName] ||
                    FALLBACK_VARIANT.VARIANT_NAME_FOR_TRACKING,
                caller,
                path: pathname
            });
        });
    }
    return variantPerTest;
};

export const checkIfShouldReload = (
    userTestsInTargetPage,
    testsNamesInTargetPage
) => {
    return !testsNamesInTargetPage.every(
        testName => !!userTestsInTargetPage[testName]
    );
};

export const checkShouldReloadDueToMissingTestsInTargetPage = (
    pathname: string
): boolean => {
    UtilsService.updateReferrer();
    const testsInTargetPage = getPageTests(pathname);
    const testsNamesInTargetPage = Object.keys(testsInTargetPage);
    if (testsNamesInTargetPage.length) {
        const userTestsInTargetPage =
            ABTestToolServiceV2.getUserPageTests(pathname);
        return checkIfShouldReload(
            userTestsInTargetPage,
            testsNamesInTargetPage
        );
    }
    return false;
};
