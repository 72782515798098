import { Trans, useTranslation } from 'react-i18next';
import HBText from '../../honeybook-ui/HBText/HBText';

interface Props {
    hasFailed: boolean;
}

const StepOne = ({ hasFailed }: Props) => {
    const { t } = useTranslation();

    return (
        <div className="mission-statement-step mission-statement-step--one">
            <HBText
                tag="p"
                type="body-l"
                customClass="mission-statement-step__pre-question"
            >
                {t('missionStatement.quiz.step1.preQuestion')}
            </HBText>
            <HBText
                tag="p"
                type="body-l"
                customClass="mission-statement-step__question"
            >
                <Trans>{t('missionStatement.quiz.step1.question')}</Trans>
            </HBText>
            <input
                autoFocus
                className="mission-statement-step__input"
                type="text"
                placeholder={t('missionStatement.quiz.step1.placeholder')}
            />
            {hasFailed && (
                <HBText
                    tag="p"
                    type="body-s"
                    customClass="mission-statement-step__error"
                >
                    {t('missionStatement.quiz.errors.missingField')}
                </HBText>
            )}
        </div>
    );
};

export default StepOne;
