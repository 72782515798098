import { memo } from 'react';

import './curved-spacer.scss';

interface Props {
    customClass?: string;
    bgColor: string;
}

const CurvedSpacer = ({ customClass = '', bgColor }: Props) => (
    <div
        className={`curved-spacer ${customClass}`}
        style={{ backgroundColor: `${bgColor}` }}
    ></div>
);

export default memo(CurvedSpacer);
