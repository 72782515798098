import { useSignatureTemplateData } from './useSignatureTemplateData';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import {
    SignatureField,
    SignatureImage,
    SignatureJobTitleAndCompany,
    SignatureSocialIcons
} from '../Email-signature-template';

function EmailSignatureTemplateCrisp() {
    const {
        name,
        imageUrl,
        jobTitle,
        company,
        phone,
        email,
        address,
        website,
        facebookUrl,
        twitterUrl,
        linkedinUrl,
        instagramUrl,
        pinterestUrl,
        youtubeUrl
    } = useSignatureTemplateData();

    const socialUrls = {
        facebookUrl,
        twitterUrl,
        linkedinUrl,
        instagramUrl,
        pinterestUrl,
        youtubeUrl
    };

    return (
        <div className="email-signature-template email-signature-template--crisp">
            <SignatureImage imageUrl={imageUrl} />
            <div className="email-signature-template__text-container">
                <SignatureField fieldName="name" fieldValue={name} />
                <div className="email-signature-template__line">
                    <SignatureJobTitleAndCompany
                        jobTitle={jobTitle}
                        company={company}
                        spacer="▪︎"
                    />
                </div>
                <div className="email-signature-template__spacer"></div>
                <SignatureField
                    fieldName="phone"
                    fieldValue={phone}
                    elPrefix={
                        <HBMediaElement name="marketing_site/email-signature/info-icons/Mobile_2_2x.png" />
                    }
                />
                <SignatureField
                    fieldName="email"
                    fieldValue={email}
                    elPrefix={
                        <HBMediaElement name="marketing_site/email-signature/info-icons/Web_2_2x.png" />
                    }
                />
                <SignatureField
                    fieldName="address"
                    fieldValue={address}
                    elPrefix={
                        <HBMediaElement name="marketing_site/email-signature/info-icons/Location_3_2x.png" />
                    }
                />
                <SignatureField
                    fieldName="website"
                    fieldValue={website}
                    href={website}
                    elPrefix={
                        <HBMediaElement name="marketing_site/email-signature/info-icons/Web_1_2x.png" />
                    }
                />
                <SignatureSocialIcons socialUrls={socialUrls} />
            </div>
        </div>
    );
}

export default EmailSignatureTemplateCrisp;
