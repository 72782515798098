import type { ReactNode } from 'react';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import { useTranslation } from 'react-i18next';
import Hero from '../Hero/Hero';
import HeroTitle from '../HeroTitle/HeroTitle';
import HeroStrip from '../HeroStrip/HeroStrip';
import HeroColumn from '../HeroColumn/HeroColumn';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './horizontal-hero.scss';

interface Props {
    buttonSize?: 'x-small' | 'small' | 'medium' | 'large';
    buttonText?: string;
    preTitle?: string;
    mainTitle: string;
    description?: string;
    image: string;
    imageAltTag?: string;
    imageWidth?: number;
    mobileImage?: string;
    showRegistration?: boolean;
    showRegistrationCta?: boolean;
    customClass?: string;
    titleCustomClass?: string;
    isVideo?: boolean;
    children?: ReactNode;
}

const HorizontalHero = ({
    image,
    imageAltTag,
    preTitle,
    mainTitle,
    description,
    imageWidth = 1280,
    mobileImage,
    showRegistration,
    showRegistrationCta,
    customClass = '',
    titleCustomClass = '',
    isVideo,
    children,
    buttonSize = 'medium',
    buttonText,
    ...imageProps
}: Props) => {
    const { t } = useTranslation();

    return (
        <Hero customClass={`horizontal-hero ${customClass}`}>
            <HeroStrip>
                <HeroColumn
                    customClass={`horizontal-hero__column horizontal-hero__column--text ${
                        showRegistration
                            ? ''
                            : 'horizontal-hero__column--height'
                    }`}
                >
                    <HeroTitle
                        customClass={titleCustomClass}
                        preTitle={preTitle}
                        title={mainTitle}
                        subtitle={description}
                    />
                    {children}
                    {(showRegistration || showRegistrationCta) && (
                        <RegistrationForm
                            showSSO={!showRegistrationCta}
                            showNoCcRequired={!showRegistrationCta}
                            size={buttonSize}
                            source="hero"
                            direction="row"
                            buttonText={buttonText}
                        />
                    )}

                    <HBMediaElement
                        customClass="hero__img horizontal-hero__media-element"
                        name={image}
                        type={isVideo ? 'video' : 'Image'}
                        forceImgTag
                        alt={imageAltTag ? t(imageAltTag) : ''}
                        {...imageProps}
                        width={imageWidth}
                        crop="scale"
                        playsInline
                        autoPlay
                        muted
                    />
                    {mobileImage ? (
                        <HBMediaElement
                            customClass="hero__img horizontal-hero__media-element--mobile"
                            name={mobileImage}
                            width={375}
                            crop="scale"
                            alt={imageAltTag ? t(imageAltTag) : ''}
                            {...imageProps}
                        />
                    ) : null}
                </HeroColumn>
            </HeroStrip>
        </Hero>
    );
};

export default HorizontalHero;
