import { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Hero from '../Hero/Hero';
import HeroTitle from '../HeroTitle/HeroTitle';
import HeroStrip from '../HeroStrip/HeroStrip';
import HeroColumn from '../HeroColumn/HeroColumn';
import EducatorImageBadge from '../../EducatorImageBadge/EducatorImageBadge';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './educator-hero.scss';

const heroEducators = [
    {
        name: 'Candice Coppola',
        jobTitle: 'Business Coach',
        image: 'https://res.cloudinary.com/honeybook/image/upload/ar_1,c_thumb,g_north/c_scale,w_550/v1621879782/marketing_site/educator/hero_candice_coppola.png'
    },
    {
        name: 'Terry Rice',
        jobTitle: 'Business Consultant',
        image: 'https://res.cloudinary.com/honeybook/image/upload/ar_1,c_thumb,g_north/c_scale,w_550/v1621879782/marketing_site/educator/hero_terry_rice.png'
    },
    {
        name: 'Melanie Turner',
        jobTitle: 'Event Planner & Educator',
        image: 'https://res.cloudinary.com/honeybook/image/upload/ar_1,c_thumb,g_north/c_scale,w_550/v1621879782/marketing_site/educator/melanie_turner2.jpg'
    },
    {
        name: 'Brandi Mowles',
        jobTitle: 'Business & Marketing Strategist',
        image: 'https://res.cloudinary.com/honeybook/image/upload/ar_1,c_thumb,g_north/c_scale,w_550/v1621879782/marketing_site/educator/DSC_2459.jpg'
    },
    {
        name: 'Cristina Barragan',
        jobTitle: 'Posh Peony',
        image: 'https://res.cloudinary.com/honeybook/image/upload/ar_1,c_thumb,g_north/c_scale,w_550/v1621879782/marketing_site/educator/Cristina_Headshot_2019_2.png'
    },
    {
        name: 'Michelle Knight',
        jobTitle: 'Brandmerry',
        image: 'https://res.cloudinary.com/honeybook/image/upload/ar_1,c_thumb,g_north/c_scale,w_550/v1621879782/marketing_site/educator/Michelle_Knight_-_Brandmerry.png'
    },
    {
        name: 'Michelle Simmons',
        jobTitle: 'The Suitcase Studio',
        image: 'https://res.cloudinary.com/honeybook/image/upload/ar_1,c_thumb,g_north/c_scale,w_550/v1621879782/marketing_site/educator/Michelle_Simmons-_The_Suitcase_Studio_4.png'
    },
    {
        name: 'Amy & Jordan',
        jobTitle: 'Photography Business Coaches',
        image: 'https://res.cloudinary.com/honeybook/image/upload/ar_1,c_thumb,g_north/c_scale,w_550/v1621879782/marketing_site/educator/amy_and_jordan.jpg'
    }
];

const handleButtonClick = () => {
    AnalyticsService.trackClick(AnalyticsEvents.educator_apply_now, {
        source: 'hero'
    });
};

const EducatorHero = () => {
    const { t } = useTranslation();

    const [currentEducatorIndex, setCurrentEducatorIndex] = useState(0);
    const [currentEducator, setCurrentEducator] = useState(
        heroEducators[currentEducatorIndex]
    );

    useEffect(() => {
        const indexLimit = heroEducators.length - 1;

        const timeout = window.setTimeout(() => {
            const newIndex =
                currentEducatorIndex < indexLimit
                    ? currentEducatorIndex + 1
                    : 0;

            setCurrentEducatorIndex(newIndex);
            setCurrentEducator(heroEducators[newIndex]);
        }, 3000);

        return () => {
            window.clearTimeout(timeout);
        };
    }, [currentEducatorIndex]);

    return (
        <Hero customClass="educator-hero">
            <HeroStrip>
                <HeroColumn customClass="">
                    <HeroTitle
                        title={t('EducatorHero.title')}
                        subtitle={t('EducatorHero.subtitle')}
                    />
                    <p className="educator-hero__link-button">
                        <a
                            className="hbui-button hbui-button--medium hbui-button--primary"
                            onClick={handleButtonClick}
                            href="https://form.typeform.com/to/i2u0n2"
                            rel="noopener noreferrer"
                        >
                            {t('EducatorHero.ctaText')}
                        </a>
                    </p>
                </HeroColumn>
                <HeroColumn customClass="hero__column hero__column--img">
                    <EducatorImageBadge
                        name={currentEducator.name}
                        jobTitle={currentEducator.jobTitle}
                        image={currentEducator.image}
                    />
                </HeroColumn>
            </HeroStrip>
        </Hero>
    );
};

export default memo(EducatorHero);
