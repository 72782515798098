import { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CoachingHero, { Bio } from '../CoachingHero/CoachingHero';
import CoachingTestimonial from '../CoachingTestimonial/CoachingTestimonial';
import SocialIcons from '../../SocialIcons/SocialIcons';
import FeatureIconGrid from '../../FeatureIconGrid/FeatureIconGrid';
import ServicesBlock from '../../FeatureBlocks/ServicesBlock/ServicesBlock';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './photography.scss';

const handleVideoClick = () => {
    const selector = document.querySelector('.coaching-testimonial__quote');

    if (selector !== null) {
        selector.scrollIntoView({
            behavior: 'smooth'
        });
    }
};

const source = 'coaching/photography';
const context = {
    source
};

interface Props {
    bio: Bio;
}

function Photography({ bio }: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className="coaching-photography">
            <CoachingHero bio={bio} handleClick={handleVideoClick} />
            <CoachingTestimonial
                customClass="js-video-link-from-hero"
                bio={bio}
            />

            <section className="subscription">
                <div className="subscription__container">
                    <div className="subscription__form">
                        <h2 className="subscription__title">
                            {t('coaching.photography.registrationFormTitle')}
                            <span className="subscription__title--strong">
                                {t(
                                    'coaching.photography.registrationFormTitleDiscount'
                                )}
                            </span>
                        </h2>
                        <RegistrationForm
                            showSSO
                            showNoCcRequired
                            direction="column"
                            source={source}
                        />
                    </div>
                    <FeatureIconGrid customClass="subscription__features" />
                </div>
            </section>

            <ServicesBlock bio={bio} />

            <section className="social">
                <div className="social__container">
                    <h2 className="social__title">
                        {t('coaching.photography.socialText')}{' '}
                        {t(`coaching.photography.${bio.id}.firstName`)}
                    </h2>
                    <SocialIcons
                        context={context}
                        customData={bio.social}
                        source="photography_page"
                    />
                </div>
            </section>
        </div>
    );
}

Photography.propTypes = {
    bio: PropTypes.shape({
        id: PropTypes.string.isRequired,
        bioImgUrl: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        memberSince: PropTypes.string.isRequired,
        websiteUrl: PropTypes.string.isRequired,
        logoImgUrl: PropTypes.string.isRequired,
        video: PropTypes.string.isRequired
    })
};

export default memo(Photography);
