import { memo, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ImageTextElement from '../../ImageTextElement/ImageTextElement';

import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

interface ArticleProps {
    id: string;
    imagePath: string;
    customClass?: string;
    link: string;
}

function Article({
    id,
    imagePath,
    customClass = '',
    link
}: ArticleProps): JSX.Element {
    const { t } = useTranslation();

    const handleClick = useCallback(() => {
        AnalyticsService.trackClick(AnalyticsEvents.press_link, {
            source: link
        });

        window.open(link);
    }, [link]);

    return (
        <ImageTextElement
            imagePath={imagePath}
            description={t(`FeaturedInBlock.${id}.title`)}
            customClass={customClass}
            handleClick={handleClick}
        />
    );
}

Article.propTypes = {
    id: PropTypes.string.isRequired,
    imagePath: PropTypes.string.isRequired,
    customClass: PropTypes.string,
    link: PropTypes.string.isRequired
};

export default memo(Article);
