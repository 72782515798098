import { useEffect } from 'react';
import StorageService from '../Services/StorageService';
import OGTags from '../Services/OGTagsService';

function tokenEventListener(event) {
    console.log('event', event.data);
    if (event.data?.type === 'take_this_token') {
        StorageService.setItem({
            key: 'jStorage',
            data: event.data.authData
        });

        window.location.href = event.data.redirect;
    }
}

function onLoad() {
    StorageService.setItem({
        key: 'jStorage',
        data: {}
    });
    window.addEventListener('message', tokenEventListener);
    window.opener?.postMessage({ type: 'www_tab_ready' }, '*');
}

function onUnload() {
    window.removeEventListener('message', tokenEventListener);
}

function AdminLoginAs(): JSX.Element {
    useEffect(() => {
        onLoad();

        return onUnload;
    }, []);

    return (
        <>
            <OGTags noIndex title={'Malkut Helper'} />
            <h1>Welcome HoneyBooker!</h1>
            <h2>You will soon be redirected...</h2>
        </>
    );
}

export default AdminLoginAs;
