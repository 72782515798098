import { graphql } from 'gatsby';
import { usePromoCode } from '../hooks/usePromoCode';
import Layout from '../components/Layout/Layout';
import { useTranslation } from 'react-i18next';
import ReviewsHero from '../components/Hero/ReviewsHero/ReviewsHero';
import MembersCarousel from '../components/FeatureBlocks/MembersCarousel/MembersCarousel';
import MembersSpotlight from '../components/FeatureBlocks/MembersSpotlight/MembersSpotlight';
import MembersEnjoy from '../components/FeatureBlocks/MembersEnjoy/MembersEnjoy';
import BadgesBlock from '../components/FeatureBlocks/BadgesBlock/BadgesBlock';
import DiscoverLinkBlock from '../components/FeatureBlocks/DiscoverLinkBlock/DiscoverLinkBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';

import '../styles/reviews.scss';

const ogTags = {
    url: 'https://www.honeybook.com/reviews',
    title: 'HoneyBook Reviews and Testimonials',
    description:
        'Read reviews and testimonials from HoneyBook customers. Independent entrepreneurs and freelancers explain why they chose HoneyBook over other CRMs.'
};

const faqs = [
    {
        id: 'whatIs'
    },
    {
        id: 'HBCost'
    },
    {
        id: 'whoUses'
    },
    {
        id: 'features',
        answer: `#TEXT# <a href="/features">#LINK_TEXT#</a> #TEXT#`
    },
    {
        id: 'crm',
        answer: `#TEXT# <a href="https://help.honeybook.com/integrations/quickbooks-integration/quickbooks-integration-overview-faq">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/integrations/calendly-and-zapier-integrations/calendly-integration-faq">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/integrations/calendly-and-zapier-integrations/integrating-with-zapier">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/integrations/gmail-and-google-integration/syncing-with-gmail">#LINK_TEXT#</a> #TEXT# <span>##AND-placeholder##</span> #TEXT# <a href="https://help.honeybook.com/integrations/gmail-and-google-integration/understanding-your-google-calendar-integration">#LINK_TEXT#</a>`
    },
    {
        id: 'integrateQuickbooks'
    },
    {
        id: 'getStarted',
        answer: `#TEXT# <a href="https://help.honeybook.com/integrations/quickbooks-integration/quickbooks-integration-overview-faq">#LINK_TEXT#</a>`
    }
];

const discoverLinkData = [
    {
        id: 'usedFor',
        url: '/blog/4-things-business-owners-love-to-automate'
    },
    {
        id: 'setupGuide',
        url: '/blog/honeybook-setup'
    },
    {
        id: 'integrations',
        url: '/blog/honeybook-integrations'
    }
];

function ReviewsTemplate() {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    return (
        <div className="reviews-page">
            <Layout ogTags={ogTags}>
                <ReviewsHero />
                <MembersCarousel />
                <MembersSpotlight />
                <MembersEnjoy />
                <BadgesBlock />
                <DiscoverLinkBlock linkData={discoverLinkData} />
                <FAQBlock faqs={faqs} />
                <PromoBlockLeft
                    customClass="online-template__promo-block-left promo-block-left--yellow"
                    title={t(`${promoCode}.promoBlockLeft.title`)}
                    subtitle={t(`${promoCode}.promoBlockLeft.subtitle`, {
                        defaultValue: null
                    })}
                    source="promo block left - yellow"
                    promoCode={promoCode}
                />
            </Layout>
        </div>
    );
}

export default ReviewsTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "reviews", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
