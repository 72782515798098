import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import ChevronLeft from '../../../svg/chevron-left.inline.svg';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import MarqueeAnimation from '../../MarqueeAnimation/MarqueeAnimation';

import './feature-integration-block.scss';

interface IIntegrationMarqueeItem {
    iconUrl: string;
    transKey: string;
}

const defaultIntegrationMarqueeContent = [
    {
        iconUrl: 'marketing_site/crm-page/integration/contact.png',
        transKey: 'contact'
    },
    {
        iconUrl: 'marketing_site/crm-page/integration/meta.png',
        transKey: 'meta'
    },
    {
        iconUrl: 'marketing_site/crm-page/integration/quickbooks.png',
        transKey: 'quickbooks'
    },
    {
        iconUrl: 'marketing_site/crm-page/integration/zoom.png',
        transKey: 'zoom'
    },
    {
        iconUrl: 'marketing_site/crm-page/integration/icloud.png',
        transKey: 'icloud'
    },
    {
        iconUrl: 'marketing_site/crm-page/integration/outlook.png',
        transKey: 'outlook'
    },
    {
        iconUrl: 'marketing_site/crm-page/integration/zapier.png',
        transKey: 'zapier'
    },
    {
        iconUrl: 'marketing_site/crm-page/integration/calendly.png',
        transKey: 'calendly'
    },
    {
        iconUrl: 'marketing_site/crm-page/integration/calendar.png',
        transKey: 'calendar'
    },
    {
        iconUrl: 'marketing_site/crm-page/integration/honeybook.png',
        transKey: 'honeybook'
    },
    {
        iconUrl: 'marketing_site/crm-page/integration/gmail.png',
        transKey: 'gmail'
    }
];

const COMMON_OPTIONS = {
    gap: 24,
    speed: 50
};

interface Props {
    title: string;
    subtitle: string;
    items?: IIntegrationMarqueeItem[];
}

const FeatureIntegrationBlock = ({
    title,
    subtitle,
    items = defaultIntegrationMarqueeContent
}: Props) => {
    const { t } = useTranslation(undefined, {
        keyPrefix: 'featureIntegrationBlock.integrations'
    });
    const { mediumUp } = useBreakpoint();

    const panels = useMemo(
        () =>
            items.map(item => (
                <IntegrationMarqueePreview
                    key={item.transKey}
                    title={t(`${item.transKey}.title`)}
                    subtitle={t(`${item.transKey}.subtitle`)}
                    iconUrl={item.iconUrl}
                />
            )),
        [items]
    );

    const MarqueeDesktopLayout = memo(() => {
        return (
            <>
                <MarqueeAnimation
                    options={{ ...COMMON_OPTIONS, reverse: false }}
                    panels={panels.slice(0, 6)}
                />

                <MarqueeAnimation
                    options={{ ...COMMON_OPTIONS, reverse: true }}
                    panels={panels.slice(6, 12)}
                />
            </>
        );
    });

    const MarqueeMobileLayout = memo(() => {
        return (
            <>
                <MarqueeAnimation
                    options={{ ...COMMON_OPTIONS, reverse: true }}
                    panels={panels.slice(0, 4)}
                />
                <MarqueeAnimation
                    options={{ ...COMMON_OPTIONS, reverse: false }}
                    panels={panels.slice(4, 8)}
                />
                <MarqueeAnimation
                    options={{ ...COMMON_OPTIONS, reverse: true }}
                    panels={panels.slice(8, 12)}
                />
            </>
        );
    });

    return (
        <FeatureBlockSection customClass="feature-integration-block">
            <FeatureBlockContent customClass="feature-integration-block__content">
                <div className="feature-integration-block__text-container">
                    <HBText
                        tag="h2"
                        type="title-l"
                        customClass="feature-integration-block__title"
                    >
                        {title}
                    </HBText>
                    <HBText
                        tag="p"
                        type="body-m"
                        customClass="feature-integration-block__subtitle"
                    >
                        {subtitle}
                    </HBText>
                </div>

                <div className="feature-integration-block__marquee-container">
                    {mediumUp ? (
                        <MarqueeDesktopLayout />
                    ) : (
                        <MarqueeMobileLayout />
                    )}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default FeatureIntegrationBlock;

const CTA_SELECTOR = 'integrations-marquee__invisible-cta';

interface IIntegrationMarqueePreview {
    iconUrl: string;
    title: string;
    subtitle: string;
}

const IntegrationMarqueePreview = ({
    iconUrl,
    title,
    subtitle
}: IIntegrationMarqueePreview) => {
    const { t } = useTranslation();
    const handleClick = () => {
        const ctaElement: HTMLButtonElement | null = document.querySelector(
            `.${CTA_SELECTOR} button`
        );
        if (!!ctaElement) {
            ctaElement.click();
        }
    };

    return (
        <div className="integrations-marquee__preview" onClick={handleClick}>
            <div className="integrations-marquee__preview__icon-and-title-container">
                <HBMediaElement
                    customClass="integrations-marquee__preview__icon"
                    name={iconUrl}
                />
                <HBText
                    customClass="integrations-marquee__preview__title"
                    tag="p"
                    type="title-s"
                >
                    {title}
                </HBText>
            </div>
            <HBText
                customClass="integrations-marquee__preview__subtitle"
                tag="p"
                type="body-xs"
            >
                {subtitle}
            </HBText>
            <div className="integrations-marquee__preview__learn-more">
                <HBText
                    customClass="integrations-marquee__preview__learn-more__text"
                    tag="p"
                    type="body-xs"
                >
                    {t('featureIntegrationBlock.ctaText')}
                </HBText>

                <ChevronLeft />
            </div>
            <RegistrationForm
                customClass={CTA_SELECTOR}
                buttonStyle="invisible"
                source={`integrations module - ${title}`}
            />
        </div>
    );
};
