const InvoiceGeneratorImportanceStore = {
    doula: {
        imagePath:
            'marketing_site/invoice-hub/example-invoice-template-rebrand.png'
    },
    design: {
        imagePath:
            'marketing_site/invoice-hub/example-invoice-template-rebrand.png'
    },
    graphicDesign: {
        imagePath:
            'marketing_site/invoice-hub/example-invoice-template-rebrand.png'
    },
    interiorDesign: {
        imagePath:
            'marketing_site/invoice-hub/example-invoice-template-rebrand.png'
    },
    consultants: {
        imagePath:
            'marketing_site/invoice-hub/example-invoice-template-rebrand.png'
    },
    photographers: {
        imagePath:
            'marketing_site/invoice-hub/example-invoice-template-rebrand.png',
        imageAlt:
            'photography invoice templates for photographers with HoneyBook'
    },
    coach: {
        imagePath:
            'marketing_site/invoice-hub/example-invoice-template-rebrand.png'
    },
    tutors: {
        imagePath:
            'marketing_site/invoice-hub/example-invoice-template-rebrand.png'
    },
    cleaners: {
        imagePath:
            'marketing_site/invoice-hub/example-invoice-template-rebrand.png'
    },
    virtualAssistant: {
        imagePath:
            'marketing_site/invoice-hub/example-invoice-template-rebrand.png'
    }
};

export default InvoiceGeneratorImportanceStore;
