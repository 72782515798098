import { useTranslation } from 'react-i18next';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './smarter-system-block.scss';

const SmarterSystemBlock = ({ title, subtitle, ctaText }) => {
    const { t } = useTranslation();

    const _title = title || t('SmarterSystemBlock.title');
    const _subtitle = subtitle || t('SmarterSystemBlock.subtitle');

    return (
        <FeatureBlockSection customClass="smarter-system-block" lightBackground>
            <FeatureBlockContent>
                <div className="smarter-system-block__text-container">
                    <FeatureBlockText primary={_title} secondary={_subtitle} />
                    <RegistrationForm
                        size="large"
                        source="smarter system module"
                        showNoCcRequired
                        buttonText={ctaText}
                    />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default SmarterSystemBlock;
