import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { usePromoCode } from '../hooks/usePromoCode';
import Layout from '../components/Layout/Layout';
import FeaturedIn from '../components/FeaturedIn/FeaturedIn';
import FeatureIntegrationBlock from '../components/FeatureBlocks/FeatureIntegrationBlock/FeatureIntegrationBlock';
import FeatureValuePropsBlock from '../components/FeatureBlocks/FeatureValuePropsBlock/FeatureValuePropsBlock';
import TestimonialCarouselCardBlock from '../components/FeatureBlocks/TestimonialCarouselCardBlock/TestimonialCarouselCardBlock';
import TestimonialCarouselBlock from '../components/FeatureBlocks/TestimonialCarouselBlock/TestimonialCarouselBlock';
import BestExperienceBlock from '../components/FeatureBlocks/BestExperienceBlock/BestExperienceBlock';
import ShowcaseServicesBlock from '../components/FeatureBlocks/ShowcaseServicesBlock/ShowcaseServicesBlock';
import SmarterSystemBlock from '../components/FeatureBlocks/SmarterSystemBlock/SmarterSystemBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import StatsAnimation from '../components/Hero/StatsAnimation/StatsAnimation';
import FeatureChecklistHero from '../components/Hero/FeatureChecklistHero/FeatureChecklistHero';
import { TAdditionalDataForClickStartTrialModules } from '../types/util-types';
import AdobeStore from '../stores/AdobeStore.js';

import '../styles/home.scss';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';

const ogTags = {
    url: 'https://www.honeybook.com/adobe',
    title: 'Clientflow Management for Independent Businesses',
    description:
        'Book clients, manage projects, and get paid all in one place on HoneyBook. The clientflow management platform for independent businesses. Get started with a free 7-day trial.',
    noIndex: true
};

const AdobeTOSLink =
    'https://www.honeybook.com/lp/honeybook-adobe-terms-of-service';

const { featuredItems, carouselSlideData, featureValues } = AdobeStore;

const faqs = [
    {
        id: 'whatIs'
    },
    {
        id: 'HBCostAdobe',
        answer: '#TEXT# <a href="/lp/honeybook-adobe-terms-of-service">#LINK_TEXT#</a>#TEXT#'
    },
    {
        id: 'adobeWork'
    },
    {
        id: 'HBTemplates',
        answer: '#TEXT# <a href="/templates">#LINK_TEXT#</a>#TEXT#'
    },
    {
        id: 'integration'
    }
];

const AdobeA = () => {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    const stats = useMemo(
        () => [
            {
                title: <Trans>{t('homeStatsAnimation.earned.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.earned.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('homeStatsAnimation.invoices.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.invoices.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('homeStatsAnimation.members.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.members.subtitle')}</Trans>
                )
            }
        ],
        []
    );

    const promoKey = promoCode || 'default';

    return (
        <Layout ogTags={ogTags}>
            <div className="home adobe adobe--a">
                <div className="feature-block__wrapper">
                    <FeatureChecklistHero
                        title={t('adobeA.hero.title')}
                        titleImage="marketing_site/adobe/logos.png"
                        subtitle={t('adobeA.hero.subtitle')}
                        module={
                            TAdditionalDataForClickStartTrialModules.CHECK_LIST_HOME_PAGE
                        }
                        customButtonText={t('adobeA.hero.customButtonText')}
                    />
                    <StatsAnimation stats={stats} />
                    <FeatureValuePropsBlock
                        hideCta={true}
                        values={featureValues}
                        title={t('featureValueProps.title')}
                    />
                    <TestimonialCarouselBlock slideData={carouselSlideData} />
                    <ShowcaseServicesBlock videoUrl="marketing_site/homepage2/your-services" />
                    <BestExperienceBlock
                        subtitle={t('adobeA.bestExperience.subtitle')}
                        videoId="798848864"
                    />
                    <PromoBlockLeft
                        title={t(`${promoKey}.promoBlockLeft.title`)}
                        subtitle={t(`${promoKey}.promoBlockLeft.subtitle`, {
                            defaultValue: null
                        })}
                        buttonStyle="primary--bright"
                        darkTheme
                        source="promo block left - dark"
                        promoCode={promoCode}
                        tosUrl={AdobeTOSLink}
                    />
                    <FeatureIntegrationBlock
                        title={t('integrationsBlock.title')}
                        subtitle={t('integrationsBlock.subtitle')}
                    />
                    <SmarterSystemBlock />
                    <TestimonialCarouselCardBlock />
                    <FeaturedIn featuredItems={featuredItems} />
                    <FAQBlock title={t('adobeA.faqTitle')} faqs={faqs} />
                    <PromoBlockLeft
                        titleImage="marketing_site/adobe/logos.png"
                        title={t('adobe50.PromoBlockLeftYellow.title')}
                        subtitle={t('adobe50.PromoBlockLeftYellow.subtitle')}
                        customClass="promo-block-left--yellow"
                        source="promo block left - yellow"
                        buttonStyle="primary--dark"
                        tosUrl={AdobeTOSLink}
                        customTosText={t(
                            'adobe50.PromoBlockLeftYellow.tosText'
                        )}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default AdobeA;
