import { useState, useEffect, useRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import './template-feature-block.scss';
import { useMemo } from 'react';

const boxLinkClass = 'template-feature-block__box-link';
const TemplateFeatureBlock = ({ transId, values, shouldAnimate }) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const [autoTransition, setAutoTransition] = useState(true);
    const selectorContainerRef = useRef();

    const onTrigger = (shouldAutoTransition, elementIndex) => {
        setSelectedItemIndex(elementIndex);
        setAutoTransition(shouldAutoTransition);
    };

    useEffect(() => {
        if (breakpoints.largeUp && shouldAnimate) {
            const interval = setInterval(() => {
                if (!autoTransition) {
                    return clearInterval(interval);
                }
                if (selectedItemIndex < values.length - 1) {
                    setSelectedItemIndex(selectedItemIndex + 1);
                } else {
                    setSelectedItemIndex(0);
                }
            }, 2500);
            return () => clearInterval(interval);
        }
    }, [autoTransition, breakpoints, shouldAnimate, selectedItemIndex]);

    const distanceFromTop = useMemo(
        () =>
            selectorContainerRef.current?.querySelectorAll(`.${boxLinkClass}`)[
                selectedItemIndex
            ].offsetTop || 0,
        [selectedItemIndex, selectorContainerRef.current]
    );

    return (
        <div className="template-feature-block" id="template-feature-block">
            <FeatureBlockText
                primary={t(`TemplateTabsBlock.tabs.${transId}.tabSectionTitle`)}
                fullWidth
            />

            <FeatureBlockSection halfSize>
                <FeatureBlockContent customClass="template-feature-block__text-content">
                    <div
                        className="features-container"
                        ref={selectorContainerRef}
                        onMouseLeave={() => onTrigger(true, selectedItemIndex)}
                    >
                        <div
                            className="template-feature-block__selector"
                            style={{
                                transform: `translateY(${distanceFromTop}px`
                            }}
                        ></div>
                        {values.map(({ id }, index) => (
                            <div
                                key={id}
                                className={boxLinkClass}
                                onMouseEnter={() => onTrigger(false, index)}
                            >
                                {t(
                                    `TemplateTabsBlock.tabs.${transId}.buttons.${id}`
                                )}
                            </div>
                        ))}
                        <RegistrationForm
                            source={`Template feature block - ${t(
                                `TemplateTabsBlock.tabs.${transId}.tabButtonTitle`
                            )}`}
                            showNoCcRequired
                        />
                    </div>
                </FeatureBlockContent>
                <FeatureBlockContent customClass="template-feature-block__media">
                    <div className="template-feature-block__media-container">
                        {values.map(({ id, imagePath }, index) => (
                            <HBMediaElement
                                customClass={`template-feature-block__media-img ${
                                    index == selectedItemIndex &&
                                    'template-feature-block__media-img--active'
                                }`}
                                key={id}
                                name={imagePath}
                                lazyLoad={false}
                                alt={t(`TemplateTabsBlock.tabs.${id}.title`)}
                            />
                        ))}
                    </div>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

TemplateFeatureBlock.propTypes = {
    transId: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.object)
};

export default memo(TemplateFeatureBlock);
