import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GeolocationService from '../../../Services/GeolocationService';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import ToggleSelector from '../../ToggleSelector/ToggleSelector';
import RoleList from '../OpenRolesBlock/RoleList.jsx';
import Perk, { IPerkDetails } from './Perk';
import './open-roles-block.scss';

function OpenRolesBlock(): JSX.Element {
    const { t } = useTranslation();

    const perks: IPerkDetails[] = t('careersPageRebrand.openRoles.perks', {
        returnObjects: true
    });

    const perksIcons = {
        'Tel Aviv': [
            '/marketing_site/careers_page/careers_benefits/house-buildings-x2.png',
            '/marketing_site/careers_page/careers_benefits/money-x2.png',
            '/marketing_site/careers_page/careers_benefits/bulb-shapes-x2.png',
            '/marketing_site/careers_page/careers_benefits/wallet-flower-x2.png',
            '/marketing_site/careers_page/careers_benefits/lotus-flower-x2.png',
            '/marketing_site/careers_page/careers_benefits/purple-heart-x2.png'
        ],
        'United States': [
            '/marketing_site/careers_page/careers_benefits/glasses-tooth-heart.png',
            '/marketing_site/careers_page/careers_benefits/stroller.png',
            '/marketing_site/careers_page/careers_benefits/beach.png',
            '/marketing_site/careers_page/careers_benefits/money-x2.png',
            '/marketing_site/careers_page/careers_benefits/bulb-shapes-x2.png',
            '/marketing_site/careers_page/careers_benefits/house-buildings-x2.png'
        ]
    };

    const toggleButtons = [
        {
            id: 'tel_aviv',
            text: 'Tel Aviv'
        },
        {
            id: 'US',
            text: 'United States'
        }
    ];

    const [initialToggleSelectedId, setInitialToggleSelectedId] = useState(1);

    const [location, setLocation] = useState(toggleButtons[1].text);

    const updateUserLocation = () => {
        GeolocationService.getLocationPromise().then(data => {
            if (data?.country_code === 'IL') {
                setInitialToggleSelectedId(0);
                setLocation(toggleButtons[0].text);
            }
        });
    };

    useEffect(() => {
        updateUserLocation();
    }, []);

    return (
        <div id="careers-open-positions">
            <FeatureBlockSection customClass="open-roles-block">
                <FeatureBlockContent>
                    <ToggleSelector
                        handleToggle={() =>
                            setLocation(
                                location === toggleButtons[0].text
                                    ? toggleButtons[1].text
                                    : toggleButtons[0].text
                            )
                        }
                        buttons={toggleButtons}
                        defaultSelectedId={
                            toggleButtons[initialToggleSelectedId].id
                        }
                    />
                    <RoleList
                        location={location}
                        toggleButtons={toggleButtons}
                    />
                    <div className="perks-list">
                        {perks[location].map((perkDetails, idx) => (
                            <Perk
                                {...perkDetails}
                                logo={perksIcons[location][idx]}
                                key={perkDetails.title + idx}
                                idx={idx}
                            />
                        ))}
                    </div>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
}

export default memo(OpenRolesBlock);
