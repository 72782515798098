import { LegacyRef, memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import HBText from '@honeybook-ui/HBText/HBText';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import RegistrationFormAsText from '../../RegistrationFormAsText/RegistrationFormAsText';

import AutomationSVG from '../../../svg/navbar-icons/automation-24.inline.svg';
import InvoiceSVG from '../../../svg/navbar-icons/invoice-24.inline.svg';

import './feature-supercharge-block.scss';

interface ContentData {
    transId: string;
    imageUrl: string;
    icon?: JSX.Element;
    iconUrl?: string;
    isLinkedCard?: boolean;
}

interface Props {
    contentData?: ContentData[];
    isLinkedCard?: boolean;
}

const defaultContentData = [
    {
        transId: 'invoice',
        imageUrl: 'marketing_site/online-contracts/invoices.png',
        icon: <InvoiceSVG />
    },
    {
        transId: 'automations',
        imageUrl: 'marketing_site/online-contracts/automations.png',
        icon: <AutomationSVG />
    }
];

const ContractsBox = ({
    transId,
    imageUrl,
    icon,
    iconUrl,
    isLinkedCard
}: ContentData) => {
    const { t } = useTranslation();

    const cardRef: LegacyRef<any> = useRef();
    const CTARef: LegacyRef<any> = useRef();

    const onCardClick = () => {
        if (isLinkedCard) {
            window.location.href = t(`FeatureSuperchargeBlock.${transId}.url`);
        } else {
            CTARef.current.querySelector('button').click();
        }
    };

    return (
        <div
            className="feature-supercharge-block__box"
            ref={cardRef}
            onClick={onCardClick}
        >
            <HBMediaElement
                customClass="feature-supercharge-block__image"
                name={imageUrl}
                lazyLoad={false}
            />
            <div className="feature-supercharge-block__text-container">
                <div className="feature-supercharge-block__title-container">
                    {iconUrl ? (
                        <HBMediaElement
                            customClass="feature-supercharge-block__icon"
                            name={iconUrl}
                            forceImgTag
                        />
                    ) : (
                        icon
                    )}
                    <HBText
                        customClass="feature-supercharge-block__box__title"
                        tag="h3"
                        type="body-l"
                    >
                        {t(`FeatureSuperchargeBlock.${transId}.title`)}
                    </HBText>
                </div>
                <HBText
                    customClass="feature-supercharge-block__box__description"
                    tag="h4"
                    type="body-m"
                >
                    {t(`FeatureSuperchargeBlock.${transId}.description`)}
                </HBText>
                <div
                    ref={CTARef}
                    onClick={ev => {
                        ev.stopPropagation();
                    }}
                >
                    <RegistrationFormAsText
                        linkText={t('FeatureSuperchargeBlock.linkText')}
                        source={t(`FeatureSuperchargeBlock.${transId}.title`)}
                    />
                </div>
            </div>
        </div>
    );
};

const FeatureSuperchargeBlock = ({
    contentData = defaultContentData,
    isLinkedCard = true
}: Props) => {
    const { t } = useTranslation();
    return (
        <FeatureBlockSection customClass="feature-supercharge-block">
            <FeatureBlockContent>
                <HBText
                    customClass="feature-supercharge-block__title"
                    tag="h1"
                    type="title-l"
                >
                    {t('FeatureSuperchargeBlock.title')}
                </HBText>
                <HBText
                    customClass="feature-supercharge-block__subtitle"
                    tag="h2"
                    type="body-m"
                >
                    {t('FeatureSuperchargeBlock.subtitle')}
                </HBText>

                <div className="feature-supercharge-block__container">
                    {contentData.map(content => {
                        return (
                            <ContractsBox
                                key={content.transId}
                                {...content}
                                isLinkedCard={isLinkedCard}
                            />
                        );
                    })}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default memo(FeatureSuperchargeBlock);
