import { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

import './investors-block.scss';
import { FeatureBlockSection, FeatureBlockContent, FeatureBlockText } from '..';

const InvestorsBlockStore = {
    investors: [
        {
            id: 'norwest',
            imgUrl: 'marketing_site/about_page/our_story/norwest_logo.png'
        },
        {
            id: 'aleph',
            imgUrl: 'marketing_site/about_page/our_story/aleph_logo.png'
        },
        {
            id: 'hillsven',
            imgUrl: 'marketing_site/about_page/our_story/hillsven_logo.png'
        },
        {
            id: 'upwest',
            imgUrl: 'marketing_site/about_page/our_story/UPW-Logo.png'
        },
        {
            id: 'citi',
            imgUrl: 'marketing_site/about_page/investors/Citi_Ventures.png'
        },
        {
            id: 'durable',
            imgUrl: 'marketing_site/about_page/investors/Durable_Capital_Partners.png'
        },
        {
            id: 'tiger',
            imgUrl: 'marketing_site/about_page/investors/tiger.png'
        },
        {
            id: 'battery',
            imgUrl: 'marketing_site/about_page/investors/battery.png'
        },
        {
            id: 'zeev',
            imgUrl: 'marketing_site/about_page/investors/Zeev_Ventures.png'
        },
        {
            id: '01',
            imgUrl: 'marketing_site/about_page/investors/01A_Logo.png'
        }
    ]
};

interface Props {
    lightBackground?: boolean;
}

const InvestorsBlock = ({ lightBackground }: Props) => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection
            customClass="investors-block"
            lightBackground={lightBackground}
        >
            <FeatureBlockContent>
                <FeatureBlockText
                    fullWidth
                    primary={t('InvestorsBlock.title')}
                />
                <div className="investors-block__logos">
                    {InvestorsBlockStore.investors.map(investor => (
                        <HBMediaElement
                            key={investor.id}
                            customClass={`investors-block__logo investors-block__logo--${investor.id}`}
                            name={investor.imgUrl}
                        />
                    ))}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

InvestorsBlock.propTypes = {
    lightBackground: PropTypes.bool
};

export default memo(InvestorsBlock);
