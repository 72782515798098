import React from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import UtilsService from '../../Services/UtilsService';

import './proposals-toast.scss';

const ProposalsToast = ({
    content,
    timeAgo,
    entranceDirection,
    display,
    iconClass,
    position
}) => {
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true
    });

    const getToastClasses = () => {
        const entranceDirectionClass = entranceDirection
            ? `proposals-toast--${entranceDirection}`
            : '';
        const activeClass = inView ? 'proposals-toast--active' : '';

        const toastPosition = position
            ? `proposals-toast--${position}`
            : 'proposals-toast--top';
        const className = `proposals-toast ${entranceDirectionClass} ${activeClass} ${toastPosition}`;
        return display
            ? `${className} ${UtilsService.screens(display)}`
            : className;
    };

    const toastClasses = getToastClasses();

    return (
        <div className={toastClasses} ref={ref}>
            <div className="proposals-toast-content">
                {iconClass && (
                    <span
                        className={`proposals-toast-content--icon ${iconClass}`}
                    />
                )}
                <span>{content}</span>
                <span className="proposals-toast-time-ago">{timeAgo}</span>
            </div>
        </div>
    );
};

ProposalsToast.propTypes = {
    iconClass: PropTypes.string,
    content: PropTypes.string.isRequired,
    display: PropTypes.objectOf(PropTypes.string),
    timeAgo: PropTypes.string,
    entranceDirection: PropTypes.string.isRequired,
    position: PropTypes.oneOf(['top', 'bottom'])
};

export default ProposalsToast;
