import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import UtilsService from '../../../Services/UtilsService';
import ApiService from '../../../Services/ApiService';
import MissionStatementEditor from '../MissionStatementEditor/MissionStatementEditor';
import { trackError } from '../../../utils/error.utils';

import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './mission-statement-results.scss';
import HBText from '../../honeybook-ui/HBText/HBText';
import DownloadIcon from '../../../svg/download.inline.svg';
import PencilIcon from '../../../svg/pencil.inline.svg';
import RecycleIcon from '../../../svg/recycle.inline.svg';

import './mission-statement-results.scss';
import MissionStatementSendModal from '../../Modals/MissionStatementSendModal/MissionStatementSendModal';
import MissionStatementSentModal from '../../Modals/MissionStatementSentModal/MissionStatementSentModal';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import getApiUrl from '../../../env';

const statements = [
    {
        id: 'one',
        options: {
            personality_nouns_count: 3
        }
    },
    {
        id: 'two',
        options: {
            personality_nouns_count: 2
        }
    },
    {
        id: 'three'
    },

    {
        id: 'four',
        options: {
            personality_nouns_count: 3
        }
    }
];

const personality_nouns = {
    // TO DO: move words to translation file
    powerful: ['strength', 'energy', 'authority', 'poise', 'boldness'],
    rugged: ['resilience', 'authenticity', 'grit', 'stability', 'strength'],
    creative: [
        'creativity',
        'innovation',
        'imagination',
        'originality',
        'ingenuity'
    ],
    sophisticated: ['refinement', 'grace', 'elegance', 'poise', 'style'],
    authentic: ['authenticity', 'sincerity', 'honesty', 'originality', 'pride'],
    exciting: ['excitement', 'energy', 'vigor', 'enthusiasm', 'liveliness'],
    funny: ['humor', 'energy', 'cleverness', 'whimsy', 'wit'],
    thoughtful: ['thoughtfulness', 'empathy', 'care', 'understanding', 'trust']
};

interface AnswersType {
    company_name: string;
    personality: string;
    industry: string;
    product: string;
    audience: string;
    mission: string;
    email: string;
}

interface Props {
    storedAnswers: AnswersType;
    onClose: () => void;
}

const MissionStatementResults = ({ storedAnswers, onClose }: Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        prepareLeadApiCall();
    }, []);

    const FONT_OPTIONS = ['classic', 'elegant', 'modern'];

    const COLOR_OPTIONS = [
        { id: 'yellow', code: '#ffdd0f' },
        { id: 'orange', code: '#ed9727' },
        { id: 'red', code: '#ff6161' },
        { id: 'pink', code: '#d60890' },
        { id: 'purple', code: '#9271f5' },
        { id: 'blue', code: '#597eff' },
        { id: 'blue-light', code: '#59ccff' },
        { id: 'navy', code: '#283f88' },
        { id: 'teal', code: '#18c7c9' },
        { id: 'green', code: '#53d4a4' },
        { id: 'gray', code: '#767b84' },
        { id: 'black', code: '#000000' }
    ];

    const industry_experience = {
        education: 'educational ',
        health: 'wellness ',
        retail: 'retail ',
        social_media: 'social media ',
        bake: '',
        business_consulting: 'consulting ',
        blogging: 'blogging ',
        marketing: 'marketing ',
        entertainment: 'entertainment ',
        fashion: 'fashion ',
        food: '',
        floral_design: 'floral design ',
        graphic_design: 'graphic design ',
        hair: 'beauty ',
        interior_design: 'design ',
        maker: 'art ',
        photography: 'photography ',
        planning: 'planning ',
        rentals_photo_booth: 'rental ',
        Venue: 'event ',
        visual_art: 'art ',
        videography: 'videography ',
        web_design: 'design ',
        other: ''
    };

    const getMissionGrammar = () => {
        if (
            storedAnswers.mission.indexOf('them') > 0 ||
            storedAnswers.mission.indexOf('their') > 0
        ) {
            let newMission = storedAnswers.mission;
            newMission = storedAnswers.mission.replace(
                t('missionStatement.quiz.replacements.them'),
                storedAnswers.audience
            );
            newMission = newMission.replace(
                t('missionStatement.quiz.replacements.their'),
                storedAnswers.audience
            );
            return newMission;
        } else {
            return storedAnswers.mission;
        }
    };

    const getPersonalityNouns = (personality: string, count: number = 1) => {
        const personalityType = personality.toLowerCase() || 'powerful';

        const nouns = personality_nouns[personalityType];
        const shuffled = UtilsService.shuffle(nouns);

        if (count === 1) {
            return shuffled[0];
        }
        if (count === 2) {
            return shuffled[0] + ' and ' + shuffled[1];
        }
        if (count === 3) {
            return shuffled[0] + ', ' + shuffled[1] + ' and ' + shuffled[2];
        }
    };

    const setPersonalityNouns = randomStatementTemplate => {
        const containsPersonalityNouns =
            t(
                `missionStatement.quiz.statements.${randomStatementTemplate.id}`
            ).indexOf('{{personality_nouns}}') > 0;

        const hasPersonalityOptions =
            randomStatementTemplate.options?.personality_nouns_count > 0;

        if (containsPersonalityNouns && hasPersonalityOptions) {
            return getPersonalityNouns(
                storedAnswers.personality,
                randomStatementTemplate.options?.personality_nouns_count
            );
        } else {
            return storedAnswers.industry;
        }
    };

    const generateMissionStatement = () => {
        const randomStatementTemplate =
            statements[Math.floor(Math.random() * statements.length)];
        const personality_nouns = setPersonalityNouns(randomStatementTemplate);
        const mission = getMissionGrammar();
        const answerData = {
            company_name: storedAnswers.company_name,
            mission: mission,
            product: storedAnswers.product,
            personality_nouns: personality_nouns,
            audience: storedAnswers.audience,
            industry_experience: industry_experience[storedAnswers.industry]
        };

        const statement = t(
            `missionStatement.quiz.statements.${randomStatementTemplate.id}`,
            answerData
        );

        return statement;
    };

    const [missionStatement, setMissionStatement] = useState(
        generateMissionStatement()
    );
    const [backgroundColor, setBackgroundColor] = useState(COLOR_OPTIONS[6]);
    const [fontStyle, setFontStyle] = useState('classic');
    const [isEditing, setIsEditing] = useState(false);
    const [isSendModalOpen, setIsSendModalOpen] = useState(false);
    const [isSentModalOpen, setIsSentModalOpen] = useState(false);
    const [leadId, setLeadId] = useState('');

    const generateNewStatement = () => {
        const newStatement = generateMissionStatement();

        if (newStatement === missionStatement) {
            return generateNewStatement();
        }
        return newStatement;
    };

    const handleDownload = () => {
        downloadStatementApiCall();
    };

    const missionStatementApi = (
        formattedData: Record<string, any>
    ): Record<string, any> => {
        const apiUrl = `${getApiUrl()}/api/v2/lead_mission_statement_generator`;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formattedData)
        };

        return ApiService._apiFetch(apiUrl, requestOptions).catch(err => {
            trackError(err, {
                message: 'failed to fetch mission statement'
            });
        });
    };
    const prepareLeadApiCall = () => {
        const formattedData = {
            company_name: storedAnswers.company_name,
            company_type: storedAnswers.industry,
            stripped_email: storedAnswers.email,
            email: storedAnswers.email,
            accept_getting_emails: true,
            utm_params: UtilsService.getAdvertisingData(),
            email_to_send: storedAnswers.email
        };

        missionStatementApi(formattedData).then(response => {
            setLeadId(response.lead_mission_statement_id);
        });

        AnalyticsService.reportIterableEvent(
            'lead_created',
            {
                lead_type: 'lead_mission_statement',
                email: storedAnswers.email,
                company_name: storedAnswers.company_name,
                company_type: storedAnswers.industry
            },
            { email: storedAnswers.email }
        );
    };

    const downloadStatementApiCall = () => {
        const formattedData = {
            company_name: storedAnswers.company_name,
            company_type: storedAnswers.industry,
            generated_statement: missionStatement,
            stripped_email: storedAnswers.email,
            email: storedAnswers.email,
            accept_getting_emails: true,
            utm_params: UtilsService.getAdvertisingData(),
            email_to_send: storedAnswers.email,
            generated_statement_image_link_vertical:
                generateCloudinaryUrl(true),
            generated_statement_image_link_horizontal:
                generateCloudinaryUrl(false),
            lead_mission_statement_id: leadId
        };

        missionStatementApi(formattedData);
    };

    var getFontFamily = fontStyle => {
        switch (fontStyle) {
            case 'classic':
                return 'PlayfairDisplay-Bold.ttf';
            case 'modern':
                return 'RobotoSlab-Regular.ttf';
            case 'elegant':
                return 'ProximaNova-Regular.otf';
            default:
                return 'PlayfairDisplay-Bold.ttf';
        }
    };

    const generateCloudinaryUrl = isVertical => {
        const statement = encodeURIComponent(missionStatement.trim()).replace(
            '%2C',
            '%252C'
        );
        const fontSize = getImageFontSize(missionStatement.length, isVertical);
        const fontFamily = getFontFamily(fontStyle);
        const height = isVertical ? 1920 : 628;
        const width = isVertical ? 1080 : 1200;
        const color = backgroundColor.code.replace('#', '');

        return `https://res.cloudinary.com/growth-tools/image/upload/c_scale,h_${height},w_${width}/c_fit,co_white,l_text:${fontFamily}_${fontSize}_antialias_undefined_hinting_undefined:${statement},w_1000/b_rgb:${color}/v1/mission-statement/${
            isVertical ? 'Vertical' : 'Horizontal'
        }.png`;
    };

    const handleDownloadClick = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.lp_invoice_mission_statement_download,
            {
                source: 'download button'
            }
        );
        setIsSendModalOpen(true);
    };

    const toggleEditor = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.lp_invoice_mission_statement_editor,
            {
                source: isEditing ? 'close' : 'open'
            }
        );
        setIsEditing(isEditing => !isEditing);
    };

    const handleSendModalComplete = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.lp_invoice_mission_statement_send,
            {
                source: 'send mission statement'
            }
        );
        handleDownload();
        setIsSendModalOpen(false);
        setIsSentModalOpen(true);
    };

    const handleCloseSendModal = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.lp_invoice_mission_statement_close_send_modal,
            {
                source: 'close send mission statement modal'
            }
        );
        setIsSendModalOpen(false);
    };

    const handleCloseSentModal = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.lp_invoice_mission_statement_close_sent_modal,
            {
                source: 'close sent mission statement modal'
            }
        );
        setIsSentModalOpen(false);
    };

    const handleStartOver = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.lp_invoice_mission_statement_close_start_over_btn
        );
        onClose();
    };

    const StartOverButton = () => (
        <div
            className="mission-statement-results__start-over-btn"
            onClick={handleStartOver}
        >
            <HBText tag="p" type="body-m">
                {t('missionStatement.results.startOver')}
            </HBText>
        </div>
    );

    const getImageFontSize = (length, isDesktop) => {
        let fontSize = 18;

        if (isDesktop) {
            if (length < 85) {
                fontSize = 160;
            } else if (length >= 85 && length < 100) {
                fontSize = 105;
            } else {
                fontSize = 70;
            }
        } else {
            if (length < 100) {
                fontSize = 70;
            } else if (length >= 100 && length < 135) {
                fontSize = 60;
            } else {
                fontSize = 50;
            }
        }

        return fontSize;
    };

    const isMissionStatementLongString = missionStatement.length > 80;

    return (
        <div
            className="mission-statement-results"
            style={{ backgroundColor: backgroundColor.code }}
        >
            <div className="mission-statement-results__title-container">
                <HBText
                    tag="p"
                    type="body-m"
                    customClass="mission-statement-results__company-name"
                >
                    {storedAnswers.company_name}'s
                </HBText>
                <HBText
                    tag="p"
                    type="body-m"
                    customClass="mission-statement-results__title"
                >
                    {t('missionStatement.results.title')}
                </HBText>
            </div>

            {isEditing ? (
                <MissionStatementEditor
                    onClose={toggleEditor}
                    statement={missionStatement}
                    setFontStyle={setFontStyle}
                    selectedFont={fontStyle}
                    selectedBackgroundColor={backgroundColor}
                    setBackgroundColor={setBackgroundColor}
                    setMissionStatement={setMissionStatement}
                    fontOptions={FONT_OPTIONS}
                    colorOptions={COLOR_OPTIONS}
                />
            ) : (
                <>
                    <HBText
                        tag="p"
                        type="body-m"
                        customClass={`mission-statement-results__result mission-statement-results__result--${fontStyle} ${
                            isMissionStatementLongString
                                ? 'mission-statement-results__result--long'
                                : ''
                        }`}
                    >
                        {missionStatement}
                    </HBText>

                    <div className="mission-statement-results__button-container">
                        <PencilIcon onClick={toggleEditor} />
                        <div
                            className="mission-statement-results__generate-button-container"
                            onClick={() =>
                                setMissionStatement(generateNewStatement)
                            }
                        >
                            <RecycleIcon className="mission-statement-results__generate-button" />
                            <HBText
                                tag="p"
                                type="body-m"
                                customClass="mission-statement-results__generate"
                            >
                                {t('missionStatement.results.generateMore')}
                            </HBText>
                        </div>
                        <DownloadIcon onClick={handleDownloadClick} />

                        <MissionStatementSendModal
                            isOpen={isSendModalOpen}
                            onClose={handleCloseSendModal}
                            onComplete={handleSendModalComplete}
                            emailAddress={storedAnswers.email}
                        />

                        <MissionStatementSentModal
                            isOpen={isSentModalOpen}
                            onClose={handleCloseSentModal}
                        />
                    </div>

                    <RegistrationForm
                        size="large"
                        source="mission statement result"
                        showNoCcRequired
                    />

                    <StartOverButton />
                </>
            )}
        </div>
    );
};

export default MissionStatementResults;
