import { useTranslation } from 'react-i18next';
import HBButton from '../honeybook-ui/HBButton/HBButton';
import HBText from '../honeybook-ui/HBText/HBText';
import RegistrationForm from '../RegistrationForm/RegistrationForm';

import './email-signature-cta.scss';

interface Props {
    openModal?: () => void;
}

function EmailSignatureCta({ openModal }: Props) {
    const { t } = useTranslation();

    return (
        <div className="email-signature-cta">
            <div className="email-signature-cta__text-container">
                <HBText tag="p" type="body-l">
                    {t('emailSignatureBlock.cta.running')}
                </HBText>
                <div>
                    <HBText tag="p" type="body-l">
                        {t('emailSignatureBlock.cta.try.text')}{' '}
                    </HBText>
                    <RegistrationForm
                        source="email_signature_block"
                        buttonStyle="plain"
                        buttonText={t('emailSignatureBlock.cta.try.link')}
                    />
                </div>
            </div>
            {openModal && (
                <HBButton
                    buttonStyle="primary--dark"
                    size="large"
                    customClass="email-signature-cta__cta"
                    text={t('emailSignatureBlock.cta.button')}
                    onClick={openModal}
                />
            )}
        </div>
    );
}

export default EmailSignatureCta;
