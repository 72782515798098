import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';

import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './business-personality-tool-almost-30-block.scss';

const BusinessPersonalityToolAlmost30Block = ({ t }) => {
    return (
        <FeatureBlockSection customClass="bptr-almost30" lightBackground>
            <FeatureBlockContent
                fullWidth
                customClass="bptr-almost30__head-icon__container"
            >
                <HBMediaElement
                    customClass="bptr-almost30__head-icon"
                    name="marketing_site/business_personality_tool/almost30/tab02-illu-icon01_1x.svg"
                />
            </FeatureBlockContent>

            <FeatureBlockContent
                fullWidth
                position="center"
                customClass="bptr-almost30__content"
            >
                <FeatureBlockText
                    fullWidth
                    customClass="bptr-almost30__title"
                    primary={t(`businessPersonalityTool.almost30.title`)}
                />

                <div className="bptr-almost30__almost30-logo__container">
                    <div className="bptr-almost30__almost30-logo" />
                </div>

                <FeatureBlockText
                    fullWidth
                    customClass="bptr-almost30__body"
                    bodyText={t(`businessPersonalityTool.almost30.body`)}
                />
                <FeatureBlockText
                    fullWidth
                    customClass="bptr-almost30__hashtag"
                    bodyText={t(`businessPersonalityTool.almost30.hashtag`)}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

BusinessPersonalityToolAlmost30Block.propTypes = {
    t: PropTypes.func
};

export default withTranslation()(BusinessPersonalityToolAlmost30Block);
