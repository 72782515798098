const ContractsPageStore = {
    timeManagement: {
        quoteAvatar: 'marketing_site/homepage2/jesse_debusk.jpg',
        media: {
            checkmark: 'marketing_site/lp/chart-check-green_3x.svg',
            featureImage: '',
            bubble: '',
            mobile: 'marketing_site/home_page/pie-percent.svg'
        }
    }
};

export default ContractsPageStore;
