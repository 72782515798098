import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout/Layout';
import WhiteHero, {
    HERO_POSITIONS
} from '../components/Hero/WhiteHero/WhiteHero';

import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import WereFirstClassBlock from '../components/FeatureBlocks/WereFirstClassBlock/WereFirstClassBlock';
import InvoiceReviewBlock from '../components/FeatureBlocks/InvoiceReviewBlock/InvoiceReviewBlock';
import AgreementSignBlock from '../components/FeatureBlocks/AgreementSignBlock/AgreementSignBlock';
import PaymentBookedBlock from '../components/FeatureBlocks/PaymentBookedBlock/PaymentBookedBlock';
import ChaChingBlock from '../components/FeatureBlocks/ChaChingBlock/ChaChingBlock';
import BenefitsBlock from '../components/FeatureBlocks/BenefitsBlock/BenefitsBlock';

import '../styles/proposal-software.scss';

const faqs = [
    {
        id: 'whatsProposal',
        answer: '#TEXT# <a href="/online-payment-software">#TEXT</a> #TEXT#'
    },
    {
        id: 'proposalGeneration'
    },
    {
        id: 'scopeOfWork'
    },
    {
        id: 'whyProposal',
        answer: '#TEXT# <a target="_blank" rel="noopener noreferrer" href="//intercom.help/honeybook-5891e2a409ff/en/articles/2537722-video-tutorial-creating-and-sending-a-proposal">#TEXT_LINK#</a>.'
    },
    {
        id: 'proposalTemplates',
        answer: '#TEXT# <a target="_blank" rel="noopener noreferrer" href="//help.honeybook.com/en/articles/2209099-using-templates">#LINK_TEXT#</a>'
    },
    {
        id: 'proposalVsOthers'
    },
    {
        id: 'testProposals'
    },
    {
        id: 'otherFeatures'
    },
    {
        id: 'HBCost',
        answer: '#TEXT <a href="/pricing">#LINK_TEXT#</a> #TEXT#'
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/proposal-software',
    title: 'Close Deals Faster With Better Proposal Software',
    description: `Streamline your proposal process and win over clients with HoneyBook's intuitive proposal software. Create professional and interactive proposals in no time.`
};

function ProposalSoftwareTemplate() {
    const { t } = useTranslation();

    return (
        <div className="proposals-page">
            <Layout ogTags={ogTags}>
                <WhiteHero
                    image="marketing_site/lp/proposal/easy-booking"
                    mainTitle={t('proposalsPage.hero.title')}
                    description={t('proposalsPage.hero.subtitle')}
                    imagePosition={HERO_POSITIONS.BOTTOM}
                    imageWidth={450}
                    showRegistration
                    buttonSize="large"
                    customClass="white-hero-2020"
                />

                <div className="feature-block__wrapper">
                    <InvoiceReviewBlock customTransId="proposalsPage.invoiceReview" />
                    <AgreementSignBlock customTransId="proposalsPage.agreementSign" />
                    <PaymentBookedBlock customTransId="proposalsPage.paymentBooked" />
                    <ChaChingBlock />
                    <BenefitsBlock />

                    <FAQBlock title={t('proposalsPage.faqTitle')} faqs={faqs} />

                    <WereFirstClassBlock />
                </div>
            </Layout>
        </div>
    );
}

export default ProposalSoftwareTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "proposalSoftware", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
