const ClientRelationshipsStore = {
    imageTextElements: [
        {
            title: 'clientRelationships.imageTextElements.leads.title',
            text: 'clientRelationships.imageTextElements.leads.description',
            imagePath:
                'marketing_site/october_gtm/chemistry%20converts%20icons/organize_leads.png',
            altText: 'clientRelationships.imageTextElements.leads.title',
            mediaFlags: { forceImgTag: true }
        },
        {
            title: 'clientRelationships.imageTextElements.clients.title',
            text: 'clientRelationships.imageTextElements.clients.description',
            imagePath:
                'marketing_site/october_gtm/chemistry%20converts%20icons/connect.png',
            altText: 'clientRelationships.imageTextElements.clients.title',
            mediaFlags: { forceImgTag: true }
        },
        {
            title: 'clientRelationships.imageTextElements.book.title',
            text: 'clientRelationships.imageTextElements.book.description',
            imagePath:
                'marketing_site/october_gtm/chemistry%20converts%20icons/confidence.png',
            altText: 'clientRelationships.imageTextElements.book.title',
            mediaFlags: { forceImgTag: true }
        }
    ],
    integrationItems: [
        {
            iconUrl:
                'marketing_site/october_gtm/integrations%20logos/google-contacts-logo_1.png',
            transKey: 'contact'
        },
        {
            iconUrl:
                'marketing_site/october_gtm/integrations%20logos/google-contacts-logo_1-1.png',
            transKey: 'meta'
        },
        {
            iconUrl:
                'marketing_site/october_gtm/integrations%20logos/google-contacts-logo_1-2.png',
            transKey: 'quickbooks'
        },
        {
            iconUrl:
                'marketing_site/october_gtm/integrations%20logos/google-contacts-logo_1-3.png',
            transKey: 'zoom'
        },
        {
            iconUrl:
                'marketing_site/october_gtm/integrations%20logos/google-contacts-logo_1-4.png',
            transKey: 'icloud'
        },
        {
            iconUrl:
                'marketing_site/october_gtm/integrations%20logos/image_1.png',
            transKey: 'pictime'
        },
        {
            iconUrl:
                'marketing_site/october_gtm/integrations%20logos/outlook-logo_1.png',
            transKey: 'outlook'
        },
        {
            iconUrl:
                'marketing_site/october_gtm/integrations%20logos/google-contacts-logo_1-6.png',
            transKey: 'zapier'
        },
        {
            iconUrl:
                'marketing_site/october_gtm/integrations%20logos/google-contacts-logo_2.png',
            transKey: 'calendly'
        },
        {
            iconUrl:
                'marketing_site/october_gtm/integrations%20logos/google-contacts-logo_1-7.png',
            transKey: 'calendar'
        },
        {
            iconUrl:
                'marketing_site/october_gtm/integrations%20logos/google-contacts-logo_1-8.png',
            transKey: 'honeybook'
        },
        {
            iconUrl:
                'marketing_site/october_gtm/integrations%20logos/google-contacts-logo_1-9.png',
            transKey: 'gmail'
        }
    ],
    testimonials: [
        {
            profileImage: 'marketing_site/october_gtm/Natasha_Mojica.png',
            transId: 'featureTestimonialCarouselBlock.testimonials.natasha',
            name: 'Natasha M.',
            personalInfo: true
        },
        {
            profileImage: 'marketing_site/october_gtm/Dave_Hooper.png',
            transId: 'featureTestimonialCarouselBlock.testimonials.david',
            name: 'David H.',
            personalInfo: true
        },
        {
            profileImage: 'marketing_site/october_gtm/earnesto.png',
            transId: 'featureTestimonialCarouselBlock.testimonials.earnest',
            name: 'Earnest O.',
            personalInfo: true
        }
    ],
    templateData: [
        {
            templateId: '61633ab31dd45702a0c16b8f',
            transId: 'templates.clientService',
            imageUrl: 'marketing_site/online-contracts/templates/1.png'
        },
        {
            templateId: '647ee710b061802bece34967',
            transId: 'templates.bookingFile',
            imageUrl:
                'marketing_site/online-invoices/03_Templates/Desktop/Card_allinonebookingfile.png'
        },
        {
            templateId: '647ee77cca13550454a38200',
            transId: 'templates.minimalProposal',
            imageUrl:
                'marketing_site/online-invoices/03_Templates/Desktop/Card_minimalproposal.png'
        },
        {
            templateId: '61e07925845559016c7af36c',
            transId: 'templates.servicesAndPricingGuide',
            imageUrl:
                'marketing_site/crm-page/templates/services_and_pricing_guide.png'
        },
        {
            templateId: '616336771dd45702a0c16b07',
            transId: 'templates.simpleContract',
            imageUrl: 'marketing_site/online-contracts/templates/5.png'
        },
        {
            templateId: '63d90d77ffaf4f0310911b4b',
            transId: 'templates.instantBooking',
            imageUrl:
                'marketing_site/online-invoices/03_Templates/Desktop/Card_instantbooking.png'
        }
    ]
};

export default ClientRelationshipsStore;
