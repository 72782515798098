const MeetOurInstructorsStore = {
    instructors: [
        {
            title: 'Mac Hughes',
            imagePath: 'marketing_site/webinars/headshot.square.png',
            mediaFlags: {
                width: 180,
                height: 180,
                crop: 'fill',
                gravity: 'face',
                radius: 'max'
            }
        },
        {
            title: 'Masha Zvereva',
            imagePath: 'marketing_site/webinars/masha.png',
            mediaFlags: {
                height: 180,
                width: 180,
                crop: 'fill',
                gravity: 'face',
                radius: 'max'
            }
        },
        {
            title: 'Kitzzy Aviles',
            imagePath: 'marketing_site/webinars/kitzzy.jpg',
            mediaFlags: {
                height: 180,
                width: 180,
                crop: 'fill',
                gravity: 'face',
                radius: 'max'
            }
        }
    ]
};

export default MeetOurInstructorsStore;
