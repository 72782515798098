const TimelyPaymentStore = {
    listItems: [
        { id: 'clientManagement' },
        { id: 'autoPay' },
        { id: 'paymentTracking' },
        { id: 'deposit' }
    ],
    listIcon: 'nx-check-16',
    avatar: 'marketing_site/online-payments/liz-rivera2.jpg'
};

export default TimelyPaymentStore;
