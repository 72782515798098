import { useSignatureTemplateData } from './useSignatureTemplateData';
import {
    SignatureField,
    SignatureImage,
    SignatureJobTitleAndCompany,
    SignatureSocialIcons
} from '../Email-signature-template';

function EmailSignatureTemplateConservative() {
    const {
        name,
        imageUrl,
        jobTitle,
        company,
        phone,
        email,
        address,
        website,
        facebookUrl,
        twitterUrl,
        linkedinUrl,
        instagramUrl,
        pinterestUrl,
        youtubeUrl
    } = useSignatureTemplateData();

    const socialUrls = {
        facebookUrl,
        twitterUrl,
        linkedinUrl,
        instagramUrl,
        pinterestUrl,
        youtubeUrl
    };

    return (
        <div className="email-signature-template email-signature-template--conservative">
            <SignatureImage imageUrl={imageUrl} />
            <div className="email-signature-template__text-container">
                <div className="email-signature-template__line">
                    <SignatureField fieldName="name" fieldValue={name} />
                    <SignatureJobTitleAndCompany
                        jobTitle={jobTitle}
                        company={company}
                        spacer="at"
                    />
                </div>
                <div className="email-signature-template__line">
                    <SignatureField
                        fieldName="phone"
                        fieldValue={phone}
                        elPrefix={<strong>P:</strong>}
                    />
                    <SignatureField
                        fieldName="email"
                        fieldValue={email}
                        elPrefix={<strong>E:</strong>}
                    />
                </div>
                <SignatureField fieldName="address" fieldValue={address} />
                <SignatureField
                    fieldName="website"
                    fieldValue={website}
                    href={website}
                />
                <SignatureSocialIcons socialUrls={socialUrls} isColor />
            </div>
        </div>
    );
}

export default EmailSignatureTemplateConservative;
