import { useTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';

import './cha-ching-block.scss';

const ChaChingBlock = ({ customTitle }) => {
    const { t } = useTranslation();
    return (
        <FeatureBlockSection customClass="cha-ching-block">
            <HBMediaElement name="marketing_site/lp/proposal/booked.png" />
            <FeatureBlockContent position="center">
                <FeatureBlockText
                    primary={customTitle || t('featureBlock.chaChing.title')}
                />

                <div className="feature-block__signup-wrapper feature-block__signup-wrapper">
                    <RegistrationForm
                        size="large"
                        source="cha-ching module"
                        showNoCcRequired
                    />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};
export default ChaChingBlock;
