import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TestimonialBlock from '../FeatureBlocks/TestimonialBlock/TestimonialBlock';
import { FeatureBlockSection } from '../FeatureBlockElements';
import './testimonials-container.scss';

const TestimonialsContainer = ({ quotes, customClass }) => {
    return (
        <FeatureBlockSection customClass="testimonials-container">
            {quotes.map(quote => {
                return (
                    <TestimonialBlock
                        key={quote.name}
                        customClass={customClass}
                        text={quote.text}
                        name={quote.name}
                        description={quote.description}
                        image={quote.image}
                    />
                );
            })}
        </FeatureBlockSection>
    );
};

TestimonialsContainer.propTypes = {
    quotes: PropTypes.arrayOf(PropTypes.object).isRequired,
    customClass: PropTypes.string
};

export default withTranslation()(TestimonialsContainer);
