import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HBLogo from '../../../svg/honeybook-logo.inline.svg';

import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './mission-statement-quiz.scss';
import HBText from '../../honeybook-ui/HBText/HBText';
import HBButton from '../../honeybook-ui/HBButton/HBButton';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepSix from './StepSix';
import StepSeven from './StepSeven';
import MissionStatementResults from '../MissionStatementResults/MissionStatementResults';
import UtilsService from '../../../Services/UtilsService';

import CrossIcon from '../../../svg/cross.inline.svg';

const ANSWERS_OBJ_TEMPLATE = {
    company_name: '',
    personality: '',
    industry: '',
    product: '',
    audience: '',
    mission: '',
    email: ''
};

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const MissionStatementQuiz = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const [canProceed, setCanProceed] = useState(false);
    const [hasQuestionError, setHasQuestionError] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [storedAnswers, setStoredAnswers] = useState(ANSWERS_OBJ_TEMPLATE);
    const currentStepId = Object.keys(ANSWERS_OBJ_TEMPLATE)[currentStep - 1];

    const handleValidation = e => {
        const answer = e.target.value;
        setCanProceed(true);
        setHasQuestionError(false);

        const newAnswers = storedAnswers;
        newAnswers[currentStepId] = answer;
        setStoredAnswers(newAnswers);
    };

    const submitStep = () => {
        if (
            currentStepId === 'email' &&
            !UtilsService.isEmailValid(storedAnswers.email)
        ) {
            setHasQuestionError(true);
            return;
        }

        if (storedAnswers.industry === 'placeholder') {
            setHasQuestionError(true);
            return;
        }

        if (canProceed && storedAnswers[currentStepId].length > 0) {
            AnalyticsService.trackClick(
                AnalyticsEvents.lp_invoice_mission_statement_quiz_next_step,
                {
                    source: `step ${currentStepId} complete`
                }
            );
            setCurrentStep(currentStep => currentStep + 1);
            setCanProceed(false);
        } else {
            setHasQuestionError(true);
        }
    };

    const handleBackButton = () => {
        AnalyticsService.trackClick(
            AnalyticsEvents.lp_invoice_mission_statement_quiz_prev_step,
            {
                source: `back from step ${currentStep}`
            }
        );

        if (currentStep > 1) {
            setCurrentStep(currentStep => currentStep - 1);
        }
    };

    const steps = [
        <StepOne hasFailed={hasQuestionError} />,
        <StepTwo />,
        <StepThree hasFailed={hasQuestionError} />,
        <StepFour
            hasFailed={hasQuestionError}
            industry={storedAnswers.industry.toLowerCase()}
        />,
        <StepFive
            hasFailed={hasQuestionError}
            industry={storedAnswers.industry.toLowerCase()}
        />,
        <StepSix
            hasFailed={hasQuestionError}
            industry={storedAnswers.industry.toLowerCase()}
        />,
        <StepSeven hasFailed={hasQuestionError} />
    ];

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            submitStep();
        }
    };

    const CloseButton = () => (
        <div className="mission-statement-quiz__close-btn" onClick={onClose}>
            <CrossIcon />
        </div>
    );

    const BackButton = () => (
        <div
            className="mission-statement-quiz__back-btn"
            onClick={handleBackButton}
        >
            <HBText tag="p" type="body-m">
                {t('missionStatement.quiz.back')}
            </HBText>
        </div>
    );

    const Quiz = (
        <div className="mission-statement-quiz__container">
            <CloseButton />

            <div
                className="mission-statement-quiz__progress-bar"
                style={{ width: `${(currentStep / steps.length) * 100}%` }}
            ></div>

            <div className="mission-statement-quiz__header">
                <HBLogo className="mission-statement-quiz__header__logo" />
                <HBText
                    customClass="mission-statement-quiz__header__title"
                    tag="h1"
                    type="subtitle-l"
                >
                    {t('missionStatement.quiz.title')}
                </HBText>
                <HBText
                    customClass="mission-statement-quiz__header__steps"
                    tag="p"
                    type="title-m"
                >
                    {currentStep.toString()}/{steps.length.toString()}
                </HBText>
            </div>
            <div className="mission-statement-quiz__wrapper">
                <div onKeyDown={handleKeyDown} onChange={handleValidation}>
                    {steps[currentStep - 1]}
                </div>

                <div
                    className={`mission-statement-quiz__button-container ${
                        canProceed
                            ? ''
                            : 'mission-statement-quiz__button-container--disabled'
                    }`}
                >
                    <HBButton
                        customClass={`mission-statement-quiz__next-button ${
                            canProceed ? '' : 'disabled'
                        }`}
                        onClick={submitStep}
                        text={t('missionStatement.quiz.button.buttonText')}
                        size="large"
                    />
                    <p className="mission-statement-quiz__enter-text">
                        {t('missionStatement.quiz.button.enterText')}
                    </p>
                </div>

                {currentStep > 1 && <BackButton />}
            </div>
        </div>
    );

    const isQuizInProgress = currentStep < steps.length + 1;

    // for quicker testing of the results page
    // const testData = {
    //     audience: 'dessert lovers',
    //     company_name: 'Paper Co.',
    //     email: 'test@testing.com',
    //     industry: 'bake',
    //     mission: 'baking delicious treats',
    //     personality: 'Creative',
    //     product: 'cakes'
    // };

    return isOpen ? (
        <div className="mission-statement-generator">
            {isQuizInProgress ? (
                Quiz
            ) : (
                <MissionStatementResults
                    storedAnswers={storedAnswers}
                    onClose={onClose}
                />
            )}
        </div>
    ) : null;
};

export default MissionStatementQuiz;
