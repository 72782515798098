import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';
import ImageTextElement from '../../ImageTextElement/ImageTextElement';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './migration-steps.scss';

const MigrationStepsBlock = ({ t }) => {
    return (
        <FeatureBlockSection customClass="migration-steps" lightBackground>
            <FeatureBlockContent>
                <FeatureBlockText
                    fullWidth
                    primary={t('featureBlock.migrationStepsBlock.title')}
                />
                <div className="migration-steps__container">
                    <ImageTextElement
                        title={t(
                            'featureBlock.migrationStepsBlock.step1.title'
                        )}
                        description={t(
                            'featureBlock.migrationStepsBlock.step1.body'
                        )}
                    >
                        <span className="migration-steps__step">1</span>
                        <span className="migration-steps__arrow" />
                    </ImageTextElement>

                    <ImageTextElement
                        title={t(
                            'featureBlock.migrationStepsBlock.step2.title'
                        )}
                        description={t(
                            'featureBlock.migrationStepsBlock.step2.body'
                        )}
                    >
                        <span className="migration-steps__step">2</span>
                        <span className="migration-steps__arrow" />
                    </ImageTextElement>

                    <ImageTextElement
                        title={t(
                            'featureBlock.migrationStepsBlock.step3.title'
                        )}
                        description={t(
                            'featureBlock.migrationStepsBlock.step3.body'
                        )}
                    >
                        <span className="migration-steps__step">3</span>
                    </ImageTextElement>
                </div>
                <div className="feature-block__signup-wrapper feature-block__signup-wrapper--left">
                    <RegistrationForm
                        source="how it works"
                        size="large"
                        showNoCcRequired
                    />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

MigrationStepsBlock.propTypes = {
    t: PropTypes.func.isRequired
};

export default withTranslation()(MigrationStepsBlock);
