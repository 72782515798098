import React from 'react';
import PropTypes from 'prop-types';
import WebinarTimeSlot from '../WebinarTimeSlot/WebinarTimeSlot';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

const today = new Date();

const convertToDate = date => {
    return new Date(Date.parse(date));
};

const isWebinarPending = timeSlot => {
    return convertToDate(timeSlot.date) >= today;
};

const sortByDate = timeSlots => {
    return timeSlots.sort((a, b) => {
        return convertToDate(a.date) - convertToDate(b.date);
    });
};

const handleAnalytics = title => {
    AnalyticsService.trackClick(AnalyticsEvents.webinar, {
        source: title
    });
};

const WebinarRecordingsContainer = ({ webinarSlots }) => {
    const pendingWebinarSlots = webinarSlots
        ? sortByDate(webinarSlots.filter(isWebinarPending))
        : null;
    return pendingWebinarSlots.map(slot => {
        return (
            <WebinarTimeSlot
                key={`${slot.title} ${slot.date}`}
                title={slot.title}
                date={slot.date}
                startTime={slot.startTime}
                endTime={slot.endTime}
                linkUrl={slot.link}
                onClick={() => {
                    handleAnalytics(slot.title);
                }}
            />
        );
    });
};

WebinarRecordingsContainer.propTypes = {
    webinarSlots: PropTypes.arrayOf(PropTypes.object)
};

export default WebinarRecordingsContainer;
