import { Component, createRef } from 'react';
import * as PropTypes from 'prop-types';

import HBIcon from '../HBIcon/HBIcon';
import HBLabel from '../HBLabel/HBLabel';

import type {
    RefObject,
    FocusEventHandler,
    FocusEvent,
    ChangeEventHandler,
    MouseEventHandler
} from 'react';

import './hb-input.scss';

interface Props {
    onFocus?: FocusEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onClick?: MouseEventHandler<HTMLInputElement>;
    name?: string;
    placeholder?: string;
    customClass?: string;
    iconLeft?: string;
    iconRight?: string;
    label?: string;
    type?:
        | 'text'
        | 'email'
        | 'tel'
        | 'password'
        | 'url'
        | 'search'
        | 'number'
        | 'date'
        | 'range'
        | 'file';
    errorMessage?: string;
    showBar?: boolean;
    inputStyle?: 'simple-input' | 'simple-input-dark' | 'material-input' | '';
    isRequired?: boolean;
    isValid?: boolean;
    value?: number | string;
    e2eTestLocator?: string;
    readOnly?: boolean;
    id?: string;
    isDisabled?: boolean;
}

interface State {
    isFocused: boolean;
    isEmpty: boolean;
}

// This component has to stay a class component until:
// https://github.com/Hacker0x01/react-datepicker/issues/862 is resolved
// due to it being used by HBDatePicker
export default class HBInput extends Component<Props, State> {
    inputRef: RefObject<HTMLInputElement>;

    state = { isFocused: false, isEmpty: true };

    static defaultProps = {
        type: 'text',
        inputStyle: '',
        customClass: '',
        showBar: true,
        errorMessage: '',
        isDisabled: false
    };

    static propTypes = {
        name: PropTypes.string,
        placeholder: PropTypes.string,
        customClass: PropTypes.string,
        iconLeft: PropTypes.string,
        iconRight: PropTypes.string,
        label: PropTypes.string,
        type: PropTypes.oneOf([
            'text',
            'email',
            'tel',
            'password',
            'url',
            'search',
            'number',
            'date',
            'range',
            'file'
        ]),
        errorMessage: PropTypes.string,
        showBar: PropTypes.bool,
        inputStyle: PropTypes.oneOf([
            'simple-input',
            'simple-input-dark',
            'material-input'
        ]),
        isRequired: PropTypes.bool,
        isValid: PropTypes.bool,
        onFocus: PropTypes.func,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.instanceOf(Date)
        ]),
        e2eTestLocator: PropTypes.string,
        readOnly: PropTypes.bool,
        id: PropTypes.string,
        isDisabled: PropTypes.bool
    };

    constructor(props: Props) {
        super(props);

        this.inputRef = createRef();
    }

    componentDidMount(): void {
        this.setState({ isEmpty: this.inputRef.current?.value === '' });
    }

    handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
        const { onFocus } = this.props;

        this.setState({ isFocused: true });

        if (onFocus) {
            onFocus(event);
        }
    };

    handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
        const { onBlur } = this.props;

        this.setState({ isFocused: false, isEmpty: this.isInputEmpty(event) });

        if (onBlur) {
            onBlur(event);
        }
    };

    isInputEmpty = (event: FocusEvent<HTMLInputElement>): boolean => {
        if (event.target.value === '') {
            return true;
        }

        return false;
    };

    render(): JSX.Element {
        const {
            type,
            name,
            placeholder,
            label,
            isRequired,
            readOnly,
            iconLeft,
            iconRight,
            value,
            onChange,
            onClick,
            showBar,
            isValid,
            errorMessage,
            id,
            e2eTestLocator,
            inputStyle,
            customClass,
            isDisabled
        } = this.props;

        const { isFocused, isEmpty } = this.state;

        const inputClasses = `hbui-input ${
            inputStyle ? `hbui-input--${inputStyle}` : ''
        } ${!isValid ? 'hbui-input-error' : ''} ${
            iconLeft ? 'hbui-input--icon-left' : ''
        } ${isDisabled ? 'hbui-input--disabled' : ''} ${customClass}`;

        return (
            <div className={inputClasses}>
                <HBLabel
                    label={label}
                    isRequired={isRequired}
                    customClass={`input-label ${
                        isFocused ? 'input-label--focused' : ''
                    } ${isEmpty ? 'empty' : ''}`}
                />
                {
                    // eslint-disable-next-line jsx-a11y/autocomplete-valid
                    <input
                        id={id}
                        name={name}
                        type={type}
                        ref={this.inputRef}
                        autoComplete="nope" /* Regarding autoComplete="nope" => autoComplete="off" isn't working for some reason. Found this idea in SO and it worked :D */
                        className="input-wrapper"
                        placeholder={placeholder}
                        onBlur={this.handleBlur}
                        onChange={onChange}
                        onClick={onClick}
                        value={value}
                        onFocus={this.handleFocus}
                        e2e-test-locator={e2eTestLocator}
                        readOnly={readOnly}
                        disabled={isDisabled}
                    />
                }
                {showBar && <span className="bar" />}
                {iconLeft && (
                    <HBIcon
                        customClass="input-icon--left"
                        onClick={onClick}
                        name={iconLeft}
                    />
                )}
                {iconRight && (
                    <HBIcon
                        customClass="input-icon--right"
                        onClick={onClick}
                        name={iconRight}
                    />
                )}
                {!isValid && errorMessage && (
                    <div className="error-message">{errorMessage}</div>
                )}
            </div>
        );
    }
}

export const InputStyles = {
    DEFAULT: '',
    SIMPLE_INPUT: 'simple-input',
    SIMPLE_INPUT_DARK: 'simple-input-dark',
    MATERIAL_INPUT: 'material-input'
} as const;
