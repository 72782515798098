const FeaturesContractBlockStore = {
    blockData: {
        id: 'contracts',
        image: 'marketing_site/features-hub/contract-brows.png',
        slideInImages: {
            mug: 'marketing_site/features-hub/illustration_2x.png',
            contract: 'marketing_site/features-hub/contracts_2x.png',
            toast: 'marketing_site/features-hub/Toast_2x.png'
        },
        quoteMember: 'Erin Girouard',
        quoteAvatar:
            'marketing_site/features-hub/testimonial_headshots/erin_girouard.png',
        link: '/online-contract'
    }
};

export default FeaturesContractBlockStore;
