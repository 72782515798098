import { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import Hero from '../Hero/Hero';
import HeroStrip from '../../Hero/HeroStrip/HeroStrip';
import HeroColumn from '../../Hero/HeroColumn/HeroColumn';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './one-business-platform-hero.scss';

function OneBusinessPlatformHero({
    customTitle,
    titleImage,
    customSubtitle,
    customCtaText
}): JSX.Element {
    const { t } = useTranslation();

    return (
        <Hero customClass="one-business-platform__hero">
            <HeroStrip>
                <HeroColumn
                    columnWidth={1}
                    customClass="one-business-platform__hero-registration"
                >
                    {titleImage && (
                        <HBMediaElement
                            customClass="one-business-platform__hero-title-image"
                            name={titleImage}
                        />
                    )}
                    <h1 className="one-business-platform__hero-title">
                        {customTitle || t(`oneBusinessPlatform.hero.mainTitle`)}
                    </h1>
                    <h2 className="one-business-platform__hero-subtitle">
                        <Trans>
                            {customSubtitle ||
                                t(`oneBusinessPlatform.hero.description`)}
                        </Trans>
                    </h2>
                    <RegistrationForm
                        source="one business platform hero"
                        size="large"
                        buttonText={customCtaText}
                    />
                    <p className="one-business-platform__hero-cc-text">
                        <Trans>
                            {t(`oneBusinessPlatform.hero.noCCRequeired`)}
                        </Trans>
                    </p>
                </HeroColumn>
                <HeroColumn columnWidth={1}>
                    <HBMediaElement
                        customClass="one-business-platform__hero-img"
                        width="398"
                        name="marketing_site/lp/all-in-one/desktop/all-in-one-illustration-hero-rebrand.png"
                        alt="All-in-one business platform"
                        lazyLoad={false}
                    />
                </HeroColumn>
            </HeroStrip>
        </Hero>
    );
}

export default memo(OneBusinessPlatformHero);
