import { useTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import HBText from '../../honeybook-ui/HBText/HBText';

import './mission-statement-tips-block.scss';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

const textTransIds = [
    'specific',
    'focus',
    'direction',
    'unique',
    'concise',
    'values'
];

const MissionStatementTipsBlock = () => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="mission-statement-tips-block">
            <FeatureBlockContent>
                <HBMediaElement
                    customClass="mission-statement-tips-block__image"
                    name="marketing_site/mission-statement/Group_1000001719.svg"
                />
                <HBText
                    customClass="mission-statement-tips-block__title"
                    tag="h2"
                    type="title-m"
                >
                    {t('missionStatement.missionStatementTips.title')}
                </HBText>
                <div className="mission-statement-tips-block__text-container">
                    {textTransIds.map(transId => (
                        <div
                            className="mission-statement-tips-block__text"
                            key={transId}
                        >
                            <HBText
                                tag="p"
                                type="subtitle-l"
                                customClass="mission-statement-tips-block__subtitle"
                            >
                                {t(
                                    `missionStatement.missionStatementTips.tips.${transId}.title`
                                )}
                            </HBText>
                            <HBText
                                tag="p"
                                type="body-s"
                                customClass="mission-statement-tips-block__description"
                            >
                                {t(
                                    `missionStatement.missionStatementTips.tips.${transId}.description`
                                )}
                            </HBText>
                        </div>
                    ))}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default MissionStatementTipsBlock;
