import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ImageTextElementsBlock from '../ImageTextElementsBlock/ImageTextElementsBlock';

import PaymentsBlockStore from './PaymentsBlockStore';
import './payments-block.scss';

const PaymentsBlock = ({ t }) => {
    return (
        <ImageTextElementsBlock
            customClass="payments-block"
            title={t('featureBlock.payments.title')}
            imageTextElements={PaymentsBlockStore.imageTextElements}
        />
    );
};

PaymentsBlock.propTypes = {
    t: PropTypes.func
};

export default withTranslation()(PaymentsBlock);
