import NonUsaWarningModal from '../Modals/NonUsaWarningModal/NonUsaWarningModal';
import InternationalAvailabilityModal from '../Modals/InternationalAvailabilityModal/InternationalAvailabilityModal';
import { useState } from 'react';

export const NonUsaWarningModalWrapper = ({
    isOpenNonUsaModal,
    onConfirmNonUsaModal,
    onCloseNonUsaModal
}: {
    onConfirmNonUsaModal: () => void;
    onCloseNonUsaModal: () => void;
    isOpenNonUsaModal: boolean;
}) => {
    const [
        isInternationalAvailabilityModalOpen,
        setIsInternationalAvailabilityModalOpen
    ] = useState(false);

    const closeInternationalAvailabilityModal = () =>
        setIsInternationalAvailabilityModalOpen(false);

    return (
        <>
            <NonUsaWarningModal
                isOpen={isOpenNonUsaModal}
                onClose={onCloseNonUsaModal}
                onConfirm={onConfirmNonUsaModal}
                onSecondaryAction={() => {
                    onCloseNonUsaModal();
                    setIsInternationalAvailabilityModalOpen(true);
                }}
            />
            <InternationalAvailabilityModal
                isOpen={isInternationalAvailabilityModalOpen}
                closeInternationalAvailabilityModal={
                    closeInternationalAvailabilityModal
                }
            />
        </>
    );
};
