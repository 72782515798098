import { useMemo } from 'react';
import { useIsBrowser } from '../hooks/useIsBrowser';
import { Trans, useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import FeatureHeroBlock from '../components/FeatureBlocks/FeatureHeroBlock/FeatureHeroBlock';
import AnimatedDollarBlock from '../components/FeatureBlocks/AnimatedDollarBlock/AnimatedDollarBlock';
import FeatureTextBlock from '../components/FeatureBlocks/FeatureTextBlock/FeatureTextBlock';
import StepFeatureBlock from '../components/FeatureBlocks/StepFeatureBlock/StepFeatureBlock';
import FeatureTemplateGallery from '../components/FeatureBlockElements/FeatureTemplateGallery/FeatureTemplateGallery';
import TestimonialCarouselCardBlock from '../components/FeatureBlocks/TestimonialCarouselCardBlock/TestimonialCarouselCardBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import FeaturePreviewBlock from '../components/FeatureBlocks/FeaturePreviewBlock/FeaturePreviewBlock';
import AdobeStore from '../stores/AdobeStore.js';

import '../styles/online-template.scss';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import ExploreFeaturesBlock from '../components/FeatureBlocks/ExploreFeaturesBlock/ExploreFeaturesBlock';

const ogTags = {
    url: 'https://www.honeybook.com/adobe',
    title: 'Clientflow Management for Independent Businesses',
    description:
        'Book clients, manage projects, and get paid all in one place on HoneyBook. The clientflow management platform for independent businesses. Get started with a free 7-day trial.',
    noIndex: true
};

const AdobeTOSLink =
    'https://www.honeybook.com/lp/honeybook-adobe-terms-of-service';

const faqs = [
    {
        id: 'whatIs'
    },
    {
        id: 'HBCostAdobe',
        answer: '#TEXT# <a href="/lp/honeybook-adobe-terms-of-service">#LINK_TEXT#</a>#TEXT#'
    },
    {
        id: 'adobeWork'
    },
    {
        id: 'HBTemplates',
        answer: '#TEXT# <a href="/templates">#LINK_TEXT#</a>#TEXT#'
    },
    {
        id: 'integration'
    }
];

const {
    stepBlocksOne,
    stepBlocksTwo,
    stepBlocksThree,
    previewContent,
    signatureCardContents,
    templateData,
    quotes,
    exploreLinksContent
} = AdobeStore;

function OnlineInvoicesNew(): JSX.Element {
    const { t } = useTranslation();
    const { isBrowser } = useIsBrowser();

    const stats = useMemo(
        () => [
            {
                title: (
                    <Trans>{t('invoicesStatsAnimation.signed.title')}</Trans>
                ),
                subtitle: (
                    <Trans>{t('invoicesStatsAnimation.signed.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('invoicesStatsAnimation.value.title')}</Trans>,
                subtitle: (
                    <Trans>{t('invoicesStatsAnimation.value.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('invoicesStatsAnimation.daily.title')}</Trans>,
                subtitle: (
                    <Trans>{t('invoicesStatsAnimation.daily.subtitle')}</Trans>
                )
            }
        ],
        []
    );

    return (
        <Layout
            ogTags={ogTags}
            customClass={isBrowser ? '' : 'hidden'}
            mobileStickyCTA
        >
            <div className="online-template online-template--invoices adobe adobe--b">
                <FeatureHeroBlock
                    label={t('featureHeroBlock.label')}
                    title={t('adobeB.hero.title')}
                    titleImage="marketing_site/adobe/logos.png"
                    subtitle={t('adobeB.hero.subtitle')}
                    stats={stats}
                    customCtaText={t('adobeB.hero.customButtonText')}
                    iconUrl1="marketing_site/online-invoices/00_Hero/Desktop/Document.png"
                    iconUrl2="marketing_site/online-invoices/00_Hero/Desktop/Group_48096347.png"
                />
                <FeaturePreviewBlock
                    transId="contractToggle"
                    previewContent={previewContent}
                />
                <AnimatedDollarBlock
                    customTitle={t('adobeB.animatedDollarBlock.title')}
                    cardContents={signatureCardContents}
                />
                <FeatureTextBlock
                    title={t('adobeB.featureTextBlock.title')}
                    imageUrl="marketing_site/online-invoices/02_Steps/Desktop/Document.png"
                />
                <StepFeatureBlock
                    customClass="online-template__step1"
                    transId="adobeB.stepsFeatureBlock.step1"
                    stepSubBlocks={stepBlocksOne}
                    linkText={t('adobeB.stepsFeatureBlock.linkText')}
                />
                <StepFeatureBlock
                    reverse
                    transId="adobeB.stepsFeatureBlock.step2"
                    stepSubBlocks={stepBlocksTwo}
                    linkText={t('adobeB.stepsFeatureBlock.linkText')}
                />
                <StepFeatureBlock
                    transId="adobeB.stepsFeatureBlock.step3"
                    stepSubBlocks={stepBlocksThree}
                    linkText={t('adobeB.stepsFeatureBlock.linkText')}
                />
                <FeatureTemplateGallery
                    title={t('adobeB.templateGallery.title')}
                    subtitle={t('adobeB.templateGallery.subtitle')}
                    customTemplateData={templateData}
                    ctaSource="Invoices template gallery footer"
                    customCtaText={t('adobeB.templateGallery.ctaText')}
                />
                <TestimonialCarouselCardBlock
                    testimonials={quotes}
                    autoScroll={false}
                />
                <ExploreFeaturesBlock
                    title={t('exploreFeaturesBlock.title')}
                    linksContent={exploreLinksContent}
                />
                <FAQBlock title={t('adobeA.faqTitle')} faqs={faqs} />
                <PromoBlockLeft
                    titleImage="marketing_site/adobe/logos.png"
                    title={t('adobe50.PromoBlockLeftYellow.title')}
                    subtitle={t('adobe50.PromoBlockLeftYellow.subtitle')}
                    customClass="promo-block-left--yellow"
                    source="promo block left - yellow"
                    buttonStyle="primary--dark"
                    tosUrl={AdobeTOSLink}
                    customTosText={t('adobe50.PromoBlockLeftYellow.tosText')}
                />
            </div>
        </Layout>
    );
}

export default OnlineInvoicesNew;
