const FrictionlessPaymentStore = {
    listItems: [
        { id: 'oneStep' },
        { id: 'transfers' },
        { id: 'autoPay' },
        { id: 'mobile' }
    ],
    listIcon: 'nx-check-16',
    avatar: 'marketing_site/online-payments/Screen_Shot_2019-04-10_at_10.13.30_AM.png'
};

export default FrictionlessPaymentStore;
