const InvoiceGeneratorOtherFeaturesBlockStore = {
    sections: [
        {
            id: 'proposals',
            image: 'marketing_site/invoice-generator-lp/tutors/Module%209/Proposals.svg',
            linkUrl: '/proposal-software'
        },
        {
            id: 'contracts',
            image: 'marketing_site/invoice-generator-lp/tutors/Module%209/contracts.svg',
            linkUrl: '/online-contract'
        },
        {
            id: 'allInOne',
            image: 'marketing_site/invoice-generator-lp/tutors/Module%209/all_in_one.svg',
            linkUrl: '/all-in-one-business-platform'
        },
        {
            id: 'mobileApp',
            image: 'marketing_site/invoice-generator-lp/tutors/Module%209/mobile.svg',
            linkUrl: 'https://honeybook.onelink.me/850908038/qpe0ybvh'
        },
        {
            id: 'scheduling',
            image: 'marketing_site/invoice-generator-lp/tutors/Module%209/Scheduling.svg',
            linkUrl: '/meeting-scheduler'
        },
        {
            id: 'onlinePayments',
            image: 'marketing_site/invoice-generator-lp/tutors/Module%209/payments.svg',
            linkUrl: '/online-payment-software'
        }
    ]
};

export default InvoiceGeneratorOtherFeaturesBlockStore;
