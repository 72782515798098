import { graphql } from 'gatsby';
import AdobeB from './adobeB';

import '../styles/adobe.scss';

const AdobePageB = () => {
    return <AdobeB />;
};

export default AdobePageB;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: [
                        "home"
                        "oneBusinessPlatform"
                        "templatesRebrand"
                        "common"
                        "templates"
                        "getStarted"
                        "checklistHero"
                        "onlineInvoices"
                        "adobe"
                        "faqs"
                    ]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
