import { memo } from 'react';
import * as PropTypes from 'prop-types';

import './footer-copyright.scss';

const currentYear = new Date().getFullYear();

interface Props {
    customClass?: string;
}

function FooterCopyright({ customClass }: Props): JSX.Element {
    return (
        <small className={`copyright-text ${customClass || ''}`}>
            &#169; {currentYear} HoneyBook Inc.
        </small>
    );
}

FooterCopyright.propTypes = {
    customClass: PropTypes.string
};

export default memo(FooterCopyright);
