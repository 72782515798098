const FeaturesInvoiceBlockStore = {
    blockData: {
        id: 'invoices',
        image: 'marketing_site/features_page/light-brows.png',
        slideInImage: 'marketing_site/features-hub/proposal_illustration.png',
        quoteMember: 'Mark Dickinson',
        quoteAvatar:
            'marketing_site/features-hub/testimonial_headshots/mark_dickinson.png',
        link: '/online-invoices'
    }
};

export default FeaturesInvoiceBlockStore;
