import { useTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import HBText from '../../honeybook-ui/HBText/HBText';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBButton from '../../honeybook-ui/HBButton/HBButton';

import './how-it-works-block.scss';

const features = [
    {
        imageUrl: 'marketing_site/mission-statement/enter-company-details.svg',
        transId: 'companyDetails'
    },
    {
        imageUrl:
            'marketing_site/mission-statement/select-business-personality.svg',
        transId: 'brand'
    },
    {
        imageUrl: 'marketing_site/mission-statement/Group_1000001719.svg',
        transId: 'statement'
    }
];

interface Props {
    onClick: () => void;
}

const HowItWorksBlock = ({ onClick }: Props) => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="how-it-works-block">
            <FeatureBlockContent>
                <HBText
                    customClass="how-it-works-block__title"
                    tag="h2"
                    type="title-m"
                >
                    {t('missionStatement.howItWorks.title')}
                </HBText>
                <div className="how-it-works-block__features-container">
                    {features.map(feature => (
                        <div
                            className="how-it-works-block__feature"
                            key={feature.transId}
                        >
                            <HBMediaElement
                                name={feature.imageUrl}
                                customClass="how-it-works-block__image"
                            />
                            <HBText
                                tag="p"
                                type="body-m"
                                customClass="how-it-works-block__description"
                            >
                                {t(
                                    `missionStatement.howItWorks.features.${feature.transId}`
                                )}
                            </HBText>
                        </div>
                    ))}
                </div>
                <HBButton
                    customClass="how-it-works-block__cta"
                    onClick={onClick}
                    text={t('missionStatement.howItWorks.ctaText')}
                    size="large"
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default HowItWorksBlock;
