import { memo } from 'react';
import * as PropTypes from 'prop-types';

import HBModal from '../../honeybook-ui/HBModal/HBModal';
import HBIcon from '../../honeybook-ui/HBIcon/HBIcon';

import './video-modal.scss';

import type { VideoItem } from '../../VideoCarousel/VideoCarousel';

interface Props {
    video: VideoItem;
    isOpen: boolean;
    customClass: string;
    onClose: () => void;
}

function VideoModal({
    video,
    isOpen,
    customClass = '',
    onClose
}: Props): JSX.Element | null {
    if (!isOpen) return null;

    const handleEnterPress = e => {
        if (e.key === 'Enter') {
            onClose();
        }
    };

    return (
        <HBModal
            className={`video-modal ${customClass}`}
            isOpen={isOpen}
            onClose={onClose}
            header={false}
        >
            <div tabIndex={0} onKeyDown={handleEnterPress}>
                <HBIcon name="nx-close" onClick={onClose} />
            </div>
            <div className="video-container">
                <iframe
                    src={`${video.videoURL}?controls=0&rel=0&autoplay=1`}
                    frameBorder="0"
                    allow="autoplay"
                    title={video.id}
                    allowFullScreen
                />
            </div>
        </HBModal>
    );
}

VideoModal.propTypes = {
    video: PropTypes.shape({
        id: PropTypes.string.isRequired,
        videoURL: PropTypes.string.isRequired
    }).isRequired,
    isOpen: PropTypes.bool.isRequired,
    customClass: PropTypes.string,
    onClose: PropTypes.func.isRequired
};

export default memo(VideoModal);
