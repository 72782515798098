import { memo, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';

import type { ReactNode, MouseEvent as ReactMouseEvent } from 'react';

import Collapse from '../../vendor/blueprintjs/collapse';
import AnalyticsService, {
    AnalyticsEvents,
    GTM_ANALYTICS_EVENTS
} from '../../Services/AnalyticsService';
import ReactHtmlParser from 'react-html-parser';
import { TRANSLATION_COMPONENTS } from '../../constants/constants';

import './faq-container.scss';

interface Props {
    id: string;
    question: string;
    children?: ReactNode;
    answer?: string;
    answerTransKey: string;
    isActive: boolean;
    context: {
        source: string;
        signupLandingPage?: string;
    };
    currentOpenItemId: string;
    setCurrentOpenItemId: (itemId: string) => void;
}

function FAQItem({
    children,
    question,
    answer,
    answerTransKey,
    id,
    isActive,
    context,
    currentOpenItemId,
    setCurrentOpenItemId
}: Props): JSX.Element {
    const { t } = useTranslation();

    const handleFaqClick = useCallback(
        event => {
            const { itemId } = event.currentTarget.dataset;

            if (typeof itemId !== 'undefined') {
                AnalyticsService.trackGtm(GTM_ANALYTICS_EVENTS.faq_click, {
                    faq: itemId,
                    fqa_action:
                        currentOpenItemId === itemId ? 'collapse' : 'expand'
                });
                setCurrentOpenItemId(
                    currentOpenItemId === itemId ? '' : itemId
                );

                AnalyticsService.trackClick(
                    AnalyticsEvents.faq_section,
                    context
                );
            }
        },
        [context, currentOpenItemId, setCurrentOpenItemId]
    );

    const className = classnames('faq-container__toggle-icon', {
        'faq-container__toggle-icon--expand': isActive
    });

    return (
        <div className="faq-container__faq">
            <div
                role="button"
                tabIndex={0}
                onClick={handleFaqClick}
                onKeyDown={e => {
                    if (e.code == 'Space' || e.key == 'Enter') {
                        e.preventDefault();
                        handleFaqClick(e);
                    }
                }}
                data-item-id={id}
                className="faq-container__item"
            >
                <h3 className="faq-container__question">{t(question)}</h3>
                <span className={className} />
            </div>

            <Collapse isOpen={isActive} className="faq-container__answer">
                {/* TODO: convert all older instances of FAQs to use the answer prop and make it required */}
                <Trans
                    i18nKey={answerTransKey}
                    defaults={answer}
                    components={
                        answer
                            ? ReactHtmlParser(answer)
                            : TRANSLATION_COMPONENTS
                    }
                />
                {children}
            </Collapse>
        </div>
    );
}

FAQItem.propTypes = {
    question: PropTypes.string.isRequired,
    children: PropTypes.node,
    answer: PropTypes.string,
    answerTransKey: PropTypes.string,
    id: PropTypes.string.isRequired,
    isActive: PropTypes.bool
};

export default memo(FAQItem);
