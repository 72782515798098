import { memo } from 'react';
import * as PropTypes from 'prop-types';
import type { MouseEventHandler } from 'react';
import HBIcon from '../HBIcon/HBIcon';
import HBLoader from '../HBLoader/HBLoader';

import './hb-button.scss';

export type TButtonSize = 'x-small' | 'small' | 'medium' | 'large';
export type TButtonStyle =
    | 'primary'
    | 'primary--dark'
    | 'primary-rebrand'
    | 'primary--bright'
    | 'minimal'
    | 'invisible'
    | 'plain'
    | 'plain-primary'
    | 'plain-rebrand'
    | 'minimal-primary'
    | 'secondary'
    | 'secondary-rebrand'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger';
interface Props {
    text?: string;
    size?: TButtonSize;
    isDisabled?: boolean;
    buttonStyle?: TButtonStyle;
    type?: 'submit' | 'button' | 'reset';
    icon?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    customClass?: string;
    full?: boolean;
    isLoading?: boolean;
    e2eTestLocator?: string;
    'data-testid'?: string;
    ariaLabel?: string;
    role?: string;
}

function HBButton({
    text,
    isDisabled,
    icon,
    onClick,
    type,
    full,
    e2eTestLocator,
    isLoading = false,
    size = 'medium',
    buttonStyle = 'primary',
    customClass = '',
    'data-testid': testId,
    ariaLabel,
    role
}: Props): JSX.Element {
    const className = `hbui-button hbui-button--${size} hbui-button--${buttonStyle} ${
        full ? 'hbui-button--full' : ''
    } ${isLoading ? 'hbui-button--loading' : ''} ${customClass}`;

    return (
        // eslint-disable-next-line react/button-has-type
        <button
            className={className}
            onClick={onClick}
            disabled={isDisabled}
            type={type}
            e2e-test-locator={e2eTestLocator}
            data-testid={testId}
            aria-label={ariaLabel}
            role={role}
        >
            <span className="hbui-button__text">{text}</span>
            {icon && <HBIcon name={icon} />}
            {isLoading && <HBLoader />}
        </button>
    );
}

export const ButtonSizes = {
    X_SMALL: 'x-small',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
} as const;

export const ButtonStyles = {
    PRIMARY: 'primary',
    PRIMARY_DARK: 'primary--dark',
    PRIMARY_BRIGHT: 'primary--bright',
    MINIMAL: 'minimal',
    INVISIBLE: 'invisible',
    PLAIN: 'plain',
    PLAIN_PRIMARY: 'plain-primary',
    MINIAML_PRIMARY: 'minimal-primary',
    SECONDARY: 'secondary',
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    DANGER: 'danger'
} as const;

HBButton.propTypes = {
    text: PropTypes.string,
    size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large']),
    isDisabled: PropTypes.bool,
    buttonStyle: PropTypes.oneOf([
        'primary',
        'primary--dark',
        'primary--bright',
        'minimal',
        'invisible',
        'plain',
        'plain-primary',
        'minimal-primary',
        'secondary',
        'success',
        'info',
        'warning',
        'danger'
    ]),
    type: PropTypes.oneOf(['submit', 'button', 'reset']),
    icon: PropTypes.string,
    onClick: PropTypes.func,
    customClass: PropTypes.string,
    full: PropTypes.bool,
    isLoading: PropTypes.bool,
    e2eTestLocator: PropTypes.string
};

export default memo(HBButton);
