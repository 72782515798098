import React from 'react';
import { useABTestContext } from './context/useABTestContext';
import { Helmet } from 'react-helmet';
import { FALLBACK_VARIANT } from './constants';
export const ABTestFallbackVariants = () => {
    const { variantPerTest } = useABTestContext();
    return (
        <Helmet>
            <noscript className={FALLBACK_VARIANT.CLASS_NAME}>
                {JSON.stringify(variantPerTest)}
            </noscript>
        </Helmet>
    );
};
