import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';

import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import type { ClickableIconProps } from './ClickableIcon';
import ClickableIcon from './ClickableIcon';

import './social-media-block.scss';

const fullIcons: ClickableIconProps[] = [
    {
        iconImage: '/marketing_site/careers_page/career_social/glassdoor.svg',
        link: 'https://www.glassdoor.com/Overview/Working-at-HoneyBook-EI_IE982645.11,20.htm'
    },
    {
        iconImage: '/marketing_site/careers_page/career_social/medium.svg',
        link: 'https://wearehoneybook.medium.com/'
    },
    {
        iconImage: '/marketing_site/careers_page/career_social/linkedin.svg',
        link: 'https://www.linkedin.com/company/honeybook'
    }
];
const miniIcons: ClickableIconProps[] = [
    {
        iconImage: '/marketing_site/careers_page/career_social/instagram.svg',
        link: 'https://www.instagram.com/honeybook'
    },
    {
        iconImage: '/marketing_site/careers_page/career_social/twitter.svg',
        link: 'https://twitter.com/honeybook'
    },
    {
        iconImage: '/marketing_site/careers_page/career_social/facebook.svg',
        link: 'https://www.facebook.com/honeybook.co/'
    },
    {
        iconImage: '/marketing_site/careers_page/career_social/pinterest.svg',
        link: 'https://www.pinterest.com/honeybook/'
    }
];

function SocialMediaBlock(): JSX.Element {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="social-media-block">
            <FeatureBlockContent>
                <FeatureBlockText
                    customClass="social-media-block__title"
                    primary={t(`careersPageRebrand.socialMedia.title`)}
                    fullWidth
                />
                <div className={'icon-list'}>
                    <div className="row">
                        {fullIcons.map(icon => (
                            <ClickableIcon {...icon} key={icon.iconImage} />
                        ))}
                    </div>
                    <div className="row">
                        {miniIcons.map(icon => (
                            <ClickableIcon {...icon} key={icon.iconImage} />
                        ))}
                    </div>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default memo(SocialMediaBlock);
