import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';

import './sticky-sidebar.scss';

const StickySidebar = ({
    t,
    menuItems,
    onClick,
    fixedState,
    highlightMenuItem,
    transKey
}) => {
    return (
        <ul
            className={`sticky-sidebar ${
                fixedState ? `sticky-sidebar--${fixedState}` : ''
            }`}
        >
            {menuItems.map(menuItem => {
                const menuItemId = menuItem.id;
                const activeClass =
                    highlightMenuItem === menuItemId ? 'active' : '';
                return (
                    <div
                        key={menuItemId}
                        onClick={() => {
                            onClick(menuItemId);
                        }}
                    >
                        <li
                            className={`sticky-sidebar__list-item sticky-sidebar__list-item--${menuItemId} ${activeClass}`}
                        >
                            {menuItem.menuIcon && (
                                <HBMediaElement
                                    customClass="sticky-sidebar__list-item__icon"
                                    name={menuItem.menuIcon}
                                    forceImgTag
                                />
                            )}
                            <span className="sticky-sidebar__list-item__text">
                                {t(`${transKey}.${menuItemId}.title`)}
                            </span>
                        </li>
                    </div>
                );
            })}
        </ul>
    );
};

StickySidebar.propTypes = {
    t: PropTypes.func,
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    highlightMenuItem: PropTypes.string,
    fixedState: PropTypes.oneOf(['fixed', 'fixed-bottom', 'static']),
    onClick: PropTypes.func,
    transKey: PropTypes.string
};

export default withTranslation()(StickySidebar);
