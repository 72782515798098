import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import HBText from '../../honeybook-ui/HBText/HBText';
import ChevronLeft from '../../../svg/chevron-left.inline.svg';
interface Props {
    hasFailed: boolean;
    industry: string;
}

const StepSix = ({ hasFailed, industry }: Props) => {
    const { t } = useTranslation();
    const [isViewingExamples, setIsViewingExamples] = useState(false);
    const inputRef = useRef(null);

    const examples = t('missionStatement.quiz.step6.examples', {
        returnObjects: true
    });

    const handleClick = () => {
        setIsViewingExamples(isViewingExamples => !isViewingExamples);
        inputRef.current?.focus();
    };

    return (
        <div className="mission-statement-step mission-statement-step--six">
            <HBText
                tag="p"
                type="body-l"
                customClass="mission-statement-step__question"
            >
                {t('missionStatement.quiz.step6.question')}
            </HBText>

            <HBText
                tag="p"
                type="body-m"
                customClass="mission-statement-step__tip"
            >
                {t('missionStatement.quiz.step6.tip')}
            </HBText>

            <div className="mission-statement-step__input-container">
                <HBText
                    tag="p"
                    type="body-l"
                    customClass="mission-statement-step__by"
                >
                    {t('missionStatement.quiz.step6.by')}
                </HBText>
                <input
                    ref={inputRef}
                    autoFocus
                    className="mission-statement-step__input"
                    type="text"
                    placeholder={t(
                        `missionStatement.quiz.hints.${industry}.how_help`
                    )}
                />
            </div>

            {hasFailed && (
                <HBText
                    tag="p"
                    type="body-s"
                    customClass="mission-statement-step__error"
                >
                    {t('missionStatement.quiz.errors.missingField')}
                </HBText>
            )}

            <div onClick={handleClick}>
                <HBText
                    tag="p"
                    type="body-l"
                    customClass={`mission-statement-step__view-examples ${
                        isViewingExamples
                            ? 'mission-statement-step__view-examples--active'
                            : ''
                    }`}
                >
                    {t('missionStatement.quiz.step6.viewExamples')}{' '}
                    <ChevronLeft />
                </HBText>
            </div>

            {isViewingExamples && (
                <ul className="mission-statement-step__examples">
                    {examples.map(example => (
                        <li
                            className="mission-statement-step__example"
                            key={example}
                        >
                            ...{example}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default StepSix;
