const STATE_SPECIFIC_CONTENT = {
    CA: (
        <>
            <tr>
                <td>
                    <strong>California</strong>
                </td>
                <td>1.00%</td>
                <td>&gt;</td>
                <td>$0</td>
                <td>1.00%</td>
                <td>&gt;</td>
                <td>$0</td>
                <td>$5,202</td>
                <td>$10,404</td>
                <td>$140 credit</td>
                <td>$280 credit</td>
                <td>$433 credit</td>
            </tr>
            <tr>
                <td></td>
                <td>2.00%</td>
                <td>&gt;</td>
                <td>$10,099</td>
                <td>2.00%</td>
                <td>&gt;</td>
                <td>$20,198</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>4.00%</td>
                <td>&gt;</td>
                <td>$23,942</td>
                <td>4.00%</td>
                <td>&gt;</td>
                <td>$47,884</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>6.00%</td>
                <td>&gt;</td>
                <td>$37,788</td>
                <td>6.00%</td>
                <td>&gt;</td>
                <td>$75,576</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>8.00%</td>
                <td>&gt;</td>
                <td>$52,455</td>
                <td>8.00%</td>
                <td>&gt;</td>
                <td>$104,910</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>9.30%</td>
                <td>&gt;</td>
                <td>$66,295</td>
                <td>9.30%</td>
                <td>&gt;</td>
                <td>$132,590</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>10.30%</td>
                <td>&gt;</td>
                <td>$338,639</td>
                <td>10.30%</td>
                <td>&gt;</td>
                <td>$677,278</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>11.30%</td>
                <td>&gt;</td>
                <td>$406,364</td>
                <td>11.30%</td>
                <td>&gt;</td>
                <td>$812,728</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>12.30%</td>
                <td>&gt;</td>
                <td>$677,275</td>
                <td>12.30%</td>
                <td>&gt;</td>
                <td>$1,000,000</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>13.30%</td>
                <td>&gt;</td>
                <td>$1,000,000</td>
                <td>13.30%</td>
                <td>&gt;</td>
                <td>$1,354,550</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </>
    ),
    TX: (
        <tr>
            <td>
                <strong>Texas</strong>
            </td>
            <td colSpan={3}>none</td>
            <td colSpan={3}>none</td>
            <td>n.a.</td>
            <td>n.a.</td>
            <td>n.a.</td>
            <td>n.a.</td>
            <td>n.a.</td>
        </tr>
    ),
    FL: (
        <tr>
            <td>
                <strong>Florida</strong>
            </td>
            <td colSpan={3}>none</td>
            <td colSpan={3}>none</td>
            <td>n.a.</td>
            <td>n.a.</td>
            <td>n.a.</td>
            <td>n.a.</td>
            <td>n.a.</td>
        </tr>
    ),
    NY: (
        <>
            <tr>
                <td>
                    <strong>New York</strong>
                </td>
                <td>4.00%</td>
                <td>&gt;</td>
                <td>$0</td>
                <td>4.00%</td>
                <td>&gt;</td>
                <td>$0</td>
                <td>$8,000</td>
                <td>$16,050</td>
                <td>n.a.</td>
                <td>n.a.</td>
                <td>$1,000</td>
            </tr>
            <tr>
                <td></td>
                <td>4.50%</td>
                <td>&gt;</td>
                <td>$8,500</td>
                <td>4.50%</td>
                <td>&gt;</td>
                <td>$17,150</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>5.25%</td>
                <td>&gt;</td>
                <td>$11,700</td>
                <td>5.25%</td>
                <td>&gt;</td>
                <td>$23,600</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>5.50%</td>
                <td>&gt;</td>
                <td>$13,900</td>
                <td>5.50%</td>
                <td>&gt;</td>
                <td>$27,900</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>6.00%</td>
                <td>&gt;</td>
                <td>$80,650</td>
                <td>6.00%</td>
                <td>&gt;</td>
                <td>$161,550</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>6.85%</td>
                <td>&gt;</td>
                <td>$215,400</td>
                <td>6.85%</td>
                <td>&gt;</td>
                <td>$323,200</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>9.65%</td>
                <td>&gt;</td>
                <td>$1,077,550</td>
                <td>9.65%</td>
                <td>&gt;</td>
                <td>$2,155,350</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>10.30%</td>
                <td>&gt;</td>
                <td>$5,000,000</td>
                <td>10.30%</td>
                <td>&gt;</td>
                <td>$5,000,000</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>10.90%</td>
                <td>&gt;</td>
                <td>$25,000,000</td>
                <td>10.90%</td>
                <td>&gt;</td>
                <td>$25,000,000</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </>
    ),
    PA: (
        <tr>
            <td>
                <strong>Pennsylvania</strong>
            </td>
            <td>3.07%</td>
            <td>&gt;</td>
            <td>$0</td>
            <td>3.07%</td>
            <td>&gt;</td>
            <td>$0</td>
            <td>n.a.</td>
            <td>n.a.</td>
            <td>n.a.</td>
            <td>n.a.</td>
            <td>n.a.</td>
        </tr>
    ),
    IL: (
        <tr>
            <td>
                <strong>Illinois</strong>
            </td>
            <td>4.95%</td>
            <td>&gt;</td>
            <td>$0</td>
            <td>4.95%</td>
            <td>&gt;</td>
            <td>$0</td>
            <td>n.a.</td>
            <td>n.a.</td>
            <td>$2,425</td>
            <td>$2,850</td>
            <td>$2,425</td>
        </tr>
    ),
    OH: (
        <tr>
            <td>
                <strong>Ohio</strong>
            </td>
            <td>2.765%</td>
            <td>&gt;</td>
            <td>$26,050</td>
            <td>2.765%</td>
            <td>&gt;</td>
            <td>$26,050</td>
            <td>n.a.</td>
            <td>n.a.</td>
            <td>$2,400</td>
            <td>$4,800</td>
            <td>$2,400</td>
        </tr>
    ),
    GA: (
        <>
            <tr>
                <td>
                    <strong>Georgia</strong>
                </td>
                <td>1.00%</td>
                <td>&gt;</td>
                <td>$0</td>
                <td>1.00%</td>
                <td>&gt;</td>
                <td>$0</td>
                <td>$5,400</td>
                <td>$7,100</td>
                <td>$2,700</td>
                <td>$7,400</td>
                <td>$3,000</td>
            </tr>
            <tr>
                <td></td>
                <td>2.00%</td>
                <td>&gt;</td>
                <td>$750</td>
                <td>2.00%</td>
                <td>&gt;</td>
                <td>$1,000</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>3.00%</td>
                <td>&gt;</td>
                <td>$2,250</td>
                <td>3.00%</td>
                <td>&gt;</td>
                <td>$3,000</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>4.00%</td>
                <td>&gt;</td>
                <td>$3,750</td>
                <td>4.00%</td>
                <td>&gt;</td>
                <td>$5,000</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>5.00%</td>
                <td>&gt;</td>
                <td>$5,250</td>
                <td>5.00%</td>
                <td>&gt;</td>
                <td>$7,000</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td>5.75%</td>
                <td>&gt;</td>
                <td>$7,000</td>
                <td>5.75%</td>
                <td>&gt;</td>
                <td>$10,000</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </>
    ),
    NC: (
        <tr>
            <td>
                <strong>North Carolina</strong>
            </td>
            <td>4.75%</td>
            <td>&gt;</td>
            <td>$0</td>
            <td>4.75%</td>
            <td>&gt;</td>
            <td>$0</td>
            <td>$12,750</td>
            <td>$25,500</td>
            <td>n.a.</td>
            <td>n.a.</td>
            <td>n.a.</td>
        </tr>
    ),
    MI: (
        <tr>
            <td>
                <strong>Michigan</strong>
            </td>
            <td>4.25%</td>
            <td>&gt;</td>
            <td>$0</td>
            <td>4.25%</td>
            <td>&gt;</td>
            <td>$0</td>
            <td>n.a.</td>
            <td>n.a.</td>
            <td>$5,000</td>
            <td>$10,000</td>
            <td>$5,000</td>
        </tr>
    )
};

export default STATE_SPECIFIC_CONTENT;
