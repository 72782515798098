import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxTabContent from '../../CheckboxTabContent/CheckboxTabContent';
import TabsBlock from '../TabsBlock/TabsBlock';

function TabbedFeaturesBlock(): JSX.Element {
    const { t } = useTranslation();

    const tabsContent = useMemo(() => {
        return [
            {
                name: t('featureBlock.tabbedFeatures.tabs.business.title'),
                id: 'business',
                panel: (
                    <CheckboxTabContent bulletsString="featureBlock.tabbedFeatures.tabs.business.bullets" />
                )
            },
            {
                name: t('featureBlock.tabbedFeatures.tabs.clients.title'),
                id: 'clients',
                panel: (
                    <CheckboxTabContent bulletsString="featureBlock.tabbedFeatures.tabs.clients.bullets" />
                )
            },
            {
                name: t('featureBlock.tabbedFeatures.tabs.peaceOfMind.title'),
                id: 'peace-of-mind',
                panel: (
                    <CheckboxTabContent bulletsString="featureBlock.tabbedFeatures.tabs.peaceOfMind.bullets" />
                )
            }
        ];
    }, []);

    return (
        <TabsBlock
            title={t('TemplateTabsBlock.title')}
            tabsContent={tabsContent}
            source="tabbed_features"
        />
    );
}

export default memo(TabbedFeaturesBlock);
