import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import HBButton from '../../honeybook-ui/HBButton/HBButton';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';

import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './press-kit-block.scss';
import HBText from '../../honeybook-ui/HBText/HBText';

interface Props {
    pressKitUrl: string;
    lightBackground?: boolean;
}

function PressKitBlock({ pressKitUrl, lightBackground }: Props): JSX.Element {
    const { t } = useTranslation();

    const onClick = useCallback(() => {
        AnalyticsService.trackClick(AnalyticsEvents.press_link, {
            source: pressKitUrl
        });
    }, [pressKitUrl]);

    return (
        <FeatureBlockSection
            customClass="press-page__press-kit-block"
            lightBackground={lightBackground}
        >
            <FeatureBlockContent customClass="press-page__press-kit-block__content">
                <FeatureBlockText
                    fullWidth
                    primary={t(`pressPage.blocks.pressKit.title`)}
                />
                <HBText
                    tag="p"
                    type="body-s"
                    customClass="press-page__press-kit-block__description"
                >
                    {t(`pressPage.blocks.pressKit.description`)}
                </HBText>
                <a
                    href={pressKitUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onClick}
                >
                    <HBButton text={t(`pressPage.blocks.pressKit.button`)} />
                </a>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default memo(PressKitBlock);
