import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import WebinarTimeSlotsContainer from '../../WebinarTimeSlotsContainer/WebinarTimeSlotsContainer';
import WebinarRecordingsContainer from '../../WebinarRecordingsContainer/WebinarRecordingsContainer';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import { TRANSLATION_COMPONENTS } from '../../../constants/constants';

import './webinar-block.scss';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

const WebinarBlock = ({ t, webinars }) => {
    return (
        <FeatureBlockSection customClass="webinar-block">
            <FeatureBlockContent>
                {webinars.map(
                    ({ id, link, image, recordings, webinarSlots }) => {
                        return (
                            <div
                                key={id}
                                id={`webinar-block__${id}`}
                                className="webinar-block__column"
                            >
                                <div className="webinar-block__intro-text">
                                    {link && (
                                        <HBMediaElement
                                            name={image}
                                            customClass="webinar-block__image"
                                        />
                                    )}

                                    <h2 className="webinar-block__title">
                                        {t(
                                            `productEducationPage.webinars.${id}.title`
                                        )}
                                    </h2>

                                    <Trans components={TRANSLATION_COMPONENTS}>
                                        {t(
                                            `productEducationPage.webinars.${id}.description`
                                        )}
                                    </Trans>

                                    {link && (
                                        <a
                                            href={link}
                                            className="webinar-block__button hbui-button hbui-button--small hbui-button--primary"
                                        >
                                            {t(
                                                `productEducationPage.webinars.chooseSession.ctaText`
                                            )}
                                        </a>
                                    )}
                                </div>

                                {recordings && (
                                    <WebinarRecordingsContainer
                                        recordings={recordings}
                                    />
                                )}

                                {webinarSlots && (
                                    <WebinarTimeSlotsContainer
                                        webinarSlots={webinarSlots}
                                    />
                                )}
                            </div>
                        );
                    }
                )}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

WebinarBlock.propTypes = {
    t: PropTypes.func,
    webinars: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withTranslation()(WebinarBlock);
