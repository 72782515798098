import type { ReactNode } from 'react';
import ABTestToolServiceV2 from '../../Services/ABTestToolService.v2';
import UtilsService from '../../Services/UtilsService';
import { ABTestContext } from './ab-test.context';
import { useMemo } from 'react';
import type { TBuildProps } from '../types/ab-test-types';
import { handleMissingTests } from '../ab-test-routing-utils';
import { useLocation } from '@gatsbyjs/reach-router';
import type { TRunningTests } from '../types/running-tests-config';

type Props = {
    children: ReactNode;
} & TBuildProps;

export const ABTestContextProvider = ({
    children,
    buildVariantPerTest
}: Props) => {
    const { pathname: browserPagePath } = useLocation();

    const variantPerTest: Partial<TRunningTests> = useMemo(() => {
        if (!UtilsService.isBrowser()) {
            return buildVariantPerTest;
        } else {
            const _variantPerTest =
                ABTestToolServiceV2.getVariantPerTestInPage(browserPagePath);
            // will populate the missing tests with the variants the page generated with
            return handleMissingTests(
                browserPagePath,
                _variantPerTest,
                'ABTestContextProvider'
            );
        }
    }, [browserPagePath]);

    return (
        <ABTestContext.Provider value={{ variantPerTest }}>
            {children}
        </ABTestContext.Provider>
    );
};
