import ApiService from './ApiService';

interface TCloudinaryResponse {
    asset_id: string;
    bytes: number;
    created_at: string;
    etag: string;
    folder: string;
    format: string;
    height: number;
    width: number;
    original_filename: string;
    placeholder: boolean;
    public_id: string;
    resource_type: string;
    secure_url: string;
    signature: string;
    tags: any[];
    type: string;
    url: string;
    version: number;
    version_id: string;
}

interface TFile extends Blob {
    lastModified: number;
    lastModifiedDate: Date;
    name: string;
    size: number;
    type: string;
    webkitRelativePath: string;
}

const uploadImage = async (file: TFile) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append(
        'upload_preset',
        process.env.CLOUDINARY_UPLOAD_PRESET || ''
    );
    const res = (await ApiService.uploadImage(formData)) as TCloudinaryResponse;
    return res.url;
};

export const CloudinaryService = {
    uploadImage
};
