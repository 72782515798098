import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel, Keyboard } from 'swiper';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import HBText from '../../honeybook-ui/HBText/HBText';
import ImageTextElement from '../../ImageTextElement/ImageTextElement';
import { useWindowSize } from '../../../hooks/useWindowSize';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import ArrowIcon from '../../../svg/arrow-left-line.inline.svg';
import ChevronLeft from '../../../svg/chevron-left.inline.svg';

import 'swiper/css';
import './members-carousel.scss';

const MembersCarousel = () => {
    const { t } = useTranslation();
    const { windowSize } = useWindowSize();

    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSetCurrentSlide = ({ activeIndex }) => {
        AnalyticsService.trackClick(
            AnalyticsEvents.reviews_carousel_navigation,
            {
                source: 'members carousel',
                direction: activeIndex < currentSlide ? 'left' : 'right'
            }
        );
        setCurrentSlide(activeIndex + 1);
    };

    const slidesPerView = useMemo(() => {
        if (windowSize.width > 2400) {
            return 7.5;
        }
        if (windowSize.width > 1900) {
            return 6.5;
        }
        if (windowSize.width > 1500) {
            return 5.5;
        }
        if (windowSize.width > 1200) {
            return 4.5;
        }
        if (windowSize.width > 820) {
            return 3.5;
        }
        if (windowSize.width > 500) {
            return 2;
        }
        return 1;
    }, [windowSize.width]);

    const caseStudies = [
        {
            imageUrl:
                'marketing_site/reviews/membersCarousel/Cards%20%28desktop%20and%20mweb%29/case-study-01_2x.png',
            transId: 'caseStudy1',
            linkUrl: '/blog/case-study-scheduling'
        },
        {
            transId: 'caseStudy2',
            linkUrl: '/blog/case-study-automating-processes'
        },
        {
            imageUrl:
                'marketing_site/reviews/membersCarousel/Cards%20%28desktop%20and%20mweb%29/case-study-03_2x.png',
            transId: 'caseStudy3',
            linkUrl: '/blog/honeybook-for-consultants'
        },
        { transId: 'caseStudy4', linkUrl: '/blog/case-study-lead-forms' },
        {
            imageUrl:
                'marketing_site/reviews/membersCarousel/Cards%20%28desktop%20and%20mweb%29/maxwell-bentley-crop.jpg',
            transId: 'caseStudy5',
            linkUrl: '/blog/honeybook-for-marketing-agency'
        },
        {
            transId: 'caseStudy6',
            linkUrl: '/blog/how-website-designer-used-honeybook'
        },
        {
            imageUrl:
                'marketing_site/reviews/membersCarousel/Cards%20%28desktop%20and%20mweb%29/case-study-07_2x.png',
            transId: 'caseStudy7',
            linkUrl: '/blog/case-study-business-growth'
        },
        { transId: 'caseStudy8' }
    ];

    return (
        <div className="members-carousel" id="members-carousel">
            <FeatureBlockSection>
                <FeatureBlockContent customClass="members-carousel__container">
                    <HBText
                        tag="h2"
                        type="title-l"
                        customClass="members-carousel__title"
                    >
                        {t(`ReviewsPage.membersCarousel.title`)}
                    </HBText>
                    <HBText
                        tag="h3"
                        type="body-l"
                        customClass="members-carousel__subtitle"
                    >
                        {t(`ReviewsPage.membersCarousel.subtitle`)}
                    </HBText>
                </FeatureBlockContent>
            </FeatureBlockSection>
            <FeatureBlockSection customClass="swiper-container">
                <FeatureBlockContent>
                    <Swiper
                        className="members-carousel__swiper"
                        onSlideChange={handleSetCurrentSlide}
                        slidesPerView={slidesPerView}
                        draggable={false}
                        mousewheel={false}
                        initialSlide={caseStudies.length / 2}
                        spaceBetween={24}
                        navigation={{
                            prevEl: '.swiper-container__prev',
                            nextEl: '.swiper-container__next'
                        }}
                        modules={[Navigation, Mousewheel, Keyboard]}
                        centeredSlides={true}
                    >
                        {caseStudies.map(({ imageUrl, transId, linkUrl }) => (
                            <SwiperSlide key={transId}>
                                <div
                                    className={`members-carousel__case-study ${
                                        imageUrl
                                            ? ''
                                            : 'members-carousel__case-study--text'
                                    } ${
                                        !linkUrl &&
                                        'members-carousel__case-study--final'
                                    }
                                    ${`members-carousel__case-study--${transId}`}`}
                                >
                                    <ImageTextElement
                                        imagePath={imageUrl}
                                        mediaFlags={{ lazyLoad: false }}
                                        description={t(
                                            `ReviewsPage.membersCarousel.caseStudies.${transId}`
                                        )}
                                    />
                                    {linkUrl ? (
                                        <a
                                            className="members-carousel__case-study__link"
                                            href={linkUrl}
                                        >
                                            {t(
                                                `ReviewsPage.membersCarousel.caseStudies.readMore`
                                            )}
                                            <ChevronLeft />
                                        </a>
                                    ) : (
                                        <a
                                            className="members-carousel__case-study__link"
                                            href="/blog"
                                        >
                                            {t(
                                                'ReviewsPage.membersCarousel.caseStudies.blogLinkText'
                                            )}
                                            <ChevronLeft />
                                        </a>
                                    )}
                                </div>
                            </SwiperSlide>
                        ))}

                        <div className="swiper-container__controls">
                            <div
                                className={`swiper-container__prev ${
                                    currentSlide <= 1
                                        ? 'swiper-container__prev--disabled'
                                        : ''
                                }`}
                            >
                                <ArrowIcon />
                            </div>
                            <div
                                className={`swiper-container__next ${
                                    currentSlide > caseStudies.length - 1
                                        ? 'swiper-container__next--disabled'
                                        : ''
                                }`}
                            >
                                <ArrowIcon />
                            </div>
                        </div>
                    </Swiper>
                    <RegistrationForm
                        size="large"
                        customClass="members-carousel__cta"
                        direction="column"
                        source="members carousel"
                        showNoCcRequired
                    />
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

export default MembersCarousel;
