import { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { TRANSLATION_COMPONENTS } from '../../../constants/constants';

import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import FeatureBlockUserQuote from '../../FeatureBlockElements/FeatureBlockUserQuote/FeatureBlockUserQuote';
import FeatureBlockImage from '../../FeatureBlockElements/FeatureBlockImage/FeatureBlockImage';
import ProposalsToast from '../../ProposalsToast/ProposalsToast';

import './payment-booked-block.scss';

const featureImage = 'marketing_site/lp/proposal/payment.png';

const quoteAvatar = 'marketing_site/proposals_page/Taylor_of_Bixby_Pine.jpg';

function PaymentBookedBlock({
    customTransId = 'featureBlock.paymentBooked'
}): JSX.Element {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection
            customClass="feature-block__payment-booked"
            lightBackground
        >
            <FeatureBlockContent>
                <FeatureBlockImage
                    customClass="feature-block__payment-booked-img"
                    featureImage={featureImage}
                    width={570}
                />
                <ProposalsToast
                    position="bottom"
                    iconClass="icon-hb-nx-dollar-recurring"
                    content={t(`${customTransId}.toastContent`)}
                    timeAgo={t(`${customTransId}.timeAgo`)}
                    entranceDirection="left"
                />
            </FeatureBlockContent>
            <FeatureBlockContent position="right">
                <FeatureBlockText
                    primary={
                        <Trans
                            i18nKey={`${customTransId}.title`}
                            components={TRANSLATION_COMPONENTS}
                        />
                    }
                    secondary={t(`${customTransId}.indicator.file`)}
                    subheading={t(`${customTransId}.subtitle`)}
                />
                <FeatureBlockUserQuote
                    hideQuotes
                    name={t(`${customTransId}.quote.userName`)}
                    jobTitle={t(`${customTransId}.quote.jobTitle`)}
                    avatar={quoteAvatar}
                    avatarHeight={40}
                    avatarWidth={40}
                    avatarDpr={1}
                    avatarCrop="thumb"
                    avatarGravity="face"
                    quote={t(`${customTransId}.quote.text`)}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default memo(PaymentBookedBlock);
