import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import ComparisonTableRow from './ComparisonTableRow';
import HBLogo from '../../../svg/honeybook-logo.inline.svg';

import './comparison-table.scss';

const ComparisonTableBlock = ({
    lightBackground,
    contentKey,
    transKey,
    finePrint
}) => {
    const { t } = useTranslation();

    const allComparisonData = {
        dubsado: {
            columns: ['honeybook', 'dubsado'],
            comparisonData: [
                {
                    id: 'header',
                    customClass: 'comparison-table-row--header',
                    noFeature: true,
                    cellContent: {
                        honeybook: {
                            cellElement: <HBLogo />
                        },
                        dubsado: {
                            cellElement: (
                                <>{t(`${contentKey}.table.headers.dubsado`)}</>
                            )
                        }
                    }
                },
                {
                    id: 'monthlyPricing',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        dubsado: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'mobileApp',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        dubsado: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'payment',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />,
                            hasDescription: true
                        },
                        dubsado: {
                            cellElement: <HBIcon name="nx-close" />,
                            hasDescription: true
                        }
                    }
                },
                {
                    id: 'security',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        dubsado: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'fraud',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        dubsado: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'files',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        dubsado: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'scheduling',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        dubsado: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'customUrl',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        dubsado: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'qbOnline',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        dubsado: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'task',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        dubsado: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'moneyBack',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        dubsado: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'pricing',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />,
                            hasDescription: true
                        },
                        dubsado: {
                            cellElement: <HBIcon name="nx-check" />,
                            hasDescription: true
                        }
                    }
                },
                {
                    id: 'chromeExt',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        dubsado: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'referrals',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        dubsado: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'deposit',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        dubsado: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'registrationForm',
                    noFeature: true,
                    cellContent: {
                        honeybook: {
                            cellElement: (
                                <RegistrationForm
                                    showNoCcRequired
                                    direction="column"
                                    source="comparison table"
                                />
                            )
                        },
                        dubsado: {
                            cellElement: <></>
                        }
                    }
                }
            ]
        },
        freshbooks: {
            columns: ['honeybook', 'freshbooks'],
            comparisonData: [
                {
                    id: 'header',
                    customClass: 'comparison-table-row--header',
                    noFeature: true,
                    cellContent: {
                        honeybook: {
                            cellElement: <HBLogo />
                        },
                        freshbooks: {
                            cellElement: (
                                <>
                                    {t(
                                        `${contentKey}.table.headers.freshbooks`
                                    )}
                                </>
                            )
                        }
                    }
                },
                {
                    id: 'monthlyPricing',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        freshbooks: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'freeTrial',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'unlimitedClientsAndProjects',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />,
                            hasDescription: true
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-close" />,
                            hasDescription: true
                        }
                    }
                },
                {
                    id: 'mobileApp',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'scheduling',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />,
                            hasDescription: true
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-close" />,
                            hasDescription: true
                        }
                    }
                },
                {
                    id: 'clientPortal',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'questionnaires',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'deposit',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'referrals',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        freshbooks: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'security',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'fraud',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'chromeExt',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'qbOnline',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'task',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'capital',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'gratuity',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'clientAutoPayment',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'paymentReminders',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'contactForm',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'teamMembers',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'whitelabelling',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'emailIntegration',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'zoomIntegration',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        freshbooks: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'registrationForm',
                    noFeature: true,
                    cellContent: {
                        honeybook: {
                            cellElement: (
                                <RegistrationForm
                                    showNoCcRequired
                                    direction="column"
                                    source="comparison table"
                                />
                            )
                        },
                        freshbooks: {
                            cellElement: <></>
                        }
                    }
                }
            ]
        },
        seventeenHats: {
            columns: ['honeybook', 'seventeenHats'],
            comparisonData: [
                {
                    id: 'header',
                    customClass: 'comparison-table-row--header',
                    noFeature: true,
                    cellContent: {
                        honeybook: {
                            cellElement: <HBLogo />
                        },
                        seventeenHats: {
                            cellElement: (
                                <>
                                    {t(
                                        `${contentKey}.table.headers.seventeenHats`
                                    )}
                                </>
                            )
                        }
                    }
                },
                {
                    id: 'monthlyPricing',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        seventeenHats: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'freeTrial',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'unlimitedClientsAndProjects',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'refundPolicy',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'mobileApp',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'scheduling',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'clientPortal',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'questionnaires',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'workflowAutomation',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'deposit',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'referrals',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        seventeenHats: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'recurringPayments',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'timeTracking',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'security',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'fraud',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'chromeExt',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'qbOnline',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'task',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'capital',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'unlimitedTemplates',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'gratuity',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'unlimitedTemplates',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'clientAutoPayment',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'paymentReminders',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'contactForm',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'teamMembers',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'whitelabelling',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'emailIntegration',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'zoomIntegration',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        seventeenHats: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'registrationForm',
                    noFeature: true,
                    cellContent: {
                        honeybook: {
                            cellElement: (
                                <RegistrationForm
                                    showNoCcRequired
                                    direction="column"
                                    source="comparison table"
                                />
                            )
                        },
                        seventeenHats: {
                            cellElement: <></>
                        }
                    }
                }
            ]
        },
        mondayDotCom: {
            columns: ['honeybook', 'mondayDotCom'],
            comparisonData: [
                {
                    id: 'header',
                    customClass: 'comparison-table-row--header',
                    noFeature: true,
                    cellContent: {
                        honeybook: {
                            cellElement: <HBLogo />
                        },
                        mondayDotCom: {
                            cellElement: (
                                <>
                                    {t(
                                        `${contentKey}.table.headers.mondayDotCom`
                                    )}
                                </>
                            )
                        }
                    }
                },
                {
                    id: 'monthlyPricing',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        mondayDotCom: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'freeTrial',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'unlimitedClientsAndProjects',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'refundPolicy',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'mobileApp',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'scheduling',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'clientPortal',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'questionnaires',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'workflowAutomation',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'referrals',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'recurringPayments',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'timeTracking',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'fraud',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'chromeExt',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'qbOnline',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'task',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'capital',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'unlimitedTemplates',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'gratuity',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'clientAutoPayment',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'paymentReminders',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'contactForm',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'teamMembers',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'whitelabelling',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'emailIntegration',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'zoomIntegration',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        mondayDotCom: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'registrationForm',
                    noFeature: true,
                    cellContent: {
                        honeybook: {
                            cellElement: (
                                <RegistrationForm
                                    showNoCcRequired
                                    direction="column"
                                    source="comparison table"
                                />
                            )
                        },
                        mondayDotCom: {
                            cellElement: <></>
                        }
                    }
                }
            ]
        },
        bonsai: {
            columns: ['honeybook', 'bonsai'],
            comparisonData: [
                {
                    id: 'header',
                    customClass: 'comparison-table-row--header',
                    noFeature: true,
                    cellContent: {
                        honeybook: {
                            cellElement: <HBLogo />
                        },
                        bonsai: {
                            cellElement: (
                                <>{t(`${contentKey}.table.headers.bonsai`)}</>
                            )
                        }
                    }
                },
                {
                    id: 'monthlyPricing',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        bonsai: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'freeTrial',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'unlimitedClientsAndProjects',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'refundPolicy',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />,
                            hasDescription: true
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-check" />,
                            hasDescription: true
                        }
                    }
                },
                {
                    id: 'mobileApp',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'scheduling',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'clientPortal',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'questionnaires',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'workflowAutomation',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'referrals',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        bonsai: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'recurringPayments',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'timeTracking',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'chromeExt',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'qbOnline',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'task',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'capital',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'unlimitedTemplates',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'gratuity',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'clientAutoPayment',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'paymentReminders',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'contactForm',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'teamMembers',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'whitelabelling',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'emailIntegration',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'zoomIntegration',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        bonsai: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'registrationForm',
                    noFeature: true,
                    cellContent: {
                        honeybook: {
                            cellElement: (
                                <RegistrationForm
                                    showNoCcRequired
                                    direction="column"
                                    source="comparison table"
                                />
                            )
                        },
                        bonsai: {
                            cellElement: <></>
                        }
                    }
                }
            ]
        },
        pipedrive: {
            columns: ['honeybook', 'pipedrive'],
            comparisonData: [
                {
                    id: 'header',
                    customClass: 'comparison-table-row--header',
                    noFeature: true,
                    cellContent: {
                        honeybook: {
                            cellElement: <HBLogo />
                        },
                        pipedrive: {
                            cellElement: (
                                <>
                                    {t(`${contentKey}.table.headers.pipedrive`)}
                                </>
                            )
                        }
                    }
                },
                {
                    id: 'monthlyPricing',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        pipedrive: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'freeTrial',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'unlimitedClientsAndProjects',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'refundPolicy',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'mobileApp',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'scheduling',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'clientPortal',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'questionnaires',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'workflowAutomation',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'referrals',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        pipedrive: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'recurringPayments',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'timeTracking',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />,
                            hasDescription: true
                        }
                    }
                },
                {
                    id: 'chromeExt',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'qbOnline',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'task',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />,
                            hasDescription: true
                        }
                    }
                },
                {
                    id: 'capital',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'unlimitedTemplates',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'gratuity',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'clientAutoPayment',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'paymentReminders',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'contactForm',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'teamMembers',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'whitelabelling',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'emailIntegration',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'zoomIntegration',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        pipedrive: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'registrationForm',
                    noFeature: true,
                    cellContent: {
                        honeybook: {
                            cellElement: (
                                <RegistrationForm
                                    showNoCcRequired
                                    direction="column"
                                    source="comparison table"
                                />
                            )
                        },
                        pipedrive: {
                            cellElement: <></>
                        }
                    }
                }
            ]
        },
        vcita: {
            columns: ['honeybook', 'vcita'],
            comparisonData: [
                {
                    id: 'header',
                    customClass: 'comparison-table-row--header',
                    noFeature: true,
                    cellContent: {
                        honeybook: {
                            cellElement: <HBLogo />
                        },
                        vcita: {
                            cellElement: (
                                <>{t(`${contentKey}.table.headers.vcita`)}</>
                            )
                        }
                    }
                },
                {
                    id: 'monthlyPricing',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        vcita: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'freeTrial',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'expenseTracking',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'unlimitedClientsAndProjects',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />,
                            hasDescription: true
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-close" />,
                            hasDescription: true
                        }
                    }
                },
                {
                    id: 'refundPolicy',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'scheduling',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'clientPortal',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'workflowAutomation',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'referrals',
                    cellContent: {
                        honeybook: {
                            hasTitle: true
                        },
                        vcita: {
                            hasTitle: true
                        }
                    }
                },
                {
                    id: 'recurringPayments',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'timeTracking',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'chromeExt',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'task',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'capital',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'gratuity',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'clientAutoPayment',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'paymentReminders',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'contactForm',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'teamMembers',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'whitelabelling',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'emailIntegration',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-close" />
                        }
                    }
                },
                {
                    id: 'zoomIntegration',
                    cellContent: {
                        honeybook: {
                            cellElement: <HBIcon name="nx-check" />
                        },
                        vcita: {
                            cellElement: <HBIcon name="nx-check" />
                        }
                    }
                },
                {
                    id: 'registrationForm',
                    noFeature: true,
                    cellContent: {
                        honeybook: {
                            cellElement: (
                                <RegistrationForm
                                    showNoCcRequired
                                    direction="column"
                                    source="comparison table"
                                />
                            )
                        },
                        vcita: {
                            cellElement: <></>
                        }
                    }
                }
            ]
        }
    };

    const { columns, comparisonData } = allComparisonData[contentKey];

    return (
        <FeatureBlockSection
            lightBackground={lightBackground}
            customClass="comparison-table-block"
        >
            <FeatureBlockContent>
                <div className="comparison-table">
                    {comparisonData.map(data => (
                        <ComparisonTableRow
                            key={data.id}
                            customClass={data.customClass}
                            columns={columns}
                            comparisonData={data}
                            transKey={transKey}
                        />
                    ))}
                    {finePrint && (
                        <p className="comparison-table__fine-print">
                            {finePrint}
                        </p>
                    )}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

ComparisonTableBlock.propTypes = {
    contentKey: PropTypes.string.isRequired,
    transKey: PropTypes.string,
    finePrint: PropTypes.shape({}),
    lightBackground: PropTypes.bool
};

export default ComparisonTableBlock;
