import { memo } from 'react';
import classnames from 'classnames';
import type { ReactNode } from 'react';

import UtilsService from '../../../Services/UtilsService';

import './feature-block-section.scss';
import type { Display } from '../../../Services/UtilsService';

interface Props {
    children?: ReactNode;
    display?: Display;
    lightBackground?: boolean;
    darkBackground?: boolean;
    smallHeight?: boolean;
    halfSize?: boolean;
    customClass?: string;
    fullWidth?: boolean;
}

function FeatureBlockSection({
    children,
    lightBackground = false,
    smallHeight,
    darkBackground = false,
    display,
    halfSize,
    fullWidth = false,
    customClass = ''
}: Props): React.ReactElement {
    const className = classnames('feature-block', {
        'feature-block--light': lightBackground,
        'feature-block--dark': darkBackground,
        'feature-block--small-height': smallHeight,
        'feature-block--half': halfSize,
        'feature-block--full-width': fullWidth,
        [`${customClass}`]: Boolean(customClass),
        [UtilsService.screens(display)]: Boolean(display)
    });

    return (
        <section className={className}>
            <div className="feature-block__container">{children}</div>
        </section>
    );
}

export default memo(FeatureBlockSection);
