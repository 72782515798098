import { memo, useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';

import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';
import AppStoreRating from '../AppStoreRating/AppStoreRating';

import AnalyticsService, {
    AnalyticsEvents,
    GTM_ANALYTICS_EVENTS
} from '../../Services/AnalyticsService';

import './app-download-button.scss';

interface Props {
    url: string;
    context: string;
    width?: number;
    platform?: 'iphone' | 'android';
    darkMode?: boolean;
    caption?: boolean;
    source?: string;
}

const imgPathData = {
    light: {
        iphone: 'v1548328799/system_web/mobile_icons/apple-store-btn-footer.svg',
        android: 'v1548328799/system_web/mobile_icons/g-play-btn-footer.svg'
    },
    dark: {
        iphone: 'v1555520810/system_web/mobile_icons/apple-store-btn-dark.svg',
        android: 'v1555520810/system_web/mobile_icons/g-play-btn-dark.svg'
    }
};

function AppDownloadButton({
    url,
    width = 120,
    platform = 'iphone',
    darkMode,
    context,
    caption,
    source
}: Props): JSX.Element {
    const handleAnalytics = useCallback(() => {
        AnalyticsService.track(
            source || AnalyticsEvents.footer_navigation_button_click,
            {
                context
            }
        );
        AnalyticsService.trackGtm(GTM_ANALYTICS_EVENTS.app_download_click, {
            platform
        });
    }, [context, source]);

    const shouldShowCaption = () => {
        return platform === 'android' ? (
            <span className="app-download-link__subtext">
                New! Check it out.
            </span>
        ) : (
            <span className="app-download-link__subtext">
                <AppStoreRating />
            </span>
        );
    };

    const svgUrl = useMemo(
        () =>
            darkMode ? imgPathData.dark[platform] : imgPathData.light[platform],
        [darkMode, platform]
    );

    return (
        <a className="app-download-link" href={url} onClick={handleAnalytics}>
            <HBMediaElement
                width={width}
                crop="scale"
                name={svgUrl}
                type="Image"
                forceImgTag
                alt={platform === 'android' ? 'Google Play' : 'Apple App Store'}
            />
            {caption && shouldShowCaption()}
        </a>
    );
}

AppDownloadButton.propTypes = {
    url: PropTypes.string.isRequired,
    width: PropTypes.number,
    darkMode: PropTypes.bool,
    caption: PropTypes.bool,
    platform: PropTypes.string,
    context: PropTypes.string.isRequired,
    source: PropTypes.string
};

export default memo(AppDownloadButton);
