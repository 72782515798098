import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import HBVideo from '@honeybook-ui/HBVideo/HBVideo';
import ImageListAndQuoteBlock, {
    Props
} from '../ImageListAndQuoteBlock/ImageListAndQuoteBlock';

import FrictionlessPaymentStore from './FrictionlessPaymentStore';
import './frictionless-payment.scss';

const FrictionlessPaymentBlock = () => {
    const props: Props = {
        featureName: 'frictionlessPayment',
        featureClassName: 'frictionless-payment',
        mediumUpMediaElement: (
            <HBVideo
                autoplay
                loop
                muted
                videoId="770388001"
                padding="75% 0 0 0"
            />
        ),
        mediumDownMediaElement: (
            <HBMediaElement
                width={530}
                name="marketing_site/online-payments/Proposal_static.png"
            />
        ),
        listItems: FrictionlessPaymentStore.listItems,
        listIcon: FrictionlessPaymentStore.listIcon,
        quoteProps: {
            name: 'Kati Fictum',
            avatar: FrictionlessPaymentStore.avatar
        }
    };

    return <ImageListAndQuoteBlock {...props} />;
};

export default FrictionlessPaymentBlock;
