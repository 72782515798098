import * as PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../FeatureBlockElements';
import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../honeybook-ui/HBText/HBText';

import './featured-in.scss';

const defaultFeaturedItems = [
    {
        imgUrl: 'marketing_site/featured_in/fortune-dark.png',
        alt: 'Fortune'
    },
    {
        imgUrl: 'marketing_site/featured_in/cnbc-dark.png',
        alt: 'CNBC'
    },
    {
        imgUrl: 'marketing_site/featured_in/fast-company-dark.png',
        alt: 'Fast Company'
    },
    {
        imgUrl: 'marketing_site/featured_in/forbes-dark.png',
        alt: 'Forbes'
    },
    {
        imgUrl: 'marketing_site/featured_in/reuters-dark.png',
        alt: 'Reuters'
    },
    {
        imgUrl: 'marketing_site/featured_in/tc-dark.png',
        alt: 'Tech Crunch'
    }
];

const FeaturedIn = ({ featuredItems = defaultFeaturedItems }) => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection lightBackground customClass="featured-in">
            <FeatureBlockContent>
                <HBText
                    customClass="featured-in__title"
                    tag="h2"
                    type="title-s"
                >
                    {t(`featuredIn`)}
                </HBText>
                <ul className="featured-in__list">
                    {featuredItems.map(item => (
                        <li key={item.alt} className="featured-in__list-item">
                            <HBMediaElement name={item.imgUrl} alt={item.alt} />
                        </li>
                    ))}
                </ul>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

FeaturedIn.propTypes = {
    featuredItems: PropTypes.arrayOf(PropTypes.object)
};

export default memo(FeaturedIn);
