import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import HBVideo from '@honeybook-ui/HBVideo/HBVideo';
import ImageListAndQuoteBlock, {
    Props
} from '../ImageListAndQuoteBlock/ImageListAndQuoteBlock';

import TimelyPaymentStore from './TimelyPaymentStore';
import './timely-payment-block.scss';

const TimelyPaymentBlock = () => {
    const props: Props = {
        isLeftSide: true,
        featureName: 'timelyPayment',
        featureClassName: 'timely-payment',
        mediumUpMediaElement: (
            <HBVideo autoplay loop muted videoId="770388160" ratio={0.75} />
        ),
        mediumDownMediaElement: (
            <HBMediaElement
                width={530}
                name="marketing_site/online-payments/Payment_notification_static.png"
            />
        ),
        listItems: TimelyPaymentStore.listItems,
        listIcon: TimelyPaymentStore.listIcon,
        quoteProps: {
            name: 'Liz Rivera',
            avatar: TimelyPaymentStore.avatar
        }
    };

    return <ImageListAndQuoteBlock {...props} />;
};

export default TimelyPaymentBlock;
