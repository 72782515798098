import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import Store from '../../stores/TemplatesStore';

import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

import './invoice-template-element.scss';
import TemplateDownloadButton from '../TemplateDownloadButton/TemplateDownloadButton';

const trackClickMoreInvoices = vertical => {
    AnalyticsService.trackClick(`navigate to invoices for ${vertical} page`);
};
const trackClickSoftwareFor = vertical => {
    AnalyticsService.trackClick(`navigate to software for ${vertical} page`);
};

const handleLinkWithAnalytics = (e, trackFn) => {
    e.preventDefault();
    trackFn(e.target.dataset.vertical);

    if (typeof window !== 'undefined') {
        window.location = e.currentTarget.href;
    }
};

const handleLoadToHBAnalytics = vertical => {
    AnalyticsService.trackClick(AnalyticsEvents.template_load_to_hb, {
        source: vertical
    });
};

const handleMoreInvoicesClick = e =>
    handleLinkWithAnalytics(e, trackClickMoreInvoices);
const handleSoftwareForClick = e =>
    handleLinkWithAnalytics(e, trackClickSoftwareFor);

const InvoiceTemplateElement = ({
    t,
    invoiceId,
    showLinks,
    hasGatedDownload,
    templateTypeId,
    mediaFlags
}) => {
    const { templateLinkPath } = Store;

    const invoiceData = Store.templates.find(i => i.id === invoiceId);

    // Sometimes, an invoice has a specific url
    // Usually, each one falls back to a vertical default page
    const invoice = {
        ...invoiceData,
        urlMoreInvoices:
            invoiceData.moreInvoices ||
            templateLinkPath[invoiceData.vertical].moreInvoices,
        urlSoftwareFor:
            invoiceData.softwareFor ||
            templateLinkPath[invoiceData.vertical].softwareFor
    };

    const {
        vertical,
        imagePath,
        imageAlt,
        showVertical,
        urlMoreInvoices,
        urlSoftwareFor,
        downloadLink,
        templateId
    } = invoice;

    if (invoice === undefined) {
        return <p>No invoice found</p>;
    }

    const _imageAlt =
        imageAlt ||
        `${t(`TemplateElements.${vertical}.id.${invoiceId}.title`)} ${t(
            `TemplateElements.common.altTag`
        )}`;

    return (
        <div className="invoice-template-element">
            {imagePath && (
                <HBMediaElement
                    name={imagePath}
                    {...mediaFlags}
                    alt={_imageAlt}
                />
            )}

            <div className="invoice-template-element__text-container">
                {showVertical && (
                    <h4 className="invoice-template-element__pre-title">
                        {t(`TemplateElements.${vertical}.title`)}
                    </h4>
                )}
                <h3 className="invoice-template-element__title">
                    {t(`TemplateElements.${vertical}.id.${invoiceId}.title`)}
                </h3>
                <p className="invoice-template-element__description">
                    {t(
                        `TemplateElements.${vertical}.id.${invoiceId}.description`
                    )}
                </p>
                {showLinks && (
                    <div className="invoice-template-element__link-container">
                        <p className="invoice-template-element__link-text">
                            <a
                                data-vertical={vertical}
                                className="invoice-template-element__link"
                                onClick={handleMoreInvoicesClick}
                                href={urlMoreInvoices}
                            >
                                {t(`TemplateElements.common.linkInvoices`)}{' '}
                                {t(`TemplateElements.${vertical}.linkTitle`)}
                            </a>
                        </p>
                        <p className="invoice-template-element__link-text">
                            <a
                                data-vertical={vertical}
                                className="invoice-template-element__link"
                                onClick={handleSoftwareForClick}
                                href={urlSoftwareFor}
                            >
                                {t(`TemplateElements.common.linkSoftware`)}{' '}
                                {t(`TemplateElements.${vertical}.titlePlural`)}
                            </a>
                        </p>
                    </div>
                )}

                <div
                    onClick={() => {
                        handleLoadToHBAnalytics(vertical);
                    }}
                >
                    <RegistrationForm
                        customClass="invoice-template-element__load-template-btn"
                        direction="column"
                        templateId={templateId}
                        buttonStyle="primary--dark"
                        buttonText={t(`TemplateElements.common.ctaLoadToHb`)}
                        source={`Invoice generator LP - ${invoiceId}`}
                    />
                </div>

                <TemplateDownloadButton
                    invoiceName={t(
                        `TemplateElements.${vertical}.id.${invoiceId}.title`
                    )}
                    templateTypeId={templateTypeId}
                    downloadUrl={downloadLink}
                    hasGatedDownload={hasGatedDownload}
                />
            </div>
        </div>
    );
};

InvoiceTemplateElement.propTypes = {
    t: PropTypes.func,
    invoiceId: PropTypes.string.isRequired,
    templateTypeId: PropTypes.string,
    mediaFlags: PropTypes.shape({}),
    showLinks: PropTypes.bool,
    hasGatedDownload: PropTypes.bool
};

export default withTranslation()(InvoiceTemplateElement);
