import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';

import FeatureBlockText from '../../FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import '../SocialProofBlock/social-proof-block.scss';
import TestimonialCard from '../../TestimonialCard/TestimonialCard';

interface Quote {
    personalInfo: {
        name: string;
        job: string;
        location: string;
    };
    quote: string;
}

function SocialProofBlock(): JSX.Element {
    const { t } = useTranslation();

    const quotes: Quote[] = t('careersPageRebrand.ourStories.quotes', {
        returnObjects: true
    });
    const quotesAvatars = [
        '/marketing_site/careers_page/careers_testimonials/hannah.png',
        '/marketing_site/careers_page/careers_testimonials/christian.png',
        '/marketing_site/careers_page/careers_testimonials/allen.png',
        '/marketing_site/careers_page/careers_testimonials/claire.png',
        '/marketing_site/careers_page/careers_testimonials/carmel.png',
        '/marketing_site/careers_page/careers_testimonials/lorenzo.png'
    ];

    return (
        <FeatureBlockSection customClass="social-proof">
            <FeatureBlockContent>
                <FeatureBlockText
                    customClass="social-proof__title"
                    primary={t('careersPageRebrand.ourStories.title')}
                    fullWidth
                />
                <div className={'quotes-list'}>
                    {quotes.map((quoteData, idx) => (
                        <TestimonialCard
                            key={`testimonial-${idx}`}
                            customClass="quote-card"
                            text={quoteData.quote}
                            personalInfo={quoteData.personalInfo}
                            image={quotesAvatars[idx]}
                        />
                    ))}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default memo(SocialProofBlock);
