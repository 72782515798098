import { memo, useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';

import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';
import VideoModal from '../Modals/VideoModal/VideoModal';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

import './video-with-modal.scss';

import type { VideoItem } from '../VideoCarousel/VideoCarousel';

interface Props {
    selectedVideo: VideoItem;
    context?: { variant: string };
    noVideoModal?: boolean;
}

function VideoWithModal({
    selectedVideo,
    context,
    noVideoModal
}: Props): JSX.Element {
    const [videoModalOpen, setVideoModalOpen] = useState(false);

    const toggleVideoModal = useCallback((): void => {
        if (!videoModalOpen)
            AnalyticsService.trackClick(AnalyticsEvents.watch_video, context);

        setVideoModalOpen(!videoModalOpen);
    }, [context, noVideoModal, videoModalOpen]);

    const onClick = useCallback(() => {
        if (noVideoModal) return;

        toggleVideoModal();
    }, [noVideoModal, toggleVideoModal]);

    // TODO: replace style with data-* attribute, and set background with css function
    // const style = useMemo(() => {
    //   return {
    //     backgroundImage: `url(${selectedVideo.posterURL})`,
    //   }
    // }, [selectedVideo.posterURL])

    const divClassName = `lazyload video-with-modal video-with-modal--${selectedVideo.id}`;
    const videoClass = classnames('video-overlay', {
        'video-overlay--clickable': !noVideoModal
    });

    // TODO: Should be a button
    return (
        <div className={divClassName} onClick={onClick}>
            <img
                className="video-poster-image"
                src={selectedVideo.posterURL}
                alt={selectedVideo.quote}
                loading="lazy"
            />
            <div className={videoClass}>
                {selectedVideo.quote && (
                    <div className="quote-container">
                        <div className="quote">{selectedVideo.quote}</div>
                        <div className="quotee">{selectedVideo.name}</div>
                    </div>
                )}
                {!noVideoModal && (
                    <HBMediaElement
                        name="marketing_site/home_page/play.svg"
                        width={92}
                        height={92}
                        forceImgTag
                        alt="play"
                    />
                )}
            </div>
            {!noVideoModal && (
                <VideoModal
                    video={selectedVideo}
                    isOpen={videoModalOpen}
                    onClose={toggleVideoModal}
                />
            )}
        </div>
    );
}

VideoWithModal.propTypes = {
    selectedVideo: PropTypes.shape({
        id: PropTypes.string.isRequired,
        quote: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }).isRequired,
    context: PropTypes.shape({}),
    noVideoModal: PropTypes.bool
};

export default memo(VideoWithModal);
