import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import UtilsService from '../Services/UtilsService';
import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';
import TOSLinksBlock from '../components/FeatureBlocks/TOSLinksBlock/TOSLinksBlock';
import TOSTabContent from './TosTabLayouts/TOSTabContent';
import PrivacyTabContent from './TosTabLayouts/PrivacyTabContent';
import TOSOldTabContent from './TosTabLayouts/TOSOldTabContent';
import PrivacyOldTabContent from './TosTabLayouts/PrivacyOldTabContent';

import '../../src/styles/terms-of-service.scss';

const selectContent = contentId => {
    switch (contentId) {
        case 'termsOfService':
            return <TOSTabContent />;
        case 'termsOfServiceOriginal':
            return <TOSOldTabContent />;
        case 'privacy':
            return <PrivacyTabContent />;
        case 'privacyOriginal':
            return <PrivacyOldTabContent />;
        default:
            return <TOSTabContent />;
    }
};

const TermsOfServiceStore = {
    termsOfService: {
        url: '/terms/terms-of-service',
        title: 'Terms of Service',
        description: 'Read our terms of service and our privacy policy.',
        noIndex: true
    },
    privacy: {
        url: '/terms/privacy',
        title: 'Privacy Policy',
        description: 'Read our terms of service and our privacy policy.',
        noIndex: true
    },
    privacyOriginal: {
        url: '/terms/privacy-original',
        title: 'Privacy Policy',
        description: 'Read our terms of service and our privacy policy.',
        noIndex: true
    }
};

const TermsOfServicePage = ({ pageContext }) => {
    const { t } = useTranslation();
    const { contentKey } = pageContext;

    const contentKeyCamelCase = UtilsService.toCamelCase(contentKey);
    const ogTags =
        TermsOfServiceStore[contentKeyCamelCase] ||
        TermsOfServiceStore.termsOfService;

    return (
        <section className="tos-lp">
            <Layout ogTags={ogTags}>
                <WhiteHero
                    image="marketing_site/tos_page/hero-terms-v2_3x.jpg"
                    mainTitle={t('heroTOS.title')}
                    description={t('heroTOS.subtitle')}
                    imageWidth={940}
                />

                <TOSLinksBlock contentId={contentKey} />

                {selectContent(contentKey)}
            </Layout>
        </section>
    );
};

TermsOfServicePage.propTypes = {
    contentKey: PropTypes.string
};

export default TermsOfServicePage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "TOS"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
