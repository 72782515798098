import AnalyticsService, { AnalyticsEvents } from './AnalyticsService';
import CookiesService from './CookiesService';
import StorageService from './StorageService';
import UtilsService from './UtilsService';
import PageLoadService from './PageLoadService';

import type {
    IStorageUserABTest,
    IUserABTest,
    TVariant
} from '../types/ABTest-types';
import { ABTestToolUtils } from './ABTestToolServiceUtils';

import { VARIANT_OPTIONS } from '../constants/constants';
import { CURRENT_AB_TESTS } from '../constants/current-ab-tests';

export const AB_TEST_COOKIE_KEY = 'segments';
export const AB_TEST_STORAGE_KEY = 'abTests';
export const FALLBACK_VARIANT_STORAGE_KEY = 'fallbackVariants';
const queryParamsVariant = 'variant';

class ABTestToolService {
    // userAbTests represent the existing ab tests that the user got , with his own unique variant
    private userAbTests: IUserABTest = {};

    constructor() {
        this.updateUserAbTestsFromCookie();
        const isDevOrQA =
            UtilsService.isBrowser() &&
            (UtilsService.isDev() || UtilsService.isQA());
        if (isDevOrQA) {
            this.handleManualTest();
        }
    }

    // For dev and qa only
    private handleManualTest() {
        const variant =
            UtilsService.getParameterByName(queryParamsVariant) || 'a';

        const cookieString = ABTestToolUtils.getAbTestCookieString(
            CURRENT_AB_TESTS,
            variant as TVariant,
            this.userAbTests
        );
        ABTestToolUtils.setAbTestCookie(cookieString);
        this.updateUserAbTestsFromCookie();
    }

    private updateUserAbTestsFromCookie() {
        if (!UtilsService.isBrowser()) return;
        const userSegments = CookiesService.getCookie(AB_TEST_COOKIE_KEY);
        const splittedUserSegments = userSegments
            .split('&')
            .filter(s => s !== '');
        this.userAbTests = splittedUserSegments.reduce(
            (userAbTests: IUserABTest, segment) => {
                const [path, variant, version] = segment.split(':');
                if (!path && !variant) return userAbTests;
                if (CURRENT_AB_TESTS[path]) {
                    const shouldUseABTest =
                        CURRENT_AB_TESTS[path].version === version;
                    userAbTests[path] = {
                        page_path: path,
                        ab_test_name: CURRENT_AB_TESTS[path].ab_test_name,
                        variant: shouldUseABTest
                            ? (variant as TVariant)
                            : undefined,
                        description: CURRENT_AB_TESTS[path].description,
                        ab_test_variation: shouldUseABTest
                            ? CURRENT_AB_TESTS[path].variants[variant]
                                  .ab_test_variation
                            : undefined,
                        version,
                        shouldUseABTest
                    };
                }
                return userAbTests;
            },
            {}
        );
        const abTestsFromLocalStorage =
            ABTestToolUtils.getUpdatedAbTestsFromLocalStorage(this.userAbTests);
        StorageService.setItem({
            key: AB_TEST_STORAGE_KEY,
            data: abTestsFromLocalStorage
        });
    }

    fireAbTestAnalytics(path: string) {
        if (ABTestToolUtils.abTestNotExistOrStaging(path)) {
            return;
        }
        // one tap test runs only in specific conditions so we want to send data if conditions are true

        if (this.userAbTests[path] && this.userAbTests[path].shouldUseABTest) {
            const abTestsFromLocalStorage =
                (StorageService.getItem(
                    AB_TEST_STORAGE_KEY
                ) as IStorageUserABTest) || {};
            if (
                abTestsFromLocalStorage[path] &&
                !abTestsFromLocalStorage[path].isSentAnalytics
            ) {
                if (PageLoadService.isUserLoggedIn) return;
                AnalyticsService.track(
                    AnalyticsEvents.ab_test_marketing_started,
                    this.getMixpanelData(path)
                );
                abTestsFromLocalStorage[path].isSentAnalytics = true;
                StorageService.setItem({
                    key: AB_TEST_STORAGE_KEY,
                    data: abTestsFromLocalStorage
                });
            }
        }
    }

    getMixpanelData(path: string) {
        if (ABTestToolUtils.abTestNotExistOrStaging(path)) {
            return;
        }
        if (
            !this.userAbTests[path] ||
            !this.userAbTests[path].shouldUseABTest
        ) {
            return;
        }

        return {
            test_platform: 'ab_testor',
            ...this.userAbTests[path]
        };
    }

    getAbTestDetailsByPathName(path: string) {
        return this.userAbTests[path];
    }

    getAllUserABTests() {
        return this.userAbTests;
    }

    setVariantForDevTest(path: string, variant: TVariant) {
        if (process.env.GATSBY_env !== 'development') return;
        if (
            !CURRENT_AB_TESTS[path] ||
            (variant &&
                VARIANT_OPTIONS.includes(variant) &&
                !CURRENT_AB_TESTS[path].variants[variant])
        ) {
            console.error(
                `You should update CURRENT_AB_TESTS if you want to set variant for ${path}`
            );
            return;
        }
        if (this.userAbTests[path].variant === variant) {
            return;
        }
        if (this.userAbTests[path]) {
            this.userAbTests[path].variant = variant;
        }
        let cookieString = ABTestToolUtils.getAbTestCookieString(
            this.userAbTests
        );

        if (!this.userAbTests[path]) {
            if (!!cookieString) {
                cookieString += `&`;
            }
            cookieString += `${path}:${variant}`;
        }
        ABTestToolUtils.setAbTestCookie(cookieString);
    }

    setFallbackVariant(pathName: string) {
        const fallbackVariants =
            ABTestToolUtils.getFallbackVariantsFromSessionStorage();
        if (fallbackVariants[pathName]) {
            delete fallbackVariants[pathName];
            ABTestToolUtils.setFallbackVariant(fallbackVariants);
        }
        if (!CURRENT_AB_TESTS[pathName]?.mainSelectorPage) {
            return;
        }
        const { mainSelectorPage } = CURRENT_AB_TESTS[pathName];
        const selectors = Array.isArray(mainSelectorPage)
            ? mainSelectorPage
            : [mainSelectorPage];
        let mainPageElement;
        selectors.forEach(selector => {
            if (!!selector) {
                mainPageElement ||= document.querySelector(selector);
            }
        });

        const fallbackVariant = mainPageElement?.className
            .split(' ')
            .find(s => VARIANT_OPTIONS.includes(s));
        if (!fallbackVariant) return;
        ABTestToolUtils.setFallbackVariant({
            ...fallbackVariants,
            [pathName]: fallbackVariant as TVariant
        });
    }
}

export default new ABTestToolService();
